export default class MathUtil {
    /**
     * Linear interpolates (lerps) a value that is between 'from' and 'to' based on the given ratio 'value'
     */
    public static lerp(from: number, to: number, value: number): number {
        return (1 - value) * from + value * to;
    }

    /**
     * The opposite of lerp. Normalizes the ratio 'value' between 'from' and 'to'
     */
    public static inverseLerp(from: number, to: number, value: number): number {
        return (value - from) / (to - from);
    }

    /**
     * Maps a value from one range to another
     */
    public static remap(originalFrom, originalTo, targetFrom, targetTo, value): number {
        const relativeValue = MathUtil.inverseLerp(originalFrom, originalTo, value);
        return MathUtil.lerp(targetFrom, targetTo, relativeValue);
    }

    /**
     * Clamps a value between a min and max
     */
    public static clamp(value: number, min: number, max: number): number {
        return Math.min(Math.max(value, min), max);
    }

    public static formatNumber(value: number): string {
        const fixNumber = (value: number, div: number) => {
            const fixedNumber = (value / div).toFixed(1);
            return /\.0$/.test(`${fixedNumber}`) ? `${fixedNumber}`.replace('.0', '') : `${fixedNumber}`;
        };

        if (value > 999) {
            if (value > 999999) {
                return `${fixNumber(value, 1000000)}M`;
            }
            return `${fixNumber(value, 1000)}K`;
        }
        return `${value}`;
    }
}
