import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { Basemap } from './model';

interface BasemapStore {
    selectedBasemap: undefined | Basemap;
}

const initialState: BasemapStore = {
    selectedBasemap: undefined,
};

export default (state: BasemapStore = initialState, action: Action): BasemapStore => {
    switch (action.type) {
        case at.SET_BASEMAP:
            return {
                ...state,
                selectedBasemap: action.payload,
            };
        default:
            return state;
    }
};
