import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import DropDown, { DropOptionType } from '../../Shared/dropdown';
import { ImagerySortOptions } from '../Imagery/imagery-options';
import { isMobileVersion } from '../../../lib/soar-helper';

interface NavigationFilterInputProps {
    placeholderText: string;
    filterValue: string;
    setFilterValue: (filterValue: string) => void;
    setSortValue?: (sortValue: string | undefined) => void;
    handleClearFilter: () => void;
    filterComponent?: string;
    dataTestId?: string;
    sortOptions?: DropOptionType[];
    title?: string;
}

const NavigationFilterInput = (props: NavigationFilterInputProps) => {
    const { placeholderText, setFilterValue, setSortValue, handleClearFilter } = props;
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        setFilterValue(inputValue.toLowerCase());
    }, [inputValue, setFilterValue]);

    const handleClearInput = () => {
        setInputValue('');
        handleClearFilter();
    };

    return (
        <ProfileNavigationFilterContainer>
            <FilterInputContainer>
                <FilterInput
                    data-testid={props.dataTestId && `${props.dataTestId}-input`}
                    placeholder={placeholderText}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        Analytics.Event(
                            `${props.filterComponent ? props.filterComponent : 'Explore'}`,
                            'Clicked filter input',
                            e.target.value
                        );
                    }}
                />
                <ClearIcon
                    data-testid={props.dataTestId && `${props.dataTestId}-clear-input`}
                    className="fa fa-times"
                    onClick={handleClearInput}
                />
            </FilterInputContainer>
            <DropDownContainer>
                <DropDown
                    dataTestId={props.dataTestId && `${props.dataTestId}-sort`}
                    name="SortBy"
                    options={props.sortOptions ?? ImagerySortOptions.options}
                    title={props.title ?? ImagerySortOptions.title}
                    handleSelectedOptions={(options) => {
                        const sortValue = options ? options[0] : undefined;
                        const value = sortValue?.value || '';
                        Analytics.Event(
                            `${props.filterComponent ? props.filterComponent : 'Explore'}`,
                            'Clicked sort option',
                            `${ImagerySortOptions.title} - ${value}`
                        );

                        if (setSortValue) {
                            setSortValue(sortValue?.value);
                        }
                    }}
                    isRadio={true}
                    icons={{
                        light: ImagerySortOptions.icons.light,
                        dark: ImagerySortOptions.icons.dark,
                    }}
                />
            </DropDownContainer>
        </ProfileNavigationFilterContainer>
    );
};

export default NavigationFilterInput;

const ProfileNavigationFilterContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 6px 0 20px 0;

    ${isMobileVersion &&
    css`
        margin-left: 5px;
        margin-right: 5px;
    `};
`;

const FilterInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    @media only screen and (max-width: 600px) {
        position: relative;
    }
`;

const FilterInput = styled.input`
    display: block;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    padding: 4px;
    color: rgba(255, 255, 255, 0.6);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;

    :hover,
    :active,
    :focus,
    :focus-visible {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6) !important;
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3) !important;
        color: rgba(255, 255, 255, 0.6) !important;
        outline: none;
    }
`;

const ClearIcon = styled.i`
    float: right;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    padding: 9px 7px;
    font-size: 16px;
    margin-left: -30px;
    z-index: 5;
`;

const DropDownContainer = styled.div`
    padding-left: 10px;
    width: 290px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
        width: 50px;
    }
`;
