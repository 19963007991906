/**
 * Extracted from the protomap theme file, as using theme and labelRules is not supported
 * SOAR_DEFAULT_LIGHT and SOAR_DEFAULT_DARK are current custom themes
 *
 * OSM_DEFAULT_LIGHT, OSM_DEFAULT_DARK, OSM_DEFAULT_WHITE, OSM_DEFAULT_BLACK, OSM_DEFAULT_GRAYSCALE are the default themes
 * with the labels set to transparent
 *
 */
export interface Theme {
    background: string;
    earth: string;
    park_a: string;
    park_b: string;
    hospital: string;
    industrial: string;
    school: string;
    wood_a: string;
    wood_b: string;
    pedestrian: string;
    scrub_a: string;
    scrub_b: string;
    glacier: string;
    sand: string;
    beach: string;
    aerodrome: string;
    runway: string;
    water: string;
    pier: string;
    zoo: string;
    military: string;

    tunnel_other_casing: string;
    tunnel_minor_casing: string;
    tunnel_link_casing: string;
    tunnel_medium_casing: string;
    tunnel_major_casing: string;
    tunnel_highway_casing: string;
    tunnel_other: string;
    tunnel_minor: string;
    tunnel_link: string;
    tunnel_medium: string;
    tunnel_major: string;
    tunnel_highway: string;

    transit_pier: string;
    buildings: string;

    minor_service_casing: string;
    minor_casing: string;
    link_casing: string;
    medium_casing: string;
    major_casing_late: string;
    highway_casing_late: string;
    other: string;
    minor_service: string;
    minor_a: string;
    minor_b: string;
    link: string;
    medium: string;
    major_casing_early: string;
    major: string;
    highway_casing_early: string;
    highway: string;

    railway: string;
    boundaries: string;
    waterway_label: string;

    bridges_other_casing: string;
    bridges_minor_casing: string;
    bridges_link_casing: string;
    bridges_medium_casing: string;
    bridges_major_casing: string;
    bridges_highway_casing: string;
    bridges_other: string;
    bridges_minor: string;
    bridges_link: string;
    bridges_medium: string;
    bridges_major: string;
    bridges_highway: string;

    roads_label_minor: string;
    roads_label_minor_halo: string;
    roads_label_major: string;
    roads_label_major_halo: string;
    ocean_label: string;
    peak_label: string;
    subplace_label: string;
    subplace_label_halo: string;
    city_circle: string;
    city_circle_stroke: string;
    city_label: string;
    city_label_halo: string;
    state_label: string;
    state_label_halo: string;
    country_label: string;
}

const TRANSPARENT_LABELS = {
    waterway_label: 'transparent',

    roads_label_minor: 'transparent',
    roads_label_minor_halo: 'transparent',
    roads_label_major: 'transparent',
    roads_label_major_halo: 'transparent',
    ocean_label: 'transparent',
    peak_label: 'transparent',
    subplace_label: 'transparent',
    subplace_label_halo: 'transparent',
    city_circle: 'transparent',
    city_circle_stroke: 'transparent',
    city_label: 'transparent',
    city_label_halo: 'transparent',
    state_label: 'transparent',
    state_label_halo: 'transparent',
    country_label: 'transparent',
};

export const DEFAULT_SOAR_LIGHT: Theme = {
    background: '#FFFBF6',
    earth: '#FFFBF6',
    park_a: '#FFFBF6',
    park_b: '#FFFBF6',
    hospital: '#FFF6F6',
    industrial: '#FFFBF6',
    school: '#FFFBF6',
    wood_a: '#FFFBF6',
    wood_b: '#FFFBF6',
    pedestrian: '#FFFBF6',
    scrub_a: '#FFFBF6',
    scrub_b: '#FFFBF6',
    glacier: '#FFFBF6',
    sand: '#FFFBF6',
    beach: '#FFFBF6',
    aerodrome: '#FFFBF6',
    runway: '#FFFBF6',
    water: '#B7DFF2',
    pier: '#FFFBF6',
    zoo: '#FFFBF6',
    military: '#FFFBF6',
    tunnel_other_casing: '#FFFBF6',
    tunnel_minor_casing: '#FFFBF6',
    tunnel_link_casing: '#FFFBF6',
    tunnel_medium_casing: '#FFFBF6',
    tunnel_major_casing: '#FFFBF6',
    tunnel_highway_casing: '#FFFBF6',
    tunnel_other: '#FFFBF6',
    tunnel_minor: '#FFFBF6',
    tunnel_link: '#FFFBF6',
    tunnel_medium: '#FFFBF6',
    tunnel_major: '#FFFBF6',
    tunnel_highway: '#FFFBF6',
    transit_pier: '#FFFBF6',
    buildings: '#F2EDE8',
    minor_service_casing: '#FFFBF6',
    minor_casing: '#FFFBF6',
    link_casing: '#FFFBF6',
    medium_casing: '#FFFBF6',
    major_casing_late: '#FFFBF6',
    highway_casing_late: '#FFFBF6',
    other: '#FFFBF6',
    minor_service: '#FFFBF6',
    minor_a: '#FFFBF6',
    minor_b: '#FFFBF6',
    link: '#FFFBF6',
    medium: '#FFFBF6',
    major_casing_early: '#FFFBF6',
    major: '#FFFBF6',
    highway_casing_early: '#FFE4B3',
    highway: '#FFF2C8',
    railway: '#ffffff',
    boundaries: '#9e9e9e',
    bridges_other_casing: '#FFFBF6',
    bridges_minor_casing: '#FFFBF6',
    bridges_link_casing: '#FFFBF6',
    bridges_medium_casing: '#FFFBF6',
    bridges_major_casing: '#FFFBF6',
    bridges_highway_casing: '#FFFBF6',
    bridges_other: '#FFFBF6',
    bridges_minor: '#FFFBF6',
    bridges_link: '#FFFBF6',
    bridges_medium: '#FFFBF6',
    bridges_major: '#FFFBF6',
    bridges_highway: '#FFFBF6',

    ...TRANSPARENT_LABELS,
};

export const DEFAULT_SOAR_DARK: Theme = {
    background: '#151515',
    earth: '#151515',
    park_a: '#151515',
    park_b: '#151515',
    hospital: '#151515',
    industrial: '#151515',
    school: '#151515',
    wood_a: '#151515',
    wood_b: '#151515',
    pedestrian: '#151515',
    scrub_a: '#151515',
    scrub_b: '#151515',
    glacier: '#151515',
    sand: '#151515',
    beach: '#151515',
    aerodrome: '#151515',
    runway: '#151515',
    water: '#4D5B73',
    pier: '#151515',
    zoo: '#151515',
    military: '#151515',
    tunnel_other_casing: '#151515',
    tunnel_minor_casing: '#151515',
    tunnel_link_casing: '#151515',
    tunnel_medium_casing: '#151515',
    tunnel_major_casing: '#151515',
    tunnel_highway_casing: '#151515',
    tunnel_other: '#151515',
    tunnel_minor: '#151515',
    tunnel_link: '#151515',
    tunnel_medium: '#151515',
    tunnel_major: '#151515',
    tunnel_highway: '#151515',
    transit_pier: '#151515',
    buildings: '#464545',
    minor_service_casing: '#151515',
    minor_casing: '#151515',
    link_casing: '#151515',
    medium_casing: '#151515',
    major_casing_late: '#151515',
    highway_casing_late: '#151515',
    other: '#151515',
    minor_service: '#151515',
    minor_a: '#151515',
    minor_b: '#151515',
    link: '#151515',
    medium: '#151515',
    major_casing_early: '#151515',
    major: '#151515',
    highway_casing_early: '#5B5B5B',
    highway: '#5B5B5B',
    railway: '#464545',
    boundaries: '#666666',
    bridges_other_casing: '#151515',
    bridges_minor_casing: '#151515',
    bridges_link_casing: '#151515',
    bridges_medium_casing: '#151515',
    bridges_major_casing: '#151515',
    bridges_highway_casing: '#151515',
    bridges_other: '#151515',
    bridges_minor: '#151515',
    bridges_link: '#151515',
    bridges_medium: '#151515',
    bridges_major: '#151515',
    bridges_highway: '#151515',

    ...TRANSPARENT_LABELS,
};

export const LIGHT: Theme = {
    background: '#cccccc',
    earth: '#e0e0e0',
    park_a: '#cfddd5',
    park_b: '#9cd3b4',
    hospital: '#e4dad9',
    industrial: '#d1dde1',
    school: '#e4ded7',
    wood_a: '#d0ded0',
    wood_b: '#a0d9a0',
    pedestrian: '#e3e0d4',
    scrub_a: '#cedcd7',
    scrub_b: '#99d2bb',
    glacier: '#e7e7e7',
    sand: '#e2e0d7',
    beach: '#e8e4d0',
    aerodrome: '#dadbdf',
    runway: '#e9e9ed',
    water: '#80deea',
    pier: '#e0e0e0',
    zoo: '#c6dcdc',
    military: '#dcdcdc',

    tunnel_other_casing: '#e0e0e0',
    tunnel_minor_casing: '#e0e0e0',
    tunnel_link_casing: '#e0e0e0',
    tunnel_medium_casing: '#e0e0e0',
    tunnel_major_casing: '#e0e0e0',
    tunnel_highway_casing: '#e0e0e0',
    tunnel_other: '#d5d5d5',
    tunnel_minor: '#d5d5d5',
    tunnel_link: '#d5d5d5',
    tunnel_medium: '#d5d5d5',
    tunnel_major: '#d5d5d5',
    tunnel_highway: '#d5d5d5',

    transit_pier: '#e0e0e0',
    buildings: '#cccccc',

    minor_service_casing: '#e0e0e0',
    minor_casing: '#e0e0e0',
    link_casing: '#e0e0e0',
    medium_casing: '#e0e0e0',
    major_casing_late: '#e0e0e0',
    highway_casing_late: '#e0e0e0',
    other: '#ebebeb',
    minor_service: '#ebebeb',
    minor_a: '#ebebeb',
    minor_b: '#ffffff',
    link: '#ffffff',
    medium: '#f5f5f5',
    major_casing_early: '#e0e0e0',
    major: '#ffffff',
    highway_casing_early: '#e0e0e0',
    highway: '#ffffff',

    railway: '#a7b1b3',
    boundaries: '#adadad',

    bridges_other_casing: '#e0e0e0',
    bridges_minor_casing: '#e0e0e0',
    bridges_link_casing: '#e0e0e0',
    bridges_medium_casing: '#e0e0e0',
    bridges_major_casing: '#e0e0e0',
    bridges_highway_casing: '#e0e0e0',
    bridges_other: '#ebebeb',
    bridges_minor: '#ffffff',
    bridges_link: '#ffffff',
    bridges_medium: '#f0eded',
    bridges_major: '#f5f5f5',
    bridges_highway: '#ffffff',

    ...TRANSPARENT_LABELS,
};

export const DARK: Theme = {
    background: '#34373d',
    earth: '#1f1f1f',
    park_a: '#232325',
    park_b: '#232325',
    hospital: '#252424',
    industrial: '#222222',
    school: '#262323',
    wood_a: '#202121',
    wood_b: '#202121',
    pedestrian: '#1e1e1e',
    scrub_a: '#222323',
    scrub_b: '#222323',
    glacier: '#1c1c1c',
    sand: '#212123',
    beach: '#28282a',
    aerodrome: '#1e1e1e',
    runway: '#333333',
    water: '#34373d',
    pier: '#222222',
    zoo: '#222323',
    military: '#242323',

    tunnel_other_casing: '#141414',
    tunnel_minor_casing: '#141414',
    tunnel_link_casing: '#141414',
    tunnel_medium_casing: '#141414',
    tunnel_major_casing: '#141414',
    tunnel_highway_casing: '#141414',
    tunnel_other: '#292929',
    tunnel_minor: '#292929',
    tunnel_link: '#292929',
    tunnel_medium: '#292929',
    tunnel_major: '#292929',
    tunnel_highway: '#292929',

    transit_pier: '#333333',
    buildings: '#111111',

    minor_service_casing: '#1f1f1f',
    minor_casing: '#1f1f1f',
    link_casing: '#1f1f1f',
    medium_casing: '#1f1f1f',
    major_casing_late: '#1f1f1f',
    highway_casing_late: '#1f1f1f',
    other: '#333333',
    minor_service: '#333333',
    minor_a: '#3d3d3d',
    minor_b: '#333333',
    link: '#3d3d3d',
    medium: '#3d3d3d',
    major_casing_early: '#1f1f1f',
    major: '#3d3d3d',
    highway_casing_early: '#1f1f1f',
    highway: '#474747',

    railway: '#000000',
    boundaries: '#5b6374',

    bridges_other_casing: '#2b2b2b',
    bridges_minor_casing: '#1f1f1f',
    bridges_link_casing: '#1f1f1f',
    bridges_medium_casing: '#1f1f1f',
    bridges_major_casing: '#1f1f1f',
    bridges_highway_casing: '#1f1f1f',
    bridges_other: '#333333',
    bridges_minor: '#333333',
    bridges_link: '#3d3d3d',
    bridges_medium: '#3d3d3d',
    bridges_major: '#3d3d3d',
    bridges_highway: '#474747',

    ...TRANSPARENT_LABELS,
};

export const WHITE: Theme = {
    background: '#ffffff',
    earth: '#ffffff',
    park_a: '#fcfcfc',
    park_b: '#fcfcfc',
    hospital: '#f8f8f8',
    industrial: '#fcfcfc',
    school: '#f8f8f8',
    wood_a: '#fafafa',
    wood_b: '#fafafa',
    pedestrian: '#fdfdfd',
    scrub_a: '#fafafa',
    scrub_b: '#fafafa',
    glacier: '#fcfcfc',
    sand: '#fafafa',
    beach: '#f6f6f6',
    aerodrome: '#fdfdfd',
    runway: '#efefef',
    water: '#dcdcdc',
    pier: '#f5f5f5',
    zoo: '#f7f7f7',
    military: '#fcfcfc',

    tunnel_other_casing: '#d6d6d6',
    tunnel_minor_casing: '#fcfcfc',
    tunnel_link_casing: '#fcfcfc',
    tunnel_medium_casing: '#fcfcfc',
    tunnel_major_casing: '#fcfcfc',
    tunnel_highway_casing: '#fcfcfc',
    tunnel_other: '#d6d6d6',
    tunnel_minor: '#d6d6d6',
    tunnel_link: '#d6d6d6',
    tunnel_medium: '#d6d6d6',
    tunnel_major: '#d6d6d6',
    tunnel_highway: '#d6d6d6',

    transit_pier: '#efefef',
    buildings: '#efefef',

    minor_service_casing: '#ffffff',
    minor_casing: '#ffffff',
    link_casing: '#ffffff',
    medium_casing: '#ffffff',
    major_casing_late: '#ffffff',
    highway_casing_late: '#ffffff',
    other: '#f5f5f5',
    minor_service: '#f5f5f5',
    minor_a: '#ebebeb',
    minor_b: '#f5f5f5',
    link: '#ebebeb',
    medium: '#ebebeb',
    major_casing_early: '#ffffff',
    major: '#ebebeb',
    highway_casing_early: '#ffffff',
    highway: '#ebebeb',

    railway: '#d6d6d6',
    boundaries: '#adadad',

    bridges_other_casing: '#ffffff',
    bridges_minor_casing: '#ffffff',
    bridges_link_casing: '#ffffff',
    bridges_medium_casing: '#ffffff',
    bridges_major_casing: '#ffffff',
    bridges_highway_casing: '#ffffff',
    bridges_other: '#f5f5f5',
    bridges_minor: '#f5f5f5',
    bridges_link: '#ebebeb',
    bridges_medium: '#ebebeb',
    bridges_major: '#ebebeb',
    bridges_highway: '#ebebeb',

    ...TRANSPARENT_LABELS,
};

export const GRAYSCALE: Theme = {
    background: '#a3a3a3',
    earth: '#cccccc',
    park_a: '#c2c2c2',
    park_b: '#c2c2c2',
    hospital: '#d0d0d0',
    industrial: '#c6c6c6',
    school: '#d0d0d0',
    wood_a: '#c2c2c2',
    wood_b: '#c2c2c2',
    pedestrian: '#c4c4c4',
    scrub_a: '#c2c2c2',
    scrub_b: '#c2c2c2',
    glacier: '#d2d2d2',
    sand: '#d2d2d2',
    beach: '#d2d2d2',
    aerodrome: '#c9c9c9',
    runway: '#f5f5f5',
    water: '#a3a3a3',
    pier: '#b8b8b8',
    zoo: '#c7c7c7',
    military: '#bfbfbf',

    tunnel_other_casing: '#b8b8b8',
    tunnel_minor_casing: '#b8b8b8',
    tunnel_link_casing: '#b8b8b8',
    tunnel_medium_casing: '#b8b8b8',
    tunnel_major_casing: '#b8b8b8',
    tunnel_highway_casing: '#b8b8b8',
    tunnel_other: '#d6d6d6',
    tunnel_minor: '#d6d6d6',
    tunnel_link: '#d6d6d6',
    tunnel_medium: '#d6d6d6',
    tunnel_major: '#d6d6d6',
    tunnel_highway: '#d6d6d6',

    transit_pier: '#b8b8b8',
    buildings: '#e0e0e0',

    minor_service_casing: '#cccccc',
    minor_casing: '#cccccc',
    link_casing: '#cccccc',
    medium_casing: '#cccccc',
    major_casing_late: '#cccccc',
    highway_casing_late: '#cccccc',
    other: '#e0e0e0',
    minor_service: '#e0e0e0',
    minor_a: '#ebebeb',
    minor_b: '#e0e0e0',
    link: '#ebebeb',
    medium: '#ebebeb',
    major_casing_early: '#cccccc',
    major: '#ebebeb',
    highway_casing_early: '#cccccc',
    highway: '#ebebeb',

    railway: '#f5f5f5',
    boundaries: '#5c5c5c',

    bridges_other_casing: '#cccccc',
    bridges_minor_casing: '#cccccc',
    bridges_link_casing: '#cccccc',
    bridges_medium_casing: '#cccccc',
    bridges_major_casing: '#cccccc',
    bridges_highway_casing: '#cccccc',
    bridges_other: '#e0e0e0',
    bridges_minor: '#e0e0e0',
    bridges_link: '#ebebeb',
    bridges_medium: '#ebebeb',
    bridges_major: '#ebebeb',
    bridges_highway: '#ebebeb',

    ...TRANSPARENT_LABELS,
};

export const BLACK: Theme = {
    background: '#2b2b2b',
    earth: '#141414',
    park_a: '#181818',
    park_b: '#181818',
    hospital: '#1d1d1d',
    industrial: '#101010',
    school: '#111111',
    wood_a: '#1a1a1a',
    wood_b: '#1a1a1a',
    pedestrian: '#191919',
    scrub_a: '#1c1c1c',
    scrub_b: '#1c1c1c',
    glacier: '#191919',
    sand: '#161616',
    beach: '#1f1f1f',
    aerodrome: '#191919',
    runway: '#323232',
    water: '#333333',
    pier: '#0a0a0a',
    zoo: '#191919',
    military: '#121212',

    tunnel_other_casing: '#101010',
    tunnel_minor_casing: '#101010',
    tunnel_link_casing: '#101010',
    tunnel_medium_casing: '#101010',
    tunnel_major_casing: '#101010',
    tunnel_highway_casing: '#101010',
    tunnel_other: '#292929',
    tunnel_minor: '#292929',
    tunnel_link: '#292929',
    tunnel_medium: '#292929',
    tunnel_major: '#292929',
    tunnel_highway: '#292929',

    transit_pier: '#0a0a0a',
    buildings: '#0a0a0a',

    minor_service_casing: '#141414',
    minor_casing: '#141414',
    link_casing: '#141414',
    medium_casing: '#141414',
    major_casing_late: '#141414',
    highway_casing_late: '#141414',
    other: '#1f1f1f',
    minor_service: '#1f1f1f',
    minor_a: '#292929',
    minor_b: '#1f1f1f',
    link: '#1f1f1f',
    medium: '#292929',
    major_casing_early: '#141414',
    major: '#292929',
    highway_casing_early: '#141414',
    highway: '#292929',

    railway: '#292929',
    boundaries: '#707070',

    bridges_other_casing: '#141414',
    bridges_minor_casing: '#141414',
    bridges_link_casing: '#141414',
    bridges_medium_casing: '#141414',
    bridges_major_casing: '#141414',
    bridges_highway_casing: '#141414',
    bridges_other: '#1f1f1f',
    bridges_minor: '#1f1f1f',
    bridges_link: '#292929',
    bridges_medium: '#292929',
    bridges_major: '#292929',
    bridges_highway: '#292929',

    ...TRANSPARENT_LABELS,
};

export const themes: Record<string, Theme> = {
    light: LIGHT,
    dark: DARK,
    white: WHITE,
    grayscale: GRAYSCALE,
    black: BLACK,
};
