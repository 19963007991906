import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMyPoints } from '../../../store/Account/selectors';
import { lightDropShadow } from '../../../styles/style';

const PointsTooltip = () => {
    const userPoints = useSelector(selectMyPoints);

    return (
        <React.Fragment>
            <TooltipContainer>
                <TooltipTitle>
                    You have <span>{userPoints ? userPoints.toLocaleString() : 'no'}</span> points
                </TooltipTitle>
                <TooltipContent>Earn more points by:</TooltipContent>
                <TooltipList>
                    <TooltipListItem>Viewing maps</TooltipListItem>
                    <TooltipListItem>Commenting on maps</TooltipListItem>
                    <TooltipListItem>Uploading maps</TooltipListItem>
                </TooltipList>
            </TooltipContainer>
        </React.Fragment>
    );
};

export default PointsTooltip;

const TooltipContainer = styled.div`
    position: fixed;
    width: 200px;
    max-height: 200px;
    margin: 155px 125px 0 0;
    background: ${(props) => props.theme.color.transparentDark};
    padding: 8px;
    z-index: 99999;
    border-radius: 6px;
    ${lightDropShadow}

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        right: 20px;
    }
    &:after {
        top: -10px;
        border-bottom: 10px solid ${(props) => props.theme.color.transparentDark};
        border-bottom: 10px solid ${(props) => props.theme.color.transparentDark};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
    &:before {
        right: 19px;
        top: -11px;
        border-bottom: 11px solid rgba(255, 255, 255, 0.3);
        border-bottom: 11px solid rgba(255, 255, 255, 0.3);
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
    }
`;

const TooltipTitle = styled.h5`
    text-align: center;
    color: white;
    padding: 0;
    margin: 0;

    span {
        color: #eed923;
    }
`;

const TooltipContent = styled.p`
    color: white;
    padding: 0;
    margin: 12px 0 0 0;
`;

const TooltipList = styled.ul`
    list-style: inside;
    padding: 0 0 0 6px;
    margin: 0;
`;

const TooltipListItem = styled.li`
    color: white;
`;
