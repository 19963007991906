import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';
import L from 'leaflet';
import LayersUtil from '../layers-util';

interface PolygonDragElementProps extends L.PolylineOptions {
    polygonElement: Readonly<{ instance: L.Polygon; context: Readonly<{ map: L.Map }> }>;
    positions: L.LatLng[];
    isSelected: boolean;
}

const pathDragOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 30,
    fill: true,
    bubblingMouseEvents: false,
};

export const createPolygonDragElement = (props: PolygonDragElementProps, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);

    const polygonDrag = new L.Polygon([...props.positions, props.positions[0]], {
        ...pathDragOptions,
        pane: controlPaneId,
    });

    const polygonDragElement = createElementObject<L.Polygon, PolygonDragElementProps>(polygonDrag, context);

    polygonDragElement.instance.on('mouseover', () => {
        if (props.isSelected) {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
        }
    });

    polygonDragElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
    });

    let lastLatLng: L.LatLng | undefined;
    let isDraggable = false;
    const onMouseDown = (e: L.LeafletMouseEvent) => {
        if (!props.isSelected) {
            return;
        }
        lastLatLng = e.latlng;
        isDraggable = true;
        context.map.dragging.disable();

        context.map.on('mousemove', onMouseMove);
        context.map.on('mouseup', onMouseUp);
        props.polygonElement.instance.fireEvent('path-drag-start');
    };

    const onMouseUp = () => {
        lastLatLng = undefined;
        isDraggable = false;
        context.map.dragging.enable();

        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);
        props.polygonElement.instance.fireEvent('path-drag-end');
    };

    const onMouseMove = (e: L.LeafletMouseEvent) => {
        if (lastLatLng && isDraggable) {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
            const latDiff = e.latlng.lat - lastLatLng.lat;
            const lngDiff = e.latlng.lng - lastLatLng.lng;
            const latLngDifference = L.latLng(latDiff, lngDiff);

            lastLatLng = e.latlng;

            const currentLatLngs = props.polygonElement.instance.getLatLngs() as [L.LatLng[]];
            const updatedLatLngs = currentLatLngs[0].map((point) => {
                return L.latLng(point.lat + latLngDifference.lat, point.lng + latLngDifference.lng);
            });

            polygonDragElement.instance.setLatLngs(updatedLatLngs);
            props.polygonElement.instance.setLatLngs(updatedLatLngs);
            props.polygonElement.instance.fireEvent('update');
            props.polygonElement.instance.fireEvent('update-node-markers');
        }
    };

    polygonDragElement.instance.on('mousedown', onMouseDown);

    props.polygonElement.instance.on('remove', () => {
        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);
        polygonDragElement.instance.off('mousedown', onMouseDown);
        context.map.removeLayer(polygonDragElement.instance);
    });

    props.polygonElement.instance.on('update', () => {
        polygonDragElement.instance.setLatLngs(props.polygonElement.instance.getLatLngs() as L.LatLng[]);
    });

    return polygonDragElement;
};
