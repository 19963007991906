import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Constants from '../../../../constants';
import UriHelper, { useSentinelQuery } from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import {
    actionSentinelBeginBoxSelect,
    actionSentinelClearFeatures,
    actionSentinelEndBoxSelect,
    actionSentinelFetchFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
    actionSentinelSelectAOI,
    actionSentinelSelectFeature,
} from '../../../../store/Map/Sentinel/actions';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../../store/Map/Sentinel/selectors';
import SatelliteLoading from '../satellite-loading';
import SentinelItem from '../Sentinel/sentinel-item';
import LandsatFilter, { DateRange, last30Days } from './landsat-filter';
import { addSentinelParamsToUrl, getDateFromShareLink } from '../../../../lib/sentinel-service';
import { actionFlyTo } from '../../../../store/App/actions';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import DrawerHint from '../../drawer-hint';
import {
    Content,
    LogoContainer,
    Logo,
    ShowMoreButton,
    ShowMoreContainer,
    TeaserIcon,
    TeaserText,
    EmptyTeaserTitle,
    SatelliteSubmitButton,
} from '../satellite-drawer-styles';
import { PulseLoader } from '../../../Shared/pulse-loader';

import { SideDrawerBackButton } from '../../SideDrawer/Shared/side-drawer-buttons';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import DrawerLogin from '../../Shared/drawer-login';

const moment = require('moment');

const LandsatDrawer = () => {
    const bbox = useSelector(selectSentinelSelectedAOI);
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const sentinelError = useSelector(selectSentinelError);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);

    const dispatch = useDispatch();
    const beginBoxSelect = () => dispatch(actionSentinelBeginBoxSelect());
    const endBoxSelect = () => dispatch(actionSentinelEndBoxSelect());
    const resetSelectedAOI = () => dispatch(actionSentinelResetSelectedAOI());
    const resetSelectedSentinelFeature = () => dispatch(actionSentinelResetSelectedFeature());
    const clearSentinelFeatures = () => dispatch(actionSentinelClearFeatures());

    const isLoggedIn = useSelector(selectLoggedIn);

    const [dateRange, setDateRange] = useState<DateRange>(last30Days);
    const [showMoreLoading, setShowMoreLoading] = useState(false);

    const sentinelShareFeature: SentinelFeature | undefined = useSentinelQuery();

    const invalidateSentinelResults = () => {
        clearSentinelFeatures();
        resetSelectedAOI();
        resetSelectedSentinelFeature();
        endBoxSelect();
    };

    useEffect(() => {
        if (sentinelShareFeature) {
            dispatch(actionFlyTo(sentinelShareFeature.bbox, undefined, true));
            dispatch(actionSentinelSelectAOI(sentinelShareFeature.bbox));
            if (UriHelper.tryGetParam('time')) {
                setDateRange(getDateFromShareLink(sentinelShareFeature.date));
            }
            dispatch(actionSentinelSelectFeature(sentinelShareFeature));
        }

        return () => {
            invalidateSentinelResults();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedSentinelFeature) {
            addSentinelParamsToUrl(selectedSentinelFeature);
        }
    }, [selectedSentinelFeature]);

    useEffect(() => {
        if (bbox) {
            dispatch(
                actionSentinelFetchFeatures(
                    bbox,
                    dateRange.startDate,
                    dateRange.endDate,
                    Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8
                )
            );
        }
    }, [bbox, dateRange, dispatch]);

    const handleShowMore = () => {
        setShowMoreLoading(true);
        const newStartDate = moment(dateRange.startDate).subtract(1, 'months').toDate();
        setDateRange({ ...dateRange, startDate: newStartDate });
    };

    useEffect(() => {
        if (sentinelFeatures) {
            setShowMoreLoading(false);
        }
    }, [sentinelFeatures]);

    const reset = () => {
        UriHelper.removeAllSentinelParametersFromURI();
        if (sentinelFeatures) {
            invalidateSentinelResults();
        } else {
            clearSentinelFeatures();
            resetSelectedAOI();
            UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
            endBoxSelect();
            Analytics.Event('Satellite - Landsat', 'Clicked back arrow');
        }
    };

    if (!isLoggedIn) {
        return (
            <DrawerLogin
                logo="/assets/floating-drawer-satellite-icons/satellite-nasa-logo.png"
                onLogin={(isUserLoggedIn) => {
                    if (!isUserLoggedIn) {
                        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
                    }
                }}
                onClickBackButton={reset}
            />
        );
    }

    return (
        <React.Fragment>
            <SideDrawerBackButton onClick={reset} />

            <LogoContainer>
                <Logo src="/assets/floating-drawer-satellite-icons/satellite-nasa-logo.png" />
            </LogoContainer>

            {!sentinelFeatures && !sentinelFeaturesLoading && sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <SatelliteSubmitButton
                        id="draw-aoi-button"
                        onClick={() => {
                            invalidateSentinelResults();
                            Analytics.Event('Satellite - Landsat', 'Clicked redraw area of interest');
                        }}
                    >
                        RESET AREA OF INTEREST
                    </SatelliteSubmitButton>
                </Content>
            ) : null}

            {!sentinelFeatures && !sentinelFeaturesLoading && !sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <TeaserText>Click the button and drag an Area of Interest (AOI).</TeaserText>
                    <SatelliteSubmitButton
                        disabled={isBoxSelectActive}
                        id="draw-aoi-button"
                        onClick={() => {
                            beginBoxSelect();
                            Analytics.Event('Satellite - Landsat', 'Clicked draw area of interest');
                        }}
                    >
                        DRAW AOI
                    </SatelliteSubmitButton>
                    {!isBoxSelectActive && <DrawerHint isActive={true}>Now includes Landsat 9!</DrawerHint>}
                </Content>
            ) : null}

            {sentinelFeaturesLoading && !showMoreLoading && !sentinelError ? (
                <Content>
                    <SatelliteLoading />
                </Content>
            ) : null}

            {sentinelFeatures && !sentinelError && (!sentinelFeaturesLoading || showMoreLoading) ? (
                <React.Fragment>
                    <LandsatFilter
                        dateRange={dateRange}
                        onSelectDateRange={(dateRange) => {
                            setDateRange(dateRange);
                        }}
                    />
                    <LandsatContent>
                        <LandsatItems>
                            {sentinelFeatures.map((t, index) => {
                                return (
                                    <SentinelItem
                                        key={`landsat-feature-${index}`}
                                        feature={t}
                                        selectedFeature={selectedSentinelFeature}
                                    />
                                );
                            })}
                            <ShowMoreContainer>
                                {showMoreLoading ? (
                                    <ShowMoreButton>
                                        <PulseLoader />
                                    </ShowMoreButton>
                                ) : (
                                    <ShowMoreButton
                                        onClick={() => {
                                            handleShowMore();
                                            Analytics.Event('Satellite - Landsat', 'Clicked show more');
                                        }}
                                    >
                                        SHOW MORE
                                    </ShowMoreButton>
                                )}
                            </ShowMoreContainer>
                        </LandsatItems>
                    </LandsatContent>
                </React.Fragment>
            ) : null}
            {sentinelError ? <DrawerHint error>{`${sentinelError}` || sentinelError.message}</DrawerHint> : null}
        </React.Fragment>
    );
};

export default LandsatDrawer;

const LandsatContent = styled(Content)`
    display: flex;
    flex-direction: column;
`;

const LandsatItems = styled.div`
    overflow-y: auto;
    flex-basis: calc(100vh - 250px);

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;
