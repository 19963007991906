import React, { useState } from 'react';
import styled from 'styled-components';
import { SubDomainType } from '../../../api/model';
import ContactUs, { ContactSubject } from '../../Contact/contact-us';
import Analytics from '../../../lib/user-analytics';
import { PlusButton } from '.';

interface PricingCardsProps {
    handleSelectPrice: (priceType: SubDomainType) => void;
}

const PlusPricingCards: React.FC<PricingCardsProps> = (props) => {
    const [showContactForm, setShowContactForm] = useState(false);

    return (
        <PricingCardContainer>
            <PricingCardRow>
                <PricingCardCol>
                    <PlusPricingCard>
                        <PlusCardHeader>
                            <h4>EXPLORER</h4>
                            <h1>FREE</h1>
                            <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
                        </PlusCardHeader>

                        <PricingCardPoints>
                            <li>All your content publicly available on Soar.Earth</li>
                            <li>2Gb Storage limit</li>
                            <li>Upload any GeoTIFF / ECW / JP2 map or image</li>
                            <li>Free, view and download time satellite imagery from Sentinel or Landsat</li>
                            <li>Access to high resolution satellite imagery on-demand</li>
                        </PricingCardPoints>

                        <PricingCardNoticeText>Great for hobbyists and those starting out.</PricingCardNoticeText>

                        <PricingButtonContainer>
                            <PlusButton
                                label="START NOW"
                                handleOnClick={() => {
                                    props.handleSelectPrice(SubDomainType.Free);
                                    Analytics.Event('Soar Plus', 'Clicked Free Card');
                                }}
                            />
                        </PricingButtonContainer>

                        <p className="plus-offer">Completely free. No credit card required.</p>
                    </PlusPricingCard>
                </PricingCardCol>

                <PricingCardCol>
                    <PlusPricingCard>
                        <PlusCardHeader>
                            <h4>PROFESSIONAL</h4>
                            <h1>US$50.00</h1>
                            <h5>per month</h5>
                            <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
                        </PlusCardHeader>

                        <PricingCardPoints>
                            <li>Your own custom subdomain: YOU.SOAR.EARTH</li>
                            <li>
                                <b>Custom branding on all publicly shared content. Make your business standout!</b>
                            </li>
                            <li>100Gb storage limit</li>
                            <li>Dedicated user and data management dashboard</li>
                            <li>Decide who can access your data. Make it public or private</li>
                            <li>Add up to 20 users to your subdomain</li>
                            <li>Upload any GeoTIFF / ECW / JP2 map or image</li>
                            <li>Free view and download satellite imagery from Sentinel or Landsat</li>
                            <li>Access to high resolution satellite imagery on-demand</li>
                        </PricingCardPoints>

                        <PricingCardNoticeText>
                            Great for small businesses. Your own portal for your own map and imagery content.
                        </PricingCardNoticeText>

                        <PricingButtonContainer>
                            <PlusButton
                                label="START NOW"
                                handleOnClick={() => {
                                    props.handleSelectPrice(SubDomainType.Pro);
                                    Analytics.Event('Soar Plus', 'Clicked Pro Card');
                                }}
                            />
                        </PricingButtonContainer>

                        <p className="plus-offer">Billed upfront per month. Cancel anytime.</p>
                    </PlusPricingCard>
                </PricingCardCol>

                <PricingCardCol>
                    <PlusPricingCard>
                        <PlusCardHeader>
                            <h4>ENTERPRISE</h4>
                            <h3>FROM</h3>
                            <h1>US$250.00</h1>
                            <h5>per month</h5>
                            <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
                        </PlusCardHeader>

                        <PricingCardPoints>
                            <li>Your own custom subdomain: YOU.SOAR.EARTH</li>
                            <li>
                                <b>Custom branding on all publicly shared content. Make your business standout!</b>
                            </li>
                            <li>Unlimited storage</li>
                            <li>Unlimited map hits, scalability and users</li>
                            <li>Dedicated cluster</li>
                            <li>Dedicated customer support</li>
                            <li>Customisation options for external use</li>
                            <li>Free access to on-demand high resolution satellite imagery</li>
                            <li>Dedicated user and data management dashboard</li>
                            <li>Decide you can access your data. Make it public or private</li>
                            <li>Upload any GeoTIFF / ECW / JP2 map or image</li>
                            <li>Free view and download satellite imagery from Sentinel or Landsat</li>
                        </PricingCardPoints>

                        <PricingCardNoticeText>
                            Made for larger organisations with dedicated and custom mapping requirements.
                        </PricingCardNoticeText>

                        <PricingButtonContainer>
                            <PlusButton
                                label="TALK TO OUR EXPERTS"
                                handleOnClick={() => {
                                    setShowContactForm(true);
                                    Analytics.Event('Soar Plus', 'Clicked Enterprise Card');
                                }}
                            />
                        </PricingButtonContainer>

                        <p className="plus-offer">
                            Annual discount packages available. 30 day account. Managed invoicing for projects.
                        </p>
                    </PlusPricingCard>
                </PricingCardCol>
            </PricingCardRow>

            <ContactUs
                isOpen={showContactForm}
                toggle={() => setShowContactForm(!showContactForm)}
                customMessage="Tell us about your needs and we will create your custom solution"
                askForIndustry={true}
                preselectContactSubject={ContactSubject.SoarEnterprise}
            />
        </PricingCardContainer>
    );
};

export default PlusPricingCards;

const PricingCardContainer = styled.div`
    margin: 80px auto;
    width: 80%;
    max-width: 1400px;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const PricingCardRow = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    max-width: fit-content;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
    }
`;

const PricingCardCol = styled.div`
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    margin: 10px;
    width: 33.33333333%;

    @media only screen and (max-width: 600px) {
        width: auto;
    }
`;

const PlusPricingCard = styled.div`
    color: white;
    padding: 20px;
    text-align: center;

    p {
        color: white;

        &.plus-offer {
            opacity: 0.6;
            padding-bottom: 50px;
        }

        @media only screen and (max-width: 600px) {
            font-size: 13px;
        }
    }
`;

const PricingCardNoticeText = styled.p`
    color: white;
    min-height: 42px;
`;

const PlusCardHeader = styled.div`
    color: white;
    text-align: left;

    @media only screen and (max-width: 600px) {
        text-align: center;
    }

    h1 {
        color: inherit;
        text-align: inherit;
        font-weight: 700;
        font-size: 30px;
    }

    h3 {
        color: inherit;
        position: absolute;
        font-size: 14px;
        line-height: 2px;

        @media only screen and (max-width: 600px) {
            position: inherit;
        }
    }

    h4 {
        color: inherit;
        text-align: inherit;
        opacity: 0.6;
        font-weight: 700;
        font-size: 18px;
        padding: 10px 0px;

        @media only screen and (max-width: 600px) {
            padding: 15px 0px;
        }
    }

    h5 {
        color: inherit;
        opacity: 0.6;
        position: absolute;
        font-size: 13px;
        line-height: 2px;

        @media only screen and (max-width: 600px) {
            position: inherit;
        }
    }
`;

const PricingCardPoints = styled.ul`
    text-align: left;
    height: 340px;
    padding-inline-start: 20px;

    li {
        color: white;
        list-style-type: square;

        @media only screen and (max-width: 600px) {
            font-size: 13px;
        }
    }

    @media only screen and (max-width: 600px) {
        height: auto;
    }
`;

const Divider = styled.img`
    margin: 30px 0px 50px 0px;
`;

const PricingButtonContainer = styled.div`
    display: block;
    text-align: center;
    margin: 50px 0px 20px 0px;
`;
