import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class CookieHelper {
    private static UTM_KEY = 'soar-utm';

    public static setTrackerCode(trackerCode: string) {
        cookies.set(CookieHelper.UTM_KEY, trackerCode, { path: '/' });
    }

    public static getTrackerCode(): string | undefined {
        return cookies.get(CookieHelper.UTM_KEY);
    }
}
