import PolygonPath from '../../../components/MapView/Annotations/Polygon/polygon';
import { actionTypes as at } from './constants';

export const setPolygonsAction = (polygons: PolygonPath[]) => {
    return {
        type: at.SET_POLYGONS,
        payload: { polygons: polygons },
    };
};

export const setPolygonOptionsAction = (options: L.PolylineOptions) => {
    return {
        type: at.SET_POLYGON_OPTIONS,
        payload: options,
    };
};

export const setPolygonAddAction = (polygon: PolygonPath) => {
    return {
        type: at.SET_POLYGON_ADD,
        payload: polygon,
    };
};

export const setPolygonUpdateAction = (polygon: PolygonPath) => {
    return {
        type: at.SET_POLYGON_UPDATE,
        payload: polygon,
    };
};

export const setPolygonDeleteAction = (polygon: PolygonPath) => {
    return {
        type: at.SET_POLYGON_DELETE,
        payload: polygon,
    };
};
