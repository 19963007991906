import { AppState } from '../../root-reducer';

export const selectDroneImagePreviewUrl = (state: AppState) => state.uploadDomain.droneImagePreviewUrl;

export const selectDroneImagePositions = (state: AppState) => state.uploadDomain.droneImagePositions;

export const selectDroneImageDistortionTool = (state: AppState) => state.uploadDomain.droneImageDistortionTool;

export const selectDroneImageOpacity = (state: AppState) => state.uploadDomain.droneImageOpacity;

export const selectDroneImageDistortionCorners = (state: AppState) => state.uploadDomain.droneImageDistortionCorners;

export const selectDroneImageManualPosition = (state: AppState) => state.uploadDomain.droneImageManualPosition;
