import styled from 'styled-components';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../soar-modal';
import { StyledButton, StyledTransButton } from '../styled-button';

interface ConfirmModalProps {
    children: React.ReactNode;
    title?: string | JSX.Element;
    isOpen: boolean;
    onToggle: () => void;
    continueText?: string;
    onContinue: () => void;
    exitText?: string;
    onExit?: () => void;
}

const InfoModal = (props: ConfirmModalProps) => {
    return (
        <SoarModal title={props.title ?? ''} isOpen={props.isOpen} toggle={props.onToggle} width="600px">
            <StyledModalBody>{props.children}</StyledModalBody>
            <StyledModalFooter>
                {props.onExit && <ExitButton onClick={props.onExit}>{props.exitText ?? 'LEAVE'}</ExitButton>}
                <StyledButton onClick={props.onContinue}>{props.continueText ?? 'CONTINUE'}</StyledButton>
            </StyledModalFooter>
        </SoarModal>
    );
};

export default InfoModal;

const ExitButton = styled(StyledTransButton)`
    padding: 8px 25px;
`;
