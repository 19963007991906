import DrawerHint from '../drawer-hint';
import { SatelliteSubmitButton, Content, TeaserIcon, TeaserTitle } from './satellite-drawer-styles';

interface SatelliteDrawerAOIErrorProps {
    title: string;
    icon: string;
    onClick: () => void;
    error: string;
    id?: string;
}

export const SatelliteDrawerAOIError = (props: SatelliteDrawerAOIErrorProps) => {
    const { title, icon, onClick, error } = props;
    return (
        <Content>
            <TeaserTitle>{title}</TeaserTitle>
            <TeaserIcon src={icon} />
            <SatelliteSubmitButton onClick={onClick}>RESET AREA OF INTEREST</SatelliteSubmitButton>
            <DrawerHint error>{error}</DrawerHint>
        </Content>
    );
};
