import Api from './api';

export default class ApiContactUs extends Api {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static postContactUs(email: string, subject: string, message: string, industry: string): Promise<any> {
        const data = {
            email: email,
            subject: subject,
            message: message,
            industry: industry,
        };
        return this.axios.post('/v1/contact-us', data).then((res) => {
            return res.data;
        });
    }
}
