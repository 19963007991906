import L from 'leaflet';
import { defaultZIndex } from '../layers-util';
import { MeasurementUnits } from '../Measurement/measurement-toolbar-units';
import { MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import { handleDeserializePatternFill, handlePattternIdFromPatternFillUrl } from '../FillPattern/pattern-util';

export type RectangleOtherOptions = {
    pattern?: string;
};

export default class Rectangle {
    annotationType: string;
    id: string;
    bounds: L.LatLngBounds;
    options: L.PolylineOptions;
    fillPattern?: string;
    showArea: boolean;
    showLength: boolean;
    units: MeasurementUnits;
    labelColor: MeasurementLabelColor;
    zIndex: number;

    constructor(
        bounds: L.LatLngBounds,
        options: L.PolylineOptions,
        showArea: boolean,
        showLength: boolean,
        units: MeasurementUnits,
        labelColor: MeasurementLabelColor,
        fillPattern?: string
    ) {
        this.annotationType = 'Rectangle';
        this.bounds = bounds;
        this.options = options;
        this.fillPattern = fillPattern;
        this.showArea = showArea;
        this.showLength = showLength;
        this.units = units;
        this.labelColor = labelColor;
    }

    static serialize(rectangle: Rectangle): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [rectangle.bounds.getNorthWest().lng, rectangle.bounds.getNorthWest().lat],
                        [rectangle.bounds.getNorthEast().lng, rectangle.bounds.getNorthEast().lat],
                        [rectangle.bounds.getSouthEast().lng, rectangle.bounds.getSouthEast().lat],
                        [rectangle.bounds.getSouthWest().lng, rectangle.bounds.getSouthWest().lat],
                        [rectangle.bounds.getNorthWest().lng, rectangle.bounds.getNorthWest().lat],
                    ],
                ],
            },
            properties: {
                annotationType: 'Rectangle',
                id: rectangle.id,
                options: rectangle.options,
                fillPattern: rectangle.fillPattern ? rectangle.fillPattern : undefined,
                zIndex: rectangle.zIndex || defaultZIndex,
                showArea: rectangle.showArea,
                showLength: rectangle.showLength,
                units: rectangle.units,
                labelColor: rectangle.labelColor,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): Rectangle {
        const boundingBox = new L.LatLngBounds(
            new L.LatLng(json.geometry.coordinates[0][0][1], json.geometry.coordinates[0][0][0]),
            new L.LatLng(json.geometry.coordinates[0][2][1], json.geometry.coordinates[0][2][0])
        );

        if (
            json.properties.options?.fillColor &&
            handlePattternIdFromPatternFillUrl(json.properties?.options?.fillColor) !== 'none'
        ) {
            handleDeserializePatternFill(json);
        }

        const { id, fillPattern, zIndex, showArea, showLength, units, labelColor } = json.properties;

        return {
            annotationType: 'Rectangle',
            id: id,
            bounds: boundingBox,
            options: json.properties.options,
            fillPattern: fillPattern ? fillPattern : undefined,
            zIndex: zIndex || defaultZIndex,
            showArea: showArea,
            showLength: showLength,
            units: units,
            labelColor: labelColor,
        };
    }
}

export const defaultRectangleOptions: L.PolylineOptions = {
    smoothFactor: 1.0,
    noClip: false,
    stroke: true,
    color: '#eed926',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: '#eed926',
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};
