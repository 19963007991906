import {
    AuthFlowType,
    AuthenticationResultType,
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    InitiateAuthCommandOutput,
    ChangePasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import Constants from '../constants';

// aliased to limit the imported types/constants to come from this file only
export type CognitoAuthResultType = AuthenticationResultType;

export default class CognitoUtil {
    private static cognitoClient = new CognitoIdentityProviderClient({ region: Constants.COGNITO_REGION });
    private static cognitoClientId = Constants.COGNITO_CLIENT_ID;

    public static async login(username: string, password: string): Promise<CognitoAuthResultType> {
        const command = new InitiateAuthCommand({
            ClientId: this.cognitoClientId,
            AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password,
            },
        });

        const loginReponse: InitiateAuthCommandOutput = await this.cognitoClient.send(command);

        return loginReponse?.AuthenticationResult as CognitoAuthResultType;
    }

    public static async refreshToken(refreshToken: string): Promise<CognitoAuthResultType> {
        const command = new InitiateAuthCommand({
            ClientId: this.cognitoClientId,
            AuthFlow: AuthFlowType.REFRESH_TOKEN,
            AuthParameters: {
                REFRESH_TOKEN: refreshToken,
            },
        });

        const refreshTokenReponse: InitiateAuthCommandOutput = await this.cognitoClient.send(command);

        return refreshTokenReponse?.AuthenticationResult as CognitoAuthResultType;
    }

    public static async updatePassword(oldPassword: string, newPasword: string, accessToken: string) {
        const command = new ChangePasswordCommand({
            PreviousPassword: oldPassword,
            ProposedPassword: newPasword,
            AccessToken: accessToken,
        });

        await this.cognitoClient.send(command);
    }
}
