import styled from 'styled-components';

interface PlusCardProps {
    img: string;
    text: string;
}

export const PlusCard = ({ img, text }: PlusCardProps) => {
    return (
        <PlusCardContainer>
            <img src={img} alt={text} loading="lazy" />
            <p>{text}</p>
        </PlusCardContainer>
    );
};

const PlusCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    color: white;

    img {
        height: 60px;
        margin-bottom: 10px;
        margin-top: 0px;
    }

    p {
        text-transform: uppercase;
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
        margin: 10px 0px;
        max-width: fit-content;
        text-align: center;
        color: inherit;
    }
`;
