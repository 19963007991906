import {
    LeafletContextInterface,
    createElementHook,
    createElementObject,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import { LatLng } from 'leaflet';
import { ImageOverlayProps } from 'react-leaflet';
import { imageTransform } from './distorted-image-overlay-leaflet';

interface DistortedImageOverlayProps {
    url: string;
    anchors: LatLng[]; //[topLeft, topRight, bottomRight, bottomLeft]
    opacity?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistortedImageOverlayElement = any;

const createDistortedImageOverlay = (props: DistortedImageOverlayProps, context: LeafletContextInterface) => {
    const element = createElementObject<DistortedImageOverlayElement, ImageOverlayProps>(
        imageTransform(props.url, props.anchors, {
            opacity: props.opacity || 1.0,
        }),
        context
    );
    return element;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
const updateDistortedImageOverlay = (
    element: DistortedImageOverlayElement,
    props: DistortedImageOverlayProps,
    prevProps: DistortedImageOverlayProps
) => {
    if (props !== prevProps) {
        element.setUrl(props.url);

        // Recalculation needs to happen after the image loads
        element.on('load', () => {
            element.setAnchors(props.anchors);
        });
    }
};

const useDistortedImageOverlay = createElementHook<
    DistortedImageOverlayElement,
    DistortedImageOverlayProps,
    LeafletContextInterface
>(createDistortedImageOverlay, updateDistortedImageOverlay);

const DistortedImageOverlay = (props: DistortedImageOverlayProps) => {
    const context = useLeafletContext();
    const distortedImageOverlayTool = useDistortedImageOverlay(props, context);
    useLayerLifecycle(distortedImageOverlayTool.current, context);
    return null;
};

export default DistortedImageOverlay;
