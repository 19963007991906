import * as React from 'react';
import { Button } from 'reactstrap';

interface ProgressButtonProps {
    text: string;
    isSubmitting: boolean;
    isDisabled: boolean;
    onSubmit?: () => void;
    submittingText?: string;
    customClassName?: string;
}

const ProgressButton: React.FC<ProgressButtonProps> = (props) => {
    return (
        <Button disabled={props.isDisabled} className={props.customClassName || ''} onClick={props.onSubmit}>
            {props.isSubmitting ? (
                <span>
                    <i className="fa fa-spinner fa-spin" />
                    {props.submittingText || ''}
                </span>
            ) : (
                <span>{props.text}</span>
            )}
        </Button>
    );
};

export default ProgressButton;
