import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import {
    actionSatelliteBeginBoxSelect,
    actionSatelliteEndBoxSelect,
    actionSatelliteResetAOI,
} from '../../../../store/Map/SatelliteArchive/actions';
import { selectSatelliteAOI, selectIsSatelliteBoxSelectActive } from '../../../../store/Map/SatelliteArchive/selectors';
import { ValidArchiveAOIParameters } from './satellite-archive';
import { SatelliteSubmitButton, TeaserIcon, TeaserText, TeaserTitle } from '../satellite-drawer-styles';
import SatelliteArchiveSelectAOIError from './satellite-archive-select-aoi-error';

interface SatelliteArchiveSelectAOIProps {
    title: string;
    analyticsCategory: string;
    validAOIParameters: ValidArchiveAOIParameters;
}

const SatelliteArchiveSelectAOI = (props: SatelliteArchiveSelectAOIProps) => {
    const dispatch = useDispatch();
    const beginBoxSelect = () => dispatch(actionSatelliteBeginBoxSelect());
    const endBoxSelect = () => dispatch(actionSatelliteEndBoxSelect());
    const resetAOI = () => dispatch(actionSatelliteResetAOI());

    const isBoxSelectActive = useSelector(selectIsSatelliteBoxSelectActive);
    const selectedAOI = useSelector(selectSatelliteAOI);

    return (
        <React.Fragment>
            <TeaserTitle>{props.title}</TeaserTitle>
            <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
            <TeaserText hidden={selectedAOI !== undefined}>Click the button and draw an Area of Interest.</TeaserText>
            <TeaserText hidden={selectedAOI !== undefined}>Let's see what we can find!</TeaserText>

            <SatelliteArchiveSelectAOIError
                clearBoxSelect={() => {
                    endBoxSelect();
                    resetAOI();
                }}
                selectedAOI={selectedAOI}
                validAOIParameters={props.validAOIParameters}
                analyticsCategory={props.analyticsCategory}
            />

            <SatelliteSubmitButton
                id="draw-aoi-button"
                disabled={isBoxSelectActive}
                hidden={selectedAOI !== undefined}
                onClick={() => {
                    beginBoxSelect();
                    Analytics.Event(props.analyticsCategory, 'Clicked start draw aoi');
                    Analytics.Conversion(ConversionEvent.DRAW_AOI);
                }}
            >
                DRAW AOI
            </SatelliteSubmitButton>
        </React.Fragment>
    );
};

export default SatelliteArchiveSelectAOI;
