import { actionTypes as at } from './constants';
import { OrderDetailsDTO, OrderDTO } from '../../../api/model';
import { Action } from '../../root-reducer';

export type ORDER_TYPE = OrderDetailsDTO | 'FETCHING' | 'ERROR';

interface StoreMapTasks {
    orders: OrderDTO[] | undefined;
    orderDetailsList: { [id: number]: ORDER_TYPE };
    activeOrder: undefined | ORDER_TYPE;
}

const initialState: StoreMapTasks = {
    orders: undefined,
    orderDetailsList: {},
    activeOrder: undefined,
};

export default (state: StoreMapTasks = initialState, action: Action): StoreMapTasks => {
    switch (action.type) {
        case at.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload,
            };
        case at.FETCH_ORDER_BY_ID: {
            const id = action.payload as number;
            return {
                ...state,
                orderDetailsList: {
                    ...state.orderDetailsList,
                    [id]: 'FETCHING',
                },
            };
        }
        case at.FETCH_ORDER_BY_ID_SUCCESS: {
            const order = action.payload as OrderDetailsDTO;
            return {
                ...state,
                orderDetailsList: {
                    ...state.orderDetailsList,
                    [order.id]: order,
                },
            };
        }
        case at.FETCH_ORDER_BY_ID_ERROR: {
            const id = action.payload as number;
            return {
                ...state,

                orderDetailsList: {
                    ...state.orderDetailsList,
                    [id]: 'ERROR',
                },
            };
        }
        case at.ORDER_SET_ACTIVE:
            return {
                ...state,
                activeOrder: action.payload,
            };

        default:
            return state;
    }
};
