import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import ApiListings from '../../../api/api-listings';
import { ListingDTO, PurchasedItemInfo } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import Analytics from '../../../lib/user-analytics';
import PurchaseListingDownloadButton from './purchase-listing-download-button';

interface TransactionPurchaseItemProps {
    purchase: PurchasedItemInfo;
}

export const TransactionPurchaseItem = ({ purchase }: TransactionPurchaseItemProps) => {
    const [listing, setListing] = useState<ListingDTO | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [link, setLink] = useState<string>('');

    useEffect(() => {
        if (purchase.listingId) {
            ApiListings.getListing(purchase.listingId)
                .then((listing) => {
                    setListing(listing);
                    const listingTitle = ListingHelper.getTitleFromListing(listing);
                    setTitle(listingTitle);
                    if (listing.id) {
                        setLink(`/maps/${listing.id}`);
                    }
                })
                .catch((err) => {
                    setError(err);
                });
        }
    }, [purchase.listingId]);

    const handleOpenImage = () => {
        if (listing?.filehash) {
            window.open('/maps/' + listing.id, '_blank');
            Analytics.Event('My Dashboard', 'Clicked open image from transaction history', listing.id);
        }
    };

    return (
        <>
            <PurchaseImageTd>
                <PurchaseImgDiv>
                    {error ? (
                        <img src="/assets/image-unavailable/preview_fallback.svg" />
                    ) : listing?.id ? (
                        <img
                            src={ListingHelper.getPreviewUrlForListing(listing.id, 'small')}
                            onClick={handleOpenImage}
                        />
                    ) : (
                        <Spinner color="warning" />
                    )}
                </PurchaseImgDiv>
            </PurchaseImageTd>
            <PurchaseTitleTd>
                <TitleLink href={link} target="_blank">
                    {title}
                </TitleLink>
            </PurchaseTitleTd>
            {listing && (
                <PurchaseButtonTd>
                    <PurchaseListingDownloadButton listing={listing} />
                </PurchaseButtonTd>
            )}
        </>
    );
};

const PurchaseImageTd = styled.td`
    border-top: none !important;
    cursor: pointer;
`;

const PurchaseImgDiv = styled.div`
    width: 85px !important;
    border-top: none !important;
    border-bottom: none;
`;

const PurchaseTitleTd = styled.td`
    font-size: inherit;
    width: 100%;
    border-top: none !important;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (max-width: 600px) {
        font-size: 12px;
    }
`;

const PurchaseButtonTd = styled.td`
    border-top: none !important;
`;

const TitleLink = styled.a`
    color: white;
    text-decoration: none !important;
    &:visited,
    &:hover {
        color: white !important;
    }
`;
