import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveOrder } from '../../../../store/Map/Tasks/selectors';
import SideDrawerLoader from '../Shared/side-drawer-loader';
import styled from 'styled-components';

const SideDrawerTask = () => {
    const listing = useSelector(selectActiveOrder);

    if (listing === 'FETCHING') {
        return <SideDrawerLoader />;
    }

    if (listing === 'ERROR') {
        return <div>ERROR</div>;
    }

    return (
        <React.Fragment>
            <OrderNumber title={listing?.orderNumber}>{listing?.orderNumber}</OrderNumber>
            {listing?.company && <CompanyName title={listing?.company}>{listing?.company}</CompanyName>}
        </React.Fragment>
    );
};

export default SideDrawerTask;

const CompanyName = styled.span`
    display: flex;
    flex-direction: row;
    margin: 0px 0px -7px 12px;
    padding: 0 6px 4px 0;
    color: white;
    font-size: 16px;
`;

const OrderNumber = styled.h1`
    flex: 0 1 auto;
    margin: 12px;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 30px;
    max-width: 440px;
    -webkit-line-clamp: 3;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    line-height: 3rem;
    word-break: break-word;
    hyphens: auto;
`;
