import { useState } from 'react';
import styled from 'styled-components';
import { PlusButton } from '.';
import Analytics from '../../../lib/user-analytics';
import ContactUs, { ContactSubject } from '../../Contact/contact-us';

export const PlusContactSection = () => {
    const [showContactForm, setShowContactForm] = useState(false);
    return (
        <>
            <PlusContactSectionContainer>
                <h1>HAVE A QUESTION?</h1>
                <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
                <p>We're here to help.</p>
                <p>
                    Reach out to us anytime and you'll be contacted within two business days by one of our dedicated
                    team members.
                </p>
                <PricingButtonContainer>
                    <PlusButton
                        label="CONTACT US"
                        handleOnClick={() => {
                            setShowContactForm(true);
                            Analytics.Event('Soar Plus', 'Clicked plus page contact us');
                        }}
                    />
                </PricingButtonContainer>
            </PlusContactSectionContainer>

            <ContactUs
                isOpen={showContactForm}
                toggle={() => setShowContactForm(!showContactForm)}
                customMessage="Tell us about your needs and we will create your custom solution"
                askForIndustry={true}
                preselectContactSubject={ContactSubject.SoarEnterprise}
            />
        </>
    );
};

const PlusContactSectionContainer = styled.div`
    margin: 80px;
    border-radius: 6px;
    border: 1px solid white;
    padding: 70px 170px 45px 170px;
    text-align: center;
    color: white;

    @media only screen and (max-width: 600px) {
        margin: 10px;
        padding: 50px 0px 40px 0px;
    }

    h1 {
        font-size: 30px;
        font-weight: 700;
        color: inherit;
    }

    p {
        color: white;
        font-size: 20px;

        @media only screen and (max-width: 600px) {
            font-size: 16px;
            padding: 0px 10px;
        }
    }
`;

const Divider = styled.img`
    margin: 20px 0px;
`;

const PricingButtonContainer = styled.div`
    display: block;
    text-align: center;
    margin: 30px 0px 0px 0px;
`;
