import React, { useEffect } from 'react';
import styled from 'styled-components';
import MobileUseDesktopContent from './mobile-use-desktop-content';

interface MobileSuggestDesktopNoticeProps {
    setVisitedRoute?: () => void;
}

const MobileSuggestDesktopNotice = (props: MobileSuggestDesktopNoticeProps) => {
    useEffect(() => {
        return () => {
            if (props.setVisitedRoute) props.setVisitedRoute();
        };
    }, [props]);

    return (
        <MobileUseDesktopContainer>
            <MobileUseDesktopContent />
        </MobileUseDesktopContainer>
    );
};

export default MobileSuggestDesktopNotice;

const MobileUseDesktopContainer = styled.div`
    background-color: rgb(34, 34, 34);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    overflow-y: auto;
    inset: auto; // identifies position: absolute as top: 0, left: 0, right: 0, bottom: 0 ...
`;
