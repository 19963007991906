import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfig } from '../../App/selectors';
import { selectBasemap } from './selector';
import { Basemap, getBasemap } from './model';
import { actionSetBasemap } from './actions';
import { useLocation } from 'react-router';
import UriHelper from '../../../lib/uri-helper';

const COUNTRY_CODES_TO_IGNORE = ['CN'];

const isGoogleBasemap = (basemap: Basemap) => {
    return [Basemap.GOOGLE_SATELLITE, Basemap.GOOGLE_HYBRID, Basemap.GOOGLE_STREET].includes(basemap);
};

const getEffectiveBasemap = (countryCode: string, selectedBasemap: Basemap | undefined, defaultBasemap: Basemap) => {
    if (COUNTRY_CODES_TO_IGNORE.includes(countryCode) && selectedBasemap && isGoogleBasemap(selectedBasemap)) {
        return Basemap.OPEN_ELEVATION;
    }
    return selectedBasemap ?? defaultBasemap;
};

export const useBasemap = () => {
    const config = useSelector(selectConfig);
    const dispatch = useDispatch();
    const selectedBasemap = useSelector(selectBasemap);
    const countryCode = config.COUNTRY_CODE ?? '';
    const defaultBasemap = COUNTRY_CODES_TO_IGNORE.includes(countryCode)
        ? Basemap.OPEN_ELEVATION
        : Basemap.GOOGLE_HYBRID;
    const url = useLocation();

    const setBasemap = useCallback(
        (basemap: Basemap | undefined) => {
            if (COUNTRY_CODES_TO_IGNORE.includes(countryCode) && basemap && isGoogleBasemap(basemap)) {
                basemap = Basemap.OPEN_ELEVATION;
            }
            dispatch(actionSetBasemap(basemap));
        },
        [dispatch, countryCode]
    );

    useEffect(() => {
        // Handle adding or removing basemap from URL
        if (selectedBasemap && selectedBasemap !== defaultBasemap) {
            UriHelper.addParameterToUri(UriHelper.BASEMAP_URI_KEY, selectedBasemap);
        } else if (selectedBasemap === defaultBasemap) {
            UriHelper.removeParameterFromUri('basemap');
        }

        // Handle default basemap from URL
        if (!selectedBasemap) {
            const params = new URLSearchParams(window.location.search);
            const urlSelectedBaseMap = params.get(UriHelper.BASEMAP_URI_KEY);

            if (urlSelectedBaseMap) {
                const bm = getBasemap(decodeURI(urlSelectedBaseMap));
                setBasemap(bm);
            } else {
                setBasemap(defaultBasemap);
            }
        }
    }, [url, selectedBasemap, defaultBasemap, setBasemap]);

    // Apply the basemap based on the country code
    const effectiveBasemap = getEffectiveBasemap(countryCode, selectedBasemap, defaultBasemap);

    return { basemap: effectiveBasemap, setBasemap };
};
