import React from 'react';
import { useSelector } from 'react-redux';
import { selectFreehandDraws } from '../../../store/Annotations/Freehand/selectors';
import FreehandPolylineAnnotation from '../../MapView/Annotations/FreehandPolyline/freehand-polyline-annotation';

const MobileFreehandDraw = () => {
    const freehandDraws = useSelector(selectFreehandDraws);

    return (
        <React.Fragment>
            {freehandDraws.map((annotation) => (
                <FreehandPolylineAnnotation
                    key={`
                        ${annotation.id}-
                        ${annotation.options.color}-
                        ${annotation.options.dashArray}-
                        ${annotation.options.dashOffset}-
                        ${annotation.options.weight}-
                        ${annotation.zIndex}-
                        ${annotation.positions?.toString()}-
                        ${annotation.bounds?.toBBoxString()}-
                    `}
                    freehandPolyline={annotation}
                    isSelected={false}
                    onUpdate={() => ''}
                    options={annotation.options}
                    positions={annotation.positions}
                />
            ))}
        </React.Fragment>
    );
};

export default MobileFreehandDraw;
