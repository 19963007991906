import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import Marker from './marker';
import MarkerAnnotation from './marker-annotation';
import MarkerBuilder from './marker-builder';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import { setMarkerAddAction, setMarkerUpdateAction } from '../../../../store/Annotations/Marker/actions';
import { selectMarkers } from '../../../../store/Annotations/Marker/selectors';
import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import { useDrawToolUpdated } from '../use-draw-tool-updated';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';

const MarkerControl = (props: AnnotationControlProps) => {
    const [isCreateActive, setIsCreateActive] = useState(false);

    const dispatch = useDispatch();

    const markers = useSelector(selectMarkers);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const onUpdateMarker = useDrawToolUpdated((marker: Marker) => {
        dispatch(setMarkerUpdateAction(marker));
    });

    const isInteractive = !props.locked && props.visible;

    return (
        <React.Fragment>
            <AnnotationControl
                active={isCreateActive}
                disabled={props.disabled}
                layout="middle"
                visible={props.visible}
                iconDark="/assets/annotations/black-annotations/icon-marker-black.svg"
                iconLight="/assets/annotations/icon-marker.svg"
                name="Marker"
                onClick={() => {
                    lockAnnotationsEvent();
                    setIsCreateActive(true);
                }}
                dataTestId="annotation-control-marker"
            />

            {isCreateActive ? (
                <MarkerBuilder
                    zIndex={currentZIndex}
                    onCreateMarker={(marker: Marker) => {
                        setIsCreateActive(false);
                        dispatch(setMarkerAddAction(marker));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(marker));
                        });
                        DrawToolAnalytics.add('marker');
                    }}
                    onCancelBuild={() => {
                        setIsCreateActive(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {!isInteractive &&
                markers.map((marker) => {
                    return (
                        <MarkerAnnotation
                            key={`
                                ${marker.id}-locked-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${marker.color}-${marker.iconName}-
                                ${marker.zoomBase}-
                                ${marker.position.lat}-
                                ${marker.position.lng}-
                                ${marker.zIndex}-
                                ${marker.iconName}
                            `}
                            marker={marker}
                            isSelected={false}
                            isDisabled={true}
                            onUpdate={() => ''}
                        />
                    );
                })}

            {isInteractive &&
                markers.map((marker) => {
                    return (
                        <MarkerAnnotation
                            key={`
                                ${marker.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${marker.color}-${marker.iconName}-
                                ${marker.zoomBase}-
                                ${marker.position.lat}-
                                ${marker.position.lng}-
                                ${marker.zIndex}-
                                ${marker.iconName}
                            `}
                            marker={marker}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === marker.id : false}
                            onUpdate={onUpdateMarker}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default MarkerControl;
