import {
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
    MeasurementToolbarSelect,
    MeasurementToolbarSelectOption,
} from './measurement-toolbar';

export type MeasurementUnits = 'metric' | 'imperial';

interface MeasurementToolbarUnitsProps {
    units: MeasurementUnits;
    onChangeUnits: (units: MeasurementUnits) => void;
}

const MeasurementToolbarUnits = (props: MeasurementToolbarUnitsProps) => {
    return (
        <MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionLabel>Units:</MeasurementToolbarOptionLabel>
            <MeasurementToolbarSelect
                onChange={(e) => {
                    props.onChangeUnits(e.target.value as 'metric' | 'imperial');
                }}
                value={props.units}
            >
                <MeasurementToolbarSelectOption value="metric">Metric</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="imperial">Imperial</MeasurementToolbarSelectOption>
            </MeasurementToolbarSelect>
        </MeasurementToolbarOptionContainer>
    );
};

export default MeasurementToolbarUnits;
