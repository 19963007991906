import L from 'leaflet';
import { Point, LineString, Polygon } from '@turf/helpers';
import pointToLineDistance from '@turf/point-to-line-distance';
import buffer from '@turf/buffer';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import area from '@turf/area';
import Marker from './Marker/marker';
import MilitaryMarker from './MilitaryMarker/military-marker';
import { Feature, Position } from 'geojson';
import Coordinate from './Coordinate/coordinate';
import { getCoordinateSVGPadding } from './Coordinate/coordinate-util';
import { createMilitaryMarkerSVGPreview } from './MilitaryMarker/military-marker-util';

/**
 * Returns true if the point is within `pixelThreshold` screen pixels from the polyline
 */
export const isPointCloseToPolyline = (
    map: L.Map,
    point: L.LatLng,
    polylinePositions: L.LatLng[],
    pixelThreshold = 10
): boolean => {
    // Calculate distance between point and polyline
    const turfPoint: Point = { type: 'Point', coordinates: [point.lng, point.lat] };
    const turfPolyline: LineString = {
        type: 'LineString',
        coordinates: polylinePositions.map((t) => [t.lng, t.lat]),
    };
    const distance = pointToLineDistance(turfPoint, turfPolyline, { units: 'meters' });

    // Calculate distance of one pixel at this zoom level
    const p1 = map.latLngToContainerPoint(point);
    const p2 = new L.Point(p1.x + 1, p1.y + 1);
    const latLng2 = map.containerPointToLatLng(p2);
    const distanceInMetersOfOnePixel = point.distanceTo(latLng2);

    return distance < distanceInMetersOfOnePixel * pixelThreshold;
};

/**
 * Returns true if the bounds occupies enough screen space to be considered a click candidate
 * The TextBox uses this to prevent creating an editable textarea that is small enough to cause errors
 */
export const isBoundsLargeEnoughToBeClickCandidate = (
    map: L.Map,
    bounds: L.LatLngBounds,
    pixelThreshold = 14
): boolean => {
    const topLeft = map.latLngToContainerPoint(bounds.getNorthWest());
    const bottomRight = map.latLngToContainerPoint(bounds.getSouthEast());
    const width = Math.abs(bottomRight.x - topLeft.x);
    const height = Math.abs(bottomRight.y - topLeft.y);

    return width > pixelThreshold && height > pixelThreshold;
};

const handleWrapAtAnitmeridian = (buffered: Feature<Polygon>): L.LatLng[] => {
    // Modified from https://github.com/Turfjs/turf/issues/2003#issuecomment-976107168
    // @turf/buffer stretches polygons infinitely when buffering close to 180 degrees longitude
    const coordinates = buffered.geometry.coordinates[0];

    for (let i = 1; i < coordinates.length - 1; ++i) {
        const destination = coordinates[i];
        const origin = coordinates[i - 1];

        // Handle longitude wrap at anti-meridian
        destination[0] += destination[0] - origin[0] > 180 ? -360 : origin[0] - destination[0] > 180 ? 360 : 0;

        // Handle latitude wrap if needed
        destination[1] += destination[1] - origin[1] > 90 ? -180 : origin[1] - destination[1] > 90 ? 180 : 0;
    }

    return coordinates.map((t) => new L.LatLng(t[1], t[0]));
};

export const polygonToPaddedPolygon = (map: L.Map, polygonPositions: L.LatLng[], paddingInPixels = 15): L.LatLng[] => {
    const positions: Position[] = polygonPositions.map((t) => [t.lng, t.lat]);
    const startPosition: Position = [polygonPositions[0].lng, polygonPositions[0].lat];
    const closedPolygon: Position[][] = [[...positions, startPosition]];

    const turfPolygon: Polygon = {
        type: 'Polygon',
        coordinates: closedPolygon,
    };

    const point = polygonPositions[0];
    const p1 = map.latLngToContainerPoint(point);
    const p2 = new L.Point(p1.x + 1, p1.y + 1);
    const latLng2 = map.containerPointToLatLng(p2);
    const distanceInMetersOfOnePixel = point.distanceTo(latLng2);
    const bufferRadius = distanceInMetersOfOnePixel * paddingInPixels;

    const buffered = buffer(turfPolygon, bufferRadius, { units: 'meters' });
    try {
        const bufferedPositions = handleWrapAtAnitmeridian(buffered);
        return bufferedPositions;
    } catch (e) {
        return polygonPositions;
    }
};

export const polylineToPaddedPolygon = (
    map: L.Map,
    polylinePositions: L.LatLng[],
    paddingInPixels = 15
): L.LatLng[] => {
    const turfPolyline: LineString = {
        type: 'LineString',
        coordinates: polylinePositions.map((t) => [t.lng, t.lat]),
    };
    const point = polylinePositions[0];
    const p1 = map.latLngToContainerPoint(point);
    const p2 = new L.Point(p1.x + 1, p1.y + 1);
    const latLng2 = map.containerPointToLatLng(p2);
    const distanceInMetersOfOnePixel = point.distanceTo(latLng2);
    const bufferRadius = distanceInMetersOfOnePixel * paddingInPixels;

    const buffered = buffer(turfPolyline, bufferRadius, { units: 'meters' });
    try {
        const bufferedPositions = handleWrapAtAnitmeridian(buffered);
        return bufferedPositions;
    } catch (e) {
        return polylinePositions;
    }
};

/**
 * Returns true if the point is inside the polygon
 */
export const isPointInsidePolygon = (map: L.Map, point: L.LatLng, polygonPositions: L.LatLng[]): boolean => {
    const turfPoint: Point = { type: 'Point', coordinates: [point.lng, point.lat] };
    const turfPolygon: Polygon = {
        type: 'Polygon',
        coordinates: [polygonPositions.map((t) => [t.lng, t.lat])],
    };

    return booleanPointInPolygon(turfPoint, turfPolygon);
};

/**
 * Returns the area of the bounding box of the polygon in meters squared
 */
export const areaOfBoundingBox = (bounds: L.LatLngBounds): number => {
    const turfPolygon = {
        type: 'Polygon',
        coordinates: [
            [
                [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
                [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
                [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
                [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
                [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
            ],
        ],
    };

    const polygonArea = area(turfPolygon);
    return polygonArea;
};

/**
 * Returns the area of a polygon in meters squared
 */
export const areaOfPolygon = (polygonPositions: L.LatLng[]): number => {
    const turfPolygon = {
        type: 'Polygon',
        coordinates: [polygonPositions.map((t) => [t.lng, t.lat])],
    };

    const polygonArea = area(turfPolygon);
    return polygonArea;
};

export const areaOfPolylineWithBuffer = (map: L.Map, polylinePositions: L.LatLng[], bufferInPixels = 20): number => {
    const turfPolyline: LineString = {
        type: 'LineString',
        coordinates: polylinePositions.map((t) => [t.lng, t.lat]),
    };

    const point = polylinePositions[0];
    const p1 = map.latLngToContainerPoint(point);
    const p2 = new L.Point(p1.x + 1, p1.y + 1);
    const latLng2 = map.containerPointToLatLng(p2);
    const distanceInMetersOfOnePixel = point.distanceTo(latLng2);
    const bufferRadius = distanceInMetersOfOnePixel * bufferInPixels;

    const buffered = buffer(turfPolyline, bufferRadius, { units: 'meters' });
    const bufferedArea = area(buffered);
    return bufferedArea;
};

export const getMarkerBoundingBox = (map: L.Map, marker: Marker, iconBaseSize = 32): L.LatLngBounds => {
    const zoom = map.getZoom();
    const zoomBase = marker.zoomBase || 0;
    const scaleFactor = Math.pow(2, zoom - zoomBase);
    const iconSize = iconBaseSize * scaleFactor;

    const iconAnchorPosition = map.latLngToContainerPoint(marker.position);
    const top = iconAnchorPosition.y + iconSize / 2;
    const bottom = iconAnchorPosition.y - iconSize / 2;
    const left = iconAnchorPosition.x - iconSize / 2;
    const right = iconAnchorPosition.x + iconSize / 2;

    const bottomLeft = new L.Point(left, bottom);
    const topRight = new L.Point(right, top);

    const latLngBounds = new L.LatLngBounds(
        map.containerPointToLatLng(bottomLeft),
        map.containerPointToLatLng(topRight)
    );

    return latLngBounds;
};

export const getMilitaryMarkerBoundingBox = (map: L.Map, marker: MilitaryMarker): L.LatLngBounds => {
    const zoom = map.getZoom();
    const zoomBase = marker.zoomBase || 0;

    const svg = createMilitaryMarkerSVGPreview(marker.sidc);

    const scaleFactor = Math.pow(2, zoom - zoomBase);
    const iconWidth = (svg?.width ?? 0) * scaleFactor;
    const iconHeight = (svg?.height ?? 0) * scaleFactor;

    const iconAnchorPosition = map.latLngToContainerPoint(marker.position);
    const top = iconAnchorPosition.y + iconHeight / 2;
    const bottom = iconAnchorPosition.y - iconHeight / 2;
    const left = iconAnchorPosition.x - iconWidth / 2;
    const right = iconAnchorPosition.x + iconWidth / 2;

    const bottomLeft = new L.Point(left, bottom);
    const topRight = new L.Point(right, top);

    const latLngBounds = new L.LatLngBounds(
        map.containerPointToLatLng(bottomLeft),
        map.containerPointToLatLng(topRight)
    );

    return latLngBounds;
};

/*
 * Returns the bounding box of the coordinate with padding
 */
export const getCoordinateBoundingBox = (map: L.Map, coord: Coordinate): L.LatLngBounds => {
    const paddingHeightBase = 7;
    const paddingWidthBase = 5;

    const padding = getCoordinateSVGPadding(coord.coordinateSystem);

    const heightPadding = padding.height + paddingHeightBase;
    const widthPadding = padding.width + paddingWidthBase;

    const iconAnchorPosition = map.latLngToContainerPoint(coord.position);

    const top = iconAnchorPosition.y - 47;
    const bottom = iconAnchorPosition.y + heightPadding;
    const left = iconAnchorPosition.x - 135;
    const right = iconAnchorPosition.x + widthPadding;

    const bottomLeft = new L.Point(left, bottom);
    const topRight = new L.Point(right, top);

    const latLngBounds = new L.LatLngBounds(
        map.containerPointToLatLng(bottomLeft),
        map.containerPointToLatLng(topRight)
    );

    return latLngBounds;
};
