import React, { useState } from 'react';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import Arrow from './arrow';
import ArrowAnnotation from './arrow-annotation';
import ArrowBuilder from './arrow-builder';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import { selectArrows } from '../../../../store/Annotations/Arrow/selectors';
import { setArrowAddAction, setArrowUpdateAction } from '../../../../store/Annotations/Arrow/actions';
import { useDrawToolUpdated } from '../use-draw-tool-updated';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';

const ArrowControl = (props: AnnotationControlProps) => {
    const [isCreatingArrow, setIsCreatingArrow] = useState(false);
    const dispatch = useDispatch();
    const arrows = useSelector(selectArrows);

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const onUpdateArrow = useDrawToolUpdated((arrow: Arrow) => {
        dispatch(setArrowUpdateAction(arrow));
    });

    const isInteractive = !props.locked && props.visible;

    return (
        <React.Fragment>
            <AnnotationControl
                active={isCreatingArrow}
                disabled={props.disabled}
                layout="middle"
                visible={props.visible}
                iconDark="/assets/annotations/black-annotations/icon-arrow-black.svg"
                iconLight="/assets/annotations/icon-arrow.svg"
                name="Arrow"
                onClick={() => {
                    lockAnnotationsEvent();
                    setIsCreatingArrow(true);
                }}
                dataTestId="annotation-control-arrow"
            />
            {isCreatingArrow && !isInteractive ? (
                <ArrowBuilder
                    zIndex={currentZIndex}
                    onCreateArrow={(arrow) => {
                        setIsCreatingArrow(false);
                        dispatch(setArrowAddAction(arrow));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(arrow));
                        });
                        DrawToolAnalytics.add('arrow');
                    }}
                    onCancelBuild={() => {
                        setIsCreatingArrow(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {!isInteractive
                ? arrows.map((arrow) => {
                      return (
                          <ArrowAnnotation
                              key={`
                            ${arrow.id}-locked-
                            ${selectedAnnotation ? selectedAnnotation.id : ''}-
                            ${arrow.options.color}-
                            ${arrow.options.dashArray}-
                            ${arrow.options.dashOffset}-
                            ${arrow.options.weight}-
                            ${arrow.endLatLng.lat}-
                            ${arrow.endLatLng.lng}-
                            ${arrow.startLatLng.lat}-
                            ${arrow.startLatLng.lng}-
                            ${arrow.zIndex}
                        `}
                              arrow={arrow}
                              isSelected={false}
                              isDisabled={true}
                              onDeselect={() => null}
                          />
                      );
                  })
                : null}

            {isInteractive &&
                arrows.map((arrow) => {
                    return (
                        <ArrowAnnotation
                            key={`
                                ${arrow.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${arrow.options.color}-
                                ${arrow.options.dashArray}-
                                ${arrow.options.dashOffset}-
                                ${arrow.options.weight}-
                                ${arrow.endLatLng.lat}-
                                ${arrow.endLatLng.lng}-
                                ${arrow.startLatLng.lat}-
                                ${arrow.startLatLng.lng}-
                                ${arrow.zIndex}
                            `}
                            arrow={arrow}
                            onUpdateArrow={onUpdateArrow}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === arrow.id : false}
                            onDeselect={() => {
                                dispatch(setSelectedAnnotationAction(undefined));
                            }}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default ArrowControl;
