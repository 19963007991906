import { useEffect, useMemo, useState } from 'react';
import ReactDistortableImageOverlayMapLayer from './react-distortable-imageoverlay-maplayer';
import { LatLng, LatLngBounds } from 'leaflet';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectMapZoom } from '../../../../store/App/selectors';

interface Props {
    corners: LatLng[];
    setCorners?: (corners: LatLng[]) => void;
    dataURL: string;
    mode?: string;
    opacity?: number;
}

const DistortableOverlay = ({ opacity, setCorners, mode, corners, dataURL }: Props) => {
    const map = useMap();
    const mapZoom = useSelector(selectMapZoom);
    const [updatedCorners, setUpdatedCorners] = useState<LatLng[]>([]);
    const [visible, setVisible] = useState(true);

    //get maximum zoom level on which the given bounds fit to the map view in its entirety.
    const boundsZoom = useMemo(() => {
        if (corners.length >= 4) {
            const northEast = corners[0];
            const southWest = corners[3];
            const bounds = new LatLngBounds(northEast, southWest);
            const boundsZoom = map.getBoundsZoom(bounds);
            return boundsZoom;
        }
        return undefined;
    }, [corners, map]);

    // TODO this handles some weirdness with the corners being in the wrong order
    // We do it multiple places so we can probably fix it for good in the package now since
    // We have access to it directly
    const mapCornersForDistortableOverlay = (corners: LatLng[]) => {
        return [corners[0], corners[1], corners[3], corners[2]];
    };

    const unMapCornersForDistortableOverlay = (corners: LatLng[]) => {
        return [corners[0], corners[1], corners[2], corners[3]];
    };

    useEffect(() => {
        if (!updatedCorners) {
            setUpdatedCorners(mapCornersForDistortableOverlay(corners));
        }
        // TODO works with but something says it may cook it or cause excessive re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateCorners = (corners: LatLng[]) => {
        setCorners && setCorners(unMapCornersForDistortableOverlay(corners));
        setUpdatedCorners(corners);
    };

    //set image visibility where the image can be seen on zoom, -5 is arbitrary
    useEffect(() => {
        if (boundsZoom && mapZoom) {
            if (mapZoom > boundsZoom - 5) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }
    }, [boundsZoom, mapZoom]);

    return (
        <ReactDistortableImageOverlayMapLayer
            url={dataURL}
            opacity={visible ? opacity : 0}
            corners={corners}
            editMode={mode}
            onUpdate={(corners: LatLng[]) => handleUpdateCorners(corners)}
            map={map}
        />
    );
};

export default DistortableOverlay;
