import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import PolygonPath from './polygon';
import PolygonAnnotation from './polygon-annotation';
import PolygonBuilder from './polygon-builder';
import { selectPolylines } from '../../../../store/Annotations/Path/selectors';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { selectPolygons } from '../../../../store/Annotations/Polygon/selectors';
import { setPolygonAddAction, setPolygonUpdateAction } from '../../../../store/Annotations/Polygon/actions';
import { useDrawToolUpdated } from '../use-draw-tool-updated';

const PolygonControl = (props: AnnotationControlProps) => {
    const [pathBuilderActive, setPathBuilderActive] = useState(false);
    const dispatch = useDispatch();
    const polygons = useSelector(selectPolygons);
    const polylines = useSelector(selectPolylines);

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const isInteractive = !props.locked && props.visible;

    const onUpdatePolygon = useDrawToolUpdated((polygon: PolygonPath) => {
        dispatch(setPolygonUpdateAction(polygon));
    });

    const calculateSnapPoints = () =>
        polylines.flatMap((polyline) => polyline.positions).concat(polygons.flatMap((polygon) => polygon.positions));

    // Do not remove handles a user creating a polygon and then clicking exit without the shape been closed
    useEffect(() => {
        if (!props.visible) {
            setPathBuilderActive(false);
            unlockAnnotationsEvent();
        }
    }, [props.visible, unlockAnnotationsEvent]);

    return (
        <React.Fragment>
            <AnnotationControl
                active={pathBuilderActive}
                layout="middle"
                visible={props.visible}
                disabled={props.disabled}
                iconDark="/assets/annotations/black-annotations/icon-polygon-black.svg"
                iconLight="/assets/annotations/icon-polygon.svg"
                name="Polygon"
                onClick={() => {
                    lockAnnotationsEvent();
                    setPathBuilderActive(true);
                }}
                dataTestId="annotation-control-polygon"
            />

            {pathBuilderActive ? (
                <PolygonBuilder
                    zIndex={currentZIndex}
                    snapPoints={calculateSnapPoints()}
                    onCreatePolygon={(polygon: PolygonPath) => {
                        setPathBuilderActive(false);
                        dispatch(setPolygonAddAction(polygon));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(polygon));
                        });
                        DrawToolAnalytics.add('polygon');
                    }}
                    onCancelBuild={() => {
                        setPathBuilderActive(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {!isInteractive &&
                polygons.map((polygon) => {
                    return (
                        <PolygonAnnotation
                            key={`
                                ${polygon.id}-locked-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${polygon.options.color}-
                                ${polygon.options.fillOpacity}-
                                ${polygon.options.dashArray}-
                                ${polygon.options.dashOffset}-
                                ${polygon.options.weight}-
                                ${polygon.showArea}-
                                ${polygon.showLength}-
                                ${polygon.units}-
                                ${polygon.labelColor}-
                                ${polygon.fillPattern ? polygon.fillPattern : ''}
                                ${polygon.zIndex}-
                                ${polygon?.positions ? JSON.stringify(polygon.positions) : ''}
                            `}
                            polygon={polygon}
                            isSelected={false}
                            isDisabled={true}
                        />
                    );
                })}

            {isInteractive &&
                polygons.map((polygon) => {
                    return (
                        <PolygonAnnotation
                            key={`
                                ${polygon.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${polygon.options.color}-
                                ${polygon.options.fillOpacity}-
                                ${polygon.options.dashArray}-
                                ${polygon.options.dashOffset}-
                                ${polygon.options.weight}-
                                ${polygon.showArea}-
                                ${polygon.showLength}-
                                ${polygon.units}-
                                ${polygon.labelColor}-
                                ${polygon.fillPattern ? polygon.fillPattern : ''}
                                ${polygon.zIndex}-
                                ${polygon?.positions ? JSON.stringify(polygon.positions) : ''}
                            `}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === polygon.id : false}
                            polygon={polygon}
                            onUpdatePolygon={onUpdatePolygon}
                        ></PolygonAnnotation>
                    );
                })}
        </React.Fragment>
    );
};

export default PolygonControl;
