import styled from 'styled-components';
import { CSWTaskStatus } from '../../../api/model';
import { Badge } from 'reactstrap';
import { isMobileVersion } from '../../../lib/soar-helper';

interface OrderStatusProps {
    status: CSWTaskStatus;
}

const OrderStatus = (props: OrderStatusProps): JSX.Element => {
    switch (props.status) {
        case CSWTaskStatus.CREATED:
            return <StatusNotice color="primary">Created</StatusNotice>;
        case CSWTaskStatus.REVIEW_SOAR:
        case CSWTaskStatus.REVIEW_PROVIDER:
        case CSWTaskStatus.REVIEW_SUPPLIER:
            return <StatusNotice color="primary">Submitted</StatusNotice>;

        case CSWTaskStatus.COLLECTION:
            return <StatusNotice color="info">{isMobileVersion ? 'Approved' : 'Approved for collection'}</StatusNotice>;

        case CSWTaskStatus.QA_SOAR:
            return <StatusNotice color="info">Awaiting QA</StatusNotice>;

        case CSWTaskStatus.DELIVERED:
            return <StatusNotice color="success">Complete</StatusNotice>;

        case CSWTaskStatus.REJECTED:
            return <StatusNotice color="danger">Rejected</StatusNotice>;

        default:
            return <StatusNotice color="secondary">Unknown</StatusNotice>;
    }
};

export default OrderStatus;

const StatusNotice = styled(Badge)`
    position: inherit;
    margin-top: -10px;
    margin-left: -11px;
    font-size: 8px;
    white-space: auto;

    @media only screen and (max-width: 600px) {
        position: auto;
        font-size: 14px;
        font-weight: 500;
        margin: inherit;
    }
`;
