import { CommentDTO } from '../../api/model';
import { actionTypes as at } from './constants';

export const actionSetDisplayAnnotations = (annotationString: string) => {
    return {
        type: at.ANNOTATION_GET_ACTIVE_STRING,
        payload: annotationString,
    };
};

export const actionSetReplyingToComment = (replying: boolean) => {
    return {
        type: at.REPLYING_TO_COMMENT,
        payload: replying,
    };
};

export const actionSetUpdateAnnotations = (updateAnnotations: boolean) => {
    return {
        type: at.ANNOTATION_UPDATE_ALL,
        payload: updateAnnotations,
    };
};

export const actionSetSelectedCommentId = (commentId: string) => {
    return {
        type: at.ANNOTATION_COMMENT_ID,
        payload: commentId,
    };
};

export const actionSetResetCommentSection = (resetAnnotations: boolean) => {
    return {
        type: at.ANNOTATION_RESET_ALL,
        payload: resetAnnotations,
    };
};

export const actionSetEditingComment = (editingComment: boolean) => {
    return {
        type: at.SOCIAL_EDITING_COMMENT,
        payload: editingComment,
    };
};

export const actionSetSelectedComment = (comment: CommentDTO | undefined) => {
    return {
        type: at.COMMENT_SET,
        payload: comment,
    };
};
