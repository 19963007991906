import React, { useState } from 'react';
import styled from 'styled-components';
import InviteUserEmailInput from '../../../../MapView/Annotations/Project/Permissions/invite-user-email-input';
import InviteUserEmailList from '../../../../MapView/Annotations/Project/Permissions/invite-user-email-list';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../../Shared/soar-modal';
import { StyledButton, TransparentButton } from '../../../../Shared/styled-button';
import ApiListings from '../../../../../api/api-listings';

interface UploadMapShareModalProps {
    listingId: number;
    isOpen: boolean;
    onClose: () => void;
}

const UploadMapShareModal = (props: UploadMapShareModalProps) => {
    const [emails, setEmails] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);

    const handleShare = () => {
        setIsLoading(true);

        ApiListings.sendShareUploadToEmail(props.listingId, emails)
            .then((res) => {
                console.log('res', res);
                setIsLoading(false);
                setIsComplete(true);
            })
            .catch((err) => {
                console.log('err', err);
                setIsLoading(false);
                setIsComplete(true);
            });
    };

    return (
        <React.Fragment>
            <SoarModal isOpen={props.isOpen} onClose={props.onClose} title="Share your Map">
                <StyledModalBody>
                    <Text>Congratulations! Your map is being uploaded. Why not let your community know about it?</Text>

                    <Divider />

                    <InviteUserEmailInput
                        onAddUser={(email: string) => setEmails([...emails, email])}
                        onAddUsers={(emails: string[]) => setEmails([...emails, ...emails])}
                        invitedUserEmails={emails}
                    />

                    <InviteUserEmailList
                        invitedUserEmails={emails}
                        onRemoveUser={(email: string) => setEmails(emails.filter((e) => e !== email))}
                    />

                    {isComplete && (
                        <React.Fragment>
                            <CompletedText>Your users will be notified as soon as your map is ready.</CompletedText>
                        </React.Fragment>
                    )}
                </StyledModalBody>

                <StyledModalFooter>
                    <TransparentButton onClick={props.onClose}>Close</TransparentButton>
                    {isLoading ? (
                        <Button>
                            <i className="fa fa-spinner fa-spin" />
                        </Button>
                    ) : (
                        <Button onClick={handleShare}>Invite</Button>
                    )}
                </StyledModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default UploadMapShareModal;

const Text = styled.p`
    font-size: 1rem;
    color: white;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
`;

const CompletedText = styled(Text)`
    margin: 14px 0 0 0;
`;

const Button = styled(StyledButton)`
    min-width: 149px;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 30px;
`;
