import L from 'leaflet';
import { MarkerIconName, getMarkerIcon } from './marker-util';
import { defaultZIndex } from '../layers-util';

export default class Marker {
    annotationType: string;
    id: string;
    position: L.LatLng;
    iconName: string;
    options: L.MarkerOptions;
    color?: string;
    zoomBase?: number;
    zIndex: number;

    constructor(
        id: string,
        position: L.LatLng,
        iconName: string,
        options: L.MarkerOptions,
        color?: string,
        zoomBase?: number
    ) {
        this.id = id;
        this.position = position;
        this.iconName = iconName;
        this.options = options;
        this.color = color;
        this.zoomBase = zoomBase;
    }

    static serialize(marker: Marker): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [marker.position.lng, marker.position.lat],
            },
            properties: {
                annotationType: 'Marker',
                id: marker.id,
                options: marker.options,
                iconName: marker.iconName,
                color: marker.color,
                zoomBase: marker.zoomBase,
                zIndex: marker.zIndex || defaultZIndex,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): Marker {
        const position = new L.LatLng(json.geometry.coordinates[1] as number, json.geometry.coordinates[0] as number);
        const icon = getMarkerIcon(
            json.properties?.iconName ? json.properties.iconName : MarkerIconName.SOARDEFAULT,
            json.properties?.color
        );
        const iconName = json.properties?.iconName ? json.properties.iconName : MarkerIconName.SOARDEFAULT;
        const marker: Marker = {
            annotationType: 'Marker',
            id: json.properties.id,
            position,
            iconName,
            options: {
                ...defaultMarkerOptions,
                ...icon.options,
            },
            color: json.properties?.color,
            zoomBase: json.properties?.zoomBase,
            zIndex: json.properties?.zIndex || defaultZIndex,
        };
        return marker;
    }
}

export const defaultMarkerOptions: L.MarkerOptions = {
    interactive: true,
};
