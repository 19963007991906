import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import ShareService from '../../../../../lib/share-service';
import Analytics from '../../../../../lib/user-analytics';
import SoarHelper from '../../../../../lib/soar-helper';
import { toast } from 'react-toastify';

const SideDrawerSocial = () => {
    const selectedMap = useSelector(selectActiveAllMap);

    const handleShareLink = (
        seoTitle: string,
        mapId: number,
        href: string,
        analyticsEvent: string,
        analyticsEventType: string
    ) => {
        Analytics.Event(analyticsEvent, analyticsEventType, mapId);
        const url = ShareService.shareMapUrl(mapId);
        const shareURL = `${href}${url}`;
        window.open(shareURL, '_blank');
    };

    const generateCopyLink = async (mapId: number | string) => {
        const short = ShareService.shareMapUrl(mapId);
        const currentDomain = SoarHelper.isSoar() && short ? short : `${window.location.href}`;
        navigator.clipboard.writeText(currentDomain);
        toast.dark('Copied to clipboard');
        Analytics.Event(`Social Media - Copied To Share - ${currentDomain}`, 'Link', mapId);
    };

    if (!selectedMap) {
        return <React.Fragment />;
    }

    // We do not have access to the FA X icon currently, so we use a dataurl instead for X
    return (
        <React.Fragment>
            {SoarHelper.isSoar() ? (
                <React.Fragment>
                    <StyledSocialButton
                        iconUrl={`data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke-width:0px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m12.59.79h2.45l-5.34,6.1,6.28,8.31h-4.92l-3.86-5.04-4.41,5.04H.35l5.71-6.53L.03.79h5.04l3.48,4.6L12.59.79Zm-.86,12.95h1.35L4.34,2.18h-1.46l8.85,11.57Z'/%3E%3C/svg%3E`}
                        text={'Share to X'}
                        onClick={() =>
                            handleShareLink(
                                selectedMap.seoTitle || '',
                                selectedMap.id,
                                'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=',
                                'Social Media - Opened To Share',
                                'Twitter'
                            )
                        }
                    />
                    <StyledSocialButton
                        iconUrl={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='white'%3E%3Cpath d='M279.1 288l14.2-92.7h-88.9v-60.1c0-25.4 12.4-50.1 52.2-50.1h40.4V6.3S260.4 0 225.4 0c-73.2 0-121.1 44.4-121.1 124.7v70.6H22.9V288h81.4v224h100.2V288z'/%3E%3C/svg%3E`}
                        text={'Share to Facebook'}
                        onClick={() =>
                            handleShareLink(
                                selectedMap.seoTitle || '',
                                selectedMap.id,
                                'https://www.facebook.com/sharer.php?u=',
                                'Social Media - Opened To Share',
                                'Facebook'
                            )
                        }
                    />
                    <StyledSocialButton
                        iconUrl={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='white'%3E%3Cpath d='M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z'/%3E%3C/svg%3E`}
                        text={'Share to LinkedIn'}
                        onClick={() =>
                            handleShareLink(
                                selectedMap.seoTitle || '',
                                selectedMap.id,
                                'https://www.linkedin.com/shareArticle?mini=true&url=',
                                'Social Media - Opened To Share',
                                'LinkedIn'
                            )
                        }
                    />
                </React.Fragment>
            ) : null}
            <StyledSocialButton
                iconUrl={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'%3E%3Cpath d='M307 34.8c-11.5 5.1-19 16.6-19 29.2l0 64-112 0C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96l96 0 0 64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z'/%3E%3C/svg%3E`}
                text={'Share by Link'}
                onClick={() => generateCopyLink(selectedMap.seoTitle || selectedMap.id)}
            />
        </React.Fragment>
    );
};

export default SideDrawerSocial;

export interface ButtonProps {
    iconUrl: string;
    text: string;
    onClick: () => void;
}

export const StyledSocialButton = ({ iconUrl, text, onClick }: ButtonProps) => {
    return (
        <StyledButton onClick={onClick}>
            <StyledButtonIcon src={iconUrl} title={text} />
        </StyledButton>
    );
};

export const StyledButton = styled.button`
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(142, 145, 143, 0.3);
    border-radius: 6px;
    height: 40px;
    letter-spacing: 0.25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.218s, border-color 0.218s;
    width: 40px;
    margin: 5px;
    outline: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    :hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
        background: rgba(26, 26, 27, 0.9);
        border-color: rgba(116, 119, 117, 0.9);
    }
`;

export const StyledButtonIcon = styled.img`
    height: 20px;
`;
