import { StoaryPage, Stoary } from '../../../api/stoaryModel';
import { setArrowsAction } from '../../Annotations/Arrow/actions';
import { setCirclesAction } from '../../Annotations/Circle/actions';
import { setCoordinatesAction } from '../../Annotations/Coordinate/actions';
import { setFreehandDrawsAction } from '../../Annotations/Freehand/actions';
import { setImagesAction } from '../../Annotations/Images/actions';
import { setMarkersAction } from '../../Annotations/Marker/actions';
import { setPolylinesAction } from '../../Annotations/Path/actions';
import { setPolygonsAction } from '../../Annotations/Polygon/actions';
import { setRectanglesAction } from '../../Annotations/Rectangle/actions';
import { setTextBoxesAction } from '../../Annotations/TextBox/actions';
import { deleteAllAnnotationsAction, setSelectedAnnotationAction } from '../../Annotations/actions';
import { actionActiveMapCleared, actionActiveMapFetchById } from '../SuperMap/actions';
import { actionTypes as at } from './constants';

export const actionSetStoaryBuilderActive = (isActive: boolean) => {
    return {
        type: at.STOARY_BUILDER_ACTIVE,
        payload: isActive,
    };
};

export const actionSetStoary = (stoary: Stoary) => {
    return {
        type: at.SET_STOARY,
        payload: stoary,
    };
};

export const actionSetStoaryId = (stoaryId: string) => {
    return {
        type: at.SET_STOARY_ID,
        payload: stoaryId,
    };
};

export const actionClearStoary = () => {
    return {
        type: at.SET_STOARY_ID,
        package: undefined,
    };
};

export const actionSetStoaryPage = (stoaryPage: StoaryPage) => {
    deleteAllAnnotationsAction();
    if (stoaryPage.project) {
        setArrowsAction(stoaryPage.project.arrows);
        setMarkersAction(stoaryPage.project.markers);
        setPolylinesAction(stoaryPage.project.polylines);
        setPolygonsAction(stoaryPage.project.polygons);
        setCirclesAction(stoaryPage.project.circles);
        setRectanglesAction(stoaryPage.project.rectangles);
        setFreehandDrawsAction(stoaryPage.project.freehandDraws);
        setArrowsAction(stoaryPage.project.arrows);
        setImagesAction(stoaryPage.project.images);
        setTextBoxesAction(stoaryPage.project.textBoxes);
        setCoordinatesAction(stoaryPage.project.coordinates);
        setSelectedAnnotationAction(undefined);
    }

    if (stoaryPage.map) {
        actionActiveMapFetchById(stoaryPage.map.id);
    } else {
        actionActiveMapCleared();
    }

    return {
        type: at.SET_STOARY_PAGE,
        payload: stoaryPage,
    };
};

export const actionSetHighlightedStoaryWKT = (highlightedStoaryWKT: string | undefined) => {
    return {
        type: at.SET_HIGHLIGHTED_STOARY_WKT,
        payload: highlightedStoaryWKT,
    };
};
