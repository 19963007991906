import { actionTypes as at } from './constants';
import { LatLng } from 'leaflet';

export const actionUpdateHighlightedSearchPosition = (position: LatLng | undefined) => {
    return {
        type: at.UPDATE_HIGHLIGHTED_SEARCH_POSITION,
        payload: position,
    };
};

export const actionUpdateActiveSearchPosition = (position: LatLng | undefined) => {
    return {
        type: at.UPDATE_ACTIVE_SEARCH_POSITION,
        payload: position,
    };
};

export const actionClearActiveSearch = () => {
    return {
        type: at.CLEAR_ACTIVE_SEARCH,
    };
};

export const actionClearHighlightedSearch = () => {
    return {
        type: at.CLEAR_HIGHLIGHTED_SEARCH,
    };
};
