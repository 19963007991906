import React, { useState } from 'react';
import styled from 'styled-components';
import { AutocompleteDTO } from '../../../../../../api/model';
import SoarCarousel from '../../../../../Shared/soar-carousel';
import SearchResultsAutocompleteChip from './search-results-autocomplete-chip';

interface SearchResultsAutocompleteProps {
    autocomplete: AutocompleteDTO[];
    autocompleteCoordinate: AutocompleteDTO | undefined;
    handleSelectAutocomplete: (location: AutocompleteDTO) => void;
}

const SearchResultsAutocomplete = (props: SearchResultsAutocompleteProps) => {
    const [isDragging, setIsDragging] = useState(false);

    if (props.autocomplete.length === 0 && !props.autocompleteCoordinate) return <React.Fragment />;

    return (
        <TagContainer>
            <LocationText>Did you mean </LocationText>
            {props.autocompleteCoordinate ? (
                <SearchResultsAutocompleteChip
                    key={props.autocompleteCoordinate.title}
                    autocomplete={props.autocompleteCoordinate}
                    onSelectAutocomplete={props.handleSelectAutocomplete}
                />
            ) : (
                <Container>
                    <SoarCarousel dragHandler={setIsDragging} containerHeight="32px">
                        {props.autocomplete.map((t, index) => {
                            return (
                                <SearchResultsAutocompleteChip
                                    key={`${index}_${t.title}`}
                                    autocomplete={t}
                                    onSelectAutocomplete={(location) => {
                                        if (!isDragging) {
                                            props.handleSelectAutocomplete(location);
                                        }
                                    }}
                                />
                            );
                        })}
                    </SoarCarousel>
                </Container>
            )}
        </TagContainer>
    );
};

export default SearchResultsAutocomplete;

const Container = styled.div`
    width: calc(100% - 165px);
    margin-left: 0px;
    padding-right: 30px;
    position: relative;
`;

const TagContainer = styled.div`
    margin: 20px 0px 0px 30px;
    background: none;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
`;

const LocationText = styled.span`
    color: #eed923;
    font-size: 1.2rem;
    width: 159px;
    padding-top: 4px;
`;
