import { Map, LatLng, LatLngBounds, LatLngExpression, Point } from 'leaflet';
import GeoUtil from '../../../../lib/geo-util';

const MAX_IMAGE_COVERAGE_OF_MAP_PERCENTAGE = 5; // 5% of the map

type Corner = 'ne' | 'nw' | 'se' | 'sw';

/**
 * Resize the image overlay by dragging a corner.
 * @param context The context.
 * @param bounds The bounds of the image overlay.
 * @param corner The corner being dragged.
 * @param newLatLng The new latlng of the corner.
 * @returns The new bounds of the image overlay.
 **/
export const resizeBoundsByCornerWhilePreservingAspectRatio = (
    context: Readonly<{ map: Map }>,
    bounds: LatLngBounds,
    corner: Corner,
    newLatLng: LatLng
): LatLngBounds => {
    switch (corner) {
        case 'ne':
            return handleCornerAndAspectRatio(context.map, bounds, bounds.getNorthEast(), newLatLng);
        case 'nw':
            return handleCornerAndAspectRatio(context.map, bounds, bounds.getNorthWest(), newLatLng);
        case 'sw':
            return handleCornerAndAspectRatio(context.map, bounds, bounds.getSouthWest(), newLatLng);
        case 'se':
            return handleCornerAndAspectRatio(context.map, bounds, bounds.getSouthEast(), newLatLng);
        default:
            return bounds;
    }
};

/**
 * Resize the image overlay by dragging a corner.
 * @param map The Map.
 * @param bounds The bounds of the image overlay.
 * @param corner The corner being dragged.
 * @param newLatLng The new latlng of the corner.
 * @returns The new bounds of the image overlay.
 **/
const handleCornerAndAspectRatio = (
    map: Map,
    bounds: LatLngBounds,
    dragCorner: LatLng,
    newLatLng: LatLng
): LatLngBounds => {
    const oppositeCornerFromDragCorner = getOppositeCorner(dragCorner, bounds);
    const scale = calculateScalingFactor(map, dragCorner, newLatLng, oppositeCornerFromDragCorner.bounds);
    const imageSizeToScreen = calculateRelevantImageSizeToScreen(map, dragCorner, oppositeCornerFromDragCorner.bounds);

    // If the image covers more than specified percentage of the map, do not scale.
    if (imageSizeToScreen > MAX_IMAGE_COVERAGE_OF_MAP_PERCENTAGE) {
        return scaleWithFixedCornerAndAspectRatio(map, bounds, scale, oppositeCornerFromDragCorner);
    } else {
        const calculateDistanceBetweenPoints = (map: Map, dragCorner: LatLng, staticCorner: LatLng): number => {
            const corner1 = map.latLngToLayerPoint(dragCorner);
            const corner2 = map.latLngToLayerPoint(staticCorner);
            const w = Math.abs(corner1.x - corner2.x);
            const h = Math.abs(corner1.y - corner2.y);
            const distance = Math.sqrt(w * w + h * h);
            return distance;
        };
        // If the user is dragging the corner closer to the opposite corner, do not scale.
        // else allow the image to scale.
        const draggableCorner = calculateDistanceBetweenPoints(map, dragCorner, oppositeCornerFromDragCorner.bounds);
        const currentCorner = calculateDistanceBetweenPoints(map, newLatLng, oppositeCornerFromDragCorner.bounds);
        const isDragCornerCloserToOppositeCorner = draggableCorner > currentCorner;
        if (isDragCornerCloserToOppositeCorner) {
            return bounds;
        } else {
            return scaleWithFixedCornerAndAspectRatio(map, bounds, scale, oppositeCornerFromDragCorner);
        }
    }
};

/**
 * Return the corner and index opposite to the corner being dragged.
 * @param cornerBeingDragged The corner being dragged.
 * @param bounds The bounds of the image overlay.
 * @returns The opposite corner with a relevant index.
 **/
const getOppositeCorner = (cornerBeingDragged: LatLng, bounds: LatLngBounds): { bounds: LatLng; index: number } => {
    let oppositeCorner = { bounds: new LatLng(0, 0), index: 0 };
    if (cornerBeingDragged.equals(bounds.getNorthEast())) {
        oppositeCorner = { bounds: bounds.getSouthWest(), index: 2 };
    } else if (cornerBeingDragged.equals(bounds.getNorthWest())) {
        oppositeCorner = { bounds: bounds.getSouthEast(), index: 1 };
    } else if (cornerBeingDragged.equals(bounds.getSouthWest())) {
        oppositeCorner = { bounds: bounds.getNorthEast(), index: 0 };
    } else if (cornerBeingDragged.equals(bounds.getSouthEast())) {
        oppositeCorner = { bounds: bounds.getNorthWest(), index: 3 };
    }
    return oppositeCorner;
};

const calculateRelevantImageSizeToScreen = (map: Map, dragCorner: LatLng, staticCorner: LatLng): number => {
    const corner1: Point = map.latLngToLayerPoint(dragCorner);
    const corner2: Point = map.latLngToLayerPoint(staticCorner);
    const pixelDistance = corner1.distanceTo(corner2);

    // Get the dimensions of the map container (screen dimensions)
    const mapContainer = map.getContainer();
    const screenWidth = mapContainer.clientWidth;
    const screenHeight = mapContainer.clientHeight;

    // Calculate the diagonal distance of the screen
    const diagonalScreenDistance = Math.sqrt(screenWidth * screenWidth + screenHeight * screenHeight);

    // Calculate the percentage of pixel distance relative to the screen diagonal distance
    const distancePercentage = (pixelDistance / diagonalScreenDistance) * 100;

    return distancePercentage;
};

/**
 * Calculate the scaling factor between two points.
 * @param map The map.
 * @param latlngA Latlng of the first point.
 * @param latlngB Latlng of the second point.
 * @param scaleFromLatLng The point to scale from.
 * @returns The scaling factor.
 */
const calculateScalingFactor = (
    map: Map,
    latlngA: LatLngExpression,
    latlngB: LatLngExpression,
    scaleFromLatLng?: LatLngExpression
): number => {
    const centerPoint = map.latLngToLayerPoint(scaleFromLatLng || map.getCenter());
    const formerPoint = map.latLngToLayerPoint(latlngA);
    const newPoint = map.latLngToLayerPoint(latlngB);

    const distanceBetweenTwoPointsInCartesianSpace = (a: Point, b: Point): number => {
        const dx = a.x - b.x;
        const dy = a.y - b.y;

        return Math.pow(dx, 2) + Math.pow(dy, 2);
    };

    const formerRadiusSquared = distanceBetweenTwoPointsInCartesianSpace(centerPoint, formerPoint);
    const newRadiusSquared = distanceBetweenTwoPointsInCartesianSpace(centerPoint, newPoint);

    return Math.sqrt(newRadiusSquared / formerRadiusSquared);
};

/**
 * Maintains the aspect ratio while scaling to the static corner.
 * @param map The map.
 * @param bounds The bounding box.
 * @param scale The scaling factor.
 * @param staticCorner The corner that is not being scaled.
 * @returns The scaled LatLngBounds.
 */
const scaleWithFixedCornerAndAspectRatio = (
    map: Map,
    bounds: LatLngBounds,
    scale: number,
    staticCorner: { bounds: LatLng; index: number }
): LatLngBounds => {
    const boundsAsPolygon = GeoUtil.polygonForBounds(bounds);
    const center = bounds.getCenter();

    const centerPoint = map.project(center);
    const scaledCorners: { lat: number; lng: number }[] = [];

    for (let i = 0; i < 4; i++) {
        if (i !== staticCorner.index) {
            const p = map.project(boundsAsPolygon[i]).subtract(centerPoint).multiplyBy(scale).add(centerPoint);
            scaledCorners[i] = map.unproject(p);
        } else {
            scaledCorners[i] = new LatLng(boundsAsPolygon[i]['lat'], boundsAsPolygon[i]['lng']);
        }
    }

    const NorthEast = scaledCorners[0];
    const SouthEast = scaledCorners[1];
    const SouthWest = scaledCorners[2];
    const NorthWest = scaledCorners[3];

    if (staticCorner.bounds.equals(bounds.getNorthWest())) {
        NorthEast.lat = NorthWest.lat;
        SouthWest.lng = NorthWest.lng;
    } else if (staticCorner.bounds.equals(bounds.getSouthEast())) {
        NorthEast.lng = SouthEast.lng;
        SouthWest.lat = SouthEast.lat;
    }

    const scaledBounds = new LatLngBounds(scaledCorners[0], scaledCorners[2]);
    return scaledBounds;
};

export const handlePopupPanning = (image, map) => {
    // `autoPan` will be set to `true` if the whole image can be seen on the map, it will be `false` if a part of the image is only seen
    const autoPanMap =
        map.getBounds().intersects(image.getBounds()) && !map.getBounds().contains(image.getBounds()) ? false : true;

    const imagePopup = image.getPopup();
    if (imagePopup) {
        image.bindPopup(imagePopup, {
            ...imagePopup.options,
            autoPan: autoPanMap,
        });
    }
};
