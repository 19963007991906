import { LatLng } from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { CoordinateSystem, coordinateSystemOptions, getCoordinateSVGPadding } from './coordinate-util';

export const CoordinateSvg = (id: string, latlng: LatLng, selectedCoordinateSystem: CoordinateSystem) => {
    const selectedOption =
        coordinateSystemOptions.find((option) => option.value === selectedCoordinateSystem) ||
        coordinateSystemOptions[0];
    const textContent = selectedOption.format(latlng);

    const paddingX = 5;
    const paddingY = 5;
    const textLineHeight = 20;

    const baseWidth = 130;

    const padding = getCoordinateSVGPadding(selectedOption.value);

    const coordinateBoxWidth = baseWidth + padding.width;
    const coordinateBoxHeight = textContent.length * textLineHeight;

    // All for a fkn triangle with a line...
    const triangleBaseSize = 14;
    const triangleOffsetX = -13;

    const trianglePositionY = coordinateBoxHeight - textLineHeight / 2 - triangleBaseSize / 2 + 3; // 3 is a magic number to put it to the baseline

    const centerX = coordinateBoxWidth / 2;

    const { x1, y1, x2, y2 } = calculateLinePositions(
        centerX,
        triangleOffsetX,
        trianglePositionY,
        triangleBaseSize,
        padding
    );

    return ReactDOMServer.renderToString(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`-${centerX} 0 ${coordinateBoxWidth} ${coordinateBoxHeight}`}
            id={`latlng-tool-${id}`}
            width={coordinateBoxWidth}
            height={coordinateBoxHeight}
        >
            <rect
                id={`latlng-tool-background-${id}`}
                x={`-${centerX}`}
                width={coordinateBoxWidth}
                height={coordinateBoxHeight}
                fill="#212529"
                stroke="#eed926"
                strokeWidth={1}
            />
            {textContent?.map((line, index) => (
                <text
                    key={`coordinate-text-${id}-${index}`}
                    textAnchor="start"
                    dominantBaseline="hanging"
                    x={`-${centerX - paddingX}`}
                    y={index * textLineHeight + paddingY}
                    fontSize="0.9rem"
                    fontFamily="'Manrope', sans-serif"
                    fontWeight="500"
                    fill="#eed926"
                >
                    {line.trim()}
                </text>
            ))}
            {<line x1={x1} y1={y1} x2={x2} y2={y2} stroke="#eed926" strokeWidth={2} />}
            <defs>
                <polygon
                    id="corner-triangle"
                    points={`${triangleBaseSize} 0, ${triangleBaseSize} ${triangleBaseSize}, 0 ${triangleBaseSize}`}
                    fill="#eed926"
                />
            </defs>
            <use x={centerX + triangleOffsetX} y={trianglePositionY} xlinkHref="#corner-triangle" />
        </svg>
    );
};

const calculateLinePositions = (
    centerX: number,
    triangleOffsetX: number,
    trianglePositionY: number,
    triangleBaseSize: number,
    padding: { width: number; height: number }
): {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
} => {
    const lineX = centerX + triangleOffsetX;
    const lineY = trianglePositionY + triangleBaseSize - 5;
    const lineLength = 20;

    const lineBaseX = 46;
    const lineBaseY = 40;
    const lineAdjustment = padding.width ? padding.width / 2 : 0;
    const x1 = lineBaseX + padding.width - lineAdjustment;
    const y1 = lineBaseY + padding.height;

    const x2 = lineX + lineLength * Math.cos(Math.PI / 4);
    const y2 = lineY - lineLength * Math.sin(Math.PI / 4);

    return { x1, y1, x2, y2 };
};
