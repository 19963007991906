import { AppState } from '../../root-reducer';

export const selectIsSatelliteBoxSelectActive = (state: AppState) =>
    state.satelliteArchiveDomain.isSatelliteBoxSelectActive;

export const selectSatelliteAOI = (state: AppState) => state.satelliteArchiveDomain.satelliteAOI;

export const selectSatelliteSelectedFeatures = (state: AppState) =>
    state.satelliteArchiveDomain.satelliteSelectedFeatures;

export const selectSatelliteHighlightedFeature = (state: AppState) =>
    state.satelliteArchiveDomain.satelliteHighlightedFeature;

export const selectSatelliteZIndex = (state: AppState) => state.satelliteArchiveDomain.satelliteZIndex;
