import React, { useState } from 'react';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';

import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { setTextBoxAddAction, setTextBoxUpdateAction } from '../../../../store/Annotations/TextBox/actions';
import { selectTextBoxes } from '../../../../store/Annotations/TextBox/selectors';
import { incrementZIndexAction, setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectCurrentZIndex, selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import TextAnnotation from './text-annotation';
import TextAnnotationSVGLabel from './text-annotation-svg-label';
import TextBoxBuilder from './text-builder';
import TextBox from './textbox';
import { selectMapZoom } from '../../../../store/App/selectors';

const TextControl = (props: AnnotationControlProps) => {
    const map = useMap();
    const [isCreatingText, setIsCreatingText] = useState(false);
    const mapZoom = useSelector(selectMapZoom);

    const dispatch = useDispatch();
    const textBoxes = useSelector(selectTextBoxes);

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const onUpdateTextBox = (textBox: TextBox) => {
        dispatch(setTextBoxUpdateAction(textBox));
    };

    const canRenderSVGTextLabel = (textBoxBounds: L.LatLngBounds): boolean => {
        const northEastPoint = map.latLngToContainerPoint(textBoxBounds.getNorthEast());
        const southWestPoint = map.latLngToContainerPoint(textBoxBounds.getSouthWest());
        const width = Math.abs(northEastPoint.x - southWestPoint.x);
        const height = Math.abs(southWestPoint.y - northEastPoint.y);
        return width > 3 || height > 3;
    };

    return (
        <React.Fragment>
            <AnnotationControl
                active={isCreatingText}
                disabled={props.disabled}
                layout="start"
                visible={props.visible}
                iconDark="/assets/annotations/black-annotations/icon-text-black.svg"
                iconLight="/assets/annotations/icon-text.svg"
                iconClassName="text-icon"
                name="Text"
                onClick={() => {
                    lockAnnotationsEvent();
                    setIsCreatingText(true);
                }}
                dataTestId="annotation-control-text"
            />

            {isCreatingText ? (
                <TextBoxBuilder
                    zIndex={currentZIndex}
                    onPlaceTextBox={(newTextBox) => {
                        setIsCreatingText(false);
                        dispatch(setTextBoxAddAction(newTextBox));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        dispatch(setSelectedAnnotationAction(newTextBox));

                        DrawToolAnalytics.add('textarea');
                    }}
                    onCancelBuild={() => {
                        setIsCreatingText(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {mapZoom &&
                textBoxes.map((textBox) => {
                    if (selectedAnnotation && selectedAnnotation.id === textBox.id) {
                        return (
                            <TextAnnotation
                                key={`
                                ${textBox.id}-
                                ${textBox.fillColor}-
                                ${textBox.textColor}-
                                ${textBox.fillOpacity}-
                                ${textBox.fontFamily}-
                                ${textBox.fontSize}-
                                ${textBox.bounds.getNorth()}-
                                ${textBox.bounds.getEast()}-
                                ${textBox.bounds.getSouth()}-
                                ${textBox.bounds.getWest()}-
                                ${textBox.zIndex}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}
                            `}
                                isSelected={selectedAnnotation ? selectedAnnotation.id === textBox.id : false}
                                onSelect={() => dispatch(setSelectedAnnotationAction(textBox))}
                                onDeselect={() => dispatch(setSelectedAnnotationAction(undefined))}
                                textBox={textBox}
                                onUpdate={onUpdateTextBox}
                            />
                        );
                    } else if (canRenderSVGTextLabel(textBox.bounds)) {
                        return (
                            <TextAnnotationSVGLabel
                                key={`
                                ${textBox.id}-locked-
                                ${textBox.fillColor}-
                                ${textBox.textColor}-
                                ${textBox.fillOpacity}-
                                ${textBox.fontFamily}-
                                ${textBox.fontSize}-
                                ${textBox.bounds.getNorth()}-
                                ${textBox.bounds.getEast()}-
                                ${textBox.bounds.getSouth()}-
                                ${textBox.bounds.getWest()}-
                                ${textBox.zIndex}-
                                ${mapZoom}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}
                            `}
                                textBox={textBox}
                                isDisabled={true}
                            />
                        );
                    } else {
                        return <React.Fragment key={textBox.id} />;
                    }
                })}
        </React.Fragment>
    );
};

export default TextControl;
