import Image from '../../../components/MapView/Annotations/ImageTool/image';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface ImagesState {
    images: Image[];
}

const initialState: ImagesState = {
    images: [],
};

export default (state: ImagesState = initialState, action: Action): ImagesState => {
    switch (action.type) {
        case at.SET_IMAGES:
            return { ...state, images: action.payload.images };

        case at.SET_IMAGE_ADD:
            return { ...state, images: [...state.images, action.payload] };

        case at.SET_IMAGE_UPDATE:
            return {
                ...state,
                images: state.images.map((i) => (i.id === action.payload.id ? action.payload : i)),
            };

        case at.SET_IMAGE_DELETE:
            return {
                ...state,
                images: state.images.filter((i) => i.id !== action.payload.id),
            };

        default:
            return state;
    }
};
