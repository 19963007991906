import { useEffect, useMemo, useState } from 'react';

import { LatLng, LatLngBounds } from 'leaflet';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectMapZoom } from '../../../../store/App/selectors';
import DistortedImageOverlay from './distorted-image-overlay';

interface Props {
    corners: LatLng[];
    imageURL: string;
    opacity: number;
}

const PinnedImageOverlay = ({ corners, imageURL, opacity }: Props) => {
    const map = useMap();
    const mapZoom = useSelector(selectMapZoom);
    const [visible, setVisible] = useState(true);

    //get maximum zoom level on which the given bounds fit to the map view in its entirety.
    const boundsZoom = useMemo(() => {
        if (corners.length >= 4) {
            const northEast = corners[0];
            const southWest = corners[3];
            const bounds = new LatLngBounds(northEast, southWest);
            const zoom = map.getBoundsZoom(bounds);
            return zoom;
        }
        return undefined;
    }, [corners, map]);

    //set image visibility where the image can be seen on zoom, -5 is arbitrary
    useEffect(() => {
        if (boundsZoom && mapZoom) {
            if (mapZoom > boundsZoom - 5) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }
    }, [boundsZoom, mapZoom]);

    if (!boundsZoom) return null;

    return <DistortedImageOverlay url={imageURL} anchors={corners} opacity={visible ? opacity : 0} />;
};

export default PinnedImageOverlay;
