import L from 'leaflet';
import { defaultZIndex } from '../layers-util';

export default class FreehandPolyline {
    annotationType: string;
    id: string;
    positions: L.LatLng[];
    options: L.PolylineOptions;
    zIndex: number;
    bounds?: L.LatLngBounds;

    constructor(id: string, positions: L.LatLng[], options: L.PolylineOptions, bounds: L.LatLngBounds) {
        this.id = id;
        this.positions = positions;
        this.options = options;
        this.bounds = bounds;
    }

    static serialize = (freehandDraw: FreehandPolyline): GeoJSON.Feature => {
        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: freehandDraw.positions.map((position) => [position.lng, position.lat]),
            },
            properties: {
                annotationType: 'FreehandPolyline',
                id: freehandDraw.id,
                zIndex: freehandDraw.zIndex || defaultZIndex,
                ...freehandDraw.options,
            },
        };
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize = (json: any): FreehandPolyline => {
        const positions = json.geometry.coordinates.map((coordinate) => {
            return new L.LatLng(coordinate[1], coordinate[0]);
        });

        const { id, zIndex, ...freehandDrawOptions } = json.properties;

        return {
            annotationType: 'FreehandPolyline',
            positions: positions,
            id: id,
            zIndex: zIndex || defaultZIndex,
            options: {
                ...defaultFreehandPolylineOptions,
                ...freehandDrawOptions,
            },
        };
    };
}

export const defaultFreehandPolylineOptions: L.PolylineOptions = {
    stroke: true,
    color: '#e75554',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: undefined,
    fillColor: undefined,
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: true,
};
