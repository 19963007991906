import { useState } from 'react';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { StyledButton } from '../Shared/styled-button';

import MapCountTicker from './map-count-ticker';

const LandingPageLeftContent = () => {
    const [showRegister, setShowRegister] = useState<boolean>(false);

    return (
        <LandingPageLeftContainer>
            <MissionText>Our mission is to build the largest digital atlas of the world's maps and images.</MissionText>
            <ImpactText>
                <MapCountTicker /> maps and counting
            </ImpactText>
            <SoarLogo src="/assets/logos/soar_logo_large.png" data-testid="homepage-logo" />
            <SoarExploreButton
                onClick={() => {
                    UriHelper.navigateToPath('/maps');
                    Analytics.Event('LandingPage', 'Clicked explore the atlas');
                }}
            >
                <SoarExploreImage src="/assets/landing-page/landing_page_globe.png" alt="Landing page globe" />
                <p> Enter the Atlas </p>
            </SoarExploreButton>
            <LoginRegisterDialog
                isOpen={showRegister}
                initialMode={LoginModalMode.REGISTER}
                onClose={() => setShowRegister(!showRegister)}
            />
        </LandingPageLeftContainer>
    );
};

export default LandingPageLeftContent;

const LandingPageLeftContainer = styled.div`
    width: 50%;
    text-align: center;

    // Tablet
    @media only screen and (max-width: 1280px) {
        width: 100%;
    }
`;

const MissionText = styled.h6`
    font-weight: 300;
    color: white;
    font-size: 18px;
`;

const ImpactText = styled.h2`
    color: white;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.7px;
    margin: 30px 0px 10px 0px;
    cursor: pointer;

    div {
        color: white;
    }
`;

const SoarLogo = styled.img`
    width: 50%;
    max-width: 350px;
    object-fit: contain;
`;

const SoarExploreButton = styled(StyledButton)`
    border-radius: 8px;
    display: flex;
    margin: 10px auto 0px auto;
    align-items: center;

    p {
        text-transform: none;
        font-size: 20px;
        margin: -2px 0px 0px 12px;
    }
`;

const SoarExploreImage = styled.img`
    max-width: 35px;
    max-height: 35px;
`;
