import React from 'react';
import styled from 'styled-components';

interface JoinContentProps {
    type: string;
}

const JoinContent = ({ type }: JoinContentProps) => {
    switch (type) {
        case 'US Aerial':
            return (
                <React.Fragment>
                    <ContentHeader>Explore the biggest collection of FREE aerial photo maps of the US </ContentHeader>
                    <ContentFeatures>
                        <ContentFeature>Free aerial photos of most US States</ContentFeature>
                        <ContentFeature>Current and historical aerial photos available</ContentFeature>
                        <ContentFeature>
                            Collections available from USGS, USDA, university collections and local governments
                        </ContentFeature>
                        <ContentFeature>All online and no download required</ContentFeature>
                        <ContentFeature>World's fastest growing collection of online maps</ContentFeature>
                        <ContentFeature>All maps are QGIS software plugin ready</ContentFeature>
                    </ContentFeatures>
                </React.Fragment>
            );
        case 'AU Geology':
            return (
                <React.Fragment>
                    <ContentHeader>Explore the biggest collection of FREE geological maps of Australia</ContentHeader>
                    <ContentFeatures>
                        <ContentFeature>Continental coverage (all of Australia)</ContentFeature>
                        <ContentFeature>Both current and historical maps</ContentFeature>
                        <ContentFeature>
                            Easily accessed maps from Geoscience Australia and State agencies
                        </ContentFeature>
                        <ContentFeature>All online and no download required</ContentFeature>
                        <ContentFeature>World's fastest growing collection of online maps</ContentFeature>
                        <ContentFeature>All maps are QGIS software plugin ready</ContentFeature>
                    </ContentFeatures>
                </React.Fragment>
            );
        default:
            return (
                <React.Fragment>
                    <ContentHeader>Find the maps you are looking for, fast!</ContentHeader>
                    <ContentFeatures>
                        <ContentFeature>World's fastest growing collection of online maps</ContentFeature>
                        <ContentFeature>Over 641,035 maps&hellip; and growing!</ContentFeature>
                        <ContentFeature>100% FREE</ContentFeature>
                    </ContentFeatures>
                </React.Fragment>
            );
    }
};

export default JoinContent;

export const ContentHeader = styled.h1`
    font-weight: 700;
    font-size: 4rem;
`;

export const ContentFeatures = styled.ul`
    list-style: none;
    margin: 50px 65px 0;
    padding: 0;

    font-size: 1.75rem;
`;

export const ContentFeature = styled.li`
    margin-bottom: 20px;

    &:before {
        content: '✓';
        font-weight: 700;

        margin-right: 10px;
    }
`;
