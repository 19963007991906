import Analytics from '../../../../lib/user-analytics';
import store from '../../../../store/store';

export default class DrawToolAnalytics {
    public static clickedIcon = (iconName: string): void => {
        const drawProject = store.getState().drawProjectDomain.drawProject;
        Analytics.Event('Draw Tools - Project', `Clicked ${iconName} icon`, drawProject?.id || '');
    };

    public static add(name: string): void {
        const drawProject = store.getState().drawProjectDomain.drawProject;
        Analytics.Event('Draw Tools - Project', `Added ${name} to project`, drawProject?.id || '');
    }

    public static delete(name: string): void {
        const drawProject = store.getState().drawProjectDomain.drawProject;
        Analytics.Event('Draw Tools - Project', `Delete ${name} from project`, drawProject?.id || '');
    }

    public static deleteAll(): void {
        const drawProject = store.getState().drawProjectDomain.drawProject;
        Analytics.Event('Draw Tools - Project', 'Clicked delete all from project', drawProject?.id || '');
    }
}
