import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { ListingDTO } from '../../../api/model';

interface StoreMapTileLayer {
    tileLayerOpacity: { [id: number]: number };
    pinnedTileLayers: ListingDTO[];
}

const initialState: StoreMapTileLayer = {
    tileLayerOpacity: {},
    pinnedTileLayers: [],
};

export default (state: StoreMapTileLayer = initialState, action: Action): StoreMapTileLayer => {
    switch (action.type) {
        case at.TILE_LAYER_SET_OPACITY:
            return {
                ...state,
                tileLayerOpacity: {
                    ...state.tileLayerOpacity,
                    [action.payload.tileLayerId]: action.payload.opacity,
                },
            };

        case at.PIN_TILE_LAYER:
            return {
                ...state,
                pinnedTileLayers: [...state.pinnedTileLayers, action.payload],
            };

        case at.UNPIN_TILE_LAYER:
            if (state.pinnedTileLayers.findIndex((t) => t.id === action.payload.id) === -1) {
                return state;
            } else {
                const nextState = state.pinnedTileLayers.filter((t) => {
                    return t.id !== action.payload.id;
                });
                return {
                    ...state,
                    pinnedTileLayers: nextState,
                };
            }

        default:
            return state;
    }
};
