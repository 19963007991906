import { useEffect } from 'react';
import styled from 'styled-components';

interface RedirectPageProps {
    url: string;
}

const RedirectPage = ({ url }: RedirectPageProps) => {
    useEffect(() => {
        window.location.replace(url);
    }, [url]);

    return <RedirectMessage>Redirecting...</RedirectMessage>;
};

export default RedirectPage;

const RedirectMessage = styled.p`
    margin-top: 100px;
    text-align: center;
    color: ${(props) => props.theme.color.white};
`;
