import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import styled, { keyframes } from 'styled-components';
import ApiAutocomplete from '../../../../../../api/api-autocomplete';
import ApiListings from '../../../../../../api/api-listings';
import { AutocompleteDTO } from '../../../../../../api/model';
import Analytics from '../../../../../../lib/user-analytics';
import {
    actionSetSelectedCategory,
    actionSetSelectedContinent,
} from '../../../../../../store/Map/MapSelection/actions';
import { selectSelectedCategory, selectSelectedContinent } from '../../../../../../store/Map/MapSelection/selectors';
import { actionUpdateActiveSearchPosition } from '../../../../../../store/Search/actions';
import { useInvalidateSearchOnEscapeKey } from '../../../../../Search/use-invalidate-search-on-escape-key';
import { lightDropShadow } from '../../../../../../styles/style';

interface SearchInputProps {
    handleOnSearchInputHasFocus: (hasFocus: boolean) => void;
    handleOnSearchLoading: (isLoading: boolean) => void;
    handleAutocompleteResults: (autocompleteResults: AutocompleteDTO[]) => void;
    handleSearchTerm: (searchTerm: string) => void;
    handleEnterKeyPress: () => void;
    isLoading: boolean;
}

const DEBOUNCE_TIME = 700; //ms

const SearchInput = (props: SearchInputProps) => {
    const { handleOnSearchLoading, handleAutocompleteResults, handleSearchTerm } = props;

    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState<string>('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
    const [searchSubject] = useState(() => new Subject<string>());
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    const selectedContinent = useSelector(selectSelectedContinent);
    const selectedCategory = useSelector(selectSelectedCategory);

    const clearSelectedCategory = () => dispatch(actionSetSelectedCategory(undefined));
    const clearSelectedContinent = () => dispatch(actionSetSelectedContinent(undefined));

    useInvalidateSearchOnEscapeKey(searchInputRef.current, props.handleOnSearchInputHasFocus, setSearchInput);

    useEffect(() => {
        handleSearchTerm(debouncedSearchInput);
        if (debouncedSearchInput.length <= 2) {
            handleAutocompleteResults([]);
            handleOnSearchLoading(false);
            handleSearchTerm('');
        } else if (!selectedContinent) {
            handleOnSearchLoading(true);
            ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
            ApiAutocomplete.autocomplete(debouncedSearchInput, ['ADDRESS', 'USER'], 20)
                .then((res) => {
                    handleAutocompleteResults(res);
                })
                .finally(() => {
                    handleOnSearchLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchInput]);

    useEffect(() => {
        searchSubject.next(searchInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                tap((value) => {
                    if (value.length <= 2) {
                        handleAutocompleteResults([]);
                        handleOnSearchLoading(false);
                    }
                    ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
                    ApiListings.cancelGetListings('Cancel get listings');
                }),
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe((next) => {
                setDebouncedSearchInput(next);
            });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };

    const handleClearCloseContinent = (continent) => {
        Analytics.Event('Search Bar', 'Search Continent Cleared', continent);
        clearSelectedContinent();
    };

    const handleClearCloseCategory = (category: string) => {
        Analytics.Event('Search Bar', 'Search Category Cleared', category);
        clearSelectedCategory();
    };

    return (
        <SearchInputContainer id="search-input">
            <TagInputContainer>
                <TagList>
                    {selectedContinent && selectedContinent.continent ? (
                        <ContinentTag>
                            Continent: {selectedContinent.continent.name}
                            <TagRemoveButton
                                onClick={() =>
                                    handleClearCloseContinent(
                                        selectedContinent?.continent?.name ? selectedContinent.continent.name : ''
                                    )
                                }
                            >
                                <i className="fa fa-close" />
                            </TagRemoveButton>
                        </ContinentTag>
                    ) : null}

                    {selectedCategory ? (
                        <CategoryTag>
                            Category: {selectedCategory.label}
                            <TagRemoveButton onClick={() => handleClearCloseCategory(selectedCategory.label)}>
                                <i className="fa fa-close" />
                            </TagRemoveButton>
                        </CategoryTag>
                    ) : null}

                    <SearchInputItem>
                        <Input
                            ref={searchInputRef}
                            value={searchInput}
                            onChange={handleChange}
                            onFocus={() => props.handleOnSearchInputHasFocus(true)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.currentTarget.blur();
                                    props.handleEnterKeyPress();
                                }
                            }}
                            type="text"
                            placeholder={selectedContinent ? '' : 'Search for Maps!'}
                        />

                        {props.isLoading ? (
                            <LoadingSpinnerContainer>
                                <LoadingSpinner className="fa fa-spinner fa-spin" />
                            </LoadingSpinnerContainer>
                        ) : null}

                        {!props.isLoading && (searchInput.length > 0 || selectedContinent || selectedCategory) ? (
                            <CloseIconContainer>
                                <CloseIcon
                                    onClick={() => {
                                        setSearchInput('');
                                        clearSelectedContinent();
                                        clearSelectedCategory();
                                        dispatch(actionUpdateActiveSearchPosition(undefined));
                                        Analytics.Event('Search Bar', 'Search Cleared');
                                    }}
                                    src="/assets/close.png"
                                />
                            </CloseIconContainer>
                        ) : null}
                    </SearchInputItem>
                </TagList>
            </TagInputContainer>
        </SearchInputContainer>
    );
};

export default SearchInput;

const SearchInputContainer = styled.div`
    position: fixed;
    width: 40vw;
    max-width: 640px;
    left: 0;
    right: 0;
    top: 94px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    display: block;
    z-index: 10003;
    transform: translate(20px, 0);

    ${lightDropShadow}
`;

const TagInputContainer = styled.div`
    background: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 5px;
    background: url('/assets/globe-icon-white.png') no-repeat;
    background-size: 40px;
    padding-left: 40px;

    border-radius: 6px;

    &:focus {
        outline: black;
        border: 1px solid rgba(0, 0, 0, 0.7);
    }
`;

const TagList = styled.ul`
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 30px;
    list-style: none;
`;

const TagItem = styled.li`
    border-radius: 4px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    margin: 2px 2px 0 2px;
    padding: 1px 2px 2px 5px;
    text-align: center;
    font-size: 14px;
    cursor: default;
`;

const ContinentTag = styled(TagItem)`
    border: 1px solid #eed926;
    border-radius: 8px;
    background: #191a1a;
    color: white;
`;

const CategoryTag = styled(TagItem)`
    border: 1px solid #00a2ff;
    border-radius: 8px;
    background: #191a1a;
    color: white;
`;

const TagRemoveButton = styled.button`
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    color: #b3b3b3;
    padding-top: -2px;
    cursor: pointer;
    display: inline-flex;
    font-weight: lighter;
    font-size: 0.8rem;

    :hover {
        color: white;
    }

    :focus {
        outline: none !important;
    }
`;

const SearchInputItem = styled.li`
    background: none;
    flex-grow: 1;
    padding: 0;
    margin: 4px 0 0 8px;
`;

const Input = styled.input`
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    color: white;
    padding: 0;

    &::placeholder {
        color: ${(props) => props.theme.color.lightGray};
    }
`;

const LoadingSpinnerContainer = styled.div`
    position: absolute;
    right: 10px;
    height: 20px;
    width: 20px;
    top: calc(50% - 10px);
`;

const Rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const LoadingSpinner = styled.i`
    position: relative;
    font-size: 1.4rem;
    width: 100%;
    height: 100%;
    display: inline-block;
    animation: ${Rotate} 1s linear infinite;
    color: #a3a3a3;
`;

const CloseIconContainer = styled(LoadingSpinnerContainer)`
    position: absolute;
    right: 10px;
    height: 18px;
    width: 18px;
    top: calc(50% - 12px);
    cursor: pointer;
`;

const CloseIcon = styled.img`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin: 3px 2px 0 0;
    cursor: pointer;
    float: right;
`;
