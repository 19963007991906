import Polyline from '../../../components/MapView/Annotations/Polyline/polyline';
import { actionTypes as at } from './constants';

export const setPolylinesAction = (polylines: Polyline[]) => {
    return {
        type: at.SET_POLYLINES,
        payload: { polylines: polylines },
    };
};

export const setPolylinesOptionsAction = (options: L.PathOptions) => {
    return {
        type: at.SET_POLYLINE_OPTIONS,
        payload: options,
    };
};

export const setPolylineAddAction = (polyline: Polyline) => {
    return {
        type: at.SET_POLYLINE_ADD,
        payload: polyline,
    };
};

export const setPolylineUpdateAction = (polyline: Polyline) => {
    return {
        type: at.SET_POLYLINE_UPDATE,
        payload: polyline,
    };
};

export const setPolylineDeleteAction = (polyline: Polyline) => {
    return {
        type: at.SET_POLYLINE_DELETE,
        payload: polyline,
    };
};
