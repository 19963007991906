import React, { useState } from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import Analytics from '../../../lib/user-analytics';
import profileIconUrl from '../profile-icon-url';
import { isMobileVersion } from '../../../lib/soar-helper';
const moment = require('moment');

import ImagePreviewFailed, { PreviewSize } from '../../Shared/image-preview-failed';

interface RecentActivityItemProps {
    listing: ListingDTO;
}

const RecentActivityItem = (props: RecentActivityItemProps) => {
    const [imageFailed, setImageFailed] = useState(false);
    const username = props.listing.userName || props.listing.userEmail;
    const timeAgo = moment(new Date(props.listing.createdAt * 1000)).fromNow();
    const title = props.listing.title;

    const handleClickFilename = () => {
        Analytics.Event('My Dashboard', 'Clicked recent activity item', props.listing.id);
    };

    return (
        <RecentActivityLi>
            {isMobileVersion ? (
                <RecentActivityMobileContainer>
                    <React.Fragment>
                        {imageFailed ? (
                            <RecentActivityImageFailed previewSize={PreviewSize.SMALL} />
                        ) : props.listing.id ? (
                            <RecentActivityImage
                                src={ListingHelper.getPreviewUrlForListing(props.listing.id)}
                                onLoad={() => console.log('RecentActivityImage.onLoad')}
                                onError={() => setImageFailed(true)}
                            />
                        ) : (
                            <RecentActivityImage
                                src={profileIconUrl.satelliteImageUrl}
                                onError={() => setImageFailed(true)}
                            />
                        )}
                    </React.Fragment>
                    <RecentActivityDetailsDiv>
                        <RecentActivityUserName>{username}</RecentActivityUserName>
                        <RecentActivityUploadDate>uploaded a file {timeAgo}</RecentActivityUploadDate>
                        <RecentActivityFileName onClick={handleClickFilename}>{title}</RecentActivityFileName>
                    </RecentActivityDetailsDiv>
                </RecentActivityMobileContainer>
            ) : (
                <DashboardActivityContainer>
                    <DashBoardImageContainer
                        href={`/maps/${props.listing.id}`}
                        target="_blank"
                        onClick={handleClickFilename}
                    >
                        {imageFailed ? (
                            <DashBoardImageFailed previewSize={PreviewSize.SMALL} />
                        ) : props.listing.id ? (
                            <DashBoardImage
                                src={ListingHelper.getPreviewUrlForListing(props.listing.id)}
                                onError={() => setImageFailed(true)}
                            />
                        ) : (
                            <DashBoardImage
                                src={profileIconUrl.satelliteImageUrl}
                                onError={() => setImageFailed(true)}
                            />
                        )}
                    </DashBoardImageContainer>
                    <DashboardActivityDetails>
                        <DashboardActivityDetail>
                            <RecentActivityUserName>{username}</RecentActivityUserName>
                            <RecentActivityUploadDate>&nbsp; uploaded {timeAgo}</RecentActivityUploadDate>
                        </DashboardActivityDetail>
                        <DashboardActivityDetail>
                            <RecentActivityFileName onClick={handleClickFilename}>{title}</RecentActivityFileName>
                        </DashboardActivityDetail>
                    </DashboardActivityDetails>
                </DashboardActivityContainer>
            )}
        </RecentActivityLi>
    );
};

export default RecentActivityItem;

const DashboardActivityContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const DashBoardImageContainer = styled.a`
    margin-right: 5px;
    display: block;
`;

const DashBoardImage = styled.img`
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 6px;
    margin: 10px 0px;
`;

const DashBoardImageFailed = styled(ImagePreviewFailed)``;

const DashboardActivityDetails = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const DashboardActivityDetail = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const RecentActivityLi = styled.li`
    border-top: 1px solid rgba(255, 255, 255, 0.37);
    list-style-type: none;
`;

const RecentActivityMobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

const RecentActivityImageFailed = styled(ImagePreviewFailed)``;

const RecentActivityImage = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 6px;
    margin: 10px 0px;
`;

const RecentActivityDetailsDiv = styled.div`
    padding-top: 7px;
    width: calc(100vw - 140px);
`;

const RecentActivityUserName = styled.div`
    color: rgba(255, 255, 255, 0.87);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const RecentActivityUploadDate = styled.p`
    color: rgba(255, 255, 255, 0.87);
`;

const RecentActivityFileName = styled.a`
    color: rgba(255, 255, 255, 0.87) !important;
    text-decoration: underline !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding-top: 7px;
`;
