import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Annotation } from '../../../store/Annotations/reducer';

import {
    isPointInsidePolygon,
    areaOfBoundingBox,
    areaOfPolygon,
    areaOfPolylineWithBuffer,
    isPointCloseToPolyline,
    getMarkerBoundingBox,
    getCoordinateBoundingBox,
    isBoundsLargeEnoughToBeClickCandidate,
    getMilitaryMarkerBoundingBox,
} from './use-select-annotation-utils';

import Marker from './Marker/marker';
import PolygonPath from './Polygon/polygon';
import Circle from './Circle/circle';
import Polyline from './Polyline/polyline';
import Rectangle from './Rectangle/rectangle';
import Image from './ImageTool/image';
import TextBox from './Text/textbox';
import Arrow from './Arrow/arrow';
import Coordinate from './Coordinate/coordinate';

import { setSelectedAnnotationAction } from '../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../store/Annotations/selectors';

import { selectArrows } from '../../../store/Annotations/Arrow/selectors';
import { selectMarkers } from '../../../store/Annotations/Marker/selectors';
import { selectPolylines } from '../../../store/Annotations/Path/selectors';
import { selectCircles } from '../../../store/Annotations/Circle/selectors';
import { selectRectangles } from '../../../store/Annotations/Rectangle/selectors';
import { selectCoordinates } from '../../../store/Annotations/Coordinate/selectors';
import { selectFreehandDraws } from '../../../store/Annotations/Freehand/selectors';
import { selectPolygons } from '../../../store/Annotations/Polygon/selectors';
import { selectTextBoxes } from '../../../store/Annotations/TextBox/selectors';
import { selectImages } from '../../../store/Annotations/Images/selectors';

import { defaultZIndex } from './layers-util';
import { selectMilitaryMarkers } from '../../../store/Annotations/MilitaryMarker/selectors';
import MilitaryMarker from './MilitaryMarker/military-marker';

// This constant is an important value for the user experience of clicking vs dragging annotations
// A mouse click fires the following sequence of events; `mouseDown`, `mouseUp` and finally `click`.
// We use this to determine if the user intended to drag an annotation instead of clicking to select
const DRAG_VS_CLICK_THRESHOLD = 250; //ms;

export const useSelectAnnotation = (isBuilderActive: boolean, isEditMode: boolean) => {
    const map = useMap();
    const dispatch = useDispatch();

    const [mouseDownTime, setMouseDownTime] = useState<number | undefined>(undefined);
    const [mouseUpTime, setMouseUpTime] = useState<number | undefined>(undefined);

    const markers = useSelector(selectMarkers);
    const militaryMarkers = useSelector(selectMilitaryMarkers);
    const polylines = useSelector(selectPolylines);
    const polygons = useSelector(selectPolygons);
    const circles = useSelector(selectCircles);
    const rectangles = useSelector(selectRectangles);
    const freehandDraws = useSelector(selectFreehandDraws);
    const arrows = useSelector(selectArrows);
    const images = useSelector(selectImages);
    const coordinates = useSelector(selectCoordinates);
    const textBoxes = useSelector(selectTextBoxes);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);

    const allAnnotations = useMemo(
        () => [
            ...markers,
            ...militaryMarkers,
            ...polylines,
            ...polygons,
            ...circles,
            ...rectangles,
            ...freehandDraws,
            ...arrows,
            ...images,
            ...coordinates,
            ...textBoxes,
        ],
        [
            markers,
            militaryMarkers,
            polylines,
            polygons,
            circles,
            rectangles,
            freehandDraws,
            arrows,
            images,
            coordinates,
            textBoxes,
        ]
    );

    const onClickMap = useCallback(
        (e: L.LeafletMouseEvent) => {
            // If the user clicked on an annotation control, do not attempt to select an annotation on the map
            // @ts-ignore - Leaflet typings are incorrect classList exists on originalEvent.target...
            if (e.originalEvent.target?.classList.contains('annotation-control-icon')) {
                return;
            }

            // Do not attempt to select something while the user is building an annotation
            if (isBuilderActive) {
                e.originalEvent.stopPropagation(); // Allow build events to occur
                return;
            }

            // Do not attempt to select something when not in edit mode
            if (!isEditMode) {
                return;
            }

            // If the user has been holding down the mouse for more than the threshold, it means that they
            // intend to move something. We don't want to select a different annotation if this is the case.
            if (mouseDownTime && mouseUpTime && mouseUpTime - mouseDownTime > DRAG_VS_CLICK_THRESHOLD) {
                setMouseDownTime(undefined);
                setMouseUpTime(undefined);
                return;
            }
            setMouseDownTime(undefined);
            setMouseUpTime(undefined);

            const latlng = e.latlng;
            const bestGuess = allAnnotations
                .filter((t) => t && t.id !== selectedAnnotation?.id)
                .filter((annotation) => {
                    // Filter potential click candidates
                    // If the click position is inside a polygon, or the distance to the polyline is less than
                    // 20 pixels in screen space, it is considered a click candidate
                    switch (annotation.annotationType) {
                        case 'Marker':
                            return getMarkerBoundingBox(map, annotation as Marker).contains(latlng);

                        case 'MilitaryMarker':
                            return getMilitaryMarkerBoundingBox(map, annotation as MilitaryMarker).contains(latlng);

                        case 'Coordinate':
                            return getCoordinateBoundingBox(map, annotation as Coordinate).contains(latlng);

                        case 'Polygon':
                            return isPointInsidePolygon(map, latlng, (annotation as PolygonPath).positions);

                        case 'FreehandPolyline':
                            return isPointCloseToPolyline(map, latlng, (annotation as Polyline).positions);

                        case 'Polyline':
                            return isPointCloseToPolyline(map, latlng, (annotation as Polyline).positions);

                        case 'Circle':
                            return latlng.distanceTo((annotation as Circle).center) < (annotation as Circle).radius;

                        case 'Rectangle':
                            return (annotation as Rectangle).bounds.contains(latlng);

                        case 'Image':
                            return (annotation as Image).bounds.contains(latlng);

                        case 'TextBox':
                            return (
                                isBoundsLargeEnoughToBeClickCandidate(map, (annotation as TextBox).bounds) &&
                                (annotation as TextBox).bounds.contains(latlng)
                            );

                        case 'Arrow':
                            return isPointCloseToPolyline(map, latlng, [
                                (annotation as Arrow).startLatLng,
                                (annotation as Arrow).endLatLng,
                            ]);

                        default:
                            return false;
                    }
                })
                .sort((a, b) => {
                    // Decide which one is the best click candidate. Currently, by the one with the smallest area
                    // The rationale being that the smallest annotation is probably the one the user intended to click.
                    // Improvement idea: Use point to line distance for polylines and Math.min(distanceToPerimeter, distanceToCentroid) for polygons

                    // Improvement idea: Maintain a list of previous click candidates and cycle through them if they
                    // are the same.  This would allow even nicer cycling through overlapping annotations.
                    const area = (annotation: Annotation) => {
                        if (!annotation) return 0;

                        switch (annotation.annotationType) {
                            case 'Marker':
                                return areaOfBoundingBox(getMarkerBoundingBox(map, annotation as Marker));

                            case 'MilitaryMarker':
                                return areaOfBoundingBox(
                                    getMilitaryMarkerBoundingBox(map, annotation as MilitaryMarker)
                                );

                            case 'Coordinate':
                                return areaOfBoundingBox(getCoordinateBoundingBox(map, annotation as Coordinate));

                            case 'Polygon':
                                return areaOfPolygon((annotation as PolygonPath).positions);

                            case 'FreehandPolyline':
                                return areaOfPolylineWithBuffer(map, (annotation as Polyline).positions);

                            case 'Polyline':
                                // TODO: Create an envelope around the polyline and calculate its area for more accurate sorting of potential click candidates
                                return areaOfPolylineWithBuffer(map, (annotation as Polyline).positions);

                            case 'Circle':
                                return latlng.distanceTo((annotation as Circle).center);

                            case 'Rectangle':
                                return areaOfBoundingBox((annotation as Rectangle).bounds);

                            case 'Image':
                                return areaOfBoundingBox((annotation as Image).bounds);

                            case 'TextBox':
                                return areaOfBoundingBox((annotation as TextBox).bounds);

                            case 'Arrow':
                                return areaOfPolygon([
                                    (annotation as Arrow).startLatLng,
                                    (annotation as Arrow).endLatLng,
                                ]);

                            default:
                                return 0;
                        }
                    };
                    return area(a) - area(b);
                });

            if (bestGuess) {
                // sorting by zIndex of layers
                const bestGuessZIndexSorted = bestGuess.sort((a, b) => {
                    const aZIndex = a.zIndex || defaultZIndex;
                    const bZIndex = b.zIndex || defaultZIndex;
                    if (aZIndex < bZIndex) {
                        return 1;
                    }
                    if (aZIndex > bZIndex) {
                        return -1;
                    }
                    return 0;
                });
                requestAnimationFrame(() => {
                    dispatch(setSelectedAnnotationAction(bestGuessZIndexSorted[0])); // use the top-most layer
                });
            } else {
                dispatch(setSelectedAnnotationAction(undefined));
            }
        },
        [dispatch, map, mouseDownTime, mouseUpTime, allAnnotations, selectedAnnotation, isBuilderActive, isEditMode]
    );

    const onMouseUp = () => {
        setMouseUpTime(Date.now());
    };

    const onMouseDown = () => {
        setMouseDownTime(Date.now());
    };

    useEffect(() => {
        map.on('click', onClickMap);
        map.on('mousedown', onMouseDown);
        map.on('mouseup', onMouseUp);

        return () => {
            map.off('click', onClickMap);
            map.off('mousedown', onMouseDown);
            map.off('mouseup', onMouseUp);
        };
    }, [dispatch, map, selectedAnnotation, onClickMap]);
};
