import { toast } from 'react-toastify';
import { actionTypes as at } from './constants';
import ApiDraw from '../../../api/api-draw';
import { UpdateStoaryDataRequest, UpdateStoaryRequest } from '../../../api/stoaryModel';

export const actionFetchUserDrawProjects = () => {
    return async (dispatch) => {
        try {
            const drawProjects = await ApiDraw.getStoaries();

            dispatch({
                type: at.DRAW_PROJECTS_FETCH_SUCCESS,
                payload: drawProjects,
            });
        } catch (err) {
            toast.error(err.message);
        }
    };
};

export const actionFetchSharedDrawProjects = () => {
    return async (dispatch) => {
        try {
            const drawProjects = await ApiDraw.getSharedStoaries();

            dispatch({
                type: at.DRAW_PROJECTS_SHARED_FETCH_SUCCESS,
                payload: drawProjects,
            });
        } catch (err) {
            toast.error(err.message);
        }
    };
};

export const actionUpdateDrawProject = (projectId: number, project: UpdateStoaryRequest) => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_UPDATING,
            payload: true,
        });
        try {
            const drawProject = await ApiDraw.updateStoary(projectId, project);

            let updatedPayload = drawProject;
            if ((project as UpdateStoaryDataRequest)?.data) {
                updatedPayload = { ...updatedPayload, data: (project as UpdateStoaryDataRequest).data };
            }

            dispatch({
                type: at.DRAW_PROJECT_UPDATE,
                payload: updatedPayload,
            });
        } catch (err) {
            toast.error(err.message);
        } finally {
            dispatch({
                type: at.DRAW_PROJECT_UPDATING,
                payload: true,
            });
        }
    };
};

export const actionDeleteStoary = (projectId: number) => {
    return async (dispatch) => {
        dispatch({
            type: at.DRAW_PROJECT_DELETING,
            payload: true,
        });
        try {
            await ApiDraw.deleteStoary(projectId);
            toast.dark('Stoary deleted successfully!');
            dispatch({
                type: at.DRAW_PROJECT_DELETE,
                payload: projectId,
            });
        } catch (err) {
            toast.error(err.message);
        } finally {
            dispatch({
                type: at.DRAW_PROJECT_DELETING,
                payload: false,
            });
        }
    };
};
