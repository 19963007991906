export const actionTypes = {
    SATELLITE_BEGIN_BOX_SELECT: 'SATELLITE_ARCHIVE/BEGIN_BOX_SELECT',
    SATELLITE_END_BOX_SELECT: 'SATELLITE_ARCHIVE/END_BOX_SELECT',
    SATELLITE_SELECT_AOI: 'SATELLITE_ARCHIVE/SELECT_AOI',
    SATELLITE_RESET_AOI: 'SATELLITE_ARCHIVE/RESET_AOI',

    SATELLITE_SET_FEATURE_ACTIVE: 'SATELLITE_ARCHIVE/SET_FEATURE_ACTIVE',
    SATELLITE_SET_FEATURE_INACTIVE: 'SATELLITE_ARCHIVE/SET_FEATURE_INACTIVE',
    SATELLITE_RESET_ACTIVE_FEATURES: 'SATELLITE_ARCHIVE/RESET_ACTIVE_FEATURES',
    SATELLITE_REORDER_Z_INDEX: 'SATELLITE_ARCHIVE/REORDER_Z_INDEX',

    SATELLITE_FEATURE_MOUSE_OVER: 'SATELLITE_ARCHIVE/FEATURE_MOUSE_OVER',
    SATELLITE_FEATURE_MOUSE_OUT: 'SATELLITE_ARCHIVE/FEATURE_MOUSE_OUT',
};
