import React, { useState } from 'react';
import { RgbColor, RgbColorPicker } from 'react-colorful';
import styled from 'styled-components';
import { StyledButton } from '../../../Shared/styled-button';

interface FillColorCustomSelectionProps {
    selectedColor: string;
    onSelectColor: (color: string) => void;
    onClose: () => void;
}

const FillColorCustomSelection = (props: FillColorCustomSelectionProps) => {
    const [selectedColor, setSelectedColor] = useState(props.selectedColor);

    const colorToRGB = (color: string): RgbColor => {
        const rgba: RgbColor = {
            r: parseInt(color.slice(1, 3), 16),
            g: parseInt(color.slice(3, 5), 16),
            b: parseInt(color.slice(5, 7), 16),
        };
        return rgba;
    };

    const rgbToColor = (rgb: RgbColor): string => {
        return isNaN(rgb.r) || isNaN(rgb.g) || isNaN(rgb.b)
            ? '#000000'
            : `#${rgb.r.toString(16).padStart(2, '0')}${rgb.g.toString(16).padStart(2, '0')}${rgb.b
                  .toString(16)
                  .padStart(2, '0')}`;
    };

    return (
        <React.Fragment>
            <ColorPickerContainer>
                <RgbColorPicker
                    color={colorToRGB(selectedColor)}
                    onChange={(newColor) => {
                        setSelectedColor(rgbToColor(newColor));
                    }}
                />
                <Button onClick={() => props.onSelectColor(rgbToColor(colorToRGB(selectedColor)))}>Select Color</Button>
            </ColorPickerContainer>
        </React.Fragment>
    );
};

export default FillColorCustomSelection;

const ColorPickerContainer = styled.div`
    margin: 8px 0 0 32px !important;
`;

const Button = styled(StyledButton)`
    margin: 8px 0 0 22px;
`;
