import styled from 'styled-components';
import { UserDTO } from '../../../api/model';
import PointsUtil from '../../NavigationBar/Points/points-util';
import ProfileName from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';
import UserAvatar from '../../Shared/Avatar/user-avatar';
import { useState } from 'react';
import PointsTooltip from './points-tooltip';

interface DashboardHomeProfileProps {
    profile: UserDTO;
}

const DashboardHomeProfile = (props: DashboardHomeProfileProps) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <ProfileContainer>
            <UserAvatar user={props.profile} margin="0" diameter={80} />
            <ProfileName isCommunityModerator={props.profile.communityModerator} isReputable={props.profile.reputable}>
                {props.profile.name ?? 'Soar User'}
            </ProfileName>
            <ProfilePoints onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
                {PointsUtil.pointsFormatter(props.profile.points)} points
            </ProfilePoints>

            {showTooltip && <PointsTooltip />}
        </ProfileContainer>
    );
};

export default DashboardHomeProfile;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
`;

const ProfilePoints = styled.span`
    color: #eed926;
    font-weight: bold;
    cursor: pointer;
`;
