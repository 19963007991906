import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';

interface SideDrawerSearchProps {
    listings: ListingDTO[];
    setListings: (listings: ListingDTO[] | undefined) => void;
}

const SideDrawerSearch = ({ listings, setListings }: SideDrawerSearchProps) => {
    const [searchTerm, setSearchTerm] = useState('');

    const searchFilter = (title: string, tags: string[], userName: string, searchTerm: string): boolean => {
        if (searchTerm?.length > 0) {
            const searchTermLowerCase = searchTerm.toLocaleLowerCase();
            return `${title}${userName}${tags?.join(' ')}`.toLocaleLowerCase().includes(searchTermLowerCase);
        }
        return true;
    };

    useEffect(() => {
        if (!listings?.length) return;
        const filteredListings = listings.filter((listing) =>
            searchFilter(listing.title, listing.tags, listing.userName, searchTerm.trim())
        );
        setListings(filteredListings);
    }, [listings, searchTerm, setListings]);

    const handleClearSearch = () => {
        setSearchTerm('');
        setListings(listings);
    };

    return (
        <SearchContainer>
            <SearchIcon className="fa fa-search" />
            <SearchInput
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                }}
            />
            {searchTerm && <ClearIcon className="fa fa-times" onClick={handleClearSearch} />}
        </SearchContainer>
    );
};

export default SideDrawerSearch;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 97%;
    height: 40px;
    margin: 10px 0px 5px 5px;
`;

const SearchIcon = styled.i`
    padding: 10px;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 3px 30px;
    background: transparent;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    height: 35px;

    :focus {
        outline: none;
    }
`;

const ClearIcon = styled.i`
    padding: 10px;
    font-size: 16px;
    color: white;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;
