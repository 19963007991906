import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Permission } from '../../../api/model';
import SoarHelper, { isMobileVersion } from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { DashboardMode } from '../../../store/Dashboard/model';
import { actionLogoutUser } from '../../../store/Account/actions';
import { selectMyProfile } from '../../../store/Account/selectors';
import profileIconUrl from '../profile-icon-url';
import MenuLink from './menu-link';
import NavigationPanel from '../Shared/navigation-panel';
import SignOutModal from '../../Shared/Modal/sign-out';

interface MenuSubdomainProps {
    dashboardMode?: DashboardMode;
}

const MenuSubdomain = (props: MenuSubdomainProps) => {
    const myProfile = useSelector(selectMyProfile);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isWrite, setIsWrite] = useState(false);
    const { dashboardMode } = props;

    const dispatch = useDispatch();

    const [userPermission, setUserPermission] = useState(Permission.None);
    const [isUserLogout, setIsUserLogout] = useState(false);

    useEffect(() => {
        if (myProfile) {
            const permission = UserHelper.getSubdomainPermission(myProfile);
            setUserPermission(permission);
            if (!isMobileVersion && permission < Permission.Review) {
                handleModeSelected(DashboardMode.PROFILE);
            }
        }
    }, [myProfile]);

    useEffect(() => {
        setIsAdmin(userPermission >= Permission.Admin);
        setIsWrite(userPermission >= Permission.Write);
    }, [userPermission]);

    const logout = () => {
        Analytics.Event('My Dashboard', 'Clicked to sign out');
        dispatch(actionLogoutUser());
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    const handleModeSelected = (mode: DashboardMode) => {
        UriHelper.navigateToDashboard(mode);
        Analytics.Event('My Dashboard', 'Clicked menu item', mode);
    };

    return (
        <SubdomainUserDashboardContainer>
            {isMobileVersion && <NavigationPanel label={'Dashboard'} />}
            <MenuContainer>
                <SubdomainUserDetails>
                    <SubdomainDashboardUserName>{`Hello, ${
                        myProfile?.firstName || 'Soar User'
                    }`}</SubdomainDashboardUserName>
                    <SubdomainUserDetailsSubtext>
                        You have {SoarHelper.permissionsEnumToUIText(userPermission)} access on the page.
                    </SubdomainUserDetailsSubtext>
                </SubdomainUserDetails>
                <MenuList>
                    {(isAdmin || isWrite) && (
                        <MenuLink
                            handleMenuItemClick={() => handleModeSelected(DashboardMode.SUBDOMAIN_OVERVIEW)}
                            iconLight={profileIconUrl.dashboardLightUrl}
                            iconDark={profileIconUrl.dashboardDarkUrl}
                            selected={dashboardMode === DashboardMode.SUBDOMAIN_OVERVIEW}
                            label="Dashboard"
                        />
                    )}

                    {(isAdmin || isWrite) && (
                        <MenuLink
                            handleMenuItemClick={() => handleModeSelected(DashboardMode.DATA_MANAGEMENT)}
                            iconLight={profileIconUrl.dataManagementLightUrl}
                            iconDark={profileIconUrl.dataManagementDarkUrl}
                            selected={dashboardMode === DashboardMode.DATA_MANAGEMENT}
                            label="Data Management"
                        />
                    )}

                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.MY_DRAW_PROJECTS)}
                        iconLight={profileIconUrl.drawProjectLightUrl}
                        iconDark={profileIconUrl.drawProjectDarkUrl}
                        selected={dashboardMode === DashboardMode.MY_DRAW_PROJECTS}
                        label="My Stoaries"
                    />

                    {(isAdmin || isWrite) && (
                        <MenuLink
                            handleMenuItemClick={() => handleModeSelected(DashboardMode.SATELLITE_QUEUE)}
                            iconLight={profileIconUrl.satelliteQueueLightUrl}
                            iconDark={profileIconUrl.satelliteQueueDarkUrl}
                            selected={dashboardMode === DashboardMode.SATELLITE_QUEUE}
                            label="My Satellite Orders"
                        />
                    )}

                    {isAdmin && (
                        <MenuLink
                            handleMenuItemClick={() => handleModeSelected(DashboardMode.USER_MANAGEMENT)}
                            iconLight={profileIconUrl.userManagementLightUrl}
                            iconDark={profileIconUrl.userManagementDarkUrl}
                            selected={dashboardMode === DashboardMode.USER_MANAGEMENT}
                            label="User Management"
                        />
                    )}

                    {isAdmin && (
                        <MenuLink
                            handleMenuItemClick={() => handleModeSelected(DashboardMode.ACCOUNT_MANAGEMENT)}
                            iconLight={profileIconUrl.payoutSettingsLightUrl}
                            iconDark={profileIconUrl.payoutSettingsDarkUrl}
                            selected={dashboardMode === DashboardMode.ACCOUNT_MANAGEMENT}
                            label="Account Billing"
                        />
                    )}

                    <MenuLink
                        handleMenuItemClick={() => handleModeSelected(DashboardMode.PROFILE)}
                        iconLight={profileIconUrl.profileLightUrl}
                        iconDark={profileIconUrl.profileDarkUrl}
                        selected={dashboardMode === DashboardMode.PROFILE}
                        label="Edit My Profile"
                    />

                    <MenuLink
                        handleMenuItemClick={() => setIsUserLogout(true)}
                        iconLight={profileIconUrl.logoutLightUrl}
                        iconDark={profileIconUrl.logoutDarkUrl}
                        selected={false}
                        label="Sign Out"
                    />
                </MenuList>
            </MenuContainer>

            <SubdomainUserDashboardTermsLink onClick={() => handleModeSelected(DashboardMode.TERMS_AND_CONDITION)}>
                Terms & Conditions
            </SubdomainUserDashboardTermsLink>
            <SignOutModal
                isOpen={isUserLogout}
                onToggle={() => setIsUserLogout(false)}
                onSignOut={() => {
                    setIsUserLogout(false);
                    logout();
                }}
            />
        </SubdomainUserDashboardContainer>
    );
};

export default MenuSubdomain;

const SubdomainUserDashboardContainer = styled.div`
    color: white;
    width: 330px;
    margin: 0px 15px;
`;

const MenuList = styled.div`
    // Makes only the menu item section scrollable
    max-height: calc(100vh - 400px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }
    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const MenuContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    width: inherit;
`;

const SubdomainUserDashboardTermsLink = styled.div`
    text-decoration-line: underline;
    padding-top: 10px;
    text-align: center;
    color: #fff;
    cursor: pointer;
`;

const SubdomainUserDetails = styled.div`
    color: white;
    padding: 10px 5px 0px 5px;
`;

const SubdomainDashboardUserName = styled.h1`
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SubdomainUserDetailsSubtext = styled.p`
    color: white;
    text-align: center;
`;
