import Polyline from '../../../components/MapView/Annotations/Polyline/polyline';
import { actionTypes as at } from './constants';
import { Action } from '../../root-reducer';

interface PathState {
    polylines: Polyline[];
    polylineOptions?: L.PolylineOptions;
}

const initialState: PathState = {
    polylines: [],
    polylineOptions: undefined,
};

export default (state: PathState = initialState, action: Action): PathState => {
    switch (action.type) {
        case at.SET_POLYLINES:
            return { ...state, polylines: action.payload.polylines };

        case at.SET_POLYLINE_OPTIONS:
            return { ...state, polylineOptions: action.payload };

        case at.SET_POLYLINE_ADD:
            return {
                ...state,
                polylines: [...state.polylines, action.payload],
            };

        case at.SET_POLYLINE_UPDATE: {
            const newPolylines = state.polylines.map((polyline) => {
                if (polyline.id === action.payload.id) {
                    return { ...polyline, ...action.payload };
                }
                return polyline;
            });
            return { ...state, polylines: newPolylines };
        }

        case at.SET_POLYLINE_DELETE: {
            const newPolylines = state.polylines.filter((polyline) => polyline.id !== action.payload.id);
            return { ...state, polylines: newPolylines };
        }

        default:
            return state;
    }
};
