import Api from './api';
import ApiUser from './api-user';
import { CommentDTO, CommentResultsDTO, CommentReportDTO } from './model';

// TODO: #3059 - Refactor 'any'
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ApiComments extends Api {
    public static createComment(comment: CommentDTO): Promise<CommentDTO> {
        return this.axios
            .post('/v1/comments', comment, { cache: false })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                ApiUser.updateMyPoints();
                return data;
            });
    }

    public static getCommentById(commentId: string): Promise<CommentDTO> {
        return this.axios.get('/v1/comments/' + commentId, { cache: false }).then((res) => {
            return res.data;
        });
    }

    public static async getCommentsForListingById(commentId: string): Promise<CommentResultsDTO[]> {
        const res = await this.axios.get('/v1/comments/listing/' + commentId, { cache: false });
        return res.data;
    }

    public static editComment(commentId: string, updatedComment: { text: any; annotations: any }): Promise<CommentDTO> {
        return this.axios
            .put('/v1/comments/' + commentId, {
                text: updatedComment.text,
                annotations: updatedComment.annotations,
            })
            .then((res) => {
                return res.data;
            });
    }

    public static deleteComment(commentId: string) {
        return this.axios.delete('/v1/comments/' + commentId);
    }

    public static reportComment(commentId: string, reason: string, message: string): Promise<CommentReportDTO> {
        return this.axios
            .post(`/v1/comments/${commentId}/report`, {
                reason: reason,
                message: message,
            })
            .then((res) => {
                return res.data;
            });
    }

    public static upVote(commentId: string): Promise<boolean> {
        return this.axios
            .put(`/v1/comments/${commentId}/vote`, {
                vote: 'UP',
            })
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    }

    public static downVote(commentId: string): Promise<boolean> {
        return this.axios
            .put(`/v1/comments/${commentId}/vote`, {
                vote: 'DOWN',
            })
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    }

    public static removeVote(commentId: string): Promise<boolean> {
        return this.axios
            .put(`/v1/comments/${commentId}/vote`, {
                vote: 'UNVOTE',
            })
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    }

    public static createCommentReply(comment: CommentDTO): Promise<CommentDTO> {
        return this.axios
            .post('/v1/comments', comment)
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                ApiUser.updateMyPoints();
                return data;
            });
    }
}
