import { LatLngBounds } from 'leaflet';
import { actionTypes as at } from './constants';
import { Action } from '../../root-reducer';
import { SatelliteArchiveImageryDTO } from '../../../api/api-supplier';

interface SatelliteArchiveStore {
    isSatelliteBoxSelectActive: boolean;
    satelliteAOI: LatLngBounds | undefined;
    satelliteSelectedFeatures: SatelliteArchiveImageryDTO[];
    satelliteHighlightedFeature: SatelliteArchiveImageryDTO | undefined;
    satelliteZIndex: { [sceneId: number]: number };
}

const initialState: SatelliteArchiveStore = {
    isSatelliteBoxSelectActive: false,
    satelliteAOI: undefined,
    satelliteSelectedFeatures: [],
    satelliteHighlightedFeature: undefined,
    satelliteZIndex: {},
};

export default (state: SatelliteArchiveStore = initialState, action: Action): SatelliteArchiveStore => {
    switch (action.type) {
        case at.SATELLITE_BEGIN_BOX_SELECT:
            return {
                ...state,
                isSatelliteBoxSelectActive: true,
            };

        case at.SATELLITE_END_BOX_SELECT:
            return {
                ...state,
                isSatelliteBoxSelectActive: false,
            };

        case at.SATELLITE_SELECT_AOI:
            return {
                ...state,
                satelliteSelectedFeatures: [],
                satelliteAOI: action.payload,
            };

        case at.SATELLITE_RESET_AOI:
            return {
                ...state,
                satelliteAOI: undefined,
                isSatelliteBoxSelectActive: false,
            };

        case at.SATELLITE_SET_FEATURE_ACTIVE:
            if (state.satelliteSelectedFeatures.findIndex((t) => t.id === action.payload.id) === -1) {
                return {
                    ...state,
                    satelliteSelectedFeatures: [...state.satelliteSelectedFeatures, action.payload],
                };
            } else {
                return state;
            }

        case at.SATELLITE_SET_FEATURE_INACTIVE:
            if (state.satelliteSelectedFeatures.findIndex((t) => t.id === action.payload.id) === -1) {
                return state;
            } else {
                const stateWithRemovedFeature = state.satelliteSelectedFeatures.filter(
                    (t) => t.id !== action.payload.id
                );
                return {
                    ...state,
                    satelliteSelectedFeatures: stateWithRemovedFeature,
                };
            }

        case at.SATELLITE_RESET_ACTIVE_FEATURES:
            return {
                ...state,
                satelliteSelectedFeatures: [],
            };

        case at.SATELLITE_FEATURE_MOUSE_OVER:
            return {
                ...state,
                satelliteHighlightedFeature: action.payload,
            };

        case at.SATELLITE_FEATURE_MOUSE_OUT:
            return {
                ...state,
                satelliteHighlightedFeature: undefined,
            };

        case at.SATELLITE_REORDER_Z_INDEX:
            return {
                ...state,
                satelliteZIndex: action.payload,
            };

        default:
            return state;
    }
};
