import React from 'react';
import { useSelector } from 'react-redux';
import { selectTextBoxes } from '../../../store/Annotations/TextBox/selectors';
import TextAnnotationSVGLabel from '../../MapView/Annotations/Text/text-annotation-svg-label';
import { selectMapZoom } from '../../../store/App/selectors';

const MobileText = () => {
    const textBoxes = useSelector(selectTextBoxes);
    const mapZoom = useSelector(selectMapZoom);

    return (
        <React.Fragment>
            {mapZoom &&
                textBoxes.map((t) => {
                    return (
                        <TextAnnotationSVGLabel
                            key={`
                            ${t.id}-
                            ${t.fillColor}-
                            ${t.textColor}-
                            ${t.fillOpacity}-
                            ${t.fontFamily}-
                            ${t.fontSize}-
                            ${t.bounds.getNorth()}-
                            ${t.bounds.getEast()}-
                            ${t.bounds.getSouth()}-
                            ${t.bounds.getWest()}-
                            ${t.zIndex}-
                            ${mapZoom}-
                        `}
                            textBox={t}
                            isDisabled={true}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default MobileText;
