import axios from 'axios';
import { isMobile } from 'react-device-detect';
import Constants from '../constants';
import Analytics from '../lib/user-analytics';
import Api from './api';
import { UploadCredentialsDTO } from './model';
import { OssUploader } from './oss-uploader';
import UserHelper from '../lib/user-helper';
import { CreateStoaryRequest, StoaryResponse, UpdateStoaryRequest } from './stoaryModel';

let CANCEL_TOKEN_PROJECT_SEARCH = axios.CancelToken.source();

export default class ApiDraw extends Api {
    public static async uploadImage(image: File): Promise<string> {
        const uploadCredentialStartTime = performance.now();
        const response = await this.axios.post('/v1/map-draw/image/upload');
        const uploadCredentialEndTime = performance.now();
        console.log(
            'Request image upload credentials: ' + (uploadCredentialEndTime - uploadCredentialStartTime) + 'ms'
        );

        const credentials: UploadCredentialsDTO = response.data;
        const attachmentOssUploader = new OssUploader(credentials);
        const path = `${credentials.path}/${image.name}`;

        const uploadStartTime = performance.now();
        await attachmentOssUploader.uploadFileToStorage(image, path);
        const uploadEndTime = performance.now();
        console.log('Upload image: ' + (uploadEndTime - uploadStartTime) + 'ms');
        const url = `https://sp.${Constants.DOMAIN}/map-draw/image/${response.data.drawImageId}`;
        return url;
    }

    public static async createStoary(projectData: CreateStoaryRequest): Promise<StoaryResponse> {
        // TODO #7369 - description should not be required but added temporarily to avoid breaking changes
        projectData.description = '';
        const response = await this.axios.post('/v1/map-draw/projects', projectData, {
            cache: false,
            errorOnInvalidAuth: true,
        });
        Analytics.Event('Stoary', `Created draw Stoary`, response.data.id.toString());
        return response.data;
    }

    public static async updateStoary(id: number, projectData: UpdateStoaryRequest): Promise<StoaryResponse> {
        const response = await this.axios.put(`/v1/map-draw/projects/${id}`, projectData, {
            cache: false,
        });
        Analytics.Event('Stoary', `Updated share Stoary`, id.toString());
        return response.data;
    }

    public static async deleteStoary(id: number): Promise<void> {
        await this.axios.delete(`/v1/map-draw/projects/${id}`);
    }

    public static async getStoary(id: number): Promise<StoaryResponse> {
        if (UserHelper.getIdToken() === undefined && UserHelper.getRefreshToken()) {
            await UserHelper.refreshAuth();
        }

        const response = await this.axios.get(`/v1/map-draw/projects/${id}`, { cache: false });
        Analytics.Event('Stoary', `Viewed Stoary on ${isMobile ? 'mobile' : 'desktop'}`, id.toString());
        return response.data;
    }

    public static async getStoaries(): Promise<StoaryResponse[]> {
        const response = await this.axios.get('/v1/map-draw/projects', { cache: false });
        return response.data;
    }

    public static async getSharedStoaries(): Promise<StoaryResponse[]> {
        const response = await this.axios.get('/v1/map-draw/projects/shared', { cache: false });
        return response.data;
    }

    public static cancelSearchStoaries(cancelReason: string) {
        // Allow a reason for cancellation to be added
        CANCEL_TOKEN_PROJECT_SEARCH.cancel(cancelReason);
        // Generate a new token each time a request is cancelled;
        this.generateSearchStoariesCancelToken();
    }

    private static generateSearchStoariesCancelToken() {
        CANCEL_TOKEN_PROJECT_SEARCH = axios.CancelToken.source();
    }

    public static async getAndSearchStoaries(
        offset: number,
        limit: number,
        searchTerm?: string,
        aoi?: string
    ): Promise<StoaryResponse[]> {
        type StoariesParams = {
            offset: string;
            limit: string;
            keywords?: string;
            aoi?: string;
        };

        const params: StoariesParams = {
            limit: limit.toString(),
            offset: offset.toString(),
        };

        if (searchTerm) {
            params.keywords = searchTerm;
        }

        if (aoi) {
            params.aoi = aoi;
        }

        return this.axios
            .get('/v1/map-draw/projects/search', {
                params,
                cancelToken: CANCEL_TOKEN_PROJECT_SEARCH.token,
                cache: false,
            })
            .then((res) => {
                return res.data;
            });
    }
}
