import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import axios from 'axios';
import Constants from '../../../../constants';
import { CognitoAuthResultType } from '../../../../lib/cognito-util';
import { PulseLoader } from '../../../Shared/pulse-loader';

export interface OAuthProviderConfig {
    SUBDOMAIN: string;
    SCOPE: string;
    IDENTITY_PROVIDER: string;
}

const FADE_DURATION = 3; // seconds
const ERROR_DURATION = 5; // seconds

const OAuthCallback = () => {
    const [fadeOutSequence, setFadeOutSequence] = useState<boolean>(false);
    const [showWelcome, setShowWelcome] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleError = (error: string) => {
        setError(error);
        setTimeout(() => {
            window.close();
        }, ERROR_DURATION * 1000);
    };

    const handleProviderConstants = (identityProvider: string) => {
        switch (identityProvider) {
            case 'Google':
                return Constants.OAUTH_SOCIAL_LOGIN.GOOGLE;
            case 'Facebook':
                return Constants.OAUTH_SOCIAL_LOGIN.FACEBOOK;
            default:
                console.error('Unknown identity provider:', identityProvider);
                return null;
        }
    };

    useEffect(() => {
        const fetchToken = async (code: string, oAuthConstants: OAuthProviderConfig) => {
            const baseUrl = `https://${oAuthConstants.SUBDOMAIN}.auth.${Constants.COGNITO_REGION}.amazoncognito.com/oauth2/token`;
            const params = new URLSearchParams({
                grant_type: 'authorization_code',
                client_id: Constants.COGNITO_CLIENT_ID,
                code: code,
                redirect_uri: `${window.location.origin}/auth/callback`,
            });

            try {
                const response = await axios.post(baseUrl, params);

                const { access_token, expires_in, id_token, refresh_token, token_type } = response.data;

                const formattedData: CognitoAuthResultType = {
                    AccessToken: access_token,
                    ExpiresIn: expires_in,
                    IdToken: id_token,
                    RefreshToken: refresh_token,
                    TokenType: token_type,
                };

                if (window.opener) {
                    window.opener.postMessage({ type: 'OAuthCallback', data: formattedData }, '*');
                }

                setFadeOutSequence(true);
                setShowWelcome(true);
                setTimeout(() => {
                    window.close();
                }, FADE_DURATION * 1000);
            } catch (error) {
                handleError(
                    'We ran into some problems while trying to sign you in. Please try again. If the problem persists, please contact us at skysponsor@soar.earth'
                );
            }
        };

        const updateToken = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const error = urlParams.get('error');
            const error_description = urlParams.get('error_description');
            const identityProvider = urlParams.get('state');

            if (error) {
                if (error_description) {
                    handleError(error_description);
                }
                return;
            }

            if (!identityProvider) {
                handleError('No identity provider found');
                return;
            }

            const oAuthConstants = handleProviderConstants(identityProvider);
            if (code && oAuthConstants) {
                await fetchToken(code, oAuthConstants);
            } else {
                handleError(
                    'We ran into some problems while trying to sign you in. Please try again. If the problem persists, please contact us at skysponsor@soar.earth'
                );
            }
        };

        updateToken();
    }, []);

    return (
        <OAuthLoadingContainer>
            <OAuthContentContainer fadeOut={fadeOutSequence}>
                <OAuthSoarLogo src="/assets/logos/soar_logo_large.png" />
                {error ? (
                    <OAuthLoginText className="error">{error}</OAuthLoginText>
                ) : (
                    <React.Fragment>
                        {!error && showWelcome ? (
                            <OAuthLoginText className="welcome-text">Welcome to Soar</OAuthLoginText>
                        ) : (
                            <React.Fragment>
                                <OAuthLoginText>Attempting to login...</OAuthLoginText>
                                <PulseLoader />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </OAuthContentContainer>
        </OAuthLoadingContainer>
    );
};

export default OAuthCallback;

const OAuthLoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: #191a1a;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const flyOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-200px);
    }
`;

const OAuthContentContainer = styled.div<{ fadeOut: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${({ fadeOut }) =>
        fadeOut &&
        css`
            animation: ${flyOut} ${FADE_DURATION}s ease-in-out;
        `}
`;

const OAuthSoarLogo = styled.img`
    max-width: 60vw;
    height: auto;
    opacity: 0;
    animation: ${fadeIn} ${FADE_DURATION}s ease-in-out forwards;
`;

const OAuthLoginText = styled.h2`
    font-size: 18px;
    margin-bottom: 20px;
    color: white;
    opacity: 0;
    animation: ${fadeIn} ${FADE_DURATION}s ease-in-out forwards;

    &.welcome-text {
        animation: ${fadeIn} ${FADE_DURATION}s ease-in-out forwards,
            ${fadeOut} ${FADE_DURATION}s ease-in-out ${FADE_DURATION}s forwards;
    }

    &.error {
        color: #f44336;
    }
`;
