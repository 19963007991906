import React from 'react';
import styled from 'styled-components';

interface PFSSatelliteItemProps {
    disabled: boolean;
    imageUrl: string;
    title: string;
    hoverText: string;
    onClick: () => void;
}

const PFSSatelliteItem = (props: PFSSatelliteItemProps) => {
    return (
        <Container onClick={props.onClick}>
            <NewTag src="/assets/new-tag.svg" alt="New!" />
            <HoverContainer>
                <HoverTitle>{props.title}</HoverTitle>
                <HoverDescription>{props.hoverText}</HoverDescription>
            </HoverContainer>
            <Logo src={props.imageUrl} />
        </Container>
    );
};

export default PFSSatelliteItem;

const NewTag = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 33px;
    height: auto;
`;

const Logo = styled.img`
    margin: 0;
    height: auto;
    width: 95%;
`;

const HoverContainer = styled.div`
    width: 317px;
    height: 70px;
    position: absolute;
    padding-top: 8px;
    background: rgb(110, 50, 43);
    border: 1px solid rgba(204, 119, 34, 0.3);
    display: none;
`;

const HoverTitle = styled.h5`
    color: white;
    text-align: left;
    margin: 8px 0 1px 9px;
    padding: 0;
    font-size: 16px;
`;

const HoverDescription = styled.p`
    color: white;
    margin: 0;
    padding: 0;
    margin: 0 0 0 9px;
    font-size: 12px;
    line-height: 13px;
`;

const Container = styled.div`
    user-select: none;
    height: 64px;
    margin: 4px;
    border: 1px solid rgba(204, 119, 34, 0.3);
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: #e9d7ad;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    :hover {
        border: 1px solid #eed926;
    }

    :hover ${HoverContainer} {
        display: block;
    }

    :hover ${Logo} {
        filter: blur(16px);
        opacity: 0.5;
    }
`;
