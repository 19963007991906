import React from 'react';
import {
    setCoordinateDeleteAction,
    setCoordinateSystemAction,
    setCoordinateUpdateAction,
} from '../../../../store/Annotations/Coordinate/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import { selectCoordinates } from '../../../../store/Annotations/Coordinate/selectors';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import CoordinateCopyControl from './coordinate-copy-control';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import AnnotationDelete from '../annotation-delete';
import CoordinateSystemSelect from './coordinate-system-select';
import Coordinate from './coordinate';

const CoordinateToolbar = () => {
    const dispatch = useDispatch();
    const coordinates = useSelector(selectCoordinates);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedCoordinate = coordinates.find((c) => c.id === selectedAnnotation?.id);

    const updateCoordinate = (coordinate: Coordinate) => {
        dispatch(setCoordinateUpdateAction(coordinate));
        dispatch(setCoordinateSystemAction(coordinate.coordinateSystem || 'WGS84 DD'));
    };

    if (!selectedCoordinate) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Coordinate System:</ToolbarText>
            <CoordinateSystemSelect
                latLng={selectedCoordinate.position}
                coordinateSystem={selectedCoordinate.coordinateSystem || 'WGS84 DD'}
                onSelect={(coordinateSystem) => {
                    const newCoordinate = {
                        ...selectedCoordinate,
                        coordinateSystem: coordinateSystem.value,
                    };

                    updateCoordinate(newCoordinate);
                }}
            />

            <CoordinateCopyControl coordinate={selectedCoordinate} />
            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    DrawToolAnalytics.delete('coordinate');
                    dispatch(setCoordinateDeleteAction(selectedCoordinate));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default CoordinateToolbar;
