import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import L from 'leaflet';

import ApiDraw from '../../../../api/api-draw';
import { StoaryResponse } from '../../../../api/stoaryModel';
import { AnalyticsAction, AnalyticsNote } from '../../../../api/model';
import ApiAnalytics from '../../../../api/api-analytics';
import { selectLeafletMap } from '../../../../store/App/selectors';

import SideDrawerStoarySearch from './side-drawer-stoary-search';
import SideDrawerInfiniteList, { ItemType } from '../Shared/side-drawer-infinite-list';

import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';
import GeoUtil, { adjustedMapBounds } from '../../../../lib/geo-util';
import SideDrawerStoariesCarditem from './side-drawer-stoaries-card-item';

const RESULT_LIMIT = 25;
const LOAD_MORE_LIMIT = 20;

const MAP_MOVE_DEBOUNCE = 250;
const WORLD_BOUNDS = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));

// TODO need to standardise it is a stoary or project in code as currently it is a mix of both
const SideDrawerStoaries = () => {
    const [searchWord, setSearchWord] = useState<string>('');
    const [drawProjects, setDrawProjects] = useState<StoaryResponse[] | undefined>(undefined);
    const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const history = useHistory();
    const leafletMap = useSelector(selectLeafletMap);

    const fetchStoaries = useCallback(
        async (offset: number, limit?: number) => {
            setIsLoading(true);
            if (!leafletMap) return;
            try {
                const WORLD_BOUNDS_ZOOM_CUTOFF = 3;
                const ALL_BOUNDS_PADDING = 0;
                const NAV_HEIGHT = 70;
                const DRAWER_WIDTH = 405;

                const applyWorldBounds = leafletMap?.getZoom() < WORLD_BOUNDS_ZOOM_CUTOFF ? true : false;

                const mapBounds = adjustedMapBounds(leafletMap, {
                    top: NAV_HEIGHT + ALL_BOUNDS_PADDING,
                    right: ALL_BOUNDS_PADDING,
                    bottom: ALL_BOUNDS_PADDING,
                    left: DRAWER_WIDTH + ALL_BOUNDS_PADDING,
                });

                const aoiWKT = GeoUtil.latLngBoundsToWKT(applyWorldBounds ? WORLD_BOUNDS : mapBounds);

                const data = await ApiDraw.getAndSearchStoaries(
                    offset,
                    limit ? limit : RESULT_LIMIT,
                    searchWord,
                    aoiWKT
                );

                if (data?.length < (limit ?? RESULT_LIMIT)) {
                    setHasReachedEnd(true);
                }

                if (data?.length) {
                    const updatedDrawProjects = offset === 0 ? data : [...(drawProjects ?? []), ...data];
                    setDrawProjects(updatedDrawProjects);
                } else {
                    setDrawProjects([]);
                }
            } catch (error) {
                // Ignore Cancel Token errors
                if (!error.toString().includes('Cancel')) {
                    console.log('Error fetching listings:', error);
                }
            } finally {
                setTimeout(() => setIsLoading(false), 300);
            }
        },

        [drawProjects, leafletMap, searchWord]
    );

    const CancelSearchToken = () => ApiDraw.cancelSearchStoaries('User refetched Stoaries');

    useEffect(() => {
        if (!leafletMap) return;
        CancelSearchToken();
        fetchStoaries(0);
        // Disabled to stop continuous refetching from callback?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchWord, leafletMap]);

    useEffect(() => {
        if (!leafletMap) return;
        const debouncedHandleMapMove = debounce(() => {
            CancelSearchToken();
            fetchStoaries(0);
        }, MAP_MOVE_DEBOUNCE);

        leafletMap.on('moveend', debouncedHandleMapMove);
        leafletMap.on('zoomend', debouncedHandleMapMove);

        return () => {
            leafletMap.off('moveend', debouncedHandleMapMove);
            leafletMap.off('zoomend', debouncedHandleMapMove);
        };
    }, [leafletMap, fetchStoaries]);

    const handleLoadMore = debounce(() => {
        CancelSearchToken();
        fetchStoaries(drawProjects?.length ?? 0, LOAD_MORE_LIMIT);
    }, MAP_MOVE_DEBOUNCE);

    const handleItemClick = (item: ItemType, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        history.push(UriHelper.stripOrigin(window.location.href));
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.STOARY, item.id);
        Analytics.Event('Side Drawer', 'Clicked To View Stoary', item.id);
        UriHelper.navigateToPath(`/draw/${item.id}`);
    };

    return (
        <StoariesDrawer>
            <StoariesTitle>Search Stoaries</StoariesTitle>
            <SideDrawerStoarySearch setSearchWord={setSearchWord} />
            <SideDrawerInfiniteScrollContainer>
                <SideDrawerInfiniteList
                    type="Stoaries"
                    items={drawProjects}
                    exitingItems={new Set()}
                    itemsCount={drawProjects?.length || 0}
                    filteredCount={drawProjects?.length || 0}
                    isLoading={isLoading}
                    hasMoreToLoad={!hasReachedEnd}
                    heightPadding="175px"
                    onLoadMore={handleLoadMore}
                    onClick={handleItemClick}
                    getItemLink={(item: ItemType) => `/draw/${item.id}`}
                    getItemCard={(item: StoaryResponse) => <SideDrawerStoariesCarditem project={item} />}
                />
            </SideDrawerInfiniteScrollContainer>
        </StoariesDrawer>
    );
};

export default SideDrawerStoaries;

const StoariesDrawer = styled.div`
    margin-top: 15px;
    * {
        user-select: none;
    }
`;

const StoariesTitle = styled.h1`
    text-align: center;
    font-size: 22px;
    color: white;
`;

const SideDrawerInfiniteScrollContainer = styled.div`
    margin: 0px 0px 0px 5px;
`;
