import { actionTypes as at } from './constants';

function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

// TODO:  Move to 'store/Map/Mobile`
export const actionBeginMobileSentinelSearch = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.BEGIN_MOBILE_SENTINEL_SEARCH,
            payload: true,
        });
        await sleep(100);
        dispatch(actionResetBeginMobileSentinelSearch());
    };
};

// TODO:  Move to 'store/Map/Mobile`
export const actionResetBeginMobileSentinelSearch = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.BEGIN_MOBILE_SENTINEL_SEARCH,
            payload: false,
        });
        await sleep(100);
        dispatch(actionResetBeginMobileSentinelSearch());
    };
};
