import { useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import ApiSubdomain from '../../../api/api-subdomain';
import MapViewHistoryHelper from '../../../lib/map-view-history-helper';
import Analytics from '../../../lib/user-analytics';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../Shared/soar-modal';
import { StyledDeleteButton, StyledDarkButton } from '../../Shared/styled-button';

interface ProfileImageryEditDeleteProps {
    showDelete: boolean;
    toggleShowDelete: () => void;
    refreshMasonary: () => void;
    listingId: number;
}

const ImageryDeleteModal = (props: ProfileImageryEditDeleteProps) => {
    const { showDelete, toggleShowDelete, refreshMasonary, listingId } = props;
    const [hasDeleted, setHasDeleted] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleClickedDeletedListing = () => {
        setIsDeleting(true);
        ApiSubdomain.deleteListing(listingId)
            .then(() => {
                setIsDeleting(false);
                setHasDeleted(true);
                setTimeout(() => {
                    setHasDeleted(false);
                    toggleShowDelete();
                }, 2000);
                MapViewHistoryHelper.removeMapToViewedMapsHistory(listingId);
                refreshMasonary();
                toast.dark('Map deleted');
                Analytics.Event('My Dashboard', 'Delete Listing', listingId);
            })
            .catch((err) => {
                setIsDeleting(false);
                toast.error('Error deleting map', err);
            });
    };

    return (
        <SoarModal title="Delete Map?" width={'400px !important'} isOpen={showDelete} toggle={toggleShowDelete}>
            <StyledModalBody>Are you sure you want to delete this map?</StyledModalBody>
            <StyledModalFooter>
                {isDeleting && !hasDeleted && <Spinner color="warning" />}
                {hasDeleted && !isDeleting && <p>Map has been removed successfully</p>}
                {!isDeleting && !hasDeleted && (
                    <>
                        <StyledDarkButton onClick={toggleShowDelete}>CANCEL</StyledDarkButton>
                        <StyledDeleteButton
                            onClick={() => {
                                handleClickedDeletedListing();
                            }}
                        >
                            DELETE MAP
                        </StyledDeleteButton>
                    </>
                )}
            </StyledModalFooter>
        </SoarModal>
    );
};

export default ImageryDeleteModal;
