import L from 'leaflet';
import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';
import LayersUtil from '../layers-util';

interface ArrowDragElementProps extends L.PolylineOptions {
    arrowShaftElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
    isSelected: boolean;
}

const arrowDragOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 20,
    bubblingMouseEvents: true,
};

export const createArrowDragElement = (props: ArrowDragElementProps, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const startLatLng = props.arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
    const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;

    const arrowDrag = new L.Polyline([startLatLng, endLatLng], { ...arrowDragOptions, pane: controlPaneId });
    const arrowDragElement = createElementObject<L.Polyline, ArrowDragElementProps>(arrowDrag, context);

    arrowDragElement.instance.on('mouseover', () => {
        if (props.isSelected) {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
        }
    });

    arrowDragElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
    });

    let lastLatLng: L.LatLng | undefined;
    let isDraggable = false;
    const onMouseDown = (e: L.LeafletMouseEvent) => {
        if (!props.isSelected) {
            return;
        }
        lastLatLng = e.latlng;
        isDraggable = true;
        context.map.dragging.disable();

        context.map.on('mousemove', onMouseMove);
        context.map.on('mouseup', onMouseUp);
    };

    const onMouseUp = () => {
        lastLatLng = undefined;
        isDraggable = false;
        context.map.dragging.enable();

        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);
    };

    const onMouseMove = (e: L.LeafletMouseEvent) => {
        e.originalEvent.stopPropagation();
        const currentLatLngs = props.arrowShaftElement.instance.getLatLngs() as L.LatLng[];

        if (lastLatLng && isDraggable) {
            const latDiff = e.latlng.lat - lastLatLng.lat;
            const lngDiff = e.latlng.lng - lastLatLng.lng;
            const latLngDifference = L.latLng(latDiff, lngDiff);

            lastLatLng = e.latlng;

            const updatedLatLngs = currentLatLngs.map((point) => {
                return L.latLng(point.lat + latLngDifference.lat, point.lng + latLngDifference.lng);
            });

            arrowDragElement.instance.setLatLngs(updatedLatLngs);
            props.arrowShaftElement.instance.setLatLngs(updatedLatLngs);
            props.arrowShaftElement.instance.fireEvent('update', {
                ...e,
                startLatLng: updatedLatLngs[0],
                endLatLng: updatedLatLngs[1],
            });
        }
    };

    arrowDragElement.instance.on('mousedown', onMouseDown);

    props.arrowShaftElement.instance.on('remove', () => {
        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);
        arrowDragElement.instance.off('mousedown', onMouseDown);
        context.map.removeLayer(arrowDragElement.instance);
    });

    props.arrowShaftElement.instance.on('update', () => {
        const startLatLng = props.arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
        arrowDragElement.instance.setLatLngs([startLatLng, endLatLng]);
    });

    return arrowDragElement;
};
