import ReactGA from 'react-ga4';
import { isMobileVersion } from './soar-helper';

export enum ConversionEvent {
    SELECT_SATELLITE_TYPE = 'AW-752086192/5kSyCI391dIDELDZz-YC',
    SELECT_ORDER_TYPE = 'AW-752086192/P-D7CKuYjdMDELDZz-YC',
    DRAW_AOI = 'AW-752086192/S9MACPTrg9MDELDZz-YC',
    START_ORDER = 'AW-752086192/2S8nCPPykdIDELDZz-YC',
    CONFIRM_ORDER = 'AW-752086192/CBgSCIbyg9MDELDZz-YC',
    ADD_USER_DETAILS = 'AW-752086192/UeZLCOX3g9MDELDZz-YC',
    ACCEPT_TERMS_AND_CONDITIONS = 'AW-752086192/ZzPVCM2ujdMDELDZz-YC',
    COMPLETE_ORDER = 'AW-752086192/oQNxCOSlgdIDELDZz-YC',
}

const DEBUG_ANALYTICS = false;

export default class Analytics {
    public static Conversion = (event: ConversionEvent) => {
        try {
            //@ts-ignore
            window.gtag('config', 'AW-752086192');

            //@ts-ignore
            window.gtag('event', 'conversion', { send_to: event });
        } catch {
            //do nothing for now, implemented this try catch so the integration testing will work
        }
    };

    public static ConversionWithValue = (event: ConversionEvent, currency: string, value: string) => {
        try {
            //@ts-ignore
            window.gtag('config', 'AW-752086192');

            //@ts-ignore
            window.gtag('event', 'conversion', {
                send_to: event,
                value: value,
                currency: currency,
            });
        } catch {
            //do nothing for now, implemented this try catch so the integration testing will work
        }
    };

    public static Event = (
        categoryName: string,
        actionName: string,
        labelName?: number | string | unknown,
        valueName?: number
    ) => {
        const event = {
            category: categoryName,
            action: actionName,
            label: Analytics.isAcceptedLabel(labelName),
            value: valueName,
        };

        if (DEBUG_ANALYTICS) {
            console.log(event);
        }
        ReactGA.event(event);
    };

    public static setIsLoggedIn = (loginState: boolean) => {
        ReactGA.ga('set', 'dimension1', loginState ? 'true' : 'false'); // dimension is on user state
        ReactGA.ga('set', 'dimension2', loginState ? 'true' : 'false'); // dimension is on session
    };

    public static setDeviceDimension = () => {
        if (isMobileVersion) {
            ReactGA.ga('set', 'dimension3', 'Mobile'); // dimension is user on mobile device
        } else {
            ReactGA.ga('set', 'dimension3', 'PC'); // dimension is user on PC
        }
    };
    public static isLoggedIn = () => {
        const cookie = document.cookie.indexOf('soar-id-token-v2');
        if (cookie !== -1) {
            return true;
        } else {
            return false;
        }
    };

    private static isAcceptedLabel = (label: number | string | unknown): string => {
        if (label === undefined) {
            return '';
        }

        if (typeof label === 'number') {
            return label.toString();
        }

        if (typeof label === 'object') {
            return JSON.stringify(label);
        }

        if (typeof label === 'string') {
            return label;
        }

        return '';
    };
}
