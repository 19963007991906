import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import useOnClickOutside from '../../lib/use-on-click-outside';
import { actionSetSelectedSearchTerm } from '../../store/Map/MapSelection/actions';
import SearchBarAutocomplete from './search-bar-autocomplete';
import { setSearchTermToLocalStorage } from './search-term-localstorage';
import { actionActiveMapCleared } from '../../store/Map/SuperMap/actions';
import { useQuery } from '../../lib/uri-helper';

const SearchBar = () => {
    const dispatch = useDispatch();
    const url = useLocation();
    const query = useQuery();

    const searchBarContainerRef = useRef(null);

    const [searchInput, setSearchInput] = useState<string>('');
    const [isSearchInputFocused, setIsSearchInputFocused] = useState<boolean>(false);
    const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

    useOnClickOutside(searchBarContainerRef, () => {
        setIsSearchInputFocused(false);
    });

    useEffect(() => {
        setShowSearchBar(url.pathname.includes('/maps') || url.pathname.includes('/discover'));
    }, [url.pathname]);

    useEffect(() => {
        const q = query.get('q')?.toString();
        if (q) {
            setSearchInput(q);
            onSearch(q);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const onSearch = (searchTerm: string) => {
        setSearchTermToLocalStorage(searchTerm);
        setIsSearchInputFocused(false);
        dispatch(actionActiveMapCleared());
        dispatch(actionSetSelectedSearchTerm(searchTerm));
    };

    const onClearSearch = () => {
        setSearchInput('');
        dispatch(actionSetSelectedSearchTerm(undefined));
    };

    const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);

        if (e.target.value.length === 0) {
            onClearSearch();
        }
    };

    const onFocusSearchInput = () => {
        setIsSearchInputFocused(true);
    };

    if (!showSearchBar) return <React.Fragment />;

    return (
        <SearchInputContainer
            tabIndex={0}
            id="search-input"
            isFocused={isSearchInputFocused}
            ref={searchBarContainerRef}
        >
            <Input
                onFocus={onFocusSearchInput}
                value={searchInput}
                onChange={onChangeSearchInput}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onSearch(searchInput);
                    }

                    if (e.key === 'Escape') {
                        onClearSearch();
                    }
                }}
                type="text"
                placeholder={'Search for Maps!'}
            />

            {isSearchInputFocused ? (
                <SearchBarAutocomplete
                    searchInput={searchInput}
                    onSelectAutocomplete={(autocomplete) => {
                        setSearchInput(autocomplete.title);
                        onSearch(autocomplete.title);
                    }}
                />
            ) : null}

            <IconContainer
                isFocused={isSearchInputFocused}
                onClick={() => {
                    if (searchInput.length > 0) {
                        onClearSearch();
                    }
                }}
            >
                {searchInput.length === 0 ? (
                    <SearchIcon src="/assets/side-drawer/discover_search_icon_black.svg" title="Search" />
                ) : (
                    <CloseIcon src="/assets/close-black.png" title="Clear Search" />
                )}
            </IconContainer>
        </SearchInputContainer>
    );
};

export default SearchBar;

const SearchInputContainer = styled.div<{ isFocused: boolean }>`
    position: absolute;
    z-index: 99999;
    width: calc(100vw / 3);
    height: 40px;
    left: calc((100vw / 2) - (100vw / 6));
    top: 15px;
    background: white;
    margin: 0;
    padding: 0;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: ${(props) => (props.isFocused ? '0' : '10px')};
    border-bottom-right-radius: ${(props) => (props.isFocused ? '0' : '10px')};

    border: ${(props) =>
        props.isFocused ? `2px solid ${props.theme.color.yellow}` : `2px solid ${props.theme.color.lightGray}`};

    border-bottom: ${(props) =>
        props.isFocused ? '1px solid rgba(0, 0, 0, 0.2)' : `2px solid ${props.theme.color.lightGray}`};

    transition: box-shadow 0.3s ease;
    display: flex;
`;

const IconContainer = styled.div<{ isFocused: boolean }>`
    height: 37px;
    width: 60px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: ${(props) => (props.isFocused ? '0' : '10px')};
    background: ${(props) => props.theme.color.yellow};
    margin: 0;
    padding: 0;
    cursor: pointer;
`;

const SearchIcon = styled.img`
    height: 34px;
    width: auto;
    margin: 2px 0 0 8px;
`;

const Input = styled.input`
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    color: black;
    padding: 0 0 0 15px;
    margin: 0;
    font-size: 1.3rem;

    &::placeholder {
        color: black;
        font-weight: 200;
    }
`;

const CloseIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 11px 0 12px 18px;
`;
