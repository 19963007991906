import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ToolbarModal from '../ToolBar/toolbar-modal';

interface MeasurementToolbarProps {
    children: React.ReactNode;
}

const MeasurementToolbar = (props: MeasurementToolbarProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toolbarButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <Container>
            <ButtonToggle ref={toolbarButtonRef} isSelected={isOpen} onClick={() => setIsOpen((current) => !current)}>
                <MeasurementIcon src="/assets/annotations/icon-ruler.svg" alt="Measurement" />
            </ButtonToggle>

            {isOpen ? (
                <ToolbarModal
                    toolbarButtonRef={toolbarButtonRef}
                    width={250}
                    title="Measurement"
                    onClose={() => setIsOpen(false)}
                >
                    {props.children}
                </ToolbarModal>
            ) : null}
        </Container>
    );
};

export default MeasurementToolbar;

const ButtonToggle = styled.button<{ isSelected: boolean }>`
    background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
    color: inherit;
    border: none;
    border-radius: 4px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 2px 0px 0px 0px;
    padding: 2px 2px;
    display: flex;
    flex-direction: row;
    height: 38px;
`;

const MeasurementIcon = styled.img`
    width: 28px;
    height: 28px;
    margin-top: 2px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 2px 0 0 2px;
`;

export const MeasurementToolbarOptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin: 5px;
`;

export const MeasurementToolbarOptionLabel = styled.label`
    display: flex;
    flex-direction: column;
    color: white;
`;

interface MeasurementToolbarCheckboxProps {
    disabled?: boolean;
}

export const MeasurementToolbarCheckbox = styled.input<MeasurementToolbarCheckboxProps>`
    appearance: none;
    background-image: ${(props) =>
        props.disabled
            ? "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='rgba(255,255,255,0.2)' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='18' height='18' rx='6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A\")"
            : "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='18' height='18' rx='6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A\")"};
    width: 20px;
    height: 20px;
    margin-left: auto;
    background-repeat: none !important;
    outline: none !important;
    cursor: pointer;

    &:hover {
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }

    &:checked {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='6' fill='%23EED926'/%3E%3Cpath d='M5 9L8.10017 12L14 6' stroke='%23191A1A' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
`;

export const MeasurementToolbarSelect = styled.select`
    min-width: 90px;
    margin-left: auto;
`;

export const MeasurementToolbarSelectOption = styled.option``;
