import L from 'leaflet';
import { Map } from 'leaflet';

/**
 * Calculate the initial bounds of the image based on the map bounds
 * @param map The map
 * @param image  The image to calculate the bounds for
 * @returns  The initial bounds of the image
 */
export const calculateInitialBounds = (map: Map, image: HTMLImageElement): L.LatLngBounds => {
    const mapBounds = map.getContainer().getBoundingClientRect();
    const imageWidth = image.width / 2;
    const imageHeight = image.height / 2;
    const ratio = calculateAspectRatioFit(mapBounds, imageWidth, imageHeight);
    const { newWidth, newHeight } = calculateImageWidthAndHeightByGreaterDimension(imageWidth, imageHeight, ratio);
    const newLatLngBounds = calculateLatLngBounds(map, mapBounds, newWidth, newHeight);
    return newLatLngBounds;
};

/**
 *  Calculate the aspect ratio of the image based on the map bounds
 * @param mapBounds  The bounds of the map
 * @param srcWidth  The width of the image
 * @param srcHeight  The height of the image
 * @returns  The aspect ratio of the image
 */
const calculateAspectRatioFit = (mapBounds: DOMRect, srcWidth: number, srcHeight: number) => {
    const ratio = Math.min(mapBounds.width / srcWidth, mapBounds.height / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
};

/**
 *  Calculate the new width and height of the image based on the greater dimension
 * @param imageWidth  The width of the image
 * @param imageHeight  The height of the image
 * @param ratio  The ratio of the image based on the map bounds
 * @returns  The new width and height of the image
 */
const calculateImageWidthAndHeightByGreaterDimension = (
    imageWidth: number,
    imageHeight: number,
    ratio: { width: number; height: number }
) => {
    let newWidth = imageWidth;
    let newHeight = imageHeight;

    if (newWidth > newHeight) {
        newHeight = (newHeight / newWidth) * (ratio.width / 2);
        newWidth = ratio.width / 2;
    } else {
        newWidth = (newWidth / newHeight) * (ratio.height / 2);
        newHeight = ratio.height / 2;
    }

    return { newWidth, newHeight };
};

/**
 * Calculate the new lat lng bounds of the image based on the map bounds
 * @param map The map
 * @param mapBounds  The bounds of the map
 * @param newWidth  The new width of the image
 * @param newHeight  The new height of the image
 * @returns  The new lat lng bounds of the image
 */
const calculateLatLngBounds = (map: Map, mapBounds: DOMRect, newWidth: number, newHeight: number) => {
    const xLeft = mapBounds.width / 2 - newWidth / 2;
    const xRight = xLeft + newWidth;
    const yTop = mapBounds.height / 2 - newHeight / 2;
    const yBottom = yTop + newHeight;
    const topLeft = map.containerPointToLatLng(new L.Point(xLeft, yTop));
    const bottomRight = map.containerPointToLatLng(new L.Point(xRight, yBottom));
    return new L.LatLngBounds(topLeft, bottomRight);
};
