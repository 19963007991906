import L from 'leaflet';
import { presetMarkers } from './Icons/markers';
import { nature } from './Icons/nature';
import { transport } from './Icons/transport';
import { urban } from './Icons/urban';
import Icon from './icon';
import Marker from './marker';

export type MarkerCategoryName = 'Preselect' | 'Marker' | 'Urban' | 'Transport' | 'Nature';

export const enum MarkerIconName {
    // Default Marker
    SOARDEFAULT = 'SOARDEFAULT',

    // Urban
    CAFE = 'CAFE',
    CIRCLECROSS2 = 'CIRCLECROSS2',
    CIRCLEHALFFULL2 = 'CIRCLEHALFFULL2',
    CIRCLETRIANGLE1 = 'CIRCLETRIANGLE1',
    COMMUNITY_CENTER = 'COMMUNITY_CENTER',
    CONVENIENCE = 'CONVENIENCE',
    DOCTOR = 'DOCTOR',
    FERRY_STATION = 'FERRY_STATION',
    FIRE_STATION = 'FIRE_STATION',
    FLORIST = 'FLORIST',
    HOTEL = 'HOTEL',
    HOUSE = 'HOUSE',
    INDUSTRY = 'INDUSTRY',
    INFORMATION = 'INFORMATION',
    JOGGING = 'JOGGING',
    LIBRARY = 'LIBRARY',
    MILITARY = 'MILITARY',
    MUSEUM = 'MUSEUM',
    MUSIC = 'MUSIC',
    PET_SHOP = 'PET_SHOP',
    POLICE = 'POLICE',
    POST_OFFICE = 'POST_OFFICE',
    POWER = 'POWER',
    SHELTER = 'SHELTER',
    TELEPHONE = 'TELEPHONE',
    TEMPLE = 'TEMPLE',
    THEATRE = 'THEATRE',
    TOWER = 'TOWER',
    WASTE_DISPOSAL = 'WASTE_DISPOSAL',
    WATER = 'WATER',
    WIFI = 'WIFI',

    // Transport
    ACCESSABILITY = 'ACCESSABILITY',
    AMBULANCE2 = 'AMBULANCE2',
    BICYCLE = 'BICYCLE',
    BUS_STATION = 'BUS_STATION',
    CAR_SHOP = 'CAR_SHOP',
    FUEL = 'FUEL',
    HELICOPTER = 'HELICOPTER',
    HELIPAD = 'HELIPAD',
    HORSE_RIDING = 'HORSE_RIDING',
    LIGHTHOUSE = 'LIGHTHOUSE',
    MARINA = 'MARINA',
    PARKING = 'PARKING',
    SAILING = 'SAILING',
    VEHICLE_CHARGING = 'VEHICLE_CHARGING',
    WARNING = 'WARNING',

    // Nature
    ALPINE_HUT = 'ALPINE_HUT',
    BEACH = 'BEACH',
    CAMPING = 'CAMPING',
    EVENTS = 'EVENTS',
    FIRE_PIT = 'FIRE_PIT',
    FISH = 'FISH',
    FISHING = 'FISHING',
    FOREST = 'FOREST',
    FOX = 'FOX',
    FRUITS = 'FRUITS',
    GARDEN = 'GARDEN',
    GENERIC_TREE = 'GENERIC_TREE',
    HIKING = 'HIKING',
    ISLAND = 'ISLAND',
    KANGAROO = 'KANGAROO',
    LEOPARD = 'LEOPARD',
    MOUNTAIN = 'MOUNTAIN',
    MOUNTAINEERING = 'MOUNTAINEERING',
    NATURE = 'NATURE',
    PATH = 'PATH',
    PEAK = 'PEAK',
    PINE = 'PINE',
    SHARK = 'SHARK',

    // Preselected Symbols
    PRESELECT1 = 'PRESELECT1',
    PRESELECT2 = 'PRESELECT2',
    PRESELECT3 = 'PRESELECT3',
    PRESELECT4 = 'PRESELECT4',
    PRESELECT5 = 'PRESELECT5',
    PRESELECT6 = 'PRESELECT6',
    PRESELECT7 = 'PRESELECT7',
    PRESELECT8 = 'PRESELECT8',
    PRESELECT9 = 'PRESELECT9',
}

const customMarkerOptions = {
    iconSize: new L.Point(32, 32),
    iconAnchor: new L.Point(16, 32),
    popupAnchor: new L.Point(1, -34),
    tooltipAnchor: new L.Point(16, -28),
    shadowSize: new L.Point(32, 32),
};

/**
 * Creates a new soarCustomMarker
 * @param url - The url of the marker icon
 * @param category - The category of the marker
 * @returns A new soarCustomMarker
 */
const soarCustomMarker = (url: string, category: string): L.Icon => {
    return new L.Icon({
        iconUrl: url,
        ...customMarkerOptions,
        category: category,
    });
};

const changeFillColor = (svgString: string, color: string): string => {
    // Find the "fill" attribute in the SVG and replace its value which seems to be the only one used
    const updatedSvgString = svgString.replace(/fill:[^;]+;/, `fill:${color};`);
    return updatedSvgString;
};

const DEFAULT_MARKER_COLOR = '#EED926';
const updateMarkerIcon = (markerIcon: string, color?: string): string => {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        changeFillColor(markerIcon, color ? color : DEFAULT_MARKER_COLOR)
    )}`;
};

const markerIcons: Record<string, (color?: string) => Icon> = {
    // Default Marker
    // If a user drops in a geojson with no iconName we present them as a default marker
    SOARDEFAULT: (color) => soarCustomMarker(updateMarkerIcon(presetMarkers.defaultMarker, color), 'Default'),

    // Preselected Symbols/Markers
    PRESELECT1: (color) =>
        soarCustomMarker(updateMarkerIcon(presetMarkers.defaultMarker, color ? color : '#EED926'), 'Preselect'),
    PRESELECT2: (color) => soarCustomMarker(updateMarkerIcon(nature.generictree, color), 'Preselect'),
    PRESELECT3: (color) => soarCustomMarker(updateMarkerIcon(urban.power, color), 'Preselect'),
    PRESELECT4: (color) => soarCustomMarker(updateMarkerIcon(transport.events, color), 'Preselect'),
    PRESELECT5: (color) => soarCustomMarker(updateMarkerIcon(nature.firestation, color), 'Preselect'),
    PRESELECT6: (color) => soarCustomMarker(updateMarkerIcon(nature.camping, color), 'Preselect'),
    PRESELECT7: (color) => soarCustomMarker(updateMarkerIcon(nature.mountain, color), 'Preselect'),
    PRESELECT8: (color) => soarCustomMarker(updateMarkerIcon(nature.nature, color), 'Preselect'),
    PRESELECT9: (color) => soarCustomMarker(updateMarkerIcon(nature.petshop, color), 'Preselect'),

    // Urban
    CAFE: (color) => soarCustomMarker(updateMarkerIcon(urban.cafe, color), 'Urban'),
    COMMUNITY_CENTER: (color) => soarCustomMarker(updateMarkerIcon(urban.communitycenter, color), 'Urban'),
    CONVENIENCE: (color) => soarCustomMarker(updateMarkerIcon(urban.convenience, color), 'Urban'),
    DOCTOR: (color) => soarCustomMarker(updateMarkerIcon(urban.doctor, color), 'Urban'),
    HOTEL: (color) => soarCustomMarker(updateMarkerIcon(urban.hotel, color), 'Urban'),
    HOUSE: (color) => soarCustomMarker(updateMarkerIcon(urban.house, color), 'Urban'),
    INDUSTRY: (color) => soarCustomMarker(updateMarkerIcon(urban.industry, color), 'Urban'),
    INFORMATION: (color) => soarCustomMarker(updateMarkerIcon(urban.information, color), 'Urban'),
    JOGGING: (color) => soarCustomMarker(updateMarkerIcon(urban.jogging, color), 'Urban'),
    LIBRARY: (color) => soarCustomMarker(updateMarkerIcon(urban.library, color), 'Urban'),
    MILITARY: (color) => soarCustomMarker(updateMarkerIcon(urban.military, color), 'Urban'),
    MUSEUM: (color) => soarCustomMarker(updateMarkerIcon(urban.museum, color), 'Urban'),
    MUSIC: (color) => soarCustomMarker(updateMarkerIcon(urban.music, color), 'Urban'),
    POLICE: (color) => soarCustomMarker(updateMarkerIcon(urban.police, color), 'Urban'),
    POST_OFFICE: (color) => soarCustomMarker(updateMarkerIcon(urban.postoffice, color), 'Urban'),
    POWER: (color) => soarCustomMarker(updateMarkerIcon(urban.power, color), 'Urban'),
    SHELTER: (color) => soarCustomMarker(updateMarkerIcon(urban.shelter, color), 'Urban'),
    TELEPHONE: (color) => soarCustomMarker(updateMarkerIcon(urban.telephone, color), 'Urban'),
    TEMPLE: (color) => soarCustomMarker(updateMarkerIcon(urban.temple, color), 'Urban'),
    THEATRE: (color) => soarCustomMarker(updateMarkerIcon(urban.theatre, color), 'Urban'),
    TOWER: (color) => soarCustomMarker(updateMarkerIcon(urban.tower, color), 'Urban'),
    WASTE_DISPOSAL: (color) => soarCustomMarker(updateMarkerIcon(urban.wastedisposal, color), 'Urban'),
    WATER: (color) => soarCustomMarker(updateMarkerIcon(urban.water, color), 'Urban'),
    WIFI: (color) => soarCustomMarker(updateMarkerIcon(urban.wifi, color), 'Urban'),

    // Transport
    ACCESSABILITY: (color) => soarCustomMarker(updateMarkerIcon(transport.accessability, color), 'Transport'),
    AMBULANCE2: (color) => soarCustomMarker(updateMarkerIcon(transport.ambulance2, color), 'Transport'),
    BICYCLE: (color) => soarCustomMarker(updateMarkerIcon(transport.bicycle, color), 'Transport'),
    BUS_STATION: (color) => soarCustomMarker(updateMarkerIcon(transport.busstation, color), 'Transport'),
    CAR_SHOP: (color) => soarCustomMarker(updateMarkerIcon(transport.carshop, color), 'Transport'),
    FUEL: (color) => soarCustomMarker(updateMarkerIcon(transport.fuel, color), 'Transport'),
    HELICOPTER: (color) => soarCustomMarker(updateMarkerIcon(transport.helicopter, color), 'Transport'),
    HELIPAD: (color) => soarCustomMarker(updateMarkerIcon(transport.helipad, color), 'Transport'),
    HORSE_RIDING: (color) => soarCustomMarker(updateMarkerIcon(transport.horseriding, color), 'Transport'),
    LIGHTHOUSE: (color) => soarCustomMarker(updateMarkerIcon(transport.lighthouse, color), 'Transport'),
    MARINA: (color) => soarCustomMarker(updateMarkerIcon(transport.marina, color), 'Transport'),
    PARKING: (color) => soarCustomMarker(updateMarkerIcon(transport.parking, color), 'Transport'),
    SAILING: (color) => soarCustomMarker(updateMarkerIcon(transport.sailing, color), 'Transport'),
    VEHICLE_CHARGING: (color) => soarCustomMarker(updateMarkerIcon(transport.vehiclecharging, color), 'Transport'),
    WARNING: (color) => soarCustomMarker(updateMarkerIcon(transport.warning, color), 'Transport'),
    FERRY_STATION: (color) => soarCustomMarker(updateMarkerIcon(transport.ferrystation, color), 'Transport'),
    EVENTS: (color) => soarCustomMarker(updateMarkerIcon(transport.events, color), 'Transport'),
    CIRCLECROSS2: (color) => soarCustomMarker(updateMarkerIcon(transport.circlecross2, color), 'Transport'),
    CIRCLEHALFFULL2: (color) => soarCustomMarker(updateMarkerIcon(transport.circlehalffull2, color), 'Transport'),
    CIRCLETRIANGLE1: (color) => soarCustomMarker(updateMarkerIcon(transport.circletriangle1, color), 'Transport'),

    // Nature
    ALPINE_HUT: (color) => soarCustomMarker(updateMarkerIcon(nature.alpinehut, color), 'Nature'),
    BEACH: (color) => soarCustomMarker(updateMarkerIcon(nature.beach, color), 'Nature'),
    CAMPING: (color) => soarCustomMarker(updateMarkerIcon(nature.camping, color), 'Nature'),
    FIRE_PIT: (color) => soarCustomMarker(updateMarkerIcon(nature.firepit, color), 'Nature'),
    FISH: (color) => soarCustomMarker(updateMarkerIcon(nature.fish, color), 'Nature'),
    FISHING: (color) => soarCustomMarker(updateMarkerIcon(nature.fishing, color), 'Nature'),
    FOREST: (color) => soarCustomMarker(updateMarkerIcon(nature.forest, color), 'Nature'),
    FOX: (color) => soarCustomMarker(updateMarkerIcon(nature.fox, color), 'Nature'),
    FRUITS: (color) => soarCustomMarker(updateMarkerIcon(nature.fruits, color), 'Nature'),
    GARDEN: (color) => soarCustomMarker(updateMarkerIcon(nature.garden, color), 'Nature'),
    GENERIC_TREE: (color) => soarCustomMarker(updateMarkerIcon(nature.generictree, color), 'Nature'),
    HIKING: (color) => soarCustomMarker(updateMarkerIcon(nature.hiking, color), 'Nature'),
    ISLAND: (color) => soarCustomMarker(updateMarkerIcon(nature.island, color), 'Nature'),
    KANGAROO: (color) => soarCustomMarker(updateMarkerIcon(nature.kangaroo, color), 'Nature'),
    LEOPARD: (color) => soarCustomMarker(updateMarkerIcon(nature.leopard, color), 'Nature'),
    MOUNTAIN: (color) => soarCustomMarker(updateMarkerIcon(nature.mountain, color), 'Nature'),
    MOUNTAINEERING: (color) => soarCustomMarker(updateMarkerIcon(nature.mountaineering, color), 'Nature'),
    NATURE: (color) => soarCustomMarker(updateMarkerIcon(nature.nature, color), 'Nature'),
    PATH: (color) => soarCustomMarker(updateMarkerIcon(nature.path, color), 'Nature'),
    PEAK: (color) => soarCustomMarker(updateMarkerIcon(nature.peak, color), 'Nature'),
    PINE: (color) => soarCustomMarker(updateMarkerIcon(nature.pine, color), 'Nature'),
    SHARK: (color) => soarCustomMarker(updateMarkerIcon(nature.shark, color), 'Nature'),
    PET_SHOP: (color) => soarCustomMarker(updateMarkerIcon(nature.petshop, color), 'Nature'),
    FIRE_STATION: (color) => soarCustomMarker(updateMarkerIcon(nature.firestation, color), 'Nature'),
    FLORIST: (color) => soarCustomMarker(updateMarkerIcon(nature.florist, color), 'Nature'),
};

export const getMarkerIcon = (markerName: MarkerIconName, color?: string): Icon => {
    const markerIcon = markerIcons[markerName](color);
    if (markerIcon) {
        markerIcon.options.name = markerName;
        return markerIcon;
    } else {
        return markerIcons[MarkerIconName.SOARDEFAULT](color);
    }
};

export const getMarkerIconsByCategory = (filter?: MarkerCategoryName, color?: string): Icon[] => {
    const iconsArray = Object.entries(markerIcons).map(([name, createMarkerFunction]) => {
        const icon = createMarkerFunction(color);
        icon.options.name = name;
        return icon;
    });
    if (filter) {
        return iconsArray.filter((icon) => icon.options.category === filter);
    }

    return iconsArray;
};

export const isMarkerSelected = (currentIcon: MarkerIconName, newIcon: Icon) => {
    if (currentIcon === MarkerIconName.SOARDEFAULT || !newIcon.options.name) {
        return newIcon.options.name === MarkerIconName.PRESELECT1;
    }
    return newIcon.options.name === currentIcon;
};

const ICON_BASE_SIZE = 32;

export const scaleMarkerIconByZoom = (icon: Icon, zoomBase: number, zoom: number): Icon => {
    const scaleFactor = Math.pow(2, zoom - zoomBase);

    const newIconSize = ICON_BASE_SIZE * scaleFactor;

    return new L.Icon({
        iconUrl: icon.options.iconUrl,
        ...icon,
        iconSize: new L.Point(newIconSize, newIconSize),
    });
};

export const getScaledMarkerIcon = (marker: Marker, zoom: number, color?: string): Icon => {
    const newIcon = getMarkerIcon(marker.iconName as MarkerIconName, color || marker.color);
    const updateAndScaleIcon = scaleMarkerIconByZoom(newIcon, marker.zoomBase || zoom, zoom);
    return updateAndScaleIcon;
};
