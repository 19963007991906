import L from 'leaflet';
import { SidcMapValue, MilSymbolValues } from './MilSymbol/values';
import MilitaryMarker from './military-marker';
const ms = require('./MilSymbol/milsymbol.js');

export const BASE_MILITARY_MARKER_SIZE = 35;

export const SIDCPartMap = {
    affiliation: { index: 3, length: 1 }, // affiliationValues
    symbolSet: { index: 4, length: 2 }, // symbolSetValues
    unit: { index: 10, length: 6 }, // mainIcon, unitValues
    echelon: { index: 8, length: 2 }, // amplifier, echelonValues
};

export const updateSIDC = (sidc: string, partName: SidcMapValue, newValue: string, symbolSet?: string): string => {
    const part = SIDCPartMap[partName];
    if (!part) {
        return sidc;
    }

    let newSidc = sidc;
    if (symbolSet) {
        const symbolPart = SIDCPartMap.symbolSet;
        newSidc = newSidc.slice(0, symbolPart.index) + symbolSet + newSidc.slice(symbolPart.index + symbolPart.length);
    }

    return newSidc.slice(0, part.index) + newValue + newSidc.slice(part.index + part.length);
};

export const getSIDCName = (options: MilSymbolValues[], sidc: string, partName: SidcMapValue): MilSymbolValues => {
    const part = SIDCPartMap[partName];
    if (!part) {
        return options[0];
    }
    const partValue = sidc.slice(part.index, part.index + part.length);
    const selectedOption = options.find((option) => option.value === partValue);
    if (partValue !== '000000' && !selectedOption && partName === 'unit') {
        return { value: partValue, text: 'Custom' };
    }

    return selectedOption || options[0];
};

export const createMilitryMarkerSVGIcon = (sidc: string, size = BASE_MILITARY_MARKER_SIZE): L.DivIcon => {
    const symbol = new ms.Symbol(sidc, { size: size });

    const svgSymbol = symbol.asSVG();
    const svg = new DOMParser().parseFromString(svgSymbol, 'image/svg+xml').documentElement;

    const width = parseFloat(svg.getAttribute('width') || '0');
    const height = parseFloat(svg.getAttribute('height') || '0');

    const icon = L.divIcon({
        className: 'military-marker-icon',
        html: svgSymbol,
        iconAnchor: new L.Point(width / 2, height / 2),
    });
    return icon;
};

export const scaleAndCreateMilitaryMarkerIcon = (marker: MilitaryMarker, zoom: number) => {
    const zoomBase = marker.zoomBase || zoom;
    const scaleFactor = Math.pow(2, zoom - zoomBase);
    const newIconSize = BASE_MILITARY_MARKER_SIZE * scaleFactor;
    const resizedIcon = createMilitryMarkerSVGIcon(marker.sidc, newIconSize);
    return resizedIcon;
};

interface MilitarySVG {
    svgElement: HTMLElement;
    width: number;
    height: number;
    isValid: boolean;
}

export const createMilitaryMarkerSVGPreview = (sidc: string): MilitarySVG => {
    const symbol = new ms.Symbol(sidc, { size: BASE_MILITARY_MARKER_SIZE });
    const svgSymbol = symbol.asSVG();
    const svg = new DOMParser().parseFromString(svgSymbol, 'image/svg+xml').documentElement;

    const width = parseFloat(svg.getAttribute('width') || '0');
    const height = parseFloat(svg.getAttribute('height') || '0');

    return { svgElement: svg, width, height, isValid: symbol.validIcon };
};
