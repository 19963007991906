import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectFillPatterns, selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import { handlePatternPreview } from '../FillPattern/pattern-util';
import FillColorModal from './fill-color-modal';

interface FillColorProps {
    selectedColor: string;
    onSelectColor: (color: string) => void;
    opacity: number;
    onSetOpacity: (opacity: number) => void;
    showFillPattern?: boolean;
    fillPattern?: string;
    onSetFillPatternUrl?: (fillPatternUrl: string) => void;
}

const FillColor = (props: FillColorProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toolbarButtonRef = useRef<HTMLButtonElement>(null);

    const patterns = useSelector(selectFillPatterns);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const pattern = patterns.find((p) => p.shapeId === selectedAnnotation?.id);

    const handlePatternFillImageSrc = () => {
        return handlePatternPreview(pattern);
    };

    return (
        <React.Fragment>
            <Button
                ref={toolbarButtonRef}
                isSelected={isOpen}
                title="Fill Color"
                onClick={() => setIsOpen((current) => !current)}
            >
                {props.fillPattern && props.fillPattern !== 'none' ? (
                    <FillPatternPreview src={handlePatternFillImageSrc()} />
                ) : (
                    <FillPreview color={props.selectedColor} />
                )}
            </Button>

            {isOpen && (
                <FillColorModal
                    toolbarButtonRef={toolbarButtonRef}
                    title="Select Fill Color"
                    onClose={() => setIsOpen(false)}
                    selectedColor={props.selectedColor}
                    onSelectColor={props.onSelectColor}
                    opacity={props.opacity}
                    onSetOpacity={props.onSetOpacity}
                    showFillPattern={props.showFillPattern}
                    selectedFillPattern={props.fillPattern}
                    onSetFillPatternUrl={props.onSetFillPatternUrl}
                />
            )}
        </React.Fragment>
    );
};

export default FillColor;

const Button = styled.button<{ isSelected: boolean }>`
    background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
    color: inherit;
    border: none;
    border-radius: 4px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 6px 4px 4px 4px;
    padding: 7px 12px;
    display: flex;
    flex-direction: row;
    height: 38px;
`;

const FillPreview = styled.div<{ color: string }>`
    width: 18px;
    height: 18px;
    background: ${(props) => props.color};
    border: 1px solid white;
    margin: 2px 0 0 0;
    border-radius: 9px;
`;

const FillPatternPreview = styled.img`
    width: 18px;
    height: 18px;
    border: 1px solid white;
    margin: 2px 0 0 0;
    border-radius: 9px;
`;
