export default class PointsUtil {

    // We no longer use 'levels' for the points system, however
    // this formula may prove useful in the future for things like the
    // admin dashboard histogram
    //
    // public static pointsForLevel = (level: number): number => {
    //     // y = 15x^2 + 85x
    //     const levelMinusOne = level - 1;
    //     return (15 * (levelMinusOne * levelMinusOne)) + (85 * levelMinusOne);
    // }

    // public static levelForPoints (points: number): number {
    //     // Inverse of above formula using the quadratic equation
    //     return Math.floor(((85 - (Math.sqrt(7225 + (60 * points)))) / 30) * -1) + 1;
    // }

    public static pointsFormatter = (points: number | undefined): string => {
        if (!points || points === 0) return "0";
        const formatter = Intl.NumberFormat('en', { notation: 'compact'})
        return formatter.format(points).replace("K", "k");
    }
}


