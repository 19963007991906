import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import { CoordinateSystemOption, coordinateSystemOptions } from './coordinate-util';

interface CoordinateSystemSelectProps {
    latLng: L.LatLng;
    coordinateSystem: string;
    onSelect: (coordinateSystem: CoordinateSystemOption) => void;
}

const CoordinateSystemSelect = (props: CoordinateSystemSelectProps) => {
    const [selectedOption, setSelectedOption] = useState<SingleValue<CoordinateSystemOption>>(
        coordinateSystemOptions?.find((option) => option.value === props.coordinateSystem) || null
    );

    useEffect(() => {
        setSelectedOption(coordinateSystemOptions.find((option) => option.value === props.coordinateSystem) || null);
    }, [props.coordinateSystem]);

    const handleChange = (selectedOption: SingleValue<CoordinateSystemOption>) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            props.onSelect(selectedOption);
        }
    };
    return (
        <CoordinateSelect
            value={selectedOption}
            placeholder={'Select GCS'}
            options={coordinateSystemOptions}
            onChange={handleChange}
            isSearchable={false}
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    cursor: 'pointer',
                    background: 'transparent',
                    borderColor: 'rgba(255,255,255,0.95)',
                    minHeight: '40px',
                    width: '170px',
                    color: 'white',
                    '&:hover': {
                        borderColor: 'rgba(255,255,255,0.95)',
                    },
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white',
                }),
                option: (baseStyles, { isFocused }) => ({
                    ...baseStyles,
                    backgroundColor: isFocused ? 'rgba(255,255,255,0.3)' : 'transparent',
                    color: isFocused ? '#eed926' : 'white',
                }),
                menuList: (baseStyles) => ({
                    ...baseStyles,
                    position: 'fixed',
                    zIndex: 100,
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    color: 'white',
                    width: '170px',
                    borderRadius: '4px',
                    '::-webkit-scrollbar': {
                        width: '6px',
                        backgroundColor: '#343a40',
                    },
                    '::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                        backgroundColor: '#343a40',
                    },
                    '::-webkit-scrollbar-thumb': {
                        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                        backgroundColor: '#eed926',
                        borderRadius: '4px',
                    },
                }),
            }}
        />
    );
};

export default CoordinateSystemSelect;

const CoordinateSelect = styled(Select)`
    background-color: transparent;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: white;
    margin: 4px 0px 0px 8px;
`;
