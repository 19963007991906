import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';

interface PlusButtonProps {
    handleOnClick: () => void;
    type?: string;
    label: string;
}

export const PlusButton = (props: PlusButtonProps) => {
    return (
        <PlusButtonStyled
            buttonStyle={props.type === 'DARK' ? true : false}
            onClick={() => {
                props.handleOnClick();
                Analytics.Event('Plus Page', 'Clicked', props.label);
            }}
        >
            {props.label}
        </PlusButtonStyled>
    );
};

interface PlusButtonStyledProps {
    buttonStyle: boolean;
}

const PlusButtonStyled = styled.button<PlusButtonStyledProps>`
    position: relative;
    padding: 4px 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-radius: 6px;
    border: ${(props) => (props.buttonStyle ? '1px solid white' : '1px solid #EED926')};
    background-color: ${(props) => (props.buttonStyle ? 'transparent' : '#EED926')};
    color: ${(props) => (props.buttonStyle ? 'white' : '#191a1a')};
    width: ${(props) => (props.buttonStyle ? '200px' : 'auto')};
    text-transform: uppercase;

    :active,
    :focus {
        outline: none;
    }
`;
