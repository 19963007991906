import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { selectActiveAllMapIsLoading } from '../../store/Map/SuperMap/selectors';
import { PulseLoader } from '../Shared/pulse-loader';
import { lightDropShadow } from '../../styles/style';

const TileLayerLoadingIndicator = () => {
    const isTileLayerLoading = useSelector(selectActiveAllMapIsLoading);

    return (
        <ToastContainer isLoading={isTileLayerLoading} className="tile-loader">
            <PulseLoader />
        </ToastContainer>
    );
};

export default TileLayerLoadingIndicator;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const ToastContainer = styled.div<{ isLoading: boolean }>`
    position: absolute;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.8);
    left: calc(50% - 75px);
    width: 150px;
    height: 45px;
    border-radius: 6px;
    animation: ${({ isLoading }) => (isLoading ? fadeIn : fadeOut)} 0.5s ease-in-out both;
    ${lightDropShadow};
`;
