import { ListingDTO, UserDTO } from '../../api/model';
import Constants from '../../constants';
import ListingHelper from '../../lib/listing-helper';
import { SeoInfo } from './seo';

export const DEFAULT_TITLE = 'Soar | The New Atlas';
export const DEFAULT_DESCRIPTION = 'Explore view and share maps and imagery of the Earth.';
export const DEFAULT_IMAGE = `https://pda.${Constants.DOMAIN}/assets/social-share-preview-image-mar-2023.jpg`;
export const DEFAULT_IMAGE_PROFILE = `https://pda.${Constants.DOMAIN}/assets/sharing-preview-user-profile.png`;

export const SEO_DEFAULT: SeoInfo = {
    title: DEFAULT_TITLE,
    description: DEFAULT_DESCRIPTION,
    imageUrl: DEFAULT_IMAGE,
};

export const SEO_MAPS: SeoInfo = {
    title: DEFAULT_TITLE,
    description: DEFAULT_DESCRIPTION,
    canonicalPath: '/maps',
    imageUrl: DEFAULT_IMAGE,
};

export const SEO_SATELLITES: SeoInfo = {
    title: DEFAULT_TITLE,
    description: DEFAULT_DESCRIPTION,
    canonicalPath: '/satellites',
    imageUrl: DEFAULT_IMAGE,
};

export const createSeoListing = (listing: ListingDTO): SeoInfo => {
    const categoriesInCapitalCase = listing.categories.map((category) => {
        return category.charAt(0).toUpperCase() + category.slice(1);
    });

    return {
        title: `${listing.title} | Soar`,
        description: [...categoriesInCapitalCase, ...listing.tags].join(' '),
        imageUrl: ListingHelper.getPreviewUrlForListingSharing(listing.id),
        canonicalPath: `/maps/${listing.id}`,
    };
};

export const createSeoProfile = (user: UserDTO): SeoInfo => {
    return {
        title: `${user.name || 'User profile'} | Soar`,
        description: `${user.bio || 'User bio'} | Soar`,
        imageUrl: DEFAULT_IMAGE_PROFILE,
        canonicalPath: `/profile/${user.userId}`,
    };
};
