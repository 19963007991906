import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
    extendContext,
} from '@react-leaflet/core';
import L from 'leaflet';
import { getMilitaryMarkerBoundingBox } from '../use-select-annotation-utils';
import { selectedPolygonOutlineOptions } from '../Polygon/polygon';
import { translateLatLngBounds } from '../Rectangle/rectangle-annotation-util';
import LayersUtil from '../layers-util';
import MilitaryMarker from './military-marker';
import { scaleAndCreateMilitaryMarkerIcon } from './military-marker-util';

interface MilitaryMarkerAnnotationProps extends L.MarkerOptions {
    marker: MilitaryMarker;
    isSelected: boolean;
    isDisabled?: boolean;
    onUpdate: (marker: MilitaryMarker) => void;
    children?: React.ReactNode;
}

const createMilitaryMarkerElement = (props: MilitaryMarkerAnnotationProps, context: LeafletContextInterface) => {
    const pane = LayersUtil.getPaneId(context.map, props.marker);

    const militaryMarkerIcon = scaleAndCreateMilitaryMarkerIcon(props.marker, context.map.getZoom());
    const marker = new L.Marker(props.marker.position, {
        draggable: props.isSelected,
        icon: militaryMarkerIcon,
        ...props.marker.options,
        pane: pane,
    });

    const markerElement = createElementObject<L.Marker, MilitaryMarkerAnnotationProps>(
        marker,
        extendContext(context, { overlayContainer: marker })
    );

    const controlPaneId = LayersUtil.getControlPaneId(context.map);

    const boundsPolygon = new L.Polygon([], {
        ...selectedPolygonOutlineOptions,
        fill: true,
        fillColor: 'transparent',
        fillOpacity: 0.1,
        interactive: true,
        pane: controlPaneId,
    });
    const boundsPolygonElement = createElementObject<L.Polygon, MilitaryMarkerAnnotationProps>(boundsPolygon, context);

    // Dragging is handled by the bounds polygon instead of the marker.drag events
    const addBoundsPolygon = () => {
        const markerBounds = getMilitaryMarkerBoundingBox(context.map, props.marker);
        const markerBoundsPositions = [
            markerBounds.getNorthWest(),
            markerBounds.getNorthEast(),
            markerBounds.getSouthEast(),
            markerBounds.getSouthWest(),
        ];
        boundsPolygonElement.instance.setLatLngs(markerBoundsPositions);
        context.map.addLayer(boundsPolygonElement.instance);

        let lastLatLng: L.LatLng | undefined;

        const onMouseOver = () => {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
        };

        const onMouseOut = () => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
        };

        const onMouseDown = (e: L.LeafletMouseEvent) => {
            lastLatLng = e.latlng;
            context.map.dragging.disable();
            context.map.on('mousemove', onMouseMove);
            context.map.on('mouseup', onMouseUp);
        };

        const onMouseUp = () => {
            lastLatLng = undefined;
            context.map.off('mousemove', onMouseMove);
            context.map.off('mouseup', onMouseUp);
            context.map.dragging.enable();
            markerElement.instance.setIcon(scaleAndCreateMilitaryMarkerIcon(props.marker, context.map.getZoom()));
            props.onUpdate({ ...props.marker, position: markerElement.instance.getLatLng() });
        };

        const onMouseMove = (e: L.LeafletMouseEvent) => {
            if (lastLatLng) {
                const newBounds = translateLatLngBounds(
                    boundsPolygonElement.instance.getBounds(),
                    lastLatLng,
                    e.latlng
                );
                const newBoundsPositions = [
                    newBounds.getNorthWest(),
                    newBounds.getNorthEast(),
                    newBounds.getSouthEast(),
                    newBounds.getSouthWest(),
                ];
                lastLatLng = e.latlng;
                boundsPolygonElement.instance.setLatLngs(newBoundsPositions);
                markerElement.instance.setLatLng(newBounds.getCenter());
            }
        };

        boundsPolygon.on('mouseover', onMouseOver);
        boundsPolygon.on('mouseout', onMouseOut);
        boundsPolygon.on('mousedown', onMouseDown);
        boundsPolygon.on('mouseup', onMouseUp);
    };

    const removeBoundsPolygon = () => {
        context.map.removeLayer(boundsPolygonElement.instance);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
    };

    context.map.on('zoomend', () => {
        markerElement.instance.setIcon(scaleAndCreateMilitaryMarkerIcon(props.marker, context.map.getZoom()));
    });

    markerElement.instance.on('add', () => {
        if (props.isSelected) {
            context.map.addLayer(boundsPolygonElement.instance);
            addBoundsPolygon();
        } else {
            context.map.removeLayer(boundsPolygonElement.instance);
            removeBoundsPolygon();
        }
    });

    markerElement.instance.on('remove', () => {
        removeBoundsPolygon();
    });

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        markerElement.instance.options.interactive = false;
    } else {
        markerElement.instance.options.interactive = true;
    }

    return markerElement;
};

const useMilitaryMarkerElement = createElementHook<L.Marker, MilitaryMarkerAnnotationProps, LeafletContextInterface>(
    createMilitaryMarkerElement
);

const useMilitaryMarker = createLayerHook(useMilitaryMarkerElement);
const MilitaryMarkerAnnotation = createContainerComponent(useMilitaryMarker);
export default MilitaryMarkerAnnotation;
