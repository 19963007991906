export const actionTypes = {
    CLEAR_ALL: 'ANNOTATIONS/CLEAR_ALL',

    SET_SELECTED_ANNOTATION: 'ANNOTATIONS/SET_SELECTED_ANNOTATION',

    SET_CURRENT_Z_INDEX: 'ANNOTATIONS/SET_CURRENT_Z_INDEX',
    INCREMENT_CURRENT_Z_INDEX: 'ANNOTATIONS/INCREMENT_CURRENT_Z_INDEX',

    SET_ANNOTATION_TO_TOP: 'ANNOTATIONS/SET_ANNOTATION_TO_TOP',
    SET_ANNOTATION_TO_BOTTOM: 'ANNOTATIONS/SET_ANNOTATION_TO_BOTTOM',

    SET_ANNOTATION_FILL_PATTERNS: 'ANNOTATIONS/SET_ANNOTATION_FILL_PATTERNS',
};
