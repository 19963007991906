import styled from 'styled-components';
import profileIconUrl from '../Dashboard/profile-icon-url';

export const ExploreResultsEmpty = (): JSX.Element => {
    return (
        <NoResultsContainer>
            <NoResultsNotice>
                <NoResultsImage src={profileIconUrl.noImagesUrl} />
                <h3>No images to display</h3>
                <p>Try updating the search to explore the communities imagery</p>
            </NoResultsNotice>
        </NoResultsContainer>
    );
};

const NoResultsContainer = styled.div`
    text-align: center;
    width: 100%;
    height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    position: fixed;

    @media only screen and (max-width: 600px) {
        height: inherit;
        padding-bottom: 20px;
    }
`;

const NoResultsImage = styled.img`
    width: 130px;
    height: 130px;
    margin-bottom: 30px;
`;

const NoResultsNotice = styled.div`
    color: inherit;
    max-width: 400px;
    height: inherit;
    margin-top: 300px;

    @media only screen and (max-width: 600px) {
        height: 100vh;
        margin-top: 200px;
    }

    h3 {
        color: inherit;
    }

    p {
        color: inherit;
        font-size: 1rem;
    }
`;
