import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { PulseLoader } from '../../../../Shared/pulse-loader';
import SoarModal, { SoarModalProps, StyledModalBody } from '../../../../Shared/soar-modal';
import { inputMaxValues } from '../../../../../lib/limitation-constants';
import { StyledButton } from '../../../../Shared/styled-button';
import { StoaryResponse } from '../../../../../api/stoaryModel';

interface StoryBuilderProjectDetailModalProps extends SoarModalProps {
    project: StoaryResponse;
    onSubmit: (title: string) => void;
    error?: string;
}

const StoryBuilderProjectDetailModal = (props: StoryBuilderProjectDetailModalProps) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [titleLength, setTitleLength] = useState<number>(props.project?.title?.length ?? 0);
    const [formValid, setFormValid] = useState<boolean>(true);

    useEffect(() => {
        if (props.isOpen) {
            setTitle(props.project.title ?? '');
            setIsUpdating(false);
        }
    }, [props.isOpen, props.project?.title]);

    const checkForm = () => {
        if (!title) {
            return false;
        }

        setIsUpdating(true);

        props.onSubmit(title);
        return true;
    };

    const setProjectTitle = (title: string) => {
        if (title.length < inputMaxValues.MAX_TITLE_CHARACTERS) {
            setTitle(title);
            setTitleLength(title.length);
        }
    };

    return (
        <ProjectDetailModal title="My Stoary" isOpen={props.isOpen} toggle={props.toggle}>
            <ProjectDetailModalBody>
                <Content>
                    <Label>Stoary Title</Label>
                    <div className="d-flex" style={{ margin: '0px 1px' }}>
                        <TextField
                            type="text"
                            value={title}
                            onChange={(e) => setProjectTitle(e.target.value)}
                            placeholder="Give your Stoary a catchy title"
                            disabled={titleLength === inputMaxValues.MAX_TITLE_CHARACTERS ? true : false}
                        />
                    </div>

                    <CharacterCount characters={titleLength} maxCharacters={inputMaxValues.MAX_TITLE_CHARACTERS - 1}>
                        Characters: {titleLength}/{inputMaxValues.MAX_TITLE_CHARACTERS - 1}
                    </CharacterCount>
                    {!formValid && !title && <Error>Please enter a title for your Stoary</Error>}
                    {props.error && <Error>{props.error}</Error>}

                    <div className="d-flex mt-4 justify-content-between">
                        {!isUpdating ? (
                            <ProjectMetadataButtonControl>
                                <StyledButton
                                    className="mr-0 pl-4 pr-4"
                                    onClick={() => {
                                        setFormValid(checkForm());
                                    }}
                                >
                                    SAVE
                                    <i className="fa fa-save ml-2"></i>
                                </StyledButton>
                            </ProjectMetadataButtonControl>
                        ) : (
                            <PulseLoader />
                        )}
                    </div>
                </Content>
            </ProjectDetailModalBody>
        </ProjectDetailModal>
    );
};

export default StoryBuilderProjectDetailModal;

const ProjectDetailModal = styled(SoarModal)`
    color: black;
`;

const ProjectDetailModalBody = styled(StyledModalBody)`
    padding: 1rem;
`;

const Content = styled.div`
    flex: 1 1 auto;
    text-align: left;
    font-weight: 400;
    color: rgb(179, 179, 179);
`;

const TextField = styled.input`
    display: block;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    padding: 4px;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 0.25rem;
    margin: 0px 1px;
`;

const Label = styled.p`
    display: block;
    color: white;
    font-size: 16px;
    margin: 4px 3px;
`;

const Error = styled.p`
    margin: 0px;
    color: red !important;

    &.markdown-error {
        margin-top: 5px;
        color: white !important;
    }
`;

const ProjectMetadataButtonControl = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

interface CharacterCountProps {
    characters: number;
    maxCharacters: number;
}

const CharacterCount = styled.span<CharacterCountProps>`
    color: ${(props) =>
        props.characters >= props.maxCharacters ? 'rgba(255, 0, 0, 0.7);' : 'rgba(255, 255, 255, 0.5);'};
    font-size: 10px;
    text-align: right;
    display: block;
    margin-right: 3px;
`;
