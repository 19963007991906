import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ApiComments from '../../../api/api-comments';
import ShareService from '../../../lib/share-service';
import { actionSetDisplayAnnotations } from '../../../store/SocialMapping/actions';

const useShareLinkAnnotations = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const params = ShareService.parseQueryString(window.location.href);
        const commentId = params['annotations'];
        if (commentId) {
            ApiComments.getCommentById(commentId).then((comment) => {
                if (comment) {
                    dispatch(actionSetDisplayAnnotations(comment.annotations));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useShareLinkAnnotations;
