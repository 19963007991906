import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import { PlusButton } from './plus-button';
import { PlusCard } from './plus-card';

export const PlusWhatIs = () => {
    return (
        <PlusWhatIsContainer>
            <PlusWhatIsBody>
                <WhatIsTitle>WHAT IS SOAR+</WhatIsTitle>
                <WhatIsText>
                    Soar+ is the premier solution that allows users to store, view and share maps and imagery in one
                    simple mapping platform.
                </WhatIsText>
                <WhatIsQuote>"It's like Google Earth had a baby with Dropbox"</WhatIsQuote>
                <WhatIsUser>
                    <em>-Chris Devlin, iSpatial Solutions</em>
                </WhatIsUser>
                <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
                <PlusWhatIsGridIcons>
                    <PlusWhatRowItem>
                        <Col xs="6" sm="4">
                            <PlusCard
                                img="/assets/plus-images/plus-what-is-it/Satellite-New.svg"
                                text="Satellite Imagery"
                            />
                        </Col>
                        <Col xs="6" sm="4">
                            <PlusCard img="/assets/plus-images/plus-what-is-it/plane.svg" text="Aerial Imagery" />
                        </Col>
                        <Col sm="4">
                            <PlusCard img="/assets/plus-images/plus-what-is-it/drone_v2.svg" text="Drone Imagery" />
                        </Col>
                    </PlusWhatRowItem>
                    <PlusWhatRowItem>
                        <Col xs="6" sm="4">
                            <PlusCard
                                img="/assets/plus-images/plus-what-is-it/Drone-Ortho.svg"
                                text="Drone Orthomosaics"
                            />
                        </Col>
                        <Col xs="6" sm="4">
                            <PlusCard
                                img="/assets/plus-images/plus-what-is-it/Remote-Sensing.svg"
                                text="Remote sensing data"
                            />
                        </Col>
                        <Col sm="4">
                            <PlusCard img="/assets/plus-images/plus-what-is-it/Elevation.svg" text="Topographic maps" />
                        </Col>
                    </PlusWhatRowItem>
                    <PlusWhatRowItem>
                        <Col xs="6" sm="4">
                            <PlusCard img="/assets/plus-images/plus-what-is-it/Geo-v2.svg" text="Geological maps" />
                        </Col>
                        <Col xs="6" sm="4">
                            <PlusCard img="/assets/plus-images/plus-what-is-it/Topographic.svg" text="Elevation maps" />
                        </Col>
                        <Col sm="4">
                            <PlusCard img="/assets/plus-images/plus-what-is-it/Scanned.svg" text="Scanned maps" />
                        </Col>
                    </PlusWhatRowItem>
                </PlusWhatIsGridIcons>
            </PlusWhatIsBody>
            <PlusWhatIsButtonContainer>
                <PlusWhatIsMoreText>AND MORE!</PlusWhatIsMoreText>
                <PlusButton handleOnClick={() => UriHelper.navigateToPath('/')} label="LAUNCH SOAR.EARTH" />
            </PlusWhatIsButtonContainer>
        </PlusWhatIsContainer>
    );
};

const PlusWhatIsContainer = styled.div`
    margin: 80px 0px;
    background-image: url('/assets/plus-images/plus-what-is-it/what-is-background.png');
    background-position: 50% 50%;
    background-size: cover;
    min-height: inherit;
`;

const PlusWhatIsBody = styled.div`
    margin-right: 80px;
    margin-left: 80px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 100px;
    padding-bottom: 140px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;

    @media only screen and (max-width: 600px) {
        margin-right: 15px;
        margin-left: 15px;
        padding-top: 0px;
        text-align: center;
    }
`;

const WhatIsTitle = styled.h1`
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
    color: inherit;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
    }
`;

const WhatIsText = styled.h4`
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 20px;
    color: inherit;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
        margin-top: 10px;
    }
`;

const WhatIsQuote = styled.p`
    font-size: 20px;
    color: inherit;
    margin-bottom: 0px;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

const WhatIsUser = styled.span`
    color: inherit;
    font-size: 20px;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

const PlusWhatRowItem = styled(Row)`
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const PlusWhatIsGridIcons = styled.div`
    max-width: 640px;
    margin: auto;
    height: 100px;
    width: -webkit-fill-available;
    margin-top: 60px;
    margin-bottom: 60px;
`;

const PlusWhatIsButtonContainer = styled.div`
    margin: auto;
    width: fit-content;
    padding: 180px 0px 100px 0px;
    text-align: center;

    @media only screen and (max-width: 600px) {
        padding: 1150px 0px 10px 0px; // TODO this works but a little funk, containers keep overlapping for some reason
    }
`;

const PlusWhatIsMoreText = styled.h1`
    color: white;
    font-size: 32px;
    padding-bottom: 30px;
    font-weight: 700;
`;

const Divider = styled.img`
    margin-top: 60px;
`;
