import { createElementObject, createTileLayerComponent, updateGridLayer } from '@react-leaflet/core';
import { TileLayer } from 'leaflet';
import { LayerOptions } from 'leaflet';
import Constants from '../../../../constants';
import { LabelRule, leafletLayer, PaintRule, paintRules, SourceOptions } from 'protomaps-leaflet';
import { PMTiles } from 'pmtiles';
import { BLACK, DARK, DEFAULT_SOAR_DARK, DEFAULT_SOAR_LIGHT, GRAYSCALE, LIGHT, WHITE } from './protomap-themes';

type ProtomapTileLayerUrl = PMTiles | string;

type Status = {
    status: string;
    value?: unknown;
    reason: Error;
};

/** ProtomapsTileLayerOptions
 *  Used internally by protomap leafletLayer
 **/
export interface ProtomapsTileLayerOptions extends LayerOptions {
    //bounds?: number[][]; // Disabled as causes a mismatch for createTileLayer and leafletLayer
    attribution?: string;
    debug?: string;
    lang?: string;
    tileDelay?: number;
    language?: string[];
    noWrap?: boolean;
    paintRules?: PaintRule[];
    labelRules?: LabelRule[];
    tasks?: Promise<Status>[];
    maxDataZoom?: number;
    url?: ProtomapTileLayerUrl;
    sources?: Record<string, SourceOptions>;
    theme?: string;
    backgroundColor?: string;
}

export const ProtomapsTileLayer = createTileLayerComponent<TileLayer, ProtomapsTileLayerOptions>(
    function createTileLayer({ ...options }, context) {
        const layer = leafletLayer({ ...options }) as TileLayer;
        return createElementObject(layer, context);
    },
    updateGridLayer
);

export const SOAR_OSM_URL = `https://basemaps-pm.${Constants.DOMAIN}/osm/{z}/{x}/{y}.mvt`;
export const SOAR_LIGHT: PaintRule[] = paintRules(DEFAULT_SOAR_LIGHT);
export const SOAR_DARK: PaintRule[] = paintRules(DEFAULT_SOAR_DARK);
export const OSM_LIGHT: PaintRule[] = paintRules(LIGHT);
export const OSM_DARK: PaintRule[] = paintRules(DARK);
export const OSM_GRAYSCALE: PaintRule[] = paintRules(GRAYSCALE);
export const OSM_WHITE: PaintRule[] = paintRules(WHITE);
export const OSM_BLACK: PaintRule[] = paintRules(BLACK);
