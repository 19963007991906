import React, { useEffect, useState } from 'react';
import { isMobileVersion } from '../../../../lib/soar-helper';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import ApiComments from '../../../../api/api-comments';
import { CommentResultsDTO } from '../../../../api/model';
import {
    actionSetEditingComment,
    actionSetReplyingToComment,
    actionSetResetCommentSection,
    actionSetSelectedCommentId,
} from '../../../../store/SocialMapping/actions';
import ActiveListingCommentItem from './comment';
import { PulseLoader } from '../../../Shared/pulse-loader';

interface CommentListProps {
    listingId: number;
    commentUpdate?: boolean;
    setCommentCount?: (commentCount: number | undefined) => void;
    allowComment: boolean;
}

export const CommentList = (props: CommentListProps) => {
    const { listingId, commentUpdate, setCommentCount } = props;
    const [commentList, setCommentLists] = useState<CommentResultsDTO[]>([]);
    const [isLoadingComments, setIsLoadingComments] = useState<boolean>(true);

    const dispatch = useDispatch();

    //reset these redux states when leaving a map
    useEffect(() => {
        return () => {
            dispatch(actionSetSelectedCommentId(''));
            dispatch(actionSetReplyingToComment(false));
            dispatch(actionSetEditingComment(false));
            dispatch(actionSetResetCommentSection(true));
        };
    }, [dispatch]);

    useEffect(() => {
        const getComments = () => {
            setCommentLists([]);
            ApiComments.getCommentsForListingById(listingId.toString())
                .then((comments) => {
                    if (comments) {
                        setCommentLists(comments.reverse());
                        const activeCommentCount = comments.filter((comment) => comment.userName).length;
                        setCommentCount && setCommentCount(activeCommentCount ? activeCommentCount : undefined);
                    }
                })
                .finally(() => {
                    setIsLoadingComments(false);
                });
        };
        if (isLoadingComments) {
            getComments();
        }
    }, [commentList.length, isLoadingComments, listingId, setCommentCount]);

    useEffect(() => {
        if (commentUpdate) {
            setIsLoadingComments(true);
        }
    }, [commentUpdate]);

    const comments = commentList.filter((comment) => comment.createdAt !== undefined).length;

    const deletedComment = (comment: CommentResultsDTO) => {
        const children = comment.children?.filter((comment) => comment.children).length;
        if (!comment.userId && children === 0) {
            return true;
        }
        return false;
    };

    return (
        <React.Fragment>
            {isLoadingComments ? (
                <CommentLoader>
                    <PulseLoader />
                </CommentLoader>
            ) : (
                <ActiveCommentListContainer className={isMobileVersion && comments > 0 ? 'mobile-comment-list' : ''}>
                    {commentList.map((comment) => {
                        if (deletedComment(comment)) {
                            return;
                        }
                        return (
                            <ActiveListingCommentItem
                                key={comment.id}
                                listingId={listingId.toString()}
                                comment={comment}
                                handleCommentUpdate={(update) => {
                                    setIsLoadingComments(update);
                                }}
                                allowComment={props.allowComment}
                            />
                        );
                    })}
                </ActiveCommentListContainer>
            )}
        </React.Fragment>
    );
};

const ActiveCommentListContainer = styled.div`
    margin: 5px;
`;

const CommentLoader = styled.div`
    padding: 20px;
`;
