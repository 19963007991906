import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import packageJson from '../package.json';
import Api from './api/api';
import ApiSubdomain from './api/api-subdomain';
import ApiUser from './api/api-user';
import { Permission } from './api/model';
import NavigationBar from './components/NavigationBar/navbar';
import PurchaseThisSubdomain from './components/Pricing/purchase-this-subdomain';
import LoginDialog from './components/Registration/login-dialog';
import SuspendedRoutesMap from './components/Subdomain/suspended-routes-map';
import Maintenance from './components/maintenance';
import Constants from './constants';
import SoarHelper, { isMobileVersion } from './lib/soar-helper';
import Analytics from './lib/user-analytics';
import UserHelper from './lib/user-helper';
import Routes from './routes';
import RoutesMobile from './routes-mobile';
import { actionGetMyProfileSuccess } from './store/Account/actions';
import { actionUpdateConfigSuccess } from './store/App/actions';
import { selectConfig, selectMaintenance } from './store/App/selectors';
import { actionGetDomainUsers, actionGetSubdomain, actionGetSubdomainListings } from './store/Dashboard/actions';
import { actionFetchLikedMaps } from './store/Map/Favourites/actions';
import { actionGetNotifications } from './store/Notifications/actions';
import { useLocation } from 'react-router-dom';

const ConfiguredRoot = () => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const maintenance = useSelector(selectMaintenance);

    const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false);

    const url = useLocation();

    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_ID_G4);
        Analytics.setDeviceDimension();

        ApiSubdomain.configGet().then((config) => {
            dispatch(actionUpdateConfigSuccess(config));

            console.log('Soar Platform v' + packageJson.version);
            console.log('React stage: ' + process.env.REACT_APP_STAGE);
            console.log('React environment: ' + process.env.REACT_APP_ENVIRONMENT);
            console.log('Subdomain: ' + Api.getSubdomain());
            console.log('Root API: ' + Constants.API_URL);

            if (UserHelper.getAccessToken() || UserHelper.getRefreshToken()) {
                ApiUser.getMyUserProfile()
                    .then((user) => {
                        dispatch(actionGetMyProfileSuccess(user));

                        if (SoarHelper.isSoar()) {
                            dispatch(actionFetchLikedMaps());
                        } else if (UserHelper.getSubdomainPermission(user) !== Permission.None) {
                            dispatch(actionGetSubdomain());
                            dispatch(actionGetSubdomainListings());
                            dispatch(actionGetDomainUsers());
                        }
                        dispatch(actionGetNotifications());
                    })
                    .finally(() => {
                        setHasAttemptedLogin(true);
                    });
            } else {
                setHasAttemptedLogin(true);
            }
        });
    }, [dispatch]);

    if (maintenance) {
        return <Maintenance />;
    }

    if (config.IS_AVAILABLE_FOR_PURCHASE) {
        return <PurchaseThisSubdomain />;
    }

    if (config.STATUS === 'SUSPENDED') {
        return <SuspendedRoutesMap />;
    }

    if (!hasAttemptedLogin) {
        return <React.Fragment />;
    } else {
        const isLandingPage = url.pathname === '/';

        return (
            <React.Fragment>
                {!isMobileVersion ? (
                    <React.Fragment>
                        <NavigationBar transparent={isLandingPage} />
                        <ToastContainer className="toast-container" autoClose={3000} />
                        <LoginDialog />
                        <Routes />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ToastContainer className="toast-container" autoClose={3000} />
                        <LoginDialog />
                        <RoutesMobile />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
};

export default ConfiguredRoot;
