import ApiNotification from '../../api/api-notification';
import { NotificationDTO } from '../../api/model';
import { actionTypes as at } from './constants';

export const actionGetNotifications = (offset: number | undefined = undefined) => {
    return async (dispatch) => {
        dispatch(actionNotificationsLoading(true));
        try {
            const notifications: NotificationDTO[] = await ApiNotification.getNotifications(true, 10, offset);
            if (notifications) {
                dispatch(actionGetNotificationsSuccess(notifications, offset));
            }
        } catch (error) {
            dispatch(actionGetNotificationsError());
        } finally {
            dispatch(actionNotificationsLoading(false));
        }
    };
};

export const actionSetViewedNotifications = (idArray: number[]) => {
    return async () => {
        try {
            // Needs work, API call should be directly called from the component
            await ApiNotification.setViewedNotifications(idArray);
        } catch (error) {
            // TODO some error
        }
    };
};

const actionGetNotificationsSuccess = (notifications: NotificationDTO[], offset: number | undefined = undefined) => {
    return {
        type: at.NOTIFICATION_GET_SUCCESS,
        payload: { notifications, offset },
    };
};

const actionGetNotificationsError = () => {
    return {
        type: at.NOTIFICATION_GET_ERROR,
        payload: [],
    };
};

const actionNotificationsLoading = (loading: boolean) => {
    return {
        type: at.NOTIFICATION_LOADING,
        payload: loading,
    };
};

export const actionIsNotificationsOpen = (open: boolean) => {
    return {
        type: at.NOTIFICATIONS_OPEN,
        payload: open,
    };
};
