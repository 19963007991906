import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSelectedAnnotation } from '../../../store/Annotations/selectors';
import MarkerToolbar from './Marker/marker-toolbar';
import PolylineToolbar from './Polyline/polyline-toolbar';
import PolygonToolbar from './Polygon/polygon-toolbar';
import ArrowToolbar from './Arrow/arrow-toolbar';
import RectangleToolbar from './Rectangle/rectangle-toolbar';
import CircleToolbar from './Circle/circle-toolbar';
import TextboxToolbar from './Text/textbox-toolbar';
import ImageToolbar from './ImageTool/image-toolbar';
import CoordinateToolbar from './Coordinate/coordinate-toolbar';
import FreehandPolylineToolbar from './FreehandPolyline/freehand-polyline-toolbar';
import MilitaryMarkerToolbar from './MilitaryMarker/military-marker-toolbar';

const AnnotationToolbar = () => {
    const selectedAnnotation = useSelector(selectSelectedAnnotation);

    if (!selectedAnnotation) {
        return <React.Fragment />;
    }

    switch (selectedAnnotation.annotationType) {
        case 'Marker':
            return <MarkerToolbar />;

        case 'MilitaryMarker':
            return <MilitaryMarkerToolbar />;

        case 'Polyline':
            return <PolylineToolbar />;

        case 'Polygon':
            return <PolygonToolbar />;

        case 'Arrow':
            return <ArrowToolbar />;

        case 'Rectangle':
            return <RectangleToolbar />;

        case 'Circle':
            return <CircleToolbar />;

        case 'TextBox':
            return <TextboxToolbar />;

        case 'Image':
            return <ImageToolbar />;

        case 'Coordinate':
            return <CoordinateToolbar />;

        case 'FreehandPolyline':
            return <FreehandPolylineToolbar />;

        default:
            throw new Error('Invalid annotation type');
    }
};

export default AnnotationToolbar;

export const ToolbarContainer = styled.div`
    position: fixed;
    top: 74px;
    left: 4px;
    right: 4px;
    height: 48px;
    z-index: ${(props) => props.theme.zIndex.nav};
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const ToolbarText = styled.span`
    color: white;
    font-size: 1rem;

    margin-top: 12px;
    margin-left: 2px;

    font-weight: bold;
`;

export const ToolbarDivider = styled.div`
    width: 1px;
    height: 100%;
    margin-left: 12px;
    margin-right: 12px;
`;

export const ToggleLabel = styled.label`
    color: white !important;
    font-size: 16px;
    margin-top: 12px;

    input {
        margin-right: 5px;
        margin-top: 3px;
    }
`;

export const ToolbarReorderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ToolbarButton = styled.button`
    top: 2px;
    right: 0px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: white;
`;

export const ToolbarDeleteIcon = styled.img`
    width: 32px;
`;

export const ToolbarSortIcon = styled.i`
    color: white;
`;

export const ToolbarMenuItem = styled.div`
    display: flex;
    flex-direction: row;
`;
