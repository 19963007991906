import { actionTypes as at } from './constants';
import { LatLng } from 'leaflet';

export const actionAddDroneImageForUpload = (previewUrl: string, positions?: number[][]) => {
    return {
        type: at.ADD_DRONE_IMAGE_FOR_UPLOAD,
        payload: {
            droneImagePreviewUrl: previewUrl,
            droneImagePositions: positions,
        },
    };
};

export const actionClearDroneImageUpload = () => {
    return {
        type: at.CLEAR_DRONE_IMAGE_FOR_UPLOAD,
    };
};

export const actionSetDroneImageDistortionTool = (mode: 'none' | 'rotate' | 'translate' | 'distort' | 'scale') => {
    return {
        type: at.SET_DRONE_IMAGE_DISTORTION_TOOL,
        payload: mode,
    };
};

export const actionSetDroneImageDistortionOpacity = (opacity: number) => {
    return {
        type: at.SET_DRONE_IMAGE_OPACITY,
        payload: opacity,
    };
};

export const actionSetDroneImageDistortionCorners = (corners: number[][]) => {
    return {
        type: at.SET_DRONE_IMAGE_DISTORTION_CORNERS,
        payload: corners,
    };
};

export const actionSetDroneImageManualPosition = (position: LatLng | undefined) => {
    return {
        type: at.SET_DRONE_IMAGE_MANUAL_POSITION,
        payload: position,
    };
};
