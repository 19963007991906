import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import styled from 'styled-components';

import { AutocompleteDTO } from '../../../api/model';
import ApiAutocomplete from '../../../api/api-autocomplete';
import ApiListings from '../../../api/api-listings';
import GeoUtil from '../../../lib/geo-util';

const DEBOUNCE_TIME = 300; //ms
const RESULTS_LIMIT = 50;

interface LocationSearchInputProps {
    handleResults: (results: AutocompleteDTO[]) => void;
    handleOnLoading: (loading: boolean) => void;
    toggleLocationSearch: () => void;
}

const LocationSearchInput = (props: LocationSearchInputProps) => {
    const [searchSubject] = useState(() => new Subject<string>());
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState('');

    let closeTimeout: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (debouncedSearchInput.length <= 2) {
            props.handleResults([]);
            props.handleOnLoading(false);
        } else {
            props.handleOnLoading(true);
            ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
            ApiAutocomplete.autocomplete(debouncedSearchInput, ['ADDRESS'], RESULTS_LIMIT)
                .then((res) => {
                    props.handleResults(res);
                })
                .finally(() => {
                    props.handleOnLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchInput]);

    useEffect(() => {
        const coordinate = GeoUtil.getCoordinateFromSearchTerm(searchTerm);
        if (coordinate) {
            const autocomplete: AutocompleteDTO = {
                title: searchTerm,
                type: 'COORDINATE',
                latlng: coordinate,
            };
            props.handleResults([autocomplete]);
            props.handleOnLoading(false);
        } else {
            searchSubject.next(searchTerm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                tap((value) => {
                    if (value.length <= 2) {
                        props.handleResults([]);
                        props.handleOnLoading(false);
                    }
                    ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
                    ApiListings.cancelGetListings('Cancel get listings');
                }),
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe((next) => {
                setDebouncedSearchInput(next);
            });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            handleClose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        setSearchTerm('');
        props.handleResults([]);
        props.handleOnLoading(false);

        if (searchTerm.length === 0) {
            props.toggleLocationSearch();
        }
    };

    const handleBlur = () => {
        if (searchTerm.length === 0) {
            closeTimeout = setTimeout(() => handleClose(), 5000);
        }
    };

    const handleFocus = () => {
        if (closeTimeout) clearTimeout(closeTimeout);
    };

    return (
        <SearchInputContainer>
            <SearchInput
                placeholder="Search for Location"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => handleFocus()}
                onBlur={() => handleBlur()}
            />
            <CloseButton onClick={() => handleClose()}>
                <CloseIcon className="fa fa-close" />
            </CloseButton>
        </SearchInputContainer>
    );
};

export default LocationSearchInput;

const SearchInputContainer = styled.div`
    width: auto;
    height: 40px;

    border: 1px solid #515151;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    padding-left: 6px;
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
        0px 9px 44px 8px rgba(0, 0, 0, 0.22);

    &:before {
        content: '';
        width: 28px;
        height: 28px;
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2232px%22%20height%3D%2232px%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M%2019%203%20C%2013.489%203%209%207.489%209%2013%20C%209%2015.395%209.839%2017.5875%2011.25%2019.3125%20L%203.28125%2027.28125%20L%204.71875%2028.720703%20L%2012.6875%2020.751953%20C%2014.4115%2022.161953%2016.605%2023%2019%2023%20C%2024.511%2023%2029%2018.511%2029%2013%20C%2029%207.489%2024.511%203%2019%203%20z%20M%2019%205%20C%2023.43%205%2027%208.57%2027%2013%20C%2027%2017.43%2023.43%2021%2019%2021%20C%2014.57%2021%2011%2017.43%2011%2013%20C%2011%208.57%2014.57%205%2019%205%20z%20M%2019%208%20C%2016.791%208%2015%209.791%2015%2012%20C%2015%2015%2019%2019%2019%2019%20C%2019%2019%2023%2015%2023%2012%20C%2023%209.791%2021.209%208%2019%208%20z%20M%2019%2010%20C%2020.105%2010%2021%2010.895%2021%2012%20C%2021%2013.104%2020.105%2014%2019%2014%20C%2017.895%2014%2017%2013.104%2017%2012%20C%2017%2010.895%2017.895%2010%2019%2010%20z%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E');
        background-size: contain;
    }
`;
const SearchInput = styled.input`
    margin: 6px 4px;
    border: none;
    background: transparent;
    outline: none;
    color: white;
    width: 250px;
    flex: 1;
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    outline: none !important;
    text-align: center;
`;

const CloseIcon = styled.i`
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin-right: 9px;
`;
