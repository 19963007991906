import MeasurementToolbar from '../Measurement/measurement-toolbar';
import MeasurementToolbarLabelColor, { MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import MeasurementToolbarPolygonLength from '../Measurement/measurement-toolbar-polygon-length';
import MeasurementToolbarUnits, { MeasurementUnits } from '../Measurement/measurement-toolbar-units';
import Polyline from './polyline';

// If there are a lot of points in the polyline, we disable the length labels
// because it will impact performance
const POLYLINE_GEOMETRY_THRESHOLD_FOR_LENGTH_LABELS = 30;

interface PolylineMeasurementToolbarProps {
    polyline: Polyline;
    onChangeShowLength: (showLength: boolean) => void;
    onChangeUnits: (units: MeasurementUnits) => void;
    onChangeLabelColor: (labelColor: MeasurementLabelColor) => void;
}

const PolylineMeasurementToolbar = (props: PolylineMeasurementToolbarProps) => {
    const disableLengthLabels = props.polyline.positions.length > POLYLINE_GEOMETRY_THRESHOLD_FOR_LENGTH_LABELS;

    return (
        <MeasurementToolbar>
            <MeasurementToolbarPolygonLength
                poly={props.polyline}
                onChangeShowLength={props.onChangeShowLength}
                disabled={disableLengthLabels}
                isPolyline={true}
            />
            <MeasurementToolbarUnits units={props.polyline.units} onChangeUnits={props.onChangeUnits} />
            <MeasurementToolbarLabelColor
                labelColor={props.polyline.labelColor}
                onChangeLabelColor={props.onChangeLabelColor}
            />
        </MeasurementToolbar>
    );
};

export default PolylineMeasurementToolbar;
