import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L, { LatLngBounds } from 'leaflet';
import { useMap } from 'react-leaflet';
import { v4 as uuidv4 } from 'uuid';
import { Annotation } from '../../../store/Annotations/reducer';
import { selectSelectedAnnotation } from '../../../store/Annotations/selectors';
import { incrementZIndexAction, setSelectedAnnotationAction } from '../../../store/Annotations/actions';

import Arrow from './Arrow/arrow';
import Circle from './Circle/circle';
import Coordinate from './Coordinate/coordinate';
import PolygonPath from './Polygon/polygon';
import Polyline from './Polyline/polyline';
import Rectangle from './Rectangle/rectangle';
import TextBox from './Textbox/textbox';
import MilitaryMarker from './MilitaryMarker/military-marker';
import Image from './ImageTool/image';
import Marker from './Marker/marker';

import { selectArrows } from '../../../store/Annotations/Arrow/selectors';
import { selectCircles } from '../../../store/Annotations/Circle/selectors';
import { selectCoordinates } from '../../../store/Annotations/Coordinate/selectors';
import { selectPolygons } from '../../../store/Annotations/Polygon/selectors';
import { selectPolylines } from '../../../store/Annotations/Path/selectors';
import { selectFreehandDraws } from '../../../store/Annotations/Freehand/selectors';
import { selectRectangles } from '../../../store/Annotations/Rectangle/selectors';
import { selectTextBoxes } from '../../../store/Annotations/TextBox/selectors';
import { selectMilitaryMarkers } from '../../../store/Annotations/MilitaryMarker/selectors';
import { selectImages } from '../../../store/Annotations/Images/selectors';
import { selectMarkers } from '../../../store/Annotations/Marker/selectors';

import { setArrowAddAction } from '../../../store/Annotations/Arrow/actions';
import { setCircleAddAction } from '../../../store/Annotations/Circle/actions';
import { setCoordinateAddAction } from '../../../store/Annotations/Coordinate/actions';
import { setPolygonAddAction } from '../../../store/Annotations/Polygon/actions';
import { setPolylineAddAction } from '../../../store/Annotations/Path/actions';
import { setFreehandDrawAddAction } from '../../../store/Annotations/Freehand/actions';
import { setRectangleAddAction } from '../../../store/Annotations/Rectangle/actions';
import { setTextBoxAddAction } from '../../../store/Annotations/TextBox/actions';
import { setMilitaryMarkerAddAction } from '../../../store/Annotations/MilitaryMarker/actions';
import { setImageAddAction } from '../../../store/Annotations/Images/actions';
import { setMarkerAddAction } from '../../../store/Annotations/Marker/actions';
import { createUniqueCopiedAnnotation } from './FillPattern/pattern-util';

const PERCENT_COPY_OFFSET = 0.01;

const useCopyPasteAnnotation = () => {
    const [copiedAnnotation, setCopiedAnnotation] = useState<Annotation | null>(null);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);

    const arrows = useSelector(selectArrows);
    const circles = useSelector(selectCircles);
    const coordinates = useSelector(selectCoordinates);
    const polygons = useSelector(selectPolygons);
    const polylines = useSelector(selectPolylines);
    const freehandDraws = useSelector(selectFreehandDraws);
    const rectangles = useSelector(selectRectangles);
    const textBoxes = useSelector(selectTextBoxes);
    const militaryMarkers = useSelector(selectMilitaryMarkers);
    const images = useSelector(selectImages);
    const markers = useSelector(selectMarkers);

    const map = useMap();
    const dispatch = useDispatch();

    // Copy the selected annotation
    const handleCopy = useCallback(() => {
        if (selectedAnnotation) {
            setCopiedAnnotation(selectedAnnotation);
        }
    }, [selectedAnnotation]);

    // Use map space to calculate the offset of the copied annotation
    const calculateOffsetLatLng = useCallback(
        (latLng: L.LatLng, existingAnnotations: Annotation[]) => {
            const originalPoint = map.latLngToContainerPoint(latLng);
            const mapSize = map.getSize();
            let offsetX = mapSize.x * PERCENT_COPY_OFFSET;
            let offsetY = mapSize.y * PERCENT_COPY_OFFSET;
            let newPoint = L.point(originalPoint.x + offsetX, originalPoint.y + offsetY);
            let newLatLng = map.containerPointToLatLng(newPoint);

            // Check if the new position overlaps any existing annotation of the same type
            const isOverlapping = (position: L.LatLng) => {
                return existingAnnotations.some((annotation) => {
                    switch (annotation?.annotationType) {
                        case 'Arrow':
                            return (
                                (annotation as Arrow).startLatLng.equals(position) ||
                                (annotation as Arrow).endLatLng.equals(position)
                            );
                        case 'Circle':
                            return (annotation as Circle).center.equals(position);
                        case 'Coordinate':
                            return (annotation as Coordinate).position.equals(position);
                        case 'Polygon':
                        case 'Polyline':
                        case 'FreehandPolyline':
                            return (annotation as PolygonPath | Polyline).positions.some((pos) => pos.equals(position));
                        case 'Rectangle':
                        case 'TextBox':
                        case 'Image': {
                            const bounds = (annotation as Rectangle | TextBox | Image).bounds;
                            return bounds.getSouthWest().equals(position) || bounds.getNorthEast().equals(position);
                        }
                        case 'MilitaryMarker':
                        case 'Marker':
                            return (annotation as Marker | MilitaryMarker).position.equals(position);
                        default:
                            return false;
                    }
                });
            };

            // Loop to increase the offset if the copy is on top of another
            while (isOverlapping(newLatLng)) {
                offsetX += mapSize.x * PERCENT_COPY_OFFSET;
                offsetY += mapSize.y * PERCENT_COPY_OFFSET;
                newPoint = L.point(originalPoint.x + offsetX, originalPoint.y + offsetY);
                newLatLng = map.containerPointToLatLng(newPoint);
            }

            return newLatLng;
        },
        [map]
    );

    // Paste the copied annotation and update its position, id and any other properties that may have changed
    const handleUpdatePosition = useCallback(
        (annotation: Annotation) => {
            switch (annotation?.annotationType) {
                case 'Arrow': {
                    const selectedArrow = arrows.find((c) => c?.id === annotation.id) as Arrow;
                    const newStartLatLng = calculateOffsetLatLng(selectedArrow.startLatLng, arrows);
                    const newEndLatLng = calculateOffsetLatLng(selectedArrow.endLatLng, arrows);
                    return {
                        ...selectedArrow,
                        id: uuidv4(),
                        startLatLng: newStartLatLng,
                        endLatLng: newEndLatLng,
                    };
                }
                case 'Circle': {
                    const selectedCircle = circles.find((c) => c?.id === annotation.id) as Circle;
                    const newCenter = calculateOffsetLatLng(selectedCircle.center, circles);

                    const updatedPattern = createUniqueCopiedAnnotation(selectedCircle);

                    if (selectedCircle.fillPattern) {
                        return {
                            ...updatedPattern,
                            center: newCenter,
                        };
                    } else {
                        return {
                            ...selectedCircle,
                            id: uuidv4(),
                            center: newCenter,
                        };
                    }
                }
                case 'Coordinate': {
                    const selectedCoordinate = coordinates.find((c) => c?.id === annotation.id) as Coordinate;
                    const newPosition = calculateOffsetLatLng(selectedCoordinate.position, coordinates);
                    return {
                        ...selectedCoordinate,
                        id: uuidv4(),
                        position: newPosition,
                    };
                }
                case 'Polygon': {
                    const selectedPolygon = polygons.find((c) => c?.id === annotation.id) as PolygonPath;
                    const newPositions = selectedPolygon?.positions.map((position) =>
                        calculateOffsetLatLng(position, polygons)
                    );
                    const updatedPattern = createUniqueCopiedAnnotation(selectedPolygon);

                    if (selectedPolygon.fillPattern) {
                        return {
                            ...updatedPattern,
                            positions: newPositions,
                        };
                    } else {
                        return {
                            ...selectedPolygon,
                            id: uuidv4(),
                            positions: newPositions,
                        };
                    }
                }
                case 'Polyline': {
                    const selectedPolyline = polylines.find((c) => c?.id === annotation.id) as Polyline;
                    const newPositions = selectedPolyline.positions.map((position) =>
                        calculateOffsetLatLng(position, polylines)
                    );
                    return {
                        ...selectedPolyline,
                        id: uuidv4(),
                        positions: newPositions,
                    };
                }
                case 'FreehandPolyline': {
                    const selectedFreehand = freehandDraws.find((c) => c?.id === annotation.id) as Polyline;
                    const newPositions = selectedFreehand.positions.map((position) =>
                        calculateOffsetLatLng(position, freehandDraws)
                    );
                    return {
                        ...selectedFreehand,
                        id: uuidv4(),
                        positions: newPositions,
                    };
                }
                case 'Rectangle': {
                    const selectedRectangle = rectangles.find((c) => c?.id === annotation.id) as Rectangle;
                    const southWestOffset = calculateOffsetLatLng(selectedRectangle.bounds.getSouthWest(), rectangles);
                    const northEastOffset = calculateOffsetLatLng(selectedRectangle.bounds.getNorthEast(), rectangles);
                    const newPositions = new LatLngBounds(southWestOffset, northEastOffset);
                    const updatedPattern = createUniqueCopiedAnnotation(selectedRectangle);

                    if (selectedRectangle.fillPattern) {
                        return {
                            ...updatedPattern,
                            bounds: newPositions,
                        };
                    } else {
                        return {
                            ...selectedRectangle,
                            id: uuidv4(),
                            bounds: newPositions,
                        };
                    }
                }
                case 'TextBox': {
                    const selectedTextBox = textBoxes.find((c) => c?.id === annotation.id) as TextBox;
                    const southWestOffset = calculateOffsetLatLng(selectedTextBox.bounds.getSouthWest(), textBoxes);
                    const northEastOffset = calculateOffsetLatLng(selectedTextBox.bounds.getNorthEast(), textBoxes);
                    const newPositions = new LatLngBounds(southWestOffset, northEastOffset);
                    return {
                        ...selectedTextBox,
                        id: uuidv4(),
                        bounds: newPositions,
                    };
                }
                case 'Image': {
                    const selectedImage = images.find((c) => c?.id === annotation.id) as Image;
                    const southWestOffset = calculateOffsetLatLng(selectedImage.bounds.getSouthWest(), images);
                    const northEastOffset = calculateOffsetLatLng(selectedImage.bounds.getNorthEast(), images);
                    const newPositions = new LatLngBounds(southWestOffset, northEastOffset);
                    return {
                        ...selectedImage,
                        id: uuidv4(),
                        bounds: newPositions,
                    };
                }
                case 'MilitaryMarker': {
                    const selectedMilMarker = militaryMarkers.find((c) => c?.id === annotation.id) as MilitaryMarker;
                    const newPosition = calculateOffsetLatLng(selectedMilMarker.position, militaryMarkers);
                    return {
                        ...selectedMilMarker,
                        id: uuidv4(),
                        position: newPosition,
                    };
                }
                case 'Marker': {
                    const selectedMarker = markers.find((c) => c?.id === annotation.id) as Marker;
                    const newPosition = calculateOffsetLatLng(selectedMarker.position, markers);
                    return {
                        ...selectedMarker,
                        id: uuidv4(),
                        position: newPosition,
                    };
                }
                default:
                    return annotation;
            }
        },
        // Do not remove the disable, we are using the side effect to update the copied annotation
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            arrows,
            circles,
            coordinates,
            polygons,
            polylines,
            freehandDraws,
            rectangles,
            textBoxes,
            images,
            militaryMarkers,
            markers,
        ]
    );

    // Paste the copied annotation
    const handlePaste = useCallback(async () => {
        if (copiedAnnotation) {
            const annotation = handleUpdatePosition(copiedAnnotation);
            if (!annotation) return;
            dispatch(incrementZIndexAction());
            switch (annotation.annotationType) {
                case 'Arrow':
                    dispatch(setArrowAddAction(annotation as Arrow));
                    break;
                case 'Circle':
                    dispatch(setCircleAddAction(annotation as Circle));
                    break;
                case 'Coordinate':
                    dispatch(setCoordinateAddAction(annotation as Coordinate));
                    break;
                case 'Polygon':
                    dispatch(setPolygonAddAction(annotation as PolygonPath));
                    break;
                case 'Polyline':
                    dispatch(setPolylineAddAction(annotation as Polyline));
                    break;
                case 'FreehandPolyline':
                    dispatch(setFreehandDrawAddAction(annotation as Polyline));
                    break;
                case 'Rectangle':
                    dispatch(setRectangleAddAction(annotation as Rectangle));
                    break;
                case 'TextBox': {
                    if (selectedAnnotation === copiedAnnotation) {
                        dispatch(setTextBoxAddAction(annotation as TextBox));
                    }

                    // If the textbox is a copy we us ethe current text otherwise we paste the clipboard text
                    const textBoxValue =
                        selectedAnnotation === copiedAnnotation
                            ? (annotation as TextBox).text || ''
                            : await navigator.clipboard.readText();

                    // If the user has text in the clipboard it will paste into the textbox when copied...
                    // Once the textbox is added clear the text, an alternative is to
                    // paste the previous textbox text into it by using annotation.text
                    requestAnimationFrame(() => {
                        const id = `text-annotation-${annotation.id}`;
                        const textBox = document.getElementById(id) as HTMLTextAreaElement;
                        if (textBox) {
                            textBox.value = textBoxValue;

                            // TODO - With a bit of time the copy and repeatable paste should work with textboxes
                            // but for now it will paste once then require copying again to put another textbox
                            setCopiedAnnotation(null);
                        }
                    });
                    break;
                }
                case 'Image':
                    dispatch(setImageAddAction(annotation as Image));
                    break;
                case 'MilitaryMarker':
                    dispatch(setMilitaryMarkerAddAction(annotation as MilitaryMarker));
                    break;
                case 'Marker':
                    dispatch(setMarkerAddAction(annotation as Marker));
                    break;
                default:
                    break;
            }
            dispatch(setSelectedAnnotationAction(annotation as Annotation));
        }
    }, [copiedAnnotation, dispatch, handleUpdatePosition, selectedAnnotation]);

    // Handle Ctrl+C (Copy) and Ctrl+V (Paste)
    // Could possible be extended to other keyboard shortcuts
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.ctrlKey || event.metaKey) {
                switch (event.key) {
                    case 'c':
                    case 'C':
                        handleCopy();
                        break;
                    case 'v':
                    case 'V':
                        handlePaste();
                        break;
                    default:
                        break;
                }
            }
        },
        [handleCopy, handlePaste]
    );

    // Add event listeners for keyboard events
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown, handleCopy]);

    return null;
};

export default useCopyPasteAnnotation;
