import { actionTypes as at } from './constants';
import { Action } from '../root-reducer';
import { LatLng } from 'leaflet';

interface StoreSearch {
    highlightedPosition: LatLng | undefined;
    activePosition: LatLng | undefined;
}

const initialState: StoreSearch = {
    highlightedPosition: undefined,
    activePosition: undefined,
};

export default (state: StoreSearch = initialState, action: Action): StoreSearch => {
    switch (action.type) {
        case at.UPDATE_HIGHLIGHTED_SEARCH_POSITION:
            return {
                ...state,
                highlightedPosition: action.payload,
            };

        case at.UPDATE_ACTIVE_SEARCH_POSITION:
            return {
                ...state,
                activePosition: action.payload,
            };

        case at.CLEAR_ACTIVE_SEARCH:
            return {
                ...state,
                activePosition: undefined,
            };

        case at.CLEAR_HIGHLIGHTED_SEARCH:
            return {
                ...state,
                highlightedPosition: undefined,
            };

        default:
            return state;
    }
};
