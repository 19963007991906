import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L from 'leaflet';
import { translateLatLngBounds } from '../Rectangle/rectangle-annotation-util';
import { createResizeElement } from './image-annotation-resize-element';
import LayersUtil from '../layers-util';

/**
 * The image annotation edit element is a composite object. It is a layer that comprises of:
 *   - Markers on each corner used to resize the image,
 *   - A drag rectangle that fills the middle used to translate the image an
 *   - An additional focus outline element to communicate the fact we are in this feature's edit state
 */
interface EditElementProps {
    bounds: L.LatLngBounds;
    imageOverlayElement: Readonly<{ instance: L.ImageOverlay; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const focusOptions: L.PolylineOptions = {
    color: '#eed926',
    weight: 3,
    interactive: false,
    fill: false,
};

const dragOptions: L.PolylineOptions = {
    color: 'transparent',
    interactive: true,
    fill: true,
    fillColor: 'transparent',
    fillOpacity: 0.1,
    bubblingMouseEvents: false,
};

export const createEditElement = (props: EditElementProps, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const editLayer = new L.LayerGroup([], { pane: controlPaneId });
    const editLayerElement = createElementObject<L.LayerGroup>(editLayer, context);

    const focusOutline = new L.Rectangle(props.imageOverlayElement.instance.getBounds(), {
        ...focusOptions,
        pane: controlPaneId,
    });
    const focusOutlineElement = createElementObject<L.Rectangle>(focusOutline, context);

    const dragRectangle = new L.Rectangle(props.imageOverlayElement.instance.getBounds(), {
        ...dragOptions,
        pane: controlPaneId,
    });
    const dragRectangleElement = createElementObject<L.Rectangle>(dragRectangle, context);

    const resizeElement = createResizeElement({ imageOverlayElement: props.imageOverlayElement, context }, context);

    props.imageOverlayElement.instance.on('update', () => {
        dragRectangleElement.instance.setBounds(props.imageOverlayElement.instance.getBounds());
        focusOutlineElement.instance.setBounds(props.imageOverlayElement.instance.getBounds());
        dragRectangleElement.instance.setBounds(props.imageOverlayElement.instance.getBounds());
    });

    props.imageOverlayElement.instance.on('remove', () => {
        editLayerElement.instance.fireEvent('remove');
    });

    editLayerElement.instance.on('remove', () => {
        dragRectangleElement.instance.off('mousedown');
        context.map.removeLayer(editLayerElement.instance);
        context.map.removeLayer(resizeElement.instance);
        context.map.removeLayer(focusOutlineElement.instance);
        context.map.removeLayer(dragRectangleElement.instance);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
    });

    editLayerElement.instance.on('add', () => {
        editLayerElement.instance.addLayer(focusOutlineElement.instance);
        editLayerElement.instance.addLayer(dragRectangleElement.instance);
        editLayerElement.instance.addLayer(resizeElement.instance);

        dragRectangleElement.instance.on('mouseover', () => {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
        });

        dragRectangleElement.instance.on('mouseout', () => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
        });

        let lastLatLng: L.LatLng | undefined;
        const onDragStart = (e: L.LeafletMouseEvent) => {
            props.imageOverlayElement.instance.fire('peekOn');
            lastLatLng = e.latlng;
            context.map.dragging.disable();
            context.map.on('mousemove', onDrag);
            context.map.on('mouseup', onDragEnd);
        };

        const onDrag = (e: L.LeafletMouseEvent) => {
            const imageOverlayBounds = props.imageOverlayElement.instance.getBounds();
            if (lastLatLng) {
                const newBounds = translateLatLngBounds(imageOverlayBounds, lastLatLng, e.latlng);
                lastLatLng = e.latlng;
                props.imageOverlayElement.instance.setBounds(newBounds);
                dragRectangleElement.instance.setBounds(newBounds);
                focusOutlineElement.instance.setBounds(newBounds);
                props.imageOverlayElement.instance.fire('update', e);
            }
        };

        const onDragEnd = () => {
            lastLatLng = undefined;
            context.map.off('mousemove', onDrag);
            context.map.off('mouseup', onDragEnd);
            context.map.dragging.enable();
            props.imageOverlayElement.instance.fire('peekOff');
        };

        dragRectangleElement.instance.on('mousedown', onDragStart);
    });

    return editLayerElement;
};
