import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMyProfile } from '../../../store/Account/selectors';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import { PulseLoader } from '../pulse-loader';
import { StyledButton } from '../styled-button';
import { isMobileVersion } from '../../../lib/soar-helper';
import ApiListings from '../../../api/api-listings';

interface ReportMapContentProps {
    handleReportIsOpen: () => void;
}

const ReportMapContent = (props: ReportMapContentProps) => {
    const [reportReason, setReportReason] = useState<string[]>([]);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [isSubmittingReport, setIsSubmittingReport] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const myProfile = useSelector(selectMyProfile);
    const selectedMap = useSelector(selectActiveAllMap);

    const reportOptions: string[] = [
        'Map violates copyright',
        'Map violates community guidelines',
        'Map is not displaying correctly',
        'Map is in the wrong location',
    ];

    const handleSelectedReason = (reason: string) => {
        const exists = reportReason.find((report) => report === reason);
        if (exists) {
            const updatedReasons = reportReason.filter((report) => report !== exists);
            setReportReason(updatedReasons);
            return;
        }
        setReportReason([...reportReason, reason]);
        setNoReportError('');
    };

    const [noReportError, setNoReportError] = useState<string | undefined>(undefined);

    const handleSubmitReport = () => {
        if (!myProfile) {
            setShowLogin(true);
            return;
        }

        if (!reportReason.length) {
            setNoReportError('Please select the reasons for this report');
            return;
        }

        if (!selectedMap || !myProfile) {
            setNoReportError('Something had gone wrong with reporting this map, Please try again');
            return;
        }

        if (myProfile.email) {
            setIsSubmittingReport(true);
            ApiListings.reportMap(selectedMap.id, reportReason.toString())
                .then(() => {
                    handleSubmittedSuccess();
                })
                .catch(() => {
                    setNoReportError('Something had gone wrong with reporting this map, Please try again');
                })
                .finally(() => {
                    setIsSubmittingReport(false);
                    setReportReason([]);
                });
        }
    };

    const handleSubmittedSuccess = () => {
        setIsSubmittingReport(false);
        setShowSuccess(true);

        // Timeout for visual success notification
        setTimeout(() => {
            setReportReason([]);
            setShowSuccess(false);
            props.handleReportIsOpen();
        }, 3000);
    };

    return (
        <ReportContainer>
            {isSubmittingReport && !showSuccess ? (
                <ReportSubmitting>
                    <PulseLoader iconSize="20px" />
                </ReportSubmitting>
            ) : (
                <React.Fragment>
                    {!isMobileVersion ? <ReportTitle>Report this map</ReportTitle> : null}
                    {showSuccess && !isSubmittingReport ? (
                        <ReportSubmitted>
                            <ReportIcon className="fa fa-exclamation-triangle" aria-hidden="true" />
                            <ReportSubmittedText>
                                Your report has been <br /> submitted!
                            </ReportSubmittedText>
                            <ReportSubmittedText>Our team will look into it as soon as possible</ReportSubmittedText>
                        </ReportSubmitted>
                    ) : (
                        <React.Fragment>
                            <ReportMenuItem>
                                {reportOptions.map((option, index) => {
                                    const checkboxId = `checkbox-${index}`;
                                    return (
                                        <ReportOption key={index}>
                                            <label htmlFor={checkboxId}>
                                                <ReportCheckbox
                                                    id={checkboxId}
                                                    type="checkbox"
                                                    onChange={() => handleSelectedReason(option)}
                                                />
                                                {option}
                                            </label>
                                        </ReportOption>
                                    );
                                })}
                                {noReportError && (
                                    <NoReportError>
                                        <NoReportErrorIcon />
                                        {noReportError}
                                    </NoReportError>
                                )}
                            </ReportMenuItem>
                            <ReportSubmitButton onClick={handleSubmitReport}>SUBMIT</ReportSubmitButton>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(false)}
                />
            )}
        </ReportContainer>
    );
};

export default ReportMapContent;

const ReportContainer = styled.div`
    min-height: 190px;
    cursor: default;
    text-align: left;
`;

const ReportMenuItem = styled.div`
    margin: 10px;
`;

const ReportOption = styled.div`
    font-size: 16px;
    padding: 2px 0px 2px 0px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 8px;
    user-select: none;

    label {
        cursor: pointer;
    }
`;

const ReportCheckbox = styled.input`
    margin-right: 8px;
    cursor: pointer;
`;

const NoReportError = styled.div`
    display: flex;
    flex-direction: row;
    color: red;
    font-weight: 400;
    font-size: 14px;
    @media only screen and (max-width: 600px) {
        margin: -5px 0 -3px 0;
    }
`;

const NoReportErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin: -3px 5px 0 -6px;

    @media only screen and (max-width: 600px) {
        margin-top: 2px;
    }
`;
const ReportSubmitButton = styled(StyledButton)`
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
`;

const ReportSubmitting = styled.div`
    padding-top: 20%;
`;

const ReportSubmitted = styled.div`
    text-align: center;
`;

const ReportSubmittedText = styled.div`
    font-size: 16px;
    padding: 5px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.87);
`;

const ReportIcon = styled.i`
    font-size: 50px;
    color: #eed926;
    margin: 15px;
`;

const ReportTitle = styled.h4`
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.87);
    user-select: none;
`;
