import { ListingDTO, UserDTO } from '../../../api/model';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface StoreUserProfile {
    userProfile: UserDTO | undefined;
    userListings: ListingDTO[];
}

const initialState: StoreUserProfile = {
    userProfile: undefined,
    userListings: [],
};

export default (state: StoreUserProfile = initialState, action: Action): StoreUserProfile => {
    switch (action.type) {
        case at.FETCH_USER_PROFILE_WITH_LISTINGS_BEGIN:
            return {
                ...state,
                userProfile: undefined,
            };

        case at.FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload,
            };
        case at.FETCH_USER_LISTINGS_SUCCESS:
            return {
                ...state,
                userListings: action.payload,
            };

        case at.FETCH_USER_PROFILE_WITH_LISTINGS_ERROR:
            return {
                ...state,
                userListings: [],
                userProfile: undefined,
            };
        default:
            return state;
    }
};
