import React, { useRef, ReactNode, useState, useEffect } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '../../../../lib/use-on-click-outside';

interface ToolbarModalProps {
    title: string;
    onClose: () => void;
    width?: number;
    marginEnd?: number;
    children: ReactNode;
    toolbarButtonRef: React.RefObject<HTMLButtonElement>;
}

const MODAL_PADDING_TOP = 10;
const MODAL_WIDTH = 300;

/**
 * A modal that is displayed and positioned automatically when a toolbar button is clicked.
 * @param title The title of the modal
 * @param onClose The function to call when the modal is closed
 * @param children The content of the modal
 * @param toolbarButtonRef The ref of the toolbar button that opened the modal
 */

const ToolbarModal = (props: ToolbarModalProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [position, setPosition] = useState<{ left: number; top: number }>();

    useOnClickOutside(ref, () => {
        props.onClose();
    });

    useEffect(() => {
        const calculateModalPosition = () => {
            if (props.toolbarButtonRef?.current) {
                const buttonRect = props.toolbarButtonRef.current.getBoundingClientRect();

                let left = buttonRect.left;
                const top = buttonRect.bottom + MODAL_PADDING_TOP;

                const rightEdge = left + MODAL_WIDTH + (props.marginEnd ?? 0);
                const viewportWidth = window.innerWidth;

                if (rightEdge > viewportWidth) {
                    left = left - (rightEdge - viewportWidth);
                }

                return { left, top };
            }
            return { left: 0, top: 0 };
        };

        const handleResize = () => {
            setPosition(calculateModalPosition());
        };

        // Calculate the initial position
        setPosition(calculateModalPosition());

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.toolbarButtonRef]);

    if (!position) return null;

    return (
        <Container ref={ref} position={position} width={props.width ?? MODAL_WIDTH} marginEnd={props.marginEnd || 0}>
            <TitleContainer>
                <Title>{props.title}</Title>
                <CloseIcon className="fa fa-close" onClick={() => props.onClose()} />
            </TitleContainer>
            <Body>{props.children}</Body>
        </Container>
    );
};

export default ToolbarModal;

const Container = styled.div<{ position: React.CSSProperties; width: number; marginEnd: number }>`
    position: fixed;
    width: ${(props) => (props.width ? props.width : 300)}px;
    background: rgba(0, 0, 0, 0.8);
    left: ${(props) => props.position.left}px;
    top: ${(props) => props.position.top}px;
    z-index: 99999;
    animation-name: fadeIn;
    animation-duration: 200ms;
    animation-fill-mode: both;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const Body = styled.div`
    padding: 8px 12px 0 12px;
    margin-bottom: 12px;
`;

const TitleContainer = styled.div`
    padding: 7px 0 7px 0;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: row;
`;

const Title = styled.h4`
    color: white;
    display: block;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 0 5px;
    margin: 0;
`;

const CloseIcon = styled.i`
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.1rem;
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 6px;
`;

export const Text = styled.span`
    color: white;
    font-size: 1rem;
`;

export const Divider = styled.div`
    margin: 6px 0 8px 0;
    height: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const ToolbarButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;

    background: transparent;
    border: none;

    padding-top: 5px;
    padding-bottom: 5px;
    margin: 2px 0;

    color: white;
    font-size: 1rem;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }

    &:focus,
    &:active {
        border: none;
        outline: none;
    }

    &:only-child {
        margin: 0 auto;
    }
`;
