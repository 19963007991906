import { handleUpdatePattern } from './pattern-util';

export type Pattern = {
    id: string;
    pattern: string;
    patternId?: string;
    shapeId?: string;
};

// TODO: What pattern do we need, no doubt someone will provide and give the ordering for them so all g for now
// Patterns could be made so the user could have control over them if we wanted, keep it simple for now
// Can literally grab the patterns from these sites or make them...
// https://10015.io/tools/svg-pattern-generator
// https://www.heropatterns.com/
// https://pattern.monster/

// Note: If you add a new pattern but the <pattern id="none-..." ..... /> it will be the patternId been incorrectly created and matching the id

const none = (id: string, color: string) => {
    return `
        <pattern id="none-${id}" width="1" height="1" patternUnits="userSpaceOnUse">
            <rect width="1" height="1" fill=${color}></rect>
        </pattern>
    `;
};

const lines0 = (id: string, color: string) => {
    return `
        <pattern id="lines0-${id}" width="8" height="8" patternUnits="userSpaceOnUse">
            <rect width="1" height="8" fill="${color}"></rect>
            <rect width="1" height="8" fill="transparent" transform="translate(1)"></rect>
        </pattern>
    `;
};

const lines45 = (id: string, color: string) => {
    return `
        <pattern id="lines45-${id}" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect width="1" height="8" fill="${color}"></rect>
            <rect width="1" height="8" fill="transparent" transform="translate(1)"></rect>
        </pattern>
    `;
};

const lines135 = (id: string, color: string) => {
    return `
        <pattern id="lines135-${id}" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(135)">
            <rect width="1" height="8" fill="${color}"></rect>
            <rect width="1" height="8" fill="transparent" transform="translate(1)"></rect>
        </pattern>
    `;
};

const lines90 = (id: string, color: string) => {
    return `
        <pattern id="lines90-${id}" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(90)">
            <rect width="1" height="8" fill="${color}"></rect>
            <rect width="1" height="8" fill="transparent" transform="translate(1)"></rect>
        </pattern>
    `;
};

const thickLines45 = (id: string, color: string) => {
    return `
        <pattern id="thickLines45-${id}" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect width="3" height="8" fill="${color}"></rect>
            <rect width="3" height="8" fill="transparent" transform="translate(3)"></rect>
        </pattern>
    `;
};

const thickLines135 = (id: string, color: string) => {
    return `
        <pattern id="thickLines135-${id}" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(135)">
            <rect width="3" height="8" fill="${color}"></rect>
            <rect width="3" height="8" fill="transparent" transform="translate(3)"></rect>
        </pattern>
    `;
};

const thickLines90 = (id: string, color: string) => {
    return `
        <pattern id="thickLines90-${id}" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(90)">
            <rect width="3" height="8" fill="${color}"></rect>
            <rect width="3" height="8" fill="transparent" transform="translate(3)"></rect>
        </pattern>
    `;
};

const wideLines = (id: string, color: string) => {
    return `
        <pattern id="wideLines-${id}" width="16" height="16" patternUnits="userSpaceOnUse">
            <rect width="1" height="16" fill="${color}"></rect>
            <rect width="1" height="16" fill="transparent" transform="translate(1)"></rect>
        </pattern>
    `;
};

const wideLines45 = (id: string, color: string) => {
    return `
        <pattern id="wideLines45-${id}" width="16" height="16" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect width="1" height="16" fill="${color}"></rect>
            <rect width="1" height="16" fill="transparent" transform="translate(1)"></rect>
        </pattern>
    `;
};

const crosshatch = (id: string, color: string) => {
    return `
        <pattern id="crosshatch-${id}" width="8" height="8" patternUnits="userSpaceOnUse">
            <rect width="1" height="8" fill="${color}"></rect>
            <rect width="8" height="1" fill="${color}"></rect>
        </pattern>
    `;
};

const crosshatch45 = (id: string, color: string) => {
    return `
        <pattern id="crosshatch45-${id}" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect width="1" height="8" fill="${color}"></rect>
            <rect width="8" height="1" fill="${color}"></rect>
        </pattern>
    `;
};

const dots = (id: string, color: string) => {
    return `
        <pattern id="dots-${id}" width="16" height="16" patternUnits="userSpaceOnUse">
            <circle cx="4" cy="4" r="2" fill="${color}"></circle>
        </pattern>
    `;
};

const dotsOffset = (id: string, color: string) => {
    return `
        <pattern id="dotsOffset-${id}" width="16" height="16" patternUnits="userSpaceOnUse">
            <circle cx="4" cy="4" r="2" fill="${color}"></circle>
            <circle cx="12" cy="12" r="2" fill="${color}"></circle>
        </pattern>
    `;
};

const triangles = (id: string, color: string) => {
    return `
        <pattern id="triangles-${id}" width="16" height="16" patternUnits="userSpaceOnUse">
            <path d="M 16 16 L 8 16 L 16 8 Z" fill="${color}"></path>
        </pattern>
    `;
};

// This is a more advanced implementation where an svg is used to create the pattern
const hexagons = (id: string, color: string) => {
    return `
        <pattern id="hexagons-${id}" width="28" height="49" patternUnits="userSpaceOnUse">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="49" viewBox="0 0 28 49">
                <g fill-rule="evenodd">
                    <g id="hexagons" fill="${color}" fill-rule="nonzero">
                        <path d="M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z"/>
                    </g>
                </g>
            </svg>
    `;
};

export const availablePatterns = (id: string, color: string): Pattern[] => [
    {
        id: 'none',
        pattern: none(id, color),
    },
    {
        id: 'lines45',
        pattern: lines45(id, color),
    },
    {
        id: 'lines135',
        pattern: lines135(id, color),
    },
    {
        id: 'lines90',
        pattern: lines90(id, color),
    },
    {
        id: 'lines0',
        pattern: lines0(id, color),
    },
    {
        id: 'thickLines45',
        pattern: thickLines45(id, color),
    },
    {
        id: 'thickLines135',
        pattern: thickLines135(id, color),
    },
    {
        id: 'thickLines90',
        pattern: thickLines90(id, color),
    },
    {
        id: 'wideLines',
        pattern: wideLines(id, color),
    },
    {
        id: 'wideLines45',
        pattern: wideLines45(id, color),
    },
    {
        id: 'crosshatch',
        pattern: crosshatch(id, color),
    },
    {
        id: 'crosshatch45',
        pattern: crosshatch45(id, color),
    },
    {
        id: 'dots',
        pattern: dots(id, color),
    },
    {
        id: 'dotsOffset',
        pattern: dotsOffset(id, color),
    },
    {
        id: 'triangles',
        pattern: triangles(id, color),
    },
    {
        id: 'hexagons',
        pattern: hexagons(id, color),
    },
];

export const findAndUpdateAvailablePattern = (id: string, patternId: string, color: string) => {
    if (!id || !patternId || !color) {
        return null;
    }
    const pattern = availablePatterns(id, color).find((pattern) => {
        return pattern.id === patternId;
    });
    if (pattern) {
        handleUpdatePattern(id, pattern);
        return pattern;
    }
    return availablePatterns(id, color)[0];
};
