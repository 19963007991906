import styled from 'styled-components';

interface CopyTextProps {
    text: string;
    onCopy?: () => void;
}

const CopyText = (props: CopyTextProps) => {
    const handleCopy = (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            navigator.clipboard.writeText(props.text);

            if (props.onCopy) {
                props.onCopy();
            }
        } catch (e) {
            console.error('Copy Error: ', { e });
        }
    };

    return (
        <CopyTextContainer>
            <CopyTextInput value={props.text} readOnly />
            <CopyTextButton onClick={handleCopy}>
                <CopyTextIcon src="/assets/floating-drawer-satellite-icons/share-icons/copy.png" />
            </CopyTextButton>
        </CopyTextContainer>
    );
};

export default CopyText;

const CopyTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid gray;
    border-radius: 8px;
    overflow: hidden;
`;
const CopyTextInput = styled.input`
    flex: 1;
    border: none;
    background: transparent;
    filter: invert(1);
    outline: none;
    padding: 4px 8px;
`;
const CopyTextButton = styled.button`
    border: none;
    opacity: 0.95;
    background: ${(props) => props.theme.color.yellow};
    &:focus,
    &:hover {
        outline: none;
        opacity: 1;
    }
`;
const CopyTextIcon = styled.img``;
