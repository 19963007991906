import { AppState } from '../../root-reducer';

export const selectListingDictionary = (state: AppState) => state.mapSelectionDomain.listingDictionary;
export const selectContinentListings = (state: AppState) => state.mapSelectionDomain.continentListings;
export const selectSelectedContinent = (state: AppState) => state.mapSelectionDomain.selectedContinent;
export const selectSelectedCategory = (state: AppState) => state.mapSelectionDomain.selectedCategory;
export const selectSearchFocused = (state: AppState) => state.mapSelectionDomain.searchFocused;
export const selectClearListing = (state: AppState) => state.mapSelectionDomain.clearListing;

export const selectSearchDrawListings = (state: AppState) => state.mapSelectionDomain.searchDrawListings;
export const selectSelectedSearchTerm = (state: AppState) => state.mapSelectionDomain.selectedSearchTerm;
