import React from 'react';
import styled from 'styled-components';

interface CharacterCountProps {
    text?: string;
    limit: number;
}

export const CharacterCount = ({ text, limit }: CharacterCountProps): JSX.Element => {
    if (!text) return <React.Fragment />;

    return (
        <CharacterLimitContainer>
            <div>Characters &nbsp; </div>
            <div>
                {text?.length}/{limit}
            </div>
        </CharacterLimitContainer>
    );
};

const CharacterLimitContainer = styled.div`
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.8);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.8);

    display: flex;
    justify-content: space-between;
`;
