import { useEffect } from 'react';
import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import { CustomCookieConsent } from '../Shared/custom-cookie-consent';
import BackgroundGradientAnimation from './Animations/background-gradient-animation';
import Footer from './Footer/footer';
import LandingPageLeftContent from './landing-page-left-content';
import LandingPageCarousel from './landing-page-carousel';
import { useSelector } from 'react-redux';
import { selectShowNavbar } from '../../store/App/selectors';

/**
 * Note - The landing page has been set up to cover tablet but would not be visible due to how we are handling the isMobileView
 * Mobile devices need a few more minor adjustments to the footer and possibly a navbar?
 * not implemented due to the current route setup and out of scope for now.
 */
const LandingPage = () => {
    // This is just for an edge case caused by the home.tsx page where the navbar is added and removed by redux
    // Pressing the back arrow on the browser quickly can cause a crash due to the navbar not being present
    const showNavbar = useSelector(selectShowNavbar);

    const handleScroll = () => {
        const position = window.pageYOffset;
        const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
        if (position <= 50) {
            navbar.style.backgroundColor = 'transparent';
        } else {
            navbar.style.backgroundColor = 'rgba(0,0,0,0.8)';
        }
    };

    useEffect(() => {
        const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
        if (showNavbar && navbar?.style) {
            navbar.style.backgroundColor = 'transparent';
        }
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
            if (showNavbar && navbar?.style) {
                navbar.style.backgroundColor = 'rgba(0,0,0,0.8)';
            }
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showNavbar]);

    // Note this is a hack...
    // The landing page needs the overflow to work regardless of screen size, in the index.css line 27ish
    // we disable the overflow and touch events to prevent page reloads on mobile/tablet devices
    useEffect(() => {
        document.body.style.overflow = 'auto';
        document.body.style.touchAction = 'auto';
        document.body.style.overscrollBehaviorY = 'auto';
    }, []);

    useEffect(() => {
        Analytics.Event('LandingPage', 'Visited the landing page');
    }, []);

    return (
        <LandingPageContainer>
            <BackgroundGradientAnimation />
            <LandingPageMainContent>
                <LandingPageLeftContent />
                <LandingPageRightHeroImage src="/assets/landing-page/landing-page-image.png" />
            </LandingPageMainContent>
            <LandingPageCarousel />
            <Footer />
            <CustomCookieConsent />
        </LandingPageContainer>
    );
};

export default LandingPage;

const LandingPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 200px auto 0 auto;
    max-width: 1400px;
    width: 90%;

    @media only screen and (max-width: 1280px) {
        margin: 100px auto 0 auto;
    }

    @media only screen and (max-width: 600px) {
        margin: 20px auto 0 auto;
        width: 100%;
    }
`;

const LandingPageMainContent = styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 1280px) {
        flex-direction: column;
        margin: 0 auto;
    }
`;

const LandingPageRightHeroImage = styled.img`
    max-width: 955px;
    width: 60%;
    margin-left: -10%;

    @media only screen and (max-width: 1280px) {
        width: 100%;
        margin: 30px auto;
    }

    @media only screen and (max-width: 600px) {
        margin: 20px auto 15px 0px;
        width: 95%;
    }
`;
