import React, { useEffect, useState } from 'react';
import { MapContainer as LeafletMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectShowMobileLoadingSpinner } from '../../store/Map/Mobile/selectors';
import SatelliteProviders from '../MapView/SatelliteProviders/satellite-providers';
import SatelliteLayers from '../MapView/SatelliteTracker/satellite-layers';
import SentinelLayers from '../MapView/Sentinel/sentinel-layers';
import TileLayers from '../MapView/TileLayers/tilelayers';
import PinnedTileLayers from '../MapView/pinned-tilelayers';
import { SubmittingSpinner } from '../Shared/submitting-spinner';
import MobileBasemap from './BaseMap/mobile-basemap';
import MobileMapDispatcher from './mobile-map-dispatcher';
import UriHelper from '../../lib/uri-helper';
import AnnotationPatterns from '../MapView/annotation-patterns';

// Values that give the map an aesthetically pleasing start position
const WORLD_ZOOM = window.innerHeight > 1100 ? 2.5 : 1.5;
const MAX_ZOOM_LEVEL = 28;
const WORLD_CENTER: [number, number] = [34.5, 0];

const MobileMap = () => {
    const showMobileLoadingSpinner = useSelector(selectShowMobileLoadingSpinner);
    const [isEditLink, setIsEditLink] = useState(false);

    useEffect(() => {
        const editMode = UriHelper.tryGetParam('edit');
        if (editMode) {
            setIsEditLink(true);
        }
    }, []);

    return (
        <MapContainer>
            <LeafletMap
                tap={false}
                zoom={WORLD_ZOOM}
                center={WORLD_CENTER}
                zoomControl={false}
                minZoom={WORLD_ZOOM}
                maxZoom={MAX_ZOOM_LEVEL}
                maxBounds={[
                    [-90, -180],
                    [90, 180],
                ]}
                maxBoundsViscosity={1.0}
            >
                <React.Fragment>
                    <AnnotationPatterns />

                    <MobileMapDispatcher />
                    <MobileBasemap />
                    <PinnedTileLayers editMode={isEditLink} />
                    <SatelliteLayers />
                    <TileLayers editMode={isEditLink} />
                    <SentinelLayers />
                    <SatelliteProviders />
                </React.Fragment>
            </LeafletMap>

            {showMobileLoadingSpinner ? (
                <MobileSpinnerContainer>
                    <SubmittingSpinner />
                </MobileSpinnerContainer>
            ) : null}
        </MapContainer>
    );
};

export default MobileMap;

const MobileSpinnerContainer = styled.div`
    position: absolute;
    left: 50vw;
    top: 38vh;
    width: 35px;
    height: 35px;
    z-index: 3000;
`;

const MapContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: black;
    /* overflow-y: hidden; */
    /* overscroll-behavior-y: none !important; */

    #kml-button,
    #box-select-button,
    #ruler-button,
    #text-field-button,
    #arrow-button,
    #circle-button,
    #rectangle-button,
    #latLngTool-button,
    #box-zoom-button,
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    #export-button,
    #remove-all-button {
        visibility: hidden !important;
        width: 0 !important;
        margin: 0 !important;
    }
`;
