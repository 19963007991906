const SELECTED_COLORS = 'soar-annotation-user-colors';

export const PRESET_COLORS = ['#eed926', '#3388ff'];

export enum COLOR_PICKER_TYPE {
    LINE = 'LINE',
    FILL = 'FILL',
}
export default class ColorHelper {
    // Get user colors from local storage
    // If not found, return predefined colors
    public static getUserColors = (): string[] => {
        const storage = localStorage.getItem(SELECTED_COLORS);
        if (!storage) {
            // Return predefined colors
            return [];
        }

        try {
            const colors: string[] = JSON.parse(storage);
            return colors;
        } catch (err) {
            // If it fails give them the default colors
            return [];
        }
    };

    // Get last preset color
    public static getLastUsedColor = (): string => {
        const colors = ColorHelper.getUserColors();
        return colors[0];
    };

    // Save user colors to local storage
    public static setUserColors = (colors: string[]) => {
        localStorage.setItem(SELECTED_COLORS, JSON.stringify(colors));
    };

    // Prepend a color to the user colors
    public static addUserColor = (color: string) => {
        const storage = localStorage.getItem(SELECTED_COLORS);

        let userColors = [];
        if (storage) {
            userColors = JSON.parse(storage);
            //userColors.splice(userColors.indexOf(color), 1); // prevent multiple instances of same color
        }

        this.setUserColors([color, ...userColors]);
    };

    public static doesColorExist = (color: string): boolean => {
        const storage = localStorage.getItem(SELECTED_COLORS);
        if (!storage) {
            return false;
        }

        try {
            const colors: string[] = JSON.parse(storage);
            return colors.includes(color);
        } catch (err) {
            return false;
        }
    };
}
