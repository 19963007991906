import { LatLngBounds } from 'leaflet';
import ApiSupplier from '../../../../api/api-supplier';
import { CountryInfo } from '../../../../api/model';
import Constants from '../../../../constants';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { CGSTL_ARCHIVE_COLLECTION_TERMS } from '../OrderWorkflow/satellite-order-conditions';
import { Industry } from '../OrderWorkflow/satellite-order-user-details';
import SatelliteArchive from '../OrderWorkflow/satellite-archive';
import { useCallback } from 'react';

const CGSTLArchive75cm = () => {
    return (
        <SatelliteArchive
            title="75cm ARCHIVAL"
            analyticsCategory="Satellite - CGSTL - Archival 75cm"
            logoUrl="/assets/floating-drawer-satellite-icons/satellite-cgstl-logo.png"
            validAOIParameters={Constants.CGSTL_PARAMETERS.ARCHIVAL}
            rootSideDrawerMode={SideDrawerMode.SATELLITE_CGSTL}
            termsAndConditionsChecklist={CGSTL_ARCHIVE_COLLECTION_TERMS}
            termsAndConditionsHref="/cgstl-archive-terms"
            isVoucherVisible={true}
            fetchSearchResults={useCallback((aoi: LatLngBounds) => {
                return ApiSupplier.searchArchive(aoi, 'CGSTL', 'A75');
            }, [])}
            fetchPrice={(aoi: LatLngBounds, sceneIds: string[], voucher?: string, countryCode?: string) => {
                return ApiSupplier.calculateArchivePrice(aoi, sceneIds, 'CGSTL', 'A75', voucher, countryCode);
            }}
            createOrder={(
                selectedAOI: LatLngBounds,
                sceneIds: string[],
                stripeToken: any, // eslint-disable-line @typescript-eslint/no-explicit-any
                userCompanyName: string,
                userIndustry: Industry,
                userCountryInfo: CountryInfo,
                voucher: string | undefined
            ) => {
                return ApiSupplier.createOrder(
                    selectedAOI,
                    sceneIds,
                    'CGSTL',
                    'A75',
                    stripeToken,
                    userCompanyName,
                    userIndustry,
                    userCountryInfo,
                    voucher
                );
            }}
        />
    );
};

export default CGSTLArchive75cm;
