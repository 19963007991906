import styled from 'styled-components';

export enum CostType {
    Free,
    Paid,
    Soon,
}

interface SatelliteItemProps {
    disabled: boolean;
    imageUrl: string;
    title: string;
    hoverText: string;
    cost: CostType;
    onClick: () => void;
    isOpen: boolean;
}

const SatelliteItem = (props: SatelliteItemProps) => {
    return (
        <Container disabled={props.cost === CostType.Soon} hover={props.isOpen} onClick={props.onClick}>
            <HoverContainer>
                <HoverTitle>{props.title}</HoverTitle>
                <HoverDescription>{props.hoverText}</HoverDescription>
            </HoverContainer>
            <Logo src={props.imageUrl} />

            <CostIcon disabled={props.cost === CostType.Soon} hover={true}>
                {props.cost === CostType.Free && 'Free '}
                {props.cost === CostType.Paid && 'Buy'}
                {props.cost === CostType.Soon && 'Soon'}
            </CostIcon>
        </Container>
    );
};

export default SatelliteItem;

const Logo = styled.img`
    margin: 0 0 0 12px;
    height: auto;
`;

const HoverContainer = styled.div`
    width: 317px;
    height: 62px;
    border-radius: 6px;
    position: absolute;
    border: 1px solid rgba(204, 119, 34, 0.3);
    display: none;
`;

const HoverTitle = styled.h5`
    color: white;
    text-align: left;
    margin: 8px 0 1px 9px;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
`;

const HoverDescription = styled.p`
    color: white;
    margin: 0;
    padding: 0;
    margin: 0 0 0 9px;
    font-size: 12px;
    line-height: 13px;
    max-width: 240px;
`;

interface DisableProps {
    disabled: boolean;
    hover: boolean;
}

const CostIcon = styled.p<DisableProps>`
    text-transform: uppercase;
    background: ${(props) => (props.disabled ? '#343332' : '#eed926')};
    color: ${(props) => (props.disabled ? 'white' : 'black')};
    border-radius: 6px;
    font-size: 1rem;
    font-weight: bold;
    width: 63px;
    padding: 6px 12px 6px 12px;
    margin: 0 12px 0 auto;
    float: right;
    text-align: center;
`;

const Container = styled.div<DisableProps>`
    user-select: none;
    height: 64px;
    margin: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    :hover {
        ${(props) => props.hover && `border: 1px solid #eed926;`}
    }

    :hover ${HoverContainer} {
        ${(props) => (props.hover ? `display: block;` : `display: none;`)}
    }

    :hover ${Logo} {
        filter: blur(16px);
        opacity: 0.5;
    }

    :hover ${CostIcon} {
        filter: blur(16px);
        opacity: 0.5;
    }
`;
