import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { actionFetchUserProfileWithListings } from '../../../../store/Map/UserProfile/actions';
import { selectUserProfile, selectUserListings } from '../../../../store/Map/UserProfile/selectors';
import ProfileFeaturedMaps from './Profile/profile-featured-maps';
import ProfileHeader from './Profile/profile-header';
import ProfileStats from './Profile/profile-stats';
import SideDrawerLoader from '../Shared/side-drawer-loader';

type RouteParams = {
    wallet?: string;
};

const SideDrawerProfile = () => {
    const userProfile = useSelector(selectUserProfile);
    const userListings = useSelector(selectUserListings);

    const dispatch = useDispatch();

    const [userProfileError, setUserProfileError] = useState<string | undefined>(undefined);

    const { wallet } = useParams<RouteParams>();

    useEffect(() => {
        if (!wallet) {
            setUserProfileError('Wrong pathname');
        } else if (!userProfile || (userProfile.userId && userProfile.userId !== wallet)) {
            dispatch(actionFetchUserProfileWithListings(wallet));
        }
    }, [wallet, dispatch, userProfile]);

    useEffect(() => {
        if (userProfile) {
            Analytics.Event('Profile', `VP: ${userProfile.userId}`);
            Analytics.Event('Profile', `Viewing user`, userProfile.name);
        }
        return () => {
            if (userProfile) {
                Analytics.Event('Profile', 'Leaving viewing User', userProfile.name);
            }
        };
    }, [userProfile]);

    if (!wallet) {
        UriHelper.navigateToPath('/maps');
    }

    if (!userProfile || userProfileError) {
        return <SideDrawerLoader />;
    }

    return (
        <React.Fragment>
            <ProfileHeader userProfile={userProfile} shareURL={null} />
            <ProfileStats userProfile={userProfile} listings={userListings.filter((l) => l.listingType)} />
            {userListings.length > 0 ? <ProfileFeaturedMaps listings={userListings} /> : null}
        </React.Fragment>
    );
};

export default SideDrawerProfile;
