import { useEffect, useState } from 'react';
import { useMap, Marker } from 'react-leaflet';
import L from 'leaflet';

interface UseZoomInNotificationProps {
    minZoom?: number;
    position?: L.LatLngExpression;
}

// Currently only implemented for COG/STACC
// Adds a buffer to the minZoom to reduce overlap of the notification and tiles loading in as it seems to be rounded and off by a bit
// User can force close it if they want to and it will not display again for the session
const ZOOM_BUFFER = 1;

const useZoomInNotification = ({ minZoom, position }: UseZoomInNotificationProps) => {
    const map = useMap();
    const [showNotification, setShowNotification] = useState(false);
    const [userHasClosedNotification, setUserHasClosedNotification] = useState(false);

    useEffect(() => {
        const handleZoomEnd = () => {
            if (userHasClosedNotification) {
                setShowNotification(false);
                map.off('zoomend', handleZoomEnd);
                return;
            }

            if (minZoom && map.getZoom() <= minZoom - ZOOM_BUFFER) {
                setShowNotification(true);
            } else {
                setShowNotification(false);
            }
        };

        map.on('zoomend', handleZoomEnd);
        return () => {
            map.off('zoomend', handleZoomEnd);
        };
    }, [map, minZoom, userHasClosedNotification]);

    useEffect(() => {
        const handleCloseClick = (event: MouseEvent) => {
            if ((event.target as HTMLElement).classList.contains('close-map-notification-button')) {
                setUserHasClosedNotification(true);
                setShowNotification(false);
                document.removeEventListener('click', handleCloseClick);
            }
        };

        document.addEventListener('click', handleCloseClick);

        return () => {
            document.removeEventListener('click', handleCloseClick);
        };
    }, []);

    const NotificationMarker = () => {
        if (!showNotification || !position) return null;

        const mapNotificationPopupContainer = `
            display: flex;
            flex-direction: column;
            background: rgba(0, 0, 0, 0.8);
            color: white;
            padding: 10px;
            border-radius: 6px;
            text-align: center;
            width: 200px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            cursor: default;
        `;

        const mapNotificationPopupText = `
            color: white;
        `;

        const mapNotificationPopupButton = `
            background-color: #eed926;
            color: black;
            border: none;
            padding: 5px 10px;
            border-radius: 6px;
            cursor: pointer;
            margin: 8px 8px 0px 8px;
            cursor: pointer;
        `;

        const icon = L.divIcon({
            className: 'map-notification-custom-div-icon',
            html: `<div style="${mapNotificationPopupContainer}">
                    <div style="${mapNotificationPopupText}">Zoom into the red boundary to view the map!</div>
                    <button class="close-map-notification-button" style="${mapNotificationPopupButton}">CLOSE</button>
                   </div>`,
            iconSize: [200, 90],
        });

        return <Marker position={position} icon={icon} />;
    };

    return { NotificationMarker };
};

export default useZoomInNotification;
