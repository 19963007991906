import { Spinner } from 'reactstrap';
import styled from 'styled-components';

interface SubmittingSpinnerProps {
    height?: string;
    width?: string;
    color?: string;
}

export const SubmittingSpinner = ({ height, width, color }: SubmittingSpinnerProps) => {
    return (
        <SubmittingSpinnerContainer>
            <SubmittingSpinnerIcon
                height={height ? height : ''}
                width={width ? width : ''}
                color={color ? color : ''}
            />
        </SubmittingSpinnerContainer>
    );
};

const SubmittingSpinnerContainer = styled.div`
    text-align: center;
`;

interface IconProps {
    height: string;
    width: string;
    color: string;
}

const SubmittingSpinnerIcon = styled(Spinner)<IconProps>`
    width: ${(props) => (props.width ? props.width : '35px')};
    height: ${(props) => (props.height ? props.height : '35px')};
    color: ${(props) => (props.color ? props.color : '#EED926')};
`;
