import React, { useState } from 'react';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import CoordinateBuilder from './coordinate-builder';
import Coordinate from './coordinate';
import CoordinateAnnotation from './coordinate-annotation';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { selectCoordinates } from '../../../../store/Annotations/Coordinate/selectors';
import { setCoordinateAddAction, setCoordinateUpdateAction } from '../../../../store/Annotations/Coordinate/actions';
import { useDrawToolUpdated } from '../use-draw-tool-updated';
import useAnnotationBuildLock from '../use-annotation-build-lock';

const CoordinateControl = (props: AnnotationControlProps) => {
    const [isCreateActive, setIsCreateActive] = useState(false);
    const dispatch = useDispatch();

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const coordinates = useSelector(selectCoordinates);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const onUpdateCoordinate = useDrawToolUpdated((coordinate: Coordinate) => {
        dispatch(setCoordinateUpdateAction(coordinate));
    });

    const isInteractive = !props.locked && props.visible;

    return (
        <React.Fragment>
            <AnnotationControl
                active={isCreateActive}
                disabled={props.disabled}
                layout="middle"
                visible={props.visible}
                iconDark="/assets/annotations/black-annotations/icon-coordinates-black.svg"
                iconLight="/assets/annotations/icon-coordinates.svg"
                name="Coordinate"
                onClick={() => {
                    lockAnnotationsEvent();
                    setIsCreateActive(true);
                }}
                dataTestId="annotation-control-coordinate"
            />

            {isCreateActive ? (
                <CoordinateBuilder
                    zIndex={currentZIndex}
                    onCreateCoordinate={(coordinate: Coordinate) => {
                        setIsCreateActive(false);
                        dispatch(setCoordinateAddAction(coordinate));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(coordinate));
                        });
                        DrawToolAnalytics.add('coordinate');
                    }}
                    onCancelBuild={() => {
                        setIsCreateActive(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {!isInteractive &&
                coordinates.map((coordinate) => {
                    return (
                        <CoordinateAnnotation
                            key={`
                                ${coordinate.id}-locked-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}
                                ${coordinate.zIndex}-
                                ${coordinate.position?.lat}-
                                ${coordinate.position?.lng}-
                                ${coordinate.coordinateSystem}
                            `}
                            coordinate={coordinate}
                            isSelected={false}
                            isDisabled={true}
                            onUpdate={() => ''}
                        />
                    );
                })}

            {isInteractive &&
                coordinates.map((coordinate) => {
                    return (
                        <CoordinateAnnotation
                            key={`
                                ${coordinate.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}
                                ${coordinate.zIndex}-
                                ${coordinate.position?.lat}-
                                ${coordinate.position?.lng}-
                                ${coordinate.coordinateSystem}
                            `}
                            coordinate={coordinate}
                            onUpdate={onUpdateCoordinate}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === coordinate.id : false}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default CoordinateControl;
