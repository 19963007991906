import { LatLng, LatLngBounds } from 'leaflet';
import { ListingDTO, ListingType } from '../api/model';
import Constants from '../constants';
import { Basemap } from '../store/Map/Basemap/model';

const GOOGLE_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.GOOGLE_MOBILE_ATTRIBUTION;
const OSM_TOPO_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_TOPO_MOBILE_ATTRIBUTION;
const OSM_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_MOBILE_ATTRIBUTION;

export default class MapHelper {
    public static filterDroneImages = (listings: ListingDTO[]): ListingDTO[] => {
        return listings.filter((l) => l.listingType === ListingType.IMAGE);
    };

    public static filterMapImages = (listings: ListingDTO[]): ListingDTO[] => {
        return listings.filter((l) => {
            return l.listingType === ListingType.TILE_LAYER || l.listingType === ListingType.NOT_SUPPORTED;
        });
    };

    // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
    public static formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    public static isLatLngBoundsValid = (bounds: LatLngBounds): boolean => {
        if (bounds.getCenter().equals(new LatLng(0, 0))) return false;
        return true;
    };

    public static getMobileBaseMapAttributionText = (zoom: number, basemap: Basemap) => {
        switch (basemap) {
            case Basemap.OPEN_STREETMAPS:
                return OSM_ATTRIBUTION;
            case Basemap.OPEN_ELEVATION:
                return OSM_TOPO_ATTRIBUTION;
            case Basemap.GOOGLE_HYBRID:
            case Basemap.GOOGLE_SATELLITE:
            case Basemap.GOOGLE_STREET:
                return GOOGLE_ATTRIBUTION;
            default:
                return;
        }
    };
}
