import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import LineStyle from '../Popup/line-style';
import LineColor from '../ToolBar/line-color';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';
import FreehandPolyline from './freehand-polyline';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import AnnotationDelete from '../annotation-delete';
import { selectFreehandDraws } from '../../../../store/Annotations/Freehand/selectors';
import {
    setFreehandDrawDeleteAction,
    setFreehandDrawOptionsAction,
    setFreehandDrawUpdateAction,
} from '../../../../store/Annotations/Freehand/actions';

const FreehandPolylineToolbar = () => {
    const dispatch = useDispatch();
    const freehandDraws = useSelector(selectFreehandDraws);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedFreehandDraw = freehandDraws.find((c) => c.id === selectedAnnotation?.id);

    const updateFreehandDraw = (freehandDraw: FreehandPolyline) => {
        dispatch(setFreehandDrawUpdateAction(freehandDraw));
    };

    if (!selectedFreehandDraw) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Line Color:</ToolbarText>

            <LineColor
                selectedColor={selectedFreehandDraw.options.color || '#eed926'}
                onSelectColor={(color) => {
                    updateFreehandDraw({
                        ...selectedFreehandDraw,
                        options: {
                            ...selectedFreehandDraw.options,
                            fillColor: color,
                            color: color,
                        },
                    });
                    dispatch(
                        setFreehandDrawOptionsAction({
                            ...selectedFreehandDraw.options,
                            fillColor: color,
                            color: color,
                        })
                    );
                }}
            />
            <ToolbarDivider />

            <ToolbarText>Line Style:</ToolbarText>
            <LineStyle
                selectedLineStyle={{
                    dashArray: selectedFreehandDraw.options.dashArray,
                    weight: selectedFreehandDraw.options.weight,
                }}
                onSelectLineStyle={(lineStyle) => {
                    updateFreehandDraw({
                        ...selectedFreehandDraw,
                        options: {
                            ...selectedFreehandDraw.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        },
                    });
                    dispatch(
                        setFreehandDrawOptionsAction({
                            ...selectedFreehandDraw.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        })
                    );
                }}
            />
            <ToolbarDivider />

            <ToolbarText>Arrange:</ToolbarText>
            <LayerOrderControl annotation={selectedFreehandDraw} />
            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    DrawToolAnalytics.delete('sketch');
                    dispatch(setFreehandDrawDeleteAction(selectedFreehandDraw));
                    setSelectedAnnotationAction(undefined);
                }}
            />
        </ToolbarContainer>
    );
};

export default FreehandPolylineToolbar;
