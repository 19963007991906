import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import remarkGfm from 'remark-gfm';
import React from 'react';

interface MarkdownViewerProps {
    text: string;
    fontColor?: string;
}

export const MarkdownViewer = ({ text, fontColor }: MarkdownViewerProps): JSX.Element => {
    const plugins = [remarkGfm];

    return (
        <ReactMarkdown
            children={text}
            className="react-markdown-styles"
            remarkPlugins={plugins}
            components={{
                a: LinkRenderer,
                h1: (props) => HeaderRenderer(props, fontColor),
                h2: (props) => HeaderRenderer(props, fontColor),
                h3: (props) => HeaderRenderer(props, fontColor),
                h4: (props) => HeaderRenderer(props, fontColor),
                h5: (props) => HeaderRenderer(props, fontColor),
                h6: (props) => HeaderRenderer(props, fontColor),
                p: (props) => TextRenderer(props, fontColor),
                pre: (props) => TextRenderer(props, fontColor),
                code: (props) => TextRenderer(props, fontColor),
                li: (props) => TableLIRenderer(props, fontColor),
                strong: (props) => BoldRenderer(props, fontColor),
                img: BlockedRenderer,
            }}
        />
    );
};

interface MarkdownViewerStyles {
    fontColor?: string;
}

// h1 - h6
const HeaderRenderer = (props, fontColor) => {
    return <StyledHeader fontColor={fontColor}>{props.children}</StyledHeader>;
};

const StyledHeader = styled.h1<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    text-align: justify;
    font-size: 20px;
    word-break: break-word;
`;

// p
const TextRenderer = (props, fontColor) => {
    return <StyledText fontColor={fontColor}>{props.children}</StyledText>;
};

const StyledText = styled.p<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    font-size: 16px;
    word-break: break-word;
`;

// li
const TableLIRenderer = (props, fontColor) => {
    return <StyledLI fontColor={fontColor}>{props.children}</StyledLI>;
};

const StyledLI = styled.li<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    text-align: left;
    font-size: 16px;
    margin-bottom: 0px;
    word-break: break-word;

    ::marker {
        color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    }
`;

// BOLD
const BoldRenderer = (props, fontColor) => {
    return <StyledBold fontColor={fontColor}>{props.children}</StyledBold>;
};

const StyledBold = styled.strong<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    text-align: justify;
    font-size: 16px;
    margin-bottom: 0px;
    word-break: break-word;
`;

// LINKS, Force a tag to open in new tab
const LinkRenderer = (props) => {
    const sanitiseHref = (props): string => {
        // Check if props and necessary properties exist
        if (!props || typeof props.href === 'undefined' || !Array.isArray(props.children)) {
            return '';
        }

        // Determine the URL from props
        const url = props.href !== 'url' ? props.href : props.children.length > 0 ? props.children[0] : '';

        // Return as is if no URL or it's not a string
        if (!url || typeof url !== 'string') {
            return url;
        }

        // URL already has a valid protocol
        if (url.startsWith('https://')) {
            return url;
        }

        // URL is valid but insecure so replace with secure protocol
        if (url.startsWith('http://')) {
            return url.replace('http://', 'https://');
        }

        // Assume it's a domain without a protocol so add one and return as the markdown wants a protocol
        if (url.includes('.')) {
            return 'https://' + url;
        }

        // Default return the original URL
        return url;
    };

    return (
        <StyledLink href={sanitiseHref(props)} target="_blank" rel="noreferrer">
            {props.children ? props.children : props.href}
        </StyledLink>
    );
};

const StyledLink = styled.a`
    color: #eed926;
    -webkit-text-fill-color: #eed926;
    text-decoration: underline !important;
    word-break: break-word;
    pointer-events: auto;

    &:hover {
        color: rgba(238, 218, 38, 0.87);
        -webkit-text-fill-color: rgba(238, 218, 38, 0.87);
    }
`;

// Could not find much on it but this seems like an easy way to block unwanted markdown been rendered
// BLOCKED MARKDOWN LIST
// img - ![](url)
const BlockedRenderer = () => {
    return <React.Fragment />;
};
