import React from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import PageError from './components/Shared/page-error';

const ErrorBoundary = (props) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error }) => {
                return (
                    <PageError background="/assets/backgrounds-large/soar-platform-hazy-as-backdrop.png">
                        <h3>
                            Houston,
                            <br />
                            we have a problem ...
                        </h3>
                        <p>
                            We've had a Main B Bus Undervolt! <br />
                            Just a joke, but we're still detecting an error. Please try to reload the page.
                        </p>
                        <button onClick={() => window.open('/', '_self')}>RELOAD PAGE</button>
                        <ReportButton
                            onClick={() => {
                                const eventId = Sentry.captureException(error);
                                Sentry.showReportDialog({ eventId });
                            }}
                        >
                            SEND US A REPORT
                        </ReportButton>
                    </PageError>
                );
            }}
        >
            {props.children}
        </Sentry.ErrorBoundary>
    );
};

export default ErrorBoundary;

const ReportButton = styled.button`
    background-color: rgba(0, 0, 0, 0) !important;
    border: 1px solid white !important;
    display: inline-block !important;
    color: white !important;
    margin-left: 10px;
`;
