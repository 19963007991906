import { LatLng, LatLngBounds, Map } from 'leaflet';
import { Config } from '../App/model';
import { actionTypes as at } from './constants';

export const actionUpdateConfigSuccess = (config: Config) => {
    return {
        type: at.UPDATE_CONFIG_SUCCESS,
        payload: config,
    };
};

export const actionFlyTo = (position: LatLng | LatLngBounds, zoom?: number, immediately?: boolean) => {
    return {
        type: at.FLY_TO,
        payload: {
            position: position,
            zoom: zoom,
            immediately: immediately ? immediately : false,
        },
    };
};

export const actionFlyToZoom = (zoom: number | undefined) => {
    return {
        type: at.FLY_TO_ZOOM,
        payload: zoom,
    };
};

export const actionFlyToOnMap = (location: LatLng | LatLngBounds) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.FLY_TO_POSITION,
            payload: location,
        });
        if (location) {
            await sleep(1000);
            dispatch(actionFlyToInvalidate());
        }
    };
};

export const actionFlyToInvalidate = () => {
    return {
        type: at.FLY_TO_POSITION_INVALIDATE,
    };
};

export const actionShowLoginDialog = (show: boolean) => {
    return {
        type: at.SHOW_LOGIN_DIALOG,
        payload: show,
    };
};

export const actionResetLoginDialogCloseRedirect = () => {
    return {
        type: at.RESET_LOGIN_DIALOG_CLOSE_REDIRECT,
        payload: {},
    };
};

function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const actionShowNavbar = (showNavBar: boolean) => {
    return {
        type: at.SHOW_NAVBAR,
        payload: showNavBar,
    };
};

export const actionSetMapBounds = (bounds) => {
    return {
        type: at.SET_MAP_BOUNDS,
        payload: bounds,
    };
};

export const actionSetMapZoom = (zoom: number | undefined) => {
    return {
        type: at.SET_MAP_ZOOM,
        payload: zoom,
    };
};

export const actionSetLeafletMap = (map: Map) => {
    return {
        type: at.SET_LEAFLET_MAP,
        payload: map,
    };
};

export const actionShowUploadDialog = (show: boolean) => {
    return {
        type: at.SHOW_UPLOAD_DIALOG,
        payload: show,
    };
};
