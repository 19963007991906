import { LatLng, LatLngBounds } from 'leaflet';
import styled from 'styled-components';
import { ListingDTO } from '../../api/model';
import GeoUtil from '../../lib/geo-util';
import ListingHelper from '../../lib/listing-helper';
const moment = require('moment');

interface SearchResultsProps {
    listings: ListingDTO[];
}

const SearchResults = (props: SearchResultsProps) => {
    const capitalize = (text: string): string => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const breadcrumbs = (listing: ListingDTO): string => {
        return listing.categories
            .map((category: string) => {
                return `${capitalize(category)}`;
            })
            .join(' > ');
    };

    const continentForListing = (listing: ListingDTO): string => {
        const oceania = new LatLngBounds(new LatLng(-5.4, 109.8), new LatLng(-53.1, 185.4));
        const asia = new LatLngBounds(new LatLng(77.2, 39.5), new LatLng(-2.5, 184.0));
        const europe = new LatLngBounds(new LatLng(76.8, -27.6), new LatLng(35.7, 70.0));
        const northAmerica = new LatLngBounds(new LatLng(80.0, -171.0), new LatLng(13.6, -26.35));
        const southAmerica = new LatLngBounds(new LatLng(13.0, -88.0), new LatLng(-56.0, -33.3));
        const africa = new LatLngBounds(new LatLng(37.3, -17.7), new LatLng(-37.7, 54.4));

        if (GeoUtil.isApproximatelyGlobalBounds(listing.boundingBox)) {
            return 'Global Map';
        }

        if (GeoUtil.approximatelyContains(oceania, listing.boundingBox)) {
            return 'Oceania';
        }

        if (GeoUtil.approximatelyContains(asia, listing.boundingBox)) {
            return 'Asia';
        }

        if (GeoUtil.approximatelyContains(europe, listing.boundingBox)) {
            return 'Europe';
        }

        if (GeoUtil.approximatelyContains(northAmerica, listing.boundingBox)) {
            return 'North America';
        }

        if (GeoUtil.approximatelyContains(southAmerica, listing.boundingBox)) {
            return 'South America';
        }

        if (GeoUtil.approximatelyContains(africa, listing.boundingBox)) {
            return 'Africa';
        }

        return 'Local map';
    };

    return (
        <Container>
            <ResultsList>
                {props.listings.map((listing: ListingDTO) => {
                    return (
                        <ResultContainer>
                            <PreviewImage src={ListingHelper.getPreviewUrlForListing(listing.id, 'small')} />
                            <DetailContainer>
                                <Title>{listing.title}</Title>
                                <Breadcrumb>{`${continentForListing(listing)} > ${breadcrumbs(listing)}`}</Breadcrumb>
                                <DateString>{moment(new Date(listing.createdAt * 1000)).fromNow()}</DateString>
                            </DetailContainer>
                        </ResultContainer>
                    );
                })}
            </ResultsList>
        </Container>
    );
};

export default SearchResults;

const Container = styled.div`
    width: 100%;
    margin: 50px 0 0 80px;
    margin-left: 80px;
    display: flex;
    flex-direction: row;
`;

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 12px;
`;

const ResultsList = styled.div`
    width: 0 0 100%;
`;

const ResultContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 30px 0 0 0;
`;

const PreviewImage = styled.img`
    max-width: 64px;
`;

const Title = styled.h3`
    text-align: left;
    line-height: 1;
    margin: 0;
    padding: 0 0 4px 0;
    font-size: 20px;
    font-weight: 400;
    color: white;
    cursor: pointer;
`;

const Breadcrumb = styled.div`
    color: white;
`;

const DateString = styled.div`
    color: white;
`;
