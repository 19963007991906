import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMapZoom } from '../../../store/App/selectors';

const MAX_SENTINEL_ZOOM = 9;

export function useMobileCanSearchForSatelliteImagery() {
    const mapZoom = useSelector(selectMapZoom);
    const [canSearchForSatellite, setCanSearchForSatellite] = useState(false);

    const handleCanSearchForImagery = useCallback((zoomLevel: number) => {
        if (zoomLevel >= MAX_SENTINEL_ZOOM) {
            setCanSearchForSatellite(true);
            return;
        }
        setCanSearchForSatellite(false);
    }, []);

    useEffect(() => {
        if (mapZoom) {
            handleCanSearchForImagery(mapZoom);
        }
    }, [handleCanSearchForImagery, mapZoom]);

    return canSearchForSatellite;
}
