import React, { useEffect } from 'react';
import { LatLng } from 'leaflet';
import SearchResultMarkerIcon, { SearchResultMarkerType } from './search-result-marker-icon';
import { selectActiveSearchPosition, selectHighlightedSearchPosition } from '../../../store/Search/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { actionUpdateActiveSearchPosition } from '../../../store/Search/actions';

const MARKER_SELF_REMOVAL_TIMEOUT = 30; // seconds

const SearchResultMarker = () => {
    const dispatch = useDispatch();
    const activePosition = useSelector(selectActiveSearchPosition);
    const highlightedPosition = useSelector(selectHighlightedSearchPosition);

    useEffect(() => {
        const removeMarkerTimeout = setTimeout(() => {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        }, MARKER_SELF_REMOVAL_TIMEOUT * 1000);

        return () => clearTimeout(removeMarkerTimeout);
    }, [activePosition, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            {activePosition instanceof LatLng && (
                <SearchResultMarkerIcon position={activePosition} type={SearchResultMarkerType.large} />
            )}

            {highlightedPosition instanceof LatLng && (
                <SearchResultMarkerIcon
                    position={highlightedPosition}
                    type={SearchResultMarkerType.large}
                    color="6ecc39"
                />
            )}
        </React.Fragment>
    );
};

export default SearchResultMarker;
