import MeasurementToolbar from '../Measurement/measurement-toolbar';
import MeasurementToolbarLabelColor, { MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import MeasurementToolbarPolygonArea from '../Measurement/measurement-toolbar-polygon-area';
import MeasurementToolbarUnits, { MeasurementUnits } from '../Measurement/measurement-toolbar-units';
import Rectangle from './rectangle';
import RectangleMeasurementLengthToolbar from './rectangle-measurement-length-toolbar';

interface RectangleMeasurementToolbarProps {
    rectangle: Rectangle;
    onChangeShowArea: (showArea: boolean) => void;
    onChangeShowLength: (showLength: boolean) => void;
    onChangeUnits: (units: MeasurementUnits) => void;
    onChangeLabelColor: (labelColor: MeasurementLabelColor) => void;
}

const RectangleMeasurementToolbar = (props: RectangleMeasurementToolbarProps) => {
    return (
        <MeasurementToolbar>
            <MeasurementToolbarPolygonArea poly={props.rectangle} onChangeShowArea={props.onChangeShowArea} />
            <RectangleMeasurementLengthToolbar
                rectangle={props.rectangle}
                onChangeShowLength={props.onChangeShowLength}
            />
            <MeasurementToolbarUnits units={props.rectangle.units} onChangeUnits={props.onChangeUnits} />
            <MeasurementToolbarLabelColor
                labelColor={props.rectangle.labelColor}
                onChangeLabelColor={props.onChangeLabelColor}
            />
        </MeasurementToolbar>
    );
};

export default RectangleMeasurementToolbar;
