import turfDistance from '@turf/distance';

import {
    MeasurementToolbarCheckbox,
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
} from '../Measurement/measurement-toolbar';
import Rectangle from './rectangle';

interface RectangleMeasurementLengthToolbarProps {
    rectangle: Rectangle;
    onChangeShowLength: (showLength: boolean) => void;
}

const MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH = 1200000; // 1,200 km

const RectangleMeasurementLengthToolbar = (props: RectangleMeasurementLengthToolbarProps) => {
    const isRectangleTooLarge = (): boolean => {
        const north = props.rectangle.bounds.getNorthEast().lat;
        const south = props.rectangle.bounds.getSouthWest().lat;
        const east = props.rectangle.bounds.getNorthEast().lng;
        const west = props.rectangle.bounds.getSouthWest().lng;

        const eastWestDistance = turfDistance([north, east], [north, west], { units: 'meters' });
        const northSouthDistance = turfDistance([north, east], [south, east], { units: 'meters' });

        if (
            eastWestDistance > MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH ||
            northSouthDistance > MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH
        ) {
            return true;
        }
        return false;
    };

    const disabled = isRectangleTooLarge();
    return (
        <MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionLabel htmlFor="rect-edge-length-tick">
                Show edge Length:
            </MeasurementToolbarOptionLabel>
            <MeasurementToolbarCheckbox
                id="rect-edge-length-tick"
                disabled={disabled}
                title={disabled ? 'Length(s) too long to show length labels' : 'Show edge Length'}
                type="checkbox"
                checked={props.rectangle.showLength}
                onClick={() => props.onChangeShowLength(!props.rectangle.showLength)}
            />
        </MeasurementToolbarOptionContainer>
    );
};

export default RectangleMeasurementLengthToolbar;
