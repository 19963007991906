import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
} from '@react-leaflet/core';
import L, { LeafletMouseEvent } from 'leaflet';
import { selectedPolygonOutlineOptions } from '../Polygon/polygon';
import LayersUtil from '../layers-util';
import Circle from './circle';
import { createCircleEditElement } from './circle-edit-element';
import { createLengthLabel } from '../Measurement/length-label';

interface CircleAnnotationProps extends L.CircleOptions {
    circle: Circle;
    isSelected: boolean;
    onUpdateCircle?: (circle: Circle) => void;
    children?: React.ReactNode;
    isDisabled?: boolean;
}

const createCircleElement = (props: CircleAnnotationProps, context: LeafletContextInterface) => {
    const pane = LayersUtil.getPaneId(context.map, props.circle);

    const circle = new L.Circle(props.circle.center, props.circle.radius, {
        ...props.circle.options,
        pane: pane,
    });
    const circleElement = createElementObject<L.Circle, CircleAnnotationProps>(circle, context);

    const selectedOutlineBoundsId = LayersUtil.getSelectedOutlinePaneId(context.map);
    const circleBounds = new L.Polygon([], { ...selectedPolygonOutlineOptions, pane: selectedOutlineBoundsId });
    const circleBoundsElement = createElementObject<L.Polygon, CircleAnnotationProps>(circleBounds, context);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let radiusMeasurementLabelElement: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let radiusPolylineElement: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let radiusCenterMarkerElement: any;

    const addRadiusMeasurementLabel = () => {
        const center = circleElement.instance.getLatLng();
        const bounds = circleElement.instance.getBounds();
        const east = new L.LatLng(center.lat, bounds.getEast());

        const radiusPolyline = new L.Polyline([center, east], {
            color: props.circle.labelColor,
            pane: pane,
            dashArray: '2, 8',
            weight: 1,
        });

        const radiusCenterMarker = new L.CircleMarker(center, {
            pane: pane,
            radius: 2,
            color: props.circle.labelColor,
            opacity: 1,
            fillOpacity: 1,
        });

        radiusMeasurementLabelElement = createLengthLabel(
            center,
            east,
            [],
            props.circle.units,
            props.circle.labelColor,
            pane,
            context
        );

        radiusCenterMarkerElement = createElementObject<L.CircleMarker>(radiusCenterMarker, context);

        radiusMeasurementLabelElement.instance.on('hide', () => {
            if (radiusPolylineElement.instance && radiusCenterMarkerElement.instance) {
                radiusPolylineElement.instance.setStyle({ opacity: 0 });
                radiusCenterMarkerElement.instance.setStyle({ opacity: 0, fillOpacity: 0 });
            }
        });

        radiusMeasurementLabelElement.instance.on('show', () => {
            if (radiusPolylineElement.instance && radiusCenterMarkerElement.instance) {
                radiusPolylineElement.instance.setStyle({ opacity: 1 });
                radiusCenterMarkerElement.instance.setStyle({ opacity: 1, fillOpacity: 1 });
            }
        });

        radiusPolylineElement = createElementObject<L.Polyline, CircleAnnotationProps>(radiusPolyline, context);
        context.map.addLayer(radiusMeasurementLabelElement.instance);
        context.map.addLayer(radiusPolylineElement.instance);
        context.map.addLayer(radiusCenterMarkerElement.instance);
    };

    const removeRadiusMeasurementLabel = () => {
        if (
            radiusCenterMarkerElement &&
            radiusCenterMarkerElement.instance &&
            radiusPolylineElement &&
            radiusPolylineElement.instance &&
            radiusMeasurementLabelElement &&
            radiusMeasurementLabelElement.instance
        ) {
            context.map.removeLayer(radiusMeasurementLabelElement.instance);
            context.map.removeLayer(radiusPolylineElement.instance);
            context.map.removeLayer(radiusCenterMarkerElement.instance);
            radiusMeasurementLabelElement = undefined;
            radiusPolylineElement = undefined;
            radiusCenterMarkerElement = undefined;
        }
    };

    const handleUpdateCircle = () => {
        props.onUpdateCircle &&
            props.onUpdateCircle({
                ...props.circle,
                center: circleElement.instance.getLatLng(),
                radius: circleElement.instance.getRadius(),
                options: {
                    ...props.circle.options,
                    ...circleElement.instance.options,
                },
            });
        if (props.isSelected) {
            updateCircleBoundsPolygon();
        }
    };

    context.map.on('zoomend', handleUpdateCircle);

    circleElement.instance.on('update', (e: LeafletMouseEvent) => {
        circleElement.instance.setLatLng(e.latlng);
        handleUpdateCircle();
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    circleElement.instance.on('drag', (e: any) => {
        circleElement.instance.setLatLng(e);
        handleUpdateCircle();
    });

    circleElement.instance.on('resize', (e: LeafletMouseEvent) => {
        circleElement.instance.setRadius(circleElement.instance.getLatLng().distanceTo(e.latlng));
        handleUpdateCircle();
    });

    const updateCircleBoundsPolygon = () => {
        const bounds = circleElement.instance.getBounds();
        const positions = [bounds.getNorthWest(), bounds.getNorthEast(), bounds.getSouthEast(), bounds.getSouthWest()];
        circleBoundsElement.instance.setLatLngs(positions);
    };

    const editElement = createCircleEditElement(
        { circleElement: circleElement, color: circleElement.instance.options.color },
        context
    );

    circleElement.instance.on('add', () => {
        if (props.circle.showRadius) {
            addRadiusMeasurementLabel();
        }

        if (props.isSelected) {
            context.map.addLayer(editElement.instance);

            updateCircleBoundsPolygon();
            context.map.addLayer(circleBoundsElement.instance);
        } else {
            context.map.removeLayer(editElement.instance);
        }
    });

    circleElement.instance.on('remove', () => {
        context.map.removeLayer(editElement.instance);
        context.map.removeLayer(circleBoundsElement.instance);
        context.map.off('zoomend', handleUpdateCircle);
        removeRadiusMeasurementLabel();
    });

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        circleElement.instance.options.interactive = false;
    } else {
        circleElement.instance.options.interactive = true;
    }

    return circleElement;
};

const updateCircleElement = (instance: L.Circle, props: CircleAnnotationProps, _: CircleAnnotationProps) => {
    instance.setStyle({ ...instance.options, ...props.circle.options });
};

const useCircleElement = createElementHook<L.Circle, CircleAnnotationProps, LeafletContextInterface>(
    createCircleElement,
    updateCircleElement
);

const useCircle = createLayerHook(useCircleElement);
const CircleAnnotation = createContainerComponent(useCircle);
export default CircleAnnotation;
