import React, { useState } from 'react';

import { ModalBody, FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiComments from '../../../../api/api-comments';
import ReportCommentThankyouDialog from './report-comment-thankyou-dialog';
import { StyledDarkButton, StyledDeleteButton } from '../../../Shared/styled-button';
import { IsSuccessResponseHandler } from './types';
import SoarModal, { StyledModalFooter } from '../../../Shared/soar-modal';
import Analytics from '../../../../lib/user-analytics';

interface ReportCommentDialogProps {
    isModeratingComment: boolean;
    commentId: string;
    handleCommentReport: IsSuccessResponseHandler;
}

const ReportCommentDialog = (props: ReportCommentDialogProps) => {
    const [reportReason, setReportReason] = useState<string>('Spam');
    const [message, setMessage] = useState<string>('');
    const [showThankyouModal, setShowThankyouModal] = useState(false);
    const [reportingComment, setReportingComment] = useState(false);

    const handleReportComment = () => {
        setReportingComment(true);
        ApiComments.reportComment(props.commentId, reportReason, message)
            .then(() => {
                setShowThankyouModal(true);
                if (props.isModeratingComment) {
                    props.handleCommentReport(true);
                    toast.dark('This comment has been deleted.');
                    Analytics.Event('Comments', 'Report comment', props.commentId);
                }
            })
            .catch((err) => {
                toast.dark('There was a problem reporting the comment.  Please try again later');
                props.handleCommentReport(false, err);
            })
            .finally(() => {
                setReportingComment(false);
            });
    };

    const handleClose = () => {
        props.handleCommentReport();
        Analytics.Event('Comments', 'Report comment closed');
    };

    if (showThankyouModal) {
        return <ReportCommentThankyouDialog handleCloseThankyouDialog={handleClose} />;
    }

    return (
        <SoarModal
            title={
                <span>
                    <i className="fa fa-exclamation-circle icon mr-2 text-danger" />
                    <small>
                        {props.isModeratingComment ? `Delete this comment as a moderator` : `Report this comment`}
                    </small>
                </span>
            }
            isOpen={true}
            toggle={handleClose}
            width="450px"
        >
            <ReportModalBody>
                <FormGroup>
                    {props.isModeratingComment ? (
                        <Label>Please select the reason for deleting this comment</Label>
                    ) : (
                        <Label>Please select the reason for reporting this comment</Label>
                    )}
                    <Input
                        data-testid="select-reason"
                        type="select"
                        value={reportReason}
                        onChange={(value) => setReportReason(value.target.value)}
                    >
                        <option value="Spam">Spam</option>
                        <option value="Hate">Hate</option>
                        <option value="Misinformation">Misinformation</option>
                        <option value="Harassment">Harassment</option>
                        <option value="Threatening Violence">Threatening violence</option>
                        <option value="Copyright violation">Copyright violation</option>
                        <option value="Violation of Soar.Earth Terms">Violation of Soar.Earth T&C's</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label>Tell us more (optional)</Label>
                    <MessageTextArea
                        aria-label="message"
                        type="textarea"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </FormGroup>
            </ReportModalBody>
            <StyledModalFooter>
                <StyledDarkButton onClick={handleClose}>CANCEL</StyledDarkButton>
                <StyledDeleteButton onClick={handleReportComment} disabled={reportingComment}>
                    {props.isModeratingComment ? `REPORT & DELETE` : `REPORT`}
                </StyledDeleteButton>
            </StyledModalFooter>
        </SoarModal>
    );
};

export default ReportCommentDialog;

const ReportModalBody = styled(ModalBody)`
    font-size: 16px;

    label {
        color: rgba(255, 255, 255, 0.87);
        width: 100%;
    }

    input {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(255 255 255 / 0.3) !important;

        :focus {
            background-color: transparent;
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
        }

        ::placeholder {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    select {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: transparent;
            border-color: rgba(255, 255, 255, 0.3);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
            color: rgba(255, 255, 255, 0.6);
        }
    }

    option {
        background-color: rgba(0, 0, 0, 0.8);
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;

        :hover {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    textarea {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: transparent;
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;

const MessageTextArea = styled(Input)`
    height: 100px !important;
`;
