import Arrow from '../../../components/MapView/Annotations/Arrow/arrow';
import { actionTypes as at } from './constants';

export const setArrowsAction = (arrows: Arrow[]) => {
    return {
        type: at.SET_ARROWS,
        payload: { arrows: arrows },
    };
};

export const setArrowOptionsAction = (options: L.PolylineOptions) => {
    return {
        type: at.SET_ARROW_OPTIONS,
        payload: options,
    };
};

export const setArrowAddAction = (arrow: Arrow) => {
    return {
        type: at.SET_ARROW_ADD,
        payload: arrow,
    };
};

export const setArrowUpdateAction = (arrow: Arrow) => {
    return {
        type: at.SET_ARROW_UPDATE,
        payload: arrow,
    };
};

export const setArrowDeleteAction = (arrow: Arrow) => {
    return {
        type: at.SET_ARROW_DELETE,
        payload: arrow,
    };
};
