import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../Shared/soar-modal';
import { StyledDarkButton } from '../../../Shared/styled-button';

interface ReportCommentThankyouDialogProps {
    handleCloseThankyouDialog: () => void;
}

const ReportCommentThankyouDialog = (props: ReportCommentThankyouDialogProps) => {
    return (
        <SoarModal title="Comment was reported" isOpen={true} toggle={props.handleCloseThankyouDialog}>
            <StyledModalBody>
                Thank you for taking the time to report this comment. Our moderators and admin will look into this
                straight away.
            </StyledModalBody>
            <StyledModalFooter>
                <StyledDarkButton onClick={props.handleCloseThankyouDialog}>CLOSE</StyledDarkButton>
            </StyledModalFooter>
        </SoarModal>
    );
};

export default ReportCommentThankyouDialog;
