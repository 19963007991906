import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { keyframes, css } from 'styled-components';
import SideDrawerNoMaps from './side-drawer-no-maps';
import SideDrawerLoader from './side-drawer-loader';
import { useDispatch } from 'react-redux';
import { actionSetHighlightedStoaryWKT } from '../../../../store/Map/Stoaries/actions';
import { StoaryResponse } from '../../../../api/stoaryModel';

export type MapDrawerTypes = 'MapSearch' | 'Bookmarks' | 'MyMaps' | 'Stoaries';

export type ItemType = StoaryResponse;

interface MapSearchDrawAOIResultsProps {
    type: MapDrawerTypes;
    items: ItemType[] | undefined;
    exitingItems: Set<number>;
    itemsCount: number;
    filteredCount: number;
    isLoading?: boolean;
    hasMoreToLoad?: boolean;
    onLoadMore: () => void;
    onClick: (item: ItemType, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    onMouseEnter?: (item: ItemType) => void;
    onMouseLeave?: (item: ItemType) => void;
    isHighlighted?: (item: ItemType) => boolean;
    heightPadding: string;
    getItemLink: (item: ItemType) => string;
    getItemCard: (item: ItemType) => JSX.Element;
}

// TODO: this was created as a shared component but only used by Stoaries Drawer so simplify for now until refactor
const SideDrawerInfiniteList = ({
    type,
    items,
    exitingItems,
    isLoading,
    hasMoreToLoad,
    onLoadMore,
    onClick,
    isHighlighted,
    heightPadding,
    getItemLink,
    getItemCard,
}: MapSearchDrawAOIResultsProps) => {
    const dispatch = useDispatch();

    const handleIsHighlighted = (item: ItemType): boolean => {
        return isHighlighted ? isHighlighted(item) : false;
    };

    const handleOnMouseEnter = (item: ItemType) => {
        if (item.viewPortsWKT) {
            dispatch(actionSetHighlightedStoaryWKT(item.viewPortsWKT[0]));
        }
    };

    const handleOnMouseLeave = () => {
        dispatch(actionSetHighlightedStoaryWKT(undefined));
    };

    const handleOnClick = (item: ItemType, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onClick(item, e);
    };

    return (
        <React.Fragment>
            <ResultsContainer
                initialScrollY={0}
                dataLength={items?.length ?? 0}
                next={onLoadMore}
                hasMore={hasMoreToLoad ?? false}
                loader={isLoading && items && items?.length > 0 ? <SideDrawerLoader /> : null}
                height={`calc(100vh - ${heightPadding})`} // height of the header and a trim off the bottom
            >
                {items &&
                    items.map((item, index) => (
                        <Container
                            key={`${index}-${item.id}`}
                            onMouseEnter={() => handleOnMouseEnter(item)}
                            onMouseLeave={() => handleOnMouseLeave()}
                            onClick={(e) => handleOnClick(item, e)}
                            title={item.title}
                            isHighlighted={handleIsHighlighted(item)}
                            isExiting={exitingItems.has(item.id)}
                            index={index}
                            href={getItemLink(item)}
                        >
                            {getItemCard(item)}
                        </Container>
                    ))}
                {items && items?.length === 0 && !isLoading ? <SideDrawerNoMaps type={type} /> : null}
                {isLoading && items === undefined ? <SideDrawerLoader /> : null}
            </ResultsContainer>
        </React.Fragment>
    );
};

export default SideDrawerInfiniteList;

const ResultsContainer = styled(InfiniteScroll)`
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 48px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 48px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const Container = styled.a<{ isHighlighted: boolean; isExiting: boolean; index: number }>`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;

    ${({ isExiting, index }) =>
        css`
            animation: ${isExiting ? fadeOut : fadeIn} 0.05s ease-in-out forwards;
            animation-delay: ${index * 0.025}s;
        `}

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
        text-decoration: none;
    }
`;
