import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import LineColor from '../ToolBar/line-color';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';
import Arrow from './arrow';
import { selectArrows } from '../../../../store/Annotations/Arrow/selectors';
import {
    setArrowOptionsAction,
    setArrowUpdateAction,
    setArrowDeleteAction,
} from '../../../../store/Annotations/Arrow/actions';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';

const ArrowToolbar = () => {
    const dispatch = useDispatch();
    const arrows = useSelector(selectArrows);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedArrow = arrows.find((c) => c.id === selectedAnnotation?.id);

    const updateArrow = (arrow: Arrow) => {
        dispatch(setArrowUpdateAction(arrow));
    };

    if (!selectedArrow) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Line Color: </ToolbarText>
            <LineColor
                onSelectColor={(color) => {
                    updateArrow({
                        ...selectedArrow,
                        options: {
                            ...selectedArrow.options,
                            fillColor: color,
                            color: color,
                        },
                    });
                    dispatch(
                        setArrowOptionsAction({
                            ...selectedArrow.options,
                            fillColor: color,
                            color: color,
                        })
                    );
                }}
                selectedColor={selectedArrow.options.color || '#eed926'}
            />
            <ToolbarDivider />

            <ToolbarText>Arrange: </ToolbarText>
            <LayerOrderControl annotation={selectedArrow} />

            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    DrawToolAnalytics.delete('arrow');
                    dispatch(setArrowDeleteAction(selectedArrow));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default ArrowToolbar;
