// Allowed location for using connected-react-router
// eslint-disable-next-line no-restricted-imports
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Store, StoreEnhancer, AnyAction } from 'redux';
import ReduxThunk from 'redux-thunk';
import { history } from './history';
import { state } from './root-reducer';

const routeMiddleware = routerMiddleware(history);

// Type declaration for window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ to avoid TypeScript errors
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// Use Redux DevTools extension if available, otherwise use Redux's compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const enhancer: StoreEnhancer<any> = composeEnhancers(applyMiddleware(routeMiddleware, ReduxThunk));

const store: Store<AnyAction> = createStore(state, undefined, enhancer);

//@ts-ignore
if (window.Cypress) {
    //@ts-ignore
    window.store = store;
}

export default store;
