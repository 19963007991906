import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MilSymbolValues } from './MilSymbol/values';
import { app6d } from 'stanag-app6';
import { Text } from '../ToolBar/toolbar-modal';

interface MilitaryMarkerSearchProps {
    SIDCCode: string;
    setSIDCCode: (SIDCCode: string) => void;
}

const MilitaryMarkerSearch = ({ SIDCCode, setSIDCCode }: MilitaryMarkerSearchProps) => {
    const [search, setSearch] = useState<string>('');
    const [symbols, setSymbols] = useState<MilSymbolValues[]>([]);
    const [filteredSymbols, setFilteredSymbols] = useState<MilSymbolValues[]>([]);
    const [symbolSearch, setSymbolSearch] = useState<string>('');

    useEffect(() => {
        setSearch(SIDCCode);
    }, [SIDCCode]);

    useEffect(() => {
        const fetchData = () => {
            const allSymbolsData: MilSymbolValues[] = [];
            const affiliation = SIDCCode[3];
            const echelon = SIDCCode[8] + SIDCCode[9];
            Object.keys(app6d).forEach((ssValue) => {
                const ss = app6d[ssValue]['mainIcon'] || [];
                ss.forEach((mi) => {
                    let text = mi.Entity;
                    if (mi['Entity Type']) text += ` - ${mi['Entity Type']}`;
                    if (mi['Entity Subtype']) text += ` - ${mi['Entity Subtype']}`;
                    allSymbolsData.push({
                        text,
                        value: `100${affiliation}${ssValue}00${echelon}${mi.Code}0000`,
                    });
                });
            });

            setSymbols(allSymbolsData);
            setFilteredSymbols(allSymbolsData);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SIDCCode]);

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearch(e.target.value);
        setSIDCCode(e.target.value);
    };

    const handleSymbolSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const searchValue = e.target.value.toLowerCase();
        setSymbolSearch(searchValue);
        const filtered = symbols.filter((symbol) => symbol.text.toLowerCase().includes(searchValue));
        setFilteredSymbols(filtered);
    };

    return (
        <React.Fragment>
            <Text key="custom-input">Enter your symbol code into the input or search the symbol list below.</Text>
            <TextInput type="text" placeholder="Enter SIDC" value={search} onChange={handleSearchInput} />
            <MarkerOptionsTitle>Search Symbols</MarkerOptionsTitle>
            <TextInput type="text" placeholder="Search..." value={symbolSearch} onChange={handleSymbolSearch} />
            <MarkerItemContainer>
                {filteredSymbols.map((symbol, index) => (
                    <MarkerItemList
                        isSelected={SIDCCode === symbol.value}
                        title={`${symbol.value} - ${symbol.text}`}
                        key={`${index}-${symbol.value}`}
                        onClick={() => setSIDCCode(symbol.value)}
                    >
                        {symbol.text}
                    </MarkerItemList>
                ))}
            </MarkerItemContainer>
        </React.Fragment>
    );
};

export default MilitaryMarkerSearch;

const TextInput = styled.input`
    margin: 4px 0px;
    display: block;
    width: 100%;
    background: transparent;
    padding: 4px;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid rgb(255 255 255 / 0.3) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;

    :active,
    :focus,
    :focus-visible {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6) !important;
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3) !important;
        color: rgba(255, 255, 255, 0.6) !important;
        outline: none;
    }
`;

const MarkerOptionsTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin: 5px 0px;
`;

const MarkerItemContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 300px;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const MarkerItemList = styled.div<{ isSelected: boolean }>`
    border: ${(props) => (props.isSelected ? '1px solid rgba(238, 217, 38, 0.6)' : '1px solid rgb(255 255 255 / 0.3)')};
    background-color: ${(props) => (props.isSelected ? 'rgba(238, 217, 38, 0.3)' : 'transparent')};
    display: block;
    width: 100%;
    padding: 4px;
    margin: 4px 0px;
    color: white !important;
    -webkit-text-fill-color: white;
    border-radius: 6px;
    cursor: pointer;

    :hover {
        background-color: rgba(238, 217, 38, 0.3);
        border: 1px solid rgba(238, 217, 38, 0.6) !important;
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3) !important;
        color: rgba(255, 255, 255, 0.6) !important;
        outline: none;
    }
`;
