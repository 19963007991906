import { useState, useEffect, useCallback } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../store/Annotations/actions';

const ANNOTATION_LOCK_EVENT = 'annotation-lock-event';
const ANNOTATION_UNLOCK_EVENT = 'annotation-unlock-event';
const BUILD_START_EVENT = 'annotation-build-start';
const BUILD_END_EVENT = 'annotation-build-end';

// Handles locking and unlocking of annotations during build phase
const useAnnotationBuildLock = () => {
    const map = useMap();
    const dispatch = useDispatch();

    const setSelectedAnnotation = useCallback(
        (annotation) => dispatch(setSelectedAnnotationAction(annotation)),
        [dispatch]
    );

    const [buildActive, setBuildActive] = useState(false);
    const [locked, setLocked] = useState(false);

    const lockAnnotationsEvent = useCallback(() => {
        map.fire(ANNOTATION_LOCK_EVENT);
        map.fire(BUILD_START_EVENT);
    }, [map]);

    const unlockAnnotationsEvent = useCallback(() => {
        map.fire(ANNOTATION_UNLOCK_EVENT);
        map.fire(BUILD_END_EVENT);
    }, [map]);

    useEffect(() => {
        const handleLock = () => setLocked(true);
        const handleUnlock = () => setLocked(false);
        const handleBuildStart = () => {
            setSelectedAnnotation(undefined);
            setBuildActive(true);
        };
        const handleBuildEnd = () => {
            setBuildActive(false);
        };

        map.on(ANNOTATION_LOCK_EVENT, handleLock);
        map.on(ANNOTATION_UNLOCK_EVENT, handleUnlock);
        map.on(BUILD_START_EVENT, handleBuildStart);
        map.on(BUILD_END_EVENT, handleBuildEnd);

        return () => {
            map.off(ANNOTATION_LOCK_EVENT, handleLock);
            map.off(ANNOTATION_UNLOCK_EVENT, handleUnlock);
            map.off(BUILD_START_EVENT, handleBuildStart);
            map.off(BUILD_END_EVENT, handleBuildEnd);
        };
    }, [map, setSelectedAnnotation]);

    return {
        buildActive,
        locked,
        lockAnnotationsEvent,
        unlockAnnotationsEvent,
    };
};

export default useAnnotationBuildLock;
