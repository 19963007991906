import React from 'react';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import SocialLinks from './social-links';
import Constants from '../../../constants';

const LogoAndCopyright = () => {
    return (
        <React.Fragment>
            <SoarLogo src="/assets/logos/soar_logo.png" />
            <Copyright>{Constants.MAPS_ATTRIBUTION.SOAR_ATTRIBUTION}</Copyright>

            <TermsContainer>
                <TermsLink
                    href="https://about.soar.earth/terms"
                    target="_blank"
                    onClick={() => {
                        Analytics.Event('LandingPage', 'Clicked Footer Terms of service');
                    }}
                >
                    Terms of Service
                </TermsLink>

                <TermsLink
                    href="https://about.soar.earth/privacy"
                    target="_blank"
                    onClick={() => {
                        Analytics.Event('LandingPage', 'Clicked Footer Privacy policy');
                    }}
                >
                    Privacy Policy
                </TermsLink>
            </TermsContainer>

            <SocialLinks />
        </React.Fragment>
    );
};

export default LogoAndCopyright;

const SoarLogo = styled.img`
    margin: -8px auto 12px;
    display: block;
`;

const Copyright = styled.span`
    color: white;
`;

const TermsContainer = styled.div`
    margin-top: 12px;
`;

const FooterLink = styled.a`
    margin: 0 auto;
    display: block;
    color: white;
    text-decoration: none;
    text-align: left;

    &:hover {
        color: #edd946;
        text-decoration: none;
    }
`;

const TermsLink = styled(FooterLink)`
    margin-right: 30px;
    margin-left: 30px;
    display: inline-block;

    &:hover {
        color: #edd946;
        text-decoration: none;
    }
`;
