import React, { useState } from 'react';
import styled from 'styled-components';
import ApiListings from '../../../api/api-listings';
import { ListingDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import { StyledButton } from '../../Shared/styled-button';

interface PurchaseListingDownloadButtonProps {
    listing: ListingDTO;
}

const PurchaseListingDownloadButton = (props: PurchaseListingDownloadButtonProps) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleClickDownload = () => {
        Analytics.Event('My Dashboard', 'Clicked transaction history download image', props.listing.id);
        setIsDownloading(true);

        ApiListings.getDownloadUrl(props.listing.id)
            .then((presignedDownloadUrl) => {
                const url = presignedDownloadUrl.presignedUrl;
                const filename = presignedDownloadUrl.filename;

                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    const urlCreator = window.URL || window.webkitURL;
                    const imageUrl = urlCreator.createObjectURL(this.response);
                    const tag = document.createElement('a');
                    tag.href = imageUrl;
                    tag.download = filename;
                    document.body.appendChild(tag);
                    tag.click();
                    document.body.removeChild(tag);

                    // Wait a second while the browser allocates memory for file browser dialog
                    setTimeout(() => {
                        setIsDownloading(false);
                    }, 1000);
                };
                xhr.send();
            })
            .catch(() => {
                // TODO: Error handling of XHR request or getDownloadUrl request
                setIsDownloading(false);
            });
    };

    if (isDownloading) {
        return (
            <CustomPurchaseButton disabled>
                DOWNLOADING
                <i className="fa fa-spinner fa-spin" />
            </CustomPurchaseButton>
        );
    } else {
        return (
            <CustomPurchaseButton onClick={() => handleClickDownload()}>
                DOWNLOAD <i className="fa fa-download" />
            </CustomPurchaseButton>
        );
    }
};

export default PurchaseListingDownloadButton;

const CustomPurchaseButton = styled(StyledButton)`
    margin: 5px auto;
    display: inline-block;
    font-size: 12px;
    width: max-content;

    i {
        margin: 3px;
    }
`;
