import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ApiAutocomplete from '../../api/api-autocomplete';
import SearchBarAutocompleteResult from './search-bar-autocomplete-result';
import { getSearchTermsFromLocalStorage, removeSearchTermFromLocalStorage } from './search-term-localstorage';

interface SearchBarAutocompleteProps {
    searchInput: string;
    onSelectAutocomplete: (autocomplete: Autocomplete) => void;
}

const defaultRecommendedSearchTerms = [
    'Climate',
    'Conflict Zone',
    'Aerial Imagery',
    'Spaceport',
    'Environment',
    'Hurricane',
    'Historical',
    'Topographic',
    'Imagery',
];

export interface Autocomplete {
    title: string;
    type: 'search' | 'previousResult' | 'listing' | 'recommended';
}

const SearchBarAutocomplete = (props: SearchBarAutocompleteProps) => {
    const [rawAutocompleteResults, setRawAutocompleteResults] = useState<Autocomplete[]>([]);
    const [autocompleteResults, setAutocompleteResults] = useState<Autocomplete[]>([]);

    useEffect(() => {
        ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
        if (props.searchInput.length > 3) {
            ApiAutocomplete.autocomplete(props.searchInput, ['SEARCH', 'LISTING']).then((res) => {
                setRawAutocompleteResults(
                    res.map((r) => {
                        return {
                            title: r.title,
                            type: r.type === 'SEARCH' ? 'search' : 'listing',
                        };
                    })
                );
            });
        } else {
            setRawAutocompleteResults([]);
        }
    }, [props.searchInput]);

    useEffect(() => {
        // Try and use autocomplete results first
        const autocompleteResults = rawAutocompleteResults.slice(0, 8);
        if (autocompleteResults.length == 8) {
            setAutocompleteResults(autocompleteResults);
            return;
        }

        // Fill with previously used search terms from local storage
        const missingResults = 8 - autocompleteResults.length;
        const previousSearchTerms = getSearchTermsFromLocalStorage()
            .filter((t) => {
                return !autocompleteResults.some((r) => r.title === t.searchTerm);
            })
            .slice(0, missingResults);
        const previousSearchResults: Autocomplete[] = previousSearchTerms.map((t) => {
            return {
                title: t.searchTerm,
                type: 'previousResult',
            };
        });

        const mergedResults = [...autocompleteResults, ...previousSearchResults];
        if (mergedResults.length == 8) {
            setAutocompleteResults(mergedResults);
            return;
        }

        // Fill with default recommended searches (such as when the user hasn't filled enough search terms in local storage)
        const missingMergedResults = 8 - mergedResults.length;
        const recommendedSearchTerms = defaultRecommendedSearchTerms
            .filter((t) => {
                return !mergedResults.some((r) => r.title === t);
            })
            .slice(0, missingMergedResults);
        const recommendedResults: Autocomplete[] = recommendedSearchTerms.map((t) => {
            return {
                title: t,
                type: 'recommended',
            };
        });
        const finalResults = [...mergedResults, ...recommendedResults];
        setAutocompleteResults(finalResults);
    }, [rawAutocompleteResults]);

    const removeAutocompleteResult = (autocomplete: Autocomplete) => {
        // List of defaultRecommendedSearchTerms not in autocompleteResults
        const currentAutocomplete = autocompleteResults.map((t) => t.title);
        const notInAutocompleteResults = defaultRecommendedSearchTerms.find((t) => !currentAutocomplete.includes(t));

        const placeholderRemainingAutocomplete: Autocomplete = {
            title: notInAutocompleteResults || '',
            type: 'recommended',
        };

        setAutocompleteResults((current) => [
            ...current.filter((r) => !r.title.includes(autocomplete.title)),
            placeholderRemainingAutocomplete,
        ]);
    };

    return (
        <Container data-testid="search-bar">
            {autocompleteResults.map((r) => {
                return (
                    <SearchBarAutocompleteResult
                        key={`${r.title}-${r.type}`}
                        autocomplete={r}
                        onSelectAutocomplete={props.onSelectAutocomplete}
                        onDeletePreviousSearchFromLocalStorage={(autocomplete: Autocomplete) => {
                            removeSearchTermFromLocalStorage(autocomplete.title);
                            removeAutocompleteResult(autocomplete);
                        }}
                    />
                );
            })}
        </Container>
    );
};

export default SearchBarAutocomplete;

const AnimateOpen = keyframes`
  from {
    height: 0;
  }

  to {
    height: 248px;  //31 * 8
  }
`;

const Container = styled.div`
    position: absolute;
    background: white;
    top: 40px;
    width: calc(100% + 4px);
    height: 0;
    margin: -2px;
    padding: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: ${(props) => `2px solid ${props.theme.color.yellow}`};
    border-top: none;
    overflow-y: hidden;
    animation: ${AnimateOpen} 100ms ease-in-out;
    animation-fill-mode: forwards;
    z-index: 99999;
`;
