import { useSelector } from 'react-redux';
import UploadMapWorkflow from './UploadWorkFlow/upload-map-workflow';
import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { LoginModalMode } from '../../../Registration/login-enum';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';

const SideDrawerUpload = () => {
    const isLoggedIn = useSelector(selectLoggedIn);

    if (!isLoggedIn)
        return (
            <LoginRegisterDialog
                isOpen={true}
                initialMode={LoginModalMode.LOGIN}
                onClose={(isLoggedIn) => {
                    Analytics.Event('Upload', 'Closed Login');
                    if (!isLoggedIn) {
                        UriHelper.navigateToPath('/maps');
                    }
                }}
            />
        );

    return <UploadMapWorkflow />;
};

export default SideDrawerUpload;
