import { Helmet } from 'react-helmet-async';
import Api from '../../api/api';

interface SeoTemplateProps {
    title: string; //  50-60 characters
    description: string; // 150-160 characters
    canonicalPath?: string;
    imageUrl: string;
}

const subdomain = Api.getSubdomain();

const SeoHelmetTemplate = (props: SeoTemplateProps) => {
    const { title, description, imageUrl } = props;
    const canonicalUrl = `https://${subdomain}${props.canonicalPath || ''}`;
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={canonicalUrl} />
            <meta name="description" content={description} />
            <meta name="author" content="Soar" />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:site_name" content="Soar | Discover your Earth" />

            {/* 
            <meta property="og:image:width" content=${IMAGE_WIDTH}>
            <meta property="og:image:height" content=${IMAGE_HEIGHT}>
            */}

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@Soar_Earth" />
            <meta name="twitter:creator" content="@Soar_Earth" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:text:title" content={title} />
        </Helmet>
    );
};

export default SeoHelmetTemplate;
