import React from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';
import NoImageryModal from '../Imagery/Shared/no-imagery-modal';
import profileIconUrl from '../profile-icon-url';
import Masonry from 'react-masonry-css';
import { useWindowSize } from '@react-hook/window-size';
import BookmarkMasonaryCard from './bookmark-masonary-card';

interface BookmarkMasonryProps {
    listings: ListingDTO[];
    dataTestId?: string;
}

const MAX_CARD_WIDTH = 300;
const SIDEBAR_WIDTH = 350;

const BookmarkMasonary = (props: BookmarkMasonryProps) => {
    const [windowWidth] = useWindowSize();

    const masonryBreakPoints = (windowWidth: number): number => {
        const availableWidth = windowWidth - SIDEBAR_WIDTH;
        const numCards = Math.floor(availableWidth / MAX_CARD_WIDTH);
        return numCards;
    };

    const selectableListings: ListingDTO[] = props.listings.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    if (selectableListings.length === 0) {
        return (
            <React.Fragment>
                <NoImageryModal
                    icon={profileIconUrl.noImagesUrl}
                    title={"It looks like you haven't bookmarked any maps"}
                    text={'Any maps that you bookmark will be available here for quick access'}
                    mobileText={''}
                    button={() => UriHelper.navigateToPath('/maps')}
                    buttonText={'Find Maps To Bookmark'}
                />
            </React.Fragment>
        );
    }

    return (
        <MasonryComponent
            breakpointCols={masonryBreakPoints(windowWidth)}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
        >
            {selectableListings.map((listing, index) => (
                <BookmarkMasonaryCard
                    dataTestId={props.dataTestId && `${props.dataTestId}-card`}
                    key={`bookmark-card-${index}`}
                    listing={listing}
                />
            ))}
        </MasonryComponent>
    );
};

export default BookmarkMasonary;

const MasonryComponent = styled(Masonry)`
    &.masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        width: auto;
    }

    &.masonry-grid_column {
        background-clip: padding-box;
        will-change: transform; /* Enable hardware acceleration for smoother animations */
    }
`;
