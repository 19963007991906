import { LatLng, LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiSubdomain from '../../../../../api/api-subdomain';
import { FileInfo, ListingMetadata } from '../../../../../api/model';
import FileUtil from '../../../../../lib/file-util';
import GeoUtil from '../../../../../lib/geo-util';
import SoarHelper from '../../../../../lib/soar-helper';
import UriHelper from '../../../../../lib/uri-helper';
import Analytics from '../../../../../lib/user-analytics';
import { selectMyListings } from '../../../../../store/Account/selectors';
import { actionFlyToOnMap } from '../../../../../store/App/actions';
import { actionReuploadMapId, actionUploadToSuperMap } from '../../../../../store/Dashboard/actions';
import { selectReuploadMapId, selectUploadToSuperMapStatusReport } from '../../../../../store/Dashboard/selectors';
import { actionAddDroneImageForUpload, actionClearDroneImageUpload } from '../../../../../store/Map/Uploads/actions';
import { SideDrawerMode } from '../../../../../store/SideDrawer/model';
import UploadMapChoose from './upload-map-choose';
import UploadMapComplete from './upload-map-complete';
import UploadMapImageLocation from './upload-map-image-location';
import UploadMapImageNoLocation from './upload-map-image-no-location';
import UploadMapMetadata from './upload-map-metadata';

export enum Workflow {
    ChooseFile,
    Location,
    NoLocation,
    Metadata,
    Upload,
}

// TODO this should be refactored to use routes like the rest of the app
const UploadMapWorkflow = () => {
    const dispatch = useDispatch();
    const clearDroneImageUpload = () => dispatch(actionClearDroneImageUpload());

    const flyTo = useCallback((bounds: LatLngBounds) => dispatch(actionFlyToOnMap(bounds)), [dispatch]);

    const [workflow, setWorkflow] = useState<Workflow>(Workflow.ChooseFile);
    const [file, setFile] = useState<File>();
    const [worldfile, setWorldfile] = useState<File | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const [title, setTitle] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [tags, setTags] = useState<string[] | undefined>(undefined);
    const [categories, setCategories] = useState<string[]>([]);

    const [exif, setExif] = useState<any>(undefined); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [latlng, setLatlng] = useState<LatLng | undefined>(undefined);
    const [positions, setPositions] = useState<LatLng[] | undefined>(undefined);

    //FOR REUPLOAD listing
    const reuploadMapId = useSelector(selectReuploadMapId);
    const myListings = useSelector(selectMyListings);
    const uploadStatusReport = useSelector(selectUploadToSuperMapStatusReport);

    useEffect(() => {
        const sideDrawerElement = document.getElementById('side-drawer-container');
        const sideDrawerCloseIcon = document.getElementById('side-drawer-close-icon');

        if (!sideDrawerElement || !sideDrawerCloseIcon) return;

        sideDrawerElement.style.width = '';
        sideDrawerElement.style.minWidth = '';
        sideDrawerCloseIcon.style.left = '';

        if (Workflow.Location === workflow) {
            sideDrawerElement.style.width = '350px';
            sideDrawerElement.style.minWidth = '350px';
            sideDrawerCloseIcon.style.left = 'calc(350px - 35px)';
        } else if (Workflow.Upload === workflow) {
            sideDrawerElement.style.width = '350px';
            sideDrawerElement.style.minWidth = '350px';
            sideDrawerCloseIcon.style.left = 'calc(350px - 35px)';
        } else if (Workflow.Metadata === workflow) {
            sideDrawerElement.style.width = '50vw';
            sideDrawerElement.style.minWidth = '405px';
            sideDrawerCloseIcon.style.left = 'calc(50vw - 35px)';
        } else {
            sideDrawerElement.style.width = '405px';
            sideDrawerElement.style.minWidth = '405px';
            sideDrawerCloseIcon.style.left = 'calc(405px - 35px)';
        }

        sideDrawerElement.style.transition = 'width 0.3s ease';

        return () => {
            sideDrawerElement.style.width = '';
            sideDrawerElement.style.minWidth = '';
            sideDrawerCloseIcon.style.left = '';
            sideDrawerElement.style.transition = '';
        };
    }, [workflow]);

    useEffect(() => {
        if (reuploadMapId && myListings) {
            const listing = myListings.filter((l) => l.id.toString() === reuploadMapId && l.review === 'REJECTED')[0];
            if (listing) {
                setTitle(listing.title);
                setDescription(listing.description);
                setTags(listing.tags);
                setCategories(listing.categories);
                return;
            }
        }
        //remove reupload if not in myListings
        if (reuploadMapId) {
            UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
        }
    }, [reuploadMapId, myListings, dispatch]);

    useEffect(() => {
        if (reuploadMapId && uploadStatusReport?.status === 100) {
            Analytics.Event('Upload', 'ReUploaded', `${reuploadMapId}`);
            ApiSubdomain.deleteListing(Number(reuploadMapId)).then(() => actionReuploadMapId(undefined));
        }
    }, [reuploadMapId, uploadStatusReport]);

    useEffect(() => {
        return () => {
            dispatch(actionClearDroneImageUpload());
        };
        // eslint-disable-next-line
    }, []);

    const showFileToMapLocation = (file: File, positions: number[][]) => {
        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
                const imagePreviewUrl = reader.result as string;

                dispatch(actionAddDroneImageForUpload(imagePreviewUrl, positions));
            };
        };
        reader.readAsDataURL(file);
    };

    const setImageFromPosition = (file: File, position: LatLng) => {
        setLatlng(position);

        const bounds = position.toBounds(300);
        flyTo(bounds);

        const positions = [
            new LatLng(bounds.getSouthEast().lat, bounds.getSouthEast().lng),
            new LatLng(bounds.getSouthWest().lat, bounds.getSouthWest().lng),
            new LatLng(bounds.getNorthWest().lat, bounds.getNorthWest().lng),
            new LatLng(bounds.getNorthEast().lat, bounds.getNorthEast().lng),
            new LatLng(bounds.getSouthEast().lat, bounds.getSouthEast().lng),
        ];
        setPositions(positions);

        const latlngs = [
            [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
            [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
            [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
            [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
            [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        ];
        showFileToMapLocation(file, latlngs);
    };

    const onSelectFile = (file: File, worldFile: File | undefined) => {
        Analytics.Event('Upload', 'Added File', file.type);
        if (worldFile) {
            setWorkflow(Workflow.Metadata);
        } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
            if (file.size < 500000) {
                setError('Your image dimensions are too small.  The image must be at least 0.5 mega pixels');
                return;
            }

            if (file.size > 20000000) {
                setError('File should be less than 20MB in size');
                return;
            }
            FileUtil.readXmpData(file).then((result) => {
                if (result.exif && result.exif.PixelXDimension && result.exif.PixelYDimension) {
                    if (result.exif.PixelXDimension * result.exif.PixelYDimension < 500000) {
                        setError('File should have a resolution of at least 0.5 mega pixels');
                        return;
                    }
                }

                setExif(result.exif);

                const position = FileUtil.parseLocation(result.exif);
                const imageBounds = FileUtil.calculateBoundingBoxFromExifData(result.xmp, result.exif);
                if (imageBounds) {
                    const positions = GeoUtil.toLeafletPositionsClock(imageBounds);
                    const bounds = new LatLngBounds(positions[0], positions[1])
                        .extend(positions[2])
                        .extend(positions[3])
                        .extend(positions[4]);

                    flyTo(bounds);

                    setTimeout(() => {
                        showFileToMapLocation(file, GeoUtil.toDistortablePositionsClock(positions));
                    }, 3000);

                    setLatlng(bounds.getCenter());

                    const newPositions = imageBounds.map((p) => {
                        return new LatLng(p[1], p[0]);
                    });
                    setPositions(newPositions);
                    setWorkflow(Workflow.Location);
                } else if (position && (position.lat || position.lng)) {
                    setImageFromPosition(file, position);
                    setWorkflow(Workflow.Location);
                } else {
                    setWorkflow(Workflow.NoLocation);
                }
            });
        } else {
            setWorkflow(Workflow.Metadata);
        }
    };

    const clearMetadata = () => {
        setTitle(undefined);
        setDescription(undefined);
        setTags(undefined);
        setCategories([]);
    };

    const submitMap = async (
        mapTitle: string,
        mapDescription: string,
        mapTags: string[],
        mapCategories: string[],
        hasCheckedTerms: boolean,
        attachment?: File
    ) => {
        if (!file) {
            throw new Error('Invalid workflow state. File does not exist during DroneImageWorkflow.Confirm');
        }

        const listingMetadata: ListingMetadata = {
            title: mapTitle,
            description: mapDescription,
            tags: mapTags,
            categories: mapCategories,
            tc: hasCheckedTerms,
        };
        let fileInfo: FileInfo | undefined = undefined;

        if (!worldfile && SoarHelper.isImageListingType(file)) {
            if (!positions) {
                throw new Error('Invalid workflow state. Positions does not exist during DroneImageWorkflow.Confirm');
            }

            if (!latlng) {
                throw new Error('Invalid workflow state. Latlng does not exist during DroneImageWorkflow.Confirm');
            }

            const distoredGeometry = GeoUtil.latLngListsToWKT(positions as unknown as LatLng[]);
            fileInfo = {
                filehash: await FileUtil.getMd5Hash(file),
                geometryWKT: 'POINT(' + latlng.lng + ' ' + latlng.lat + ')',
                geohash: FileUtil.computeGeohash(latlng),
                exif: JSON.stringify(exif),
                contentType: file.type,
                distoredGeometry,
            };
        }
        Analytics.Event('Upload', 'Uploading listing', mapTitle);
        dispatch(actionUploadToSuperMap(file, worldfile, listingMetadata, fileInfo, attachment));
        clearMetadata();
    };

    switch (workflow) {
        case Workflow.ChooseFile:
            return (
                <UploadMapChoose
                    onSelectFile={(file, worldFile) => {
                        setFile(file);
                        setWorldfile(worldFile);
                        setError('');
                        onSelectFile(file, worldFile);
                    }}
                    handleSetError={setError}
                    error={error}
                />
            );

        case Workflow.NoLocation:
            if (!file) return null;
            return (
                <UploadMapImageNoLocation
                    onClickBack={() => {
                        setFile(undefined);
                        clearDroneImageUpload();
                        setWorkflow(Workflow.ChooseFile);
                    }}
                    onClickConfirmPosition={(position: LatLng) => {
                        setImageFromPosition(file, position);
                        setWorkflow(Workflow.Location);
                    }}
                />
            );

        case Workflow.Location:
            return (
                <UploadMapImageLocation
                    onClickBack={() => {
                        setWorkflow(Workflow.ChooseFile);
                        setPositions(undefined);
                        clearDroneImageUpload();
                        setFile(undefined);
                    }}
                    onSubmit={(updatedCorners: number[][] | undefined) => {
                        if (updatedCorners && updatedCorners.length > 0 && updatedCorners[0].length > 0) {
                            const newPositions = updatedCorners.map((p) => {
                                return new LatLng(p[1], p[0]);
                            });
                            setPositions(newPositions);
                        }

                        setWorkflow(Workflow.Metadata);
                    }}
                />
            );

        case Workflow.Metadata:
            if (!file) return null;
            return (
                <UploadMapMetadata
                    onClickBack={() => {
                        if (!worldfile && SoarHelper.isImageListingType(file)) {
                            setWorkflow(Workflow.Location);
                        } else {
                            setWorkflow(Workflow.ChooseFile);
                        }
                    }}
                    submitted={(
                        title: string,
                        description: string,
                        tags: string[],
                        categories,
                        hasCheckedTerms,
                        attachment
                    ) => {
                        setTitle(title);
                        setDescription(description);
                        setTags(tags);
                        setCategories(categories);
                        setWorkflow(Workflow.Upload);
                        submitMap(title, description, tags, categories, hasCheckedTerms, attachment);
                    }}
                    fileName={file.name}
                    title={title}
                    description={description}
                    tags={tags}
                    categories={categories}
                />
            );

        case Workflow.Upload:
            if (!file) {
                throw new Error('Invalid workflow state. File is undefined at Workflow.Upload');
            }
            // updateHeaderText(true, false);
            return (
                <UploadMapComplete
                    onClickBack={() => {
                        clearDroneImageUpload();
                        clearMetadata();
                        setWorkflow(Workflow.ChooseFile);
                        UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
                    }}
                    file={file}
                    onUploadComplete={() => 'udpate header text?'}
                />
            );

        default:
            return <React.Fragment />;
    }
};

export default UploadMapWorkflow;
