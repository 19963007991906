import L, { LeafletMouseEvent } from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import MilitaryMarker, { defaultMilitaryMarkerOptions } from './military-marker';
import { v4 as uuidv4 } from 'uuid';
import { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';
import { createMilitryMarkerSVGIcon } from './military-marker-util';
import { INITIAL_SIDC } from './MilSymbol/values';

interface MilitaryMarkerAnnotationBuilderProps {
    onCreateMarker: (marker: MilitaryMarker) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const createMilitaryMarkerBuilderElement = (
    props: MilitaryMarkerAnnotationBuilderProps,
    context: LeafletContextInterface
) => {
    const defaultSidc = store.getState().annotationDomain.present.militaryMarkerReducer.sidc || INITIAL_SIDC;
    const initialMarkerIcon = createMilitryMarkerSVGIcon(defaultSidc);
    const marker = new L.Marker(new L.LatLng(0, 0), { icon: initialMarkerIcon });
    const markerElement = createElementObject<L.Marker, MilitaryMarkerAnnotationBuilderProps>(marker, context);

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancelBuild();
            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            context.map.dragging.enable();
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
            document.removeEventListener('keydown', onKeyDown);
        }
    };

    const onMouseDown = (e: LeafletMouseEvent) => {
        markerElement.instance.setLatLng(e.latlng);
        context.map.dragging.enable();
        document.removeEventListener('keydown', onKeyDown);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.off('mousemove', onMouseMove);
        context.map.off('mousedown', onMouseDown);

        const marker: MilitaryMarker = {
            annotationType: 'MilitaryMarker',
            id: uuidv4(),
            position: markerElement.instance.getLatLng(),
            sidc: defaultSidc,
            zoomBase: context.map.getZoom(),
            options: {
                ...defaultMilitaryMarkerOptions,
            },
            zIndex: props.zIndex || defaultZIndex,
        };

        props.onCreateMarker(marker);
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        markerElement.instance.setLatLng(e.latlng);
    };

    markerElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.on('mousemove', onMouseMove);
        context.map.on('mousedown', onMouseDown);
    });

    document.addEventListener('keydown', onKeyDown);

    return markerElement;
};

const useMilitaryMarkerBuilder = createElementHook<
    L.Marker,
    MilitaryMarkerAnnotationBuilderProps,
    LeafletContextInterface
>(createMilitaryMarkerBuilderElement);

const MilitaryMarkerBuilder = (props: MilitaryMarkerAnnotationBuilderProps) => {
    const context = useLeafletContext();
    const markerBuilder = useMilitaryMarkerBuilder(props, context);
    useLayerLifecycle(markerBuilder.current, context);
    return null;
};

export default MilitaryMarkerBuilder;
