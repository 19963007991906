import React, { useRef } from 'react';
import { SubDomainType } from '../../api/model';
import styled from 'styled-components';
import { PlusDetails, PlusHeader, PlusUsers, PlusWhatIs, PlusContactSection } from './PlusPage';
import PlusPricingCards from './PlusPage/plus-pricing-cards';
import { isMobileSafari } from 'react-device-detect';

interface SubdomainPricingProps {
    chooseSubdomainType: (subdomainType: SubDomainType) => void;
}

const SubdomainPricing = (props: SubdomainPricingProps) => {
    const pricingCards = useRef<HTMLDivElement>(null);

    const scrollToPricingCards = () => {
        if (pricingCards.current) {
            pricingCards.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <SubdomainPricingContainer paddingBottom={isMobileSafari}>
            <PlusHeader scrollToPricingCards={() => scrollToPricingCards()} />
            <PlusWhatIs />
            <PlusDetails />
            <PlusUsers />
            <div ref={pricingCards}>
                <PlusPricingCards handleSelectPrice={props.chooseSubdomainType} />
            </div>
            <PlusContactSection />
        </SubdomainPricingContainer>
    );
};

export default SubdomainPricing;

const SubdomainPricingContainer = styled.div<{ paddingBottom?: boolean }>`
    background-color: #191a1a;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
    padding-bottom: 50px;

    @media only screen and (max-width: 600px) {
        position: absolute;
        touch-action: auto;
        overflow-x: hidden;
        overflow-y: auto !important;
        overscroll-behavior-y: auto !important;
        padding: 0px;
        width: 100vw;
        // Mobile safari has the search on the bottom, we add padding to compensate
        padding-bottom: ${(props) => props.paddingBottom && '100px'};
    }
`;
