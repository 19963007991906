import React, { useState } from 'react';
import styled from 'styled-components';
import ColorHelper from '../Popup/color-helper';
import OpacitySlider from '../Popup/opacity-slider';
import FillColorCustomSelection from './fill-color-custom-selection';
import FillPattern from './fill-pattern';
import ToolbarModal, { Divider, Text } from './toolbar-modal';

interface FillColorModalProps {
    title: string;
    onClose: () => void;
    toolbarButtonRef: React.RefObject<HTMLButtonElement>;
    selectedColor: string;
    onSelectColor: (color: string) => void;
    opacity?: number;
    onSetOpacity?: (opacity: number) => void;
    showFillPattern?: boolean;
    selectedFillPattern?: string;
    onSetFillPatternUrl?: (fillPatternUrl: string) => void;
}

const FillColorModal = (props: FillColorModalProps) => {
    const [showCustomColorPicker, setShowCustomColorPicker] = useState(false);
    const [opacityRequiresInvalidation, setOpacityRequiresInvalidation] = useState(false);
    const defaultColors = ['#eed926', '#3388ff', '#e75554', '#f99441', '#25b158', '#54cefe', '#a150d8', '#000000'];

    return (
        <ToolbarModal
            key={props.title}
            toolbarButtonRef={props.toolbarButtonRef}
            title={props.title}
            onClose={props.onClose}
        >
            <Text key="text-simple">Simple</Text>
            <ColorListContainer key="container-color-select">
                {defaultColors.map((color, index) => (
                    <ColorSwatchContainer
                        key={`${index}-color`}
                        selected={props.selectedColor.toLowerCase() === color.toLowerCase()}
                        onClick={() => props.onSelectColor(color)}
                        data-testid={`simple-color-select-swatch`}
                    >
                        <Color color={color} />
                    </ColorSwatchContainer>
                ))}
            </ColorListContainer>

            <Divider key="div-custom-color" />

            {showCustomColorPicker ? (
                <React.Fragment key="user-custom">
                    <Text key="text-user-custom">Custom</Text>

                    <FillColorCustomSelection
                        key="container-user-custom-color"
                        selectedColor={props.selectedColor}
                        onSelectColor={(color) => {
                            props.onSelectColor(color);
                            setShowCustomColorPicker(false);
                            ColorHelper.addUserColor(color);
                        }}
                        onClose={() => setShowCustomColorPicker(false)}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment key="user-custom-new">
                    <Text key="text-new-custom">Custom</Text>
                    <ColorListContainer key="container-new-custom-color">
                        <ColorSwatchContainer selected={false} onClick={() => setShowCustomColorPicker(true)}>
                            <AddNewColorIcon className="fa fa-plus" />
                        </ColorSwatchContainer>
                        {[...ColorHelper.getUserColors()].slice(0, 7).map((color, idx) => (
                            <ColorSwatchContainer
                                key={`user-color-${idx}`}
                                selected={props.selectedColor.toLowerCase() === color.toLowerCase()}
                                onClick={() => props.onSelectColor(color)}
                                data-testid="custom-color-select-swatch"
                            >
                                <Color color={color} />
                            </ColorSwatchContainer>
                        ))}
                    </ColorListContainer>
                </React.Fragment>
            )}

            {props.showFillPattern ? (
                <React.Fragment key="fill-pattern">
                    <Divider key="div-fill-pattern" />
                    <Text key="text-fill-pattern">Fill pattern</Text>
                    <FillPattern
                        key="container-custom-fill"
                        fillPattern={props.selectedFillPattern || 'none'}
                        onSetFillPattern={(patternFillUrl) => {
                            if (props.onSetFillPatternUrl) {
                                // The opacity slider uses a ref to handle the opacity value
                                // We need to invalidate it when the fill pattern changes or the UI won't update correctly
                                props.onSetFillPatternUrl(patternFillUrl);
                                setOpacityRequiresInvalidation(true);
                                requestAnimationFrame(() => {
                                    setOpacityRequiresInvalidation(false);
                                });
                            }
                        }}
                        selectedColor={props.selectedColor}
                    />
                </React.Fragment>
            ) : null}

            {props.opacity && props.onSetOpacity && (
                <React.Fragment key="transparency">
                    <Divider key="div-transparency" />
                    <Text key="text-transparency">Transparency</Text>
                    <ColorListContainer key="container-transparency">
                        <OpacitySlider
                            color={props.selectedColor}
                            invalidate={opacityRequiresInvalidation}
                            opacity={props.opacity || 0.2}
                            onSelectOpacity={(opacity) => {
                                if (props.onSetOpacity) {
                                    props.onSetOpacity(opacity);
                                }
                            }}
                        />
                    </ColorListContainer>
                </React.Fragment>
            )}
        </ToolbarModal>
    );
};

export default FillColorModal;

const ColorListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    gap: 2px;
    margin: 0;
`;

const ColorSwatchContainer = styled.div<{ selected: boolean }>`
    width: 32px;
    height: 32px;
    border: ${(props) =>
        props.selected ? '1px solid rgba(255, 255, 255, 0.7)' : '1px solid rgba(255, 255, 255, 0.2)'};
    border-radius: 3px;
    cursor: pointer;
`;

const Color = styled.div<{ color: string }>`
    height: 18px;
    width: 18px;
    margin: 6px 6px;
    padding: 0;
    border-radius: 9px;
    background-color: ${(props) => props.color};
    border: 1px solid rgba(255, 255, 255, 0.5);
`;

const AddNewColorIcon = styled.i`
    margin: 8px 8px;
    font-size: 1rem;
    padding: 0;
    color: rgba(255, 255, 255, 0.7);
`;
