import { TemporalCategory } from './temporal-category-control';
import styled from 'styled-components';

interface TemporalCategoryPillProps {
    category: TemporalCategory;
    inverted?: boolean;
    fontSize?: string;
}

const TemporalCategoryPill = ({ category, inverted, fontSize }: TemporalCategoryPillProps) => {
    return (
        <TemporalCategoryPillContainer
            className={inverted ? 'inverted' : ''}
            color={category.color}
            fontColor={category.fontColor}
            title={category.title}
            fontSize={fontSize}
        >
            {category.label}
        </TemporalCategoryPillContainer>
    );
};

export default TemporalCategoryPill;

const TemporalCategoryPillContainer = styled.div<{
    color: string;
    fontColor?: string;
    fontSize?: string;
}>`
    background-color: ${(props) => (props.color ? props.color : 'transparent')};
    color: ${(props) => (props.fontColor ? props.fontColor : 'black')};
    border-style: solid;
    border-width: 0.5px;
    border-color: transparent;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
    font-weight: 600;
    padding: 2px 6px 1px 6px;
    border-radius: 6px;
    width: fit-content;
    text-transform: uppercase;

    &.inverted {
        background-color: transparent;
        border-color: ${(props) => (props.color ? props.color : 'white')};
        color: ${(props) => (props.color ? props.color : 'white')};
    }
`;
