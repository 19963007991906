import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';

import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import MapSearchDrawLabel from './ListingsDrawer/map-search-draw-label';
import { SideDrawerControlProps } from './side-drawer-util';

interface SideDrawerSearchCloseLabelProps {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
    showInput: boolean;
    setShowInput: (showInput: boolean) => void;
    drawerControl: SideDrawerControlProps;
}

const SideDrawerSearchCloseLabel = ({
    expanded,
    setExpanded,
    showInput,
    setShowInput,
    drawerControl,
}: SideDrawerSearchCloseLabelProps) => {
    const { hasCloseIcon, closeIconPosition, closeToPath, hasBackButton, hasMinimizeButton } = drawerControl;
    const activeListing = useSelector(selectActiveAllMap);

    useEffect(() => {
        if (!activeListing) {
            setShowInput(true);
        }
    }, [activeListing, setShowInput]);

    if (activeListing && showInput) {
        return (
            <MapSearchDrawLabel
                expanded={expanded}
                toggleDrawer={() => setExpanded(true)}
                mapTitle={activeListing?.title || 'Awesome Map'}
            />
        );
    }

    return (
        <React.Fragment>
            {hasBackButton && activeListing ? (
                <BackButton
                    onClick={() => {
                        Analytics.Event('Side Drawer', `Clicked back button`);

                        // There are two choices what to do here. Either close the map and leave the AOI as is
                        const pos = UriHelper.tryGetParam('pos');
                        if (pos) {
                            UriHelper.navigateToPath(`/maps?pos=${pos}`);
                        } else {
                            UriHelper.navigateToPath('/maps');
                        }

                        // or return the user to where they were before they opened the map
                        //history.back();
                    }}
                >
                    <BackButtonIcon className="fa fa-chevron-left" />
                    Back
                </BackButton>
            ) : null}
            {!hasCloseIcon ? (
                <CloseIcon
                    id="side-drawer-close-icon"
                    onClick={() => {
                        Analytics.Event('Side Drawer', `Clicked close to  ${closeToPath}`);
                        UriHelper.navigateToPath(closeToPath);
                    }}
                    className="fa fa-close"
                    positionLeft={closeIconPosition}
                />
            ) : null}
            {hasMinimizeButton && activeListing ? (
                <MinimizeIcon
                    onClick={() => {
                        Analytics.Event('Side Drawer', `Clicked minimize`);
                        setExpanded(false);
                    }}
                    className="fa fa-compress-alt"
                    title="Minimize"
                    positionLeft={closeIconPosition}
                />
            ) : null}
        </React.Fragment>
    );
};

export default SideDrawerSearchCloseLabel;

const BackButtonIcon = styled.i`
    font-size: 18px;
    color: white;
    margin-right: 5px;
`;

const BackButton = styled.button`
    color: white;
    border: none;
    background: transparent;
    position: absolute;
    z-index: 99999;
    height: 32px;
    left: 90px;
    top: 84px;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.normal};

    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        outline: none;
    }
`;

const CloseIcon = styled.i<{ positionLeft: string }>`
    float: right;
    padding: 10px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    position: absolute;
    z-index: 99999;
    height: 40px;
    left: ${(props) => props.positionLeft};
    top: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const MinimizeIcon = styled.i<{ positionLeft: string }>`
    float: right;
    padding: 10px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    position: absolute;
    z-index: 99999;
    width: 40px;
    height: 40px;
    top: 80px;
    left: calc(${(props) => props.positionLeft} - 4px);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    animation: fadeInAnimation ease 0.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;
