import L from 'leaflet';

export const translateLatLngBounds = (
    bounds: L.LatLngBounds,
    startPoint: L.LatLng,
    endPoint: L.LatLng
): L.LatLngBounds => {
    const latDiff = startPoint.lat - endPoint.lat;
    const lngDiff = startPoint.lng - endPoint.lng;

    const north = bounds.getNorth() - latDiff;
    const south = bounds.getSouth() - latDiff;
    const west = bounds.getWest() - lngDiff;
    const east = bounds.getEast() - lngDiff;

    return new L.LatLngBounds(new L.LatLng(south, west), new L.LatLng(north, east));
};
