import { useState, useEffect, useCallback } from 'react';

/* Used to update the annotation when the user has finished the event that is causing the changes, rather than a continual update.
 *    @param onUpdated - Callback function to be called when the user has finished drawing the annotation.
 *    @returns handleUpdated - Callback function to be passed to the annotation tool.
 */

export const useDrawToolUpdated = (onUpdated: (current) => void) => {
    const [current, setCurrent] = useState(null);

    const handleMouseUp = useCallback(() => {
        if (current) {
            onUpdated(current);
            setCurrent(null);
        }
    }, [current, onUpdated]);

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseUp]);

    const handleUpdated = (annotation) => {
        setCurrent(annotation);
    };

    return handleUpdated;
};
