import * as React from 'react';
import { useState } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { DomainDTO } from '../../../api/model';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Constants from '../../../constants';
import ChangeCreditCard from './change-credit-card';
import DotsText from '../../Shared/dots-text';

interface BillingDetailsProps {
    domain: DomainDTO;
    invalidate: () => void;
}

const AccountBillingDetails: React.FC<BillingDetailsProps> = (props) => {
    const [showStripe, setShowStripe] = useState(false);

    if (!props.domain) return <DotsText text={'Loading details'} />;

    if (showStripe) {
        return (
            <StripeProvider apiKey={Constants.STRIPE_API_KEY}>
                <Elements>
                    <ChangeCreditCard
                        data-sentry-block
                        handleUpdatedCreditCard={() => {
                            setShowStripe(false);
                            props.invalidate();
                        }}
                        handleCloseChangeCreditCard={() => setShowStripe(false)}
                    />
                </Elements>
            </StripeProvider>
        );
    }

    if (props.domain?.subscription.nextPaymentAt) {
        return (
            <BillingDetailsContainer>
                <h4>Billing Details</h4>
                <i style={{ marginRight: '5px', marginTop: '3px' }} /> {props.domain.subscription.cardBrand} ending
                in&nbsp;
                <strong>{props.domain.subscription.cardLast4}</strong>
                Exp: {props.domain.subscription.cardExpMonth}/{props.domain.subscription.cardExpYear}
                <a
                    onClick={() => setShowStripe(true)}
                    style={{ color: 'yellow', paddingTop: '10px', paddingBottom: '10px', cursor: 'pointer' }}
                >
                    Change
                </a>
            </BillingDetailsContainer>
        );
    } else {
        return (
            <BillingDetailsContainer>
                {props.domain.status === 'TRIAL' && (
                    <React.Fragment>
                        <h4>Upgrade your account</h4>
                        <p>
                            Entering your billing information to prevent your account from being suspended once the free
                            trial is over.
                        </p>
                        <p>You will only start being charged once your free trial is over</p>
                        <BillingDetailsButton onClick={() => setShowStripe(true)}>UPGRADE</BillingDetailsButton>
                    </React.Fragment>
                )}
                {props.domain.status === 'ACTIVE' && props.domain.subscription.cancelAt && (
                    <React.Fragment>
                        <h4>Re-subscribe to your account</h4>
                        <p>Entering your billing information to re-subscribe to your account.</p>
                        <BillingDetailsButton onClick={() => setShowStripe(true)}>RE-SUBSCRIBE</BillingDetailsButton>
                    </React.Fragment>
                )}
            </BillingDetailsContainer>
        );
    }
};

export default AccountBillingDetails;

const BillingDetailsContainer = styled.div`
    margin-bottom: 20px;
    padding: 10px 0px;
    border-radius: 6px;
    background-color: rgb(0, 0, 0, 0.6);
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4,
    strong,
    a,
    p {
        color: white;
    }
`;

const BillingDetailsButton = styled(Button)`
    background-color: #eed926 !important;
    color: black !important;
    width: 250px;
`;
