import { toast } from 'react-toastify';
import ApiSocial from '../../../api/api-social';
import { actionTypes as at } from './constants';

export const actionFetchBookmarkedMaps = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.FETCH_BOOKMARKED_MAPS_ISLOADING,
            payload: true,
        });
        try {
            const bookmarkedMaps = await ApiSocial.userBookmarks();
            dispatch({
                type: at.FETCH_BOOKMARKED_MAPS_SUCCESS,
                payload: bookmarkedMaps,
            });
        } catch (err) {
            toast.error(err.message);
        } finally {
            dispatch({
                type: at.FETCH_BOOKMARKED_MAPS_ISLOADING,
                payload: false,
            });
        }
    };
};
