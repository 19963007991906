import { actionTypes as at } from './constants';
import { AppState } from '../../root-reducer';
import ApiOrder from '../../../api/api-order';
import { ORDER_TYPE } from './reducer';

export const actionSetActiveOrder = (order: ORDER_TYPE | undefined) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.ORDER_SET_ACTIVE,
            payload: order,
        });
    };
};

export const actionSetActiveOrderFetchById = (id: number) => {
    return async (dispatch, getState) => {
        const appState: AppState = getState();
        const orders = appState.tasksDomain.orderDetailsList;
        const order = orders[id];
        if (order) {
            dispatch(actionSetActiveOrder(order));
        } else {
            dispatch({
                type: at.FETCH_ORDER_BY_ID,
                payload: id,
            });
            dispatch(actionSetActiveOrder('FETCHING'));

            try {
                const order = await ApiOrder.getOrderDetails(id);
                dispatch({
                    type: at.FETCH_ORDER_BY_ID_SUCCESS,
                    payload: order,
                });

                dispatch(actionSetActiveOrder(order));
            } catch {
                dispatch({
                    type: at.FETCH_ORDER_BY_ID_ERROR,
                    payload: id,
                });
                dispatch(actionSetActiveOrder('ERROR'));
            }
        }
    };
};

export const actionFetchSatelliteOrders = () => {
    return async (dispatch, getState) => {
        const orders = await ApiOrder.getOrders();
        dispatch({ type: at.FETCH_ORDERS_SUCCESS, payload: orders });
    };
};
