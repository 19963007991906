import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import FillColorModal from './fill-color-modal';

interface LineColorProps {
    selectedColor: string;
    onSelectColor: (color: string) => void;
}

const LineColor = (props: LineColorProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toolbarButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <React.Fragment>
            <Button
                isSelected={isOpen}
                title="Line Color"
                onClick={() => setIsOpen((current) => !current)}
                ref={toolbarButtonRef}
                data-testid="annotation-open-line-color"
            >
                <FillPreview color={props.selectedColor} />
            </Button>

            {isOpen && (
                <FillColorModal
                    toolbarButtonRef={toolbarButtonRef}
                    title="Select Line Color"
                    onClose={() => setIsOpen(false)}
                    selectedColor={props.selectedColor}
                    onSelectColor={props.onSelectColor}
                />
            )}
        </React.Fragment>
    );
};

export default LineColor;

const Button = styled.button<{ isSelected: boolean }>`
    background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
    color: inherit;
    border: none;
    border-radius: 4px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 5px 4px 4px 4px;
    padding: 7px 12px;
    display: flex;
    flex-direction: row;
    height: 38px;
`;

const FillPreview = styled.div<{ color: string }>`
    width: 18px;
    height: 18px;
    background: ${(props) => props.color};
    border: 1px solid white;
    margin: 2px 0 0 0;
    border-radius: 9px;
`;
