const SEARCH_TERM_KEY = 'searchTerm';

interface SearchTermLocalStorage {
    searchTerm: string;
    useCount: string;
}

export const getSearchTermsFromLocalStorage = (): SearchTermLocalStorage[] => {
    const items = localStorage.getItem(SEARCH_TERM_KEY);
    if (!items) return [];
    try {
        const json = JSON.parse(items);
        return json as SearchTermLocalStorage[];
    } catch (err) {
        return [];
    }
};

export const setSearchTermToLocalStorage = (searchTerm: string) => {
    const items = getSearchTermsFromLocalStorage();
    const existingItem = items.find((i) => i.searchTerm.toLowerCase() === searchTerm.toLowerCase());
    if (existingItem) {
        existingItem.useCount = (parseInt(existingItem.useCount) + 1).toString();
    } else {
        items.unshift({ searchTerm, useCount: '1' });
    }
    localStorage.setItem(SEARCH_TERM_KEY, JSON.stringify(items));
};

export const removeSearchTermFromLocalStorage = (searchTerm: string) => {
    const items = getSearchTermsFromLocalStorage();
    const newItems = items.filter((i) => i.searchTerm !== searchTerm);
    localStorage.setItem(SEARCH_TERM_KEY, JSON.stringify(newItems));
};
