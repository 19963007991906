import L, { LatLng } from 'leaflet';

/**
 * Calculate the topmost point of the circle using harvesine formula
 * @param latlng - center of the circle
 * @param radius - radius of the circle
 * @returns latlng - topmost point of the circle in LatLng format
 * */
export const calculateTopmostLatLng = (center: L.LatLng, radius: number): L.LatLng => {
    const earthRadius = 6378.137; // Radius of the Earth in kilometers
    const distance = radius / 1000; // Convert radius from meters to kilometers

    // Convert degrees to radians
    const centerLatRad = center.lat * (Math.PI / 180);
    const centerLngRad = center.lng * (Math.PI / 180);

    // Calculate latitude of the topmost point
    const topmostLatRad = Math.asin(
        Math.sin(centerLatRad) * Math.cos(distance / earthRadius) +
            Math.cos(centerLatRad) * Math.sin(distance / earthRadius) * Math.cos(0)
    );

    // Calculate longitude of the topmost point
    const topmostLngRad =
        centerLngRad +
        Math.atan2(
            Math.sin(0) * Math.sin(distance / earthRadius) * Math.cos(centerLatRad),
            Math.cos(distance / earthRadius) - Math.sin(centerLatRad) * Math.sin(topmostLatRad)
        );

    // Convert radians to degrees
    const topmostLat = topmostLatRad * (180 / Math.PI);
    const topmostLng = topmostLngRad * (180 / Math.PI);

    // Conver to LatLng object
    const newLatLng = L.latLng(topmostLat, topmostLng);
    return newLatLng;
};

export const getCircleBoundingBox = (center: LatLng, radiusInMeters: number) => {
    const earthRadiusInMeters = 6378137; // Radius of the earth in km
    const latRadian = (center.lat * Math.PI) / 180;

    // Calculate the distance in meters corresponding to the circle's radius
    const distanceLat = (radiusInMeters / earthRadiusInMeters) * (180 / Math.PI);
    const distanceLng = ((radiusInMeters / earthRadiusInMeters) * (180 / Math.PI)) / Math.cos(latRadian);

    // Calculate bounding box corners
    const southWest = L.latLng(center.lat - distanceLat, center.lng - distanceLng);
    const northEast = L.latLng(center.lat + distanceLat, center.lng + distanceLng);

    return L.latLngBounds(southWest, northEast);
};

export const calculateRadiusInPixels = (map: L.Map, center: L.LatLng, radiusInMeters: number): number => {
    // Note, unsure of implications of this but if the map is not ready then we return a default value so it works otherwise it will break.
    // Not sure if large numbers of circles will cause issues, should you see a lot of circles not forming then this is likely the issue and
    // potentially we will have to have some wait for the map been ready when shapes are imported....

    if (!map || !center || !radiusInMeters) return 100;
    //Calculate the maximum distance from the center to any point on the circle's circumference (in degrees)
    const earthRadiusInMeters = 6378137;
    const maxDistanceInDegrees = (radiusInMeters / earthRadiusInMeters) * (180 / Math.PI);

    // Convert the center and a point on the circumference to LatLng objects
    const centerLatLng = L.latLng(center);
    const pointOnCircumference = L.latLng(centerLatLng.lat + maxDistanceInDegrees, centerLatLng.lng);
    // Calculate the circle's radius in pixels based on the maximum distance
    const radiusInPixels = map.latLngToLayerPoint(centerLatLng).y - map.latLngToLayerPoint(pointOnCircumference).y;
    return radiusInPixels;
};

export const translateCircleCenterPointToDragPoint = (
    centerPoint: L.LatLng,
    startPoint: L.LatLng,
    endPoint: L.LatLng
): L.LatLng => {
    const latDiff = endPoint.lat - startPoint.lat;
    const lngDiff = endPoint.lng - startPoint.lng;

    return L.latLng(centerPoint.lat + latDiff, centerPoint.lng + lngDiff);
};
