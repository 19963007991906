import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { isMobileVersion } from '../../../lib/soar-helper';
import ApiUser from '../../../api/api-user';
import { SaleInfo } from '../../../api/model';
import NavigationPanel from '../Shared/navigation-panel';
import { TransactionListItem } from './transaction-list-item';
import { PulseLoader } from '../../Shared/pulse-loader';
import { selectLoggedIn } from '../../../store/Account/selectors';

const SalesHistory = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [salesInfo, setSalesInfo] = useState<SaleInfo[]>([]);

    const userLoggedIn = useSelector(selectLoggedIn);

    useEffect(() => {
        setIsLoading(true);

        if (userLoggedIn) {
            ApiUser.getMySales()
                .then((salesInfo) => {
                    setSalesInfo(salesInfo);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => setIsLoading(false));
        }
    }, [userLoggedIn]);

    return (
        <React.Fragment>
            <NavigationPanel label="Sales History" />
            {isLoading && (
                <LoadingContainer>
                    <PulseLoader />
                </LoadingContainer>
            )}
            {salesInfo.length > 0 && (
                <SalesTable>
                    <thead>
                        <tr>
                            {!isMobileVersion && (
                                <>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Payout</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {salesInfo.map((sale, index) => (
                            <TransactionListItem key={index} item={sale} />
                        ))}
                    </tbody>
                </SalesTable>
            )}
        </React.Fragment>
    );
};

export default SalesHistory;

const SalesTable = styled(Table)`
    background-color: transparent;
    color: white;

    th {
        color: white;
        border-top: none;
        border-bottom: none !important;
    }
`;

const LoadingContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
`;
