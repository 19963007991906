import React, { useState } from 'react';
import styled from 'styled-components';

interface DashboardHomeTileProps {
	onClick: () => void;
	icon: string;
	iconDark: string;
	title: string;
	children: React.ReactNode;
}

const DashboardHomeTile = (props: DashboardHomeTileProps) => {
	const [hover, setHover] = useState(false);

	return (
		<DashboardTileContainer>
			<DashboardTile
				onClick={props.onClick}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				title={props.title}
			>
				{props.children}
				<img src={hover ? props.iconDark : props.icon} alt={props.title} />
			</DashboardTile>
		</DashboardTileContainer>
	);
};

export default DashboardHomeTile;

const DashboardTileContainer = styled.div`
	flex: 1 1 30%;
	display: flex;
	justify-content: center;
	padding-bottom: 45px;
`;

const DashboardTile = styled.span`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	width: 150px;
	height: 150px;
	margin: 0 5px;
	padding: 12px;
	color: #fff;
	font-weight: bold;
	text-align: center;
	cursor: pointer;

	transition: box-shadow 0.5s;

	-webkit-box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
		0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
		0px 4px 18px 3px rgba(0, 0, 0, 0.12);
	img {
		width: 45px;
		height: 45px;
		flex: 0;
	}

	&:hover {
		-webkit-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
			0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}
`;
