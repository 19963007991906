export const transport = {
    accessability: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m1.47,14.42c.02-.14.04-.28.05-.42.13-1.34.58-2.57,1.3-3.7.84-1.33,2.02-2.24,3.42-2.89.64-.3,1.3-.53,1.99-.65.08-.01.16-.02.25-.04,0,.05,0,.09,0,.13-.02.67-.04,1.35-.05,2.02,0,.12-.05.17-.16.21-.97.36-1.82.91-2.54,1.67-.88.94-1.49,2.02-1.69,3.3-.22,1.39.06,2.68.82,3.86,1.08,1.68,2.63,2.68,4.57,3.1.64.14,1.29.2,1.95.17,1.59-.07,3.07-.52,4.44-1.32.65-.38,1.24-.83,1.79-1.33.03-.03.06-.05.1-.08.03.04.05.08.07.11.29.61.58,1.22.87,1.82.05.09.04.16-.03.24-1.37,1.48-3.04,2.44-5,2.91-.55.13-1.11.18-1.66.27-.04,0-.07.02-.11.02h-1.35s-.07-.02-.11-.02c-.91-.07-1.79-.25-2.65-.56-2.17-.78-3.91-2.09-5.09-4.1-.58-.99-.95-2.06-1.1-3.2-.03-.21-.06-.41-.08-.62,0-.31,0-.61,0-.92Z"/><path class="cls-1" d="m11.96,8.21c.07,0,.13,0,.18,0,1.18,0,2.35,0,3.53,0,.36,0,.67.11.93.37.09.09.14.19.13.32,0,.18.01.36,0,.54,0,.1-.05.21-.11.28-.24.27-.54.39-.9.39-1.18,0-2.36,0-3.54,0h-.2v2.25c.05,0,.11,0,.16,0,1.63,0,3.26,0,4.89,0,.15,0,.3.02.45.04.43.08.73.33.91.73.83,1.78,1.66,3.55,2.49,5.33.04.08.07.15.12.24.34-.14.68-.28,1.01-.42.24-.1.47-.21.72-.29.48-.16.9.03,1.08.48.18.45-.06.99-.55,1.2-.37.16-.75.31-1.12.47-.48.2-.96.41-1.44.59-.2.07-.41.12-.62.13-.43.03-.73-.19-.91-.57-.76-1.62-1.52-3.25-2.28-4.88-.1-.22-.22-.44-.31-.66-.06-.17-.16-.21-.33-.21-1.94,0-3.87,0-5.81,0-.77,0-1.33-.55-1.33-1.33,0-.96.02-1.92.02-2.88,0-1.47,0-2.93,0-4.4,0-.11-.04-.18-.13-.24-.66-.47-1.08-1.09-1.16-1.91-.07-.72.17-1.36.65-1.89.54-.6,1.22-.92,2.02-1,.93-.09,1.76.15,2.46.77,1.23,1.08,1.18,2.89-.1,3.89-.24.19-.52.32-.79.46-.09.05-.13.08-.13.19,0,.61,0,1.22,0,1.84,0,.04,0,.08,0,.14Z"/></svg>`,
    ambulance2: `<?xml version="1.0" encoding="UTF-8"?><svg id="Icons_-_Blue_copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><polygon class="cls-1" points="24.09 8.84 21.5 4.36 15.47 7.84 15.47 .88 10.29 .88 10.29 7.84 4.26 4.36 1.67 8.84 7.7 12.32 1.67 15.8 4.26 20.29 10.29 16.81 10.29 23.77 15.47 23.77 15.47 16.81 21.5 20.29 24.09 15.8 18.06 12.32 24.09 8.84"/></svg>`,
    bicycle: `<?xml version="1.0" encoding="UTF-8"?><svg id="Icons_-_Blue_copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m19.81,10.16c-.95,0-1.83.29-2.57.78l-1.1-1.34.02-1.25h1.15l.26-.92h-2.89l-.46.92h.95v.25h-6.01l.32-1.65-.27-.21,1.47-.04v-1h-1.95l-.89,1.23.54.43-.35,1.81-.17,1.55-.05.07c-.68-.4-1.46-.64-2.3-.64-2.54,0-4.61,2.07-4.61,4.61s2.07,4.61,4.61,4.61,4.61-2.07,4.61-4.61c0-.62-.13-1.22-.35-1.76l3.11,3.04.51,2.22h1.49v-1h-.69l-.21-.9h1.52c.65,1.76,2.33,3.02,4.31,3.02,2.54,0,4.61-2.07,4.61-4.61s-2.07-4.61-4.61-4.61Zm-3.61,4.61c0-.92.36-1.75.92-2.39l1.72,2.1-2.59.79c-.02-.16-.05-.33-.05-.5Zm-7.21-5.17h6.18v.11l-2.05,5.17-4.25-4.15.12-1.13Zm-3.48,8.78c-1.99,0-3.61-1.62-3.61-3.61s1.62-3.61,3.61-3.61c.61,0,1.18.17,1.69.44l-2.27,2.96.79.61,2.29-2.99c.68.66,1.1,1.57,1.1,2.59,0,1.99-1.62,3.61-3.61,3.61Zm8.49-3.02l1.81-4.58.66.81c-.79.83-1.28,1.94-1.28,3.18,0,.2.03.4.06.6h-1.25Zm5.8,3.02c-1.48,0-2.74-.89-3.3-2.16l4.05-1.21-2.68-3.28c.56-.36,1.22-.57,1.93-.57,1.99,0,3.61,1.62,3.61,3.61s-1.62,3.61-3.61,3.61Z"/></svg>`,
    busstation: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="rect2312" class="cls-1" d="m1.66,6.71c-.59,0-1.06.47-1.06,1.06v7.46c0,.59.47,1.06,1.06,1.06h.84c.06-1.62,1.39-2.9,3.01-2.9,1.62,0,2.95,1.28,3.01,2.9h8.1c.06-1.62,1.39-2.9,3.01-2.9,1.62,0,2.95,1.28,3.01,2.9h1.02c.59,0,1.06-.47,1.06-1.06v-7.46c0-.59-.47-1.06-1.06-1.06H1.66Zm.13,1.35h4.34c.14,0,.25.11.25.25v3.07c0,.14-.11.25-.25.25H1.79c-.14,0-.25-.11-.25-.25v-3.07c0-.14.11-.25.25-.25Zm5.74,0h4.34c.14,0,.25.11.25.25v3.07c0,.14-.11.25-.25.25h-4.34c-.14,0-.25-.11-.25-.25v-3.07c0-.14.11-.25.25-.25Zm5.74,0h4.34c.14,0,.25.11.25.25v3.07c0,.14-.11.25-.25.25h-4.34c-.14,0-.25-.11-.25-.25v-3.07c0-.14.11-.25.25-.25Zm5.74,0h4.34c.14,0,.25.11.25.25v3.07c0,.14-.11.25-.25.25h-4.34c-.14,0-.25-.11-.25-.25v-3.07c0-.14.11-.25.25-.25Zm-13.51,6.35c-1.1,0-1.98.89-1.98,1.98,0,1.1.89,1.98,1.98,1.98,1.1,0,1.98-.89,1.98-1.98h0c0-1.1-.89-1.98-1.98-1.98Zm14.13,0c-1.1,0-1.98.89-1.98,1.98,0,1.1.89,1.98,1.98,1.98h0c1.1,0,1.98-.89,1.98-1.98h0c0-1.1-.89-1.98-1.98-1.98h0Z"/></svg>`,
    carshop: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m24.38,11.93c0-1.51-1.23-2.74-2.74-2.74h-.29l-1.36-4.88c-.14-.49-.59-.83-1.1-.83H6.45c-.51,0-.96.34-1.1.83l-1.36,4.88h-.29c-1.51,0-2.74,1.23-2.74,2.74v6.32h1.97v1.34c0,.91.74,1.65,1.65,1.65s1.65-.74,1.65-1.65v-1.34h12.88v1.34c0,.91.74,1.65,1.65,1.65s1.65-.74,1.65-1.65v-1.34h1.97v-6.32Zm-19.8,2.62c-.91,0-1.65-.74-1.65-1.65s.74-1.65,1.65-1.65,1.65.74,1.65,1.65-.74,1.65-1.65,1.65Zm1.78-5.36l.96-3.43h10.7l.96,3.43H6.36Zm14.4,5.36c-.91,0-1.65-.74-1.65-1.65s.74-1.65,1.65-1.65,1.65.74,1.65,1.65-.74,1.65-1.65,1.65Z"/></svg>`,
    circlecross2: `<?xml version="1.0" encoding="UTF-8"?><svg id="Icons_-_Blue_copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m12.73.83h-.13C6.33.83,1.2,5.96,1.2,12.24v.13c0,6.13,4.91,11.16,10.99,11.39v.02h.95v-.02c6.08-.22,10.99-5.25,10.99-11.39v-.13C24.13,5.96,19,.83,12.73.83Zm10.45,11.21h-10.03V1.8c5.51.22,9.93,4.7,10.03,10.24ZM12.19,1.8v10.24H2.16c.1-5.54,4.53-10.02,10.03-10.24ZM2.18,12.99h10.01v9.81c-5.36-.21-9.69-4.47-10.01-9.81Zm10.96,9.81v-9.81h10.01c-.32,5.33-4.65,9.6-10.01,9.81Z"/></svg>`,
    circlehalffull2: `<?xml version="1.0" encoding="UTF-8"?><svg id="Icons_-_Blue_copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m21.52,4.99c-.7-.85-1.52-1.59-2.43-2.21-.61-.41-1.26-.77-1.94-1.05-1.02-.43-2.12-.72-3.27-.84-.38-.04-.77-.06-1.16-.06h-.13c-.78,0-1.55.08-2.29.23-2.59.53-4.87,1.96-6.5,3.93-.7.85-1.28,1.79-1.71,2.82-.14.34-.27.69-.38,1.05-.33,1.07-.52,2.21-.52,3.38v.13h0c0,6.27,5.13,11.41,11.41,11.41h.13c6.27,0,11.41-5.13,11.41-11.41v-.13c0-2.74-.98-5.27-2.61-7.24Zm-8.79,17.83h-.13c-5.77,0-10.46-4.69-10.46-10.46h21.04c0,5.77-4.69,10.46-10.46,10.46Z"/></svg>`,
    circletriangle1: `<?xml version="1.0" encoding="UTF-8"?><svg id="Icons_-_Blue_copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m12.66.83C6.33.83,1.2,5.97,1.2,12.3s5.13,11.47,11.47,11.47,11.47-5.13,11.47-11.47S19,.83,12.66.83Zm7.38,15.71H5.29L12.66,3.32l7.38,13.22Zm-7.38,6.27c-5.79,0-10.51-4.71-10.51-10.51S6.73,1.94,12.41,1.8L3.65,17.5h18.02L12.92,1.8c5.68.14,10.25,4.79,10.25,10.5s-4.71,10.51-10.51,10.51Z"/></svg>`,
    events: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m12.86.86c4.62,0,7.71,3.85,7.71,9.25,0,6.17-6.94,10.79-6.94,10.79,0,0,.77,1.54.77,2.31s-3.08.77-3.08,0,.77-2.31.77-2.31c0,0-6.94-4.62-6.94-10.79C5.15,4.71,8.24.86,12.86.86Zm0,1.54c-4.62,0-6.94,5.39-6.17,8.48.77-3.08,3.08-6.94,6.17-8.48Z"/></svg>`,
    ferrystation: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m4.1,15.79l-2.99-4.12h2.99l1.84-2.38s.08-.07.25-.19c.18-.12.51-.14.51-.14h2.38v-.83h1.08l.61-1.42h.78l-.31.79.44.63h2.85v.83h4.64s.41,0,.67.22c.35.29.4.53.4.53l.75,1.96h1.66l1.58,4.12s-1.83-1.12-4.75-.98c-2.38.14-3.31.96-6.02.96-1.48,0-3.69-.74-5.39-.77-1.25-.02-3.29.51-3.97.79m1.3,1.87c1-.24,2.36-.65,4.75-.15,1.18.24,2.3.51,3.4.54,1.43,0,2.22-.24,3.33-.52,1-.26,2.16-.47,3.04-.46,1.53.02,2.7.27,3.35.56l-.47-1.27c-1.27-.43-3.43-.68-5.92.03-1.05.32-2.13.53-3.24.52-1-.02-1.96-.17-2.95-.39-3.15-.73-4.35-.23-5.95.19l.66.95Zm-.03-5.99h2.92v-1.78h-1.14s-.23.03-.5.2c-.16.1-.48.54-.48.54l-.8,1.04Zm7.5,0h3.04v-1.78h-3.04v1.78h0Zm6.46-1.35s-.08-.2-.21-.29c-.17-.12-.33-.15-.33-.15h-2.12v1.78h3.16l-.51-1.35Zm-10.26,1.35h3.06v-1.78h-3.06v1.78Z"/></svg>`,
    fuel: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m22.39,9.73h0v9.73c0,.49-.4.88-.88.88s-.88-.4-.88-.88v-3.54c0-1.46-1.19-2.65-2.65-2.65h-2.65V2.66c0-.98-.79-1.77-1.77-1.77H2.94c-.98,0-1.77.79-1.77,1.77v19.45c0,.98.79,1.77,1.77,1.77h10.61c.98,0,1.77-.79,1.77-1.77v-7.07h2.65c.49,0,.88.4.88.88v3.54c0,1.46,1.19,2.65,2.65,2.65s2.65-1.19,2.65-2.65V7.97c0-.98-.79-1.77-1.77-1.77h0v-2.67c0-.48-.4-.87-.88-.87-.5,0-.9.42-.89.92,0,.02,0,.04,0,.06v4.33c0,.98.79,1.77,1.77,1.77s1.77-.79,1.77-1.77-.79-1.77-1.77-1.77m-8.84,4.42c0,.49-.4.88-.88.88H3.83c-.49,0-.88-.4-.88-.88v-5.3c0-.49.4-.88.88-.88h8.84c.49,0,.88.4.88.88v5.3Z"/></svg>`,
    helicopter: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m13.16.89c1.49.39,2.16,1.55,2.65,2.86.14.38.23.77.35,1.19.07-.07.13-.12.19-.18,1.18-1.18,2.36-2.36,3.54-3.54.17-.17.37-.29.62-.26.43.06.62.55.35.92-.06.08-.13.15-.2.21-1.4,1.4-2.81,2.8-4.2,4.21-.07.07-.13.2-.13.3.03,1.57-.24,3.07-1.01,4.48.06.06.12.12.18.19,1.72,1.72,3.44,3.44,5.16,5.16.07.07.14.14.2.21.19.25.17.58-.03.78-.19.19-.52.21-.76.03-.08-.06-.15-.13-.21-.2-1.68-1.68-3.37-3.37-5.05-5.05-.06-.06-.11-.12-.16-.19-.2.15-.38.28-.55.43-.04.03-.05.12-.05.18,0,2.48,0,4.96,0,7.43,0,.09.08.2.16.26,1.05.72,2.1,1.43,3.16,2.13.13.08.18.17.17.33-.01.36,0,.71,0,1.1-.1-.04-.18-.07-.25-.1-1.08-.48-2.16-.96-3.24-1.44-.11-.05-.24-.08-.37-.08-.59,0-1.2-.09-1.77.03-.57.12-1.09.44-1.63.68-.61.27-1.22.54-1.82.81-.07.03-.14.05-.23.09,0-.43,0-.83,0-1.23,0-.07.09-.14.16-.19,1.04-.71,2.08-1.41,3.13-2.11.14-.1.19-.2.19-.36,0-2.43,0-4.85,0-7.28,0-.17-.04-.27-.18-.37-.21-.15-.39-.32-.63-.53-.05.07-.09.16-.15.22-1.74,1.75-3.49,3.5-5.24,5.24-.07.07-.15.15-.23.21-.25.17-.57.14-.76-.06-.19-.2-.2-.53-.02-.76.06-.08.13-.14.2-.21,1.81-1.81,3.62-3.62,5.43-5.43.13-.13.14-.22.07-.38-.54-1.13-.76-2.34-.79-3.59,0-.16-.05-.28-.17-.39-1.53-1.52-3.05-3.05-4.57-4.57-.1-.1-.2-.23-.26-.36-.11-.26-.03-.51.18-.66.19-.14.48-.14.69.02.08.06.15.13.22.2,1.28,1.28,2.55,2.55,3.83,3.83.06.06.12.11.17.16.15-.53.27-1.06.45-1.57.3-.85.74-1.62,1.44-2.21.35-.3.76-.46,1.2-.58h.58Z"/></svg>`,
    helipad: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><path class="cls-1" d="m12.67.89C6.31.89,1.16,6.04,1.16,12.4s5.15,11.5,11.5,11.5,11.5-5.15,11.5-11.5S19.02.89,12.67.89Zm0,1.53c5.51,0,9.97,4.46,9.97,9.97s-4.46,9.97-9.97,9.97S2.7,17.9,2.7,12.4,7.16,2.43,12.67,2.43Zm-4.6,2.3l-1.15,1.15v13.04s1.15,1.15,1.15,1.15h1.53v-6.14h6.14v6.14h1.53l1.15-1.15V5.88l-1.15-1.15h-1.53v6.14h-6.14v-6.14h-1.53Z"/></svg>`,
    horseriding: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1,.cls-2{fill:#fff;stroke-width:0px;}.cls-2{fill-rule:evenodd;}</style></defs><g id="layer1"><g id="g1892"><path id="path1882" class="cls-2" d="m3.65,5.39l.03,1.38-2.71,3.06.68.71,3.09-.65,1.35,2.33c-.59,1.42-.08,2.07.24,3.03l-3.36,2.12v4.68l1.21-.65-.03-3.18,2.8-1.53,2.62,5.95-.23,1.19,1.2.02.18-1.18-2.09-6.06c1.99.56,4,.67,6.04,0l1.97,3.5-2.01,3.71,1.39.03,2.09-3.86-.44-2.95,3.06,2.42-.68,4.09,1.36-.35.65-4.18-1.74-2.97c.25-1.52-.07-2.67-.72-3.45l.62-.54c1.04-.36,1.67.02,2.03.88l.35,2.14,1.77-.91-.47-1.7c-.58-1.53-2.34-2.57-4.3-1.17,0,0-.62.64-.7.66-1.04-.68-2.47-.78-3.82-.28h-.24c.43-.22.45-.77.3-1.17l-2.09-3.62c-.8-.94-2.72-.08-2.36,1.3l.27.51-1.84.87c-.93-1.65-1.91-3.26-4.53-3.26l-.94-.91Zm7.86,4.36l.69,1.15-1.63.81h-.02c-.29-.31-.54-.67-.78-1.04l1.75-.92Z"/><path id="path1884" class="cls-1" d="m12.31,5.01c0,.75-.61,1.36-1.36,1.36s-1.36-.61-1.36-1.36.61-1.36,1.36-1.36,1.36.61,1.36,1.36Z"/></g></g></svg>`,
    lighthouse: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="lighthouse" class="cls-1" d="m12.67.89l-4.92,2.46c-.76,2.46-.76,4.1,0,7.38h1.64l-1.64,13.13h9.84l-1.64-13.13h1.64c.87-3.28.87-4.92,0-7.38L12.67.89ZM2.36,3.3c-.27.14-.38.62,0,.77l3.08,1.23c.5.2.81-.57.31-.77l-3.08-1.23c-.13-.05-.22-.05-.31,0Zm20.3,0l-3.08,1.23c-.5.2-.2.97.31.77l3.08-1.23c.5-.2.19-.97-.31-.77Zm-14.1.87h8.2c.43,1.64.43,3.28,0,4.92h-8.2c-.43-1.64-.43-3.28,0-4.92Zm-6.15,2.05c-.54,0-.54.82,0,.82h3.28c.54,0,.54-.82,0-.82h-3.28Zm17.23,0c-.54,0-.54.82,0,.82h3.28c.54,0,.54-.82,0-.82h-3.28Zm-14.2,1.85l-3.08,1.23c-.5.2-.2.97.31.77l3.08-1.23c.5-.2.19-.97-.31-.77Zm14.15,0c-.27.14-.38.62,0,.77l3.08,1.23c.5.2.81-.57.31-.77l-3.08-1.23c-.13-.05-.22-.05-.31,0Z"/></svg>`,
    marina: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m12.67.89C6.33.89,1.18,6.04,1.18,12.38s5.15,11.48,11.48,11.48,11.48-5.15,11.48-11.48S19,.89,12.67.89Zm0,21.77c-5.67,0-10.28-4.61-10.28-10.28S7,2.09,12.67,2.09s10.28,4.61,10.28,10.28-4.61,10.28-10.28,10.28Z"/><path class="cls-1" d="m16.5,15.99l.64.59c-.94.69-2.11,1.15-3.37,1.31l-.21-8.78h2.4v-1.46h-2.38c.69-.3,1.14-.96,1.14-1.69,0-1.04-.93-1.89-2.07-1.89s-2.07.85-2.07,1.89c0,.74.47,1.4,1.18,1.71l-2.29-.02v1.47h2.41l-.21,8.78c-1.28-.16-2.46-.62-3.4-1.32l.66-.58-3.6-2.65.74,5.15,1.21-1.05c1.19,1.2,2.73,1.97,4.41,2.2l.12,1.07h1.82l.12-1.07c1.09-.14,2.11-.51,3.05-1.1.51-.32.95-.67,1.34-1.05l1.09,1.01.91-5.12-3.63,2.6Zm-3.06-10.67c.18.18.29.41.29.64,0,.53-.43.91-1.02.91-.55,0-1.03-.42-1.04-.92,0-.19.09-.41.25-.58.2-.22.49-.35.76-.36.28,0,.56.11.77.31Z"/></svg>`,
    parking: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><path class="cls-1" d="m4.05.89v22.97h3.35v-9.57h8.13c3.17,0,5.74-3,5.74-6.7S18.71.89,15.54.89H4.05Zm3.35,3.35v6.7h7.18c1.67,0,3.02-1.5,3.02-3.35s-1.35-3.35-3.02-3.35h-7.18Z"/></svg>`,
    sailing: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m23.18,23.86c-.45,0-.9-.11-1.29-.29-.4-.19-.86-.3-1.34-.3s-.93.11-1.33.3c-.4.18-.84.29-1.3.29s-.9-.11-1.3-.29c-.4-.19-.86-.3-1.34-.3s-.93.11-1.33.3c-.4.18-.84.29-1.3.29s-.9-.11-1.3-.29c-.4-.19-.86-.3-1.34-.3s-.93.11-1.34.3c-.39.18-.84.29-1.3.29s-.9-.11-1.3-.29c-.4-.19-.86-.3-1.34-.3s-.93.11-1.34.3c-.4.18-.84.29-1.3.29v-1.96c.46,0,.9-.11,1.3-.29.4-.19.86-.3,1.34-.3s.93.11,1.34.3c.4.18.84.29,1.3.29s.9-.11,1.3-.29c.4-.19.86-.3,1.34-.3s.93.11,1.34.3c.4.18.84.29,1.3.29s.9-.11,1.3-.29c.41-.19.86-.3,1.34-.3s.93.11,1.34.3c.39.18.84.29,1.3.29s.9-.11,1.3-.29c.4-.19.86-.3,1.33-.3s.93.11,1.34.3c.39.18.84.29,1.3.29v1.96h0ZM14.57.89v14.83H2.75L14.57.89Zm1.44,3.41c0,1.66,7.58,6.16,7.03,11.43h-7.03V4.3Zm5.52,12.38H5.48v2.97c.48.05.41.1.57.18.4.18.86.28,1.32.28s.92-.11,1.31-.28c.4-.19.87-.3,1.34-.3s.93.11,1.34.3c.4.18.84.28,1.3.28s.9-.11,1.3-.28c.41-.19.86-.3,1.34-.3s.93.11,1.34.3c.39.18.84.28,1.3.28.09,0,.17-.02.26-.03h.02s.16-.01.16-.01c.11-.01.21-.03.32-.05.94-.2,1.55-.96,1.55-.96l1.84-2.37h-.55Z"/></svg>`,
    vehiclecharging: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="charging-station" class="cls-1" d="m4.27.89C2.21.89.74,2.3.74,4.43v19.44h12.37v-13.91s1.55-.22,1.55,1.55v7.07c0,3.53,3.29,3.75,3.75,3.75.49,0,3.75-.22,3.75-3.75v-7.07s2.45.03,2.43-5.25h-1.33v-3.53c0-1-1.33-1-1.33-.06v3.53h-1.77v-3.53c0-.97-1.33-.96-1.33,0v3.53h-1.33c.02,5.28,2.43,5.3,2.43,5.3v7.07c0,1.54-1.36,1.55-1.55,1.55s-1.55-.08-1.55-1.55v-7.07c0-2.26-1.99-3.75-3.75-3.75v-3.31c0-2.16-1.44-3.53-3.53-3.53h-5.3Zm1.71,3.53h4.86l-3.04,4.71h3.64l-6.68,9.42h-1.21l2.43-7.07h-3.04l3.04-7.07Z"/></svg>`,
    warning: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1,.cls-2{fill:#fff;stroke-width:0px;}.cls-2{fill-rule:evenodd;}</style></defs><path class="cls-1" d="m12.67,2.51c.38,0,.73.2.92.53l10.16,17.6c.19.33.19.74,0,1.07s-.54.53-.92.53H2.51c-.38,0-.73-.2-.92-.53-.19-.33-.19-.74,0-1.07L11.74,3.05c.19-.33.54-.53.92-.53m0-.5c-.56,0-1.08.3-1.36.78L1.15,20.39c-.28.48-.28,1.08,0,1.57s.8.78,1.36.78h20.32c.56,0,1.08-.3,1.36-.78.28-.48.28-1.08,0-1.57L14.02,2.8c-.28-.48-.8-.78-1.36-.78h0Z"/><path class="cls-2" d="m2.51,21.84c-.24,0-.46-.13-.58-.33-.12-.21-.12-.46,0-.67L12.09,3.25c.12-.21.34-.33.58-.33s.46.13.58.33l10.16,17.6c.12.21.12.46,0,.67s-.34.33-.58.33H2.51Zm2.31-2l7.85-13.59,7.85,13.59H4.82Z"/></svg>`,
};
