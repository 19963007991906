import Marker from '../../../components/MapView/Annotations/Marker/marker';
import { MarkerIconName } from '../../../components/MapView/Annotations/Marker/marker-util';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface MarkerState {
    markers: Marker[];
    markerColor: string;
    markerIcon?: string;
}

const initialState: MarkerState = {
    markers: [],
    markerColor: '#EED926',
    markerIcon: MarkerIconName.SOARDEFAULT,
};

export default (state: MarkerState = initialState, action: Action): MarkerState => {
    switch (action.type) {
        case at.SET_MARKERS:
            return { ...state, markers: action.payload.markers };

        case at.SET_MARKER_COLOR:
            return { ...state, markerColor: action.payload };

        case at.SET_MARKER_ICON:
            return { ...state, markerIcon: action.payload };

        case at.SET_MARKER_ADD:
            return {
                ...state,
                markers: [...state.markers, action.payload],
            };

        case at.SET_MARKER_UPDATE: {
            const newMarkers = state.markers.map((marker) => {
                if (marker.id === action.payload.id) {
                    return { ...marker, ...action.payload };
                }
                return marker;
            });
            return { ...state, markers: newMarkers };
        }

        case at.SET_MARKER_DELETE: {
            const newMarkers = state.markers.filter((marker) => marker.id !== action.payload.id);
            return { ...state, markers: newMarkers };
        }

        default:
            return state;
    }
};
