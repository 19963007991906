export const urban = {
    cafe: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="cafe" class="cls-1" d="m2.57,3.68v10.1c0,3.37,3.37,5.05,7.58,5.05s7.58-1.68,7.58-5.05h3.37c1.68,0,3.37-1.68,3.37-3.37v-1.68c0-1.68-1.68-3.37-3.37-3.37h-3.37v-1.68H2.57Zm15.15,3.79h2.95c1.12,0,1.68.95,1.68,2.1s-.65,2.1-1.68,2.1h-2.95v-4.21ZM.88,20.51c0,1.68,1.68,3.37,3.37,3.37h11.78c1.68,0,3.37-1.68,3.37-3.37H.88Z"/></svg>`,
    communitycenter: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><path id="path4139-0" class="cls-1" d="m20.24,6.1c0,1.4-1.12,2.54-2.5,2.54s-2.5-1.14-2.5-2.54,1.12-2.54,2.5-2.54,2.5,1.14,2.5,2.54Zm-14.35,5.93h3.39v5.08h-3.39v-5.08Zm4.19-5.93c0,1.4-1.12,2.54-2.5,2.54s-2.5-1.14-2.5-2.54,1.12-2.54,2.5-2.54,2.5,1.14,2.5,2.54ZM.81,10.33h11.85v1.69H.81v-1.69Zm1.69,13.55l3.39-6.77h1.69l-3.39,6.77h-1.69Zm10.16,0l-3.39-6.77h-1.69l3.39,6.77h1.69Zm3.39-11.85h3.39v5.08h-3.39v-5.08Zm-3.39-1.69h11.85v1.69h-11.85v-1.69Zm0,13.55l3.39-6.77h1.69l-3.39,6.77h-1.69Zm10.16,0l-3.39-6.77h-1.69l3.39,6.77h1.69Z"/></svg>`,
    convenience: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><path class="cls-1" d="m13.61,11.38l3.09-4.64c.2-.46-.01-.99-.47-1.19-.46-.2-.99.01-1.19.47l-3.57,5.36H.93l3.57,12.5h16.07l3.57-12.5h-10.54Zm7.77,3.57h-2.59v-1.79h3.13l-.54,1.79Zm-11.52.89v2.68h-2.68v-2.68h2.68Zm-2.68-.89v-1.79h2.68v1.79h-2.68Zm3.57.89h3.57v2.68h-3.57v-2.68Zm4.46,0h2.68v2.68h-2.68v-2.68Zm0-.89v-1.79h2.68v1.79h-2.68Zm3.57.89h2.32l-.8,2.68h-1.52v-2.68Zm-4.46-2.68v1.79h-3.57v-1.79h3.57Zm-8.04,0v1.79h-2.59l-.54-1.79h3.13Zm-2.32,2.68h2.32v2.68h-1.52l-.8-2.68Zm1.88,6.25l-.8-2.68h1.25v2.68h-.45Zm1.34,0v-2.68h2.68v2.68h-2.68Zm3.57,0v-2.68h3.57v2.68h-3.57Zm4.46,0v-2.68h2.68v2.68h-2.68Zm3.57,0v-2.68h1.25l-.8,2.68h-.45Z"/></svg>`,
    doctor: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="doctor" class="cls-1" d="m12.67,4.05c-1.92,0-3.85.83-5.16,2.48-1.14,1.43-1.45,2.48-1.6,4.13-4.79.04-4.8,1.79-4.8,13.22h23.14c0-11.43-.02-13.18-4.8-13.22-.15-1.65-.49-2.73-1.6-4.13-1.31-1.65-3.25-2.48-5.16-2.48Zm0,3.31c2.12,0,3.31,1.65,3.41,3.31h-6.82c.1-1.65,1.29-3.3,3.41-3.31Zm-1.65,4.96h3.31v3.31h3.31v3.31h-3.31v3.31h-3.31v-3.31h-3.31v-3.31h3.31v-3.31Z"/></svg>`,
    hotel: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="hotel" class="cls-1" d="m1.74,10.43c-.47,0-.84.37-.84.84v12.61h1.68v-3.36h20.18v3.36h1.68v-9.25c0-.47-.37-.84-.84-.84s-.84.37-.84.84v4.2H2.58v-7.57c0-.47-.37-.84-.84-.84Zm5.04,1.68c-1.39,0-2.52,1.13-2.52,2.52s1.13,2.52,2.52,2.52,2.52-1.13,2.52-2.52-1.13-2.52-2.52-2.52Zm4.2,1.68v3.36h10.09c0-1.68-1.74-3.36-3.36-3.36h-6.73Z"/></svg>`,
    house: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m3.85,13.55v10.33h17.63v-10.33l-8.84-4.86L3.85,13.55Zm3.58,4.89v-3.37h3.35v3.37h-3.35Zm7.14,0v-3.37h3.32v3.37h-3.32Zm-1.28-13.39c-.37-.21-.86-.21-1.23,0l-5.05,2.88v-2.77h-2.56v4.16l-3.56,2.12,1.23,2.17,10.51-6.02,10.54,6.02,1.26-2.2-11.14-6.36Z"/></svg>`,
    industry: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><g id="Layer_1"><polygon class="cls-1" points="24.14 13.2 24.14 23.81 1.19 23.81 1.19 3.53 4.43 3.53 4.43 16.78 11 13.15 11 16.84 17.57 13.2 17.57 16.84 24.14 13.2"/></g></svg>`,
    information: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><path id="path41" class="cls-1" d="m12.67.91C6.34.91,1.22,6.04,1.22,12.36s5.13,11.45,11.45,11.45,11.45-5.13,11.45-11.45S18.99.91,12.67.91Zm0,2.45c4.97,0,9,4.03,9,9s-4.03,9-9,9S3.67,17.33,3.67,12.36,7.7,3.37,12.67,3.37Zm0,2.24c-.94,0-1.7.76-1.7,1.7h0c0,.94.76,1.7,1.7,1.7.94,0,1.7-.76,1.7-1.7,0-.94-.76-1.7-1.7-1.7,0,0,0,0,0,0Zm1.12,4.52l-3.37,1.12v1.12h1.04l.09,5.62h-1.12v1.12h4.49v-1.12h-1.12v-7.87Z"/></svg>`,
    jogging: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><g id="g7"><g id="g9"><path id="path4286" class="cls-1" d="m15.13,2.98c0,1.14.93,2.07,2.08,2.07h0c1.15,0,2.08-.93,2.08-2.07s-.93-2.07-2.08-2.07-2.08.93-2.08,2.07h0ZM3.86,17.06l4.41,1.13c.18.05.58,0,.73-.03.39-.08.5-.25.65-.44l2.09-3.44,2.62,2.82-1.06,5.27c-.03.11-.05.21-.05.28,0,.34.11.63.33.87.22.24.48.37.78.37.57,0,.93-.33,1.06-.99l1.29-6.39c.15-.68.08-1.17-.2-1.49l-2.3-2.56,2.5-3.24,1.61,1.8c.17.19.36.28.58.28.29,0,.56-.14.83-.42l2.62-2.93c.13-.19.2-.39.2-.62,0-.28-.09-.52-.26-.7-.18-.19-.38-.28-.62-.28s-.42.08-.56.23l-2.17,2.42-2.62-2.93c-.2-.23-.46-.4-.76-.34l-5.71,1.27c-.25.06-.43.29-.61.51l-1.27,2.19c-.2.19-.3.42-.3.7,0,.26.08.49.25.68.17.19.37.28.61.28.25,0,.47-.11.66-.34l1.47-2.47,2.39-.41-4.97,7.71-4.05-1.16c-.32,0-.61.06-.83.29-.22.23-.44.43-.44.78,0,.34.14.63.36.87.22.23.44.36.73.44Z"/></g></g></svg>`,
    library: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="path4176" class="cls-1" d="m5.22,2.15c-.34.01-.67.08-1,.21v13.52s5.39-.21,6.76,5.07V6.75c-1.05-2.21-3.41-4.7-5.76-4.6Zm5.76,18.8c-3.49-2.69-8.45-3.38-8.45-3.38V4.73l-1.69-.68v15.21l10.14,3.38h3.38l10.14-3.38V4.05l-1.69.68v13.52l-8.45,2.7c-1.13-.43-2.44-.35-3.38,0ZM21.12,2.36c-3.37-.18-7.26,1.84-9.3,3.58v14.31c1.88-1.56,5.26-3.62,9.3-4.03V2.36Z"/></svg>`,
    military: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><polygon class="cls-1" points="15.6 9.55 24.51 9.74 17.4 15.13 19.97 23.67 12.66 18.56 5.33 23.65 7.92 15.12 .82 9.72 9.74 9.55 12.68 1.12 15.6 9.55"/></svg>`,
    museum: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="museum" class="cls-1" d="m12.67,3.99L2.72,7.31v1.66h19.89v-1.66l-9.94-3.31ZM2.72,10.62v1.66h1.66v8.29h-1.66l-1.66,3.31h23.2l-1.66-3.31h-1.66v-8.29h1.66v-1.66H2.72Zm6.63,1.66c.82,0,1.66.88,1.66,1.66v6.63h-3.31v-6.63c0-.87.83-1.66,1.66-1.66Zm6.63,0c.83,0,1.66.78,1.66,1.66v6.63h-3.31v-6.63c0-.87.82-1.66,1.66-1.66Z"/></svg>`,
    music: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="nightclub" class="cls-1" d="m23.34.91L6.93,4.19v14.05c-.63-.11-1.29-.12-1.95.05-1.9.51-3.3,2.28-2.92,3.69.38,1.42,2.46,2.25,4.36,1.74,1.9-.51,2.97-2.19,2.97-3.69v-11.44l11.49-2.26v9.23c-.63-.1-1.29-.07-1.95.1-1.89.51-3.3,2.23-2.92,3.64.38,1.42,2.46,2.25,4.36,1.74,1.89-.51,2.97-2.1,2.97-3.74V.91Z"/></svg>`,
    police: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="police" class="cls-1" d="m9.38.91l-1.64,1.64v1.64h9.85v-1.64l-1.64-1.64h-6.57Zm-1.64,4.93c-.05.28,0,.56,0,.85,0,2.35,2.66,4.07,4.93,4.07s4.93-1.72,4.93-4.07v-.85H7.74Zm0,6.57c-2.33,0-3.26,3.08-3.28,4.93v6.57h2.51l8.98-11.5H7.74Zm10.67,0l-8.98,11.5h11.44v-6.57c0-1.85-.45-4.93-2.46-4.93Z"/></svg>`,
    postoffice: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="post-office" class="cls-1" d="m1.2,4.48v1.64l11.46,6.55,11.46-6.55v-1.64H1.2Zm0,4.91v11.46h22.93v-11.46l-11.46,6.55L1.2,9.39Z"/></svg>`,
    power: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-1" d="m10.37.86l-3.06,12.23h4.59l-3.06,10.7,9.17-13.76h-4.59L18.02.86h-7.64Z"/></svg>`,
    shelter: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;fill-rule:evenodd;stroke-width:0px;}</style></defs><path id="rect4777" class="cls-1" d="m7.76.91l-1.64,3.28h.77l1.72-3.28h-.85Zm6.56,0l-1.64,3.28h.77l1.72-3.28h-.85Zm6.56,0l-1.64,3.28h.77l1.72-3.28h-.85ZM2.82,4.19l-1.64,3.28h.77l1.72-3.28h-.85Zm6.58,0l-1.64,3.28h.77l1.72-3.28h-.85Zm6.56,0l-1.64,3.28h.77l1.72-3.28h-.85Zm6.56,0l-1.64,3.28h.77l1.72-3.28h-.85ZM5.28,7.48l-1.64,3.28h.77l1.72-3.28h-.85Zm13.1,0l-1.64,3.28h.77l1.72-3.28h-.85Zm-5.72,1.64L1.18,15.68h3.28l1.64-.66v8.04c0,.29.15.55.39.7.25.15.57.16.83.01.26-.14.42-.42.42-.71v-8.7l4.92-1.97,4.92,1.97v8.7c0,.29.15.55.39.7.25.15.57.16.83.01.26-.14.42-.42.42-.71v-8.04l1.64.66h3.28l-11.49-6.56Z"/></svg>`,
    telephone: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="telephone" class="cls-1" d="m3.13,14.43c-1.91,1.91-2.78,4.97-.87,6.86l.87.86,5.79-5.79v-2.9l4.83-4.83h2.9l5.79-5.79-.99-.94c-1.88-1.8-4.84-.95-6.74.94L3.13,14.43Zm1.05,8.76l.65.68s1.64.15,2.49-.69l2.69-2.65c.74-.78.59-2.52.59-2.52l-.65-.68-5.77,5.86Zm13.53-13.55l.65.68s1.68.3,2.44-.45l2.69-2.65c.76-.75.62-2.72.62-2.72l-.65-.68-5.75,5.82Z"/></svg>`,
    temple: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="place-of-worship" class="cls-1" d="m12.6.91c-1.36,0-2.46,1.1-2.46,2.46s1.1,2.46,2.46,2.46,2.46-1.1,2.46-2.46-1.1-2.46-2.46-2.46ZM3.86,8.41l2.19,3.98,4.92-1.64,1.64,6.56-3.28,3.28c-.44.5-.76.75-.82,1.64,0,1.64,1.64,1.64,1.64,1.64h9.84s1.64,0,1.64-1.64-1.64-1.64-1.64-1.64h-4.92l1.64-1.64c1.64-1.64,1.52-2.42,1.33-3.28l-1.33-6.56c-.39-1.47-1.41-2.18-3.28-1.89l-1.64.25-4.92,2.46-1.64-2.46c-.72-.96-1.94-.13-1.37.94Z"/></svg>`,
    theatre: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="theatre" class="cls-1" d="m1.25.91v11.42s0,6.53,6.53,6.53c.8,0,1.48-.14,2.09-.31-.1-.79-.05-1.33-.05-1.33v-.36c-.56.22-1.24.36-2.04.36-4.89,0-4.89-4.89-4.89-4.89V3.36c3.26,1.55,6.53,1.55,9.79,0v1.89c.55.22,1.1.42,1.63.56V.91C9.4,3.15,6.14,3.04,1.25.91Zm4.08,4.89c-.9,0-1.63.73-1.63,1.63s.73,1.63,1.63,1.63,1.63-.73,1.63-1.63-.73-1.63-1.63-1.63Zm5.71,0v11.42s0,6.53,6.53,6.53,6.53-6.53,6.53-6.53V5.81c-4.89,2.24-8.16,2.13-13.05,0Zm4.08,4.89c.9,0,1.63.73,1.63,1.63s-.73,1.63-1.63,1.63-1.63-.73-1.63-1.63.73-1.63,1.63-1.63Zm4.89,0c.9,0,1.63.73,1.63,1.63s-.73,1.63-1.63,1.63-1.63-.73-1.63-1.63.73-1.63,1.63-1.63Zm-12.24.82c-2.39,0-3.26,1.66-3.26,3.26h5.3v-2.7c-.5-.36-1.15-.56-2.04-.56Zm6.53,5.71h6.53c0,1.63-1.63,3.26-3.26,3.26s-3.26-1.63-3.26-3.26Z"/></svg>`,
    tower: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="watchtower" class="cls-1" d="m5.28.91v4.92h14.77V.91h-2.46v1.64h-3.28V.91h-3.28v1.64h-3.28V.91h-2.46Zm.82,6.56l-.82,14.77h-2.46v1.64h7.38c0-1.64.45-4.1,2.46-4.1,2.09.04,2.46,2.46,2.46,4.1h7.38v-1.64h-2.46l-.82-14.77H6.1Zm6.56,3.28c1.64,0,1.64,1.64,1.64,1.64v1.64h-3.28v-1.64s0-1.64,1.64-1.64Z"/></svg>`,
    wastedisposal: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="path817" class="cls-1" d="m10.9.91v1.77h-5.3v1.77h14.13v-1.77h-5.3V.91h-3.53Zm-5.3,7.07l1.77,15.9h10.6l1.77-15.9H5.6Zm3.53,1.77h1.77v12.37h-1.77v-12.37Zm5.3,0h1.77v12.37h-1.77v-12.37Z"/></svg>`,
    water: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><path id="drinking-water" class="cls-1" d="m2.79,10.78l2.47,13.16h8.23l2.47-13.16H2.79Zm1.94,1.65h9.26l-.61,3.29H5.34m2.39-6.58c0-3.29,1.65-4.94,4.94-4.94h1.65v-1.65h-1.65c-1.65,0-1.65-1.65,0-1.65h4.94c1.65,0,1.65,1.65,0,1.65h-1.65v1.65h6.58v2.47h-9.87c-1.78-.01-2.47.79-2.47,2.47h-2.47Z"/></svg>`,
    wifi: `<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.08"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="g4491"><path id="rect2" class="cls-1" d="m22.28,1.81c.51,0,.92.41.92.92v19.23c0,.51-.41.92-.92.92H3.05c-.51,0-.92-.41-.92-.92V2.73c0-.51.41-.92.92-.92h19.23m0-1H3.05c-1.06,0-1.92.86-1.92,1.92v19.23c0,1.06.86,1.92,1.92,1.92h19.23c1.06,0,1.92-.86,1.92-1.92V2.73c0-1.06-.86-1.92-1.92-1.92h0Z"/><g id="g4499"><path id="path4391" class="cls-1" d="m12.66,8.52c-2.27,0-4.53.86-6.26,2.54l1.58,1.56c2.6-2.52,6.78-2.54,9.4-.03l1.57-1.57c-1.75-1.67-4.02-2.5-6.28-2.5h0Zm-4.69,4.1h0s0,0,0,0Z"/><path id="path4395" class="cls-1" d="m12.65,5.19c-3.13.01-6.25,1.18-8.64,3.51l1.56,1.55c3.91-3.79,10.21-3.81,14.14-.04l1.6-1.57c-2.41-2.31-5.54-3.45-8.67-3.44h0Zm-7.08,5.05h-.02s.02,0,.02,0Z"/><path id="path4381" class="cls-1" d="m12.65,11.83c-1.41,0-2.82.53-3.9,1.58l1.51,1.49c1.33-1.29,3.47-1.3,4.81-.02l1.5-1.5c-1.09-1.04-2.5-1.56-3.91-1.55h0Zm-2.39,3.07h0s0,0,0,0Z"/><circle class="cls-1" cx="12.67" cy="17.29" r="2.21"/></g></g></svg>`,
};
