import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MapHelper from '../../../lib/map-helper';
import { selectSentinelMapZoom } from '../../../store/Map/Sentinel/selectors';
import { useBasemap } from '../../../store/Map/Basemap/use-basemap';

const MobileBaseMapAttribution = () => {
    const url = useLocation();

    const { basemap } = useBasemap();

    const mapZoom = useSelector(selectSentinelMapZoom);

    const mobileAttributionText = MapHelper.getMobileBaseMapAttributionText(mapZoom, basemap);

    if (url.pathname.includes('/explore')) {
        return <React.Fragment />;
    }

    return (
        <BaseMapAttributionContainer>
            <BaseMapAttributionText>{mobileAttributionText}</BaseMapAttributionText>
        </BaseMapAttributionContainer>
    );
};

export default MobileBaseMapAttribution;

const BaseMapAttributionContainer = styled.div`
    width: fit-content;
    padding: 0px 2px;
    background-color: rgba(255, 255, 255, 0.67);
    float: right;
    margin-top: -15px;
    margin-right: 2px;
`;

const BaseMapAttributionText = styled.div`
    padding: 0px 1px;
    font-size: 8px;
    color: #323232;
`;
