import L from 'leaflet';
import { defaultZIndex } from '../layers-util';

export const defaultArrowOptions: L.PolylineOptions = {
    color: '#EED926',
    weight: 3,
    interactive: false,
};

export default class Arrow {
    annotationType: string;
    id: string;
    startLatLng: L.LatLng;
    endLatLng: L.LatLng;
    options: L.PolylineOptions;
    zIndex: number;

    constructor(id: string, startLatLng: L.LatLng, endLatLng: L.LatLng, options: L.PolylineOptions) {
        this.annotationType = 'Arrow';
        this.startLatLng = startLatLng;
        this.endLatLng = endLatLng;
        this.options = options;
    }

    static serialize(arrow: Arrow): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [arrow.startLatLng.lng, arrow.startLatLng.lat],
                    [arrow.endLatLng.lng, arrow.endLatLng.lat],
                ],
            },
            properties: {
                annotationType: 'Arrow',
                id: arrow.id,
                color: arrow.options.color,
                weight: arrow.options.weight,
                zIndex: arrow.zIndex || defaultZIndex,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): Arrow {
        const startLatLng = new L.LatLng(json.geometry.coordinates[0][1], json.geometry.coordinates[0][0]);
        const endLatLng = new L.LatLng(json.geometry.coordinates[1][1], json.geometry.coordinates[1][0]);

        return {
            annotationType: 'Arrow',
            startLatLng: startLatLng,
            endLatLng: endLatLng,
            zIndex: json.properties.zIndex || defaultZIndex,
            id: json.properties.id,
            options: {
                weight: json.properties.weight,
                color: json.properties.color,
            },
        };
    }
}
