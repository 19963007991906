import React, { useState } from 'react';
import styled from 'styled-components';
import { SideDrawerBackButton } from '../SideDrawer/Shared/side-drawer-buttons';
import {
    Content,
    Logo,
    LogoContainer,
    SatelliteSubmitButton,
    TeaserIcon,
    TeaserText,
} from '../Satellites/satellite-drawer-styles';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import { LoginModalMode } from '../../Registration/login-enum';

interface DrawerLoginProps {
    logo: string;
    onClickBackButton: () => void;
    onLogin: (userLoggedIn?: boolean) => void;
}

const DrawerLogin = ({ logo, onClickBackButton, onLogin }: DrawerLoginProps) => {
    const [showLogin, setShowLogin] = useState(false);

    return (
        <React.Fragment>
            <SideDrawerBackButton onClick={onClickBackButton} />

            <LogoContainer>
                <Logo src={logo} />
            </LogoContainer>
            <Content>
                <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                <LoginText>
                    Access near real time orbital satellite imagery from some of the world's leading providers.
                </LoginText>
                <SatelliteSubmitButton onClick={() => setShowLogin(true)}>Sign In</SatelliteSubmitButton>
            </Content>
            <LoginRegisterDialog
                isOpen={showLogin}
                initialMode={LoginModalMode.LOGIN}
                onClose={(isUserLoggedIn) => {
                    setShowLogin(false);

                    if (isUserLoggedIn) {
                        onLogin(isUserLoggedIn);
                    }
                }}
            />
        </React.Fragment>
    );
};

export default DrawerLogin;

const LoginText = styled(TeaserText)`
    padding: 10px 20px;
`;
