/*
     The Satellite tracker contains the following:
        - A historical trail over the last 8 hours (split into a series of polylines with decreasing opacity)
        - A marker that updates every 1 second showing the current position of the satellite and
        - A trail left by the satellite as it moves (as a polyline).

    Some caveats and notes;
        - The trail adds a new position every second so we need to watch the memory size
        - The marker halts movement during map zoom due to leaflet bug: https://github.com/Leaflet/Leaflet/issues/7017
*/

import React, { useEffect, useRef } from 'react';

import {
    Marker as LeafletMarker,
    Polyline as LeafletPolyline,
    Icon as LeafletIcon,
    Point as LeafletPoint,
    LatLng,
} from 'leaflet';
import { useMap } from 'react-leaflet';
import SatelliteUtil, { Satellite } from '../../../lib/satellite-util';

// How frequently we update the live track and satellite position
const SATELLITE_UPDATE_INTERVAL = 1000; //ms

// Length of time we show the satellite trail
const HISTORICAL_PATH_DURATION = 8; //hours

// How frequently the satellite trail is sampled to build the polyline
const HISTORICAL_PATH_SAMPLE_INTERVAL = 1; //minutes

// Each segment of the historical trail reduces in opacity by this much
const HISTORICAL_PATH_OPACITY_DECAY = 0.04;

interface SatelliteTrackerProps {
    isActive: boolean;
    satelliteTracker: Satellite;
}

const SatelliteTracker2 = (props: SatelliteTrackerProps) => {
    const map = useMap();
    const canUpdateMarkerPosition = useRef(false);
    const lastPosition = useRef<LatLng>();
    const intervalRef = useRef<NodeJS.Timeout>();
    const satellitePositionMarkerRef = useRef<LeafletMarker>();
    const polylinesOverLast8HoursRef = useRef<LeafletPolyline[]>();
    const trailingPolylineRef = useRef<LeafletPolyline>();

    useEffect(() => {
        if (!map) {
            return;
        }

        if (props.isActive) {
            // Satellite position marker
            const currentPositionAndHeight = SatelliteUtil.positionAndHeightAtDate(new Date(), props.satelliteTracker);
            const currentPosition = currentPositionAndHeight.position;
            const icon = new LeafletIcon({
                iconSize: new LeafletPoint(64.0, 64.0),
                iconAnchor: new LeafletPoint(32.0, 32.0),
                popupAnchor: new LeafletPoint(32.0, 32.0),
                iconUrl: '/assets/map-categories/satellite-icon-active-blue.png',
            });
            canUpdateMarkerPosition.current = true;
            const marker = new LeafletMarker(currentPosition, { icon });
            marker.bindTooltip(
                SatelliteUtil.tooltipHTMLForSatellite(
                    props.satelliteTracker,
                    currentPositionAndHeight.height,
                    currentPositionAndHeight.velocity
                )
            );
            satellitePositionMarkerRef.current = marker;
            satellitePositionMarkerRef.current.addTo(map);

            // Trailing polyline (line left by satellite as it moves)
            const polyline = new LeafletPolyline([currentPosition]);
            trailingPolylineRef.current = polyline;
            intervalRef.current = setInterval(intervalFunction, SATELLITE_UPDATE_INTERVAL);
            trailingPolylineRef.current.addTo(map);

            // Historical polyline (sequence of opaque polylines from last N hours)
            const satellitePositionsOverLast8Hours = SatelliteUtil.positionsForLastNHours(
                HISTORICAL_PATH_DURATION,
                props.satelliteTracker,
                HISTORICAL_PATH_SAMPLE_INTERVAL
            );
            const satellitePositionsSplitAcrossAntiMeridian = SatelliteUtil.splitLatLngsAcrossAntiMeridian(
                satellitePositionsOverLast8Hours
            );
            const polylines = SatelliteUtil.splitLatLngsIntoGroupsTimeSeriesPolylines(
                satellitePositionsSplitAcrossAntiMeridian,
                HISTORICAL_PATH_OPACITY_DECAY
            );
            polylinesOverLast8HoursRef.current = polylines;
            polylinesOverLast8HoursRef.current.map((polyline) => {
                polyline.addTo(map);
            });
        } else {
            if (satellitePositionMarkerRef.current) {
                satellitePositionMarkerRef.current.remove();
            }

            if (trailingPolylineRef.current) {
                trailingPolylineRef.current.remove();
            }

            if (polylinesOverLast8HoursRef.current) {
                polylinesOverLast8HoursRef.current.map((polyline) => {
                    polyline.remove();
                });
            }

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [props.isActive]); // eslint-disable-line react-hooks/exhaustive-deps

    // Prevent update while zoom is in progress (causes marker to drift in viewport space).
    if (map) {
        map.on('zoomstart', () => {
            canUpdateMarkerPosition.current = false;
        });

        map.on('zoomend', () => {
            canUpdateMarkerPosition.current = true;
        });
    }

    const intervalFunction = () => {
        const nextPositionAndHeight = SatelliteUtil.positionAndHeightAtDate(new Date(), props.satelliteTracker);
        const nextPosition = nextPositionAndHeight.position;

        if (!nextPosition) {
            return;
        }

        if (map && satellitePositionMarkerRef.current && canUpdateMarkerPosition) {
            satellitePositionMarkerRef.current.setLatLng(nextPosition);
            satellitePositionMarkerRef.current.bindTooltip(
                SatelliteUtil.tooltipHTMLForSatellite(
                    props.satelliteTracker,
                    nextPositionAndHeight.height,
                    nextPositionAndHeight.velocity
                )
            );
        }

        // The trailing polyline might cross the anti-meridian while the user is watching
        // Push the trailing polyline into the historical and start a fresh polyline
        if (
            lastPosition.current &&
            polylinesOverLast8HoursRef.current &&
            trailingPolylineRef.current &&
            SatelliteUtil.doLatLngsCrossAntiMeridian(lastPosition.current, nextPosition)
        ) {
            polylinesOverLast8HoursRef.current.push(trailingPolylineRef.current);
            trailingPolylineRef.current = new LeafletPolyline([nextPosition]);
            trailingPolylineRef.current.addTo(map);
        }

        if (map && trailingPolylineRef.current) {
            trailingPolylineRef.current.addLatLng(nextPosition);
        }

        lastPosition.current = nextPosition;
    };

    return <React.Fragment />;
};

export default SatelliteTracker2;
