import React, { useState } from 'react';
import PurchaseFormStripe from './purchase-form-stripe';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { SubDomainType, BillingInfoDTO } from '../../api/model';
import Constants from '../../constants';
import BillingInformation from './billing-information';
import RequestSubdomainName from './request-subdomain-name';
import ConfirmSubdomain from './pricing-confirm-subdomain';
import Pricing from './pricing';
import { selectLoggedIn } from '../../store/Account/selectors';
import styled from 'styled-components';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import { actionSetSideDrawerModeAction } from '../../store/SideDrawer/actions';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { isMobileVersion } from '../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';

enum PurchasingState {
    SelectPlan,
    SetSubdomainName,
    SetBillingInfo,
    PayWithStripe,
    ConfirmSubdomain,
}

const SubdomainPricing = () => {
    const loggedIn = useSelector(selectLoggedIn);

    const dispatch = useDispatch();
    const setDrawerMode = (sideDrawerMode: SideDrawerMode) => dispatch(actionSetSideDrawerModeAction(sideDrawerMode));

    const [purchasingState, setPurchasingState] = useState<PurchasingState>(PurchasingState.SelectPlan);
    const [planType, setPlanType] = useState<SubDomainType>(SubDomainType.Free);
    const [domainName, setDomainName] = useState<string | undefined>(undefined);
    const [billingInfo, setBillingInfo] = useState<BillingInfoDTO | undefined>(undefined);
    const [requireLoggedIn, setRequireLoggedIn] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string | undefined>(undefined);

    const returnToPricing = () => {
        setPurchasingState(PurchasingState.SelectPlan);
        setDomainName(undefined);
    };

    const handleSelectedSubdomain = (planType: SubDomainType) => {
        if (planType === SubDomainType.Free) {
            if (isMobileVersion) {
                setDrawerMode(SideDrawerMode.MAPS);
            } else {
                setDrawerMode(SideDrawerMode.SHARE_MAP);
            }
            UriHelper.navigateToPath('/');
        } else {
            setPlanType(planType);
            setPurchasingState(PurchasingState.SetSubdomainName);
        }
    };

    const handleClickLogo = () => {
        Analytics.Event('Mobile - Head Navigation', 'Clicked Logo Soar Plus');
        // removes params and resets to base URI
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    if (purchasingState === PurchasingState.SelectPlan) {
        return (
            <React.Fragment>
                {isMobileVersion && <SoarLogo src={'/assets/logos/soar-icon-search.png'} onClick={handleClickLogo} />}
                <Pricing chooseSubdomainType={handleSelectedSubdomain} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {isMobileVersion && <SoarLogo src={'/assets/logos/soar-icon-search.png'} onClick={handleClickLogo} />}
            <SubdomainPurchaseContainer>
                {requireLoggedIn && !loggedIn && (
                    <LoginRegisterDialog
                        isOpen={true}
                        initialMode={LoginModalMode.REGISTER}
                        onClose={() => {
                            setPurchasingState(PurchasingState.SetSubdomainName);
                            setRequireLoggedIn(false);
                        }}
                    />
                )}

                {purchasingState === PurchasingState.SetSubdomainName && (
                    <div>
                        <RequestSubdomainName
                            name={domainName}
                            planType={planType}
                            setDomainName={(domainName: string) => {
                                setDomainName(domainName);
                                setPurchasingState(PurchasingState.SetBillingInfo);
                                setRequireLoggedIn(true);
                            }}
                            returnToPricing={returnToPricing}
                        />
                    </div>
                )}
                {purchasingState === PurchasingState.SetBillingInfo && domainName && (
                    <div>
                        <BillingInformation
                            data-sentry-block
                            planType={planType}
                            updatePaymentInfo={(billingInfo: BillingInfoDTO) => {
                                setBillingInfo(billingInfo);
                                setPurchasingState(PurchasingState.PayWithStripe);
                            }}
                            returnToPricing={returnToPricing}
                            domainName={domainName}
                            setCountryCode={(countryCode: string) => setCountryCode(countryCode)}
                            goBackOne={() => setPurchasingState(purchasingState - 1)}
                        />
                    </div>
                )}
                {purchasingState === PurchasingState.PayWithStripe && (
                    <StripeProvider apiKey={Constants.STRIPE_API_KEY}>
                        <Elements>
                            <PurchaseFormStripe
                                data-sentry-block
                                subDomainName={domainName}
                                subDomainType={planType}
                                planType={planType}
                                returnToPricing={returnToPricing}
                                billingInfo={billingInfo}
                                countryCode={countryCode}
                                reconfigureSubdomainName={() => setPurchasingState(PurchasingState.SetSubdomainName)}
                            />
                        </Elements>
                    </StripeProvider>
                )}
                {purchasingState === PurchasingState.ConfirmSubdomain && domainName && (
                    <div>
                        <ConfirmSubdomain
                            data-sentry-block
                            planType={planType}
                            subdomain={domainName}
                            billingInfo={billingInfo}
                            countryCode={countryCode}
                        />
                    </div>
                )}
            </SubdomainPurchaseContainer>
        </React.Fragment>
    );
};

export default SubdomainPricing;

const SoarLogo = styled.img`
    position: absolute;
    width: 40px;
    height: 37px;
    margin: 5px;
    z-index: 9999;
`;

const SubdomainPurchaseContainer = styled.div`
    background-image: url(/assets/subdomain/subdomain-image.jpg);
    background-size: cover;
`;
