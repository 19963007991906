import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import Root from './root';

import './styles/leaflet-1.9.3.css';
import './styles/leaflet.css';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/index.css';
import './components/MapView/map-view.css';

import Constants from './constants';

import TagManager from 'react-gtm-module';
import { clarity } from 'react-microsoft-clarity';

import { SENTRY_OPTIONS } from './tracking-configs';
import SoarHelper from './lib/soar-helper';

if (!SoarHelper.isLocalHost()) {
    Sentry.init(SENTRY_OPTIONS);
    clarity.init(Constants.CLARITY_ID);
}

TagManager.initialize({
    gtmId: Constants.GTAG_ID,
    dataLayer: {
        userId: '001',
        userProject: 'project',
    },
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<Root />);
