import styled from 'styled-components';

interface SentinelLowResolutionNoticeProps {
    className?: string;
}

const SentinelLowResolutionNotice = (props: SentinelLowResolutionNoticeProps) => {
    return (
        <Container className={props.className ?? ''}>
            <NoticeIcon src="/assets/floating-drawer-icons/low-resolution-icon.png" />
            <NoticeContainer>
                <NoticeText>Not happy with low resolution?</NoticeText>
                <NoticeText>Try selecting a smaller Area of Interest.</NoticeText>
            </NoticeContainer>
        </Container>
    );
};

export default SentinelLowResolutionNotice;

const Container = styled.div`
    user-select: none;
    margin: 12px;
    background: #eed926;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
`;

const NoticeIcon = styled.img`
    width: 34px;
    height: 34px;
    margin: 5px 12px 5px 5px;
`;

const NoticeContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const NoticeText = styled.div`
    color: black;
`;
