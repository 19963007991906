import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import ThinDrawer from '../../../../Drawer/Shared/thin-drawer';
import { StoaryDrawerContent, StoaryDrawerHeader } from '../../../../Drawer/drawer-control';
import GeoJSONProject from '../../Project/geojson-project';
import { parseSatellite, parseMapLayers } from '../stoary-utils';
import StoryBuilderAddPage from './story-builder-add-page';
import StoryBuilderProjectDetail from './story-builder-project-detail';
import StoryBuilderPageItem from './story-builder-page-item';
import StoryBuilderSaveButton from './story-builder-save-button';

import { setArrowsAction } from '../../../../../store/Annotations/Arrow/actions';
import { selectArrows } from '../../../../../store/Annotations/Arrow/selectors';
import { setCirclesAction } from '../../../../../store/Annotations/Circle/actions';
import { selectCircles } from '../../../../../store/Annotations/Circle/selectors';
import { setCoordinatesAction } from '../../../../../store/Annotations/Coordinate/actions';
import { selectCoordinates } from '../../../../../store/Annotations/Coordinate/selectors';
import { setFreehandDrawsAction } from '../../../../../store/Annotations/Freehand/actions';
import { selectFreehandDraws } from '../../../../../store/Annotations/Freehand/selectors';
import { setImagesAction } from '../../../../../store/Annotations/Images/actions';
import { selectImages } from '../../../../../store/Annotations/Images/selectors';
import { setMarkersAction } from '../../../../../store/Annotations/Marker/actions';
import { selectMarkers } from '../../../../../store/Annotations/Marker/selectors';
import { setMilitaryMarkersAction } from '../../../../../store/Annotations/MilitaryMarker/actions';
import { selectMilitaryMarkers } from '../../../../../store/Annotations/MilitaryMarker/selectors';
import { setPolylinesAction } from '../../../../../store/Annotations/Path/actions';
import { selectPolylines } from '../../../../../store/Annotations/Path/selectors';
import { setPolygonsAction } from '../../../../../store/Annotations/Polygon/actions';
import { selectPolygons } from '../../../../../store/Annotations/Polygon/selectors';
import { setRectanglesAction } from '../../../../../store/Annotations/Rectangle/actions';
import { selectRectangles } from '../../../../../store/Annotations/Rectangle/selectors';
import { selectTextBoxes } from '../../../../../store/Annotations/TextBox/selectors';
import { setSelectedAnnotationAction } from '../../../../../store/Annotations/actions';
import { selectMapBounds } from '../../../../../store/App/selectors';
import { selectStoaryPage } from '../../../../../store/Map/Stoaries/selectors';

import Arrow from '../../Arrow/arrow';
import Circle from '../../Circle/circle';
import Coordinate from '../../Coordinate/coordinate';
import FreehandPolyline from '../../FreehandPolyline/freehand-polyline';
import Image from '../../ImageTool/image';
import Marker from '../../Marker/marker';
import MilitaryMarker from '../../MilitaryMarker/military-marker';
import PolygonPath from '../../Polygon/polygon';
import Polyline from '../../Polyline/polyline';
import Rectangle from '../../Rectangle/rectangle';
import TextBox from '../../Text/textbox';

import ApiDraw from '../../../../../api/api-draw';
import ApiListings from '../../../../../api/api-listings';
import { arraymove } from '../../../../../lib/array-util';
import { setTextBoxesAction } from '../../../../../store/Annotations/TextBox/actions';
import { Annotation } from '../../../../../store/Annotations/reducer';
import { actionFlyTo } from '../../../../../store/App/actions';
import { selectStoaryId } from '../../../../../store/Map/Stoaries/selectors';
import { actionActiveMapCleared, actionActiveMapFetchById } from '../../../../../store/Map/SuperMap/actions';
import { actionSetTileLayerOpacity } from '../../../../../store/Map/TileLayer/actions';
import { selectTileLayersOpacity } from '../../../../../store/Map/TileLayer/selectors';
import ProjectAccess from '../../Project/project-access';
import { handleCopyingProjectAnnotations } from '../../FillPattern/pattern-util';
import Analytics from '../../../../../lib/user-analytics';
import { useBasemap } from '../../../../../store/Map/Basemap/use-basemap';
import {
    actionSentinelSelectAOI,
    actionSentinelSelectFeature,
    actionSentinelResetSelected,
    actionSentinelSelectFeatureOpacity,
} from '../../../../../store/Map/Sentinel/actions';
import {
    selectSentinelSelectedFeature,
    selectSentinelSelectedFeatureOpacity,
} from '../../../../../store/Map/Sentinel/selectors';
import {
    StoaryPage,
    StoaryPageMapLayer,
    StoaryProject,
    StoaryResponse,
    UpdateStoaryMetaRequest,
} from '../../../../../api/stoaryModel';

const StoryBuilderSidedrawer = () => {
    const { basemap, setBasemap } = useBasemap();

    const dispatch = useDispatch();
    const activeMap = useSelector(selectActiveAllMap);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);
    const activeSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const activeSentinelFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);

    const markers = useSelector(selectMarkers);
    const polylines = useSelector(selectPolylines);
    const polygons = useSelector(selectPolygons);
    const circles = useSelector(selectCircles);
    const rectangles = useSelector(selectRectangles);
    const freehandDraws = useSelector(selectFreehandDraws);
    const arrows = useSelector(selectArrows);
    const images = useSelector(selectImages);
    const textBoxes = useSelector(selectTextBoxes);
    const coordinates = useSelector(selectCoordinates);
    const militarySymbols = useSelector(selectMilitaryMarkers);
    const stoaryId = useSelector(selectStoaryId);
    const mapBounds = useSelector(selectMapBounds);

    const createdStoaryPage = useSelector(selectStoaryPage);

    const setMarkers = (markers: Marker[]) => dispatch(setMarkersAction(markers));
    const setPolylines = (polylines: Polyline[]) => dispatch(setPolylinesAction(polylines));
    const setPolygons = (polygons: PolygonPath[]) => dispatch(setPolygonsAction(polygons));
    const setCircles = (circles: Circle[]) => dispatch(setCirclesAction(circles));
    const setRectangles = (rectangles: Rectangle[]) => dispatch(setRectanglesAction(rectangles));
    const setFreehandDraws = (freehandDraws: FreehandPolyline[]) => dispatch(setFreehandDrawsAction(freehandDraws));
    const setArrows = (arrows: Arrow[]) => dispatch(setArrowsAction(arrows));
    const setImages = (images: Image[]) => dispatch(setImagesAction(images));
    const setCoordinates = (coordinates: Coordinate[]) => dispatch(setCoordinatesAction(coordinates));
    const setTextBoxes = (textBoxes: TextBox[]) => dispatch(setTextBoxesAction(textBoxes));
    const setMilitaryMarkers = (militaryMarkers: MilitaryMarker[]) =>
        dispatch(setMilitaryMarkersAction(militaryMarkers));
    const setSelectedAnnotation = (annotation: Annotation | undefined) =>
        dispatch(setSelectedAnnotationAction(annotation));

    const emptyProject: StoaryProject = {
        markers: [],
        polylines: [],
        polygons: [],
        circles: [],
        rectangles: [],
        freehandDraws: [],
        arrows: [],
        images: [],
        textBoxes: [],
        coordinates: [],
        militaryMarkers: [],
    };

    const emptyStoaryPageMapLayer: StoaryPageMapLayer[] = [];
    if (activeMap) {
        emptyStoaryPageMapLayer.push({
            listingId: activeMap.id,
            title: activeMap.title,
            author: activeMap.userName,
            opacity: tileLayersOpacity[activeMap.id] ?? 1,
        });
    }
    if (activeSentinelFeature) {
        emptyStoaryPageMapLayer.push({
            satelliteFeature: activeSentinelFeature,
            title: new Date(activeSentinelFeature.date).toLocaleDateString(),
            author: activeSentinelFeature.satellite,
            opacity: activeSentinelFeatureOpacity ?? 1,
        });
    }

    const emptyStoaryPage: StoaryPage = {
        pageTitle: 'Page 1',
        index: 0,
        id: uuidv4(),
        description: '',
        project: emptyProject,
        mapLayers: emptyStoaryPageMapLayer,
        viewportLocked: false,
        allViewportsLocked: false,
        activeBaseMap: basemap,
    };

    const [stoary, setStoary] = useState<StoaryResponse>();
    const [stoaryPages, setStoaryPages] = useState<StoaryPage[]>([createdStoaryPage ?? emptyStoaryPage]);
    const [selectedStoaryPage, setSelectedStoaryPage] = useState<StoaryPage>(createdStoaryPage ?? emptyStoaryPage);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (basemap) {
            const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;
            if (currentPage.activeBaseMap === basemap) return;
            const updatedPage = { ...currentPage, activeBaseMap: basemap };
            setStoaryPages((prev) => prev.map((p) => (p.id === currentPage.id ? updatedPage : p)));
        }
    }, [basemap, stoaryPages, selectedStoaryPage]);

    // Update the current page when the annotations change
    useEffect(() => {
        const project: StoaryProject = {
            ...selectedStoaryPage.project,
            markers: markers,
            polylines: polylines,
            polygons: polygons,
            circles: circles,
            rectangles: rectangles,
            freehandDraws: freehandDraws,
            arrows: arrows,
            images: images,
            textBoxes: textBoxes,
            activeMap: activeMap?.id,
            coordinates: coordinates,
            militaryMarkers: militarySymbols,
        };
        const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;
        const updatedPage = { ...currentPage, project: project };
        setStoaryPages((prev) => prev.map((p) => (p.id === currentPage.id ? updatedPage : p)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        markers,
        polylines,
        polygons,
        circles,
        rectangles,
        freehandDraws,
        arrows,
        images,
        textBoxes,
        coordinates,
        militarySymbols,
    ]);

    // Reset the project when unmounted otherwise the previous project will be used when the component is mounted again
    useEffect(() => {
        return () => {
            setSelectedStoaryPage(emptyStoaryPage);
            setStoaryPages([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update the map when the selected page changes
    useEffect(() => {
        dispatch(actionActiveMapCleared());
        dispatch(actionSentinelResetSelected());

        if (selectedStoaryPage.project) {
            setArrows(selectedStoaryPage.project.arrows);
            setMarkers(selectedStoaryPage.project.markers);
            setPolylines(selectedStoaryPage.project.polylines);
            setPolygons(selectedStoaryPage.project.polygons);
            setCircles(selectedStoaryPage.project.circles);
            setRectangles(selectedStoaryPage.project.rectangles);
            setFreehandDraws(selectedStoaryPage.project.freehandDraws);
            setImages(selectedStoaryPage.project.images);
            setTextBoxes(selectedStoaryPage.project.textBoxes);
            setCoordinates(selectedStoaryPage.project.coordinates);
            setMilitaryMarkers(selectedStoaryPage.project.militaryMarkers);
            setSelectedAnnotation(undefined);
        }

        if (selectedStoaryPage.mapLayers) {
            selectedStoaryPage.mapLayers.forEach((mapLayer) => {
                if (mapLayer.listingId) {
                    dispatch(actionActiveMapFetchById(mapLayer.listingId, undefined, true));
                    if (mapLayer.opacity !== undefined) {
                        dispatch(actionSetTileLayerOpacity(mapLayer.listingId, mapLayer.opacity));
                    }
                }
                if (mapLayer.satelliteFeature) {
                    const satelliteFeature = parseSatellite(mapLayer);
                    dispatch(actionSentinelSelectAOI(satelliteFeature.bbox));
                    dispatch(actionSentinelSelectFeature(satelliteFeature));
                    dispatch(actionSentinelSelectFeatureOpacity(mapLayer.opacity));
                }
            });
        }

        if (selectedStoaryPage.project && selectedStoaryPage.project.viewportBounds) {
            dispatch(actionFlyTo(selectedStoaryPage.project.viewportBounds as L.LatLngBounds, undefined, true));
        }

        if (selectedStoaryPage.activeBaseMap) {
            setBasemap(selectedStoaryPage.activeBaseMap);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStoaryPage, selectedStoaryPage.project?.viewportBounds]);

    // Load the selected map from the route /map/edit/{id}
    useEffect(() => {
        if (stoaryId) {
            ApiDraw.getStoary(Number(stoaryId))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((res: any) => {
                    Analytics.Event('Stoary - Builder', `Fetched story project ${stoaryId}`);
                    setStoary(res as StoaryResponse);
                    if (res.data && res.data.pages) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const pagesRaw: any[] = res.data.pages;
                        const pages: StoaryPage[] = pagesRaw.map((page, index) => {
                            const project = GeoJSONProject.import(JSON.stringify(page));

                            const mapLayers =
                                page.mapLayers?.map((mapLayer) => parseMapLayers(mapLayer)) ??
                                ([] as StoaryPageMapLayer[]);

                            if (page.map) {
                                mapLayers.push({
                                    listingId: page.map.id,
                                    title: page.map.title,
                                    author: page.map.author,
                                    opacity: page.map.opacity ?? 1,
                                });
                            }

                            const stoaryPage: StoaryPage = {
                                projectTitle: res.title,
                                pageTitle: page.pageTitle || page.title,
                                index: index,
                                id: page.project.id,
                                description: page.description || res.description,
                                project: project,
                                mapLayers: mapLayers,
                                viewportLocked: page.viewportLocked,
                                allViewportsLocked: page.allViewportsLocked || false,
                                activeBaseMap: page.activeBaseMap,
                            };
                            return stoaryPage;
                        });
                        setStoaryPages(pages);
                        setSelectedStoaryPage(pages[0]);
                    } else if (res.data) {
                        const mapLayers =
                            res.data?.project?.mapLayers.map((mapLayer) => parseMapLayers(mapLayer)) ??
                            ([] as StoaryPageMapLayer[]);

                        if (res.mapIds && res.mapIds.length) {
                            mapLayers.push({
                                listingId: res.mapIds[0],
                                title: 'Map Layer',
                                author: 'Soar User',
                                opacity: 1,
                            });
                        }

                        const stoaryPage: StoaryPage = {
                            pageTitle: res.title,
                            index: 0,
                            id: res.id,
                            description: res.description,
                            project: GeoJSONProject.import(JSON.stringify(res.data)),
                            mapLayers: mapLayers,
                            viewportLocked: res.viewportLocked,
                            allViewportsLocked: res.allViewportsLocked || false,
                            activeBaseMap: res.activeBaseMap,
                        };
                        setStoaryPages([stoaryPage]);
                        setSelectedStoaryPage(stoaryPage);
                    }
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    }, [stoaryId]);

    const handleDeletePage = (page: StoaryPage) => {
        Analytics.Event('Stoary - Builder', 'Clicked delete page');
        const currentPagePos = stoaryPages.findIndex((p) => p.id === page.id);
        const nextPagePos = currentPagePos === 0 ? 1 : currentPagePos - 1;
        const nextPage = stoaryPages[nextPagePos];
        setStoaryPages(stoaryPages.filter((p) => p.id !== page.id));
        setSelectedStoaryPage(nextPage);
    };

    const handleAddNewPage = () => {
        Analytics.Event('Stoary - Builder', 'Clicked add new page');
        const project = stoaryPages[stoaryPages.length - 1].allViewportsLocked
            ? {
                  // If all viewports are locked, create a new project with the same viewport bounds
                  ...emptyProject,
                  viewportBounds: stoaryPages[stoaryPages.length - 1].project.viewportBounds,
              }
            : {
                  // If all viewports are not locked, create an empty project
                  ...emptyProject,
              };

        const newPage: StoaryPage = {
            pageTitle: `Page ${stoaryPages.length + 1}`,
            index: stoaryPages.length,
            id: uuidv4(),
            description: '',
            project: project,
            viewportLocked: false,
            allViewportsLocked: stoaryPages[stoaryPages.length - 1].allViewportsLocked || false,
            activeBaseMap: basemap,
        };
        setStoaryPages([...stoaryPages, newPage]);
        setSelectedStoaryPage(newPage);
    };

    const handleCopyPage = () => {
        Analytics.Event('Stoary - Builder', 'Clicked copy page');
        const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;
        const titleCopy = currentPage?.pageTitle?.includes('(copy)')
            ? currentPage.pageTitle
            : `${currentPage.pageTitle} (copy)`;
        const updateUniqueProjectAnnotations = handleCopyingProjectAnnotations(currentPage.project);

        const projectCopy = updateUniqueProjectAnnotations ? updateUniqueProjectAnnotations : currentPage.project;

        let updatedViewportBounds: L.LatLngBounds | undefined = undefined;
        // If all viewports or the current viewport is locked, use the current viewport bounds in the copy
        if (currentPage.allViewportsLocked || currentPage.viewportLocked) {
            updatedViewportBounds = currentPage.project.viewportBounds as L.LatLngBounds;
        }

        const newPage: StoaryPage = {
            pageTitle: titleCopy,
            index: stoaryPages.length,
            id: uuidv4(),
            description: currentPage.description,
            project: {
                ...projectCopy,
                viewportBounds: updatedViewportBounds,
            },
            mapLayers: currentPage.mapLayers,
            viewportLocked: currentPage.viewportLocked,
            allViewportsLocked: currentPage.allViewportsLocked,
            activeBaseMap: currentPage.activeBaseMap,
        };

        setStoaryPages([...stoaryPages, newPage]);
        setSelectedStoaryPage(newPage);
    };

    const handleUpdateMapLayerFromPage = (updatedMapLayer: StoaryPageMapLayer) => {
        Analytics.Event(
            'Stoary - Builder',
            'Clicked update map layer from page',
            updatedMapLayer.listingId ?? updatedMapLayer.satelliteFeature?.previewUrl ?? ''
        );

        const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;

        const mapLayers = currentPage.mapLayers?.map((mapLayer) => {
            if (mapLayer.listingId && updatedMapLayer.listingId && mapLayer.listingId === updatedMapLayer.listingId) {
                return updatedMapLayer;
            }
            if (
                mapLayer.satelliteFeature &&
                updatedMapLayer.satelliteFeature &&
                mapLayer.satelliteFeature.previewUrl === updatedMapLayer.satelliteFeature.previewUrl
            ) {
                return updatedMapLayer;
            }
            return mapLayer;
        });

        const project = currentPage.allViewportsLocked
            ? currentPage.project
            : {
                  ...currentPage.project,
                  viewportBounds: currentPage.viewportLocked
                      ? currentPage.project.viewportBounds
                      : (mapBounds as L.LatLngBounds),
              };

        const updatedPage = { ...currentPage, project, mapLayers };

        const updatedPages = stoaryPages.map((p) => (p.id === currentPage.id ? updatedPage : p));

        setStoaryPages(updatedPages as StoaryPage[]);
        setSelectedStoaryPage(updatedPage as StoaryPage);
    };

    const handleAddMapToPage = (id: number) => {
        ApiListings.getListing(id).then((listing) => {
            const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;

            const mapLayer: StoaryPageMapLayer = {
                listingId: listing.id,
                title: listing.title,
                author: listing.userName || 'Soar User',
                opacity: 1,
            };

            /* // code for multiple layers support
            const mapLayers = currentPage.mapLayers ?? ([] as StoaryPageMapLayer[]);
            mapLayers.push(satelliteMapLayer);*/

            const mapLayers: StoaryPageMapLayer[] = [mapLayer]; // single mapLayer support

            const project = currentPage.allViewportsLocked
                ? currentPage.project
                : {
                      ...currentPage.project,
                      viewportBounds: currentPage.viewportLocked
                          ? currentPage.project.viewportBounds
                          : (mapBounds as L.LatLngBounds),
                  };

            const updatedPage = {
                ...currentPage,
                project,
                mapLayers,
            };

            const updatedPages = stoaryPages.map((p) => (p.id === currentPage.id ? updatedPage : p));
            setStoaryPages(updatedPages);
            setSelectedStoaryPage(updatedPage);

            Analytics.Event('Stoary - Builder', 'Clicked add map to page', id);
        });
    };

    const handleAddSatelliteToPage = (satelliteMapLayer: StoaryPageMapLayer) => {
        const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;

        /* // code for multiple layers support
        const mapLayers = currentPage.mapLayers ?? ([] as StoaryPageMapLayer[]);
        mapLayers.push(satelliteMapLayer);*/

        const mapLayers: StoaryPageMapLayer[] = [satelliteMapLayer]; // single mapLayer support

        const project = currentPage.allViewportsLocked
            ? currentPage.project
            : {
                  ...currentPage.project,
                  viewportBounds: currentPage.viewportLocked
                      ? currentPage.project.viewportBounds
                      : (mapBounds as L.LatLngBounds),
              };

        const updatedPage = { ...currentPage, project, mapLayers };
        const updatedPages = stoaryPages.map((p) => (p.id === currentPage.id ? updatedPage : p));
        setStoaryPages(updatedPages);
        setSelectedStoaryPage(updatedPage);

        const parsedSatellite = parseSatellite(satelliteMapLayer);

        dispatch(actionSentinelSelectAOI(parsedSatellite.bbox));
        dispatch(actionSentinelSelectFeature(parsedSatellite));

        Analytics.Event(
            'Stoary - Builder',
            'Clicked add sentinel to page',
            satelliteMapLayer.satelliteFeature?.previewUrl
        );
    };

    const handleDeleteMapLayerFromPage = (deletedMapLayer: StoaryPageMapLayer) => {
        const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;
        const updatedPage = {
            ...currentPage,
            mapLayers: currentPage.mapLayers?.filter((existingMapLayer) => {
                if (deletedMapLayer.listingId) {
                    if (!existingMapLayer.listingId) {
                        return true;
                    }

                    if (existingMapLayer.listingId && existingMapLayer.listingId !== deletedMapLayer.listingId) {
                        return true;
                    }
                }

                if (deletedMapLayer.satelliteFeature) {
                    if (!existingMapLayer.satelliteFeature) {
                        return true;
                    }

                    if (
                        existingMapLayer.satelliteFeature &&
                        existingMapLayer.satelliteFeature.downloadUrl !== deletedMapLayer.satelliteFeature.downloadUrl
                    ) {
                        return true;
                    }
                }

                return false;
            }),
        };

        const updatedPages = stoaryPages.map((p) => (p.id === selectedStoaryPage.id ? updatedPage : p));
        setStoaryPages(updatedPages as StoaryPage[]);

        if (deletedMapLayer.satelliteFeature) {
            Analytics.Event(
                'Stoary - Builder',
                'Clicked delete map layer from page',
                deletedMapLayer?.satelliteFeature.previewUrl || ''
            );
            dispatch(actionSentinelResetSelected());
        }
        if (deletedMapLayer.listingId) {
            Analytics.Event('Stoary - Builder', 'Clicked delete map layer from page', deletedMapLayer?.listingId || '');
            dispatch(actionActiveMapCleared());
        }
    };

    // NOTE: This is the behavior discussed with Chris, when a user clicks on the set viewport button
    // it will set the viewport of the selected page to the current map bounds, clicking again is not a
    // toggle but will set the viewport of the selected page to the current map bounds again.
    // It is also somewhat assumed the user now knows that they will need to click the viewport setting
    // ahgain to update the viewport to the current map bounds.
    const handleSetViewport = () => {
        Analytics.Event('Stoary - Builder', `Clicked set viewport`);
        const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;

        const resetPages = stoaryPages.map((page) => ({
            ...page,
            allViewportsLocked: false,
        }));

        const updatedPage: StoaryPage = {
            ...currentPage,
            project: {
                ...currentPage.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: true,
            allViewportsLocked: false,
        };

        const updatedPages = resetPages.map((page) => (page.id === selectedStoaryPage.id ? updatedPage : page));

        setStoaryPages(updatedPages);
        setSelectedStoaryPage(updatedPage);
    };

    // NOTE: This is the behavior discussed with Chris, when a user clicks on the set all viewports button
    // it will set the viewport of all pages to the current map bounds, clicking again is not a toggle but will
    // set the viewport of all pages to the current map bounds again.
    const handleSetAllPagesViewport = () => {
        Analytics.Event('Stoary - Builder', `Clicked set all viewports`);
        const currentPage = stoaryPages.find((p) => p.id === selectedStoaryPage.id) || selectedStoaryPage;
        const updatedPage: StoaryPage = {
            ...currentPage,
            project: {
                ...currentPage.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: false,
            allViewportsLocked: true,
        };

        const updatedPages = stoaryPages.map((page) => ({
            ...page,
            project: {
                ...page.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: false,
            allViewportsLocked: true,
        }));

        setStoaryPages(updatedPages);
        setSelectedStoaryPage(updatedPage);
    };

    const handlePageOrder = (page: StoaryPage, direction: 'up' | 'down') => {
        Analytics.Event('Stoary - Builder', `Clicked to reorder pages ${direction}`);
        const index = page.index;
        const newIndex = direction === 'up' ? index - 1 : index + 1;

        if ((direction === 'up' && index > 0) || (direction === 'down' && index < stoaryPages.length - 1)) {
            const reorderedPages = arraymove(stoaryPages, index, newIndex).map((p, i) => ({
                ...p,
                index: i,
            }));

            setStoaryPages(reorderedPages);
        }
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = Array.from(stoaryPages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedPages = items.map((item, index) => ({
            ...item,
            index,
        }));
        setStoaryPages(updatedPages);
    };

    const handleProjectUpdate = ({ title }: UpdateStoaryMetaRequest) => {
        setStoary({ ...stoary, title } as StoaryResponse);
    };

    return (
        <React.Fragment>
            {error ? (
                <ProjectAccess
                    error={error}
                    resetError={() => {
                        Analytics.Event('Stoary - Builder', `Clicked reset after project error`, error);
                        setError(undefined);
                    }}
                />
            ) : (
                <React.Fragment>
                    <EditBorder
                        isPageLocked={
                            selectedStoaryPage.allViewportsLocked ? true : selectedStoaryPage.viewportLocked || false
                        }
                    />

                    <ThinDrawer style={{ marginTop: '48px' }}>
                        <StoaryDrawerHeader collapsible title=" " />
                        <StoaryDrawerContent>
                            {stoary && <StoryBuilderProjectDetail project={stoary} onUpdate={handleProjectUpdate} />}
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="stoaryPages">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {stoaryPages.map((page, index) => (
                                                <Draggable key={page.id} draggableId={page.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <StoryBuilderPageItem
                                                                key={page.id}
                                                                page={page}
                                                                pageNumber={stoaryPages.length}
                                                                isSelected={selectedStoaryPage.id === page.id}
                                                                onSelect={(page: StoaryPage) => {
                                                                    if (selectedStoaryPage.id !== page.id) {
                                                                        setSelectedStoaryPage(page);
                                                                    }
                                                                    setSelectedAnnotation(undefined);
                                                                }}
                                                                onDelete={(page: StoaryPage) => {
                                                                    handleDeletePage(page);
                                                                }}
                                                                onUpdateMapLayerFromPage={(
                                                                    mapLayer: StoaryPageMapLayer
                                                                ) => {
                                                                    handleUpdateMapLayerFromPage(mapLayer);
                                                                }}
                                                                onDeleteMapLayerFromPage={(
                                                                    mapLayer: StoaryPageMapLayer
                                                                ) => {
                                                                    handleDeleteMapLayerFromPage(mapLayer);
                                                                }}
                                                                onSelectMapLayer={(mapLayer: StoaryPageMapLayer) => {
                                                                    if (mapLayer.satelliteFeature) {
                                                                        handleAddSatelliteToPage(mapLayer);
                                                                    } else if (mapLayer.listingId) {
                                                                        handleAddMapToPage(mapLayer.listingId);
                                                                    }
                                                                }}
                                                                onUpdatePageDescription={(description: string) => {
                                                                    const updatedPage = {
                                                                        ...page,
                                                                        description: description,
                                                                    };
                                                                    setStoaryPages(
                                                                        stoaryPages.map((p) =>
                                                                            p.id === page.id ? updatedPage : p
                                                                        )
                                                                    );
                                                                }}
                                                                onUpdatePageTitle={(title: string) => {
                                                                    const updatedPage = { ...page, pageTitle: title };
                                                                    setStoaryPages(
                                                                        stoaryPages.map((p) =>
                                                                            p.id === page.id ? updatedPage : p
                                                                        )
                                                                    );
                                                                }}
                                                                onSetPageViewport={() => handleSetViewport()}
                                                                onSetAllPagesViewport={() =>
                                                                    handleSetAllPagesViewport()
                                                                }
                                                                onMovePageDown={(page: StoaryPage) =>
                                                                    handlePageOrder(page, 'down')
                                                                }
                                                                onMovePageUp={(page: StoaryPage) =>
                                                                    handlePageOrder(page, 'up')
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <StoryBuilderAddPage onAddPage={handleAddNewPage} onCopyPage={handleCopyPage} />
                            <Divider />

                            <StoryBuilderSaveButton stoaryPages={stoaryPages} currentStoaryId={stoaryId} />
                        </StoaryDrawerContent>
                    </ThinDrawer>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default StoryBuilderSidedrawer;

const Divider = styled.div`
    height: 1px;
    border-top: 1px solid ${(props) => props.theme.color.lightGray};
`;

const EditBorder = styled.div<{ isPageLocked: boolean }>`
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    border: ${(props) => (props.isPageLocked ? '4px solid #e55801' : '4px solid #eed923')};
    z-index: 9997;
    user-select: none;
    pointer-events: none;
`;
