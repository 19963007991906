import React from 'react';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';

const SocialLinks = () => {
    return (
        <React.Fragment>
            <SocialContainer>
                <a
                    href="https://twitter.com/Soar_Earth"
                    title="X"
                    target="_blank"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer X')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/twitter.svg" />
                </a>

                <a
                    href="https://www.linkedin.com/company/soar-earth/"
                    target="_blank"
                    title="Linkedin"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer linkedin')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/linkedin.svg" />
                </a>
            </SocialContainer>
        </React.Fragment>
    );
};

export default SocialLinks;

const SocialContainer = styled.div`
    margin-top: 12px;
`;

const SocialIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 8px;
    cursor: pointer;
`;
