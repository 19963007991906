import { useState, ClipboardEvent } from 'react';
import styled from 'styled-components';
import { inputMaxValues } from '../../lib/limitation-constants';
import Analytics from '../../lib/user-analytics';

interface TagInputFieldProps {
    onTagInput: (tags: string[]) => void;
    initTags: string[];
}

const TagInputField = (props: TagInputFieldProps) => {
    const [tags, setTags] = useState<string[]>(props.initTags);
    const [tagInput, setTagInput] = useState<string>('');
    const onTagInput = props.onTagInput;

    const updateTagInput = (newTags: string[]) => {
        if (newTags.length < inputMaxValues.MAX_TAG_COUNT) {
            onTagInput(newTags);
            setTags(newTags);
        }
    };

    const removeTagAt = (index: number) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        updateTagInput(newTags);
    };

    const addTagItem = (tag: string) => {
        if (tag.length < inputMaxValues.MAX_TAG_LENGTH) {
            setTagInput(tag.replace(',', ''));
        }
    };

    const addTag = (tag: string) => {
        if (
            !tags.includes(tag) &&
            tag.length < inputMaxValues.MAX_TAG_LENGTH &&
            tags.length < inputMaxValues.MAX_TAG_COUNT
        ) {
            updateTagInput([...tags, tag]);
            Analytics.Event('Tags', `${tag}`);
        }
    };

    const inputKeyDown = (e) => {
        const val = `#${e.target.value.replace('#', '').trim()}`;
        if (e.key === 'Enter' && val) {
            addTag(val);
            setTagInput('');
        } else if (e.key === ',' && val) {
            const input = `${val.replace(',', '')}`;
            addTag(input);
            setTagInput('');
        } else if (e.key === '#' && val) {
            const input = `#${val.replace('#', '').trim()}`;
            if (input !== '#') {
                addTag(input);
                setTagInput('');
            }
        } else if (e.key === 'Backspace' && tagInput === '') {
            removeTagAt(tags.length - 1);
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { clipboardData } = e;
        const pastedText = clipboardData.getData('text');
        const removeSpaces = pastedText.replace(/\s/g, '').replaceAll('#', '').split(',');
        const removeDuplicates = removeSpaces.filter((item, index) => removeSpaces.indexOf(item) === index);
        const addHash = removeDuplicates.map((i) => '#' + i);
        updateTagInput([...tags, ...addHash]);
    };

    return (
        <Container>
            <TagList>
                {tags &&
                    tags.map((tag, i) => (
                        <TagItem key={i}>
                            {tag}
                            <TagRemoveButton type="button" onClick={() => removeTagAt(i)}>
                                +
                            </TagRemoveButton>
                        </TagItem>
                    ))}
                <TagInputItem>
                    <TagInput
                        type="text"
                        placeholder={
                            tags.length >= inputMaxValues.MAX_TAG_COUNT
                                ? `${inputMaxValues.MAX_TAG_COUNT} tag limit`
                                : tags.length > 0
                                ? ``
                                : `Separate tags by pressing enter or using a comma`
                        }
                        onKeyDown={inputKeyDown}
                        onChange={(e) => addTagItem(e.target.value)}
                        value={tagInput}
                        isMaximumTags={tags.length >= inputMaxValues.MAX_TAG_COUNT ? true : false}
                        onPaste={(e) => handlePaste(e)}
                    />
                </TagInputItem>
            </TagList>
        </Container>
    );
};

export default TagInputField;

const Container = styled.div`
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
`;

const TagList = styled.ul`
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 30px;
    list-style: none;
`;

const TagItem = styled.li`
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 2px solid rgba(255, 255, 255, 0.6);
    margin: 1px 2px;
    padding: 6px 5px;
    text-align: center;
    font-size: 12px;
    cursor: default;
`;

const TagRemoveButton = styled.button`
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    height: 16px;
    width: 16px;
    justify-content: center;
    line-height: 0;
    padding: 0;
    transform: rotate(45deg);
    margin-left: 2px;
    outline: none;

    :focus {
        outline: none !important;
    }
`;

const TagInputItem = styled.li`
    background: none;
    flex-grow: 1;
    padding: 8px 5px;
`;

interface TagInputProps {
    isMaximumTags: boolean;
}

const TagInput = styled.input<TagInputProps>`
    background: none;
    border: none;
    width: 100%;
    outline: none;
    -webkit-text-fill-color: ${(props) => (props.isMaximumTags ? 'rgb(255 0 0 / 80%);' : '')};
    color: rgba(255, 255, 255, 0.6);
`;
