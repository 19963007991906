import Api from '../api/api';
import { ListingDTO } from '../api/model';
import Constants from '../constants';
import UserHelper from './user-helper';

export type PreviewSize = 'small' | 'medium' | 'large' | 'sharing' | 'full';

export default class ListingHelper {
    public static getTitleFromListing(listing: ListingDTO) {
        const title = listing.title;
        if (title !== undefined) {
            return title;
        }

        // Get title from the filename
        const filenameSubstring = listing.filename.substring(listing.filename.lastIndexOf('/') + 1);
        const fileExtensionName = filenameSubstring.substring(filenameSubstring.lastIndexOf('.'));
        const filenameTitle = filenameSubstring.replace(fileExtensionName, '');
        if (filenameTitle) {
            return filenameTitle;
        }

        // not expected but as a last resort return the filename
        return `${listing.filehash}`;
    }

    /*
        This url is processed on cloudflare workers (cf-storage-proxy) and utilize cache where possible
    */
    public static getPreviewUrlForListing(listingId: number, size?: PreviewSize): string {
        return this.constructListingPreviewUrl(listingId, size || 'medium', UserHelper.getIdToken());
    }

    public static getPreviewUrlForListingSharing(listingId: number): string {
        return this.constructListingPreviewUrl(listingId, 'sharing');
    }

    private static constructListingPreviewUrl(listingId: number, size: PreviewSize, token?: string): string {
        let query = `subdomain=${Api.getSubdomain()}&size=${size}`;
        if (token) query += `&authorization=${token}`;
        return `https://sp.${Constants.DOMAIN}/listing-preview/${listingId}?${query}`;
    }

    /*
        This url is processed on cloudflare workers (cf-storage-proxy) and utilize cache where possible
    */
    public static getUrlForListingFile(listingId: number, listingFileId: number): string {
        const token = UserHelper.getIdToken();
        let query = `subdomain=${Api.getSubdomain()}`;
        if (token) query += `&authorization=${token}`;
        return `https://sp.${Constants.DOMAIN}/listing-file/${listingId}/${listingFileId}?${query}`;
    }
}
