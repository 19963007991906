import styled from 'styled-components';
import { ListingCardChip } from './listing-card-chip';

interface MapSearchDrawListingCardChipProps {
    chip: ListingCardChip;
}

const MapSearchDrawListingCardChip = (props: MapSearchDrawListingCardChipProps) => {
    return (
        <ChipContainer
            color={props.chip.color}
            fontColor={props.chip.fontColor}
            data-tooltip-id={`${props.chip.label}-chip-tooltip`}
            data-tooltip-content={props.chip.title}
        >
            {props.chip.label}
        </ChipContainer>
    );
};

export default MapSearchDrawListingCardChip;

const ChipContainer = styled.div<{
    color: string;
    fontColor?: string;
}>`
    background-color: ${(props) => (props.color ? props.color : 'transparent')};
    color: ${(props) => (props.fontColor ? props.fontColor : 'black')};
    border-style: solid;
    border-width: 0.5px;
    border-color: transparent;
    font-size: 11px;
    height: 22px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 6px;
    width: fit-content;
    text-transform: uppercase;
    margin-left: auto;
    margin-top: 4px;
    margin-right: 3px;
    float: right;

    &.inverted {
        background-color: transparent;
        border-color: ${(props) => (props.color ? props.color : 'white')};
        color: ${(props) => (props.color ? props.color : 'white')};
    }
`;
