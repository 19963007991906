import Constants from '../constants';
import SoarHelper from '../lib/soar-helper';
import { Config } from '../store/App/model';
import Api from './api';
import {
    BillingInfoDTO,
    CreateSubdomainDTO,
    DomainDTO,
    DomainUserDTO,
    ListingDTO,
    //@ts-ignore
    Permission,
    ResultDTO,
    SubDomainType,
    UploadCredentialsDTO,
    SubDomainPriceDTO,
} from './model';

// TODO: #3059 - Refactor 'any'
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ApiSubdomain extends Api {
    public static configGet(): Promise<Config> {
        return this.axios.get('/v1/subdomain/config', { cache: false }).then((res) => {
            return res.data;
        });
    }

    public static checkSubdomainNameAvailable(subDomainName: string): Promise<boolean> {
        return this.axios.get('/v1/subdomain/check?name=' + subDomainName, { cache: false }).then((result) => {
            return result.data.isSuccess;
        });
    }

    public static getSubdomainUsers(): Promise<DomainUserDTO[]> {
        return this.axios.get('/v1/subdomain/users', { cache: false }).then((result) => {
            const userData: DomainUserDTO[] = result.data.users.map((user) => {
                return {
                    userId: user.userId,
                    invitationId: user.invitationId,
                    email: user.email,
                    name: user.name,
                    permissions: SoarHelper.apiPermissionToEnum(user.permission),
                };
            });
            return userData;
        });
    }

    public static addUser(user: DomainUserDTO): Promise<DomainUserDTO> {
        const item = {
            email: user.email,
            permission: SoarHelper.permissionEnumToString(user.permissions),
        };
        const url = Constants.API_URL + '/v1/subdomain/users';
        return this.axios.post(url, item).then((result) => {
            const newUser = result.data;
            newUser.permission = SoarHelper.apiPermissionToEnum(newUser.permission);
            return newUser;
        });
    }

    public static updateUserPermission(userId: string, invitationId: string, permission: Permission): Promise<any> {
        const params = {
            permission: SoarHelper.permissionEnumToString(permission),
        };
        let updateIdType: string;
        if (userId) {
            updateIdType = `userId=${userId}`;
        } else {
            updateIdType = `invitationId=${invitationId}`;
        }
        return this.axios.put(`/v1/subdomain/users?${updateIdType}`, params).then((result) => {
            return result;
        });
    }

    public static deleteUser(userId: string, invitationId: string): Promise<ResultDTO> {
        let param;
        if (userId) {
            param = `userId=${userId}`;
        } else if (invitationId) {
            param = `invitationId=${invitationId}`;
        }
        return this.axios.delete('/v1/subdomain/users?' + param).then((result) => {
            return result.data;
        });
    }

    public static resendUserInvite(email: string, permission: Permission): Promise<any> {
        const item = {
            email: email,
            permission: SoarHelper.permissionEnumToString(permission),
        };
        const url = Constants.API_URL + '/v1/subdomain/user';
        return this.axios.post(url, item).then((result) => {
            return result.data;
        });
    }

    public static getSubdomainListings(): Promise<ListingDTO[]> {
        return this.axios.get('/v1/subdomain/listings', { cache: false }).then((result) => {
            return result.data.listings;
        });
    }

    public static deleteListing(listingId: number): Promise<any> {
        return this.axios.delete('/v1/listings/' + listingId).then((res) => {
            return res.data;
        });
    }

    public static refreshUploadCredentials(listingId: number | undefined): Promise<UploadCredentialsDTO> {
        if (!listingId) {
            return Promise.reject('No listing ID');
        }
        return this.axios.get(`/v1/listings/${listingId}/upload`, { cache: false }).then((res) => {
            return res.data;
        });
    }

    public static getSubdomainInfo(): Promise<DomainDTO> {
        return this.axios.get('/v1/subdomain', { cache: false }).then((result) => {
            return result.data;
        });
    }

    public static createSubdomain(
        name: string,
        type: SubDomainType,
        countryCode?: string,
        billingInfo?: BillingInfoDTO,
        voucher?: string,
        stripeToken?: string
    ): Promise<DomainDTO> {
        const item: CreateSubdomainDTO = {
            name,
            type,
            stripeToken,
        };
        if (countryCode) {
            item.countryCode = countryCode;
        }
        if (voucher) {
            item.voucher = voucher;
        }
        if (billingInfo) {
            item.firstName = billingInfo.firstName;
            item.lastName = billingInfo.lastName;
            item.company = billingInfo.company;
        }
        return this.axios.post('/v1/subdomain', item).then((result) => {
            return result.data;
        });
    }

    public static updateCreditCardDetails(stripeToken: string): Promise<DomainDTO> {
        const payload = {
            stripeToken: stripeToken,
        };
        return this.axios.put('/v1/subdomain/card', payload, { cache: false }).then((result) => {
            return result.data;
        });
    }

    public static cancelSubscription(): Promise<any> {
        return this.axios.put('/v1/subdomain/cancel').then((result) => {
            return result.data;
        });
    }

    public static setListingVisibility(listingId: number, visible: boolean): Promise<any> {
        return this.setListingsVisibility([listingId], visible);
    }

    public static setListingsVisibility(listings: number[], visible: boolean): Promise<any> {
        const payload = {
            ids: listings,
            visible,
        };

        return this.axios.post('/v1/subdomain/listing/public', payload).then((result) => {
            return result.data;
        });
    }

    public static getListingDownloadLink(listing: ListingDTO): Promise<string> {
        return this.axios
            .get(`${Constants.API_URL}/v1/listings/${listing.id}/download`, { cache: false })
            .then(function (response) {
                return response.data.presignedUrl;
            });
    }

    public static getSubdomainPrice(type: SubDomainType, countryCode: string, voucher?: string): Promise<any> {
        const coupon = {
            type,
            countryCode,
            voucher,
        };
        return this.axios
            .get('/v1/subdomain/price', { params: coupon })
            .then((result) => {
                return result.data;
            })
            .then((data) => {
                const cost: SubDomainPriceDTO = {
                    currency: data.currency,
                    freeTrialInDays: data.freeTrialInDays,
                    totalPrice: data.totalPrice / 100,
                    voucher: data.voucher,
                    voucherValue: data.voucherValue / 100,
                    voucherValueUsed: data.voucherValueUsed / 100,
                };
                if (data.durationInMonths) {
                    cost.durationInMonths = data.durationInMonths;
                }
                if (data.totalTax) {
                    cost.totalTax = data.totalTax / 100;
                }
                return cost;
            });
    }
}
