import React, { useState } from 'react';
import styled from 'styled-components';
import { Autocomplete } from './search-bar-autocomplete';

interface SearchBarAutocompleteResultProps {
    autocomplete: Autocomplete;
    onSelectAutocomplete: (autocomplete: Autocomplete) => void;
    onDeletePreviousSearchFromLocalStorage: (autocomplete: Autocomplete) => void;
}

const SearchBarAutocompleteResult = (props: SearchBarAutocompleteResultProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const onDeletePreviousSearchFromLocalStorage = (e) => {
        e.stopPropagation();
        props.onDeletePreviousSearchFromLocalStorage(props.autocomplete);
    };

    const renderPreviousResultItem = () => {
        return (
            <React.Fragment>
                <Icon className="fa fa-regular fa-clock" />
                <Text>{props.autocomplete.title}</Text>

                {isHovered ? (
                    <DeleteButton onClick={onDeletePreviousSearchFromLocalStorage}>Delete</DeleteButton>
                ) : null}
            </React.Fragment>
        );
    };

    const renderBasicResultItem = () => {
        return (
            <React.Fragment>
                <Text>{props.autocomplete.title}</Text>
            </React.Fragment>
        );
    };

    return (
        <Container
            data-testid="search-bar-autocomplete-result"
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            onClick={() => {
                props.onSelectAutocomplete(props.autocomplete);
            }}
        >
            {props.autocomplete.type === 'previousResult' && renderPreviousResultItem()}
            {props.autocomplete.type === 'listing' && renderBasicResultItem()}
            {props.autocomplete.type === 'search' && renderBasicResultItem()}
            {props.autocomplete.type === 'recommended' && renderBasicResultItem()}
        </Container>
    );
};

export default SearchBarAutocompleteResult;

const Container = styled.div`
    color: black;
    height: 31px;
    display: flex;
    padding: 4px 2px;

    cursor: pointer;

    &:hover {
        background: #f2f2f2;
    }
`;

const Icon = styled.i`
    color: black;
    font-size: 1rem;
    padding: 2px 4px;
`;

const Text = styled.span`
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
    margin: 0 0 0 4px;
    max-width: calc((100vw / 3) - 85px);
    overflow: hidden;
`;

const DeleteButton = styled.div`
    color: black;
    font-size: 1rem;
    padding: 1px 4px;
    margin-left: auto;
    align-items: right;
    text-align: right;
    cursor: pointer;
`;
