import React from 'react';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import UriHelper from '../../../lib/uri-helper';
import { StyledButton } from '../../Shared/styled-button';

const MobilePermissionNotification = () => {
    const handleClickedContinue = () => {
        Analytics.Event('Mobile', 'Mobile Permission denied', 'Clicked Search Imagery');
        UriHelper.navigateToPath('/maps');
    };

    return (
        <BackgroundContainer>
            <Container>
                <HeaderImage src="/assets/mobile/soar-suggest-notice.png" />
                <MainText>This project is private and can only be accessed on desktop or laptop computers.</MainText>
                <PageButton onClick={handleClickedContinue}>Continue to maps</PageButton>
            </Container>
        </BackgroundContainer>
    );
};

export default MobilePermissionNotification;

const BackgroundContainer = styled.div`
    background-color: rgb(34, 34, 34);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    overflow-y: auto;
`;

const Container = styled.div`
    border-radius: 4px;
    border: 1px solid #eed926;
    padding: 5px 15px;
    min-height: 100%;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);

    @media only screen and (max-height: 650px) {
        margin-top: 50px; // for ios the margin top needs to be set..
    }
`;

const HeaderImage = styled.img`
    margin-top: 10px;
    margin-bottom: 25px;
    width: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;
`;

const MainText = styled.div`
    margin: 10px auto 20px;
    color: inherit;
    font-size: inherit;
    text-align: left;
    line-height: 1.4;
    font-size: 14px;
    padding: 0 10px;
`;

const PageButton = styled(StyledButton)`
    margin: 15px auto 15px;
    font-size: 14px;
    text-transform: initial;
`;
