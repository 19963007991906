import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';

const activeStyle = `
	background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%229.75004%22%20cy%3D%229.75001%22%20r%3D%224.91667%22%20stroke%3D%22black%22%2F%3E%0A%3Cpath%20d%3D%22M13.5%2013.5007L16.8334%2016.8337%22%20stroke%3D%22black%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M1%207.66667V3C1%201.89543%201.89543%201%203%201H7.66667%22%20stroke%3D%22black%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M1%2014.3333V19C1%2020.1046%201.89543%2021%203%2021H7.66667%22%20stroke%3D%22black%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M21%207.66667V3C21%201.89543%2020.1046%201%2019%201H14.3333%22%20stroke%3D%22black%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M21%2014.3333V19C21%2020.1046%2020.1046%2021%2019%2021H14.3333%22%20stroke%3D%22black%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A') !important;
	background-color: #eed926 !important;
`;

const BoxZoomControl = createControlComponent(() => {
    return new L.Control.BoxZoomControl({
        position: 'topright',
        activeStyle: activeStyle,
    });
});

export default BoxZoomControl;

// TODO: Overhaul to make it look and feel like the other annotation tools
L.Control.BoxZoomControl = L.Control.extend({
    _isActive: false,
    _startLatLng: null,
    _drawPolygon: null,
    _boxZoomButton: null,
    _mouseMoved: false,
    _activeStyle: null,

    initialize: function (options) {
        this.options.position = options.position;
        this._activeStyle = options.activeStyle;
    },
    onAdd: function () {
        const boxZoomButton = L.DomUtil.create('button');
        boxZoomButton.setAttribute('id', 'box-zoom-button');
        boxZoomButton.setAttribute('data-testid', 'box-zoom-button');

        this._boxZoomButton = boxZoomButton;

        boxZoomButton.onclick = (e) => {
            e.stopPropagation();

            if (this._isActive) {
                this._stop();
            } else {
                this._start();
            }
        };

        return boxZoomButton;
    },
    _start: function () {
        const map = this._map;
        map.dragging.disable();
        const boxZoomButton = this._boxZoomButton;
        boxZoomButton.setAttribute('style', this._activeStyle);
        L.DomUtil.addClass(map.getContainer(), 'leaflet-crosshair');
        this._isActive = true;
        this._mouseMoved = false;
        this._startLatLng = null;

        L.DomEvent.on(map, 'mousedown', this._handleMouseDown, this);
        L.DomEvent.on(map, 'mousemove', this._handleMouseMove, this);
        L.DomEvent.on(map, 'mouseup', this._handleMouseUp, this);

        document.addEventListener('keyup', (e) => this._handleKeyUp(e));
    },
    _stop: function () {
        const map = this._map;
        map.dragging.enable();
        const boxZoomButton = this._boxZoomButton;
        boxZoomButton.setAttribute('style', '');
        L.DomUtil.removeClass(map.getContainer(), 'leaflet-crosshair');

        L.DomEvent.off(map, 'mousedown', this._handleMouseDown, this);
        L.DomEvent.off(map, 'mousemove', this._handleMouseMove, this);
        L.DomEvent.off(map, 'mouseup', this._handleMouseUp, this);
        L.DomEvent.off(map, 'keyup', this._handleKeyUp, this);

        document.removeEventListener('keyup', (e) => this._handleKeyUp(e));

        this._isActive = false;
        this._mouseMoved = false;
        this._startLatLng = null;

        if (this._drawPolygon) {
            map.removeLayer(this._drawPolygon);
            this._drawPolygon = null;
        }
    },
    _handleKeyUp: function (e) {
        if (e.key === 'Escape') {
            this._stop();
        }
    },
    _handleMouseDown: function (e) {
        this._startLatLng = e.latlng;
    },
    _handleMouseMove: function (e) {
        if (!this._startLatLng || !e.latlng) {
            return;
        }

        e.originalEvent.preventDefault();
        this._mouseMoved = true;

        const ne = this._startLatLng;
        const nw = new L.LatLng(this._startLatLng.lat, e.latlng.lng);
        const sw = e.latlng;
        const se = new L.LatLng(e.latlng.lat, this._startLatLng.lng);

        if (!this._drawPolygon) {
            this._drawPolygon = new L.Polygon([ne, nw, sw, se]);
            this._map.addLayer(this._drawPolygon);
        } else {
            this._drawPolygon.setLatLngs([ne, nw, sw, se]);
        }
    },
    _handleMouseUp: function (e) {
        const map = this._map;
        if ((!this._mouseMoved && this._isActive) || (!this._startLatLng || !this._isActive)) {
            this._stop();
            return;
        }

        const ne = this._startLatLng;
        const sw = e.latlng;

        const bounds = L.latLngBounds([ne, sw]);
        map.fitBounds(bounds, { animate: false });
        this._stop();
    },
});

L.control.boxZoomControl = (opts) => {
    return new L.Control.BoxZoomControl({ ...opts });
};
