import { useCallback } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch } from 'react-redux';

import Arrow from '../Arrow/arrow';
import Circle from '../Circle/circle';
import Coordinate from '../Coordinate/coordinate';
import FreehandPolyline from '../FreehandPolyline/freehand-polyline';
import Image from '../ImageTool/image';
import Marker from '../Marker/marker';
import MilitaryMarker from '../MilitaryMarker/military-marker';
import Polyline from '../Polyline/polyline';
import PolygonPath from '../Polygon/polygon';
import Rectangle from '../Rectangle/rectangle';
import TextBox from '../Text/textbox';

import { setArrowsAction } from '../../../../store/Annotations/Arrow/actions';
import { setCirclesAction } from '../../../../store/Annotations/Circle/actions';
import { setCoordinatesAction } from '../../../../store/Annotations/Coordinate/actions';
import { setFreehandDrawsAction } from '../../../../store/Annotations/Freehand/actions';
import { setImagesAction } from '../../../../store/Annotations/Images/actions';
import { setMarkersAction } from '../../../../store/Annotations/Marker/actions';
import { setMilitaryMarkersAction } from '../../../../store/Annotations/MilitaryMarker/actions';
import { setPolylinesAction } from '../../../../store/Annotations/Path/actions';
import { setPolygonsAction } from '../../../../store/Annotations/Polygon/actions';
import { setRectanglesAction } from '../../../../store/Annotations/Rectangle/actions';
import { setTextBoxesAction } from '../../../../store/Annotations/TextBox/actions';
import { setZIndexAction } from '../../../../store/Annotations/actions';
import LayersUtil, { defaultZIndex } from '../layers-util';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { StoaryProject } from '../../../../api/stoaryModel';

export const useProjectAnnotationHandler = () => {
    const map = useMap();
    const dispatch = useDispatch();

    const setMarkers = useCallback((markers: Marker[]) => dispatch(setMarkersAction(markers)), [dispatch]);
    const setMilitaryMarkers = useCallback(
        (militaryMarkers: MilitaryMarker[]) => dispatch(setMilitaryMarkersAction(militaryMarkers)),
        [dispatch]
    );
    const setPolylines = useCallback((polylines: Polyline[]) => dispatch(setPolylinesAction(polylines)), [dispatch]);
    const setPolygons = useCallback((polygons: PolygonPath[]) => dispatch(setPolygonsAction(polygons)), [dispatch]);
    const setCircles = useCallback((circles: Circle[]) => dispatch(setCirclesAction(circles)), [dispatch]);
    const setRectangles = useCallback(
        (rectangles: Rectangle[]) => dispatch(setRectanglesAction(rectangles)),
        [dispatch]
    );
    const setFreehandDraws = useCallback(
        (freehandDraws: FreehandPolyline[]) => dispatch(setFreehandDrawsAction(freehandDraws)),
        [dispatch]
    );
    const setArrows = useCallback((arrows: Arrow[]) => dispatch(setArrowsAction(arrows)), [dispatch]);
    const setImages = useCallback((images: Image[]) => dispatch(setImagesAction(images)), [dispatch]);
    const setTextBoxes = useCallback((textBoxes: TextBox[]) => dispatch(setTextBoxesAction(textBoxes)), [dispatch]);
    const setCoordinates = useCallback(
        (coordinates: Coordinate[]) => dispatch(setCoordinatesAction(coordinates)),
        [dispatch]
    );

    const handleSetProjectAnnotations = (
        project: StoaryProject,
        viewportLocked?: boolean,
        allViewportsLocked?: boolean
    ): void => {
        //Override the zIndex of each annotation with the zIndex from the project
        let highestZIndex = defaultZIndex;
        // find the highest zIndex, this also supports old projects with old implementation of layers
        project.markers.forEach((marker) => {
            if (marker.zIndex > highestZIndex) {
                highestZIndex = marker.zIndex;
            }
        });
        project.militaryMarkers.forEach((marker) => {
            if (marker.zIndex > highestZIndex) {
                highestZIndex = marker.zIndex;
            }
        });
        project.polylines.forEach((polyline) => {
            if (polyline.zIndex > highestZIndex) {
                highestZIndex = polyline.zIndex;
            }
        });
        project.polygons.forEach((polygon) => {
            if (polygon.zIndex > highestZIndex) {
                highestZIndex = polygon.zIndex;
            }
        });
        project.circles.forEach((circle) => {
            if (circle.zIndex > highestZIndex) {
                highestZIndex = circle.zIndex;
            }
        });
        project.rectangles.forEach((rectangle) => {
            if (rectangle.zIndex > highestZIndex) {
                highestZIndex = rectangle.zIndex;
            }
        });
        project.freehandDraws.forEach((freehand) => {
            if (freehand.zIndex > highestZIndex) {
                highestZIndex = freehand.zIndex;
            }
        });
        project.arrows.forEach((arrow) => {
            if (arrow.zIndex > highestZIndex) {
                highestZIndex = arrow.zIndex;
            }
        });
        project.images.forEach((image) => {
            if (image.zIndex > highestZIndex) {
                highestZIndex = image.zIndex;
            }
        });
        project.textBoxes.forEach((textBox) => {
            if (textBox.zIndex > highestZIndex) {
                highestZIndex = textBox.zIndex;
            }
        });
        project.coordinates.forEach((coordinate) => {
            if (coordinate.zIndex > highestZIndex) {
                highestZIndex = coordinate.zIndex;
            }
        });

        setMarkers([...project.markers]);
        setMilitaryMarkers([...project.militaryMarkers]);
        setPolylines([...project.polylines]);
        setPolygons([...project.polygons]);
        setCircles([...project.circles]);
        setRectangles([...project.rectangles]);
        setFreehandDraws([...project.freehandDraws]);
        setArrows([...project.arrows]);
        setImages([...project.images]);
        setTextBoxes([...project.textBoxes]);
        setCoordinates([...project.coordinates]);

        //Adds aesthetically pleasing buffer around the project bounds if it is not inside the viewport
        const VIEWPORT_BUFFER = 0.5;
        const MOBILE_VIEWPORT_BUFFER = 0;
        const MOBILE_VIEWPORT_PAD = -0.3; // Apply a negative pad to zoom in a bit more on mobile

        const viewportBounds = project.viewportBounds as L.LatLngBounds;
        const { isInsideBounds, projectBounds } = LayersUtil.getProjectInsideBounds(
            project,
            viewportBounds,
            isMobileVersion ? MOBILE_VIEWPORT_BUFFER : VIEWPORT_BUFFER
        );

        /**
         * The mobile view needs a little padding to make the view more aesthetically pleasing (applied padding is bias to what I think looks best)
         * Attempted different approaches like center and zoom levels but the best result came from the negative pad.
         * In the original implementation when a user is zoomed right out the "center" can look a bit off, this negates that issue
         * but could be further improved by removing the side drawer pixel width from the calculation, bur for now see how this goes.
         */
        const deviceViewportBounds = isMobileVersion ? viewportBounds.pad(MOBILE_VIEWPORT_PAD) : viewportBounds;
        const deviceProjectBounds = isMobileVersion ? projectBounds?.pad(MOBILE_VIEWPORT_PAD) : projectBounds;

        if (allViewportsLocked) {
            // If allViewportsLocked use the viewport bounds
            map.flyToBounds(deviceViewportBounds);
        } else if (!viewportLocked && !isInsideBounds) {
            // If not locked and not inside the viewport, use the project bounds
            map.flyToBounds(deviceProjectBounds);
        } else if (viewportBounds) {
            // Fly to the viewport bounds
            map.flyToBounds(deviceViewportBounds);
        }

        dispatch(setZIndexAction(highestZIndex + 1)); // set next available zIndex
    };

    return {
        handleSetProjectAnnotations,
    };
};
