import styled from 'styled-components';
import ListingHelper from '../../../../lib/listing-helper';
import ImageWithFallback from '../../Shared/image-with-fallback';
import { ListingCardChip, ListingDTOWithChip } from './listing-card-chip';
import MapSearchDrawListingCardChip from './map-search-draw-listing-card-chip';

export interface PreviewTileProps {
    listing: ListingDTOWithChip;
    isUserNameDisabled?: boolean;
    chip?: ListingCardChip;
}

const MapSearchDrawListingCard = (props: PreviewTileProps) => {
    return (
        <Container>
            <PreviewImageContainer>
                <PreviewImage
                    src={ListingHelper.getPreviewUrlForListing(props.listing.id, 'small') || undefined}
                    alt={props.listing.title}
                    smallPreview
                    height="100%"
                />
            </PreviewImageContainer>
            {!props.isUserNameDisabled ? (
                <ContentContainer>
                    <Title>{props.listing.title}</Title>
                    <AuthorAndChipContentContainer>
                        <SubText maxWidth={props.chip ? '63%' : '100%'}>{`by ${
                            props.listing.userName || 'Soar User'
                        }`}</SubText>
                        {props.chip ? <MapSearchDrawListingCardChip chip={props.chip} /> : null}
                    </AuthorAndChipContentContainer>
                </ContentContainer>
            ) : (
                <ProfileContentContainer>
                    <ProfileTitle>{props.listing.title}</ProfileTitle>
                </ProfileContentContainer>
            )}
        </Container>
    );
};

export default MapSearchDrawListingCard;

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
`;

const PreviewImageContainer = styled.div`
    height: 70px;
    width: 70px;
`;

const PreviewImage = styled(ImageWithFallback)`
    height: 100%;
    width: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin: 0;
    padding: 0;
    object-fit: cover;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 80%;
    align-self: end;
    padding: 0px 2px 0 10px;
`;

const AuthorAndChipContentContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Title = styled.p`
    color: ${(props) => props.theme.color.yellow} !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p<{ maxWidth: string }>`
    color: rgba(255, 255, 255, 0.67);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    max-width: ${(props) => props.maxWidth};
`;

const ProfileContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 75%;
    align-self: center;
    padding: 0px 10px;
`;

const ProfileTitle = styled.p`
    color: ${(props) => props.theme.color.yellow} !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;
