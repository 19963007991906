import { useState } from 'react';
import styled from 'styled-components';

import ApiDraw from '../../../../../api/api-draw';
import StoryBuilderProjectDetailModal from './story-builder-project-detail-modal';
import { StoaryResponse, UpdateStoaryMetaRequest } from '../../../../../api/stoaryModel';

interface StoryBuilderProjectDetailProps {
    project: StoaryResponse;
    onUpdate?: (payload: UpdateStoaryMetaRequest) => void;
}

const StoryBuilderProjectDetail = (props: StoryBuilderProjectDetailProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const handleSubmitProject = (title: string) => {
        const projectPayload: UpdateStoaryMetaRequest = {
            title,
            public: props.project.public,
            sharePermissions: props.project.sharePermissions,
        };

        ApiDraw.updateStoary(props.project.id, projectPayload)
            .then(() => {
                setIsOpen(false);
                if (props.onUpdate) {
                    props.onUpdate(projectPayload);
                }
            })
            .catch((e) => {
                console.log({ e });
                setError(e);
            });
    };

    return (
        <Container>
            <ProjectTitleContainer>
                <ProjectTitle title={props.project.title}>{props.project.title}</ProjectTitle>
                <ProjectEditIcon className={'fa fa-pen'} title="Edit Stoary Title" onClick={() => setIsOpen(true)} />
                <StoryBuilderProjectDetailModal
                    project={props.project}
                    isOpen={isOpen}
                    toggle={() => setIsOpen(!isOpen)}
                    onSubmit={handleSubmitProject}
                    error={error}
                />
            </ProjectTitleContainer>
        </Container>
    );
};

export default StoryBuilderProjectDetail;

const Container = styled.div`
    margin: 8px;
    padding: 8px;
`;

const ProjectTitleContainer = styled.div`
    display: flex;
`;
const ProjectTitle = styled.h1`
    color: white;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: auto;
    flex: 1;

    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    user-select: none;
`;

const ProjectEditIcon = styled.i`
    display: flex;
    justify-content: flex-end;
    margin: 5px 5px 0px 8px;
    padding: 5px;
    color: white;
    font-size: 12px;
    pointer-events: all;
    cursor: pointer;

    :hover {
        color: #eed926;
    }
`;
