import React from 'react';
import { StyledButton } from '../../../Shared/styled-button';
import SoarModal from '../../../Shared/soar-modal';
import styled from 'styled-components';
import Marker from './marker';
import { getMarkerIconsByCategory } from './marker-util';
import MarkerOptionsCategory from './marker-options-category';
import { useSelector } from 'react-redux';
import { selectMarkerColor } from '../../../../store/Annotations/Marker/selectors';

interface MarkerSelectModalProps {
    marker: Marker;
    onUpdate: (marker: Marker) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    markers?: () => void;
}

const MarkerSelectModal = (props: MarkerSelectModalProps) => {
    const lastUsedColor = useSelector(selectMarkerColor);

    return (
        <React.Fragment>
            <IconMarkerButton onClick={() => props.setOpen(!props.open)}>Show more</IconMarkerButton>
            <MarkerModal
                isOpen={props.open}
                toggle={() => props.setOpen(false)}
                title={'Select a Marker'}
                width="475px"
            >
                <MarkerOptionsCategory
                    title={'Urban'}
                    marker={props.marker}
                    onUpdate={props.onUpdate}
                    icons={getMarkerIconsByCategory('Urban', props.marker.color || lastUsedColor)}
                />
                <MarkerOptionsCategory
                    title={'Transport'}
                    marker={props.marker}
                    onUpdate={props.onUpdate}
                    icons={getMarkerIconsByCategory('Transport', props.marker.color || lastUsedColor)}
                />
                <MarkerOptionsCategory
                    title={'Nature'}
                    marker={props.marker}
                    onUpdate={props.onUpdate}
                    icons={getMarkerIconsByCategory('Nature', props.marker.color || lastUsedColor)}
                />
            </MarkerModal>
        </React.Fragment>
    );
};

export default MarkerSelectModal;

const IconMarkerButton = styled(StyledButton)`
    margin: 8px 0;
    font-size: 14px;
    padding: 5px 10px;
    margin-left: 23px;
    text-transform: none;

    &:hover {
        opacity: 1;
    }
`;

const MarkerModal = styled(SoarModal)`
    margin-top: 135px;
`;
