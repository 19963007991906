import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';
import L from 'leaflet';
import Arrow from '../Arrow/arrow';
import Rectangle from '../Rectangle/rectangle';
import Polyline from '../Polyline/polyline';
import PolygonPath from '../Polygon/polygon';
import Image from '../ImageTool/image';
import { calculatePossibleSnapPoints, hiddenSnapPointIcon, visibleSnapPointIcon } from './annotation-snap-points-util';

export interface SnapPointElementProps {
    instance: Polyline | PolygonPath | Rectangle | Arrow | Image;
    pane: string;
}

export const createSnapPointElement = (props, context: LeafletContextInterface) => {
    const annotationSnapLayer = new L.LayerGroup([], { pane: props.pane });
    const annotationSnapLayerElement = createElementObject<L.LayerGroup>(annotationSnapLayer, context);

    const snapPoints = calculatePossibleSnapPoints();
    const snapPointMarkers: L.Marker[] = [];
    let highlightedSnapPoint: L.LatLng | undefined = undefined;

    const onMouseOverSnapPoint = (e: L.LeafletMouseEvent) => {
        const target: L.Marker = e.target;
        highlightedSnapPoint = target.getLatLng();
        target.setIcon(visibleSnapPointIcon);
    };

    const onMouseOutSnapPoint = (e: L.LeafletMouseEvent) => {
        const target: L.Marker = e.target;
        highlightedSnapPoint = undefined;
        target.setIcon(hiddenSnapPointIcon);
    };

    const onMouseUpSnapPoint = (e: L.LeafletMouseEvent) => {
        if (highlightedSnapPoint) {
            const positions = [...(props.instance.getLatLngs() as L.LatLng[]), highlightedSnapPoint];
            props.instance.setLatLngs(positions);
        } else {
            const positions = [...(props.instance.getLatLngs() as L.LatLng[]), e.latlng];
            props.instance.setLatLngs(positions);
        }
    };

    annotationSnapLayerElement.instance.on('add', () => {
        snapPoints?.forEach((snapPoint) => {
            const marker = new L.Marker(snapPoint, {
                interactive: true,
                icon: hiddenSnapPointIcon,
                pane: props.pane,
            });
            context.map.addLayer(marker);
            marker.on('mouseover', onMouseOverSnapPoint);
            marker.on('mouseout', onMouseOutSnapPoint);
            marker.on('mouseup', onMouseUpSnapPoint);
            snapPointMarkers.push(marker);
        });
    });

    // All except for the arrow tool use this to update the path
    annotationSnapLayerElement.instance.on('update', (e: L.LeafletMouseEvent) => {
        if (highlightedSnapPoint) {
            const positions = [...(props.instance.getLatLngs() as L.LatLng[]), highlightedSnapPoint];
            props.instance.setLatLngs(positions);
        } else {
            const positions = [...(props.instance.getLatLngs() as L.LatLng[]), e.latlng];
            props.instance.setLatLngs(positions);
        }
    });

    // Arrow tool uses this to update the latlng points
    // Before the arrow is created, the latlng points are updated
    annotationSnapLayerElement.instance.on('update-arrow', (e: L.LeafletMouseEvent) => {
        if (highlightedSnapPoint) {
            const startLatLng = props.instance.getLatLngs()[0];
            const endLatLng = highlightedSnapPoint;
            props.instance.setLatLngs([startLatLng, endLatLng]);
        } else {
            const startLatLng = props.instance.getLatLngs()[0];
            const endLatLng = e.latlng;
            props.instance.setLatLngs([startLatLng, endLatLng]);
        }
    });

    annotationSnapLayerElement.instance.on('remove', () => {
        snapPointMarkers?.forEach((snapPointMarker) => {
            snapPointMarker.off('mouseover', onMouseOverSnapPoint);
            snapPointMarker.off('mouseout', onMouseOutSnapPoint);
            snapPointMarker.off('mouseup', onMouseUpSnapPoint);
            context.map.removeLayer(snapPointMarker);
        });
    });

    return annotationSnapLayerElement;
};
