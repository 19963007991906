import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setSelectedFillPatternAction } from '../../../../store/Annotations/actions';
import { selectFillPatterns, selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import { Pattern, availablePatterns } from '../FillPattern/availablePatterns';
import { handlePatternFillUrl, handlePatternPreview } from '../FillPattern/pattern-util';
import { isHexDark } from '../../../../lib/color-util';

interface FillPatternProps {
    selectedColor: string;
    fillPattern: string;
    onSetFillPattern: (patternFillUrl: string) => void;
}

const FillPattern = (props: FillPatternProps) => {
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedFillPatterns = useSelector(selectFillPatterns);

    const dispatch = useDispatch();

    const handleSelectedFill = (pattern: Pattern) => {
        const patternFillUrl = handlePatternFillUrl(pattern);
        const newPattern = {
            ...pattern,
            shapeId: selectedAnnotation?.id,
        };
        const filteredPatterns = selectedFillPatterns.filter((p) => p.shapeId !== selectedAnnotation?.id);
        dispatch(setSelectedFillPatternAction([...filteredPatterns, newPattern]));
        props.onSetFillPattern(patternFillUrl);
    };

    return (
        <PatternListContainer>
            {availablePatterns &&
                selectedAnnotation &&
                // @ts-ignore // hrmm selectedAnnotation options... need to check later...
                availablePatterns(selectedAnnotation.id, props.selectedColor).map((pattern: Pattern, idx) => (
                    <PatternContainer
                        selected={pattern.id === props.fillPattern}
                        key={idx}
                        onClick={() => handleSelectedFill(pattern)}
                        data-testid="fill-pattern-select-swatch"
                    >
                        <PatternPreview
                            isDark={props.selectedColor ? isHexDark(props.selectedColor) : false}
                            key={`pattern-${pattern.id}`}
                            src={handlePatternPreview(pattern)}
                        />
                    </PatternContainer>
                ))}
        </PatternListContainer>
    );
};

export default FillPattern;

const PatternListContainer = styled.div`
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
    margin: 0;
`;

const PatternContainer = styled.div<{ selected?: boolean }>`
    width: 32px;
    height: 32px;
    border: ${(props) =>
        props.selected ? '1px solid rgba(255, 255, 255, 0.7)' : '1px solid rgba(255, 255, 255, 0.2)'};
    border-radius: 3px;
    cursor: pointer;
`;

const PatternPreview = styled.img<{ isDark: boolean }>`
    height: 18px;
    width: 18px;
    margin: 6px 6px;
    padding: 0;
    border-radius: 9px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: ${(props) => (props.isDark ? `rgba(255, 255, 255, 0.25)` : `transparent`)};
`;
