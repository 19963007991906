import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../Shared/soar-modal';
import { StyledDarkButton, StyledDeleteButton } from '../../../Shared/styled-button';

interface WarningCommentDialogProps {
    handleProceedToComment: () => void;
    handleOpenWarningMessage: (openWarningMessage: boolean) => void;
}

export const WarningCommentDialog = (props: WarningCommentDialogProps) => {
    return (
        <SoarModal
            title={
                <span>
                    <i className="fa fa-exclamation-circle icon mr-2 text-danger" />
                    Existing Annotations
                </span>
            }
            isOpen={true}
            toggle={() => props.handleOpenWarningMessage(false)}
            width="400px"
        >
            <StyledModalBody>
                You currently have annotations on the screen, clicking continue will remove these annotations. Would you
                like to proceed?
            </StyledModalBody>
            <StyledModalFooter>
                <StyledDarkButton onClick={() => props.handleOpenWarningMessage(false)}>CANCEL</StyledDarkButton>
                <StyledDeleteButton onClick={props.handleProceedToComment}>CONTINUE</StyledDeleteButton>
            </StyledModalFooter>
        </SoarModal>
    );
};
