import React, { useCallback, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import { CommentDTO, CommentResultsDTO } from '../../../../api/model';
import UserHelper from '../../../../lib/user-helper';
import {
    actionSetDisplayAnnotations,
    actionSetReplyingToComment,
    actionSetResetCommentSection,
    actionSetSelectedComment,
    actionSetSelectedCommentId,
    actionSetUpdateAnnotations,
} from '../../../../store/SocialMapping/actions';
import {
    selectActiveAnnotationString,
    selectAnnotationCommentId,
    selectComment,
} from '../../../../store/SocialMapping/selectors';
import ActiveCommentEdit from './edit-comment';
import { ActiveDeleteComment } from './delete-comment-dialog';
import ReportCommentDialog from './report-comment-dialog';
import ActiveListingCommentReply from './comment-reply';
import { CommentDetails } from './comment-details';
import { WarningCommentDialog } from './warning-comment-dialog';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import { BooleanResponseHandler, CommentResponseHandler, IsSuccessResponseHandler } from './types';
import Analytics from '../../../../lib/user-analytics';
import { deleteAllAnnotationsAction } from '../../../../store/Annotations/actions';

type ActiveListingCommentItemProps = {
    comment: CommentResultsDTO;
    listingId: string;
    handleCommentUpdate: BooleanResponseHandler;
    allowComment: boolean;
};

const ActiveListingCommentItem = (props: ActiveListingCommentItemProps) => {
    const selectedActiveAnnotationString = useSelector(selectActiveAnnotationString);
    const selectedAnnotationCommentId = useSelector(selectAnnotationCommentId);
    const selectedComment = useSelector(selectComment);

    const dispatch = useDispatch();

    const setReplyToComment = (replying: boolean) => dispatch(actionSetReplyingToComment(replying));
    const setUpdateAnnotations = (updateAnnotations: boolean) =>
        dispatch(actionSetUpdateAnnotations(updateAnnotations));
    const setSelectedCommentId = (commentId: string) => {
        dispatch(actionSetSelectedCommentId(commentId));
    };
    const setAnnotationString = (annotations: string) => dispatch(actionSetDisplayAnnotations(annotations));
    const setResetAnnotations = (resetAnnotation: boolean) => dispatch(actionSetResetCommentSection(resetAnnotation));
    const resetAnnotations = () => {
        dispatch(deleteAllAnnotationsAction());
    };

    const { comment } = props;
    const childComments = comment.children.filter((child) => child.userId && child.userName);

    const [openDeleteComment, setOpenDeleteComment] = useState<boolean>(false);
    const [showReportCommentDialog, setShowReportCommentDialog] = useState<boolean>(false);
    const [showModerateCommentDialog, setShowModerateCommentDialog] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
    const [currentCommentId, setCurrentCommentId] = useState<string | undefined>(undefined);
    const [isCommentEdit, setIsCommentEdit] = useState(false);

    const handleSelectedComment = useCallback(
        (selectedComment) => {
            if (selectedComment.id) {
                dispatch(actionSetResetCommentSection(true));
                dispatch(actionSetDisplayAnnotations(selectedComment.annotations));
                dispatch(actionSetUpdateAnnotations(true));
                dispatch(actionSetSelectedCommentId(selectedComment.id || ''));
                setIsCommentEdit(false);
                setCurrentCommentId(selectedComment.id || '');
            }
            dispatch(actionSetSelectedComment(undefined));
        },
        [dispatch]
    );

    useEffect(() => {
        const isInView = (element: HTMLElement | null): boolean => {
            if (element) {
                const bounding = element.getBoundingClientRect();
                return (
                    bounding.top >= 0 &&
                    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                );
            }
            return false;
        };
        const scrollNotificationIntoView = () => {
            const commentId = document.getElementById(comment.id);
            const isVisible = isInView(commentId);
            if (commentId && !isVisible) {
                // Timeout only for visual effect when a notification is selected the comments update and scrolls new comment into view
                setTimeout(() => {
                    commentId.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest',
                    });
                }, 300);
            }
        };

        if (comment.id === selectedAnnotationCommentId) {
            scrollNotificationIntoView();
        }
    }, [comment.id, selectedAnnotationCommentId]);

    useEffect(() => {
        if (selectedComment) {
            requestAnimationFrame((selectedComment) => handleSelectedComment(selectedComment));
        }
    }, [handleSelectedComment, selectedComment]);

    const handleClickCommentContainer = () => {
        resetAnnotations();
        if (isMobileVersion) {
            handleClickComment();
        } else if (
            selectedAnnotationCommentId === '' &&
            selectedActiveAnnotationString &&
            UserHelper.getTagsFromAnnotationString(selectedActiveAnnotationString).length > 0
        ) {
            setOpenWarningDialog(true);
        } else {
            handleClickComment();
        }
    };

    const handleClickComment = () => {
        selectCommentId(comment.id);
        Analytics.Event('Comments', 'Clicked comment', comment.id);
    };

    const selectCommentId = (commentId: string) => {
        setIsCommentEdit(false);

        if (selectedAnnotationCommentId === commentId || commentId === '') {
            setSelectedCommentId('');
            setResetAnnotations(true);
            setReplyToComment(false);
        } else {
            setSelectedCommentId(commentId);
            setReplyToComment(true);
            setUpdateAnnotations(true);
            setAnnotationString(comment.annotations);
        }
    };

    const handleClickChildComment = (replyComment: CommentResultsDTO | CommentDTO) => {
        resetAnnotations();
        Analytics.Event('Comments', 'Clicked comment reply', replyComment.id);
        setIsCommentEdit(false);
        setAnnotationString(replyComment.annotations);
        setUpdateAnnotations(true);
        setSelectedCommentId(replyComment.id || '');
        setCurrentCommentId(replyComment.id);
    };

    const handleEditComment = (commentId: string) => {
        setCurrentCommentId(commentId);
        setIsCommentEdit(true);
        Analytics.Event('Comments', 'Clicked to edit', commentId);
    };

    const handleOpenDeleteModal = (commentId: string) => {
        setCurrentCommentId(commentId);
        setOpenDeleteComment(true);
        Analytics.Event('Comments', 'Clicked to open delete modal', commentId);
    };

    const handleReportComment = (commentId: string) => {
        setCurrentCommentId(commentId);
        setShowReportCommentDialog(true);
        Analytics.Event('Comments', 'Clicked to report', commentId);
    };

    const handleModerateComment = (commentId: string) => {
        setCurrentCommentId(commentId);
        setShowModerateCommentDialog(true);
        setResetAnnotations(true);
        Analytics.Event('Comments', 'Clicked to moderate', commentId);
    };

    const handleCommentReply: CommentResponseHandler = (data) => {
        if (data) {
            props.handleCommentUpdate(true);
            handleClickChildComment(data);
        } else {
            selectCommentId('');
        }
    };

    //Handlers for Comment components: Reply, Edit, Delete, Report, Moderate/Delete
    const handleCommentUpdate: CommentResponseHandler = (updatedComment, error) => {
        if (error?.message === 'Empty') {
            setOpenDeleteComment(true);
            return;
        }

        if (updatedComment) {
            props.handleCommentUpdate(true);
            setResetAnnotations(true);
            setAnnotationString(updatedComment.annotations);
            Analytics.Event('Comments', 'Updated comment', updatedComment.id);
        }
        setIsCommentEdit(false);
    };

    const handleCommentDelete: IsSuccessResponseHandler = (isDeleted) => {
        if (isDeleted) {
            props.handleCommentUpdate(true);
            setResetAnnotations(true);
            setCurrentCommentId(undefined);
            Analytics.Event('Comments', 'Deleted comment');
        }
        setOpenDeleteComment(false);
    };

    const handleCommentReport: IsSuccessResponseHandler = () => {
        setCurrentCommentId(undefined);
        setShowReportCommentDialog(false);
    };

    const handleCommentModerate: IsSuccessResponseHandler = (isDeleted) => {
        if (isDeleted) {
            props.handleCommentUpdate(true);
        }
        setCurrentCommentId(undefined);
        setShowModerateCommentDialog(false);
    };

    const isCommentSelected = comment.id === selectedAnnotationCommentId;
    const isChildCommentSelected = !!childComments.find((child) => child.id === selectedAnnotationCommentId);
    const isSelected = isCommentSelected || isChildCommentSelected;
    const isDeleted =
        comment.createdAt === undefined && comment.annotations === undefined && comment.text === undefined;

    if (isDeleted) {
        return <React.Fragment />;
    }

    return (
        <CommentContainer isSelected={isSelected} id={comment.id}>
            <Container
                onClick={(e) => {
                    handleClickCommentContainer();
                    e.stopPropagation();
                }}
                isSelected={isCommentSelected}
            >
                <CommentDetails
                    comment={comment}
                    isSelected={isCommentSelected}
                    handleOpenDeleteComment={() => handleOpenDeleteModal(comment.id)}
                    handleEditComment={() => handleEditComment(comment.id)}
                    handleReportComment={() => handleReportComment(comment.id)}
                    handleModerateComment={() => handleModerateComment(comment.id)}
                    listing={comment.listingId}
                />

                {isCommentSelected && isCommentEdit && (
                    <CommentInputContainer isReplySelected={true}>
                        <ActiveCommentEdit comment={comment} handleCommentUpdate={handleCommentUpdate} />
                    </CommentInputContainer>
                )}

                {isCommentSelected && !isCommentEdit && props.allowComment && (
                    <ReplyRow isOpen={true} isSelected={true}>
                        <CommentInputContainer className="CommentInputContainer">
                            <ActiveListingCommentReply comment={comment} handleReplyAction={handleCommentReply} />
                        </CommentInputContainer>
                    </ReplyRow>
                )}

                {openDeleteComment && currentCommentId && (
                    <ActiveDeleteComment commentId={currentCommentId} handleCommentDelete={handleCommentDelete} />
                )}

                {showReportCommentDialog && currentCommentId && (
                    <ReportCommentDialog
                        isModeratingComment={false}
                        commentId={currentCommentId}
                        handleCommentReport={handleCommentReport}
                    />
                )}

                {showModerateCommentDialog && currentCommentId && (
                    <ReportCommentDialog
                        isModeratingComment={true}
                        commentId={currentCommentId}
                        handleCommentReport={handleCommentModerate}
                    />
                )}

                {openWarningDialog && (
                    <WarningCommentDialog
                        handleOpenWarningMessage={() => setOpenWarningDialog(false)}
                        handleProceedToComment={() => {
                            handleClickComment();
                            setOpenWarningDialog(false);
                        }}
                    />
                )}
            </Container>

            {childComments.length > 0 ? (
                <CommentReply onClick={handleClickComment} isSelected={isSelected}>
                    {!isSelected
                        ? `Click to view ${childComments.length} repl${childComments.length > 1 ? 'ies' : 'y'}`
                        : ''}
                </CommentReply>
            ) : (
                <CommentReplyPadding isSelected={isSelected} />
            )}

            {isSelected ? (
                <CommentCollapse isOpen={isSelected}>
                    <CommentRepliesContainer>
                        <a onClick={handleClickComment} className="comment-border-link" />
                        {childComments.map((child, index) => (
                            <CommentReplyDetails
                                id={child.id}
                                key={index}
                                isReplySelected={isChildCommentSelected && selectedAnnotationCommentId === child.id}
                                onClick={(e) => {
                                    handleClickChildComment(child);
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <CommentDetails
                                    comment={child}
                                    isSelected={isChildCommentSelected && selectedAnnotationCommentId === child.id}
                                    handleOpenDeleteComment={() => handleOpenDeleteModal(child.id)}
                                    handleEditComment={() => handleEditComment(child.id)}
                                    handleReportComment={() => handleReportComment(child.id)}
                                    handleModerateComment={() => handleModerateComment(child.id)}
                                    listing={comment.userId}
                                    isReply={true}
                                />
                                {isChildCommentSelected && isCommentEdit && selectedAnnotationCommentId === child.id && (
                                    <CommentInputContainer isReplySelected={true}>
                                        <ActiveCommentEdit comment={child} handleCommentUpdate={handleCommentUpdate} />
                                    </CommentInputContainer>
                                )}
                            </CommentReplyDetails>
                        ))}
                    </CommentRepliesContainer>
                </CommentCollapse>
            ) : null}
        </CommentContainer>
    );
};

export default ActiveListingCommentItem;

interface CommentItemProps {
    isSelected?: boolean;
    isReplySelected?: boolean;
}

const CommentContainer = styled.div<CommentItemProps>`
    pointer-events: all;
`;

const Container = styled.div<CommentItemProps>`
    display: flex;
    flex-direction: column;
    color: white;
    background-color: ${(props) => (props.isSelected ? 'rgb(255 255 255 / 10%)' : 'transparent')};
    border: ${(props) => (props.isSelected ? '1px solid #EED926' : '1px solid rgba(255, 255, 255, 0.25)')};
    border-radius: 4px;
    position: relative;
    margin: 4px 0 0 0;
    width: 100%;

    :hover {
        cursor: pointer;
        background: rgb(255 255 255 / 10%);
        border: ${(props) => (props.isSelected ? '1px solid #EED926' : '1px solid rgba(255, 255, 255, 0.25)')};
    }
`;

const CommentRepliesContainer = styled.div`
    position: relative;
    list-style: none;
    cursor: pointer;

    .comment-border-link {
        display: block;
        position: absolute;
        width: 11px;
        height: 100%;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        background-color: #eed926;
        background-clip: padding-box;
        border-radius: 10px;
    }
`;

const CommentReplyDetails = styled.div<CommentItemProps>`
    margin: 5px 0px 0px 15px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.87);
    background-color: ${(props) => (props.isReplySelected ? 'rgb(255 255 255 / 10%)' : 'transparent')};
    border: ${(props) => (props.isReplySelected ? '1px solid #EED926' : '1px solid rgb(255 255 255 / 50%)')};
    position: relative;

    :hover {
        cursor: pointer;
        background: rgb(255 255 255 / 10%);
        border: ${(props) => (props.isReplySelected ? '1px solid #EED926' : '1px solid rgb(255 255 255 / 50%)')};
    }
`;

const ReplyRow = styled(Collapse)<CommentItemProps>`
    height: ${(props) => (props.isSelected ? 'auto' : '0px')};
    padding-bottom: ${(props) => (props.isSelected ? '20px' : '0px')};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: all 300ms;
    animation: ${(props) => (props.isSelected ? FadeIn : 'none')} 300ms ease-in-out;
    margin: ${(props) => (props.isSelected ? '0px 5px 0px 0px' : '0px')};
    font-size: ${(props) => (props.isSelected ? '13px' : 'inherit')};
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const CommentInputContainer = styled.div<CommentItemProps>`
    width: 100%;
    padding-bottom: ${(props) => (props.isReplySelected ? '20px' : '')};

    &.listing-comment-input {
        font-size: 12px !important;
    }
`;

const CommentReply = styled.div<CommentItemProps>`
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 5px;
    font-size: 10px;
    cursor: pointer;
    text-decoration: underline;
`;

const CommentReplyPadding = styled.div<CommentItemProps>`
    height: ${(props) => (props.isSelected ? '20px' : '0px')};
`;

const CommentCollapse = styled(Collapse)``;
