import { actionTypes as at } from './constants';

export const actionAddSatelliteOverlay = (name: string) => {
    return {
        type: at.SATELLITE_OVERLAY_ADD,
        payload: name,
    };
};

export const actionRemoveSatelliteOverlay = (name: string) => {
    return {
        type: at.SATELLITE_OVERLAY_REMOVE,
        payload: name,
    };
};
