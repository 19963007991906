import React from 'react';
import styled from 'styled-components';
import { AutocompleteDTO } from '../../../api/model';
import LocationSearchResultItem from './location-search-results-item';

interface LocationSearchResultsProps {
    searchResults: AutocompleteDTO[];
    onSelectAutocomplete: (location: AutocompleteDTO) => void;
}

const LocationSearchResults = (props: LocationSearchResultsProps) => {
    if (props.searchResults.length === 0) {
        return <React.Fragment />;
    }

    return (
        <SearchResultsContainer>
            {props.searchResults.map((searchResult, idx) => (
                <LocationSearchResultItem
                    key={`${idx}_${searchResult.title}`}
                    autocomplete={searchResult}
                    onSelectAutocomplete={props.onSelectAutocomplete}
                />
            ))}
        </SearchResultsContainer>
    );
};

export default LocationSearchResults;

const SearchResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    border: 1px solid #515151;
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
        0px 9px 44px 8px rgba(0, 0, 0, 0.22);
    max-height: calc(100vh - 300px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;
