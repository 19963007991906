import { SortOptions } from '../Shared/sort';

export enum DrawStoarySort {
    Title = 'Title',
    LastPosted = 'Oldest',
    Newest = 'Newest',
    Modified = 'Modified',
    Shared = 'Shared',
}

export const DrawStoarySortOptions: SortOptions = {
    title: 'Sort By',
    options: [
        { value: DrawStoarySort.Title, label: 'Title' },
        { value: DrawStoarySort.LastPosted, label: 'Oldest' },
        { value: DrawStoarySort.Newest, label: 'Newest' },
        { value: DrawStoarySort.Modified, label: 'Last Modified' },
        { value: DrawStoarySort.Shared, label: 'Shared to me' },
    ],
    icons: {
        light: '/assets/explore-dropdown/sort-by-filter_dark.svg',
        dark: '/assets/explore-dropdown/sort-by-filter_light.svg',
    },
};
