import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L from 'leaflet';
import { MarkerProps } from 'react-leaflet';
import { resizeBoundsByCornerWhilePreservingAspectRatio } from './image-annotation-util';
import LayersUtil from '../layers-util';
import { nodeMarkerOptions } from '../Polygon/node-marker-handles';

interface ResizeElementProps {
    imageOverlayElement: Readonly<{ instance: L.ImageOverlay; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const createCornerDragMarker = (position: L.LatLng, cursorClass: string, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const markerElement = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, { ...nodeMarkerOptions, pane: controlPaneId }),
        context
    );

    markerElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), cursorClass);
    });

    markerElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), cursorClass);
    });

    return markerElement;
};

export const createResizeElement = (props: ResizeElementProps, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const resizeLayer = new L.LayerGroup([], { pane: controlPaneId });
    const resizeLayerElement = createElementObject<L.LayerGroup>(resizeLayer, context);

    const northWestCornerMarker = createCornerDragMarker(
        props.imageOverlayElement.instance.getBounds().getNorthWest(),
        'leaflet-nw-resize',
        context
    );

    const northEastCornerMarker = createCornerDragMarker(
        props.imageOverlayElement.instance.getBounds().getNorthEast(),
        'leaflet-ne-resize',
        context
    );

    const southEastCornerMarker = createCornerDragMarker(
        props.imageOverlayElement.instance.getBounds().getSouthEast(),
        'leaflet-se-resize',
        context
    );
    const southWestCornerMarker = createCornerDragMarker(
        props.imageOverlayElement.instance.getBounds().getSouthWest(),
        'leaflet-sw-resize',
        context
    );

    const midLat =
        (props.imageOverlayElement.instance.getBounds().getNorth() +
            props.imageOverlayElement.instance.getBounds().getSouth()) /
        2;
    const midLng =
        (props.imageOverlayElement.instance.getBounds().getEast() +
            props.imageOverlayElement.instance.getBounds().getWest()) /
        2;

    const northEdgeMarker = createCornerDragMarker(
        new L.LatLng(props.imageOverlayElement.instance.getBounds().getNorth(), midLng),
        'leaflet-n-resize',
        context
    );
    const southEdgeMarker = createCornerDragMarker(
        new L.LatLng(props.imageOverlayElement.instance.getBounds().getSouth(), midLng),
        'leaflet-s-resize',
        context
    );
    const eastEdgeMarker = createCornerDragMarker(
        new L.LatLng(midLat, props.imageOverlayElement.instance.getBounds().getEast()),
        'leaflet-e-resize',
        context
    );
    const westEdgeMarker = createCornerDragMarker(
        new L.LatLng(midLat, props.imageOverlayElement.instance.getBounds().getWest()),
        'leaflet-w-resize',
        context
    );

    const updateBounds = (newBounds: L.LatLngBounds, event: L.LeafletMouseEvent) => {
        northEastCornerMarker.instance.setLatLng(newBounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(newBounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(newBounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(newBounds.getSouthEast());

        const midLat =
            (props.imageOverlayElement.instance.getBounds().getNorth() +
                props.imageOverlayElement.instance.getBounds().getSouth()) /
            2;

        const midLng =
            (props.imageOverlayElement.instance.getBounds().getEast() +
                props.imageOverlayElement.instance.getBounds().getWest()) /
            2;

        northEdgeMarker.instance.setLatLng(
            new L.LatLng(props.imageOverlayElement.instance.getBounds().getNorth(), midLng)
        );
        southEdgeMarker.instance.setLatLng(
            new L.LatLng(props.imageOverlayElement.instance.getBounds().getSouth(), midLng)
        );
        eastEdgeMarker.instance.setLatLng(
            new L.LatLng(midLat, props.imageOverlayElement.instance.getBounds().getEast())
        );
        westEdgeMarker.instance.setLatLng(
            new L.LatLng(midLat, props.imageOverlayElement.instance.getBounds().getWest())
        );

        props.imageOverlayElement.instance.setBounds(newBounds);
        props.imageOverlayElement.instance.fire('update', event);
    };

    const onDragNorthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = resizeBoundsByCornerWhilePreservingAspectRatio(
            context,
            props.imageOverlayElement.instance.getBounds(),
            'ne',
            e.latlng
        );
        updateBounds(newBounds, e);
    };

    const onDragNorthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = resizeBoundsByCornerWhilePreservingAspectRatio(
            context,
            props.imageOverlayElement.instance.getBounds(),
            'nw',
            e.latlng
        );
        updateBounds(newBounds, e);
    };

    const onDragSouthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = resizeBoundsByCornerWhilePreservingAspectRatio(
            context,
            props.imageOverlayElement.instance.getBounds(),
            'se',
            e.latlng
        );
        updateBounds(newBounds, e);
    };

    const onDragSouthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = resizeBoundsByCornerWhilePreservingAspectRatio(
            context,
            props.imageOverlayElement.instance.getBounds(),
            'sw',
            e.latlng
        );
        updateBounds(newBounds, e);
    };

    const onDragNorthEdgeMarker = (e: L.LeafletMouseEvent) => {
        const newNorthEast = new L.LatLng(
            e.latlng.lat,
            props.imageOverlayElement.instance.getBounds().getNorthEast().lng
        );
        const newSouthWest = props.imageOverlayElement.instance.getBounds().getSouthWest();
        const newBounds = new L.LatLngBounds(newSouthWest, newNorthEast);
        updateBounds(newBounds, e);
    };

    const onDragSouthEdgeMarker = (e: L.LeafletMouseEvent) => {
        const newSouthWest = new L.LatLng(
            e.latlng.lat,
            props.imageOverlayElement.instance.getBounds().getSouthWest().lng
        );
        const newNorthEast = props.imageOverlayElement.instance.getBounds().getNorthEast();
        const newBounds = new L.LatLngBounds(newSouthWest, newNorthEast);
        updateBounds(newBounds, e);
    };

    const onDragWestEdgeMarker = (e: L.LeafletMouseEvent) => {
        const newSouthWest = new L.LatLng(
            props.imageOverlayElement.instance.getBounds().getSouthWest().lat,
            e.latlng.lng
        );

        const newNorthEast = props.imageOverlayElement.instance.getBounds().getNorthEast();
        const newBounds = new L.LatLngBounds(newSouthWest, newNorthEast);
        updateBounds(newBounds, e);
    };

    const onDragEastEdgeMarker = (e: L.LeafletMouseEvent) => {
        const newNorthEast = new L.LatLng(
            props.imageOverlayElement.instance.getBounds().getNorthEast().lat,
            e.latlng.lng
        );

        const newSouthWest = props.imageOverlayElement.instance.getBounds().getSouthWest();
        const newBounds = new L.LatLngBounds(newSouthWest, newNorthEast);
        updateBounds(newBounds, e);
    };

    const onDragStart = () => {
        props.imageOverlayElement.instance.fire('peekOn');
    };

    const onDragEnd = () => {
        props.imageOverlayElement.instance.fire('peekOff');
    };

    props.imageOverlayElement.instance.on('update', () => {
        const bounds = props.imageOverlayElement.instance.getBounds();
        northEastCornerMarker.instance.setLatLng(bounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(bounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(bounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(bounds.getSouthEast());

        const midLat =
            (props.imageOverlayElement.instance.getBounds().getNorth() +
                props.imageOverlayElement.instance.getBounds().getSouth()) /
            2;

        const midLng =
            (props.imageOverlayElement.instance.getBounds().getEast() +
                props.imageOverlayElement.instance.getBounds().getWest()) /
            2;

        northEdgeMarker.instance.setLatLng(
            new L.LatLng(props.imageOverlayElement.instance.getBounds().getNorth(), midLng)
        );
        southEdgeMarker.instance.setLatLng(
            new L.LatLng(props.imageOverlayElement.instance.getBounds().getSouth(), midLng)
        );
        eastEdgeMarker.instance.setLatLng(
            new L.LatLng(midLat, props.imageOverlayElement.instance.getBounds().getEast())
        );
        westEdgeMarker.instance.setLatLng(
            new L.LatLng(midLat, props.imageOverlayElement.instance.getBounds().getWest())
        );
    });

    resizeLayerElement.instance.on('add', () => {
        resizeLayerElement.instance.addLayer(northEastCornerMarker.instance);
        resizeLayerElement.instance.addLayer(northWestCornerMarker.instance);
        resizeLayerElement.instance.addLayer(southEastCornerMarker.instance);
        resizeLayerElement.instance.addLayer(southWestCornerMarker.instance);
        resizeLayerElement.instance.addLayer(northEdgeMarker.instance);
        resizeLayerElement.instance.addLayer(southEdgeMarker.instance);
        resizeLayerElement.instance.addLayer(eastEdgeMarker.instance);
        resizeLayerElement.instance.addLayer(westEdgeMarker.instance);

        northEastCornerMarker.instance.on('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.on('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.on('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.on('drag', onDragSouthWestCornerMarker);
        northEdgeMarker.instance.on('drag', onDragNorthEdgeMarker);
        southEdgeMarker.instance.on('drag', onDragSouthEdgeMarker);
        westEdgeMarker.instance.on('drag', onDragWestEdgeMarker);
        eastEdgeMarker.instance.on('drag', onDragEastEdgeMarker);

        northWestCornerMarker.instance.on('dragstart', onDragStart);
        northEastCornerMarker.instance.on('dragstart', onDragStart);
        southEastCornerMarker.instance.on('dragstart', onDragStart);
        southWestCornerMarker.instance.on('dragstart', onDragStart);
        northEdgeMarker.instance.on('dragstart', onDragStart);
        southEdgeMarker.instance.on('dragstart', onDragStart);
        westEdgeMarker.instance.on('dragstart', onDragStart);
        eastEdgeMarker.instance.on('dragstart', onDragStart);

        northWestCornerMarker.instance.on('dragend', onDragEnd);
        northEastCornerMarker.instance.on('dragend', onDragEnd);
        southEastCornerMarker.instance.on('dragend', onDragEnd);
        southWestCornerMarker.instance.on('dragend', onDragEnd);
        northEdgeMarker.instance.on('dragend', onDragEnd);
        southEdgeMarker.instance.on('dragend', onDragEnd);
        westEdgeMarker.instance.on('dragend', onDragEnd);
        eastEdgeMarker.instance.on('dragend', onDragEnd);
    });

    resizeLayerElement.instance.on('remove', () => {
        resizeLayerElement.instance.removeLayer(northEastCornerMarker.instance);
        resizeLayerElement.instance.removeLayer(northWestCornerMarker.instance);
        resizeLayerElement.instance.removeLayer(southEastCornerMarker.instance);
        resizeLayerElement.instance.removeLayer(southWestCornerMarker.instance);
        resizeLayerElement.instance.removeLayer(northEdgeMarker.instance);
        resizeLayerElement.instance.removeLayer(southEdgeMarker.instance);
        resizeLayerElement.instance.removeLayer(eastEdgeMarker.instance);
        resizeLayerElement.instance.removeLayer(westEdgeMarker.instance);

        northEastCornerMarker.instance.off('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.off('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.off('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.off('drag', onDragSouthWestCornerMarker);

        northEdgeMarker.instance.off('drag', onDragNorthEdgeMarker);
        southEdgeMarker.instance.off('drag', onDragSouthEdgeMarker);
        westEdgeMarker.instance.off('drag', onDragWestEdgeMarker);
        eastEdgeMarker.instance.off('drag', onDragEastEdgeMarker);

        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-nw-resize');
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-ne-resize');
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-se-resize');
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-sw-resize');
    });

    return resizeLayerElement;
};
