import MeasurementToolbar from '../Measurement/measurement-toolbar';
import MeasurementToolbarLabelColor, { MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import MeasurementToolbarRadius from '../Measurement/measurement-toolbar-radius';
import MeasurementToolbarUnits, { MeasurementUnits } from '../Measurement/measurement-toolbar-units';
import Circle from './circle';

interface CircleMeasurementToolbarProps {
    circle: Circle;
    onChangeShowRadius: (showRadius: boolean) => void;
    onChangeUnits: (units: MeasurementUnits) => void;
    onChangeLabelColor: (labelColor: MeasurementLabelColor) => void;
}

const CircleMeasurementToolbar = (props: CircleMeasurementToolbarProps) => {
    return (
        <MeasurementToolbar>
            <MeasurementToolbarRadius circle={props.circle} onChangeShowRadius={props.onChangeShowRadius} />
            <MeasurementToolbarUnits units={props.circle.units} onChangeUnits={props.onChangeUnits} />
            <MeasurementToolbarLabelColor
                labelColor={props.circle.labelColor}
                onChangeLabelColor={props.onChangeLabelColor}
            />
        </MeasurementToolbar>
    );
};

export default CircleMeasurementToolbar;
