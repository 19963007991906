import React, { useState } from 'react';
import styled from 'styled-components';
import UserHelper from '../../lib/user-helper';
import { MarkdownViewer } from './markdown-viewer';

interface TruncatedTextProps {
    text: string;
    length: number;
}

export const TruncatedText = ({ text, length }: TruncatedTextProps) => {
    const [showMore, setShowMore] = useState<boolean>(false);
    return (
        <React.Fragment>
            <MarkdownViewer text={UserHelper.getTruncatedText(text, showMore ? text.length : length, !showMore)} />
            {text.length >= length && (
                <ShowMoreString onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Show Less' : 'Show More'}
                </ShowMoreString>
            )}
        </React.Fragment>
    );
};

const ShowMoreString = styled.span`
    color: #eed926;
    float: right;
    margin-top: -8px;

    :hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;
