import { Spinner } from 'reactstrap';
import styled from 'styled-components';

interface PulseLoaderProps {
    align?: 'left' | 'center' | 'right';
    iconSize?: string;
    iconMargin?: string;
    alignLoader?: string;
    className?: string;
}

export const PulseLoader = ({
    iconSize = '1rem',
    iconMargin = '5px',
    alignLoader,
    className,
}: PulseLoaderProps): JSX.Element => {
    return (
        <LoaderContainer alignLoader={alignLoader ? alignLoader : ''} className={className}>
            <PulseLoaderIcon type="grow" className="pulse1" width={iconSize} height={iconSize} margin={iconMargin} />
            <PulseLoaderIcon type="grow" className="pulse2" width={iconSize} height={iconSize} margin={iconMargin} />
            <PulseLoaderIcon type="grow" className="pulse3" width={iconSize} height={iconSize} margin={iconMargin} />
        </LoaderContainer>
    );
};

interface LoaderContainerProps {
    alignLoader?: string;
}

const LoaderContainer = styled.div<LoaderContainerProps>`
    width: 100%;
    height: 100%;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: ${(props) => (props.alignLoader ? props.alignLoader : 'center')};

    @media only screen and (max-width: 600px) {
        height: auto;
        padding-bottom: 20px;
    }
`;

interface PulseLoaderIconProps {
    width: string;
    height: string;
    margin: string;
}

const PulseLoaderIcon = styled(Spinner)<PulseLoaderIconProps>`
    color: #eed926;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};

    &.pulse1 {
        animation-timing-function: cubic-bezier(0.5, 0.75, 0.5, 1);
        animation-delay: 0s;
    }

    &.pulse2 {
        animation-timing-function: cubic-bezier(0.5, 0.75, 0.5, 1);
        animation-delay: 0.15s;
    }

    &.pulse3 {
        animation-timing-function: cubic-bezier(0.5, 0.75, 0.5, 1);
        animation-delay: 0.25s;
    }
`;
