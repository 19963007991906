import AnimatedNumber from 'react-animated-number';
import prettyBytes from 'pretty-bytes';
import { useEffect, useState } from 'react';

interface AnimatedNumberProps {
    number: number;
    type: AnimatedNumberType;
    duration?: number;
    color?: string;
    fontSize?: number;
}

export enum AnimatedNumberType {
    Number,
    FileSize,
    SquareKilometers,
    MillionSquareKilometers,
    BillionSquareKilometers,
    Currency,
}

export const AnimatedNumberChart = (props: AnimatedNumberProps): JSX.Element => {
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        if (props.number) {
            setTimeout(() => {
                setValue(props.number);
            }, 300);
        }
    }, [props.number]);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <AnimatedNumber
            component="span"
            value={value}
            style={{
                color: props.color || 'white',
                transition: '0.8s ease-out',
                fontSize: props.fontSize || 48,
                transitionProperty: 'background-color, color, opacity',
            }}
            frameStyle={(perc: number) => (perc === 100 ? {} : { opacity: 0.25 })}
            stepPrecision={1}
            duration={props.duration || 2000}
            formatValue={(n: number) => {
                switch (props.type) {
                    case AnimatedNumberType.Number:
                        return `${n.toLocaleString()}`;
                    case AnimatedNumberType.FileSize:
                        return prettyBytes(n);
                    case AnimatedNumberType.SquareKilometers:
                        return `${Math.round(n).toLocaleString()} km²`;
                    case AnimatedNumberType.MillionSquareKilometers:
                        return `${Math.round(n).toLocaleString()}M km²`;
                    case AnimatedNumberType.BillionSquareKilometers:
                        return `${Math.round(n).toLocaleString()}B km²`;
                    case AnimatedNumberType.Currency:
                        return currencyFormatter.format(n);
                    default:
                        return n;
                }
            }}
        />
    );
};
