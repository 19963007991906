import React from 'react';

import SoarModal, { StyledModalBody, StyledModalFooter } from '../soar-modal';
import { StyledDarkButton, StyledDeleteButton } from '../styled-button';

interface SignOutModalProps {
    title?: string;
    message?: string;
    isOpen: boolean;
    onToggle: () => void;
    onSignOut: () => void;
}

const SignOutModal = (props: SignOutModalProps) => {
    return (
        <SoarModal title={props.title || 'Sign out'} isOpen={props.isOpen} toggle={props.onToggle} width="600px">
            <StyledModalBody>{props.message || 'Are you sure you want to sign out?'}</StyledModalBody>
            <StyledModalFooter>
                <StyledDarkButton onClick={props.onToggle}>CANCEL</StyledDarkButton>
                <StyledDeleteButton onClick={props.onSignOut}>SIGN OUT</StyledDeleteButton>
            </StyledModalFooter>
        </SoarModal>
    );
};

export default SignOutModal;
