import { MutableRefObject, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { isMobileVersion } from '../../../lib/soar-helper';

/**
 * useMapInteractionControl
 * Disables double click, scroll zoom, and dragging on the map when the mouse is over the specified element or its children.
 * Also handles the case where map interactions are controlled by a boolean state.
 * @param ref
 * @param shouldDisable
 */
const useMapInteractionControl = (
    ref: MutableRefObject<HTMLElement | HTMLInputElement | HTMLDivElement | null>,
    shouldDisable?: boolean
) => {
    const map = useMap();

    useEffect(() => {
        if (isMobileVersion) return;

        const disableInteractions = () => {
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.dragging.disable();
        };

        const enableInteractions = () => {
            map.doubleClickZoom.enable();
            map.scrollWheelZoom.enable();
            map.dragging.enable();
        };

        const element = ref.current;

        if (shouldDisable) {
            disableInteractions();
        } else {
            enableInteractions();
        }

        if (element) {
            const handleMouseEnter = () => {
                disableInteractions();
            };

            const handleMouseLeave = (event: MouseEvent) => {
                if (!element.contains(event.relatedTarget as Node)) {
                    enableInteractions();
                }
            };

            element.addEventListener('mouseenter', handleMouseEnter);
            element.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                element.removeEventListener('mouseenter', handleMouseEnter);
                element.removeEventListener('mouseleave', handleMouseLeave);
                enableInteractions();
            };
        }

        return () => {
            enableInteractions();
        };
    }, [map, ref, shouldDisable]);
};

export default useMapInteractionControl;
