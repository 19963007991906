import { isMobileVersion } from '../../../lib/soar-helper';
import NavigationButton from '../Shared/navigation-button';
import NavigationPanel from '../Shared/navigation-panel';

interface PayoutNavigationProps {
    handleSubmit: (e) => void;
    isSubmitting: boolean;
    setIsSubmitting: (submitting: boolean) => void;
    clearSelection?: () => void;
}

const PayoutNavigation = (props: PayoutNavigationProps) => {
    const { handleSubmit, isSubmitting, setIsSubmitting } = props;
    return (
        <NavigationPanel label="Payout Settings">
            {!isMobileVersion && (
                <NavigationButton
                    isSubmitting={isSubmitting}
                    setIsSubmitting={setIsSubmitting}
                    label="APPLY CHANGES"
                    onClick={(e) => handleSubmit(e)}
                    active={true}
                />
            )}
        </NavigationPanel>
    );
};

export default PayoutNavigation;
