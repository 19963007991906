import L, { LeafletMouseEvent } from 'leaflet';
import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import Coordinate, { defaultCoordinateOptions } from './coordinate';
import { v4 as uuidv4 } from 'uuid';
import { defaultZIndex } from '../layers-util';
import { handleFormattingCoordinateLatLng, handleIcon } from './coordinate-util';
import store from '../../../../store/store';

interface CoordinateAnnotationBuilderProps {
    onCreateCoordinate: (coordinate: Coordinate) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const createCoordinateBuilderElement = (props: CoordinateAnnotationBuilderProps, context: LeafletContextInterface) => {
    const coordinateSystem = store.getState().annotationDomain.present.coordinateReducer.coordinateSystem || 'WGS84 DD';

    const coordinate = new L.Marker(new L.LatLng(180, 180), { ...defaultCoordinateOptions });
    const coordinateElement = createElementObject<L.Marker, CoordinateAnnotationBuilderProps>(coordinate, context);
    const id = uuidv4();

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancelBuild();
            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            context.map.dragging.enable();
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
            document.removeEventListener('keydown', onKeyDown);
        }
    };

    const onMouseDown = (e: LeafletMouseEvent) => {
        coordinateElement.instance.setLatLng(e.latlng);
        context.map.dragging.enable();
        document.removeEventListener('keydown', onKeyDown);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.off('mousemove', onMouseMove);
        context.map.off('mousedown', onMouseDown);

        const coordinate: Coordinate = {
            annotationType: 'Coordinate',
            id: id,
            position: coordinateElement.instance.getLatLng(),
            options: coordinateElement.instance.options,
            zIndex: props.zIndex || defaultZIndex,
            coordinateSystem: coordinateSystem,
            ...defaultCoordinateOptions,
        };
        props.onCreateCoordinate(coordinate);
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        const latlng = handleFormattingCoordinateLatLng(e.latlng);
        coordinateElement.instance.setIcon(handleIcon(id, latlng, coordinateSystem));
        coordinateElement.instance.setLatLng(e.latlng);
    };

    coordinateElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.on('mousemove', onMouseMove);
        context.map.on('mousedown', onMouseDown);
    });

    document.addEventListener('keydown', onKeyDown);

    return coordinateElement;
};

const useCoordinateBuilder = createElementHook<L.Marker, CoordinateAnnotationBuilderProps, LeafletContextInterface>(
    createCoordinateBuilderElement
);

const CoordinateBuilder = (props: CoordinateAnnotationBuilderProps) => {
    const context = useLeafletContext();
    const coordinateBuilder = useCoordinateBuilder(props, context);
    useLayerLifecycle(coordinateBuilder.current, context);
    return null;
};

export default CoordinateBuilder;
