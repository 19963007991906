import styled from 'styled-components';
import { PulseLoader } from '../../../Shared/pulse-loader';

const ActiveMapLoading = () => {
    return (
        <ActiveMapLoadingContainer>
            <PulseLoader iconSize="1.5rem" />
        </ActiveMapLoadingContainer>
    );
};

export default ActiveMapLoading;

const ActiveMapLoadingContainer = styled.div`
    margin: 20px;
`;
