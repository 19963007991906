import L from 'leaflet';
import { defaultZIndex } from '../layers-util';
import { MeasurementUnits } from '../Measurement/measurement-toolbar-units';
import { defaultLabelColor, MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import { handleDeserializePatternFill, handlePattternIdFromPatternFillUrl } from '../FillPattern/pattern-util';

export const HOVER_WEIGHT = 2;

export default class Circle {
    annotationType: string;
    id: string;
    center: L.LatLng;
    radius: number;
    options: L.CircleOptions;
    fillPattern?: string;
    zIndex: number;
    showRadius: boolean;
    units: MeasurementUnits;
    labelColor: MeasurementLabelColor;

    constructor(
        id: string,
        center: L.LatLng,
        radius: number,
        options: L.CircleOptions,
        showRadius: boolean,
        units: MeasurementUnits,
        labelColor: MeasurementLabelColor,
        pattern?: string
    ) {
        this.annotationType = 'Circle';
        this.id = id;
        this.center = center;
        this.radius = radius;
        this.options = options;
        this.fillPattern = pattern;
        this.showRadius = showRadius;
        this.units = units;
        this.labelColor = labelColor;
    }

    static serialize(circle: Circle): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [circle.center.lng, circle.center.lat],
            },
            properties: {
                annotationType: 'Circle',
                id: circle.id,
                radius: circle.radius,
                options: circle.options,
                fillPattern: circle.fillPattern ? circle.fillPattern : undefined,
                showRadius: circle.showRadius,
                units: circle.units,
                labelColor: circle.labelColor,
                zIndex: circle.zIndex || defaultZIndex,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): Circle {
        const center = new L.LatLng(json.geometry.coordinates[1], json.geometry.coordinates[0]);

        if (
            json.properties.options?.fillColor &&
            handlePattternIdFromPatternFillUrl(json.properties.options?.fillColor) !== 'none'
        ) {
            handleDeserializePatternFill(json);
        }

        const { id, radius, zIndex, showRadius, units, labelColor } = json.properties;

        return {
            annotationType: 'Circle',
            id: id,
            center: center,
            radius: radius,
            options: json.properties.options,
            fillPattern: json.properties?.fillPattern ? json.properties.fillPattern : undefined,
            zIndex: zIndex || defaultZIndex,
            showRadius: showRadius ? showRadius : false,
            units: units ? units : 'metric',
            labelColor: labelColor ? labelColor : defaultLabelColor,
        };
    }
}

export const defaultCircleOptions: L.CircleOptions = {
    radius: 0,
    stroke: true,
    color: '#eed926',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: '#eed926',
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: false,
    bubblingMouseEvents: false,
};

export const circleGhostOptions: L.CircleOptions = {
    radius: 0,
    color: 'transparent',
    weight: 0,
    fillColor: 'transparent',
    opacity: 0,
    fill: true,
    interactive: true,
    bubblingMouseEvents: false,
};

export const dragCircleOptions: L.CircleOptions = {
    radius: 0,
    stroke: true,
    color: 'transparent',
    weight: 0,
    opacity: 0,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: 'transparent',
    fillOpacity: 1,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};
