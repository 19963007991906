import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import Polyline from './polyline';
import PathAnnotation from './polyline-annotation';
import PathBuilder from './polyline-builder';
import { setPolylineAddAction, setPolylineUpdateAction } from '../../../../store/Annotations/Path/actions';
import { selectPolylines } from '../../../../store/Annotations/Path/selectors';
import { useDrawToolUpdated } from '../use-draw-tool-updated';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { selectPolygons } from '../../../../store/Annotations/Polygon/selectors';

const PolylineControl = (props: AnnotationControlProps) => {
    const [polylineBuilderActive, setPolylineBuilderActive] = useState(false);

    const dispatch = useDispatch();

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const polylines = useSelector(selectPolylines);
    const polygons = useSelector(selectPolygons);

    const isInteractive = !props.locked && props.visible;

    const calculateSnapPoints = () =>
        polylines.flatMap((polyline) => polyline.positions).concat(polygons.flatMap((polygon) => polygon.positions));

    const onUpdatePath = useDrawToolUpdated((polyline: Polyline) => {
        dispatch(setPolylineUpdateAction(polyline));
    });

    // Do not remove handles a user creating a polyline and then clicking exit without the line been closed
    useEffect(() => {
        if (!props.visible) {
            setPolylineBuilderActive(false);
            unlockAnnotationsEvent();
        }
    }, [props.visible, unlockAnnotationsEvent]);

    return (
        <React.Fragment>
            <AnnotationControl
                active={polylineBuilderActive}
                layout="middle"
                visible={props.visible}
                disabled={props.disabled}
                iconDark="/assets/annotations/black-annotations/icon-line-black.svg"
                iconLight="/assets/annotations/icon-line.svg"
                name="Line"
                onClick={() => {
                    lockAnnotationsEvent();
                    setPolylineBuilderActive(true);
                }}
                dataTestId="annotation-control-polyline"
            />

            {polylineBuilderActive ? (
                <PathBuilder
                    zIndex={currentZIndex}
                    snapPoints={calculateSnapPoints()}
                    onCreatePolyline={(polyline: Polyline) => {
                        setPolylineBuilderActive(false);
                        dispatch(setPolylineAddAction(polyline));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(polyline));
                        });
                        DrawToolAnalytics.add('line');
                    }}
                    onCancelBuild={() => {
                        setPolylineBuilderActive(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {!isInteractive &&
                polylines.map((polyline) => {
                    return (
                        <PathAnnotation
                            key={`
                                ${polyline.id}-locked-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${polyline.options.color}-
                                ${polyline.options.dashArray}-
                                ${polyline.options.dashOffset}-
                                ${polyline.options.weight}-
                                ${polyline.positions.length}-
                                ${polyline.showLength}-
                                ${polyline.labelColor}-
                                ${polyline.units}-
                                ${polyline.showLength}-
                                ${polyline.zIndex}-
                                ${polyline?.positions ? JSON.stringify(polyline.positions) : ''}
                            `}
                            polyline={polyline}
                            isDisabled={true}
                            isSelected={false}
                        />
                    );
                })}

            {isInteractive &&
                polylines.map((polyline) => {
                    return (
                        <PathAnnotation
                            key={`
                                ${polyline.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${polyline.options.color}-
                                ${polyline.options.dashArray}-
                                ${polyline.options.dashOffset}-
                                ${polyline.options.weight}-
                                ${polyline.positions.length}-
                                ${polyline.showLength}-
                                ${polyline.labelColor}-
                                ${polyline.units}-
                                ${polyline.showLength}-
                                ${polyline.zIndex}-
                                ${polyline?.positions ? JSON.stringify(polyline.positions) : ''}
                            `}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === polyline.id : false}
                            polyline={polyline}
                            onUpdatePolyline={onUpdatePath}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default PolylineControl;
