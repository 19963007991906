import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { actionSetSideDrawerModeAction } from '../../store/SideDrawer/actions';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { CustomCookieConsent } from '../Shared/custom-cookie-consent';
import MobileBasemapMenu from './BaseMap/mobile-basemap-menu';
import MobileBottomSheet from './BottomSheet/mobile-bottom-sheet';
import MobileMap from './mobile-map';
import MobileSoarLogo from './Sharing/mobile-soar-logo';
import SentinelShareModal from '../Drawer/Share/sentinel-share-modal';

interface MobileMapContainerProps {
    loginMode?: LoginModalMode;
    drawerMode?: SideDrawerMode;
}

const MobileMapContainer = (props: MobileMapContainerProps) => {
    const dispatch = useDispatch();

    const [loginMode, setLoginMode] = useState<LoginModalMode | undefined>(props.loginMode);

    const onLoginDialogClosed = () => {
        setLoginMode(undefined);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
        Analytics.Event('Mobile - Map', 'Clicked to close login dialog');
    };

    useEffect(() => {
        const setDrawerMode = (sideDrawerMode: SideDrawerMode) =>
            dispatch(actionSetSideDrawerModeAction(sideDrawerMode));

        if (props.drawerMode) {
            setDrawerMode(props.drawerMode);
            Analytics.Event('Mobile - Map', 'Changed drawer mode', props.drawerMode);
        }
    }, [props.drawerMode, dispatch]);

    // TODO If the native is split out we will not have to do this but for now this will remove the logo when uisng the /mobile/foo routes
    const isWebMobile = props.drawerMode !== SideDrawerMode.MOBILE_NATIVE;

    return (
        <React.Fragment>
            <MobileMap />
            {isWebMobile && <MobileSoarLogo />}
            <MobileBasemapMenu />
            <MobileBottomSheet />
            <SentinelShareModal />
            {loginMode && <LoginRegisterDialog isOpen={true} initialMode={loginMode} onClose={onLoginDialogClosed} />}
            <CustomCookieConsent />
        </React.Fragment>
    );
};

export default MobileMapContainer;
