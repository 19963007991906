import React from 'react';
import Icon from './icon';
import styled from 'styled-components';
import { MarkerIconName, isMarkerSelected } from './marker-util';
import Marker from './marker';
import { isHexDark } from '../../../../lib/color-util';

interface MarkerOptionCategoriesProps {
    marker: Marker;
    onUpdate: (marker: Marker) => void;
    title: string;
    icons: Icon[];
}

const MarkerOptionsCategory = (props: MarkerOptionCategoriesProps) => {
    return (
        <React.Fragment>
            <MarkerOptionsSection>
                <MarkerOptionsTitle>{props.title}</MarkerOptionsTitle>
                {props.icons?.map((newIcon, index) => {
                    return (
                        <MarkerItem
                            isDark={props.marker.color ? isHexDark(props.marker.color) : false}
                            isSelected={isMarkerSelected(props.marker.iconName as MarkerIconName, newIcon)}
                            title={newIcon.options.title}
                            key={`marker-custom-select-${index}`}
                            src={newIcon.options.iconUrl}
                            alt={newIcon.options.iconUrl}
                            onClick={() => {
                                const newMarker = { ...props.marker, iconName: newIcon.options.name as MarkerIconName };
                                props.onUpdate(newMarker);
                            }}
                        />
                    );
                })}
            </MarkerOptionsSection>
            <SectionDivider />
        </React.Fragment>
    );
};

export default MarkerOptionsCategory;

const MarkerOptionsSection = styled.div`
    margin: 0px 10px 10px 10px;
`;

const MarkerOptionsTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin: 5px 0px;
`;

const MarkerItem = styled.img<{ isSelected: boolean; isDark?: boolean }>`
    cursor: pointer;
    width: 32px;
    margin: 1px;
    border: ${(props) =>
        props.isSelected ? '1px solid rgba(255, 255, 255, 0.7)' : '1px solid rgba(255, 255, 255, 0.2)'};
    border-radius: 3px;
    padding: 3px;

    background: ${(props) => (props.isDark ? `rgba(255, 255, 255, 0.15)` : `transparent`)};

    &:hover {
        border: 1px solid rgba(255, 255, 255, 0.4);
        background-color: rgba(255, 255, 255, 0.3);
    }
`;

const SectionDivider = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);

    :last-child {
        border-bottom: none;
    }
`;
