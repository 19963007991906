import Api from './api';
import { NotificationDTO } from './model';

export default class ApiNotification extends Api {
    public static async getNotifications(
        includeViewed?: boolean,
        limit?: number,
        offset?: number
    ): Promise<NotificationDTO[]> {
        const params = {
            includeViewed: includeViewed ? includeViewed : undefined,
            limit: limit ? limit : undefined,
            offset: offset ? offset : undefined,
        };
        const res = await this.axios.get('/v1/notifications', { params: params, cache: false });
        return res.data;
    }

    public static async setViewedNotifications(ids: number[]): Promise<boolean> {
        const res = await this.axios.put('/v1/notifications/viewed', {
            ids,
        });
        return res.data;
    }
}
