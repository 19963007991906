import styled from 'styled-components';

export const PlusUsers = () => {
    return (
        <PlusUsersContainer>
            <PlusUsersTitle>WHO USES SOAR+?</PlusUsersTitle>
            <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
            <PlusUserText>
                Just about anyone that is interested in maps and imagery. Current users come from a broad mix of
                backgrounds including drone hobbyists, news agencies, small businesses providing services to the
                geospatial industry, through to multi-national companies in the mining, logistics and environmental
                sector.
            </PlusUserText>
            <PlusUsersImages>
                <img
                    src="/assets/plus-images/plus-users/Aquarius-Global-logo_white.png"
                    loading="lazy"
                    alt="Aquarius"
                />
                <img src="/assets/plus-images/plus-users/Bayer-logo_white.png" loading="lazy" alt="Bayer" />
                <img src="/assets/plus-images/plus-users/Saudi-ARAMCO-logo-white.png" loading="lazy" alt="Armaco" />
                <img
                    src="/assets/plus-images/plus-users/City-of-Mandurah-logo_white.png"
                    loading="lazy"
                    alt="City of Mandurah"
                />
                <img src="/assets/plus-images/plus-users/logo-mrc.png" loading="lazy" alt="MRC" />
                <img
                    src="/assets/plus-images/plus-users/The-World-Bank-logo-white-text-500px.png"
                    loading="lazy"
                    alt="World Bank"
                />
                <img src="/assets/plus-images/plus-users/Shell-logo-white.png" loading="lazy" alt="Shell" />
                <img
                    src="/assets/plus-images/plus-users/Ohio-State-University.png"
                    loading="lazy"
                    alt="Ohio State University"
                />
                <img src="/assets/plus-images/plus-users/TATNeft-logo-white-2.png" loading="lazy" alt="TATNeft" />
                <img src="/assets/plus-images/plus-users/BP-colour.png" loading="lazy" alt="BP" />
                <img src="/assets/plus-images/plus-users/SpaceVee-logo-white.png" loading="lazy" alt="SpaceVee" />
            </PlusUsersImages>
        </PlusUsersContainer>
    );
};

const PlusUsersContainer = styled.div`
    text-align: center;
    margin: 0px 80px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    color: white;
    padding: 75px 150px;

    @media only screen and (max-width: 600px) {
        margin: 0px 10px;
        padding: 70px 10px 80px 10px;
    }
`;

const PlusUsersTitle = styled.h1`
    color: inherit;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0px;
`;

const PlusUserText = styled.h3`
    color: inherit;
    font-size: 20px;
    font-weight: 400;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

const PlusUsersImages = styled.div`
    display: block;
    flex-direction: row;
    padding-top: 50px;
    padding-bottom: 20px;

    @media only screen and (max-width: 600px) {
        padding-top: 50px;
        flex-direction: column;
    }

    img {
        width: 180px;
        max-width: 100%;
        margin: 10px 20px;

        @media only screen and (max-width: 600px) {
            margin: 25px 0px;
            width: 60%;
            align-self: center;
        }
    }
`;

const Divider = styled.img`
    margin: 20px 0px;
`;
