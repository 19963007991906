import { useState } from 'react';
import styled from 'styled-components';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import { PlusButton } from './';

interface PlusHeaderProps {
    scrollToPricingCards: () => void;
}

export const PlusHeader = ({ scrollToPricingCards }: PlusHeaderProps) => {
    const [showRegister, setShowRegister] = useState<boolean>(false);

    return (
        <HeaderContainer>
            <HeaderDetails>
                <HeaderTitle>YOUR PRIVATE DIGITAL ATLAS</HeaderTitle>
                <HeaderSubTitle>
                    Your maps and imagery shared publicly or privately via your own digital atlas. Join the mapping
                    revolution.
                </HeaderSubTitle>
                <HeaderButtonContainer>
                    <PlusButton handleOnClick={() => setShowRegister(!showRegister)} label="REGISTER NOW" />
                    <PlusButton handleOnClick={() => scrollToPricingCards()} label="SEE OUR PLANS" type="DARK" />
                </HeaderButtonContainer>
            </HeaderDetails>
            {showRegister && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.REGISTER}
                    onClose={() => setShowRegister(!showRegister)}
                />
            )}
        </HeaderContainer>
    );
};

const HeaderContainer = styled.div`
    height: 480px;
    margin: 78px 80px;
    border-radius: 10px;
    background-image: linear-gradient(90deg, #191a1a 5%, transparent 75%), url('/assets/plus-images/namibia.jpg');
    background-position: 0px 0px, 0% 30%;
    background-size: auto, cover;
    color: white;

    @media only screen and (max-width: 600px) {
        height: 400px;
        margin: 0px 10px;
    }
`;

const HeaderDetails = styled.div`
    height: 100%;
    max-width: 1400px;
    color: inherit;
    padding-top: 80px;
    margin: auto;
`;

const HeaderTitle = styled.h1`
    text-align: left;
    width: 500px;
    margin-bottom: 20px;
    font-size: 66px;
    line-height: 66px;
    color: inherit;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        width: auto;
    }
`;

const HeaderSubTitle = styled.h4`
    position: relative;
    width: 400px;
    margin-bottom: 30px;
    padding-left: 0px;
    color: inherit;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    text-transform: none;
    text-align: left;

    @media only screen and (max-width: 600px) {
        text-align: center;
        width: 100%;
    }
`;

const HeaderButtonContainer = styled.div`
    max-width: 336px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        max-width: 100%;
        height: 70px;
    }
`;
