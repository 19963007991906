import L from 'leaflet';
import {
    LeafletContextInterface,
    createElementHook,
    useLeafletContext,
    createElementObject,
    useLayerLifecycle,
} from '@react-leaflet/core';
import TextBox, { defaultTextBoxRectangleOptions } from './textbox';
import { v4 as uuidv4 } from 'uuid';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';

interface TextBoxBuilderProps {
    onPlaceTextBox: (textBox: TextBox) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const WIDTH = 350;
const HEIGHT = 48;

const createTextBoxBuilder = (props: TextBoxBuilderProps, context: LeafletContextInterface) => {
    const lastUsedTextOptions = store.getState().annotationDomain.present.textboxReducer.textboxOptions;

    const boundsForTextAtPosition = (position: L.LatLng): L.LatLngBounds => {
        const screenPosition = context.map.latLngToContainerPoint(position);
        const top = screenPosition.y;
        const bottom = top + HEIGHT;
        const left = screenPosition.x;
        const right = left + WIDTH;

        const northEast = context.map.containerPointToLatLng(new L.Point(right, top));
        const southWest = context.map.containerPointToLatLng(new L.Point(left, bottom));
        return new L.LatLngBounds(southWest, northEast);
    };

    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);
    const bounds = boundsForTextAtPosition(new L.LatLng(0, 0));
    const rectangle = new L.Rectangle(bounds, { ...defaultTextBoxRectangleOptions, pane: builderPaneId });
    const rectangleElement = createElementObject<L.Rectangle, TextBoxBuilderProps>(rectangle, context);

    const onClick = (e: L.LeafletMouseEvent) => {
        const textColor = lastUsedTextOptions?.textColor ?? 'white';
        const fillColor = lastUsedTextOptions?.fillColor ?? '#000000';
        const fillOpacity = lastUsedTextOptions?.fillOpacity ?? 0.8;
        const padding = lastUsedTextOptions?.padding ?? 0;
        const zoomBase = context.map.getZoom();
        const fontSizeBase = 0;
        const fontFamily = lastUsedTextOptions?.fontFamily ?? 'Manrope';

        const lineHeight = 1.5;
        const northEastPoint = context.map.latLngToContainerPoint(bounds.getNorthEast());
        const southEastPoint = context.map.latLngToContainerPoint(bounds.getSouthEast());
        const height = Math.abs(southEastPoint.y - northEastPoint.y);
        const fontSize = height / lineHeight;

        const textBox: TextBox = {
            annotationType: 'TextBox',
            id: uuidv4(),
            text: '',
            bounds: boundsForTextAtPosition(e.latlng),
            fontSize: `${fontSize}`,
            textColor: textColor,
            fillColor: fillColor,
            fillOpacity: fillOpacity,
            padding: padding,
            zoomBase: zoomBase,
            fontSizeBase: fontSizeBase,
            fontFamily: fontFamily,
            zIndex: props.zIndex || defaultZIndex,
        };
        props.onPlaceTextBox(textBox);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancelBuild();
        }
    };

    const onMouseMove = (e: L.LeafletMouseEvent) => {
        const position = e.latlng;
        const bounds = boundsForTextAtPosition(position);
        rectangleElement.instance.setBounds(bounds);
    };

    rectangleElement.instance.on('add', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        document.addEventListener('keydown', handleKeyDown);
        context.map.on('click', onClick);
        context.map.on('mousemove', onMouseMove);
    });

    rectangleElement.instance.on('remove', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        document.removeEventListener('keydown', handleKeyDown);
        context.map.off('click', onClick);
        context.map.off('mousemove', onMouseMove);
    });

    return rectangleElement;
};

const useTextBoxBuilder = createElementHook<L.Rectangle, TextBoxBuilderProps, LeafletContextInterface>(
    createTextBoxBuilder
);

const TextBoxBuilder = (props: TextBoxBuilderProps) => {
    const context = useLeafletContext();
    const textBuilder = useTextBoxBuilder(props, context);
    useLayerLifecycle(textBuilder.current, context);
    return null;
};

export default TextBoxBuilder;
