import React from 'react';
import { LatLng, Icon, LeafletEvent } from 'leaflet';
import { Marker } from 'react-leaflet';
import { selectDroneImageManualPosition } from '../../../store/Map/Uploads/selectors';
import { actionSetDroneImageManualPosition } from '../../../store/Map/Uploads/actions';
import Analytics from '../../../lib/user-analytics';
import { useDispatch, useSelector } from 'react-redux';

const DroneUploadManualMarker = () => {
    const manualPosition = useSelector(selectDroneImageManualPosition);

    const dispatch = useDispatch();
    const setDroneImagePosition = (position: LatLng | undefined) =>
        dispatch(actionSetDroneImageManualPosition(position));

    const onDragMarkerEnd = (e: LeafletEvent) => {
        if (!e?.target?.getLatLng()) return;
        const markerLatLng = e.target.getLatLng();
        if (markerLatLng) {
            setDroneImagePosition(markerLatLng);
            Analytics.Event('Main View', 'Drone marker manually set for upload', markerLatLng);
        }
    };

    if (!manualPosition) return <React.Fragment />;

    const iconSVG = `data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e%3csvg id='Layer_4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.22 73.56'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23b21511%3b%7d.cls-1%2c.cls-2%7bstroke-width:0px%3b%7d.cls-2%7bfill:%23ea4335%3b%7d%3c/style%3e%3c/defs%3e%3cg id='Marker_Default'%3e%3cpath class='cls-2' d='M25.8%2c72.25c-.86%2c0-1.55-.48-1.71-1.21-1.92-8.7-7.27-16.29-12.44-23.62C6.39%2c39.97%2c1.43%2c32.93%2c1.43%2c25.99%2c1.43%2c12.38%2c12.5%2c1.31%2c26.11%2c1.31s24.68%2c11.07%2c24.68%2c24.68c0%2c7.94-5.32%2c15.31-10.96%2c23.1-4.91%2c6.79-9.99%2c13.82-12.29%2c21.98-.2.7-.9%2c1.16-1.74%2c1.16Z'/%3e%3cpath class='cls-1' d='M26.11%2c2.06c13.22%2c0%2c23.93%2c10.72%2c23.93%2c23.93%2c0%2c14.39-18.19%2c27.04-23.22%2c44.89-.12.41-.57.62-1.02.62s-.89-.2-.98-.62C20.86%2c52.89%2c2.18%2c39.17%2c2.18%2c25.99S12.89%2c2.06%2c26.11%2c2.06M26.11.56C12.09.56.68%2c11.97.68%2c25.99c0%2c7.17%2c5.03%2c14.31%2c10.36%2c21.86%2c5.13%2c7.27%2c10.44%2c14.8%2c12.32%2c23.35.24%2c1.07%2c1.22%2c1.79%2c2.44%2c1.79s2.18-.69%2c2.47-1.71c2.27-8.04%2c7.31-15.01%2c12.18-21.75%2c5.71-7.9%2c11.1-15.36%2c11.1-23.54C51.55%2c11.97%2c40.14.56%2c26.11.56h0Z'/%3e%3ccircle class='cls-1' cx='26.11' cy='26.54' r='9.5'/%3e%3c/g%3e%3c/svg%3e`;

    const icon = new Icon({
        iconUrl: iconSVG,
        iconSize: [38, 50],
        iconAnchor: [19, 50],
    });

    return (
        <Marker
            eventHandlers={{ dragend: (e) => onDragMarkerEnd(e) }}
            position={manualPosition}
            icon={icon}
            draggable={true}
        />
    );
};

export default DroneUploadManualMarker;
