import { useWindowHeight } from '@react-hook/window-size';
import { useLayoutEffect, useState } from 'react';

/**
 * This hook is used to calculate the height of the container when the bottom sheet is open.
 * @param bodyRef - The ref of the body element
 * @param containerHeight - The height of the container
 * @returns The new height of the container
 */
export const useMobilePadding = (bodyRef, containerHeight: number) => {
    const [size, setSize] = useState(16);
    const windowHeight = useWindowHeight();

    // uselayouteffect based on suggestion for observer
    useLayoutEffect(() => {
        bodyRef?.current && setSize(bodyRef.current.getBoundingClientRect().top);
    }, [bodyRef, containerHeight]);

    const swipeBar = 16; // height of swipebar
    const newContainerHeight = windowHeight - size - swipeBar;
    return newContainerHeight;
};
