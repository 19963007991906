import React from 'react';
import styled from 'styled-components';

interface PageErrorProps {
    background: string;
    gradient?;
    credit?: {
        avatarUrl: string;
        name: string;
        location: string;
        profile: string;
    };
    children: JSX.Element[];
}

const PageError = (props: PageErrorProps) => {
    return (
        <Container url={props.background}>
            <ErrorContainer gradient={props.gradient}>
                <ModalContainer>
                    <MessageContainer>{props.children}</MessageContainer>
                </ModalContainer>
            </ErrorContainer>
            {props.credit && (
                <UserCredit onClick={() => props.credit && window.open(props.credit.profile, '_self')}>
                    <Profile src={props.credit.avatarUrl} />
                    <UserDescription>
                        <UserLocation>
                            <span>{props.credit.location}</span>
                        </UserLocation>
                        <UserName>
                            <span>
                                by <a>{props.credit.name}</a>
                            </span>
                        </UserName>
                    </UserDescription>
                </UserCredit>
            )}
        </Container>
    );
};

export default PageError;


interface Gradient {
    gradient?;
}
const Container = styled.div<{ url: string }>`
    ${(props) => `background-image: url(${props.url});`}
    background-size: cover;
    height: 100vh;
`;

const ErrorContainer = styled.div<Gradient>`
    ${(props) => props.gradient && `background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.8));`}
    height: 100%;
    display: flex;
`;

const ModalContainer = styled.div`
    width: 100%;
    margin-top: 20vh;
    margin-left: 20vh;

    @media only screen and (max-width: 600px) {
        margin-top: 10px;
        margin-left: 10px;
    }
`;

const UserCredit = styled.div`
    position: absolute;
    bottom: 30px;
    left: 30px;
    cursor: pointer;

    @media only screen and (max-height: 500px) {
        right: 30px;
        left: auto;
    }
`;

const MessageContainer = styled.div`
    margin: 25px 0px;
    padding-top: 30px;
    padding-bottom: 30px;

    h3 {
        color: #eed926;
        font-size: 84px;
        text-align: left;
        font-weight: bold;
    }

    h4 {
        color: #eed926;
        font-size: 44px;
        text-align: left;
        font-weight: bold;
    }

    p {
        color: white;
        text-align: left;
        font-size: 18px;
        padding-left: 3px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    a {
        color: #eed926 !important;
        font-size: 18px;
    }

    button {
        display: inline-block;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 12px;
        padding-right: 12px;
        min-width: 127px;
        font-size: 1rem;
        background-color: #eed926;
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: black;
        border-radius: 4px;
        margin-bottom: 13px;
    }
`;

const Profile = styled.img`
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 90px;
`;
const UserDescription = styled.div`
    position: relative;
    float: right;
    margin-left: 5px;
    margin-top: -5px;
    span {
        font-size: 17px;
    }
`;
const UserName = styled.div`
    span {
        color: white;
    }
    a {
        color: #eed926 !important;
        text-decoration: underline !important;
    }
`;
const UserLocation = styled.div`
    margin-bottom: -5px;
    span {
        font-weight: bold;
        color: white;
    }
`;
