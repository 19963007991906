import * as React from 'react';

interface SpanDotsProps {
    text: string;
    className?: string;
}

const DotsText = (props: SpanDotsProps) => {
    const customClassName = props.className ? 'dotdotdot ' + props.className : 'dotdotdot';
    return <span className={customClassName}>{props.text}</span>;
};

export default DotsText;
