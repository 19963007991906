import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSideDrawerCollapse } from '../../../store/SideDrawer/selectors';

interface DrawerContentProps {
    children: React.ReactNode;
    className?: string;
}

/**
 * DrawerContent
 * Standardized drawer content to control height and scroll behavior
 * @required children React.ReactNode
 * @optional className string
 */
// TODO peg for removal
const DrawerContent = (props: DrawerContentProps) => {
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    return (
        <DrawerContentContainer className={props.className} closeDraw={sideDrawerCollapse}>
            {props.children}
        </DrawerContentContainer>
    );
};

export default DrawerContent;

interface DrawerContentContainerProps {
    closeDraw?: boolean;
}

// TODO this is starting to become a little messy and needs reviewing
export const DrawerContentContainer = styled.div<DrawerContentContainerProps>`
    transition: all 1s;
    -webkit-transition: all 1s;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: ${(props) => (props.closeDraw ? '0px' : '215px')};
    max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 262px)')};
    overflow: ${(props) => (props.closeDraw ? 'hidden' : '')};

    &.display-comment-input {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 442px)')};
    }

    &.hide-display-comment-input {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 337px)')};
    }

    &.satellite-container {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 296px)')};
    }

    &.draw-stoaries-container {
        min-height: auto;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 310px)')};
    }

    &.draw-stoaries-container-active-map {
        min-height: auto;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 425px)')};
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;
