import L from 'leaflet';
import { defaultZIndex } from '../layers-util';

export const defaultTextBoxRectangleOptions: L.PolylineOptions = {
    color: '#eed926',
    weight: 3,
    fill: false,
    fillOpacity: 0,
    bubblingMouseEvents: true,
};

export const highlightedTextBoxRectangleOptions: L.PolylineOptions = {
    color: '#eed926',
    weight: 3,
    fill: false,
    interactive: false,
    fillOpacity: 0,
    bubblingMouseEvents: true,
};

export const hiddenTextBoxRectangleOptions: L.PolylineOptions = {
    color: 'transparent',
    fill: true,
    interactive: false,
    fillOpacity: 1.0,
    fillColor: 'transparent',
    bubblingMouseEvents: true,
};

export const dragRectangleOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 15,
    fill: false,
    bubblingMouseEvents: true,
};

export const dragRectangleHologramOptions: L.PolylineOptions = {
    color: '#eed926',
    weight: 3,
    dashArray: [5, 5],
    fill: false,
    interactive: false,
};

export type TextBoxFontType = 'Manrope' | 'Arial' | 'Times New Roman';

export default class TextBox {
    annotationType: string;
    id: string;
    bounds: L.LatLngBounds;
    text: string;
    fontSize: string;
    textColor: string;
    fillColor: string;
    fillOpacity: number;
    padding: number;
    zoomBase: number;
    fontSizeBase: number;
    fontFamily: TextBoxFontType;
    zIndex: number;
    caretPosition?: number; // no need to include this in the serialization since it is only used within the current session to retain caret position

    constructor(
        id: string,
        bounds: L.LatLngBounds,
        text: string,
        fontSize: string,
        textColor: string,
        fillColor: string,
        fillOpacity: number,
        padding: number,
        zoomBase: number,
        fontSizeBase: number,
        fontFamily: TextBoxFontType
    ) {
        this.id = id;
        this.bounds = bounds;
        this.text = text;
        this.fontSize = fontSize;
        this.textColor = textColor;
        this.fillColor = fillColor;
        this.fillOpacity = fillOpacity;
        this.padding = padding;
        this.zoomBase = zoomBase;
        this.fontSizeBase = fontSizeBase;
        this.fontFamily = fontFamily;
        this.annotationType = 'TextBox';
    }

    static serialize = (textBox: TextBox): GeoJSON.Feature => {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [textBox.bounds.getNorthWest().lng, textBox.bounds.getNorthWest().lat],
                        [textBox.bounds.getNorthEast().lng, textBox.bounds.getNorthEast().lat],
                        [textBox.bounds.getSouthEast().lng, textBox.bounds.getSouthEast().lat],
                        [textBox.bounds.getSouthWest().lng, textBox.bounds.getSouthWest().lat],
                        [textBox.bounds.getNorthWest().lng, textBox.bounds.getNorthWest().lat],
                    ],
                ],
            },
            properties: {
                annotationType: 'TextBox',
                id: textBox.id,
                zIndex: textBox.zIndex || defaultZIndex,
                text: textBox.text,
                fontSize: textBox.fontSize,
                textColor: textBox.textColor,
                fillColor: textBox.fillColor,
                fillOpacity: textBox.fillOpacity,
                padding: textBox.padding,
                zoomBase: textBox.zoomBase,
                fontSizeBase: textBox.fontSizeBase,
                fontFamily: textBox.fontFamily,
            },
        };
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize = (json: any): TextBox => {
        const boundingBox = new L.LatLngBounds(
            new L.LatLng(json.geometry.coordinates[0][0][1], json.geometry.coordinates[0][0][0]),
            new L.LatLng(json.geometry.coordinates[0][2][1], json.geometry.coordinates[0][2][0])
        );
        return {
            annotationType: 'TextBox',
            id: json.properties.id,
            bounds: boundingBox,
            text: json.properties.text,
            fontSize: json.properties.fontSize,
            textColor: json.properties.textColor,
            fillColor: json.properties.fillColor,
            fillOpacity: json.properties.fillOpacity,
            padding: json.properties.padding,
            zoomBase: json.properties.zoomBase,
            fontSizeBase: json.properties.fontSizeBase,
            fontFamily: json.properties.fontFamily,
            zIndex: json.properties.zIndex || defaultZIndex,
        };
    };
}
