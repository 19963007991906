import React, { useEffect, useState } from 'react';
import MilitaryMarker from './military-marker';
import styled from 'styled-components';
import { Divider, Text } from '../ToolBar/toolbar-modal';
import { createMilitaryMarkerSVGPreview } from './military-marker-util';
import { StyledButton } from '../../../Shared/styled-button';
import SoarModal from '../../../Shared/soar-modal';
import MilitaryMarkerSearch from './military-marker-search';
import Analytics from '../../../../lib/user-analytics';

interface MilitaryMarkerModalProps {
    selectedMarker: MilitaryMarker;
    updateMarker: (marker: MilitaryMarker) => void;
}

const MilitaryMarkerModal = ({ selectedMarker, updateMarker }: MilitaryMarkerModalProps) => {
    const [markerModalOpen, setMarkerModalOpen] = useState(false);
    const [SIDCCode, setSIDCCode] = useState(selectedMarker.sidc);

    useEffect(() => {
        if (selectedMarker?.sidc && markerModalOpen) {
            handlePreview(selectedMarker.sidc);
            setSIDCCode(selectedMarker.sidc);
        }
    }, [markerModalOpen, selectedMarker.sidc]);

    const handlePreview = (sidcCode: string) => {
        requestAnimationFrame(() => {
            const markerPreview = createMilitaryMarkerSVGPreview(sidcCode);
            const previewContainer = document.getElementById('military-marker-preview');

            if (previewContainer) {
                previewContainer.innerHTML = '';
                if (markerPreview && markerPreview?.svgElement) {
                    previewContainer.appendChild(markerPreview.svgElement);
                }
            }
        });
    };

    const handleCustomSIDCCode = (newSidcCode: string) => {
        Analytics.Event('Draw Tools - Project', `Clicked from search custom military marker ${newSidcCode}`);
        setSIDCCode(newSidcCode);
        handlePreview(newSidcCode);
    };

    const handleSelectCustomSIDCCode = () => {
        if (!SIDCCode) {
            return;
        }
        Analytics.Event('Draw Tools - Project', `Clicked to use custom military marker ${SIDCCode}`);
        const updatedMarker = {
            ...selectedMarker,
            sidc: SIDCCode,
        };
        updateMarker(updatedMarker);
        setMarkerModalOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                isSelected={markerModalOpen}
                title={'Custom'}
                onClick={() => {
                    Analytics.Event('Draw Tools - Project', `Clicked to view custom military marker`);
                    setMarkerModalOpen(true);
                }}
            >
                <MoreIcon className="fa fa-ellipsis-v" />
            </Button>
            <MarkerModal
                key={'custom-MIL-STD-2525-or-STANAG-APP-6-code'}
                isOpen={markerModalOpen}
                toggle={() => setMarkerModalOpen(false)}
                title={'Search Military Symbols (NATO)'}
                width="620px"
            >
                <MarkerModalBody>
                    <MarkerModalSection>
                        <Text key="custom-1">Find the symbol you want to use and press "Use Symbol".</Text>
                        <br />
                        <Text key="custom-2">To update a custom symbol use this search!</Text>
                        <Divider />
                        <MilitaryMarkerSearch SIDCCode={selectedMarker.sidc} setSIDCCode={handleCustomSIDCCode} />
                    </MarkerModalSection>
                    <MarkerModalSection>
                        <SidcPreviewContainer id={'military-marker-preview'} />
                        <SelectSIDCButton onClick={handleSelectCustomSIDCCode}>Use Symbol</SelectSIDCButton>
                    </MarkerModalSection>
                </MarkerModalBody>
            </MarkerModal>
        </React.Fragment>
    );
};

export default MilitaryMarkerModal;

const MarkerModal = styled(SoarModal)`
    margin-top: 135px;
`;

const MarkerModalBody = styled.div`
    display: flex;
    flex-direction: row;
`;

const MarkerModalSection = styled.div`
    padding: 5px;
`;

const Button = styled.button<{ isSelected: boolean }>`
    background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
    border: 1px solid rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    color: inherit;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 5px 10px;
    padding: 7px 12px;
    display: flex;
    flex-direction: row;
    height: 38px;
`;

const SelectSIDCButton = styled(StyledButton)`
    margin: 10px auto;

    &:disabled {
        background-color: rgba(255, 255, 255, 0.3) !important;
        cursor: not-allowed;
    }
`;

const SidcPreviewContainer = styled.div`
    border-radius: 4px;
    border-color: rgba(255, 255, 255, 0.95);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    margin-top: 8px;

    img,
    svg {
        width: 200px;
        height: 200px;
    }
`;

const MoreIcon = styled.i`
    color: rgba(255, 255, 255, 0.95);
    margin: 2px 3px;
    font-size: 18px;
`;
