import Arrow from '../../../components/MapView/Annotations/Arrow/arrow';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface ArrowState {
    arrows: Arrow[];
    arrowOptions?: L.PolylineOptions;
}
const initialState: ArrowState = {
    arrows: [],
    arrowOptions: undefined,
};

export default (state: ArrowState = initialState, action: Action): ArrowState => {
    switch (action.type) {
        case at.SET_ARROWS:
            return { ...state, arrows: action.payload.arrows };

        case at.SET_ARROW_OPTIONS:
            return { ...state, arrowOptions: action.payload };

        case at.SET_ARROW_ADD:
            return {
                ...state,
                arrows: [...state.arrows, action.payload],
            };

        case at.SET_ARROW_UPDATE: {
            const newArrows = state.arrows.map((arrow) => {
                if (arrow.id === action.payload.id) {
                    return { ...arrow, ...action.payload };
                }
                return arrow;
            });
            return { ...state, arrows: newArrows };
        }

        case at.SET_ARROW_DELETE: {
            const newArrows = state.arrows.filter((arrow) => arrow.id !== action.payload.id);
            return { ...state, arrows: newArrows };
        }

        default:
            return state;
    }
};
