import Select from 'react-select';
import styled from 'styled-components';

export type ImageSelectOptionType = {
    image: string;
    value: string;
    label?: string;
};

export enum ImageSelectPreviewSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    FULL = 'full',
}

export enum ImageSelectPreviewPlacement {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}

interface ImageSelectProps {
    value: string;
    options: ImageSelectOptionType[];
    showPreview?: boolean; // default: true
    previewSize?: ImageSelectPreviewSize; // default: medium
    previewPlacement?: ImageSelectPreviewPlacement; // default: right
    onChange: (value: ImageSelectOptionType) => void;
    className?: string;
}

const ImageSelect = (props: ImageSelectProps) => {
    const handleFormatOptionLabel = (option: ImageSelectOptionType) => {
        const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = '/assets/transparent.png';
        };

        return (
            <ImageSelectOption $placement={props.previewPlacement ?? ImageSelectPreviewPlacement.RIGHT}>
                <ImageSelectOptionLabel>{option.label ?? option.value}</ImageSelectOptionLabel>
                <ImageSelectOptionPreview
                    src={option.image}
                    $size={props.previewSize ?? ImageSelectPreviewSize.MEDIUM}
                    onError={handleImageError}
                />
            </ImageSelectOption>
        );
    };

    return (
        <Select
            className={`${props.className} image-select-container`}
            classNamePrefix={'image-select'}
            options={props.options}
            defaultValue={props.options.find((opt) => opt.value === props.value)}
            formatOptionLabel={handleFormatOptionLabel}
            onChange={(option) => props.onChange(option as ImageSelectOptionType)}
            isSearchable={false}
            menuPlacement={'auto'}
        />
    );
};

export default ImageSelect;

export const ImageSelectOptionLabel = styled.span`
    flex: 1;
    color: inherit !important;
`;

export const ImageSelectOptionPreview = styled.img<{ $size: ImageSelectPreviewSize }>`
    width: ${(props) => {
        switch (props.$size) {
            case ImageSelectPreviewSize.SMALL:
                return '12.5%';
            case ImageSelectPreviewSize.MEDIUM:
                return '25%';
            case ImageSelectPreviewSize.LARGE:
                return '50%';
            case ImageSelectPreviewSize.FULL:
                return '100%';
        }
    }};
    height: auto;
`;

export const ImageSelectOption = styled.div<{ $placement: ImageSelectPreviewPlacement }>`
    display: flex;
    position: relative;
    align-items: center;

    flex-direction: ${(props) => {
        switch (props.$placement) {
            case ImageSelectPreviewPlacement.TOP:
                return 'column-reverse';
            case ImageSelectPreviewPlacement.BOTTOM:
                return 'column';
            case ImageSelectPreviewPlacement.LEFT:
                return 'row-reverse';
            case ImageSelectPreviewPlacement.RIGHT:
                return 'row';
        }
    }};
`;
