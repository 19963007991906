import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { LatLng } from 'leaflet';

interface StoreUpload {
    droneImagePreviewUrl: string | undefined;
    droneImagePositions: number[][] | undefined;
    droneImageDistortionTool: 'none' | 'rotate' | 'translate' | 'distort' | 'scale';
    droneImageOpacity: number;
    droneImageDistortionCorners: number[][];
    droneImageManualPosition: LatLng | undefined;
}

const initialState: StoreUpload = {
    droneImagePreviewUrl: undefined,
    droneImagePositions: undefined,
    droneImageDistortionTool: 'none',
    droneImageOpacity: 1.0,
    droneImageDistortionCorners: [],
    droneImageManualPosition: undefined,
};

export default (state: StoreUpload = initialState, action: Action): StoreUpload => {
    switch (action.type) {
        case at.ADD_DRONE_IMAGE_FOR_UPLOAD:
            return {
                ...state,
                droneImagePreviewUrl: action.payload.droneImagePreviewUrl,
                droneImagePositions: action.payload.droneImagePositions,
            };

        case at.CLEAR_DRONE_IMAGE_FOR_UPLOAD:
            return {
                ...state,
                droneImagePreviewUrl: undefined,
                droneImagePositions: undefined,
                droneImageDistortionTool: 'none',
                droneImageManualPosition: undefined,
                droneImageOpacity: 1.0,
                droneImageDistortionCorners: [],
            };

        case at.SET_DRONE_IMAGE_DISTORTION_TOOL:
            return {
                ...state,
                droneImageDistortionTool: action.payload,
            };

        case at.SET_DRONE_IMAGE_OPACITY:
            return {
                ...state,
                droneImageOpacity: action.payload,
            };

        case at.SET_DRONE_IMAGE_DISTORTION_CORNERS:
            return {
                ...state,
                droneImageDistortionCorners: action.payload,
            };

        case at.SET_DRONE_IMAGE_MANUAL_POSITION:
            return {
                ...state,
                droneImageManualPosition: action.payload,
            };

        default:
            return state;
    }
};
