import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FillColorModal from './fill-color-modal';

interface ColorSelectorProps {
    title: string;
    selectedColor: string;
    onSelectColor: (color: string) => void;
    toggle?: () => void;
}

const ColorSelector = (props: ColorSelectorProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toolbarButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isOpen && props.toggle) {
            props.toggle();
        }
    }, [isOpen, props]);

    return (
        <React.Fragment>
            <Button
                ref={toolbarButtonRef}
                isSelected={isOpen}
                title={props.title}
                onClick={() => setIsOpen((current) => !current)}
            >
                <ColorPreview color={props.selectedColor} />
            </Button>

            {isOpen && (
                <FillColorModal
                    toolbarButtonRef={toolbarButtonRef}
                    title={`Select ${props.title}`}
                    onClose={() => setIsOpen(false)}
                    selectedColor={props.selectedColor}
                    onSelectColor={props.onSelectColor}
                />
            )}
        </React.Fragment>
    );
};

export default ColorSelector;

const Button = styled.button<{ isSelected: boolean }>`
    background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
    color: inherit;
    border: none;
    border-radius: 4px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 5px 4px 4px 4px;
    padding: 7px 12px;
    display: flex;
    flex-direction: row;
    height: 38px;
`;

const ColorPreview = styled.div<{ color: string }>`
    width: 18px;
    height: 18px;
    background: ${(props) => props.color};
    border: 1px solid white;
    margin: 2px 0 0 0;
    border-radius: 9px;
`;
