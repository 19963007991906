import { useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Fade } from 'reactstrap';
import styled from 'styled-components';
import { SentinelFeature } from '../../../store/Map/Sentinel/model';
import UserHelper from '../../../lib/user-helper';
import ApiAnalytics from '../../../api/api-analytics';
import { SatelliteProvider, AnalyticsAction } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../store/App/actions';
import { actionSentinelSelectFeature, actionSentinelEndBoxSelect } from '../../../store/Map/Sentinel/actions';

const moment = require('moment');

interface SatelliteListStyleCardProps {
    feature: SentinelFeature;
    index?: number;
    onCloseMap: () => void;
}

const SatelliteListStyleCard = (props: SatelliteListStyleCardProps) => {
    const [previewImageFailed, setPreviewImageFailed] = useState<boolean>(false);
    const dispatch = useDispatch();

    return (
        <Fade in={props.feature ? true : false}>
            <Container
                onClick={() => {
                    // NOTE - THIS IS A HACK FOR SAFARI ISSUE AND LEAFLET ON MOBILE
                    // The fix lessens the issue of satellite images loading in but still remains and issue for 100m
                    // #5237 https://github.com/SoarEarth/soar-platform/issues/5237#issuecomment-1507858289
                    dispatch(actionSentinelSelectFeature(undefined));
                    {
                        isSafari
                            ? requestAnimationFrame(() => {
                                  setTimeout(() => {
                                      dispatch(actionSentinelSelectFeature(props.feature));
                                  }, 300);
                              })
                            : requestAnimationFrame(() => {
                                  dispatch(actionSentinelSelectFeature(props.feature));
                              });
                    }
                    props.onCloseMap();
                    dispatch(actionSentinelEndBoxSelect());
                    dispatch(actionFlyToOnMap(props.feature.bbox));
                    Analytics.Event(
                        'Satellite - Sentinel',
                        'Selected sentinel feature',
                        props.feature.bbox.toBBoxString()
                    );
                    ApiAnalytics.postAnalyticsSatellite(
                        props.feature.previewUrl,
                        SatelliteProvider.SINERGISE,
                        props.feature.satelliteAnalytics,
                        AnalyticsAction.VIEW
                    );
                }}
            >
                <PreviewImageContainer>
                    {previewImageFailed ? (
                        <PreviewFailed>
                            <PreviewFailedImage src="/assets/image-unavailable/preview_fallback.svg" />
                            <PreviewFailedText>
                                Preview not <br /> available
                            </PreviewFailedText>
                        </PreviewFailed>
                    ) : (
                        <PreviewImage src={props.feature.previewUrl} onError={() => setPreviewImageFailed(true)} />
                    )}
                </PreviewImageContainer>
                <ContentContainer>
                    <Title>{moment(props.feature.date).format('MMM Do YYYY')}</Title>
                    <SubText>
                        <LayerText>{UserHelper.formatLayerText(props.feature.layer)}</LayerText>
                        <PixelText>{`Pixel resolution: ${props.feature.resolution}`}</PixelText>
                    </SubText>
                </ContentContainer>
            </Container>
        </Fade>
    );
};

export default SatelliteListStyleCard;

const Container = styled.div`
    pointer-events: all;
    height: 48px;
    width: calc(100% - 8px);
    border-radius: 6px;
    overflow: hidden;
    margin: 0 4px 3px 4px;
    border: 1px solid white;
    border: 1px solid rgb(255 255 255 / 10%);
    background-color: hsla(0, 100%, 100%, 0.01);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
    display: flex;
    flex-direction: horizontal;
`;

const PreviewImageContainer = styled.div`
    height: 100%;
    width: 25%;
    max-width: 64px;
`;

const PreviewImage = styled.img`
    width: 100%;
    object-fit: contain;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin: 0;
    padding: 0;
`;

const PreviewFailed = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 68px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

const PreviewFailedImage = styled.img`
    width: 60%;
    height: auto;
    object-fit: cover;
`;

const PreviewFailedText = styled.div`
    font-size: 10px;
    color: white;
    text-align: center;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 2px 0 0 4px;
    width: 75%;
`;

const Title = styled.p`
    color: white;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const LayerText = styled.p`
    color: #eed926;
    margin-bottom: 0px;
`;

const PixelText = styled.p`
    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 0px;

    span {
        color: #eed926;
    }
`;
