import { useEffect } from 'react';
import { useKeyPress } from '../../lib/use-keypress';

/**
 * When the ESC key is pressed this hook will:
 *  - Clear the input or
 *  - Close the search container if there is no input
 * @param inputRef
 * @param setHasFocus
 * @param setSearchTerm
 */
export const useInvalidateSearchOnEscapeKey = (
    inputRef: HTMLInputElement | null,
    setHasFocus: (hasFocus: boolean) => void,
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>
) => {

    const escapeKeyPressed = useKeyPress('Escape');

    useEffect(() => {
        if (inputRef) {
            const searchTerm = inputRef.value;
            if (escapeKeyPressed) {
                if (searchTerm.length > 0) {
                    setSearchTerm('')
                } else {
                    setHasFocus(false);
                    inputRef.blur();
                }
            }
        }
    }, [escapeKeyPressed, inputRef, setHasFocus, setSearchTerm])
}
