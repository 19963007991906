import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import SoarHelper from '../../../../../lib/soar-helper';
import UriHelper from '../../../../../lib/uri-helper';
import Analytics from '../../../../../lib/user-analytics';
import { deleteAllAnnotationsAction } from '../../../../../store/Annotations/actions';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import { selectSideDrawerCollapse } from '../../../../../store/SideDrawer/selectors';
import { StoaryDrawerHeader } from '../../../../Drawer/drawer-control';
import ProfileName from '../../../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';
import DrawerContent from '../../../../Drawer/Shared/drawer-content';
import ThinDrawer from '../../../../Drawer/Shared/thin-drawer';
import { TruncatedText } from '../../../../Shared/truncated-text';
import useMapInteractionControl from '../../use-map-interaction-control';
import StoryMapAttribution from './story-map-attribution';
import StoryMapControlEdit from './story-map-control-edit';
import { selectSentinelSelectedFeature } from '../../../../../store/Map/Sentinel/selectors';
import SentinelMapAttribution from './sentinel-map-atribution';

import { actionActiveMapCleared } from '../../../../../store/Map/SuperMap/actions';
import { actionSentinelResetSelected } from '../../../../../store/Map/Sentinel/actions';

const moment = require('moment');

interface StoryMapSidedrawerProps {
    numberOfPages: number;
    projectTitle: string;
    pageTitle: string;
    description: string;
    userName: string;
    userId: string;
    reputable: boolean;
    updatedDatestamp?: string;
}

const MAX_DESCRIPTION_LENGTH = 5000;

const StoryMapSidedrawer = (props: StoryMapSidedrawerProps) => {
    const activeMap = useSelector(selectActiveAllMap);
    const sentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    const dispatch = useDispatch();

    // The StoryMapsSidedrawer is a child of the mapcontainer so we
    // disable the map events or it can not be interacted with it..
    const containerRef = useRef<HTMLDivElement | null>(null);
    useMapInteractionControl(containerRef);

    const handleUrlCopy = async () => {
        const url = `${window.location.origin}${window.location.pathname}`;
        navigator.clipboard.writeText(url);
        toast.dark('Copied to clipboard');
        Analytics.Event(`Stoary - view`, `Copied To Share - ${url}`, activeMap?.id);
    };

    const handleShowProfile = () => {
        Analytics.Event('Stoary', 'Clicked Stoary Creator Profile', props.userId);
        UriHelper.navigateToPath(`/profile/${props.userId}`);
    };

    const handleBack = () => {
        dispatch(actionActiveMapCleared());
        dispatch(actionSentinelResetSelected());

        // If the user closes a draw project by the X it resets to the /maps initial default view
        // which seems to be the intended behavior
        UriHelper.addPositionToPath('/maps');
        Analytics.Event('Stoary', 'Clicked to close project drawer while viewing');
    };

    useEffect(() => {
        return () => {
            dispatch(deleteAllAnnotationsAction());
        };
    }, [dispatch]);

    const renderPageTitle = () => {
        if (!props.pageTitle) {
            return null;
        }

        if (
            props.numberOfPages > 1 ||
            (props.numberOfPages === 1 && !/^Page [0-9]+( \(copy\))?$/i.test(props.pageTitle))
        ) {
            return (
                <React.Fragment>
                    <SubTitleDivider />
                    <StyledDrawerHeader>
                        <SubTitle>{props.pageTitle}</SubTitle>
                    </StyledDrawerHeader>
                </React.Fragment>
            );
        }
        return null;
    };

    return (
        <StoryMapDrawContainer ref={containerRef}>
            <ThinDrawer style={{ minWidth: '300px' }} id="ThinDrawer">
                <StoaryDrawerHeader backButtonTooltip="Close Stoary" collapsible handleBack={handleBack} title=" " />
                {/* <DrawerHeader backButtonTooltip="Close Stoary" collapsible handleBack={handleBack} title="" /> */}
                <DrawerContent
                    className={activeMap?.id ? 'draw-stoaries-container-active-map' : 'draw-stoaries-container'}
                >
                    <StyledDrawerHeader>
                        <Title>{props.projectTitle || props.pageTitle || 'Soar Stoary'}</Title>
                    </StyledDrawerHeader>
                    <StyledAuthorContainer>
                        by&nbsp;
                        {SoarHelper.isSoar() ? (
                            <UserProfileLink
                                userId={props.userId}
                                className="user-profile-link"
                                onClick={handleShowProfile}
                                isReputable={props.reputable}
                            >
                                {props.userName}
                            </UserProfileLink>
                        ) : (
                            <React.Fragment>{props.userName}</React.Fragment>
                        )}
                    </StyledAuthorContainer>
                    {props.updatedDatestamp ? (
                        <Date>{moment(Number(props.updatedDatestamp) * 1000).fromNow()}</Date>
                    ) : null}

                    {renderPageTitle()}

                    <Content>
                        {props.description ? (
                            <TruncatedText text={props.description} length={MAX_DESCRIPTION_LENGTH} />
                        ) : null}
                    </Content>
                </DrawerContent>
                <DrawerFooter closeDraw={sideDrawerCollapse}>
                    {activeMap || sentinelFeature ? (
                        <React.Fragment>
                            {activeMap && <StoryMapAttribution listing={activeMap} />}
                            {sentinelFeature && <SentinelMapAttribution feature={sentinelFeature} />}

                            <DrawerFooterContent>
                                <DrawerUserOptions>
                                    <StoryMapControlEdit creatorId={props.userId} />
                                    <StoariesShareCopyLink
                                        title={'Copy Stoary link'}
                                        onClick={() => handleUrlCopy()}
                                        className={'fa fa-share'}
                                    />
                                </DrawerUserOptions>
                            </DrawerFooterContent>
                        </React.Fragment>
                    ) : (
                        <DrawerFooterContent>
                            <DrawerUserOptions>
                                <StoryMapControlEdit creatorId={props.userId} />
                                <StoariesShareCopyLink
                                    title={'Copy Stoary link'}
                                    onClick={() => handleUrlCopy()}
                                    className={'fa fa-share'}
                                />
                            </DrawerUserOptions>
                        </DrawerFooterContent>
                    )}
                </DrawerFooter>
            </ThinDrawer>
        </StoryMapDrawContainer>
    );
};

export default StoryMapSidedrawer;

const StoryMapDrawContainer = styled.div``;

const StyledDrawerHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
`;

const Title = styled.h1`
    font-weight: 700;
    font-size: 30px;
    color: white;
    margin: 12px;
    flex: 0 1 auto;
    text-align: left;
    max-width: 440px;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 3rem;
    word-break: break-word;
    hyphens: auto;
`;

const SubTitle = styled.h2`
    font-weight: 600;
    font-size: 20px;
    color: white;
    margin: 6px 12px;
    flex: 0 1 auto;
    text-align: left;
    max-width: 440px;
    -webkit-line-clamp: 5;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    word-break: break-word;
    hyphens: auto;
`;

const SubTitleDivider = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 12px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    margin: 0px 12px 12px 12px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
    word-break: break-word;
    min-height: 30px;

    p {
        font-weight: 200;
    }
`;

const DrawerFooter = styled.div<{ closeDraw: boolean }>`
    overflow: ${(props) => (props.closeDraw ? 'hidden' : '')};
    user-select: ${(props) => (props.closeDraw ? 'none' : '')};
    opacity: ${(props) => (props.closeDraw ? '0' : '1')};
    height: ${(props) => (props.closeDraw ? '0px' : '100%')};

    transition: all 0.55s;
    -webkit-transition: all 0.55s;
`;

const DrawerFooterContent = styled.div`
    flex: 0 1 50px;
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px 0px 10px 0px;
    background: rgb(255, 255, 255, 5%);
    flex-direction: column;

    .active-map-share-container {
        border-bottom: none;
        border-top: none;
        margin-top: 0px;
        max-height: 35px;
        padding: 10px 0 0 15px;
    }

    .opacity-slider {
        border-bottom: none;
        border-top: none;
        margin-top: 0px;
        margin: 0 0 0 0;
        padding: 0px 12px;

        .p {
            color: rgb(255, 255, 255, 0.87);
            font-size: 15px;
        }
    }
`;

const DrawerUserOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const StoariesShareCopyLink = styled.i`
    display: flex;
    justify-content: flex-end;
    margin: 5px 10px 0px 0px;
    padding: 5px;
    color: white;
    font-size: 18px;
    pointer-events: all;
    cursor: pointer;

    &:hover {
        color: #eed926;
    }
`;

const UserProfileLink = styled(ProfileName)`
    display: inline-flex;
    width: auto;
    max-width: 90%;
    font-size: 16px !important;
    margin: 0 !important;

    a {
        min-width: 0;
        color: #eed926 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
    }
`;

const StyledAuthorContainer = styled.span`
    display: flex;
    flex-direction: row;
    margin: 0px 0px -7px 12px;
    padding: 0 6px 4px 0;
    color: white;
    font-size: 16px;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        padding-left: 3px;
        align-self: center;
        cursor: help;
        height: 17px;
    }
`;

const Date = styled.div`
    margin-left: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    margin-bottom: 6px;
`;
