import Circle from '../Circle/circle';
import {
    MeasurementToolbarCheckbox,
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
} from './measurement-toolbar';

interface MeasurementToolbarRadiusProps {
    circle: Circle;
    onChangeShowRadius: (showRadius: boolean) => void;
}

const MeasurementToolbarRadius = (props: MeasurementToolbarRadiusProps) => {
    return (
        <MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionLabel htmlFor="circle-radius-tick">Show Radius:</MeasurementToolbarOptionLabel>
            <MeasurementToolbarCheckbox
                id="circle-radius-tick"
                type="checkbox"
                checked={props.circle.showRadius}
                onClick={() => props.onChangeShowRadius(!props.circle.showRadius)}
            />
        </MeasurementToolbarOptionContainer>
    );
};

export default MeasurementToolbarRadius;
