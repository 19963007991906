import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import { actionSetSelectedSearchTerm } from '../../../store/Map/MapSelection/actions';
import { selectSelectedSearchTerm } from '../../../store/Map/MapSelection/selectors';
import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../../store/Map/SuperMap/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';
import MobileNavBar from '../NavBar/mobile-navbar';
import MobileSearchResults from '../Search/Results/mobile-search-results';
import MobileMapContainer from '../mobile-map-container';

const MobileExplorePage = () => {
    const dispatch = useDispatch();
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);

    const setSelectedSearchTerm = (searchTerm: string | undefined) => dispatch(actionSetSelectedSearchTerm(searchTerm));

    const activeMap = useSelector(selectActiveAllMap);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);

    useEffect(() => {
        if (activeMap === undefined) {
            UriHelper.replacePath(`/maps`);
        }
    }, [activeMap]);

    return (
        <MobileExploreContainer data-testid="mobile-explore">
            {activeMapIsFetching ? (
                <MobileActiveMapLoading>
                    <PulseLoader />
                </MobileActiveMapLoading>
            ) : null}
            {activeMap ? (
                <MobileGridMapViewer>
                    <MobileMapContainer />
                </MobileGridMapViewer>
            ) : null}
            <MobileNavBar
                selectedSearchTerm={selectedSearchTerm || ''}
                setSelectedSearchTerm={setSelectedSearchTerm}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
            />
            <MobileSearchResults selectedSearchTerm={selectedSearchTerm || ''} />
            {/* Currently not accessible on mobile, was part of the original design and unknown if it will be requested to add back in */}
            {/*
            {searchKeyword?.length ? (
                <MobileSearchResults selectedSearchTerm={selectedSearchTerm} />
            ) : (
                <MobileSearchDiscovery />
            )} */}
        </MobileExploreContainer>
    );
};

export default MobileExplorePage;

const MobileExploreContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgb(34, 34, 34);
    width: 100dvw;
    height: 100dvh;
`;

const MobileGridMapViewer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
`;

const MobileActiveMapLoading = styled.div`
    position: absolute;
    margin: auto;
    top: 50%;
    z-index: 9999;
    width: 100%;
`;
