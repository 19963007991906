import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectFillPatterns } from '../../store/Annotations/selectors';

// the AnnotationPatterns component dynamically manages the addition of SVG patterns to the DOM, allowing them to be utilized in fill patterns
// without the need for direct manipulation. By doing so, we can use the fill pattern as the fillColor on the shape without
// requiring the addition of an SVG overlay to the annotation.

const AnnotationPatterns = () => {
    const selectedFillPatterns = useSelector(selectFillPatterns);

    useEffect(() => {
        if (!selectedFillPatterns) return;

        // We get the SVG container from the DOM
        const svgPatternHolder = document.querySelector('.draw-tool-svg-pattern-container');

        if (svgPatternHolder) {
            // Clear existing patterns from the SVG
            while (svgPatternHolder.firstChild) {
                svgPatternHolder.removeChild(svgPatternHolder.firstChild);
            }

            // Add SVG Pattern to the SVG so they can be used in the fill patterns
            selectedFillPatterns.forEach((patternData) => {
                const pattern = createPatternElement(patternData);
                svgPatternHolder.appendChild(pattern);
            });
        }
    }, [selectedFillPatterns]);

    // Create SVG Pattern Element
    const createPatternElement = (patternData) => {
        const patternElement = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
        patternElement.setAttribute('id', patternData.id);
        const patternContent = patternData.pattern.trim();
        patternElement.innerHTML = patternContent;

        return patternElement;
    };

    // Adds the SVG container to the DOM so we can add SVG patterns to it
    return <svg className="draw-tool-svg-pattern-container" />;
};

export default AnnotationPatterns;
