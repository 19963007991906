import TextBox from '../../../components/MapView/Annotations/Text/textbox';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface TextBoxState {
    textBoxes: TextBox[];
    textboxOptions?: TextBox;
}

const initialState: TextBoxState = {
    textBoxes: [],
    textboxOptions: undefined,
};

export default (state: TextBoxState = initialState, action: Action): TextBoxState => {
    switch (action.type) {
        case at.SET_TEXT_BOXES:
            return { ...state, textBoxes: action.payload.textBoxes };

        case at.SET_TEXTBOX_OPTIONS:
            return { ...state, textboxOptions: action.payload };

        case at.SET_TEXT_BOX_ADD:
            return { ...state, textBoxes: [...state.textBoxes, action.payload] };

        case at.SET_TEXT_BOX_UPDATE:
            return {
                ...state,
                textBoxes: state.textBoxes.map((t) => (t.id === action.payload.id ? action.payload : t)),
            };

        case at.SET_TEXT_BOX_DELETE:
            return {
                ...state,
                textBoxes: state.textBoxes.filter((t) => t.id !== action.payload.id),
            };

        default:
            return state;
    }
};
