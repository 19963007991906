import React from 'react';
import styled from 'styled-components';
import LineStylePreview from './line-style-preview';

export interface LineStyleDescription {
    name: string;
    previewUrl: string;
    dashArray?: number[];
    weight: number;
}

const DEFAULT_LINE_THICKNESS = 3;

export const lineStyles: LineStyleDescription[] = [
    {
        name: 'Very Thin',
        weight: 1,
        previewUrl: '/assets/annotations/line-style-thin.svg',
    },
    {
        name: 'Thin',
        weight: DEFAULT_LINE_THICKNESS,
        previewUrl: '/assets/annotations/line-style-thin.svg',
    },
    {
        name: 'Medium',
        weight: 5,
        previewUrl: '/assets/annotations/line-style-medium.svg',
    },
    {
        name: 'Thick',
        weight: 10,
        previewUrl: '/assets/annotations/line-style-thick.svg',
    },
    {
        name: 'Very Thick',
        weight: 15,
        previewUrl: '/assets/annotations/line-style-very-thick.svg',
    },
    {
        name: 'Dot',
        dashArray: [2, 12],
        weight: DEFAULT_LINE_THICKNESS,
        previewUrl: '/assets/annotations/line-style-dot.svg',
    },
    {
        name: 'Dash',
        dashArray: [5, 5],
        weight: DEFAULT_LINE_THICKNESS,
        previewUrl: '/assets/annotations/line-style-dash.svg',
    },
    {
        name: 'Long Dash',
        dashArray: [10, 5],
        weight: DEFAULT_LINE_THICKNESS,
        previewUrl: '/assets/annotations/line-style-long-dash.svg',
    },
    {
        name: 'Dash Dot',
        dashArray: [12, 12, 1, 12],
        weight: DEFAULT_LINE_THICKNESS,
        previewUrl: '/assets/annotations/line-style-dash-dot.svg',
    },
];

interface LineStyleProps {
    selectedLineStyle: L.PolylineOptions;
    onSelectLineStyle: (lineStyle: L.PolylineOptions) => void;
    onClose: () => void;
}

const LineStylePopup = (props: LineStyleProps) => {
    const lineStyleComparator = (a: LineStyleDescription, b: L.PolylineOptions): boolean => {
        return a.dashArray === b.dashArray && a.weight === b.weight;
    };

    return (
        <PopupContainer>
            <TitleContainer>
                <Title>Style</Title>
                <CloseIcon className="fa fa-close" onClick={() => props.onClose()} />
            </TitleContainer>

            <List>
                {lineStyles.map((lineStyle) => {
                    return (
                        <ListItem
                            key={lineStyle.name}
                            isSelected={lineStyleComparator(lineStyle, props.selectedLineStyle)}
                            onClick={() => props.onSelectLineStyle(lineStyle)}
                        >
                            <LineStylePreview width={170} height={32} lineStyle={lineStyle} />
                        </ListItem>
                    );
                })}
            </List>
        </PopupContainer>
    );
};

export default LineStylePopup;

const PopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: 330px;
    width: auto;
    display: flex;
    flex-direction: column;

    background-color: rgba(20, 20, 19, 1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    margin: 0;
    padding: 0;
`;

const List = styled.ul`
    list-style: none;
    display: block;
    width: 180px;
    margin: 5px;
    padding: 0;
`;

const ListItem = styled.li<{ isSelected: boolean }>`
    height: 32px;
    border: ${(props) => (props.isSelected ? '1px solid rgba(255, 255, 255, 0.5) ' : '1px solid transparent')};
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
    padding: 0;
`;

const TitleContainer = styled.div`
    padding: 7px 0 7px 0;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
`;

const Title = styled.h4`
    color: white;
    display: block;
    text-align: left;
    font-size: 1.1rem;
    padding: 0 0 0 5px;
    margin: 0;
`;

const CloseIcon = styled.i`
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.1rem;
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 6px;
`;
