import Rectangle from '../../../components/MapView/Annotations/Rectangle/rectangle';
import { actionTypes as at } from './constants';

export const setRectanglesAction = (rectangles: Rectangle[]) => {
    return {
        type: at.SET_RECTANGLES,
        payload: { rectangles: rectangles },
    };
};

export const setRectangleOptionsAction = (options: L.PolylineOptions) => {
    return {
        type: at.SET_RECTANGLE_OPTIONS,
        payload: options,
    };
};

export const setRectangleAddAction = (rectangle: Rectangle) => {
    return {
        type: at.SET_RECTANGLE_ADD,
        payload: rectangle,
    };
};

export const setRectangleUpdateAction = (rectangle: Rectangle) => {
    return {
        type: at.SET_RECTANGLE_UPDATE,
        payload: rectangle,
    };
};

export const setRectangleDeleteAction = (rectangle: Rectangle) => {
    return {
        type: at.SET_RECTANGLE_DELETE,
        payload: rectangle,
    };
};
