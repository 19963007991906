import { NotificationDTO } from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

interface StoreNotifications {
    notifications: NotificationDTO[];
    viewed: boolean;
    loading: boolean;
    open: boolean;
    allNotificationsLoaded: boolean;
}

const initialState: StoreNotifications = {
    notifications: [],
    viewed: false,
    loading: false,
    open: false,
    allNotificationsLoaded: false,
};

export default (state: StoreNotifications = initialState, action: Action): StoreNotifications => {
    switch (action.type) {
        case at.NOTIFICATION_GET_SUCCESS: {
            const newNotifications: NotificationDTO[] = action.payload.notifications;
            const offset = action.payload.offset;
            return {
                ...state,
                notifications: offset ? [...state.notifications, ...newNotifications] : newNotifications,
                allNotificationsLoaded: newNotifications.length < 10,
            };
        }
        case at.NOTIFICATION_GET_ERROR:
            return { ...state, notifications: [] };
        case at.NOTIFICATION_LOADING:
            return { ...state, loading: action.payload };
        case at.NOTIFICATIONS_OPEN:
            return { ...state, open: action.payload };
        default:
            return state;
    }
};
