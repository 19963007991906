import L from 'leaflet';
import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';

interface ArrowHeadProps {
    id: string;
    arrowShaftElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
    color?: string;
    isDisabled?: boolean;
}

export const createArrowHeadElement = (props: ArrowHeadProps, context: LeafletContextInterface, pane?: string) => {
    const ARROW_HEAD_SIZE = 35;

    const arrowHeadBearing = (map: L.Map, from: L.LatLng, to: L.LatLng): number => {
        // Note: This was copied from the original arrow annotation. There is some unusual math and logic here.
        // I suspect it solves cartesian bearing calculation 'twisting' over long distances and
        // edge cases for when the arrow crosses meridians and equators.
        const fromPoint = map.latLngToLayerPoint(from);
        const toPoint = map.latLngToLayerPoint(to);
        const vector = {
            x: toPoint.x - fromPoint.x ? toPoint.x - fromPoint.x : 0.01,
            y: toPoint.y - fromPoint.y ? toPoint.y - fromPoint.y : 0.01,
        };
        let pixelBearing = Math.abs(180 * Math.atan(vector.y / vector.x)) / Math.PI;
        if (vector.y >= 0 && vector.x < 0) {
            pixelBearing = 180 - pixelBearing;
        } else if (vector.y < 0 && vector.x < 0) {
            pixelBearing += 180;
        } else if (vector.y < 0 && vector.x >= 0) {
            pixelBearing = 360 - pixelBearing;
        }
        const mod = (a: number, b: number) => {
            return ((a % b) + b) % b; // WTF?
        };

        return (mod(pixelBearing + 90, 360) - 45) % 360;
    };

    const createArrowHeadHTML = (size: number, color: string) => `
        <div id="arrow-head-div-enclosure-${props.id}" style="width: ${size}px; height: ${size}px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.18 49.18">
                <defs>
                    <style>.cls-1-${props.id}{fill:${color};}</style>
                </defs>
                <g>
                <path class="cls-1-${props.id}" d="M49,1.9L25.2,48.2c-0.5,1-2.1,0.9-2.4-0.3l-6.2-15c-0.1-0.4-0.5-0.8-0.9-0.9L0.9,26.3C-0.2,26-0.3,24.5,0.7,24L47.3,0.1
		C48.4-0.4,49.6,0.8,49,1.9z"/>
                </g>
            </svg>
        </div>
    `;

    const arrowHeadIcon = L.divIcon({
        className: 'arrow-head-icon',
        html: createArrowHeadHTML(ARROW_HEAD_SIZE, props.color ? props.color : '#EED926'),
        iconSize: [0, 0],
        iconAnchor: [0, 0],
    });

    const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
    const arrowHeadMarker = pane
        ? new L.Marker(endLatLng, { icon: arrowHeadIcon, interactive: true, pane: pane })
        : new L.Marker(endLatLng, { icon: arrowHeadIcon, interactive: true });

    const arrowHeadMarkerElement = createElementObject<L.Marker, ArrowHeadProps>(arrowHeadMarker, context);

    const updateArrowHead = () => {
        const startLatLng = props.arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
        arrowHeadMarkerElement.instance.setLatLng(endLatLng);
        const element = document.getElementById(`arrow-head-div-enclosure-${props.id}`);

        if (element) {
            const bearing = arrowHeadBearing(context.map, startLatLng, endLatLng);
            element.style.height = `${ARROW_HEAD_SIZE}px`;
            element.style.width = `${ARROW_HEAD_SIZE}px`;
            element.style.marginLeft = `-${ARROW_HEAD_SIZE}px`;
            element.style.marginTop = '-0px';
            element.style.transform = `rotate(${bearing}deg)`;
            element.style.transformOrigin = '100% 0%';
        }
    };

    arrowHeadMarkerElement.instance.on('add', () => {
        context.map.addLayer(arrowHeadMarkerElement.instance);
        updateArrowHead();
    });

    arrowHeadMarkerElement.instance.on('remove', () => {
        context.map.removeLayer(arrowHeadMarkerElement.instance);
        context.map.off('zoomend', updateArrowHead);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    arrowHeadMarkerElement.instance.on('update-color', (e: any) => {
        const arrowHeadIcon = L.divIcon({
            className: 'arrow-head-icon',
            html: createArrowHeadHTML(ARROW_HEAD_SIZE, e.arrow.options.color || '#EED926'),
            iconSize: [0, 0],
            iconAnchor: [0, 0],
            popupAnchor: [0, 0],
        });

        arrowHeadMarkerElement.instance.setIcon(arrowHeadIcon);
        updateArrowHead();
    });

    arrowHeadMarkerElement.instance.on('update', updateArrowHead);

    context.map.on('zoomend', updateArrowHead);

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        arrowHeadMarkerElement.instance.options.interactive = false;
    } else {
        arrowHeadMarkerElement.instance.options.interactive = true;
    }

    return arrowHeadMarkerElement;
};
