import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';
import Image from './image';
import ImageAnnotationOpacitySlider from './image-annotation-opacity-slider';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { selectImages } from '../../../../store/Annotations/Images/selectors';
import { setImageDeleteAction, setImageUpdateAction } from '../../../../store/Annotations/Images/actions';

const ImageToolbar = () => {
    const dispatch = useDispatch();
    const images = useSelector(selectImages);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedImage = images.find((c) => c.id === selectedAnnotation?.id);

    const updateImage = (image: Image) => {
        dispatch(setImageUpdateAction(image));
    };

    if (!selectedImage) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Transparency:</ToolbarText>
            <ImageAnnotationOpacitySlider
                opacity={selectedImage.opacity}
                handleOpacity={(opacity) => {
                    updateImage({
                        ...selectedImage,
                        opacity: opacity,
                    });
                }}
            />
            <ToolbarDivider />

            <ToolbarText>Arrange:</ToolbarText>
            <LayerOrderControl annotation={selectedImage} />
            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    DrawToolAnalytics.delete('image');
                    dispatch(setImageDeleteAction(selectedImage));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default ImageToolbar;
