import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../../lib/uri-helper';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import UserHelper from '../../../../lib/user-helper';
import { selectMyProfile } from '../../../../store/Account/selectors';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { StyledButton } from '../../../Shared/styled-button';
import DrawerHint from '../../drawer-hint';
import { Content, Logo, LogoContainer, TeaserIcon } from '../satellite-drawer-styles';

import { SideDrawerBackButton } from '../../SideDrawer/Shared/side-drawer-buttons';

const CGSTLDrawer = () => {
    const myProfile = useSelector(selectMyProfile);

    const back = () => {
        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
        Analytics.Event('Satellite - CGSTL', 'Clicked back arrow');
    };
    return (
        <React.Fragment>
            <SideDrawerBackButton onClick={back} />

            <LogoContainer>
                <Logo src="/assets/floating-drawer-satellite-icons/satellite-cgstl-logo.png" />
            </LogoContainer>
            <ContentContainer>
                <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />

                <TeaserList>
                    <TeaserListItem>Purchase high resolution imagery from 50cm per pixel</TeaserListItem>
                    <TeaserListItem>Mosaiced and colour balanced file</TeaserListItem>
                    <TeaserListItem>View the imagery online or download as a GeoTIFF</TeaserListItem>
                    <TeaserListItem>No tools or skills are required</TeaserListItem>
                    <TeaserListItem>Click & collect credit card payments</TeaserListItem>
                </TeaserList>
                {!UserHelper.hasWritePermissionsForSubdomain(myProfile) ? (
                    <DrawerHint isActive={true}>You do not have permission to order satellite imagery!</DrawerHint>
                ) : (
                    <>
                        <Button
                            onClick={() => {
                                UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_50);
                                Analytics.Event('Satellite - CGSTL', 'Clicked CGSTL 50cm Archive');
                                Analytics.Conversion(ConversionEvent.SELECT_ORDER_TYPE);
                            }}
                        >
                            50cm ARCHIVE
                        </Button>

                        <Button
                            onClick={() => {
                                UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_75);
                                Analytics.Event('Satellite - CGSTL', 'Clicked CGSTL 75cm Archive');
                                Analytics.Conversion(ConversionEvent.SELECT_ORDER_TYPE);
                            }}
                        >
                            75cm ARCHIVE
                        </Button>

                        <Button
                            onClick={() => {
                                UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_100);
                                Analytics.Event('Satellite - CGSTL', 'Clicked CGSTL 100cm Archive');
                                Analytics.Conversion(ConversionEvent.SELECT_ORDER_TYPE);
                            }}
                        >
                            1m ARCHIVE
                        </Button>

                        <Button disabled title="Coming soon">
                            NEW COLLECT
                        </Button>
                        <Button disabled title="Coming soon">
                            <NightSymbol src="/assets/floating-drawer-satellite-icons/night-symbol.svg" /> NIGHT TIME
                        </Button>
                    </>
                )}
            </ContentContainer>
        </React.Fragment>
    );
};

export default CGSTLDrawer;

const TeaserList = styled.ul`
    color: white;
`;

const TeaserListItem = styled.li`
    color: white;
    margin-right: 16px;
`;

const Button = styled(StyledButton)`
    margin: 0 auto 15px auto;
    padding: 8px 25px;
    min-width: 148px;
`;

const NightSymbol = styled.img`
    width: 16px;
    margin: -2px 5px 0 0;
`;

const ContentContainer = styled(Content)`
    height: calc(100vh - 165px);
    overflow: auto;
`;
