import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import styled from 'styled-components';
import ApiSubdomain from '../../../api/api-subdomain';
import { DomainUserDTO, Permission } from '../../../api/model';
import SoarHelper from '../../../lib/soar-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../Shared/soar-modal';
import { StyledButton } from '../../Shared/styled-button';
import { SubmittingSpinner } from '../../Shared/submitting-spinner';

interface InviteUserModalProps {
    onClose: () => void;
    refreshUsers: () => void;
    userData: DomainUserDTO[];
}

export const permissionDescription = (permission: Permission) => {
    switch (permission) {
        case Permission.Admin:
            return 'Admins can add and remove users.  They can also upload and view imagery';
        case Permission.Read:
            return 'View users can only view imagery';
        case Permission.Review:
            return 'Write users can upload and view imagery';
        default:
            return '';
    }
};

const InviteUserModal = (props: InviteUserModalProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [permission, setPermission] = useState<Permission>(Permission.Read);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const emailAlreadyAdded = useCallback(
        (email) => {
            return !!props.userData.filter((user) => user.email == email).length;
        },
        [props.userData]
    );

    const emailIsValid = () => {
        return email && UserHelper.isEmailValid(email) && !emailAlreadyAdded(email);
    };

    const checkEmail = () => {
        if (email.length > 0 && !UserHelper.isEmailValid(email)) {
            setError('Email is not valid');
        } else if (emailAlreadyAdded(email)) {
            setError('Email already added');
        } else {
            setError(undefined);
        }
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        const user: DomainUserDTO = {
            email: email,
            permissions: permission,
        };

        ApiSubdomain.addUser(user)
            .then((result) => {
                Analytics.Event('My Dashboard', 'Clicked to invite user to subdomain');
                if (result.userId) {
                    toast.dark(`An invite has been sent to ${email}`);
                    setIsSubmitting(false);
                    setEmail('');
                } else if (result.invitationId) {
                    toast.dark(`An invite has been sent to ${email}`);
                    setIsSubmitting(false);
                    setEmail('');
                }
                props.refreshUsers();
                props.onClose();
            })
            .catch((err) => {
                setIsSubmitting(false);
                const existingUserError = err.toString().search('already exists');
                if (existingUserError !== 0) {
                    setError('This user already exists');
                } else {
                    setError('An error occurred adding this user: ' + err);
                }
            });
    };

    return (
        <SoarModal title="Invite User" isOpen={true} toggle={props.onClose} width="400px">
            <StyledModalBody>
                <AdminUserInput>
                    <AdminInput
                        value={email}
                        type="text"
                        placeholder="Enter users email address..."
                        onChange={(e) => {
                            setEmail(e.currentTarget.value);
                            setError(undefined);
                        }}
                        onBlur={() => checkEmail()}
                    />
                    <AddUserDropdown isOpen={dropdownOpen} toggle={toggle}>
                        <AddUserDropdownToggle caret>
                            {SoarHelper.permissionsEnumToUIText(permission)}
                        </AddUserDropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    setPermission(Permission.Read);
                                }}
                            >
                                {SoarHelper.permissionsEnumToUIText(Permission.Read)}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => {
                                    setPermission(Permission.Review);
                                }}
                            >
                                {SoarHelper.permissionsEnumToUIText(Permission.Review)}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => {
                                    setPermission(Permission.Admin);
                                }}
                            >
                                {SoarHelper.permissionsEnumToUIText(Permission.Admin)}
                            </DropdownItem>
                        </DropdownMenu>
                    </AddUserDropdown>
                </AdminUserInput>
                {error && <AdminAddUserError>{error}</AdminAddUserError>}
                <AdminAddUserPermissionType>({permissionDescription(permission)})</AdminAddUserPermissionType>
            </StyledModalBody>
            <StyledModalFooter>
                {isSubmitting ? (
                    <SubmittingSpinner />
                ) : (
                    <StyledButton onClick={handleSubmit} disabled={!!error || !emailIsValid()}>
                        INVITE USER
                    </StyledButton>
                )}
            </StyledModalFooter>
        </SoarModal>
    );
};

export default InviteUserModal;

const AdminUserInput = styled.div`
    display: flex;
    flex-direction: row;
`;

const AddUserDropdown = styled(Dropdown)`
    padding-left: 5px !important;
`;

const AddUserDropdownToggle = styled(DropdownToggle)`
    background-color: #eed926 !important;
    color: black !important;
    -webkit-text-fill-color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    border-radius: 4px;
    padding: 5px 15px;
    text-transform: uppercase;
`;

const AdminAddUserPermissionType = styled.div`
    color: white;
    margin: 10px auto;
    width: fit-content;
    text-align: center;
`;

const AdminAddUserError = styled.div`
    color: red;
    margin: auto;
    width: fit-content;
    text-align: center;
`;

const AdminInput = styled(Input)`
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid rgb(255 255 255 / 0.3) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :focus {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;
