import L from 'leaflet';
import { CoordinateSystem, handleIcon } from './coordinate-util';
import { defaultZIndex } from '../layers-util';

export default class Coordinate {
    annotationType: string;
    id: string;
    position: L.LatLng;
    options: L.MarkerOptions;
    zIndex: number;
    coordinateSystem: CoordinateSystem;

    constructor(
        id: string,
        position: L.LatLng,
        zIndex: number,
        options: L.MarkerOptions,
        coordinateSystem: CoordinateSystem
    ) {
        this.id = id;
        this.position = position;
        this.zIndex = zIndex;
        this.options = options;
        this.annotationType = 'Coordinate';
        this.coordinateSystem = coordinateSystem;
    }

    static serialize(coordinate: Coordinate): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [coordinate.position.lng, coordinate.position.lat],
            },
            properties: {
                annotationType: 'Coordinate',
                id: coordinate.id,
                zIndex: coordinate.zIndex || defaultZIndex,
                coordinateSystem: coordinate.coordinateSystem,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): Coordinate {
        const position = new L.LatLng(json.geometry.coordinates[1], json.geometry.coordinates[0]);
        const coordinateSystem = json.properties.coordinateSystem || 'WGS84 DD';
        const icon = handleIcon(json.properties.id, position, coordinateSystem);
        const zIndex = json.properties.zIndex || defaultZIndex;
        return new Coordinate(json.properties.id, position, zIndex, { icon }, coordinateSystem);
    }
}

export const defaultCoordinateOptions: L.MarkerOptions = {
    interactive: true,
};
