import { LatLng, LatLngBounds } from 'leaflet';
import { useDispatch } from 'react-redux';
import { actionFlyToOnMap } from '../store/App/actions';
import { usePositionMarker } from './use-position-marker';

export const useFlyTo = () => {
    const dispatch = useDispatch();
    const positionMarker = usePositionMarker();

    const flyTo = (position: LatLng | LatLngBounds | undefined, withMarker?: boolean) => {
        if (position) {
            const flyTo = (position: LatLng | LatLngBounds, withMarker?: boolean) => {
                dispatch(actionFlyToOnMap(position));

                if (withMarker) {
                    positionMarker(position);
                }
            };
            flyTo(position, withMarker);
        }
    };

    return flyTo;
};
