import Api from './api';
import { CreatedShortUrlDTO } from './model';

export default class ApiShort extends Api {
    public static minify(
        url: string,
        type: string,
        address?: string,
        previewUrl?: string
    ): Promise<CreatedShortUrlDTO> {
        const data: PostDateMinify = {
            url: url,
            type: type,
        };
        if (address) data.address = address;
        if (previewUrl) data.previewUrl = previewUrl;
        return this.axios.post('/short', data).then((res) => {
            return res.data;
        });
    }
}

interface PostDateMinify {
    url: string;
    type: string;
    address?: string;
    previewUrl?: string;
}
