import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import ApiSubdomain from '../../../api/api-subdomain';
import { DomainDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import SoarModal from '../../Shared/soar-modal';

interface CancelAccountProps {
    domain: DomainDTO;
    invalidate: () => void;
}

const AccountActions: React.FC<CancelAccountProps> = (props) => {
    const { domain } = props;
    const subscription = domain?.subscription;
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const toggleShowCancelConfirmation = () => {
        if (!submitting) {
            setShowCancelConfirmation((prevState) => !prevState);
        }
    };

    const handleCancelAccount = () => {
        setSubmitting(true);
        ApiSubdomain.cancelSubscription()
            .then(() => {
                toast.dark('Your subscription has been cancelled');
                Analytics.Event('My Dashboard', 'Clicked cancel subscription');
                props.invalidate();
                setShowCancelConfirmation(false);
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <React.Fragment>
            {subscription && !subscription.cancelAt && subscription.nextPaymentAt && (
                <CancelAccountContainer>
                    <h4>Account Actions</h4>
                    <ButtonsContainer>
                        <CancelAccountButton className="btn-danger" onClick={toggleShowCancelConfirmation}>
                            CANCEL ACCOUNT
                        </CancelAccountButton>
                    </ButtonsContainer>
                    <SoarModal
                        isOpen={showCancelConfirmation}
                        title="Close your account"
                        toggle={toggleShowCancelConfirmation}
                    >
                        <CancelAccountModalBody>
                            Sorry to see you go. By confirming you are cancelling your account you are saying yes to
                            losing all your data, comments, annotations, imagery orders as well as user sharing
                            capabilities. You will also lose your Soar+ domain name and it will be available for others
                            to use. Please click on 'Confirm' to cancel and delete your Soar+ account.
                        </CancelAccountModalBody>
                        <CancelAccountModalFooter>
                            <CancelAccountButton
                                className="btn-danger"
                                disabled={submitting}
                                onClick={handleCancelAccount}
                            >
                                CONFIRM &amp; CANCEL
                            </CancelAccountButton>
                        </CancelAccountModalFooter>
                    </SoarModal>
                </CancelAccountContainer>
            )}
        </React.Fragment>
    );
};

export default AccountActions;

const CancelAccountContainer = styled.div`
    background-color: rgb(0, 0, 0, 0.6);
    margin-top: 20px;
    padding: 15px;
    border-radius: 6px;

    h4 {
        color: white;
    }
`;

const CancelAccountModalBody = styled(ModalBody)`
    color: rgba(255, 255, 255, 0.87);
`;

const CancelAccountModalFooter = styled(ModalFooter)`
    border-top: 1px solid rgb(255 255 255 / 0.3);
    padding: 0.5rem 1rem;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const CancelAccountButton = styled(Button)`
    font-size: 16px !important;
    margin: 10px;
    padding: 5px 10px !important;
    max-width: 170px;
`;
