import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Mousewheel, Keyboard } from 'swiper/modules';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';

import 'swiper/css';
import 'swiper/css/navigation';
import Analytics from '../../lib/user-analytics';

const LandingPageCarousel = () => {
    const carouselCardData = [
        {
            title: 'Climate',
            imageSrc: '/assets/landing-page/slider-images/climate.png',
            text: "The Earth’s climate is at a constant state of change. Find the world's most comprehensive updated climate maps from the likes of NASA, the EU and NGOs.",
            category: 'climate',
        },
        {
            title: 'Historical',
            imageSrc: '/assets/landing-page/slider-images/history.png',
            text: 'See how history is an ever continuing story with maps including ancient human migration categories, the distribution of native peoples through to archived aerial images.',
            category: 'history',
        },
        {
            title: 'Earth Art',
            imageSrc: '/assets/landing-page/slider-images/earth-art.png',
            text: 'Discover the rich tapestry of colors, patterns and shapes that is planet Earth with maps as art from thousands of users across the world.',
            category: 'earth-art',
        },
        {
            title: 'Transport',
            imageSrc: '/assets/landing-page/slider-images/transport.png',
            text: "The efficiency of the global economy is built on transport systems. Identify, visualise and study maps and satellite images showing the impact of the world's integrated transport systems.",
            category: 'transport',
        },
        {
            title: 'Environment',
            imageSrc: '/assets/landing-page/slider-images/environment.png',
            text: 'Maps are the best way to visualise landscape change. There are thousands of maps and satellite images from users globally showing how the Earth’s environments have changed both in the past, present and perhaps in the future.',
            category: 'environment',
        },
        {
            title: 'Urban',
            imageSrc: '/assets/landing-page/slider-images/urban.png',
            text: "Almost 60% of the world's population lives in urban areas. Find out how maps are being used by many of the world's governments and organisations in-order to efficiently study and manage urbanisation.",
            category: 'urban',
        },
        {
            title: 'Agriculture',
            imageSrc: '/assets/landing-page/slider-images/agriculture.png',
            text: 'No other industry has impacted as much of the Earth’s surface as agriculture has. Study and analyse the thousands of satellite images and maps demonstrating crop yields, through to seasonal variability in farmland output.',
            category: 'agriculture',
        },
        {
            title: 'Geology',
            imageSrc: '/assets/landing-page/slider-images/geology.png',
            text: "Observe and identify the impact of geology in various global contexts by accessing content from the world's best institutions. View and share tens of thousands of maps ranging from elevation, terrain and even the location of earthquake prone areas.",
            category: 'geology',
        },
        {
            title: 'Political',
            imageSrc: '/assets/landing-page/slider-images/political.png',
            text: ' To understand politics, one needs to understand maps. Access tens of thousands of maps and satellite images from across the world documenting everything from current conflict zones through to ethno-linguistic distribution of peoples.',
            category: 'political',
        },
        {
            title: 'Economic',
            imageSrc: '/assets/landing-page/slider-images/economic.png',
            text: 'Discover thousands of economic maps from libraries such as Stanford University, the EU, USGS and various organisations. These maps show everything from the building of new cities, through to how key mines are being built to extract rare minerals.',
            category: 'economic',
        },
    ];

    const handleClickedCategory = (category: string) => {
        UriHelper.navigateToPath(`/maps`);
        Analytics.Event('Landing page', 'Clicked Category', category);
    };

    return (
        <SliderContainer>
            <Swiper
                modules={[Navigation, Autoplay, Mousewheel, Keyboard]}
                slidesPerView={4}
                autoplay={true}
                loop={true}
                navigation={{
                    nextEl: '.carousel-next',
                    prevEl: '.carousel-prev',
                }}
            >
                <PreviousArrow className="carousel-prev" />
                {carouselCardData.map((carouselCard, index) => (
                    <SwiperSlide key={index}>
                        <SliderCard onClick={() => handleClickedCategory(carouselCard.category)} key={index}>
                            <SliderImageBackground src={carouselCard.imageSrc} />
                            <SliderImageText>
                                <SliderImageHeader>{carouselCard.title}</SliderImageHeader>
                                {carouselCard.text}
                            </SliderImageText>
                        </SliderCard>
                    </SwiperSlide>
                ))}
                <NextArrow className="carousel-next" />
            </Swiper>
        </SliderContainer>
    );
};

export default LandingPageCarousel;

const SliderContainer = styled.div`
    margin: 50px 0px;
    height: 220px;

    @media only screen and (max-width: 600px) {
        width: 90%;
        margin: 20px auto;
    }
`;

const SliderCard = styled.div`
    width: 100%;
    height: 204px;
    position: relative;
    padding: 3px;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(34, 34, 34, 0.5);
    }

    &:hover {
        transform: scale(1.08);
        z-index: 10;
    }
`;

const SliderImageBackground = styled.img`
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 100%;
`;

const SliderImageText = styled.div`
    position: absolute;
    vertical-align: middle;
    top: 0;
    font-size: 14px;
    color: white;
    z-index: 1;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.8);
    padding: 20px;
    word-break: break-word;
`;

const SliderImageHeader = styled.h3`
    font-size: 20px;
    color: white;
    font-weight: 600;
    text-align: left;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.8);
    z-index: 1;
`;

const SliderArrow = styled.button`
    position: absolute;
    border: none;
    cursor: pointer;
    padding-bottom: 27px;
    outline: none;
    z-index: 1;
    height: 100%;
    font-size: 65px;
    font-weight: 500;
    background: none;
    text-shadow: 2px 2px rgba(66, 66, 66, 0.8);
    width: 40px;
    color: white;
    top: 0;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus,
    &:active {
        outline: none;
        border: none;
    }
`;

const NextArrow = styled(SliderArrow)`
    right: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.062), rgba(0, 0, 0, 0.7));
    @media only screen and (max-width: 600px) {
        right: 10px;
    }

    &:after {
        content: '›';
    }
`;

const PreviousArrow = styled(SliderArrow)`
    left: 1px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.062), rgba(0, 0, 0, 0.7));

    @media only screen and (max-width: 600px) {
        left: 10px;
    }

    &:after {
        content: '‹';
    }
`;
