import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import FillColor from '../ToolBar/fill-color';
import ColorSelector from '../ToolBar/color-selector';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';
import TextFont from './text-font';
import TextBox, { TextBoxFontType } from './textbox';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { selectTextBoxes } from '../../../../store/Annotations/TextBox/selectors';
import {
    setTextBoxDeleteAction,
    setTextBoxOptionsAction,
    setTextBoxUpdateAction,
} from '../../../../store/Annotations/TextBox/actions';

const TextboxToolbar = () => {
    const dispatch = useDispatch();
    const textBoxes = useSelector(selectTextBoxes);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedTextBox = textBoxes.find((c) => c.id === selectedAnnotation?.id);

    const updateTextBox = (textBox: TextBox) => {
        dispatch(setTextBoxUpdateAction(textBox));
    };

    if (!selectedTextBox) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Font Color:</ToolbarText>

            <ColorSelector
                title="Font Color"
                selectedColor={selectedTextBox.textColor || 'black'}
                onSelectColor={(color) => {
                    updateTextBox({
                        ...selectedTextBox,
                        textColor: color,
                    });
                    dispatch(
                        setTextBoxOptionsAction({
                            ...selectedTextBox,
                            textColor: color,
                        })
                    );
                }}
            />
            <ToolbarDivider />

            <ToolbarText>Fill Color:</ToolbarText>
            <FillColor
                selectedColor={selectedTextBox.fillColor || 'black'}
                onSelectColor={(color) => {
                    updateTextBox({
                        ...selectedTextBox,
                        fillColor: color,
                    });
                    dispatch(
                        setTextBoxOptionsAction({
                            ...selectedTextBox,
                            fillColor: color,
                        })
                    );
                }}
                opacity={selectedTextBox.fillOpacity || 1.0}
                onSetOpacity={(opacity) => {
                    updateTextBox({
                        ...selectedTextBox,
                        fillOpacity: opacity,
                    });
                    dispatch(
                        setTextBoxOptionsAction({
                            ...selectedTextBox,
                            fillOpacity: opacity,
                        })
                    );
                }}
            />

            <ToolbarDivider />

            <ToolbarText>Font:</ToolbarText>
            <TextFont
                textBox={selectedTextBox}
                onChangeFont={(font: TextBoxFontType) => {
                    updateTextBox({
                        ...selectedTextBox,
                        fontFamily: font,
                    });
                    dispatch(
                        setTextBoxOptionsAction({
                            ...selectedTextBox,
                            fontFamily: font,
                        })
                    );
                }}
            />
            <ToolbarDivider />

            <ToolbarText>Arrange:</ToolbarText>
            <LayerOrderControl annotation={selectedTextBox} />

            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    DrawToolAnalytics.delete('textarea');
                    dispatch(setTextBoxDeleteAction(selectedTextBox));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default TextboxToolbar;
