import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actionFetchBookmarkedMaps } from '../../../../store/Map/BookMarks/actions';
import { selectBookmarkedMaps, selectBookmarkedMapsIsLoading } from '../../../../store/Map/BookMarks/selectors';
import SideDrawerInfiniteScroll from '../Shared/side-drawer-infinite-scroll';
import { AnalyticsNote, ListingDTO } from '../../../../api/model';
import SideDrawerSearch from '../Shared/side-drawer-search';

const SideDrawerBookMark = () => {
    const [bookmarkedListings, setBookmarkedListings] = useState<ListingDTO[] | undefined>(undefined);

    const dispatch = useDispatch();

    const listings = useSelector(selectBookmarkedMaps);
    const isLoading = useSelector(selectBookmarkedMapsIsLoading);

    const handleFetchBookmarks = useCallback(() => {
        dispatch(actionFetchBookmarkedMaps());
    }, [dispatch]);

    useEffect(() => {
        if (!listings?.length) {
            handleFetchBookmarks();
        }
    }, [handleFetchBookmarks, listings?.length]);

    useEffect(() => {
        if (listings?.length && !bookmarkedListings) {
            setBookmarkedListings(listings);
        }

        return () => {
            setBookmarkedListings(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BookMarkDrawer>
            <BookmarkMapsTitle>Bookmarks</BookmarkMapsTitle>
            <SideDrawerSearch listings={listings || []} setListings={setBookmarkedListings} />
            <SideDrawerInfiniteScrollContainer>
                <SideDrawerInfiniteScroll
                    type="Bookmarks"
                    listings={bookmarkedListings || []}
                    exitingListings={new Set()}
                    listingsCount={bookmarkedListings?.length || 0}
                    isLoading={isLoading}
                    onLoadMore={() => ''}
                    heightPadding="175px"
                    analyticNote={AnalyticsNote.BOOKMARKS}
                />
            </SideDrawerInfiniteScrollContainer>
        </BookMarkDrawer>
    );
};

export default SideDrawerBookMark;

const BookMarkDrawer = styled.div`
    margin-top: 15px;
`;

const BookmarkMapsTitle = styled.h1`
    text-align: center;
    font-size: 22px;
    color: white;
`;

const SideDrawerInfiniteScrollContainer = styled.div`
    margin: 0px 0px 0px 7px;
`;
