import { LatLng, LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../../lib/user-analytics';
import { actionSetMapZoom } from '../../store/App/actions';
import { selectFlyToMapPosition } from '../../store/App/selectors';
import { selectBeingMobileSentinelSearch, selectIsMobileInteractionEnabled } from '../../store/Map/Mobile/selectors';
import { actionSentinelMapZoom, actionSentinelSelectAOI } from '../../store/Map/Sentinel/actions';

// Level to zoom to when focusing on a point
const FLYTO_ZOOM_LEVEL = 11;

// Force the map back to these bounds while panning
const WORLD_DRAG_REBOUND_BOUNDS: [number, number][] = [
    [-80.98155760646617, -220],
    [89.99346179538875, 240],
];

const MobileMapDispatcher = () => {
    const isMobileMapInteractionEnabled = useSelector(selectIsMobileInteractionEnabled);
    const beginMobileSentinelSearch = useSelector(selectBeingMobileSentinelSearch);
    const mapPosition = useSelector(selectFlyToMapPosition);

    const map = useMap();
    const dispatch = useDispatch();

    const setMapZoomLevel = useCallback(
        (zoom: number) => {
            dispatch(actionSetMapZoom(zoom));
            dispatch(actionSentinelMapZoom(zoom));
        },
        [dispatch]
    );

    const flyTo = useCallback(
        (position: LatLng | LatLngBounds) => {
            if (position instanceof LatLng) {
                const params = new URLSearchParams(window.location.search);
                const zoom = parseInt(params.get('pos')?.split(',')[2] || '');
                if (zoom && !Number.isNaN(zoom)) {
                    map.flyTo(position, zoom, { animate: false });
                } else {
                    map.flyTo(position, FLYTO_ZOOM_LEVEL, { animate: false });
                }
            } else if (position instanceof LatLngBounds) {
                map.flyToBounds(position, { animate: false });
            }
        },
        [map]
    );

    useEffect(() => {
        if (mapPosition) {
            flyTo(mapPosition);
        }
    }, [flyTo, mapPosition]);

    useEffect(() => {
        const setSentinelAOI = (bbox: LatLngBounds) => dispatch(actionSentinelSelectAOI(bbox));

        if (beginMobileSentinelSearch && map) {
            setSentinelAOI(map.getBounds());
            Analytics.Event('Mobile Map', 'Created sentinel AOI', map.getBounds());
        }
    }, [beginMobileSentinelSearch, dispatch, map]);

    useEffect(() => {
        if (!map) return;

        // Prevent the map from wrapping by forcing in to pan inside world bounds
        map.on('drag', () => {
            map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
        });

        map.on('zoomend', () => {
            map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            setMapZoomLevel(map.getZoom());
        });

        setMapZoomLevel(map.getZoom());
    }, [map, dispatch, setMapZoomLevel]);

    useEffect(() => {
        if (!map) return;

        if (isMobileMapInteractionEnabled) {
            map.dragging.enable();
            map.touchZoom.enable();
            map.doubleClickZoom.enabled();
            map.scrollWheelZoom.enabled();
            map.boxZoom.enabled();
            map.keyboard.enabled();
        } else {
            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
        }
    }, [isMobileMapInteractionEnabled, map]);

    return <React.Fragment />;
};

export default MobileMapDispatcher;
