/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Annotation } from '../../../store/Annotations/reducer';
import { selectAllAnnotations, selectSelectedAnnotation } from '../../../store/Annotations/selectors';

import Arrow from './Arrow/arrow';
import Circle from './Circle/circle';
import Coordinate from './Coordinate/coordinate';
import FreehandPolyline from './FreehandPolyline/freehand-polyline';
import Image from './ImageTool/image';
import Marker from './Marker/marker';
import Polyline from './Polyline/polyline';
import PolygonPath from './Polygon/polygon';
import Rectangle from './Rectangle/rectangle';

import { setSelectedAnnotationAction } from '../../../store/Annotations/actions';

import { selectArrows } from '../../../store/Annotations/Arrow/selectors';
import { setArrowsAction } from '../../../store/Annotations/Arrow/actions';
import { setMarkersAction } from '../../../store/Annotations/Marker/actions';
import { selectMarkers } from '../../../store/Annotations/Marker/selectors';
import { selectCircles } from '../../../store/Annotations/Circle/selectors';
import { setCirclesAction } from '../../../store/Annotations/Circle/actions';
import { setPolylinesAction } from '../../../store/Annotations/Path/actions';
import { selectPolylines } from '../../../store/Annotations/Path/selectors';
import { setRectanglesAction } from '../../../store/Annotations/Rectangle/actions';
import { selectRectangles } from '../../../store/Annotations/Rectangle/selectors';
import { setCoordinatesAction } from '../../../store/Annotations/Coordinate/actions';
import { selectCoordinates } from '../../../store/Annotations/Coordinate/selectors';
import { setFreehandDrawsAction } from '../../../store/Annotations/Freehand/actions';
import { selectFreehandDraws } from '../../../store/Annotations/Freehand/selectors';
import { setPolygonsAction } from '../../../store/Annotations/Polygon/actions';
import { selectPolygons } from '../../../store/Annotations/Polygon/selectors';
import { setImagesAction } from '../../../store/Annotations/Images/actions';
import { selectImages } from '../../../store/Annotations/Images/selectors';

export const useDeleteKeyToRemoveAnnotation = () => {
    const dispatch = useDispatch();
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const allAnnotations = useSelector(selectAllAnnotations);

    const setMarkers = (markers: Marker[]) => dispatch(setMarkersAction(markers));
    const setPolylines = (polylines: Polyline[]) => dispatch(setPolylinesAction(polylines));
    const setPolygons = (polygons: PolygonPath[]) => dispatch(setPolygonsAction(polygons));
    const setCircles = (circles: Circle[]) => dispatch(setCirclesAction(circles));
    const setRectangles = (rectangles: Rectangle[]) => dispatch(setRectanglesAction(rectangles));
    const setFreehandDraws = (freehandDraws: FreehandPolyline[]) => dispatch(setFreehandDrawsAction(freehandDraws));
    const setArrows = (arrows: Arrow[]) => dispatch(setArrowsAction(arrows));
    const setImages = (images: Image[]) => dispatch(setImagesAction(images));
    const setCoordinates = (coordinates: Coordinate[]) => dispatch(setCoordinatesAction(coordinates));
    const setActiveAnnotation = (annotation: Annotation) => dispatch(setSelectedAnnotationAction(annotation));

    const markers = useSelector(selectMarkers);
    const polylines = useSelector(selectPolylines);
    const polygons = useSelector(selectPolygons);
    const circles = useSelector(selectCircles);
    const rectangles = useSelector(selectRectangles);
    const freehandDraws = useSelector(selectFreehandDraws);
    const arrows = useSelector(selectArrows);
    const images = useSelector(selectImages);
    const coordinates = useSelector(selectCoordinates);

    useEffect(() => {
        if (!selectedAnnotation) return;

        const onDeleteKeyPressed = (e: KeyboardEvent) => {
            if (selectedAnnotation && (e.key === 'Backspace' || e.key === 'Delete')) {
                const deletedAnnotation = allAnnotations.find((c) => c && c.id === selectedAnnotation.id);
                if (deletedAnnotation) {
                    switch (deletedAnnotation.annotationType) {
                        case 'Polygon':
                            const updatedPolygonIndex = polygons.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedPolygons = [...polygons];
                            updatedPolygons.splice(updatedPolygonIndex, 1);
                            setPolygons(updatedPolygons);
                            setActiveAnnotation(undefined);
                            break;

                        case 'Polyline':
                            const updatedPolylineIndex = polylines.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedPolylines = [...polylines];
                            updatedPolylines.splice(updatedPolylineIndex, 1);
                            setPolylines(updatedPolylines);
                            setActiveAnnotation(undefined);
                            break;

                        case 'Arrow':
                            const updatedArrowIndex = arrows.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedArrows = [...arrows];
                            updatedArrows.splice(updatedArrowIndex, 1);
                            setArrows(updatedArrows);
                            setActiveAnnotation(undefined);
                            break;

                        case 'Circle':
                            const updatedCircleIndex = circles.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedCircles = [...circles];
                            updatedCircles.splice(updatedCircleIndex, 1);
                            setCircles(updatedCircles);
                            setActiveAnnotation(undefined);
                            break;

                        case 'Rectangle':
                            const updatedRectangleIndex = rectangles.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedRectangles = [...rectangles];
                            updatedRectangles.splice(updatedRectangleIndex, 1);
                            setRectangles(updatedRectangles);
                            setActiveAnnotation(undefined);
                            break;

                        case 'Textbox':
                            // Do nothing for a textbox since delete is used to update text.
                            break;

                        case 'FreehandPolyline':
                            const updatedFreehandDrawIndex = freehandDraws.findIndex(
                                (c) => c.id === selectedAnnotation.id
                            );
                            const updatedFreehandDraws = [...freehandDraws];
                            updatedFreehandDraws.splice(updatedFreehandDrawIndex, 1);
                            setFreehandDraws(updatedFreehandDraws);
                            setActiveAnnotation(undefined);
                            break;

                        case 'Image':
                            const updatedImageIndex = images.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedImages = [...images];
                            updatedImages.splice(updatedImageIndex, 1);
                            setImages(updatedImages);
                            setActiveAnnotation(undefined);
                            break;

                        case 'Marker':
                            const updatedMarkerIndex = markers.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedMarkers = [...markers];
                            updatedMarkers.splice(updatedMarkerIndex, 1);
                            setMarkers(updatedMarkers);
                            setActiveAnnotation(undefined);
                            break;

                        case 'MilitaryMarker':
                            // Do nothing, has an input and when deleting this triggers a close...
                            // const updatedMilitaryMarkerIndex = markers.findIndex((c) => c.id === selectedAnnotation.id);
                            // const updatedMilitaryMarkers = [...militaryMarkers];
                            // updatedMilitaryMarkers.splice(updatedMilitaryMarkerIndex, 1);
                            // setMilitaryMarkers(updatedMilitaryMarkers);
                            // setActiveAnnotation(undefined);
                            break;

                        case 'Coordinate':
                            const updatedCoordinateIndex = coordinates.findIndex((c) => c.id === selectedAnnotation.id);
                            const updatedCoordinates = [...coordinates];
                            updatedCoordinates.splice(updatedCoordinateIndex, 1);
                            setCoordinates(updatedCoordinates);
                            setActiveAnnotation(undefined);
                            break;

                        default:
                            console.log('Unknown annotation type: ', deletedAnnotation.annotationType);
                            break;
                    }
                }
            }
        };

        if (selectedAnnotation) {
            document.addEventListener('keydown', onDeleteKeyPressed);
        } else {
            document.removeEventListener('keydown', onDeleteKeyPressed);
        }

        return () => {
            document.removeEventListener('keydown', onDeleteKeyPressed);
        };
    }, [selectedAnnotation]);
};
