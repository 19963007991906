import React from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

interface NavigationButtonProps {
    label: string;
    onClick?: (e) => void;
    isSubmitting?: boolean;
    setIsSubmitting?: (submit: boolean) => void;
    active?: boolean;
}

const NavigationButton = (props: NavigationButtonProps) => {
    const { isSubmitting, setIsSubmitting, label, onClick, active } = props;
    return (
        <React.Fragment>
            <ProfileNavigationStyledButton
                active={active}
                onClick={(e) => {
                    onClick && onClick(e);
                    setIsSubmitting && setIsSubmitting(true);
                }}
            >
                {isSubmitting ? <Spinner style={{ color: 'light', height: '20px', width: '20px' }} /> : label}
            </ProfileNavigationStyledButton>
        </React.Fragment>
    );
};

export default NavigationButton;

interface StyledProps {
    active?: boolean;
}

const ProfileNavigationStyledButton = styled.button<StyledProps>`
    margin: 12px 10px;
    padding: 0px 10px;
    font-size: 1rem;
    background-color: ${(props) => (props.active ? '#EED926;' : 'transparent')};
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: ${(props) => (props.active ? 'black' : 'white')};
    text-transform: uppercase;

    &:hover {
        background-color: #eed926 !important;
        color: black;
        opacity: 0.6;
    }

    &:focus {
        background-color: #eed926 !important;
        outline: none !important;
        color: black;
    }

    @media only screen and (max-width: 600px) {
        margin: 8px 5px;
        padding: 5px 10px;
        font-size: 18px;
    }
`;
