import React, { useEffect, useState } from 'react';
import { UserDTO } from '../../../api/model';
import EditProfileAvatar from './edit-profile-avatar';
import styled from 'styled-components';
import profileIconUrl from '../profile-icon-url';
import FileUtil from '../../../lib/file-util';
import SoarModal, { StyledModalBody } from '../../Shared/soar-modal';
import UserAvatar from '../../Shared/Avatar/user-avatar';

const moment = require('moment');
interface ProfileEditAvatarProps {
    myProfile: UserDTO;
    avatarFile: File | string | undefined;
    setAvatarFile: (avatarFile: File | undefined) => void;
    setHasChangedAvatar: (changed: boolean) => void;
}

export const ProfileEditAvatar = (props: ProfileEditAvatarProps) => {
    const [openEditor, setOpenEditor] = useState<boolean>(false);
    const [avatarPreview, setAvatarPreview] = useState<string>('');
    const toggle = () => setOpenEditor(!openEditor);

    useEffect(() => {
        if (props.avatarFile) {
            FileUtil.fileToBase64Image(props.avatarFile as File).then((base64Image) => {
                setAvatarPreview(base64Image);
            });
        }
    }, [props.avatarFile]);

    return (
        <React.Fragment>
            <AvatarEditContainer>
                {props.avatarFile ? (
                    <AvatarProfilePage src={avatarPreview} alt="profile-image" />
                ) : (
                    <UserAvatar user={props.myProfile} margin="0" diameter={150} />
                )}

                <AvatarEditButtonWrapper>
                    <AvatarEditButton
                        onClick={toggle}
                        src={profileIconUrl.cameraUrl}
                        data-testid="dashboard-profile-image-edit"
                    />
                </AvatarEditButtonWrapper>
                {props.myProfile.createdAt && (
                    <ProfileCreated>
                        Member Since {moment((props.myProfile.createdAt || 0) * 1000).format('D MMM YYYY')}
                    </ProfileCreated>
                )}
            </AvatarEditContainer>

            <SoarModal title="Edit your profile picture" isOpen={openEditor} toggle={toggle} width="360px">
                <StyledModalBody>
                    <EditProfileAvatar
                        myProfile={props.myProfile}
                        onAvatarUnchanged={toggle}
                        onAvatarUpdated={(file) => {
                            props.setAvatarFile(file);
                        }}
                        setHasChangedAvatar={props.setHasChangedAvatar}
                    />
                </StyledModalBody>
            </SoarModal>
        </React.Fragment>
    );
};

const AvatarEditContainer = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.3);
    // -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
    padding: 10px 6px 6px 6px;
    position: relative;
    align-items: center;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const AvatarProfilePage = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 120px;
    border: 1px solid #eed926;
    align-self: center;

    @media only screen and (max-width: 600px) {
        margin: 10px auto;
    }
`;

const AvatarEditButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 120px;
    right: 20px;

    @media only screen and (max-width: 600px) {
        margin: -3rem -7.8rem 0 0;
        position: relative;
    }
`;

const AvatarEditButton = styled.img`
    width: 30px;
    height: 30px;
    background-color: #eed926 !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: black;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    @media only screen and (max-width: 600px) {
        margin: 0;
    }
`;

const ProfileCreated = styled.div`
    font-size: 14px;
    color: #fff;
    text-align: center;

    @media only screen and (max-width: 600px) {
        padding: 5px;
        margin: 0 auto;
    }
`;
