import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ApiUser from '../../../api/api-user';
import { UserDTO } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../../store/Map/SuperMap/selectors';
import ProfileHeader from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-header';
import { PulseLoader } from '../../Shared/pulse-loader';
import MobileSearchResults from '../Search/Results/mobile-search-results';
import MobileMapContainer from '../mobile-map-container';

type RouteParams = {
    wallet?: string;
};

const MobileProfileExplorePage = () => {
    const dispatch = useDispatch();

    const { wallet } = useParams<RouteParams>();
    const [userProfileError, setUserProfileError] = useState<string | undefined>(undefined);
    const [userProfile, setUserProfile] = useState<UserDTO | undefined>(undefined);

    useEffect(() => {
        if (!wallet) {
            setUserProfileError('Wrong pathname');
        } else {
            const fetchProfile = async () => {
                try {
                    const res = await ApiUser.getUserProfile(wallet);
                    setUserProfile(res);
                } catch (err) {
                    setUserProfileError('User not found');
                }
            };
            fetchProfile();
        }
    }, [wallet, dispatch]);

    useEffect(() => {
        if (userProfile) {
            Analytics.Event('Profile', `VP: ${userProfile.userId}`);
            Analytics.Event('Profile', `Viewing user`, userProfile.name);
        }
        return () => {
            if (userProfile) {
                Analytics.Event('Profile', 'Leaving viewing User', userProfile.name);
            }
        };
    }, [userProfile]);

    const activeMap = useSelector(selectActiveAllMap);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);

    useEffect(() => {
        if (activeMap === undefined) {
            UriHelper.replacePath(`/profile/${wallet}`);
        }
    }, [activeMap, wallet]);

    const onCloseClick = () => {
        UriHelper.navigateToPath('/maps');
    };

    //todo display error and add link to go back to maps
    if (!userProfile) {
        if (userProfileError) {
            return (
                <MobileActiveMapLoading>
                    <div>{userProfileError}</div>
                </MobileActiveMapLoading>
            );
        } else {
            return (
                <MobileExploreContainer>
                    <MobileActiveMapLoading>
                        <PulseLoader />
                    </MobileActiveMapLoading>
                </MobileExploreContainer>
            );
        }
    }

    return (
        <MobileExploreContainer id="profile-explore" data-testid="mobile-profile-explore">
            {activeMapIsFetching ? (
                <MobileActiveMapLoading>
                    <PulseLoader />
                </MobileActiveMapLoading>
            ) : null}
            {activeMap ? (
                <MobileGridMapViewer>
                    <MobileMapContainer />
                </MobileGridMapViewer>
            ) : null}

            <Navbar>
                <NavImage src="/assets/close.png" onClick={onCloseClick} />
            </Navbar>
            <ProfileHeader userProfile={userProfile} shareURL={null} />
            <MobileSearchResults
                userId={userProfile.userId}
                fromProfile={true}
                resultsContainer={'profile-explore'}
            ></MobileSearchResults>
        </MobileExploreContainer>
    );
};

export default MobileProfileExplorePage;

const MobileExploreContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgb(34, 34, 34);
    width: 100dvw;
    height: 100dvh;
    overflow-y: auto;
`;

const MobileGridMapViewer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
`;

const MobileActiveMapLoading = styled.div`
    position: absolute;
    margin: auto;
    top: 50%;
    z-index: 9999;
    width: 100%;
`;

export const Navbar = styled.div`
    background-color: transparent;
    height: 55px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: end;
`;

const NavImage = styled.img`
    cursor: pointer;
    margin: 13px 20px;
    width: 24px;
    height: 24px;
`;
