import styled from 'styled-components';

const StoariesCompetitionTermsAndConditions = () => {
    return (
        <TermsAndConditionsContainer>
            <TermsAndConditionsHero src="/assets/Stoaries_Comp_thumb_01.jpg" />
            <TermsAndConditionsHeader>
                <h3>Soar Stoaries Competition Terms and Conditions</h3>
                <p>Effective Date: 16 August 2024</p>
            </TermsAndConditionsHeader>
            <TermsAndConditionsBody>
                <h4>1. ACCEPTANCE OF TERMS</h4>
                <ul>
                    <li>
                        These Terms and Conditions (referred to as the "Competition Terms") together with the General
                        Terms of Service ("Terms of Service") set forth the terms of an Agreement by which You, (the
                        "Participant") and Soar.Earth Ltd whose registered business address is at Unit 3 / 128 Main
                        Street Osborne Park, Western Australia, (“Soar”, “we”, “us”, our”), may participate from time to
                        time in this competition and related activities as specified by Soar ("the Competition").
                    </li>
                    <li>
                        You must be at least 18 years of age to become a Participant and not barred from entry to the
                        competition by any local laws regarding such competitions of the laws affecting Soar
                    </li>
                    <li>We reserve the right to change these Competition Terms at any time without notice.</li>
                    <li>
                        These Competition Terms are effective upon acceptance and govern the Participant's engagement in
                        the Competition together with Soar’s Terms of Service. In the event of the Soar Terms of Service
                        conflicting with the Competition Terms, the Competition Terms will take precedence.
                    </li>
                    <li>
                        The Competition is not open to Soar employees, associates or their family members, or anyone
                        else connected to the Competition.
                    </li>
                    <li>All entrants into the Competition shall be deemed to have accepted these Competition Terms.</li>
                    <li>
                        To enter the Competition, you must follow the Competition Instructions, and submit your entry(s)
                        as instructed. No purchase is necessary to enter the competition, however to be eligible to win
                        a prize in the Competition you must have:
                        <ul>
                            <li> a free account registered on Soar and</li>
                            <li>
                                an active PayPal account for the remittance of any Prize money should you win. If you do
                                not have an active PayPal account, you agree that the payment of any Prize money is
                                void.
                            </li>
                        </ul>
                    </li>
                    <li>
                        To create an eligible entry for the Competition you must:
                        <li>
                            Create a Soar Stoaries project with a minimum of 6 pages on any topic (for example
                            environmental, conflict, natural disasters, historic etc.)
                        </li>
                        <li>
                            Have the Stoary access set to public at the time of submission and until the completion of
                            judging for the competition
                        </li>
                        <li>
                            Post a publicly accessible link to your Stoary on X (Twitter) and tag the official Soar X
                            account @Soar_Earth in your post prior to the close of the Competition.
                        </li>
                    </li>
                    <li>
                        You may enter the competition as many times as you wish but each individual entrant is limited
                        to a single shortlisted entry and prize award.
                    </li>
                    <li>
                        If you have any questions about how to enter or otherwise in connection with the Competition,
                        please email us at hello@soar.earth with “Soar Draw Competition” in the subject line.
                    </li>
                    <li>
                        You may enter the Competition as many times as you like subject to reasonable limits and
                        automated submission of entries is strictly prohibited.
                    </li>
                    <li>
                        Should we determine you have made automated entries, we may at our sole discretion disqualify
                        you from any prizes in the competition and should we disqualify you and no correspondence will
                        be entered into.
                    </li>
                    <li>
                        Only individuals may enter the competition. Entries on behalf of another person or an
                        organisation are invalid. Joint submissions are not allowed and will not be eligible for any
                        prizes.
                    </li>
                    <li>You are responsible for the cost (if any) of sending your Competition entry to us. </li>
                    <li>
                        We accept no responsibility for entries that are lost, delayed, misdirected or incomplete or
                        cannot be delivered or entered for any technical or other reason. Proof of delivery of the entry
                        is not proof of receipt by us.
                    </li>
                    <li>
                        The Competition opens on 14 August 2024, 9:00 pm Australian Western Standard Time (AWST / GMT
                        +8). It will run for 4 weeks and closes 12 September 2024, 8:59:59 pm AWST. Entries received
                        outside this time period will be void.
                    </li>
                    <li>
                        Judging of Competition entries will be undertaken by Soar staff and selected judges to create a
                        short list of four (4) entries.
                    </li>
                    <li>
                        The 4 shortlisted entries will be open for public vote for the most popular Stoary project via
                        the official Soar X account. The vote shall be open for 1 week.
                    </li>
                    <li>
                        The prizes shall be awarded in the order of most votes to least votes for each shortlisted entry
                        over the public voting period. In the case of a tie between any entry for a prize, the prize
                        shall be awarded to the Stoary which was created first on Soar.
                    </li>
                    <li>
                        The prizes shall be awarded as follows:
                        <ul>
                            <li>First Prize - AU $500 plus a retro branded Soar shirt and Soar socks </li>
                            <li>Second Prize - AU $300 plus a retro branded Soar backpack and Soar Socks</li>
                            <li>Third prize - AU $100 plus a retro branded Soar shirt and Soar socks</li>
                            <li>Fourth Prize - Retro branded Soar Cap and Soar Socks</li>
                        </ul>
                    </li>
                    <li>Prizes are non-exchangeable, non-transferable, and not redeemable for any other prize.</li>
                    <li>
                        The winner consents to the use by us and our related companies, both before and after the
                        closing date of the Competition for an unlimited time, of the winner’s voice, image, photograph
                        and name for publicity purposes (in any medium, including still photographs and films, and on
                        the internet, including any websites hosted by us and our related companies) and in advertising,
                        marketing or promotional material without additional compensation or prior notice and, in
                        entering the Competition, all entrants consent to the same. Residents of jurisdictions where it
                        is unlawful to require agreement to a perpetual publicity release as a condition of winning a
                        prize shall not be bound by this paragraph
                    </li>
                    <li>
                        We shall use and take care of any personal information you supply in entering the Competition as
                        described in our Privacy Policy, and in accordance with data protection legislation and
                        applicable law. By entering the Competition, you agree to the collection, retention, usage and
                        distribution of your personal information in order to process and contact you about your
                        Competition entry, and for the purposes outlined above.
                    </li>
                    <li>
                        We accept no responsibility for any damage, loss, liabilities, injury or disappointment incurred
                        or suffered by you as a result of entering the Competition or accepting the prize. We further
                        disclaims liability for any injury or damage to your or any other person’s computer relating to
                        or resulting from participation in or downloading any materials in connection with the
                        Competition.
                    </li>
                    <li>
                        We reserve the right at any time and from time to time to modify or discontinue, temporarily or
                        permanently, the Competition with or without prior notice due to reasons outside its reasonable
                        control (including, without limitation, in the case of anticipated, suspected or actual fraud).
                        Our decision in all matters relating to the Competition is final and no correspondence will be
                        entered into. In the event that Soar permanently discontinues the Competition pursuant to this
                        paragraph, the entrants in the Competition shall be released from their obligations to Soar
                        under these Terms and Conditions.
                    </li>
                    <li>
                        We shall not be liable for any failure to comply with its obligations relating to this
                        Competition where the failure is caused by something outside its reasonable control. Such
                        circumstances shall include, but not be limited to, weather conditions, fire, flood, hurricane,
                        strike, industrial dispute, war, hostilities, political unrest, riots, civil commotion,
                        inevitable accidents, supervening legislation or any other circumstances amounting to force
                        majeure.
                    </li>
                    <li>
                        The Competition and these Competition Terms are governed by the laws of Western Australia and
                        entrants to the Competition submit to the exclusive jurisdiction of the Australian courts.
                    </li>
                </ul>
            </TermsAndConditionsBody>
        </TermsAndConditionsContainer>
    );
};

export default StoariesCompetitionTermsAndConditions;

const TermsAndConditionsHero = styled.img`
    margin-top: 30px;
    width: 100vw;

    @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
    }
`;

const TermsAndConditionsContainer = styled.div`
    padding-right: 50px;
    padding-bottom: 20px;
    padding-top: 60px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        margin-left: 4vw;
    }
    @media all and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
        margin: 20px;
    }

    @media only screen and (max-width: 767px) {
        margin: 20px;
        padding-top: 30px;
        padding-right: 0px;
        padding-bottom: 20px;
    }
`;

const TermsAndConditionsHeader = styled.div`
    -webkit-text-fill-color: white;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    height: 60px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    color: white;
    margin: auto;
    text-align: center;
    flex-direction: none;
    border-top: 0px;
    padding-top: 0px;

    h3 {
        color: inherit;
        justify-content: center;
        align-self: center;
        display: flex;
        padding-left: 20px;
    }

    p {
        color: inherit;
        opacity: 0.8;
        margin: 18px;
    }

    @media only screen and (max-width: 767px) {
        height: auto;
        margin-bottom: 40px;
        flex-direction: column;

        h3 {
            padding-left: 0px;
        }
    }
`;

const TermsAndConditionsBody = styled.div`
    color: white;
    margin: 20px 0px 0px 20px;

    h4 {
        font-weight: bold;
        color: #eed926;
        -webkit-text-fill-color: #eed926;
        text-align: left;
        font-size: 1.5rem;
        font-weight: 200;
    }

    li {
        color: white;
        list-style-type: number;
    }

    a {
        color: #eed926;
    }

    p {
        color: white;
    }

    @media only screen and (max-width: 767px) {
        margin-right: 20px;
    }
`;
