import { LeafletContextInterface, createElementObject, extendContext } from '@react-leaflet/core';
import L, { LeafletMouseEvent } from 'leaflet';
import { dragCircleOptions } from './circle';
import { translateCircleCenterPointToDragPoint } from './circle-annotation-util';
import LayersUtil from '../layers-util';

interface CircleDragElementProps {
    circleElement: Readonly<{ instance: L.Circle; context: Readonly<{ map: L.Map }> }>;
    color?: string;
}

// Makes the drag circle smaller that the circleElement as a percentage of the radius.
const DRAG_CIRCLE_SCALE_BY_PERCENT = 0.9; // about 10% smaller

export const createCircleDragElement = (props: CircleDragElementProps, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const circleDragLayer = new L.LayerGroup([], { pane: controlPaneId });
    const circleDragLayerElement = createElementObject<L.LayerGroup>(circleDragLayer, context);

    const originalRadius = props.circleElement.instance.getRadius();
    const dragCircleRadius = originalRadius * DRAG_CIRCLE_SCALE_BY_PERCENT;

    const dragCircle = new L.Circle(props.circleElement.instance.getLatLng(), {
        ...dragCircleOptions,
        radius: dragCircleRadius,
        pane: controlPaneId,
    });

    const dragCircleElement = createElementObject<L.Circle, CircleDragElementProps>(
        dragCircle,
        extendContext(context, { overlayContainer: dragCircle })
    );

    let lastLatLng: L.LatLng | undefined;
    circleDragLayerElement.instance.on('add', () => {
        circleDragLayerElement.instance.addLayer(dragCircleElement.instance);
        dragCircleElement.instance.setStyle({ color: props.color });

        props.circleElement.instance.on('resize', (e: LeafletMouseEvent) => {
            const resizedRadius = props.circleElement.instance.getLatLng().distanceTo(e.latlng);
            const dragCircleRadius = resizedRadius * DRAG_CIRCLE_SCALE_BY_PERCENT;
            dragCircleElement.instance.setRadius(dragCircleRadius);
        });

        dragCircleElement.instance.on('mouseover', () => {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
        });

        dragCircleElement.instance.on('mouseout', () => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
        });

        const onMouseDown = (e: LeafletMouseEvent) => {
            lastLatLng = e.latlng;
            context.map.dragging.disable();
            context.map.on('mousemove', onMouseMove);
            context.map.on('mouseup', onMouseUp);
            dragCircleElement.instance.off('mousedown', onMouseDown);
        };

        const onMouseMove = (e: LeafletMouseEvent) => {
            if (lastLatLng) {
                const dragPoint = translateCircleCenterPointToDragPoint(
                    props.circleElement.instance.getLatLng(),
                    lastLatLng,
                    e.latlng
                );
                lastLatLng = e.latlng;
                props.circleElement.instance.setLatLng(dragPoint);
                dragCircleElement.instance.setLatLng(dragPoint);
                props.circleElement.instance.fireEvent('drag', dragPoint);
            }
        };

        const onMouseUp = () => {
            lastLatLng = undefined;
            context.map.dragging.enable();
            context.map.off('mousemove', onMouseMove);
            context.map.off('mouseup', onMouseUp);
            dragCircleElement.instance.on('mousedown', onMouseDown);
        };

        dragCircleElement.instance.on('mousedown', onMouseDown);
    });

    circleDragLayerElement.instance.on('remove', () => {
        circleDragLayerElement.instance.removeLayer(dragCircleElement.instance);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
    });

    return circleDragLayerElement;
};
