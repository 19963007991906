import { LatLngBounds } from 'leaflet';
import { ImageOverlay } from 'react-leaflet';

interface SatelliteQualityWatermarkProps {
    bounds: LatLngBounds;
}

const SatelliteQualityWatermark = (props: SatelliteQualityWatermarkProps) => {
    return (
        <ImageOverlay
            url="/assets/overlay-preview/satellite-watermark.png"
            zIndex={300000}
            opacity={0.3}
            interactive={false}
            bounds={props.bounds}
        />
    );
};

export default SatelliteQualityWatermark;
