import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';

import './map-scale-control.css';

interface MapScaleControlProps extends L.ControlOptions {
    maxWidth?: number;
    updateWhenIdle?: boolean;
}

const createMapScaleControl = (props: MapScaleControlProps) => {
    const maxWidth = props.maxWidth ?? 110;
    const updateWhenIdle = props.updateWhenIdle ?? false;

    const mapScaleControl = new L.Control({ position: props.position ?? 'bottomright' });

    let mapInstance: L.Map;
    let scaleContainer: HTMLDivElement;
    let scaleMetric: HTMLDivElement;
    let scaleMetricLabel: HTMLDivElement;
    let scaleImperial: HTMLDivElement;
    let scaleImperialLabel: HTMLDivElement;

    mapScaleControl.onAdd = (map: L.Map) => {
        mapInstance = map;

        const className = 'leaflet-control-better-scale';
        scaleContainer = L.DomUtil.create('div', className);

        const ruler = L.DomUtil.create('div', `${className}-ruler`, scaleContainer);

        addScales(className, ruler);

        map.on(updateWhenIdle ? 'moveend' : 'move', update);
        map.whenReady(update, map);

        return scaleContainer;
    };

    mapScaleControl.onRemove = (map: L.Map) => {
        map.off(props.updateWhenIdle ? 'moveend' : 'move', update);
    };

    const addScales = (className: string, ruler: HTMLDivElement) => {
        scaleMetric = L.DomUtil.create('div', `${className}-ruler-block ${className}-metric`, ruler);
        scaleMetricLabel = L.DomUtil.create('div', `${className}-label`, scaleMetric);

        scaleImperial = L.DomUtil.create('div', `${className}-ruler-block ${className}-imperial`, ruler);
        scaleImperialLabel = L.DomUtil.create('div', `${className}-label`, scaleImperial);
    };

    const update = () => {
        const bounds = mapInstance.getBounds();

        const centerLat = bounds.getCenter().lat;

        const i = 6378137 * Math.PI * Math.cos((centerLat * Math.PI) / 180);

        const n = (i * (bounds.getNorthEast().lng - bounds.getSouthWest().lng)) / 180;

        const mapPoint = mapInstance.getSize();

        let a = 0;
        if (mapPoint.x > 0) {
            a = n * (maxWidth / mapPoint.x);
        }

        updateScales(a);
    };

    const updateScales = (scale: number) => {
        updateMetric(scale);
        updateImperial(scale);
    };

    const updateMetric = (scale: number) => {
        if (scale > 500) {
            const e = scale / 1000;
            const kilometers = getRoundNum(e);

            scaleMetric.style.width = `${getScaleWidth(kilometers / e)}px`;
            scaleMetricLabel.innerHTML = `${kilometers}km`;
        } else {
            const meters = getRoundNum(scale);

            scaleMetric.style.width = `${getScaleWidth(meters / scale)}px`;
            scaleMetricLabel.innerHTML = `${meters}m`;
        }
    };
    const updateImperial = (scale: number) => {
        const measurement = 3.2808399 * scale;

        if (measurement > 2640) {
            const e = measurement / 5280;
            const miles = getRoundNum(e);

            scaleImperial.style.width = `${getScaleWidth(miles / e)}px`;
            scaleImperialLabel.innerHTML = `${miles}mi`;
        } else {
            const feet = getRoundNum(measurement);

            scaleImperial.style.width = `${getScaleWidth(feet / measurement)}px`;
            scaleImperialLabel.innerHTML = `${feet}ft`;
        }
    };

    const getScaleWidth = (num: number): number => {
        return Math.round(maxWidth * num) - 10;
    };

    const getRoundNum = (num: number): number => {
        if (num >= 2) {
            const pow = Math.pow(10, (Math.floor(num) + '').length - 1);

            let i = num / pow;

            return (i = i >= 10 ? 10 : i >= 5 ? 5 : i >= 3 ? 3 : i >= 2 ? 2 : 1), pow * i;
        }
        return parseFloat((Math.round(100 * num) / 100).toFixed(1));
    };

    return mapScaleControl;
};

const MapScaleControl = createControlComponent(createMapScaleControl);

export default MapScaleControl;
