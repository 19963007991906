import { LatLng, DivIcon } from 'leaflet';
import { CoordinateSvg } from './coordinate-svg';
import {
    wrapLongitudeCoordinates,
    convertDDToDMS,
    convertDDtoUTM,
    convertDDtoMGRS,
    convertDDtoGDA2020,
    displayDMS,
} from '../../Coordinates/coordinates-util';

export const handleIcon = (id: string, latlng: LatLng, selectedCoordinateSystem: CoordinateSystem) => {
    const coordinateBoxWidth = 130;
    const coordinateBoxHeight = 40;
    const padding = 5;

    return new DivIcon({
        iconSize: [coordinateBoxWidth + padding, coordinateBoxHeight + padding],
        iconAnchor: [coordinateBoxWidth, coordinateBoxHeight],
        html: CoordinateSvg(id, latlng, selectedCoordinateSystem),
        className: 'coordinate-builder-icon',
    });
};

export const handleFormattingCoordinateLatLng = (latlng: LatLng): LatLng => {
    const lat = latlng.lat;
    const lng = wrapLongitudeCoordinates(latlng.lng);
    return new LatLng(lat, lng);
};

export type CoordinateSystem = 'WGS84 DD' | 'WGS84 MD' | 'UTM' | 'MGRS (Military)' | 'GDA94 MGA';

export interface CoordinateSystemOption {
    value: CoordinateSystem;
    label: string;
    padding?: { width: number; height: number };
    format: (latLng: LatLng) => string[];
}

export const getFormattedCoordinates = (value: CoordinateSystem, latLng: LatLng): string[] => {
    const option = coordinateSystemOptions.find((option) => option.value === value) || coordinateSystemOptions[0];
    return option.format(latLng);
};

export const getCoordinateSVGPadding = (value: CoordinateSystem): { width: number; height: number } => {
    const option = coordinateSystemOptions.find((option) => option.value === value) || coordinateSystemOptions[0];
    return option.padding || { width: 0, height: 0 };
};

const formatWGS84DD = (latLng: LatLng): string[] => {
    return [
        `Lat: ${latLng.lat.toLocaleString('en-US', { minimumFractionDigits: 8, useGrouping: false })}`,
        `Lng: ${wrapLongitudeCoordinates(latLng.lng).toLocaleString('en-US', {
            minimumFractionDigits: 8,
            useGrouping: false,
        })}`,
    ];
};

const formatWGS84MD = (latLng: LatLng): string[] => {
    return [
        `Lat: ${displayDMS(convertDDToDMS(latLng.lat, false))}`,
        `Lng: ${displayDMS(convertDDToDMS(wrapLongitudeCoordinates(latLng.lng), true))}`,
    ];
};

const formatUTM = (latLng: LatLng): string[] => {
    const utm = convertDDtoUTM(latLng.lat, wrapLongitudeCoordinates(latLng.lng));
    return [
        `Easting: ${utm.Easting.toLocaleString('en-US', { useGrouping: false })}`,
        `Northing: ${utm.Northing.toLocaleString('en-US', { useGrouping: false })}`,
        `Zone: ${utm.ZoneNumber}${utm.ZoneLetter}`,
    ];
};

const formatMGRS = (latLng: LatLng): string[] => {
    return [`MGRS:`, `${convertDDtoMGRS(wrapLongitudeCoordinates(latLng.lng), latLng.lat)}`];
};

const formatGDA94MGA = (latLng: LatLng): string[] => {
    const { x, y } = convertDDtoGDA2020(latLng.lat, wrapLongitudeCoordinates(latLng.lng));
    return [
        `X: ${x.toLocaleString('en-US', { minimumFractionDigits: 8, useGrouping: false })}`,
        `Y: ${y.toLocaleString('en-US', { minimumFractionDigits: 8, useGrouping: false })}`,
    ];
};

export const coordinateSystemOptions: CoordinateSystemOption[] = [
    {
        value: 'WGS84 DD',
        label: 'WGS84 DD',
        format: formatWGS84DD,
    },
    {
        value: 'WGS84 MD',
        label: 'WGS84 MD',
        padding: { width: 10, height: 0 },
        format: formatWGS84MD,
    },
    {
        value: 'UTM',
        label: 'UTM',
        padding: { width: 0, height: 20 },
        format: formatUTM,
    },
    {
        value: 'MGRS (Military)',
        label: 'MGRS (Military)',
        padding: { width: 20, height: 0 },
        format: formatMGRS,
    },
    {
        value: 'GDA94 MGA',
        label: 'GDA94 MGA',
        padding: { width: 25, height: 0 },
        format: formatGDA94MGA,
    },
];
