import TextBox from '../../../components/MapView/Annotations/Text/textbox';
import { actionTypes as at } from './constants';

export const setTextBoxesAction = (textBoxes: TextBox[]) => {
    return {
        type: at.SET_TEXT_BOXES,
        payload: { textBoxes: textBoxes },
    };
};

export const setTextBoxOptionsAction = (options: TextBox) => {
    return {
        type: at.SET_TEXTBOX_OPTIONS,
        payload: options,
    };
};

export const setTextBoxAddAction = (textBox: TextBox) => {
    return {
        type: at.SET_TEXT_BOX_ADD,
        payload: textBox,
    };
};

export const setTextBoxUpdateAction = (textBox: TextBox) => {
    return {
        type: at.SET_TEXT_BOX_UPDATE,
        payload: textBox,
    };
};

export const setTextBoxDeleteAction = (textBox: TextBox) => {
    return {
        type: at.SET_TEXT_BOX_DELETE,
        payload: textBox,
    };
};
