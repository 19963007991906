import { useSelector, useDispatch } from 'react-redux';

import { setZIndexAction } from '../../../store/Annotations/actions';
import { Annotation } from '../../../store/Annotations/reducer';

import Arrow from './Arrow/arrow';
import Marker from './Marker/marker';
import Circle from './Circle/circle';
import Rectangle from './Rectangle/rectangle';
import Coordinate from './Coordinate/coordinate';
import Image from './ImageTool/image';
import TextBox from './Text/textbox';
import Polyline from './Polyline/polyline';
import Polygon from './Polygon/polygon';
import FreehandPolyline from './FreehandPolyline/freehand-polyline';

import { setArrowUpdateAction } from '../../../store/Annotations/Arrow/actions';
import { setMarkerUpdateAction } from '../../../store/Annotations/Marker/actions';
import { setPolylineUpdateAction } from '../../../store/Annotations/Path/actions';
import { setCircleUpdateAction } from '../../../store/Annotations/Circle/actions';
import { setRectangleUpdateAction } from '../../../store/Annotations/Rectangle/actions';
import { setCoordinateUpdateAction } from '../../../store/Annotations/Coordinate/actions';
import { setFreehandDrawUpdateAction } from '../../../store/Annotations/Freehand/actions';
import { setPolygonUpdateAction } from '../../../store/Annotations/Polygon/actions';
import { setTextBoxUpdateAction } from '../../../store/Annotations/TextBox/actions';
import { setImageUpdateAction } from '../../../store/Annotations/Images/actions';

import { selectArrows } from '../../../store/Annotations/Arrow/selectors';
import { selectMarkers } from '../../../store/Annotations/Marker/selectors';
import { selectPolylines } from '../../../store/Annotations/Path/selectors';
import { selectCircles } from '../../../store/Annotations/Circle/selectors';
import { selectRectangles } from '../../../store/Annotations/Rectangle/selectors';
import { selectCoordinates } from '../../../store/Annotations/Coordinate/selectors';
import { selectFreehandDraws } from '../../../store/Annotations/Freehand/selectors';
import { selectPolygons } from '../../../store/Annotations/Polygon/selectors';
import { selectTextBoxes } from '../../../store/Annotations/TextBox/selectors';
import { selectImages } from '../../../store/Annotations/Images/selectors';

import { defaultZIndex } from './layers-util';

type AnnotationCallback = (annotation: Annotation) => void;

type UseLayersInterface = { layers: Annotation[]; sendToBack: AnnotationCallback; bringToFront: AnnotationCallback };

const useLayers = (): UseLayersInterface => {
    const dispatch = useDispatch();

    const markers = useSelector(selectMarkers);
    const polylines = useSelector(selectPolylines);
    const polygons = useSelector(selectPolygons);
    const circles = useSelector(selectCircles);
    const rectangles = useSelector(selectRectangles);
    const freehandDraws = useSelector(selectFreehandDraws);
    const arrows = useSelector(selectArrows);
    const images = useSelector(selectImages);
    const coordinates = useSelector(selectCoordinates);
    const textBoxes = useSelector(selectTextBoxes);

    const layers = [
        ...markers,
        ...polylines,
        ...polygons,
        ...circles,
        ...rectangles,
        ...freehandDraws,
        ...arrows,
        ...images,
        ...coordinates,
        ...textBoxes,
    ];

    layers.sort((a, b) => {
        const zIndexA = a.zIndex;
        const zIndexB = b.zIndex;

        if (zIndexA < zIndexB) {
            return -1;
        }

        if (zIndexA > zIndexB) {
            return 1;
        }

        return 0;
    });

    const updateAnnotations = async (updatedAnnotations: Annotation[]) => {
        await Promise.all(
            updatedAnnotations.map((updatedAnnotation) => {
                if (updatedAnnotation) {
                    switch (updatedAnnotation?.annotationType) {
                        case 'Marker':
                            dispatch(setMarkerUpdateAction(updatedAnnotation as Marker));
                            break;

                        case 'Polygon':
                            dispatch(setPolygonUpdateAction(updatedAnnotation as Polygon));
                            break;

                        case 'FreehandDraw':
                            dispatch(setFreehandDrawUpdateAction(updatedAnnotation as FreehandPolyline));
                            break;

                        case 'FreehandPolyline':
                            dispatch(setFreehandDrawUpdateAction(updatedAnnotation as FreehandPolyline));
                            break;

                        case 'Polyline':
                            dispatch(setPolylineUpdateAction(updatedAnnotation as Polyline));
                            break;

                        case 'Circle':
                            dispatch(setCircleUpdateAction(updatedAnnotation as Circle));
                            break;

                        case 'Rectangle':
                            dispatch(setRectangleUpdateAction(updatedAnnotation as Rectangle));
                            break;

                        case 'Image':
                            dispatch(setImageUpdateAction(updatedAnnotation as Image));
                            break;

                        case 'TextBox':
                            dispatch(setTextBoxUpdateAction(updatedAnnotation as TextBox));
                            break;

                        case 'Arrow':
                            dispatch(setArrowUpdateAction(updatedAnnotation as Arrow));
                            break;

                        case 'Coordinate':
                            dispatch(setCoordinateUpdateAction(updatedAnnotation as Coordinate));
                            break;

                        default:
                            break;
                    }
                }
            })
        );
    };

    const bringForward = async (annotation: Annotation) => {
        let currentZIndex = defaultZIndex;
        const updatedLayers: Annotation[] = [];

        layers.forEach((layer) => {
            if (layer.id !== annotation?.id) {
                updatedLayers.push({ ...layer, zIndex: currentZIndex++ } as Annotation);
            }
        });

        updatedLayers.push({ ...annotation, zIndex: currentZIndex++ } as Annotation);

        await updateAnnotations(updatedLayers);
        dispatch(setZIndexAction(currentZIndex));
    };

    const sendBack = async (annotation: Annotation) => {
        let currentZIndex = defaultZIndex;
        const updatedLayers: Annotation[] = [];

        updatedLayers.push({ ...annotation, zIndex: currentZIndex++ } as Annotation);

        layers.forEach((layer) => {
            if (layer.id !== annotation?.id) {
                updatedLayers.push({ ...layer, zIndex: currentZIndex++ } as Annotation);
            }
        });

        await updateAnnotations(updatedLayers);
        dispatch(setZIndexAction(currentZIndex));
    };

    return { layers, sendToBack: sendBack, bringToFront: bringForward };
};

export default useLayers;
