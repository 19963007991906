import React from 'react';
import styled from 'styled-components';
import { PulseLoader } from '../../Shared/pulse-loader';

const ActiveMapLoading = () => {
    return (
        <PulseLoadingContainer>
            <PulseLoader iconSize="2rem" />;
        </PulseLoadingContainer>
    );
};

export default ActiveMapLoading;

const PulseLoadingContainer = styled.div`
    margin-top: 24px;
`;
