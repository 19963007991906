import ApiComments from '../../../../api/api-comments';
import { StyledDarkButton, StyledDeleteButton } from '../../../Shared/styled-button';
import { toast } from 'react-toastify';
import { IsSuccessResponseHandler } from './types';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../Shared/soar-modal';
import Analytics from '../../../../lib/user-analytics';

interface ActiveDeleteCommentProps {
    commentId: string;
    handleCommentDelete: IsSuccessResponseHandler;
}

export const ActiveDeleteComment = (props: ActiveDeleteCommentProps) => {
    const handleDeleteComment = () => {
        ApiComments.deleteComment(props.commentId)
            .then(() => {
                props.handleCommentDelete(true);
                toast.dark('Comment deleted');
                Analytics.Event('Comments', 'Delete comment', props.commentId);
            })
            .catch((err) => {
                props.handleCommentDelete(undefined, err);
                toast.error('Error deleting comment');
            });
    };

    const handleCancel = () => {
        props.handleCommentDelete();
        Analytics.Event('Comments', 'Delete comment cancelled');
    };

    return (
        <SoarModal
            title={
                <span>
                    <i className="fa fa-exclamation-circle icon mr-2 text-danger" />
                    Delete Confirmation
                </span>
            }
            isOpen={true}
            toggle={handleCancel}
            width="400px"
        >
            <StyledModalBody>Are you sure you want to delete this comment ?</StyledModalBody>
            <StyledModalFooter>
                <StyledDarkButton onClick={handleCancel}>CANCEL</StyledDarkButton>
                <StyledDeleteButton onClick={handleDeleteComment}>DELETE</StyledDeleteButton>
            </StyledModalFooter>
        </SoarModal>
    );
};
