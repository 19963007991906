import L, { LeafletMouseEvent } from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import PolygonPath, { defaultPolygonOptions } from '../Polygon/polygon';
import { v4 as uuidv4 } from 'uuid';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';

import { handlePattternIdFromPatternFillUrl, handleDuplicatePattern } from '../FillPattern/pattern-util';
import { defaultLabelColor } from '../Measurement/measurement-toolbar-label-color';

interface FreehandDrawBuilderProps {
    onCreateFreehand: (freehandDraw: PolygonPath) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const createFreehandPolygonBuilder = (props: FreehandDrawBuilderProps, context: LeafletContextInterface) => {
    // NOTE: This is sharing the polygon options redux since they are the same, no doubt will need splitting
    const lastUsedDrawOptions = store.getState().annotationDomain.present.polygonReducer.polygonOptions;

    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);
    const freehandPolygon = new L.Polyline([], {
        ...defaultPolygonOptions,
        ...(lastUsedDrawOptions ?? {}),
        interactive: true,
        pane: builderPaneId,
    });
    const freehandPolygonElement = createElementObject<L.Polyline, FreehandDrawBuilderProps>(freehandPolygon, context);

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancelBuild();
            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            context.map.off('mouseup', onMouseUp);
            context.map.dragging.enable();
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
            document.removeEventListener('keydown', onKeyDown);
        }
    };

    const onMouseDown = (e: LeafletMouseEvent) => {
        context.map.on('mouseup', onMouseUp);
        context.map.on('mousemove', onMouseMove);
        freehandPolygon.setLatLngs([e.latlng]);
    };

    const onMouseUp = () => {
        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);

        const freehandDrawId = uuidv4();
        const freehandDrawOptions = {
            ...freehandPolygon.options,
            ...lastUsedDrawOptions,
        };

        let duplicatedPattern = '';
        if (freehandDrawOptions.fillColor) {
            const pattern = handlePattternIdFromPatternFillUrl(freehandDrawOptions.fillColor);

            if (pattern !== 'none') {
                duplicatedPattern = handleDuplicatePattern(
                    pattern,
                    freehandDrawId,
                    freehandDrawOptions.color || '#eed926'
                );
                freehandDrawOptions.fillColor = duplicatedPattern;
            }
        }

        const freehandDraw: PolygonPath = {
            annotationType: 'Polygon',
            id: freehandDrawId,
            zIndex: props.zIndex || defaultZIndex,
            positions: freehandPolygon.getLatLngs() as L.LatLng[],
            options: freehandDrawOptions,
            fillPattern: duplicatedPattern || undefined,
            showArea: false,
            showLength: false,
            labelColor: defaultLabelColor,
            units: 'metric',
        };

        // Only create the freehand if there are more than 2 points
        if (freehandDraw.positions.length > 2) {
            props.onCreateFreehand(freehandDraw);
        } else {
            props.onCancelBuild();
        }
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        if (freehandPolygon.getLatLngs().length === 0) {
            freehandPolygon.addLatLng(e.latlng);
        } else {
            const THRESHOLD = 1; // Must be more than 1px away from the last point to add a new point
            const lastPoint = (freehandPolygon.getLatLngs() as L.LatLng[])[freehandPolygon.getLatLngs().length - 1];
            const lastPointScreenPoint = context.map.latLngToContainerPoint(lastPoint);
            const screenPoint = context.map.latLngToContainerPoint(e.latlng);

            const dx = screenPoint.x - lastPointScreenPoint.x;
            const dy = screenPoint.y - lastPointScreenPoint.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            if (distance > THRESHOLD) {
                freehandPolygon.addLatLng(e.latlng);
            }
        }
    };

    freehandPolygonElement.instance.on('add', () => {
        context.map.dragging.disable();
        context.map.on('mousedown', onMouseDown);
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
    });

    freehandPolygonElement.instance.on('remove', () => {
        context.map.dragging.enable();
        context.map.off('mousedown', onMouseDown);
        context.map.off('mouseup', onMouseUp);
        context.map.off('mousemove', onMouseMove);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        document.removeEventListener('keydown', onKeyDown);
    });

    document.addEventListener('keydown', onKeyDown);

    return freehandPolygonElement;
};

const useFreehandDrawBuilder = createElementHook<L.Polyline, FreehandDrawBuilderProps, LeafletContextInterface>(
    createFreehandPolygonBuilder
);

const FreehandPolygonBuilder = (props: FreehandDrawBuilderProps) => {
    const context = useLeafletContext();
    const freehandDrawBuilder = useFreehandDrawBuilder(props, context);
    useLayerLifecycle(freehandDrawBuilder.current, context);
    return null;
};

export default FreehandPolygonBuilder;
