import React from 'react';
import { UserDTO, ListingDTO } from '../../../../../api/model';
import ProfileUserMapStats from './profile-user-map-stats';

import styled from 'styled-components';

interface ProfileStatsProps {
    userProfile: UserDTO;
    listings: ListingDTO[];
}

const ProfileStats = (props: ProfileStatsProps) => {
    const mapCount = props.listings.length;

    const likeCount = props.listings
        .map((t) => t.totalLikes)
        .reduce((acc, val) => {
            return acc + val;
        }, 0);

    const hasStats = mapCount || likeCount;
    return (
        <React.Fragment>
            {hasStats ? (
                <Container>
                    <ProfileUserMapStats listings={props.listings} />
                </Container>
            ) : null}
        </React.Fragment>
    );
};

export default ProfileStats;

const Container = styled.div`
    padding: 10px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    align-items: center;

    @media only screen and (max-width: 600px) {
        padding: 5px 0px;
        width: 100%;
    }
`;
