import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RgbaColorPicker, RgbaColor } from 'react-colorful';

interface OpacitySliderProps {
    color: string;
    opacity: number;
    onSelectOpacity: (opacity: number) => void;
    invalidate: boolean;
    updateOnMouseUp?: boolean;
}

const colorToRGBA = (color: string, opacity: number): RgbaColor => {
    const rgba: RgbaColor = {
        r: parseInt(color.slice(1, 3), 16),
        g: parseInt(color.slice(3, 5), 16),
        b: parseInt(color.slice(5, 7), 16),
        a: opacity,
    };
    return rgba;
};

const OpacitySlider = (props: OpacitySliderProps) => {
    const opacity = useRef(props.opacity);
    const [color, setColor] = useState(colorToRGBA(props.color, opacity.current));

    useEffect(() => {
        // Force the opacity value to invalidate when the fill pattern changes
        // Using keys to re-render will break the slider
        if (props.invalidate) {
            opacity.current = props.opacity;
            setColor(colorToRGBA(props.color, opacity.current));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.invalidate]);

    useEffect(() => {
        setColor(colorToRGBA(props.color, opacity.current));
    }, [props.color]);

    const handleMouseUp = () => {
        // If updateOnMouseUp is true, then the opacity is updated on mouse up
        opacity.current = Math.max(color.a, 0.000001);
        props.onSelectOpacity(opacity.current);
    };

    const handleChange = (newColor: RgbaColor) => {
        setColor(newColor);
        // If updateOnMouseUp is false, then the opacity is updated on every change
        if (!props.updateOnMouseUp) {
            opacity.current = Math.max(newColor.a, 0.000001);
            props.onSelectOpacity(opacity.current);
        }
    };

    return (
        <Container key={`invalidate-${props.invalidate}`}>
            <SliderOnlyRbgaColorPicker color={color} onMouseUp={handleMouseUp} onChange={handleChange} />
            <Percentage>{Math.round(opacity.current * 100)}%</Percentage>
        </Container>
    );
};

export default OpacitySlider;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 12px;
`;

const Percentage = styled.span`
    font-size: 12px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    padding: 1px 3px;
    margin: 0px;
    height: auto;
    width: 38px;
    height: 22px;
`;

const SliderOnlyRbgaColorPicker = styled(RgbaColorPicker)`
    width: 200px !important;
    margin-top: 5px;
    margin-right: 12px;
    height: auto !important;

    .react-colorful__saturation {
        display: none;
    }

    .react-colorful__hue {
        display: none;
    }

    .react-colorful__alpha {
        border-radius: 12px;
        height: 12px;
    }

    .react-colorful__saturation-pointer {
    }
    .react-colorful__hue-pointer,
    .react-colorful__alpha-pointer {
        width: 17px;
        height: 17px;
        border: none;
        cursor: pointer;
    }
`;
