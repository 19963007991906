import Marker from '../../../components/MapView/Annotations/Marker/marker';
import { MarkerIconName } from '../../../components/MapView/Annotations/Marker/marker-util';
import { actionTypes as at } from './constants';

export const setMarkersAction = (markers: Marker[]) => {
    return {
        type: at.SET_MARKERS,
        payload: { markers: markers },
    };
};

export const setMarkerColorAction = (color?: string) => {
    return {
        type: at.SET_MARKER_COLOR,
        payload: color,
    };
};

export const setMarkerIconAction = (iconName: MarkerIconName) => {
    return {
        type: at.SET_MARKER_ICON,
        payload: iconName,
    };
};

export const setMarkerAddAction = (marker: Marker) => {
    return {
        type: at.SET_MARKER_ADD,
        payload: marker,
    };
};

export const setMarkerUpdateAction = (marker: Marker) => {
    return {
        type: at.SET_MARKER_UPDATE,
        payload: marker,
    };
};

export const setMarkerDeleteAction = (marker: Marker) => {
    return {
        type: at.SET_MARKER_DELETE,
        payload: marker,
    };
};
