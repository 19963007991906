import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiListings from '../../../../../../api/api-listings';
import GeoUtil from '../../../../../../lib/geo-util';
import ListingHelper from '../../../../../../lib/listing-helper';
import Analytics from '../../../../../../lib/user-analytics';
import { selectMapBounds } from '../../../../../../store/App/selectors';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-modal';

interface SearchContentMapsInViewportProps {
    onSelectItem: (item: CarouselItem) => void;
}

const worldBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));

const SearchContentMapsInViewport = (props: SearchContentMapsInViewportProps) => {
    const aoi = useSelector(selectMapBounds);
    const [listings, setListings] = useState<CarouselItem[]>([]);

    useEffect(() => {
        const selectedAoi = aoi || worldBounds;
        if (selectedAoi && selectedAoi instanceof L.LatLngBounds) {
            const aoiWTK = GeoUtil.latLngBoundsToWKT(selectedAoi);
            ApiListings.searchListings(20, 0, undefined, undefined, aoiWTK, undefined).then((res) => {
                const items: CarouselItem[] = res.listings.map((t) => {
                    return {
                        listingId: t.id,
                        previewUrl: ListingHelper.getPreviewUrlForListing(t.id),
                        title: t.title,
                        author: t.userName,
                        opacity: 1,
                    };
                });
                setListings(items);
            });
        }
    }, [aoi]);

    if (listings.length === 0) return <React.Fragment />;

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-current-events.svg" />
                Maps in <span>this area</span>
            </SegmentTitle>

            <CarouselContainer>
                <SearchContentCarousel
                    items={listings}
                    height="180px"
                    handleSelectItem={(item: CarouselItem) => {
                        props.onSelectItem(item);
                        Analytics.Event('Search Bar', 'Clicked Map From Similar User', item.listingId);
                    }}
                />
            </CarouselContainer>
        </SegmentContainer>
    );
};

export default SearchContentMapsInViewport;

const CarouselContainer = styled.div``;
