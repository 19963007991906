import React, { useState } from 'react';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';

import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import FreehandPolygonBuilder from './freehand-polygon-builder';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { setPolygonAddAction } from '../../../../store/Annotations/Polygon/actions';

const FreehandPolygonControl = (props: AnnotationControlProps) => {
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();

    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    // This control is the same as a regular polygon, except a different builder based of the freehand path tool
    // Since we use the `setPolygonAction`, it handles all the display logic
    return (
        <React.Fragment>
            <AnnotationControl
                active={isActive}
                disabled={props.disabled}
                layout="middle"
                visible={props.visible}
                onClick={() => {
                    setIsActive((current) => !current);
                    lockAnnotationsEvent();
                }}
                iconDark="/assets/annotations/icon-blob-black.svg"
                iconLight="/assets/annotations/icon-blob.svg"
                name="Freehand Polygon"
                dataTestId="annotation-control-polygon-freehand"
            />

            {isActive ? (
                <FreehandPolygonBuilder
                    zIndex={currentZIndex}
                    onCreateFreehand={(freehand) => {
                        setIsActive(false);
                        dispatch(setPolygonAddAction(freehand));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(freehand));
                        });
                        DrawToolAnalytics.add('freehand polygon');
                    }}
                    onCancelBuild={() => {
                        setIsActive(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

export default FreehandPolygonControl;
