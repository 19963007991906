import { LatLng, LatLngBounds } from "leaflet";
import { useDispatch } from "react-redux";
import { actionUpdateActiveSearchPosition } from "../store/Search/actions";

export const usePositionMarker = () => {
    const dispatch = useDispatch();

    const positionMarker = (position: LatLng | LatLngBounds) => {
        const positionMarker = (position: LatLng | LatLngBounds) => {
            if (position instanceof LatLng) {
                const handleWithMarker = (position: LatLng) => dispatch(actionUpdateActiveSearchPosition(position));
                dispatch(handleWithMarker(position));
            } else {
                const latlng = position.getCenter();
                const handleWithMarker = (latlng: LatLng) => dispatch(actionUpdateActiveSearchPosition(latlng));
                dispatch(handleWithMarker(latlng));
            }
        };

        if (position) {
            positionMarker(position);
        }
    };

    return positionMarker;
};
