import L from 'leaflet';
import { createLayerComponent, LeafletContextInterface, LayerProps } from '@react-leaflet/core';
import { ListingTileMatrix } from '../../../api/model';
import GeoUtil from '../../../lib/geo-util';

interface WMTSTileLayerProps extends LayerProps {
    url: string;
    layer: string;
    tileMatrixSet: string;
    matrixIds: ListingTileMatrix[];
    crs?: string;
    style?: string;
    format?: string;
}

// @ts-ignore
L.TileLayer.WMTS = L.TileLayer.extend({
    defaultWmtsParams: {
        service: 'WMTS',
        request: 'GetTile',
        version: '1.0.0',
        layer: 'CARTO_WM_256',
        style: 'default',
        tileMatrixSet: 'EPSG:3857:256',
        format: 'image/png',
    },

    initialize: function (
        url: string,
        { matrixIds, tileMatrixSet, style, layer, ...options },
        context: LeafletContextInterface
    ) {
        this._url = url;
        this._map = context.map;
        const wmtsParams = L.extend({}, this.defaultWmtsParams);
        const tileSize = options.tileSize || this.options.tileSize;

        if (options.detectRetina && L.Browser.retina) {
            wmtsParams.width = wmtsParams.height = tileSize * 2;
        } else {
            wmtsParams.width = wmtsParams.height = tileSize;
        }
        if (layer) {
            wmtsParams['layer'] = layer;
        }
        if (tileMatrixSet) {
            wmtsParams['tileMatrixSet'] = tileMatrixSet;
        }
        if (style) {
            wmtsParams['style'] = style;
        }
        this.wmtsParams = wmtsParams;
        this.matrixIds = matrixIds ? this._parseMatrices(matrixIds) : this.getDefaultMatrix();

        L.setOptions(this, options);
    },

    onAdd: function (map) {
        this._crs = this.options.crs || map.options.crs;
        L.TileLayer.prototype.onAdd.call(this, map);
    },

    getTileUrl: function (coords) {
        const tileSize = this.options.tileSize;
        const nwPoint = coords.multiplyBy(tileSize);
        nwPoint.x += 1;
        nwPoint.y -= 1;
        const sePoint = nwPoint.add(new L.Point(tileSize, tileSize));
        const zoom = this._tileZoom;
        const nw = this._crs.project(this._map.unproject(nwPoint, zoom));
        const se = this._crs.project(this._map.unproject(sePoint, zoom));
        const tilewidth = se.x - nw.x;

        const tilematrix = this.matrixIds[zoom].identifier;
        const X0 = this.matrixIds[zoom].topLeftCorner.lng;
        const Y0 = this.matrixIds[zoom].topLeftCorner.lat;
        const tilecol = Math.floor((nw.x - X0) / tilewidth);
        const tilerow = -Math.floor((nw.y - Y0) / tilewidth);

        const url = L.Util.template(this._url, { s: this._getSubdomain(coords) });

        return (
            url +
            L.Util.getParamString(this.wmtsParams) +
            '&tileMatrix=' +
            tilematrix +
            '&tileRow=' +
            tilerow +
            '&tileCol=' +
            tilecol
        );
    },

    setParams: function (params, noRedraw) {
        L.extend(this.wmtsParams, params);
        if (!noRedraw) {
            this.redraw();
        }
        return this;
    },

    getDefaultMatrix: function () {
        /**
         * the matrix3857 represents the projection
         * for in the IGN WMTS for the google coordinates.
         */
        const matrixIds3857 = new Array(22);
        for (let i = 0; i < 22; i++) {
            matrixIds3857[i] = {
                identifier: '' + i,
                // identifier: i < 10 ? `0${i}` : i,
                topLeftCorner: new L.LatLng(20037508.3428, -20037508.3428),
            };
        }
        return matrixIds3857;
    },

    _parseMatrices: function (matrices: ListingTileMatrix[]) {
        return matrices
            .map((matrix) => {
                const { topLeftPoint } = matrix;

                matrix['topLeftCorner'] = GeoUtil.latLngFromWKT(topLeftPoint);

                return matrix;
            })
            .filter((matrix) => matrix);
    },
});

// @ts-ignore
L.tileLayer.wmts = function () {
    // @ts-ignore
    return new L.TileLayer.WMTS();
};

const createWMTSTileLayer = (props: WMTSTileLayerProps, context: LeafletContextInterface) => {
    // @ts-ignore
    const instance = new L.TileLayer.WMTS(props.url, { ...props }, context);

    return { instance, context };
};

const updateWMTSTileLayer = (instance, props, prevProps) => {
    if (props.opacity !== prevProps.opacity) {
        instance.setOpacity(props.opacity);
    }
};

const WMTSTileLayer = createLayerComponent(createWMTSTileLayer, updateWMTSTileLayer);

export default WMTSTileLayer;
