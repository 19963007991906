import styled from 'styled-components';
import { Input } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../../../../../lib/user-analytics';
import {
    actionSetDroneImageDistortionTool,
    actionSetDroneImageDistortionOpacity,
} from '../../../../../store/Map/Uploads/actions';
import {
    selectDroneImageDistortionCorners,
    selectDroneImageDistortionTool,
    selectDroneImageOpacity,
} from '../../../../../store/Map/Uploads/selectors';
import DrawerHint from '../../../drawer-hint';
import { StyledTransButton, StyledButton } from '../../../../Shared/styled-button';

interface UploadMapImageLocationProps {
    onClickBack: () => void;
    onSubmit: (corners: number[][] | undefined) => void;
}

const UploadMapImageLocation = (props: UploadMapImageLocationProps) => {
    const corners = useSelector(selectDroneImageDistortionCorners);
    const distortionMode = useSelector(selectDroneImageDistortionTool);
    const opacity = useSelector(selectDroneImageOpacity);

    const dispatch = useDispatch();
    const setDistortionTool = (mode: 'none' | 'rotate' | 'translate' | 'distort' | 'scale') =>
        dispatch(actionSetDroneImageDistortionTool(mode));
    const setDroneOpacity = (opacity: number) => dispatch(actionSetDroneImageDistortionOpacity(opacity));

    return (
        <Container>
            <Title>Choose Location</Title>
            <ChooseLocationDrawerhint>
                <DrawerHint>
                    We were able to set the approximate position of your image. However, it may not be 100% accurate.
                    Use these tools to fine-tune its position.
                </DrawerHint>
            </ChooseLocationDrawerhint>
            <ButtonContainer>
                <Button
                    active={distortionMode === 'translate'}
                    onClick={() => {
                        distortionMode === 'translate' ? setDistortionTool('none') : setDistortionTool('translate');
                        Analytics.Event('Upload', 'Distortion tools', 'translate');
                    }}
                >
                    <Icon className="fa fa-arrows-alt" />
                    Move
                </Button>

                <Button
                    active={distortionMode === 'scale'}
                    onClick={() => {
                        distortionMode === 'scale' ? setDistortionTool('none') : setDistortionTool('scale');
                        Analytics.Event('Upload', 'Distortion tools', 'scale');
                    }}
                >
                    <Icon className="fa fa-compress-arrows-alt" />
                    Scale
                </Button>

                <Button
                    active={distortionMode === 'rotate'}
                    onClick={() => {
                        distortionMode === 'rotate' ? setDistortionTool('none') : setDistortionTool('rotate');
                        Analytics.Event('Upload', 'Distortion tools', 'rotate');
                    }}
                >
                    <Icon className="fa fa-sync-alt" />
                    Rotate
                </Button>

                <Button
                    active={distortionMode === 'distort'}
                    style={{ minWidth: '108px' }}
                    onClick={() => {
                        distortionMode === 'distort' ? setDistortionTool('none') : setDistortionTool('distort');
                        Analytics.Event('Upload', 'Distortion tools', 'distort');
                    }}
                >
                    <Icon className="fa fa-vector-square" />
                    Adjust Corners
                </Button>
            </ButtonContainer>

            <SliderContainer>
                <SliderDetails>
                    <SliderText>Transparency:</SliderText>
                    <SliderValue>{(opacity * 100).toFixed()}%</SliderValue>
                </SliderDetails>
                <SliderInputContainer>
                    <Slider
                        type="range"
                        defaultValue={100}
                        value={opacity * 100}
                        step={0.01}
                        onChange={(e) => {
                            const opacity = parseInt(e.target.value) / 100;
                            setDroneOpacity(opacity);
                        }}
                    />
                </SliderInputContainer>
            </SliderContainer>

            <div className="d-flex mt-4 justify-content-between">
                <StyledTransButton
                    onClick={() => {
                        props.onClickBack();
                        Analytics.Event('Upload', 'Clicked back arrow at choose location');
                    }}
                >
                    <i className="fa fa-chevron-left mr-2"></i>BACK
                </StyledTransButton>

                <StyledButton
                    className="mr-0 pl-4 pr-4"
                    onClick={() => {
                        setDistortionTool('none');
                        props.onSubmit(corners);
                        Analytics.Event('Upload', 'Clicked continue from choose location');
                    }}
                >
                    CONTINUE
                    <i className="fa fa-chevron-right ml-2"></i>
                </StyledButton>
            </div>
        </Container>
    );
};

export default UploadMapImageLocation;

const Container = styled.div`
    max-height: calc(-75px + 100vh);
    padding: 20px;
`;

const Title = styled.h3`
    flex: 0 1 auto;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Button = styled.button<{ active: boolean }>`
    flex: 1;
    margin: 3px 0px;
    padding: 5px 0px;
    display: block;
    font-size: 14px;
    border: ${(props) => (props.active ? '1px solid rgba(0, 0, 0, 1.0);' : '1px solid rgba(0, 0, 0, 0.2);')};
    color: black;
    border-radius: 6px;
    text-transform: uppercase;
    letter-spacing: -0.5px;

    :not(:disabled) {
        cursor: pointer;
        background-color: ${(props) => props.theme.color.yellow} !important;
    }

    :focus {
        outline: none;
    }

    i {
        font-size: 18px;
    }
`;

const Icon = styled.i`
    display: block;
    font-size: 1.1rem;
`;

const SliderDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SliderContainer = styled.div`
    margin: 15px 0 0 0;
`;

const SliderText = styled.p`
    vertical-align: center;
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
`;

const SliderValue = styled.p`
    vertical-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    min-width: 26px;
    margin: 0;
    padding: 0;
    margin-left: 8px;
`;

const SliderInputContainer = styled.div`
    vertical-align: center;
    width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        ${(props) => props.theme.color.yellow} ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 12px;
    cursor: pointer;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: ${(props) => props.theme.color.yellow};
        height: 12px;
        width: 12px;
        border-radius: 50%;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            ${(props) => props.theme.color.yellow} ${(props) => 100 - props.value}%
        );
    }
`;

const ChooseLocationDrawerhint = styled.div`
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    transition: width 0.3s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 11px 14px -7px, rgba(0, 0, 0, 0.24) 0px 23px 36px 3px,
        rgba(0, 0, 0, 0.22) 0px 9px 44px 8px;
    border: 1px solid rgb(81, 81, 81);
    border-radius: 6px;
`;
