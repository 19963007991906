import styled from 'styled-components';

import ImageSlider from 'react-image-comparison-slider';
import { StyledButton } from '../styled-button';

import SoarModal, { StyledModalBody } from '../soar-modal';

interface ComparisonModalProps {
    isOpen: boolean;
    toggle: () => void;
    description?: string;
    image1: string;
    image2: string;
    image1_label?: string;
    image2_label?: string;
}

const ComparisonModal = (props: ComparisonModalProps) => {
    return (
        <ComparisonSoarModal isOpen={props.isOpen}>
            <SoarModalBody>
                <ImageContainer>
                    <ImageSlider
                        image1={props.image2}
                        image2={props.image1}
                        sliderWidth={3}
                        sliderColor="#EED926"
                        handleColor="#00A2FF"
                        handleBackgroundColor="black"
                        leftLabelText={props.image1_label}
                        rightLabelText={props.image2_label}
                        showPlaceholder={true}
                    />
                </ImageContainer>
                <Button className="comparison-modal-button" onClick={props.toggle}>
                    CLOSE
                </Button>
            </SoarModalBody>
        </ComparisonSoarModal>
    );
};

export default ComparisonModal;

const ComparisonSoarModal = styled(SoarModal)`
    width: 900px;
    max-width: 900px;

    @media only screen and (max-height: 750px) {
        width: 800px;
        max-width: 800px;
        height: 485px;
    }
`;

const SoarModalBody = styled(StyledModalBody)``;

const ImageContainer = styled.div`
    width: 850px;
    height: 600px;
    max-width: 850px;
    max-height: 600px;
    z-index: 999;
    margin: auto;

    @media only screen and (max-height: 750px) {
        width: 750px;
        height: 400px;
    }
`;

const Button = styled(StyledButton)`
    margin: 15px auto;
`;
