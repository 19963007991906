import React, { useState } from 'react';
import styled from 'styled-components';
import ApiComments from '../../../../api/api-comments';
import { CommentResultsDTO } from '../../../../api/model';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { LoginModalMode } from '../../../Registration/login-enum';
import { useSelector } from 'react-redux';
import Analytics from '../../../../lib/user-analytics';

interface CommentVoteProps {
    comment: CommentResultsDTO;
}

type VoteDirection = 'UP' | 'DOWN' | 'UNVOTE';

const CommentVote = (props: CommentVoteProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);

    const [voteDirection, setVoteDirection] = useState<VoteDirection>(props.comment.userVote);
    const [voteCount, setVoteCount] = useState(props.comment.vote);

    const [showLoginRegisterModal, setShowLoginRegisterModal] = useState(false);

    const handleUpVote = () => {
        ApiComments.upVote(props.comment.id);
        Analytics.Event('Comments', 'Upvoted comment', props.comment.id);
    };

    const handleDownVote = () => {
        ApiComments.downVote(props.comment.id);
        Analytics.Event('Comments', 'Downvoted comment', props.comment.id);
    };

    const handleRemoveVote = () => {
        ApiComments.removeVote(props.comment.id);
        Analytics.Event('Comments', 'Removed vote comment', props.comment.id);
    };

    const handleClickUpvote = (e) => {
        e.stopPropagation();

        if (!isLoggedIn) {
            setShowLoginRegisterModal(true);
            return;
        }

        // User presses upvote
        switch (voteDirection) {
            case 'UP': // Change upvote to neutral
                handleRemoveVote();
                setVoteDirection('UNVOTE');
                setVoteCount(voteCount - 1);
                break;

            case 'DOWN': // Change downvote to neutral
                handleRemoveVote();
                setVoteDirection('UNVOTE');
                setVoteCount(voteCount + 1);
                break;

            case 'UNVOTE': // Change neutral to upvote
                handleUpVote();
                setVoteDirection('UP');
                setVoteCount(voteCount + 1);
        }
    };

    const handleClickDownvote = (e) => {
        e.stopPropagation();

        if (!isLoggedIn) {
            setShowLoginRegisterModal(true);
            return;
        }

        // User presses downvote
        switch (voteDirection) {
            case 'UP': // Change upvote to neutral
                handleRemoveVote();
                setVoteDirection('UNVOTE');
                setVoteCount(voteCount - 1);
                break;

            case 'DOWN': // Change downvote to neutral
                handleRemoveVote();
                setVoteDirection('UNVOTE');
                setVoteCount(voteCount + 1);
                break;

            case 'UNVOTE': // Change neutral to downvote
                handleDownVote();
                setVoteDirection('DOWN');
                setVoteCount(voteCount - 1);
        }
    };

    return (
        <React.Fragment>
            <LoginRegisterDialog
                isOpen={showLoginRegisterModal}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => setShowLoginRegisterModal(false)}
            />

            <CommentVoteContainer>
                <ChevronIconUpVote
                    className={'fa fa-chevron-up'}
                    onClick={handleClickUpvote}
                    isUpvoted={voteDirection === 'UP'}
                />
                <Vote>{voteCount}</Vote>
                <ChevronIconDownVote
                    className={'fa fa-chevron-down'}
                    onClick={handleClickDownvote}
                    isDownvoted={voteDirection === 'DOWN'}
                />
            </CommentVoteContainer>
        </React.Fragment>
    );
};

export default CommentVote;

const CommentVoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 0px 0px 7px;
    align-items: center;
    width: 20px;
    height: 45px;
    line-height: 16px;
`;

const ChevronIconUpVote = styled.i<{ isUpvoted: boolean }>`
    border-radius: 4px;
    color: ${(props) => (props.isUpvoted ? '#EED926' : 'rgb(255 255 255 / 80%)')};

    &:hover {
        color: #eed926;
    }
`;

const ChevronIconDownVote = styled.i<{ isDownvoted: boolean }>`
    border-radius: 4px;
    color: ${(props) => (props.isDownvoted ? '#7193ff' : 'rgb(255 255 255 / 80%)')};

    &:hover {
        color: #7193ff;
    }
`;

const Vote = styled.span`
    color: rgba(255, 255, 255, 0.6);
    min-width: 9px;
    text-align: center;
`;
