import styled from 'styled-components';

interface SideDrawerButtonProps {
    label?: string;
    onClick: () => void;
}

export const SideDrawerBackButton = (props: SideDrawerButtonProps) => {
    return (
        <BackButton onClick={props.onClick}>
            <BackButtonIcon className="fa fa-chevron-left" />
            {props.label ?? `Back`}
        </BackButton>
    );
};

const BackButton = styled.button`
    color: white;
    border: none;
    background: transparent;
    font-size: ${(props) => props.theme.fontSize.normal};
    padding: 0px 10px;
    user-select: none;
    align-self: flex-start;
    margin-top: 10px;

    &:focus {
        outline: none;
    }
`;

const BackButtonIcon = styled.i`
    margin-right: 4px;
    user-select: none;
`;
