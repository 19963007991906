import L, { LatLngBounds } from 'leaflet';
import GeoUtil from '../../../lib/geo-util';
import turfArea from '@turf/area';
import turfDistance from '@turf/distance';
import { Point } from '@turf/helpers';

export interface AoiTextProps {
    color: string;
    text: string;
}

export interface AOIParams {
    maxArea: number;
    minArea: number;
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
}

export const satelliteProviderAOIText = (aoiParams, bounds: LatLngBounds): AoiTextProps => {
    const geoJson = new L.Polygon(GeoUtil.polygonForBounds(bounds)).toGeoJSON();
    const area = turfArea(geoJson);
    const areaKm2 = area / 1000 / 1000;

    const ne: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getNorth()] };
    const se: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getSouth()] };
    const nw: Point = { type: 'Point', coordinates: [bounds.getWest(), bounds.getNorth()] };
    const height = turfDistance(ne, se, { units: 'kilometers' });
    const width = turfDistance(nw, ne, { units: 'kilometers' });

    if (areaKm2 < aoiParams.minArea) {
        return {
            color: 'red',
            text: `Too Small`,
        };
    } else if (areaKm2 > aoiParams.maxArea) {
        return {
            color: 'red',
            text: `Too Large`,
        };
    } else if (width > aoiParams.maxWidth) {
        return {
            color: 'red',
            text: `Too Wide`,
        };
    } else if (width < aoiParams.minWidth) {
        return {
            color: 'red',
            text: `Too Thin`,
        };
    } else if (height > aoiParams.maxHeight) {
        return {
            color: 'red',
            text: `Too Tall`,
        };
    } else if (height < aoiParams.minHeight) {
        return {
            color: 'red',
            text: `Too Short`,
        };
    }
    return {
        color: 'blue',
        text: ``,
    };
};
