import { useState, useEffect, useCallback, useRef } from 'react';
import UserHelper from '../../../../lib/user-helper';
import { actionGetMyProfile } from '../../../../store/Account/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import Constants from '../../../../constants';

interface UseOAuthProps {
    subdomain: string;
    scope: string;
    identityProvider: string;
}

const POPUP_WIDTH = 600;
const POPUP_HEIGHT = 800;

const useOAuth = ({ subdomain, scope, identityProvider }: UseOAuthProps) => {
    const [authUrl, setAuthUrl] = useState<string>('');
    const popupRef = useRef<Window | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const baseUrl = `https://${subdomain}.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize`;
        const params = new URLSearchParams({
            response_type: 'code',
            client_id: Constants.COGNITO_CLIENT_ID,
            identity_provider: identityProvider,
            redirect_uri: `${window.location.origin}/auth/callback`,
            state: identityProvider,
        });

        setAuthUrl(`${baseUrl}?${params}&scope=${scope}`);
    }, [subdomain, scope, identityProvider]);

    const openPopup = useCallback(() => {
        if (popupRef.current && !popupRef.current.closed) {
            popupRef.current.focus();
            return;
        }

        const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
        const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
        popupRef.current = window.open(
            authUrl,
            'OAuth2 Popup',
            `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
        );
    }, [authUrl]);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === 'OAuthCallback') {
                const { data } = event.data;
                if (data) {
                    UserHelper.saveToken(data);
                    dispatch(actionGetMyProfile());
                    dispatch(actionShowLoginDialog(false));
                } else {
                    toast.error(`Failed to authenticate with ${identityProvider}. Please try again.`);
                }
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [dispatch, identityProvider]);

    return { openPopup };
};

export default useOAuth;
