import { StoaryPage, Stoary } from '../../../api/stoaryModel';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface StoreDrawStory {
    stoaryBuilderActive: boolean;
    stoary: Stoary | undefined;
    stoaryPage: StoaryPage | undefined;
    stoaryId: string | undefined;
    highlightedStoaryWKT: string | undefined;
}

const initialState: StoreDrawStory = {
    stoaryBuilderActive: false,
    stoary: undefined,
    stoaryPage: undefined,
    stoaryId: undefined,
    highlightedStoaryWKT: undefined,
};

export default (state: StoreDrawStory = initialState, action: Action): StoreDrawStory => {
    switch (action.type) {
        case at.STOARY_BUILDER_ACTIVE:
            return {
                ...state,
                stoaryBuilderActive: action.payload,
            };

        case at.SET_STOARY:
            return {
                ...state,
                stoary: action.payload,
            };

        case at.SET_STOARY_PAGE:
            return {
                ...state,
                stoaryPage: action.payload,
            };

        case at.SET_STOARY_ID:
            return {
                ...state,
                stoaryId: action.payload,
            };
        case at.SET_HIGHLIGHTED_STOARY_WKT:
            return {
                ...state,
                highlightedStoaryWKT: action.payload,
            };

        default:
            return state;
    }
};
