import React from 'react';
import Analytics from '../../../lib/user-analytics';
import UriHelper from '../../../lib/uri-helper';
import { SitemapLink } from './footer';
import { SideDrawerMode } from '../../../store/SideDrawer/model';

const DiscoverLinks = () => {
    const onClickMaps = () => {
        Analytics.Event('LandingPage', 'Clicked footer maps link');
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    const onClickSatellite = () => {
        Analytics.Event('LandingPage', 'Clicked footer satellite link');
        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
    };

    return (
        <React.Fragment>
            <SitemapLink onClick={onClickMaps} title="Maps">
                Maps
            </SitemapLink>
            <SitemapLink onClick={onClickSatellite} title="Satellite">
                Satellites
            </SitemapLink>
        </React.Fragment>
    );
};

export default DiscoverLinks;
