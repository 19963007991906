import { actionTypes as at } from './constants';
import { Action } from '../../root-reducer';

const initialState = {
    satelliteOverlays: [],
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case at.SATELLITE_OVERLAY_ADD: {
            const satelliteOverlaysAdd = state.satelliteOverlays.concat(action.payload);
            return {
                ...state,
                satelliteOverlays: satelliteOverlaysAdd,
            };
        }
        case at.SATELLITE_OVERLAY_REMOVE: {
            const satelliteOverlaysRemove = state.satelliteOverlays.filter((overlay) => overlay !== action.payload);
            return {
                ...state,
                satelliteOverlays: satelliteOverlaysRemove,
            };
        }
        default:
            return state;
    }
};
