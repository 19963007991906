import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionFetchBookmarkedMaps } from '../../../store/Map/BookMarks/actions';
import { selectBookmarkedMaps, selectBookmarkedMapsIsLoading } from '../../../store/Map/BookMarks/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';
import NavigationPanel from '../Shared/navigation-panel';
import BookmarkMasonary from './bookmark-masonary';

const MyBookmarks = () => {
    const dispatch = useDispatch();
    const bookmarkedMaps = useSelector(selectBookmarkedMaps);
    const bookmarkedMapsLoading = useSelector(selectBookmarkedMapsIsLoading);

    useEffect(() => {
        dispatch(actionFetchBookmarkedMaps());
    }, [dispatch]);

    return (
        <React.Fragment>
            <NavigationPanel label={'My Bookmarks'} />
            <ImageryContainer>
                {bookmarkedMaps === undefined || bookmarkedMapsLoading ? (
                    <LoaderContainer>
                        <PulseLoader iconSize="2rem" iconMargin="10px" />
                    </LoaderContainer>
                ) : (
                    <BookmarkMasonary dataTestId="dashboard-mybookmarks-imagery" listings={bookmarkedMaps} />
                )}
            </ImageryContainer>
        </React.Fragment>
    );
};

export default MyBookmarks;

const ImageryContainer = styled.div`
    margin-top: 20px;

    @media (max-width: 900px) {
        flex-direction: column;
        margin-top: 0px;
    }
`;

const LoaderContainer = styled.div`
    height: calc(100vh - 100px);
`;
