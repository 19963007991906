import React, { useEffect, useState } from 'react';
import { isMobileVersion } from '../../lib/soar-helper';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import UriHelper, { useQuery } from '../../lib/uri-helper';
import { orderByOptions } from './explore-options';
import ExploreSearch from './explore-search';
import DropDown, { DropOptionType } from '../Shared/dropdown';

export interface FilterValues {
    keywords: string;
    aoi?: string;
    category?: string;
    orderBy?: string;
}

interface ExploreFiltersProps {
    onFilter: (value: FilterValues) => void;
    isVersion2?: boolean;
}

const ExploreFilters = ({ onFilter, isVersion2 = false }: ExploreFiltersProps) => {
    const query = useQuery();
    const initialOrderBy = orderByOptions.options.find((o) => o.key === query.get('sort'));
    const initialSearch = query.get('search') ?? '';
    const [searchInput] = useState(() => new Subject<string>());
    const DEBOUNCE_TIME = 250; // milliseconds

    const [orderBy, setOrderBy] = useState<DropOptionType | undefined>(initialOrderBy);
    const [searchTerm, setSearchTerm] = useState<string>(initialSearch);
    const [openDropdown, setOpenDropdown] = useState('');

    useEffect(() => {
        const obj = {
            sort: orderBy?.key,
            search: searchTerm,
        };
        UriHelper.addParametersToUri(obj);

        onFilter({
            keywords: searchTerm,
            orderBy: orderBy?.value,
        });
        if (searchTerm) {
            Analytics.Event('Explore', 'Changed search term', searchTerm);
        }
    }, [searchTerm, orderBy, onFilter]);

    useEffect(() => {
        const subscription = searchInput
            .pipe(
                filter((value) => value.length >= 0),
                debounceTime(DEBOUNCE_TIME),
                distinctUntilChanged(),
                switchMap((value) => (value.length >= 0 ? Promise.resolve<string>(value) : Promise.resolve<string>('')))
            )
            .subscribe((next) => {
                setSearchTerm(next);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [searchInput]);

    const filteredOrderByOptions = orderByOptions.options.filter((option) => {
        if (!isVersion2 && option.onlyV2) {
            return false;
        }
        return true;
    });

    const mobileDropdownContainer = (
        <ExploreMobileDropDownContainer>
            <DropDown
                name="OrderBy"
                options={filteredOrderByOptions}
                title={orderByOptions.title}
                initial={initialOrderBy ? [initialOrderBy] : [orderByOptions.options[0]]}
                handleSelectedOptions={(order) => setOrderBy(order[0])}
                isRadio={true}
                isOpen={openDropdown === 'OrderBy'}
                handleOpen={() => setOpenDropdown('OrderBy')}
                icons={{
                    light: orderByOptions.icons.light,
                    dark: orderByOptions.icons.dark,
                }}
            />
        </ExploreMobileDropDownContainer>
    );

    const dropdownContainers = (
        <ExploreDropDownContainer>
            <DropDown
                name="OrderBy"
                options={filteredOrderByOptions}
                title={orderByOptions.title}
                initial={initialOrderBy ? [initialOrderBy] : [orderByOptions.options[0]]}
                handleSelectedOptions={(order) => setOrderBy(order[0])}
                isRadio={true}
                isOpen={openDropdown === 'OrderBy'}
                handleOpen={() => setOpenDropdown('OrderBy')}
                icons={{
                    light: orderByOptions.icons.light,
                    dark: orderByOptions.icons.dark,
                }}
            />
        </ExploreDropDownContainer>
    );

    return (
        <ExploreSearchBarContainer>
            <ExploreFilterContainer>
                <ExploreSearch
                    handleKeywordSearch={(term) => searchInput.next(term)}
                    resetCategory={() => setOpenDropdown('')}
                    searchTerm={initialSearch}
                />
            </ExploreFilterContainer>
            {isMobileVersion ? (
                <React.Fragment>{mobileDropdownContainer}</React.Fragment>
            ) : (
                <React.Fragment>{dropdownContainers}</React.Fragment>
            )}
        </ExploreSearchBarContainer>
    );
};

export default ExploreFilters;

const ExploreSearchBarContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const ExploreFilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 60vw;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const ExploreDropDownContainer = styled.div`
    width: 60vw;
    display: flex;
    padding: 0px 20px 0px 10px;
    gap: 7px;
    align-items: center;
`;

const ExploreMobileDropDownContainer = styled.div`
    margin: 5px 0px;

    @media only screen and (max-width: 600px) {
        margin: 0px 11px 0px 0px;
    }
`;
