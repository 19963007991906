import MilitaryMarker from '../../../components/MapView/Annotations/MilitaryMarker/military-marker';
import { actionTypes as at } from './constants';

export const setMilitaryMarkersAction = (markers: MilitaryMarker[]) => {
    return {
        type: at.SET_MILITARY_MARKERS,
        payload: { markers: markers },
    };
};

export const setMilitaryMarkerSidcAction = (sidc?: string) => {
    return {
        type: at.SET_MILITARY_MARKER_SIDC,
        payload: sidc,
    };
};

export const setMilitaryMarkerAddAction = (marker: MilitaryMarker) => {
    return {
        type: at.SET_MILITARY_MARKER_ADD,
        payload: marker,
    };
};

export const setMilitaryMarkerUpdateAction = (marker: MilitaryMarker) => {
    return {
        type: at.SET_MILITARY_MARKER_UPDATE,
        payload: marker,
    };
};

export const setMilitaryMarkerDeleteAction = (marker: MilitaryMarker) => {
    return {
        type: at.SET_MILITARY_MARKER_DELETE,
        payload: marker,
    };
};
