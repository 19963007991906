import styled from 'styled-components';

interface NormalizeMarkdownViewerProps {
  text: string;
}

export const NormalizeMarkdownViewer = ({ text }: NormalizeMarkdownViewerProps) => {

  const sanitizedMarkdownText = (text: string) => {
    if (text) {
      // Remove all the markdown
      const replaceNonAlphanumericCharacters = text.replace(/\W/g, '_');
      const sanitizedText = replaceNonAlphanumericCharacters.replaceAll('_', ' ');
      return sanitizedText
    }

    return ''
  }

  return (
    <NormalizeMarkdownContainer>{sanitizedMarkdownText(text)}</NormalizeMarkdownContainer>
  )
}

const NormalizeMarkdownContainer = styled.p`
    color: inherit;
    font-size: inherit;
`;