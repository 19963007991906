import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import FreehandPolyline from './freehand-polyline';
import FreehandPolylineAnnotation from './freehand-polyline-annotation';
import FreehandPolylineBuilder from './freehand-polyline-builder';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { selectFreehandDraws } from '../../../../store/Annotations/Freehand/selectors';
import { setFreehandDrawAddAction, setFreehandDrawUpdateAction } from '../../../../store/Annotations/Freehand/actions';
import { useDrawToolUpdated } from '../use-draw-tool-updated';

const FreehandPolylineControl = (props: AnnotationControlProps) => {
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();
    const freehandDraws = useSelector(selectFreehandDraws);

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const onUpdateFreehandDraw = useDrawToolUpdated((freehandDraw: FreehandPolyline) => {
        dispatch(setFreehandDrawUpdateAction(freehandDraw));
    });

    const isInteractive = !props.locked && props.visible;

    return (
        <React.Fragment>
            <AnnotationControl
                active={isActive}
                disabled={props.disabled}
                layout="start"
                visible={props.visible}
                onClick={() => {
                    setIsActive((current) => !current);
                    lockAnnotationsEvent();
                }}
                iconDark="/assets/annotations/black-annotations/icon-draw-black.svg"
                iconLight="/assets/annotations/icon-draw.svg"
                name="Sketch"
                dataTestId="annotation-control-sketch"
            />

            {isActive ? (
                <FreehandPolylineBuilder
                    zIndex={currentZIndex}
                    onCreateFreehand={(freehand) => {
                        setIsActive(false);
                        dispatch(setFreehandDrawAddAction(freehand));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(freehand));
                        });
                        DrawToolAnalytics.add('sketch');
                    }}
                    onCancelBuild={() => {
                        setIsActive(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {!isInteractive &&
                freehandDraws.map((annotation) => (
                    <FreehandPolylineAnnotation
                        key={`
                            ${annotation.id}-locked-
                            ${selectedAnnotation ? selectedAnnotation.id : ''}-
                            ${annotation.options.color}-
                            ${annotation.options.dashArray}-
                            ${annotation.options.dashOffset}-
                            ${annotation.options.weight}-
                            ${annotation.zIndex}-
                            ${annotation.positions?.toString()}-
                            ${annotation.bounds?.toBBoxString()}-
                        `}
                        freehandPolyline={annotation}
                        isSelected={false}
                        isDisabled={true}
                        onUpdate={() => ''}
                        options={annotation.options}
                        positions={annotation.positions}
                    />
                ))}

            {isInteractive &&
                freehandDraws.map((annotation) => {
                    return (
                        <FreehandPolylineAnnotation
                            key={`
                                ${annotation.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${annotation.options.color}-
                                ${annotation.options.dashArray}-
                                ${annotation.options.dashOffset}-
                                ${annotation.options.weight}-
                                ${annotation.zIndex}-
                                ${annotation.positions?.toString()}-
                                ${annotation.bounds?.toBBoxString()}-
                            `}
                            onUpdate={onUpdateFreehandDraw}
                            freehandPolyline={annotation}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === annotation.id : false}
                            positions={annotation.positions}
                            options={annotation.options}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default FreehandPolylineControl;
