import { v4 as uuidv4 } from 'uuid';

export default class DeprecatedAnnotations {
    static exportToGeoJSONProject = (project: string) => {
        const annotationData = JSON.parse(project);
        // The old annotations have an 8 key we can use to distinguish them from the new annotations
        // If it has the 8 key, we bail and move on to handle the new format
        if (!annotationData['8']) {
            return null;
        }

        const geojson = {
            type: 'FeatureCollection',
            project: {
                viewport: [] as number[][],
            },
            features: [] as any[], // eslint-disable-line @typescript-eslint/no-explicit-any
        };

        if (annotationData.mapBounds) {
            const bounds = annotationData.mapBounds;
            const mapbounds: number[][] = [
                [bounds._northEast.lng, bounds._northEast.lat],
                [bounds._southWest.lng, bounds._southWest.lat],
            ];
            geojson.project.viewport = mapbounds;
        }

        let zoomLevel = 0;
        if (annotationData.zoomLevel) {
            zoomLevel = annotationData.zoomLevel;
        }

        for (const typeName in annotationData) {
            const typeData = annotationData[typeName];

            switch (typeName) {
                case 'ARROW':
                    {
                        const arrowFeatures = processAnnotationType(typeData, annotationArrow);
                        geojson.features.push(...arrowFeatures);
                    }
                    break;
                case 'CIRCLE':
                    {
                        const circleFeatures = processAnnotationType(typeData, annotationCircle);
                        geojson.features.push(...circleFeatures);
                    }
                    break;
                case 'LATLNG_TOOL':
                    {
                        const coordinateFeatures = processAnnotationType(typeData, annotationCoordinate);
                        geojson.features.push(...coordinateFeatures);
                    }
                    break;
                case 'RECTANGLE':
                    {
                        const rectangleFeatures = processAnnotationType(typeData, annotationRectangle);
                        geojson.features.push(...rectangleFeatures);
                    }
                    break;
                case 'TEXT_FIELDS':
                    {
                        const textFieldFeatures = processAnnotationType(typeData, annotationTextField, zoomLevel);
                        geojson.features.push(...textFieldFeatures);
                    }
                    break;
                case 'BOX_SELECT':
                    {
                        const polygonFeatures = processAnnotationType(typeData, annotationPolygon);
                        geojson.features.push(...polygonFeatures);
                    }
                    break;
                case 'RULER':
                    {
                        const rulerFeatures = processAnnotationType(typeData, annotationRuler);
                        geojson.features.push(...rulerFeatures);
                    }
                    break;
                default:
                    break;
            }
        }

        return JSON.stringify(geojson);
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processAnnotationType = (typeData: any, annotationFunction: any, zoom?: number) => {
    if (Array.isArray(typeData)) {
        const features = typeData
            .map((annotation) => annotationFunction(annotation, zoom))
            .filter((feature) => feature !== null);

        return features;
    }
    return [];
};

const annotationArrow = (arrow: {
    properties: {
        direction: { start: { lng: string; lat: string }; end: { lng: string; lat: string } };
        color: string;
    };
}) => {
    if (arrow) {
        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [arrow.properties.direction.start.lng, arrow.properties.direction.start.lat],
                    [arrow.properties.direction.end.lng, arrow.properties.direction.end.lat],
                ],
            },
            properties: {
                id: uuidv4(),
                annotationType: 'Arrow',
                color: arrow.properties.color,
                weight: 3,
            },
        };
    }
    return null;
};

const annotationCircle = (circle: {
    geometry: { coordinates: { lng: string; lat: string }; radius: number };
    properties: { color: string };
}) => {
    if (circle) {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [circle.geometry.coordinates.lng, circle.geometry.coordinates.lat],
            },
            properties: {
                id: uuidv4(),
                annotationType: 'Circle',
                radius: circle.geometry.radius,
                options: {
                    radius: circle.geometry.radius,
                    stroke: true,
                    color: circle.properties.color,
                    weight: 3,
                    opacity: 1,
                    lineCap: 'round',
                    lineJoin: 'round',
                },
                pattern: undefined,
            },
        };
    }
    return null;
};

const annotationRectangle = (rectangle: { geometry: { coordinates: number[][] }; properties: { color: string } }) => {
    if (rectangle) {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        rectangle.geometry.coordinates[0][0],
                        rectangle.geometry.coordinates[0][1],
                        rectangle.geometry.coordinates[0][2],
                        rectangle.geometry.coordinates[0][3],
                        rectangle.geometry.coordinates[0][4],
                    ],
                ],
            },
            properties: {
                id: uuidv4(),
                annotationType: 'Rectangle',
                options: {
                    color: rectangle.properties.color ? rectangle.properties.color : '#eed926',
                    smoothFactor: 1,
                    noClip: false,
                    stroke: true,
                    weight: 3,
                    opacity: 1,
                    lineCap: 'round',
                    lineJoin: 'round',
                    fill: true,
                    fillColor: '#eed926',
                    fillOpacity: 0.2,
                    fillRule: 'evenodd',
                    interactive: true,
                    bubblingMouseEvents: false,
                },
                pattern: undefined,
            },
        };
    }
    return null;
};

const annotationTextField = (
    textField: {
        bounds: { _southWest: { lng: string; lat: string }; _northEast: { lat: string; lng: string } };
        text: string;
        fontSize: string;
        inputFill: string;
        backgroundFill: string;
    },
    zoom?: number
) => {
    if (textField) {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [textField.bounds._southWest.lng, textField.bounds._northEast.lat],
                        [textField.bounds._northEast.lng, textField.bounds._northEast.lat],
                        [textField.bounds._northEast.lng, textField.bounds._southWest.lat],
                        [textField.bounds._southWest.lng, textField.bounds._southWest.lat],
                        [textField.bounds._southWest.lng, textField.bounds._northEast.lat],
                    ],
                ],
            },
            properties: {
                id: uuidv4(),
                annotationType: 'TextBox',
                text: textField.text,
                fontSize: textField.fontSize.replace('px', ''), // Remove px from font size for the calculation
                fontFamily: 'Manrope',
                fontSizeBase: 0,
                textColor: textField.inputFill,
                fillColor: textField.backgroundFill,
                fillOpacity: 1,
                padding: 0,
                zoomBase: zoom ? (zoom + 0.2).toFixed(2) : 0, // Add 0.2 to zoom level to give some visual padding
                zIndex: 403,
            },
        };
    }
    return null;
};

const annotationRuler = (ruler: { geometry: { coordinates: number[] } }) => {
    if (ruler) {
        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [ruler.geometry.coordinates[0], ruler.geometry.coordinates[1]],
            },
            properties: {
                id: uuidv4(),
                annotationType: 'Ruler',
                options: {
                    color: '#EED926',
                },
            },
        };
    }
    return null;
};

const annotationCoordinate = (coordinate: { coordinates: { lng: string; lat: string } }) => {
    if (coordinate) {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [coordinate.coordinates.lng, coordinate.coordinates.lat],
            },
            properties: {
                id: uuidv4(),
                annotationType: 'Coordinate',
            },
        };
    }
    return null;
};

const annotationPolygon = (polygon: { geometry: { coordinates: { lng: string; lat: string } } }) => {
    if (polygon) {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: polygon.geometry.coordinates,
            },
            properties: {
                id: uuidv4(),
                annotationType: 'Polygon',
                color: '#EED926',
                fill: true,
                fillColor: '#EED926',
                fillOpacity: 0.2,
                pattern: undefined,
            },
        };
    }
    return null;
};
