import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import SoarModal, { StyledModalBody } from '../../Shared/soar-modal';
import { StyledButton } from '../../Shared/styled-button';
import { arrayShuffle } from '../../../lib/array-util';
import StorageHelper from '../../../lib/storage-helper';
import UserHelper from '../../../lib/user-helper';

import ApiAnalytics from '../../../api/api-analytics';
import { selectMyProfile } from '../../../store/Account/selectors';

const MapCategories = [
    'Geological maps',
    'Topographic and elevation maps',
    'Historical maps',
    'Satellite and aerial imagery',
    'Environmental maps',
    'Weather maps',
    'War and conflict maps',
];

const AnalyticsSurveyCode = 'SurveyMapsV1';
const StrorageSurveyId = 'surveyMapsV1';
const OldStorageSurveyId = '__mapSurvey001';

const DELAY_BEFORE_SURVEY = 30 * 1000; // 30 seconds

const MapCategorySurvey = () => {
    const mapCategories: string[] = MapCategories;
    const userProfile = useSelector(selectMyProfile);

    const [showSurvey, setShowSurvey] = useState(false);
    const [mapCategory, setMapCategory] = useState('');
    const [surveyError, setSurveyError] = useState('');

    const showTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        arrayShuffle(mapCategories);

        StorageHelper.remove(OldStorageSurveyId); // remove old survey storage

        if (userProfile && userProfile.analyticAnswers && userProfile.analyticAnswers[StrorageSurveyId]) {
            UserHelper.setCustomCookie(StrorageSurveyId, '1');
            return;
        }

        if (UserHelper.getCustomCookie(StrorageSurveyId)) {
            return;
        }

        showTimeout.current = setTimeout(() => {
            setShowSurvey(true);
        }, DELAY_BEFORE_SURVEY);

        return () => {
            if (showTimeout.current) {
                clearTimeout(showTimeout.current);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userProfile) {
            if (userProfile && userProfile.analyticAnswers && userProfile.analyticAnswers[StrorageSurveyId]) {
                UserHelper.setCustomCookie(StrorageSurveyId, '1');

                if (showTimeout.current) {
                    clearTimeout(showTimeout.current);
                    showTimeout.current = null;
                }
                return;
            }
        }
    }, [userProfile]);

    useEffect(() => {
        setSurveyError('');
    }, [mapCategory]);

    const handleSubmit = () => {
        if (mapCategory === '') {
            setSurveyError('Please select a category');
            return;
        }

        ApiAnalytics.postAnalyticsEvent(AnalyticsSurveyCode, 'Answer', mapCategory)
            .then(() => {
                UserHelper.setCustomCookie(StrorageSurveyId, '1');

                toast.dark('Thank you for your feedback!');
            })
            .finally(() => {
                setShowSurvey(false);
            });
    };

    return (
        <MapCategorySurveyModal isOpen={showSurvey} width="730px">
            <MapCategorySurveyBody className="body">
                <Title>One quick question before you dive into your daily dose of Soar</Title>
                <SurveyText>What kind of maps do you want to see more of?</SurveyText>
                <SurveyOptions>
                    {mapCategories.map((category) => (
                        <SurveyOption key={category}>
                            <SurveyInput
                                name="map-category"
                                type="radio"
                                value={category}
                                onClick={(_) => setMapCategory(category)}
                            />{' '}
                            {category}
                        </SurveyOption>
                    ))}
                </SurveyOptions>
                {surveyError && <SurveyError>{surveyError}</SurveyError>}
                <SurveySubmit onClick={() => handleSubmit()}>Submit</SurveySubmit>
                <SurveyText>Thank you for helping us build the world's new atlas!</SurveyText>
            </MapCategorySurveyBody>
        </MapCategorySurveyModal>
    );
};

export default MapCategorySurvey;

const MapCategorySurveyModal = styled(SoarModal)`
    background-color: ${(props) => props.theme.color.white};

    * {
        color: ${(props) => props.theme.color.black};
    }

    .modal-content {
        border: none;
    }
`;

const MapCategorySurveyBody = styled(StyledModalBody)`
    background: url('/assets/emojis/nerd-face-smirk.svg') no-repeat 1rem 1rem;
    background-size: 30px auto;
    padding-left: calc(1rem + 50px);
`;

const Title = styled.h4`
    font-size: ${(props) => props.theme.fontSize.xlarge};
    font-weight: 700;
    text-align: left;
    line-height: 32px;

    margin: 0px 0px 20px;
`;

const SurveyText = styled.p`
    font-size: ${(props) => props.theme.fontSize.xlarge};
    font-style: italic;
    text-align: left;
    line-height: 32px;
    color: ${(props) => props.theme.color.black} !important;

    margin: 0px 0px 20px;
`;

const SurveyOptions = styled.div`
    display: flex;
    flex-direction: column;
`;

const SurveyOption = styled.label`
    height: 32px;
    line-height: 32px;
`;

const SurveyInput = styled.input`
    margin-right: 4px;
`;

const SurveyError = styled.p`
    color: ${(props) => props.theme.color.red} !important;
    font-style: italic;
`;

const SurveySubmit = styled(StyledButton)`
    margin: 30px 0;
`;
