import { LatLngBounds } from 'leaflet';
import GeoUtil from '../lib/geo-util';
import Api from './api';
import ApiUser from './api-user';
import { CountryInfo, CSWCollectionRequestCost, Industry } from './model';

// TODO: Receive these from the backend
type SupplierCode = 'CSW' | 'CGSTL' | '21AT' | 'SIIS' | 'SKYM50' | 'UNKNOWN';

// TODO: Receive these from the backend
// NT50 = New Collect 50cm
// AC50 = Archive 50cm
// A75 = Archive 75cm
// A100 = Archive 100cm
type ProductCode = 'NC50' | 'A50' | 'A75' | 'A100';

// TODO: Abstract data type with concrete suppliers
export interface SatelliteArchiveImageryDTO {
    cloud: number;
    date: Date;
    description: string;
    geometryWKT: string;
    id: string;
    previewUrl: string;
    product: string;
    resolutionInCm: string;
    rollAngle: string;
    supplier: string;
}

export interface SatelliteArchiveCostDTO {
    archiveSelectedAreaInKm: number;
    totalPrice: number;
    minAreaInKm: number;
    totalTax: number;
    voucherValueUsed: number;
    maxAreaInKm: number;
    countryCode: string;
    minLengthOrWidthInKm: number;
    pricePerKm: number;
    currency: string;
    currencyRate: number;
    effectiveAreaInKm: number;
    areaInKm: number;
}

export default class ApiSupplier extends Api {
    public static calculateArchivePrice(
        aoi: LatLngBounds,
        scenes: string[],
        supplierCode: SupplierCode,
        productCode: ProductCode,
        voucher?: string,
        countryCode?: string
    ): Promise<SatelliteArchiveCostDTO> {
        const wkt = GeoUtil.latLngBoundsToWKT(aoi);
        const archives = scenes.join(',');
        const params = {
            supplier: supplierCode,
            product: productCode,
            geometry: wkt,
            archives: archives,
            additionalTax: 0, // TODO: Ask about this additional tax field
            discount: 0,
            voucher: voucher || '',
            countryCode: countryCode || '',
        };

        return this.axios.get('/v1/archive/calculate_price', { params: params, cache: false }).then((res) => {
            return res.data as SatelliteArchiveCostDTO;
        });
    }

    public static createOrder(
        aoi: LatLngBounds,
        scenes: string[],
        supplierCode: SupplierCode,
        produceCode: ProductCode,
        stripeToken: string,
        userCompany: string,
        userIndustry: Industry,
        userCountry: CountryInfo,
        voucher?: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        const geometry = GeoUtil.latLngBoundsToWKT(aoi);
        const data = {
            voucher: voucher,
            geometry: geometry,
            archives: scenes.join(','),
            stripeToken: stripeToken,
            company: userCompany.toString(),
            industry: userIndustry.toString(),
            countryCode: userCountry.countryCode,
        };

        const url = `/v1/archive/order?supplier=${supplierCode}&product=${produceCode}`;
        return this.axios.post(url, data, { cache: false }).then((res) => {
            ApiUser.updateMyPoints();
            return res;
        });
    }

    public static searchArchive(
        aoi: LatLngBounds,
        supplierCode: SupplierCode,
        productCode: ProductCode
    ): Promise<SatelliteArchiveImageryDTO[]> {
        const wkt = GeoUtil.latLngBoundsToWKT(aoi);
        const params = {
            supplier: supplierCode,
            product: productCode,
            geometry: wkt,
            additionalTax: 0,
            discount: 0,
        };

        return this.axios
            .get('/v1/archive/search', { params: params })
            .then((res) => res.data)
            .then((data) => {
                return data.list.map((t) => t as SatelliteArchiveImageryDTO);
            })
            .then((results) => {
                // Sort by newest first
                return results.sort((a, b) => {
                    const dateA = new Date(a.date).getTime();
                    const dateB = new Date(b.date).getTime();
                    return dateB - dateA;
                });
            });
    }

    public static calculateTaskCost(
        aoi: LatLngBounds,
        supplierCode: SupplierCode,
        productCode: ProductCode,
        voucher?: string,
        countryCode?: string
    ): Promise<CSWCollectionRequestCost> {
        const wkt = GeoUtil.latLngBoundsToWKT(aoi);
        const params = {
            supplier: supplierCode,
            product: productCode,
            geometry: wkt,
            voucher,
            countryCode,
        };

        return this.axios
            .get('/v1/tasking/calculate_price', { params: params, cache: false })
            .then((res) => {
                return res.data as CSWCollectionRequestCost;
            })
            .then((data) => {
                data.totalPrice = data.totalPrice / 100;
                data.voucherValue = (data.voucherValue ?? 0) / 100;
                data.voucherValueUsed = (data.voucherValueUsed ?? 0) / 100;
                data.totalTax = (data.totalTax ?? 0) / 100;
                return data;
            });
    }

    public static createTaskOrder(
        aoi: LatLngBounds,
        supplierCode: SupplierCode,
        productCode: ProductCode,
        userCompany: string,
        userIndustry: Industry,
        userCountry: CountryInfo,
        stripeToken?: string,
        voucher?: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        const geometry = GeoUtil.latLngBoundsToWKT(aoi);
        const data = {
            stripeToken,
            voucher,
            geometry,
            company: userCompany.toString(),
            industry: userIndustry.toString(),
            country: userCountry.country,
            countryCode: userCountry.countryCode,
        };

        const url = `/v1/tasking/order?supplier=${supplierCode}&product=${productCode}`;
        return this.axios.post(url, data, { cache: false }).then((res) => {
            ApiUser.updateMyPoints();
            return res;
        });
    }
}
