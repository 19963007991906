import { useRef, useState } from 'react';
import styled from 'styled-components';
import ToolbarModal, { ToolbarButton } from '../ToolBar/toolbar-modal';
import Marker from './marker';
import MilitaryMarker from '../MilitaryMarker/military-marker';

interface MarkerResizeProps {
    marker: Marker | MilitaryMarker;
    onUpdate: (marker: Marker | MilitaryMarker) => void;
}

const SIZE_INCREMENT = 0.05;

const MarkerResize = (props: MarkerResizeProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toolbarButtonRef = useRef<HTMLButtonElement>(null);
    return (
        <Container>
            <ButtonToggle ref={toolbarButtonRef} isSelected={isOpen} onClick={() => setIsOpen(!isOpen)} title="Resize">
                <OrderIcon src="/assets/annotations/layer-resize.svg" alt="Resize" />
            </ButtonToggle>

            {isOpen && props.marker.zoomBase && (
                <ToolbarModal
                    toolbarButtonRef={toolbarButtonRef}
                    width={230}
                    title="Resize"
                    onClose={() => setIsOpen(false)}
                >
                    <ToolbarButton
                        key={'makeLarger'}
                        title="Make Marker Larger"
                        onClick={() => {
                            props.onUpdate({
                                ...props.marker,
                                zoomBase: props.marker?.zoomBase ? props.marker.zoomBase - SIZE_INCREMENT : 0,
                            });
                        }}
                    >
                        Make Marker Larger
                        <Icon className="fa fa-chevron-up" />
                    </ToolbarButton>
                    <ToolbarButton
                        key={'makeSmaller'}
                        title="Make Marker Smaller"
                        onClick={() => {
                            props.onUpdate({
                                ...props.marker,
                                zoomBase: props.marker?.zoomBase ? props.marker.zoomBase + SIZE_INCREMENT : 0,
                            });
                        }}
                    >
                        Make Marker Smaller
                        <Icon className="fa fa-chevron-down" />
                    </ToolbarButton>
                </ToolbarModal>
            )}
        </Container>
    );
};

export default MarkerResize;

const ButtonToggle = styled.button<{ isSelected: boolean }>`
    background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
    color: inherit;
    border: none;
    border-radius: 4px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 2px 0px 0px 0px;
    padding: 2px 2px;
    display: flex;
    flex-direction: row;
    height: 38px;
`;

const OrderIcon = styled.img`
    width: 28px;
    height: 28px;
    margin-top: 2px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 2px 0 0 2px;
`;

const Icon = styled.i`
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
`;
