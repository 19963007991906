import React from 'react';
import { useSelector } from 'react-redux';
import { selectImages } from '../../../store/Annotations/Images/selectors';
import ImageAnnotation from '../../MapView/Annotations/ImageTool/image-annotation';

const MobileImage = () => {
    const imageAnnotations = useSelector(selectImages);

    return (
        <React.Fragment>
            {imageAnnotations.map((imageAnnotation) => {
                return (
                    <ImageAnnotation
                        key={`
                        ${imageAnnotation.id}-
                        ${imageAnnotation?.bounds ? JSON.stringify(imageAnnotation.bounds) : ''}-
                        ${imageAnnotation.opacity}-
                        ${imageAnnotation.zIndex}
                    `}
                        isSelected={false}
                        imageAnnotation={imageAnnotation}
                        onUpdateImage={() => ''}
                        url={imageAnnotation.imageSrc}
                        bounds={imageAnnotation.bounds}
                        opacity={imageAnnotation.opacity}
                        isDisabled={true}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileImage;
