import React, { useState } from 'react';
import styled from 'styled-components';
import { SatelliteArchiveCostDTO, SatelliteArchiveImageryDTO } from '../../../../api/api-supplier';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import PriceWithCurrency from '../../../Shared/price-with-currency';
import { Button, Content, Title } from '../satellite-drawer-styles';
import SatelliteOrderVoucherInput from './satellite-order-voucher-input';

interface SatelliteOrderPurchaseDetailsProps {
    isLoading: boolean;
    isVoucherVisible: boolean;
    onConfirm: () => void;
    onEnterVoucher: (voucher: string) => void;
    onResetError: () => void;
    selectedFeatures: SatelliteArchiveImageryDTO[];
    cost: SatelliteArchiveCostDTO | undefined;
    error: Error | undefined;
}

const FormattedPriceWithCurrency: React.FC<{ currency: string; amount: number }> = (props) => {
    const audUS = props.currency.toLowerCase() === 'aud';
    return (
        <FormattedPrice>
            <PriceLabel>
                <PriceWithCurrency currency={props.currency} value={props.amount} />
            </PriceLabel>
            {audUS && <GST> Inc GST</GST>}
        </FormattedPrice>
    );
};

const SatelliteOrderPurchaseDetails = (props: SatelliteOrderPurchaseDetailsProps) => {
    const { isLoading, cost } = props;
    const [showVoucherInput, setShowVoucherInput] = useState(false);
    const areaInKmString = cost ? `${cost.areaInKm}km²` : '--km²';
    const percentageCoveredString = cost ? `${Math.round((cost.effectiveAreaInKm / cost.areaInKm) * 100)}%` : '--%';

    return (
        <ContentContainer>
            <Title>Your order summary</Title>
            <CollectionDetails>
                <CollectionItems>
                    <p>Total Area</p>
                    <p>{areaInKmString}</p>
                </CollectionItems>
                <CollectionCostDivider />
                <CollectionItems>
                    <p>Pixel Resolution</p>
                    <p>{props.selectedFeatures[0].resolutionInCm}cm/pixel</p>
                </CollectionItems>
                <CollectionCostDivider />
                <CollectionItems>
                    <p>Number of scenes</p>
                    <p>{props.selectedFeatures.length}</p>
                </CollectionItems>
                <CollectionCostDivider />
                <CollectionItems>
                    <p>Total Coverage</p>
                    <p>{percentageCoveredString}</p>
                </CollectionItems>
                <CollectionCostDivider />
                {!isLoading && cost && !!cost.voucherValueUsed && (
                    <>
                        <CollectionItems>
                            <p>Voucher Value</p>
                            <p>
                                <PriceWithCurrency currency={cost.currency} value={cost.voucherValueUsed} />
                            </p>
                        </CollectionItems>
                        <CollectionCostDivider />
                    </>
                )}
                <CollectionItems>
                    <p>Total Price</p>
                    <CollectionCost>
                        {!isLoading && cost && cost.currency ? (
                            <FormattedPriceWithCurrency currency={cost.currency} amount={cost.totalPrice} />
                        ) : (
                            <NoCost>$---</NoCost>
                        )}
                    </CollectionCost>
                </CollectionItems>
            </CollectionDetails>
            <CollectionButtonContainer>
                {props.isVoucherVisible ? (
                    <VoucherButton onClick={() => setShowVoucherInput(true)}>I HAVE A VOUCHER</VoucherButton>
                ) : null}

                <Button
                    onClick={() => {
                        props.onConfirm();
                        Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked to confirm order');
                        Analytics.Conversion(ConversionEvent.CONFIRM_ORDER);
                    }}
                    disabled={isLoading || !cost}
                >
                    Confirm order
                </Button>
            </CollectionButtonContainer>

            <SatelliteOrderVoucherInput
                isLoading={isLoading}
                toggle={() => {
                    if (showVoucherInput) {
                        props.onResetError();
                    }
                    setShowVoucherInput(!showVoucherInput);
                }}
                isOpen={showVoucherInput}
                handleSubmitCoupon={props.onEnterVoucher}
                couponError={props.error ? `${props.error.message.replace('400: ', '')}` : ''}
                voucherDetails={cost}
            />
        </ContentContainer>
    );
};

export default SatelliteOrderPurchaseDetails;

const CollectionDetails = styled.div`
    font-size: 16px;
`;

const CollectionItems = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 8px;

    p {
        color: white;
        margin: 0;
    }

    p:nth-child(2) {
        color: rgba(255, 255, 255, 0.6);
    }
`;

const CollectionCost = styled.div`
    div {
        color: #eed926 !important;
        font-size: 16px !important;
    }
`;

const VoucherButton = styled.button`
    background-color: transparent !important;
    color: white !important;
    border: 1px solid white !important;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    border-radius: 4px;
`;

const CollectionButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;

    ${Button} {
        margin: 10px 0;
    }
`;

const CollectionCostDivider = styled.div`
    height: 1px;
`;

const GST = styled.div`
    font-size: 1.2rem;
    margin-left: 5px;
`;
const NoCost = styled.div`
    color: #eed926;
    font-size: 16px;
`;

const FormattedPrice = styled.div`
    display: flex;
    flex-direction: row;
`;

const PriceLabel = styled.div`
    font-size: 16px;
`;

const ContentContainer = styled(Content)`
    height: calc(100vh - 175px);
    overflow: auto;
`;
