import { LatLng, Map } from 'leaflet';
import React, { useMemo, useEffect, useRef } from 'react';
import { MapContainer, Polygon } from 'react-leaflet';
import { wktPolygonToLatLngArray } from '../../../../lib/geo-util';
import Basemaps from '../../../MapView/basemaps';
import styled from 'styled-components';
import { useWindowSize } from '@react-hook/window-size';

const MinimapControl = ({ position }: { position: LatLng[] }) => {
    const minimapRef = useRef<Map>(null);
    const windowSize = useWindowSize();
    const mapContainerSize = { width: 465, height: windowSize[1] > 900 ? 400 : 200 };

    const calculatedPositionCenter = (positions: LatLng[]): LatLng => {
        const latSum = positions?.reduce((sum, pos) => sum + pos.lat, 0);
        const lngSum = positions?.reduce((sum, pos) => sum + pos.lng, 0);
        return new LatLng(latSum / positions.length, lngSum / positions.length);
    };

    useEffect(() => {
        const fitBoundsWithPadding = () => {
            if (minimapRef?.current && position?.length) {
                const map = minimapRef.current;
                const mapSize = map.getSize();
                const padding = Math.min(mapSize.x, mapSize.y) * 0.1; // 10% padding
                const bounds = position.map((latLng) => [latLng.lat, latLng.lng] as [number, number]);
                if (!bounds?.length || !padding) return;
                requestAnimationFrame(() => {
                    map.fitBounds(bounds, {
                        paddingTopLeft: [padding, padding],
                        paddingBottomRight: [padding, padding],
                    });
                });
            }
        };
        setTimeout(() => {
            fitBoundsWithPadding();
        }, 100);
    }, [position]);

    const minimap = useMemo(
        () => (
            <MapContainer
                ref={minimapRef}
                center={calculatedPositionCenter(position) || [0, 0]}
                zoom={1}
                dragging={true}
                doubleClickZoom={true}
                scrollWheelZoom={true}
                attributionControl={false}
                zoomControl={false}
                style={{ width: mapContainerSize.width, height: mapContainerSize.height }}
            >
                <Basemaps />
                <Polygon
                    key={`${position.toString()}`}
                    positions={position}
                    weight={2}
                    color={'#EED926'}
                    fillColor={'rgba(238, 218, 38, 0.5)'}
                />
            </MapContainer>
        ),
        [mapContainerSize.height, mapContainerSize.width, position]
    );

    return minimap;
};

interface SidedrawerLocationMapProps {
    position?: string;
}

const SideDrawerLocationMap = ({ position }: SidedrawerLocationMapProps) => {
    if (!position) return <React.Fragment />;

    const bounds = wktPolygonToLatLngArray(position) as LatLng[];

    return (
        <MiniMapContainer>
            <MinimapControl position={bounds} />
        </MiniMapContainer>
    );
};

export default SideDrawerLocationMap;

const MiniMapContainer = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .leaflet-bottom.leaflet-right .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle {
        display: none;
    }
`;
