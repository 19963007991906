import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L from 'leaflet';
import PolygonPath from '../Polygon/polygon';
import turfArea from '@turf/area';
import { Polygon as TurfPolygon } from '@turf/helpers';
import { formatImperialAreaString, formatMetricAreaString } from './measurement-util';
import { MeasurementUnits } from './measurement-toolbar-units';
import { MeasurementLabelColor } from './measurement-toolbar-label-color';

interface PolygonAnnotationProps extends L.MarkerOptions {
    polygon: PolygonPath;
    children?: React.ReactNode;
}

const areaSVGLabel = (
    areaText: string,
    labelColor: MeasurementLabelColor,
    bounds: L.LatLngBounds,
    context: LeafletContextInterface
): SVGSVGElement => {
    const topLeft = context.map.latLngToLayerPoint(bounds.getNorthWest());
    const bottomRight = context.map.latLngToLayerPoint(bounds.getSouthEast());
    const width = bottomRight.x - topLeft.x;
    const height = bottomRight.y - topLeft.y;

    const backgroundColor = 'transparent';
    const textColor = labelColor;
    const strokeColor = labelColor === 'black' ? 'white' : 'black';
    const fontSize = `1.6rem`;

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    svg.setAttribute('z-index', '1000');
    svg.setAttribute('fill', backgroundColor);
    svg.style.backgroundColor = backgroundColor;

    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.setAttribute('x', '50%');
    textElement.setAttribute('y', '50%');
    textElement.setAttribute('fill', textColor);
    textElement.setAttribute('font-size', fontSize);
    textElement.setAttribute('font-weight', 'bold');
    textElement.setAttribute('font-family', 'Manrope');
    textElement.setAttribute('text-anchor', 'middle');
    textElement.setAttribute('dominant-baseline', 'central');
    textElement.setAttribute('stroke', strokeColor);
    textElement.setAttribute('stroke-width', '0.25px');

    textElement.textContent = areaText;

    svg.appendChild(textElement);

    return svg;
};

export const calculateAreaInSquareMetersWithTurfArea = (positionTuple: [number, number][]): number => {
    const turfPolygon: TurfPolygon = {
        type: 'Polygon',
        coordinates: [positionTuple],
    };
    const areaInMetersSquared = turfArea(turfPolygon);
    return areaInMetersSquared;
};

export const createMeasurementLabelByUnits = (area: number, units: string): string => {
    let areaText: string;
    if (units === 'imperial') {
        const areaInFeetSquared = area * 10.7639;
        areaText = formatImperialAreaString(areaInFeetSquared);
    } else {
        areaText = formatMetricAreaString(area);
    }
    return areaText;
};

export const createLabelBounds = (positions: L.LatLng[]): L.LatLngBounds => {
    const positionsTuple = positions.map((t) => [t.lat, t.lng] as [number, number]);
    const bounds = new L.LatLngBounds(positionsTuple);
    return bounds;
};

export const createAreaLabel = (
    positions: L.LatLng[],
    units: MeasurementUnits,
    labelColor: MeasurementLabelColor,
    paneId: string,
    context: LeafletContextInterface
) => {
    // Geojson coordinates are [lng, lat] so we need to reverse the order of the coordinates
    const positionsTupleReversed = positions.map((t) => [t.lng, t.lat] as [number, number]);

    // Use the position as a latLng or the label will be in the wrong place, needs reviewing
    const bounds = createLabelBounds(positions);

    const area = calculateAreaInSquareMetersWithTurfArea(positionsTupleReversed);
    const areaText = createMeasurementLabelByUnits(area, units);
    const svg = areaSVGLabel(areaText, labelColor, bounds, context);
    const svgElement = createElementObject<L.SVGOverlay, PolygonAnnotationProps>(
        new L.SVGOverlay(svg, bounds, {
            interactive: false,
            zIndex: 1000,
            pane: paneId,
            className: 'leaflet-svg-area-label',
        }),
        context
    );

    return svgElement;
};
