import { LatLng, LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import UriHelper from '../../lib/uri-helper';
import {
    actionFlyToInvalidate,
    actionFlyToZoom,
    actionSetLeafletMap,
    actionSetMapBounds,
    actionSetMapZoom,
} from '../../store/App/actions';
import {
    selectFlyToMapPosition,
    selectMapBounds,
    selectMapZoom,
    selectShouldMapUpdatePositionAtZoomLevel,
    selectShouldMapUpdatePositionImmediately,
} from '../../store/App/selectors';

// Level to zoom to when focusing on a point
const FLYTO_ZOOM_LEVEL = 16;

// Force the map back to these bounds while panning
const WORLD_DRAG_REBOUND_BOUNDS: [number, number][] = [
    [-89.98155760646617, -260],
    [89.99346179538875, 240],
];

const MapViewDispatcher = () => {
    const mapZoomLevel = useSelector(selectMapZoom);
    const mapBounds = useSelector(selectMapBounds);
    const mapPosition = useSelector(selectFlyToMapPosition);
    const mapPositionUpdatesWithZoomLevel = useSelector(selectShouldMapUpdatePositionAtZoomLevel);
    const mapPositionUpdatesImmediately = useSelector(selectShouldMapUpdatePositionImmediately);

    const dispatch = useDispatch();

    const map = useMap();

    // We prevent the map from wrapping (below) however in Hawaii it's possible to
    // view the map on both sides of the Anti-meridian eg longitude 200 && -150
    // which causes "No maps" and "No sentinel results" bugs like #3039
    // This wraps the viewport back to world bounds -180 .. 180
    const fixAntiMeridianIfRequired = useCallback(() => {
        if (map) {
            const center = map.getCenter();
            const zoom = map.getZoom();

            if (zoom >= 5) {
                const wrappedCenter = center.wrap();

                if (!wrappedCenter.equals(center)) {
                    flyToPosition(wrappedCenter, zoom, true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (map) {
            // Set an initial position parameter if not already set.
            // Prevents a bug where the user opens a listing without moving the map, then pressing back
            if (!UriHelper.tryGetParam('pos')) {
                const zoom = Number(map.getZoom().toFixed(2));
                dispatch(actionSetMapZoom(zoom));
                const center = map.getCenter();
                const value = center.lat.toString() + ',' + center.lng.toString() + ',' + zoom;
                UriHelper.addParameterToUri('pos', value);
            }

            dispatch(actionSetLeafletMap(map));

            // Prevent the map from wrapping by forcing in to pan inside world bounds
            map.on('drag', () => {
                map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            });

            map.on('zoomend', () => {
                map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
                fixAntiMeridianIfRequired();

                const zoom = Number(map.getZoom().toFixed(2));
                dispatch(actionSetMapZoom(zoom));
                const center = map.getCenter();
                const value = center.lat.toString() + ',' + center.lng.toString() + ',' + zoom;
                UriHelper.addParameterToUri('pos', value);
                return;
            });

            map.on('moveend', () => {
                dispatch(actionSetMapBounds(map.getBounds()));
                fixAntiMeridianIfRequired();
                return;
            });

            if (!mapZoomLevel) {
                const zoom = Number(UriHelper.tryGetParam('pos')?.split(',')[2]) || map.getZoom();
                dispatch(actionSetMapZoom(zoom));
            }

            if (!mapBounds) {
                const bounds = map.getBounds();
                dispatch(actionSetMapBounds(bounds));
            }

            map.attributionControl.setPrefix('');
        }

        return () => {
            dispatch(actionSetMapZoom(undefined));
            dispatch(actionSetMapBounds(undefined));
        };

        // Only trigger on map
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    useEffect(() => {
        if (map) {
            if (mapPosition instanceof LatLng) {
                const position = mapPosition as LatLng;
                const zoomLevel = mapPositionUpdatesWithZoomLevel ? mapPositionUpdatesWithZoomLevel : FLYTO_ZOOM_LEVEL;
                const immediately = mapPositionUpdatesImmediately ? true : false;
                flyToPosition(position, zoomLevel, immediately);
            } else if (mapPosition instanceof LatLngBounds) {
                const bounds = mapPosition as LatLngBounds;
                const immediately = mapPositionUpdatesImmediately ? true : false;
                flyToBounds(bounds, immediately);
            } else if (mapPositionUpdatesWithZoomLevel) {
                flyToZoom(mapPositionUpdatesWithZoomLevel);
                dispatch(actionFlyToZoom(undefined));
            }
        } else {
            console.error('ERROR: Attempted flyTo before map was available');
        }

        return () => {
            dispatch(actionFlyToInvalidate());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapPosition, mapPositionUpdatesImmediately, mapPositionUpdatesWithZoomLevel, dispatch]);

    const flyToPosition = (position: LatLng, zoomLevel: number, immediately: boolean) => {
        if (map) {
            if (immediately) {
                map.setView(position, zoomLevel, { animate: false });
            } else {
                map.flyTo(position, zoomLevel);
            }
        }
    };

    const flyToBounds = (bounds: LatLngBounds, immediately: boolean) => {
        if (map) {
            if (immediately) {
                map.fitBounds(bounds, { animate: false });
            } else {
                map.flyToBounds(bounds);
            }
        }
    };

    const flyToZoom = (zoom: number) => {
        if (map) {
            map.setZoom(zoom);
        }
    };

    return <React.Fragment />;
};

export default MapViewDispatcher;
