export const actionTypes = {
    DRAW_PROJECTS_FETCH_SUCCESS: 'DRAW_PROJECTS/FETCH_SUCCESS',
    DRAW_PROJECTS_SHARED_FETCH_SUCCESS: 'DRAW_PROJECTS/SHARED_FETCH_SUCCESS',

    DRAW_PROJECT_UPDATE: 'DRAW_PROJECT/UPDATE_PROJECT',
    DRAW_PROJECT_UPDATING: 'DRAW_PROJECT/UPDATING_PROJECT',

    DRAW_PROJECT_DELETE: 'DRAW_PROJECT/DELETE_PROJECT',
    DRAW_PROJECT_DELETING: 'DRAW_PROJECT/DELETING_PROJECT',
};
