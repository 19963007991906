import styled from 'styled-components';
import { PlusDetailsSection } from '.';

export const PlusDetails = () => {
    return (
        <PlusDetailsContainer>
            <PlusDetailsSection
                title="WHY WAS SOAR+ CREATED?"
                text="Because it was too hard and expensive for people to get their map and imagery content to others. Existing solutions lack simplicity and scalability. We wanted to do something different, and built it from scratch!"
                img="/assets/plus-images/plus-details/get-ready-for-an-adventure.png"
                lightboxImage="/assets/plus-images/plus-details/Maps.jpg"
                imageReverse={false}
            />
            <PlusDetailsSection
                title="HOW DOES SOAR+ WORK?"
                text="Soar+ works by processing and tiling all your ECW, GeoTIFF and JP2 files so they can be displayed quickly and efficiently, re-projected onto anywhere in the world, all inside your web browser. No need for any expensive GIS software or industry know-how. It' just drag, and drop!"
                img="/assets/plus-images/plus-details/satellite-imagery-on-the-fly.png"
                lightboxImage="/assets/plus-images/plus-details/Satellites_Imagery.jpg"
                imageReverse={true}
            />
            <PlusDetailsSection
                title="WHO CAN I SHARE MY CONTENT WITH?"
                text="Anyone! You can choose to share your maps and imagery either with a select group of people, or with the entire world. The choice is yours. You are always in control of who sees your maps."
                img="/assets/plus-images/plus-details/see-the-world-in-HD-with-skymap50.png"
                lightboxImage="/assets/plus-images/plus-details/Checks-and-d-n-d.jpg"
                imageReverse={false}
            />
            <PlusDetailsSection
                title="CAN I EXPORT TO GIS OR OTHER IMAGING PROCESSING SOFTWARE?"
                text="Of course! You can download and export your content to software such as ArcGIS. GlobalMapper, ERDAS, QGIS and even Adobe Photoshop."
                img="/assets/plus-images/plus-details/build-your-own-digital-atlas.png"
                lightboxImage="/assets/plus-images/plus-details/User_Profile.jpg"
                imageReverse={true}
            />
        </PlusDetailsContainer>
    );
};

const PlusDetailsContainer = styled.div`
    margin: auto;
    max-width: 1400px;
`;
