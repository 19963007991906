import styled from 'styled-components';
import { TransparentButton } from '../../../../Shared/styled-button';

interface StoryBuilderAddPageProps {
    onAddPage: () => void;
    onCopyPage: () => void;
}

const StoryBuilderAddPage = (props: StoryBuilderAddPageProps) => {
    return (
        <Container>
            <Button onClick={() => props.onAddPage()}>
                <i className="fa fa-plus" /> Add page
            </Button>
            <Button onClick={() => props.onCopyPage()}>
                <i className="fa fa-file" /> Copy page
            </Button>
        </Container>
    );
};

export default StoryBuilderAddPage;

const Container = styled.div`
    width: calc(100% - 16px);
    display: flex;
    flex-direction: row;
    border-radius: ${(props) => props.theme.borderRadius} !important;

    padding: 8px;
    gap: 8px;
`;

const Button = styled(TransparentButton)`
    text-transform: none;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    margin: 0;
    padding: 8px 2px;
    width: 100%;
    border: none !important;
    i {
        margin: 4px 6px;
    }
`;
