import styled from 'styled-components';

interface JoinCarouselProps {
    type: string;
}

const JoinCarousel = ({ type }: JoinCarouselProps) => {
    switch (type) {
        case 'Terrain':
            return (
                <ContentCarousel>
                    <ContentCarouselItem src="/assets/join-page/asset1-terrain.png" />
                    <ContentCarouselItem src="/assets/join-page/asset2-terrain.png" />
                    <ContentCarouselItem src="/assets/join-page/asset3-terrain.png" />
                </ContentCarousel>
            );
        case 'Climate':
            return (
                <ContentCarousel>
                    <ContentCarouselItem src="/assets/join-page/asset1-climate.png" />
                    <ContentCarouselItem src="/assets/join-page/asset2-climate.png" />
                    <ContentCarouselItem src="/assets/join-page/asset3-climate.png" />
                </ContentCarousel>
            );
        case 'US Aerial':
            return (
                <ContentCarousel>
                    <ContentCarouselItem src="/assets/join-page/us-aerial/asset1.png" />
                    <ContentCarouselItem src="/assets/join-page/us-aerial/asset2.png" />
                    <ContentCarouselItem src="/assets/join-page/us-aerial/asset3.png" />
                </ContentCarousel>
            );
        case 'AU Geology':
            return (
                <ContentCarousel>
                    <ContentCarouselItem src="/assets/join-page/au-geology/asset1.png" />
                    <ContentCarouselItem src="/assets/join-page/au-geology/asset2.png" />
                    <ContentCarouselItem src="/assets/join-page/au-geology/asset3.png" />
                </ContentCarousel>
            );
        default:
            return (
                <ContentCarousel>
                    <ContentCarouselItem src="/assets/join-page/asset1.png" />
                    <ContentCarouselItem src="/assets/join-page/asset2.png" />
                    <ContentCarouselItem src="/assets/join-page/asset3.gif" />
                </ContentCarousel>
            );
    }
};

export default JoinCarousel;

export const ContentCarousel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 50px 20px;
`;

export const ContentCarouselItem = styled.img`
    width: 30%;
    height: auto;
    max-height: 158px;
    object-fit: cover;
`;
