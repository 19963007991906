import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L, { LatLngBounds } from 'leaflet';
import { MarkerProps } from 'react-leaflet';
import { translateLatLngBounds } from '../Rectangle/rectangle-annotation-util';
import LayersUtil from '../layers-util';
import { nodeMarkerOptions } from '../Polygon/node-marker-handles';
import { dragRectangleOptions, dragRectangleHologramOptions } from './textbox';

interface TextBoxResizeElementProps {
    rectangleElement: Readonly<{ instance: L.Rectangle; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const createCornerDragMarker = (position: L.LatLng, cursorClass: string, context: LeafletContextInterface) => {
    const controlPane = LayersUtil.getControlPaneId(context.map);

    const markerElement = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, { ...nodeMarkerOptions, pane: controlPane }),
        context
    );

    markerElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), cursorClass);
    });

    markerElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), cursorClass);
    });

    return markerElement;
};

export const createTextBoxResizeElement = (props: TextBoxResizeElementProps, context: LeafletContextInterface) => {
    const controlPane = LayersUtil.getControlPaneId(context.map);

    const rectangleResizeLayer = new L.LayerGroup([], { pane: controlPane });
    const rectangleResizeLayerElement = createElementObject<L.LayerGroup>(rectangleResizeLayer, context);

    const dragRectangle = new L.Rectangle(props.rectangleElement.instance.getBounds(), {
        ...dragRectangleOptions,
        pane: controlPane,
    });
    const dragRectangleElement = createElementObject<L.Rectangle>(dragRectangle, context);

    const dragHologram = new L.Rectangle(props.rectangleElement.instance.getBounds(), {
        ...dragRectangleHologramOptions,
        pane: controlPane,
    });
    const dragHologramElement = createElementObject<L.Rectangle>(dragHologram, context);

    const northWestCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getNorthWest(),
        'leaflet-nw-resize',
        context
    );

    const northEastCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getNorthEast(),
        'leaflet-ne-resize',
        context
    );

    const southEastCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getSouthEast(),
        'leaflet-se-resize',
        context
    );
    const southWestCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getSouthWest(),
        'leaflet-sw-resize',
        context
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onTextBoxSizeChanged = (e: any) => {
        const newBounds = e.bounds;
        northEastCornerMarker.instance.setLatLng(newBounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(newBounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(newBounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(newBounds.getSouthEast());
        dragRectangleElement.instance.setBounds(newBounds);
        dragHologramElement.instance.setBounds(newBounds);
    };

    rectangleResizeLayerElement.instance.on('textbox-size-change', onTextBoxSizeChanged);

    const updateBounds = (newBounds: L.LatLngBounds, _: L.LeafletMouseEvent) => {
        const northEastPoint = context.map.latLngToContainerPoint(newBounds.getNorthEast());
        const northWestPoint = context.map.latLngToContainerPoint(newBounds.getNorthWest());
        const screenWidth = Math.abs(northEastPoint.x - northWestPoint.x);
        if (screenWidth < 10) {
            return;
        }

        northEastCornerMarker.instance.setLatLng(newBounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(newBounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(newBounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(newBounds.getSouthEast());
        dragRectangleElement.instance.setBounds(newBounds);
        dragHologramElement.instance.setBounds(newBounds);
        props.rectangleElement.instance.setBounds(newBounds);
    };

    const onDragNorthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getSouthWest());
        updateBounds(newBounds, e);
    };

    const onDragNorthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getSouthEast());
        updateBounds(newBounds, e);
    };

    const onDragSouthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getNorthWest());
        updateBounds(newBounds, e);
    };

    const onDragSouthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getNorthEast());
        updateBounds(newBounds, e);
    };

    const onCornerMarkerDragStart = () => {
        props.rectangleElement.instance.setStyle({ opacity: 0.66 });
    };

    const onCornerMarkerDragEnd = () => {
        props.rectangleElement.instance.setStyle({ opacity: 1 });
        props.rectangleElement.instance.fireEvent('resize', { bounds: props.rectangleElement.instance.getBounds() });
    };

    const onDragRectangleMouseOver = () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
    };

    const onDragRectangleMouseOut = () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
    };

    let lastLatLng: L.LatLng | undefined;
    const onDragRectangleMouseMove = (e: L.LeafletMouseEvent) => {
        if (lastLatLng) {
            const newBounds = translateLatLngBounds(props.rectangleElement.instance.getBounds(), lastLatLng, e.latlng);
            dragRectangleElement.instance.setBounds(newBounds);
            dragHologramElement.instance.setBounds(newBounds);
        }
    };

    const onDragRectangleMouseUp = (e: L.LeafletMouseEvent) => {
        lastLatLng = undefined;
        context.map.off('mousemove', onDragRectangleMouseMove);
        context.map.off('mouseup', onDragRectangleMouseUp);
        rectangleResizeLayerElement.instance.removeLayer(dragHologramElement.instance);
        updateBounds(dragRectangleElement.instance.getBounds(), e);
        props.rectangleElement.instance.fireEvent('resize', { bounds: dragRectangleElement.instance.getBounds() });
    };

    const onDragRectangleMouseDown = (e: L.LeafletMouseEvent) => {
        lastLatLng = e.latlng;
        context.map.on('mousemove', onDragRectangleMouseMove);
        context.map.on('mouseup', onDragRectangleMouseUp);
        rectangleResizeLayerElement.instance.addLayer(dragHologramElement.instance);
    };

    rectangleResizeLayerElement.instance.on('add', () => {
        rectangleResizeLayerElement.instance.addLayer(northEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(northWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(southEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(southWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(dragRectangleElement.instance);

        northEastCornerMarker.instance.on('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.on('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.on('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.on('drag', onDragSouthWestCornerMarker);

        northWestCornerMarker.instance.on('dragstart', onCornerMarkerDragStart);
        northEastCornerMarker.instance.on('dragstart', onCornerMarkerDragStart);
        southEastCornerMarker.instance.on('dragstart', onCornerMarkerDragStart);
        southWestCornerMarker.instance.on('dragstart', onCornerMarkerDragStart);

        northWestCornerMarker.instance.on('dragend', onCornerMarkerDragEnd);
        northEastCornerMarker.instance.on('dragend', onCornerMarkerDragEnd);
        southEastCornerMarker.instance.on('dragend', onCornerMarkerDragEnd);
        southWestCornerMarker.instance.on('dragend', onCornerMarkerDragEnd);

        dragRectangleElement.instance.on('mouseover', onDragRectangleMouseOver);
        dragRectangleElement.instance.on('mouseout', onDragRectangleMouseOut);

        dragRectangleElement.instance.on('mousedown', onDragRectangleMouseDown);

        dragRectangleElement.instance.bringToFront();
    });

    rectangleResizeLayerElement.instance.on('remove', () => {
        rectangleResizeLayerElement.instance.removeLayer(northEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(northWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(southEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(southWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(dragRectangleElement.instance);

        northEastCornerMarker.instance.off('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.off('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.off('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.off('drag', onDragSouthWestCornerMarker);

        dragRectangleElement.instance.off('mouseover', onDragRectangleMouseOver);
        dragRectangleElement.instance.off('mouseout', onDragRectangleMouseOut);
        dragRectangleElement.instance.off('mousedown', onDragRectangleMouseDown);

        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-nw-resize');
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-ne-resize');
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-se-resize');
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-sw-resize');
    });

    return rectangleResizeLayerElement;
};
