import { useState } from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import MapHelper from '../../../../../lib/map-helper';
import SoarHelper from '../../../../../lib/soar-helper';
import UserHelper from '../../../../../lib/user-helper';
import { selectMyProfile } from '../../../../../store/Account/selectors';
import DrawerHint from '../../../drawer-hint';
import { TransparentButton } from '../../../../Shared/styled-button';
import Analytics from '../../../../../lib/user-analytics';
import FileUtil from '../../../../../lib/file-util';

interface UploadMapChooseProps {
    onSelectFile: (file: File, worldFile: File | undefined) => void;
    handleSetError: (error: string) => void;
    error?: string;
}

const FILE_SIZE_1GB = 1073741824;
const FILE_SIZE_25GB = FILE_SIZE_1GB * 25;

const UploadMapChoose = (props: UploadMapChooseProps) => {
    const [rejectionError, setRejectionError] = useState<string | undefined>(undefined);
    const myProfile = useSelector(selectMyProfile);
    const isReputableUser = UserHelper.isReputableUser(myProfile);

    let MAX_FILE_SIZE: number = FILE_SIZE_1GB;
    if (!SoarHelper.isSoar()) {
        // Subdomain users can upload 25Gb files
        MAX_FILE_SIZE = FILE_SIZE_25GB;
    }

    if (isReputableUser) {
        // Reputable users can upload 25Gb files
        MAX_FILE_SIZE = FILE_SIZE_25GB;
    }

    const handleDrop = (files: File[]) => {
        if (files.length === 0) {
            props.handleSetError('Unsupported file type. Only .TIFF, .ECW, .JP2, .JPG, and .PNG files are accepted');
        } else if (files.length === 1) {
            props.onSelectFile(files[0], undefined);
            setRejectionError(undefined);
        } else if (files.length === 2) {
            const worldFile = files.find(
                (file) =>
                    file.name.endsWith('.jgw') ||
                    file.name.endsWith('.pgw') ||
                    file.name.endsWith('.wld') ||
                    file.name.endsWith('.tfw')
            );
            const mapFile = files.find(
                (file) =>
                    file.name.endsWith('.jpg') ||
                    file.name.endsWith('.png') ||
                    file.name.endsWith('.jpeg') ||
                    file.name.endsWith('.tif') ||
                    file.name.endsWith('.tiff')
            );
            if (worldFile && mapFile) {
                const worldFileBaseName = FileUtil.getBaseName(worldFile.name);
                const mapFileBaseName = FileUtil.getBaseName(mapFile.name);
                if (worldFileBaseName === mapFileBaseName) {
                    props.onSelectFile(mapFile, worldFile);
                    setRejectionError(undefined);
                } else {
                    props.handleSetError(
                        'The base name of the world file does not match the base name of the map file.'
                    );
                }
            } else {
                props.handleSetError('Unsupported file type. Only .TIFF, .JPG, and .PNG with files are accepted');
            }
        } else {
            props.handleSetError('Too many files selected. Please select only one file or image with a world file');
        }
    };
    const handleRejectedFile = (files: File[]) => {
        const isFileTooLarge = files.length > 0 && files[0].size >= MAX_FILE_SIZE;
        if (isFileTooLarge) {
            setRejectionError(
                `The map you are trying to upload is too large, maximum map size is ${MapHelper.formatBytes(
                    MAX_FILE_SIZE
                )}.`
            );
            return;
        }

        if (files.length !== 1) {
            setRejectionError('Only one file can be uploaded at a time');
            return;
        }

        const splitFileName = files[0].name.split('.');
        const typeFromName = splitFileName[splitFileName.length - 1];
        const fileType = files[0].type || typeFromName;

        const validFileTypes = ['image/tiff', 'image/tif', 'image/ecw'];

        if (!validFileTypes.includes(fileType)) {
            setRejectionError(`The file type ${fileType && `for ${fileType}`} is not supported`);
            return;
        }
    };

    return (
        <Content>
            {rejectionError || props.error ? (
                <DrawerHint error>{rejectionError ? rejectionError : props.error}</DrawerHint>
            ) : null}
            <Dropzone
                onDropRejected={handleRejectedFile}
                onDrop={handleDrop}
                maxSize={MAX_FILE_SIZE}
                accept=".tiff,.tif,.ecw,.jp2,image/jpeg, image/png, .jgw, .pgw, .wld, .tfw"
                style={{
                    margin: '8px',
                    border: '1px dashed rgba(255, 255, 255, 0.6)',
                    borderRadius: '6px',
                    padding: '12px',
                }}
                activeStyle={{
                    border: '1px dashed #EED926',
                }}
            >
                <UploadIcon />
                <DropzoneText>Drag and drop your file here</DropzoneText>
                <DropzoneAltText>
                    <i>or</i>
                </DropzoneAltText>
                <DropZoneButton>CHOOSE FILE</DropZoneButton>
            </Dropzone>
            <DropzoneInstructionText>
                Formats we currently support:
                <SupportedFormatsList>
                    <FormatItem>GeoTIFF</FormatItem>
                    <FormatItem>ECW</FormatItem>
                    <FormatItem>JP2</FormatItem>
                    <FormatItem>JPEG</FormatItem>
                    <FormatItem>PNG</FormatItem>
                    <FormatItem>Georeferenced Image</FormatItem>
                </SupportedFormatsList>
                <LearnMore
                    href="https://about.soar.earth/help.html"
                    target="_blank"
                    onClick={() => {
                        Analytics.Event('Side Drawer', `Clicked Upload Learn More`);
                    }}
                >
                    Learn more
                </LearnMore>
            </DropzoneInstructionText>
        </Content>
    );
};

export default UploadMapChoose;

const Content = styled.div`
    margin: 25px 0px;
    height: auto;
    cursor: pointer;
`;

const UploadIcon = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 100px;
    cursor: pointer;
    background-image: url('/assets/upload-icon.svg');
    margin: 20px auto;
`;

const DropZoneButton = styled(TransparentButton)`
    margin: 20px auto;
`;

const DropzoneText = styled.p`
    color: white;
    text-align: center;
    font-size: 16px;
    margin: 0;
`;

const DropzoneAltText = styled.p`
    color: white;
    text-align: center;
    font-size: 13px;
    margin: 0;
`;

const DropzoneInstructionText = styled.p`
    color: white;
    margin: 12px 8px;
    text-align: center;
`;

const LearnMore = styled.a`
    color: rgba(255, 255, 255, 0.67) !important;
    text-decoration: underline !important;
    cursor: pointer;
`;

const SupportedFormatsList = styled.ul`
    list-style-type: none;
    margin-left: 20px;
    padding-left: 0;
    margin: 5px 0px;
`;

const FormatItem = styled.li`
    color: rgba(255, 255, 255, 0.67);
`;
