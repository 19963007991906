import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { selectLoggedIn, selectMyProfile } from '../../../../store/Account/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { actionShowLoginDialog } from '../../../../store/App/actions';

interface ActiveCommentActionsProps {
    handleCommentDelete: () => void;
    handleCommentEdit: () => void;
    handleReportComment: () => void;
    handleModerateComment: () => void;
    commentUserId: string;
}

const ActiveCommentActions = (props: ActiveCommentActionsProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);

    const dispatch = useDispatch();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const clickedReportComment = () => {
        if (!isLoggedIn) {
            dispatch(actionShowLoginDialog(true));
        } else {
            props.handleReportComment();
        }
    };

    const isCommentOwner = props.commentUserId === myProfile?.userId;

    return (
        <React.Fragment>
            <CommentActionDropdown
                className="CommentActionDropdown"
                onClick={(e) => e.stopPropagation()}
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
            >
                <CommentActionDropdownToggle tag="span">
                    <i className="fa fa-ellipsis-h" />
                </CommentActionDropdownToggle>
                <CommentActionDropdownMenu>
                    {myProfile && myProfile.roles && myProfile.roles.includes('COMMENTS') ? (
                        <React.Fragment>
                            <CommentActionDropdownItem header>Mod Tools</CommentActionDropdownItem>
                            <CommentActionDropdownItem onClick={() => props.handleModerateComment()}>
                                Delete Comment
                            </CommentActionDropdownItem>
                            <CommentActionDropdownItem divider />
                        </React.Fragment>
                    ) : null}

                    {isCommentOwner ? (
                        <React.Fragment>
                            <CommentActionDropdownItem onClick={() => props.handleCommentEdit()}>
                                Edit
                            </CommentActionDropdownItem>

                            <CommentActionDropdownItem onClick={() => props.handleCommentDelete()}>
                                Delete
                            </CommentActionDropdownItem>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <CommentActionDropdownItem onClick={() => clickedReportComment()}>
                                Report
                            </CommentActionDropdownItem>
                        </React.Fragment>
                    )}
                </CommentActionDropdownMenu>
            </CommentActionDropdown>
        </React.Fragment>
    );
};

export default ActiveCommentActions;

const CommentActionDropdown = styled(Dropdown)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 18px;
    width: 18px;
    margin-right: 4px;
`;

const CommentActionDropdownToggle = styled(DropdownToggle)`
    color: white;
    padding-left: 0;
    margin-left: -5px;

    &:hover {
        color: #eed926;
    }

    &:after {
        display: block;
    }
`;

const CommentActionDropdownMenu = styled(DropdownMenu)`
    width: 120px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    margin-top: 12px;
    border-radius: 6px;
    max-height: 500px;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const CommentActionDropdownItem = styled(DropdownItem)`
    font-size: 1rem;
    color: white;

    &.dropdown-header {
        font-size: 16px;
    }

    &:hover {
        background: #eed926;
        color: #000;

        &.dropdown-header {
            background: none;
            color: white;
        }
    }

    &:focus {
        outline: none;
    }

    &.dropdown-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.8) !important;
    }
`;
