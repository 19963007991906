import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectMarkers } from '../../../../store/Annotations/Marker/selectors';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import Marker from './marker';
import MarkerSelect from './marker-select';
import MarkerSelectModal from './marker-select-modal';
import { MarkerIconName, getMarkerIcon } from './marker-util';
import ColorSelector from '../ToolBar/color-selector';
import MarkerResize from './marker-resize';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import {
    setMarkerIconAction,
    setMarkerColorAction,
    setMarkerUpdateAction,
    setMarkerDeleteAction,
} from '../../../../store/Annotations/Marker/actions';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';

const MarkerToolbar = () => {
    const [markerModalOpen, setMarkerModalOpen] = useState(false);
    const dispatch = useDispatch();
    const markers = useSelector(selectMarkers);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedMarker = markers.find((c) => c.id === selectedAnnotation?.id);

    const updateMarker = (marker: Marker) => {
        dispatch(setMarkerUpdateAction(marker));
        dispatch(setMarkerIconAction(marker.iconName as MarkerIconName));
    };

    if (!selectedMarker) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Marker Color:</ToolbarText>
            <ColorSelector
                title="Marker Color"
                onSelectColor={(color) => {
                    const newMarker = getMarkerIcon(selectedMarker.iconName as MarkerIconName, color);
                    updateMarker({
                        ...selectedMarker,
                        color: color,
                        options: {
                            ...selectedMarker.options,
                            ...newMarker.options,
                        },
                    });

                    dispatch(setMarkerColorAction(color));
                }}
                selectedColor={selectedMarker.color || '#EED926'}
                toggle={() => setMarkerModalOpen(false)}
            />
            <ToolbarDivider />

            <ToolbarText>Marker</ToolbarText>
            <MarkerSelect
                marker={selectedMarker}
                onUpdate={(marker) => {
                    const newMarker = {
                        ...selectedMarker,
                        iconName: marker.iconName,
                        options: marker.options,
                    };
                    updateMarker(newMarker);
                }}
            />
            <MarkerSelectModal
                marker={selectedMarker}
                onUpdate={updateMarker}
                open={markerModalOpen}
                setOpen={setMarkerModalOpen}
            />
            <ToolbarDivider />

            <ToolbarText>Resize</ToolbarText>
            <MarkerResize
                marker={selectedMarker}
                onUpdate={(marker: Marker) => {
                    const newMarker = {
                        ...selectedMarker,
                        zoomBase: marker.zoomBase,
                    };
                    updateMarker(newMarker);
                }}
            />
            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    DrawToolAnalytics.delete('marker');
                    dispatch(setMarkerDeleteAction(selectedMarker));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default MarkerToolbar;
