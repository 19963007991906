import { ListingDTO } from '../api/model';

const VIEWED_MAPS_KEY = 'viewed-maps';
const LAST_USED_DRAW_PROJECT_MAP = 'last-used-draw-project';

export interface ViewedMapSessionObject {
    id: number;
    title: string;
    author: string;
    datestamp: number;
}

export default class MapViewHistoryHelper {
    public static setLastUsedStoaryMap = (id: number, title: string, author: string) => {
        const item: ViewedMapSessionObject = {
            id: id,
            title: title,
            author: author,
            datestamp: new Date().getTime(),
        };
        localStorage.setItem(LAST_USED_DRAW_PROJECT_MAP, JSON.stringify(item));
    };

    public static getLastUsedStoaryMap = (): ViewedMapSessionObject | null => {
        const storage = localStorage.getItem(LAST_USED_DRAW_PROJECT_MAP);
        if (!storage) {
            return null;
        }

        try {
            const map: ViewedMapSessionObject = JSON.parse(storage);
            return map;
        } catch (err) {
            console.log(err);
            return null;
        }
    };

    public static deleteLastUsedStoaryMap = () => {
        localStorage.removeItem(LAST_USED_DRAW_PROJECT_MAP);
    };

    public static getViewedListingHistory = (): ViewedMapSessionObject[] => {
        const storage = localStorage.getItem(VIEWED_MAPS_KEY);
        if (!storage) {
            return [];
        }

        try {
            const maps: ViewedMapSessionObject[] = JSON.parse(storage);
            return maps;
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    static addMapToViewedMapsHistory = (viewedMap: ViewedMapSessionObject) => {
        const storedMaps = this.getViewedListingHistory().filter((t, i) => t.id !== viewedMap.id && i < 10);
        storedMaps.unshift(viewedMap);
        localStorage.setItem(VIEWED_MAPS_KEY, JSON.stringify(storedMaps));
    };

    public static removeMapToViewedMapsHistory = (id: number) => {
        const storedMaps = this.getViewedListingHistory().filter((t) => t.id !== id);
        localStorage.setItem(VIEWED_MAPS_KEY, JSON.stringify(storedMaps));
    };

    public static addListingToViewedMapsHistory = (viewedListing: ListingDTO) => {
        const item: ViewedMapSessionObject = {
            id: viewedListing.id,
            title: viewedListing.title,
            author: viewedListing.userName,
            datestamp: new Date().getTime(),
        };
        this.addMapToViewedMapsHistory(item);
    };
}
