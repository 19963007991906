import PolygonPath from '../Polygon/polygon';
import Polyline from '../Polyline/polyline';
import Rectangle from '../Rectangle/rectangle';
import {
    MeasurementToolbarCheckbox,
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
} from './measurement-toolbar';
import { shouldAllowLineSegmentLength } from './measurement-util';

interface MeasurementToolbarPolygonLengthProps {
    poly: PolygonPath | Polyline | Rectangle;
    disabled: boolean;
    onChangeShowLength: (showLength: boolean) => void;
    isPolyline?: boolean;
}

const MeasurementToolbarPolygonLength = (props: MeasurementToolbarPolygonLengthProps) => {
    const shouldShowEdgeLength = props.isPolyline && shouldAllowLineSegmentLength(props.poly as Polyline);
    const disabled = props.disabled || !shouldShowEdgeLength;

    const titleText = () => {
        if (props.disabled) {
            return 'There is too much geometry to show length labels accurately';
        } else if (shouldShowEdgeLength) {
            return 'Show edge length';
        } else {
            return 'Length(s) too long to show length labels';
        }
    };

    return (
        <MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionLabel htmlFor="poly-length-tick">Show edge Length:</MeasurementToolbarOptionLabel>
            <MeasurementToolbarCheckbox
                id="poly-length-tick"
                disabled={disabled}
                title={titleText()}
                type="checkbox"
                checked={props.poly.showLength}
                onClick={() => props.onChangeShowLength(!props.poly.showLength)}
            />
        </MeasurementToolbarOptionContainer>
    );
};

export default MeasurementToolbarPolygonLength;
