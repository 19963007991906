// Values based on code from https://github.com/spatialillusions/milsymbol-generator

export const AIR_SYMBOLSET_VALUE = '01';
export const MISSILE_SYMBOLSET_VALUE = '02';
export const SPACE_SYMBOLSET_VALUE = '05';
export const UNIT_SYMBOLSET_VALUE = '10';
export const CIVILIAN_SYMBOLSET_VALUE = '11';
export const EQUIPMENT_SYMBOLSET_VALUE = '15';
export const INSTALLATION_SYMBOLSET_VALUE = '20';
export const CONTROLMEASURE_SYMBOLSET_VALUE = '25';
export const DISMOUNTED_SYMBOLSET_VALUE = '27';
export const SURFACE_SYMBOLSET_VALUE = '30';
export const SUBSURFACE_SYMBOLSET_VALUE = '35';
export const MINE_SYMBOLSET_VALUE = '36';
export const ACTIVITY_SYMBOLSET_VALUE = '40';

export const INITIAL_SIDC = `10001000000000000000`;

export type SidcMapValue = 'affiliation' | 'unit' | 'echelon';

export type MilSymbolValues = { value: string; text: string; symbolSet?: string };

export const sidValues: MilSymbolValues[] = [
    { value: '1', text: 'Unknown' },
    { value: '3', text: 'Friend' },
    { value: '4', text: 'Neutral' },
    { value: '6', text: 'Hostile' },
];

export const unitValues: MilSymbolValues[] = [
    { value: '000000', text: 'Unspecified', symbolSet: UNIT_SYMBOLSET_VALUE },
    { value: '121100', text: 'Infantry', symbolSet: UNIT_SYMBOLSET_VALUE },
    { value: '120500', text: 'Armoured Vehicle', symbolSet: UNIT_SYMBOLSET_VALUE },
    { value: '130300', text: 'Field Artillery', symbolSet: UNIT_SYMBOLSET_VALUE },
    { value: '140700', text: 'Engineer', symbolSet: UNIT_SYMBOLSET_VALUE },
    { value: '120900', text: 'Combat', symbolSet: UNIT_SYMBOLSET_VALUE },
    { value: '180200', text: 'Allied Command Operations', symbolSet: UNIT_SYMBOLSET_VALUE },
    { value: '112000', text: 'Warehouse/Storage Facility', symbolSet: INSTALLATION_SYMBOLSET_VALUE },
];

export const echelonValues: MilSymbolValues[] = [
    { value: '00', text: 'Unspecified' },
    { value: '11', text: 'Team/Crew' },
    { value: '12', text: 'Squad' },
    { value: '13', text: 'Section' },
    { value: '14', text: 'Platoon' },
    { value: '15', text: 'Company' },
    { value: '16', text: 'Battalion' },
    { value: '17', text: 'Regiment' },
    { value: '18', text: 'Brigade' },
    { value: '21', text: 'Division' },
    { value: '22', text: 'Corps' },
    { value: '23', text: 'Army' },
];
