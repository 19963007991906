import React from 'react';
import Analytics from '../../../lib/user-analytics';
import { SitemapLink } from './footer';

const CompanyLinks = () => {
    const onClickAbout = () => {
        Analytics.Event('LandingPage', 'Clicked footer about');
        window.open('https://about.soar.earth', '_blank');
    };

    return (
        <React.Fragment>
            <SitemapLink title="About" onClick={onClickAbout}>
                About
            </SitemapLink>
        </React.Fragment>
    );
};

export default CompanyLinks;
