import React, { useEffect, useState } from 'react';
import { TileLayer as LeafletTileLayer, WMSTileLayer, GeoJSON } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ListingType, CRSDefs } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import { actionFlyToOnMap } from '../../../store/App/actions';
import { actionActiveMapTileLayerLoading } from '../../../store/Map/SuperMap/actions';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import { selectActiveOrder } from '../../../store/Map/Tasks/selectors';
import { selectTileLayersOpacity } from '../../../store/Map/TileLayer/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectSideDrawerMode, selectSideDrawerOpen } from '../../../store/SideDrawer/selectors';
import DistortableOverlay from '../Annotations/DistortableOverlay/DistortableOverlay';
import WMTSTileLayers from './wmts-tilelayers';
import GeoUtil from '../../../lib/geo-util';
import { MAX_ZOOM_LEVEL } from '../map-container';
import { isMobileVersion } from '../../../lib/soar-helper';
import useZoomInNotification from '../../../lib/use-zoomin-notification';
import MapSearchDrawProvider from './TileLayerProvider/map-search-draw-provider';
import TileLayerErrorNotice from './tilelayer-error-notice';

interface TileLayersProps {
    editMode: boolean;
}

const TileLayers = (props: TileLayersProps) => {
    const [tileError, setTileError] = useState(false);

    const dispatch = useDispatch();

    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const activeMap = useSelector(selectActiveAllMap);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);
    const sideDrawerOpen = useSelector(selectSideDrawerOpen);
    const activeOrder = useSelector(selectActiveOrder);

    const { NotificationMarker } = useZoomInNotification({
        minZoom: activeMap?.minZoom,
        position: activeMap?.boundingBox.getCenter(),
    });

    useEffect(() => {
        if (!activeMap) {
            dispatch(actionActiveMapTileLayerLoading(false));
        }
    }, [activeMap, dispatch]);

    useEffect(() => {
        if (activeOrder === 'FETCHING') {
            dispatch(actionActiveMapTileLayerLoading(true));
        } else if (activeOrder === 'ERROR') {
            dispatch(actionActiveMapTileLayerLoading(false));
            toast.dark('Unable to open this map.');
        } else if (activeOrder) {
            dispatch(actionActiveMapTileLayerLoading(false));
            dispatch(actionFlyToOnMap(activeOrder.boundingBox));
        }
    }, [activeOrder, dispatch]);

    const eventHandlers = {
        loading: () => dispatch(actionActiveMapTileLayerLoading(true)),
        load: () => dispatch(actionActiveMapTileLayerLoading(false)),
    };

    const handleTileError = (event: L.LeafletEvent) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tile = (event as any).tile;
        if (tile) {
            fetch(tile.src).then((response) => {
                const contentType = response.headers.get('Content-Type');
                if (
                    response.status === 400 ||
                    response.status === 404 ||
                    !contentType?.includes(activeMap?.layerImageFormat || 'image/')
                ) {
                    setTileError(true);
                    dispatch(actionActiveMapTileLayerLoading(false));
                }
            });
        }
    };

    const eventWithErrorHandlers = {
        loading: () => dispatch(actionActiveMapTileLayerLoading(true)),
        load: () => dispatch(actionActiveMapTileLayerLoading(false)),
        tileerror: handleTileError,
    };

    if (!sideDrawerOpen) return <React.Fragment />;

    if (activeMap) {
        switch (activeMap.listingType) {
            case ListingType.TILE_LAYER:
            case ListingType.ORDER:
                return (
                    <LeafletTileLayer
                        key={activeMap.id}
                        url={activeMap.tileUrl}
                        tms={true}
                        opacity={tileLayersOpacity[activeMap.id] ?? 1.0}
                        bounds={activeMap.boundingBox}
                        zIndex={1000}
                        maxZoom={MAX_ZOOM_LEVEL}
                        eventHandlers={eventHandlers}
                    />
                );

            case ListingType.EXTERNAL_TILE_LAYER:
                return (
                    <LeafletTileLayer
                        key={activeMap.id}
                        url={activeMap.tileUrl}
                        tms={activeMap.tms ? activeMap.tms : false}
                        opacity={tileLayersOpacity[activeMap.id] ?? 1.0}
                        bounds={activeMap.boundingBox}
                        zIndex={1000}
                        maxZoom={MAX_ZOOM_LEVEL}
                        eventHandlers={eventHandlers}
                        maxNativeZoom={activeMap.maxZoom ? activeMap.maxZoom : undefined}
                    />
                );

            case ListingType.COG:
                return (
                    <React.Fragment>
                        <NotificationMarker />
                        <GeoJSON
                            data={JSON.parse(activeMap.geometryGeoJson as string)}
                            style={{ fillOpacity: 0, color: 'red', weight: 1 }}
                        />
                        <LeafletTileLayer
                            key={activeMap.id}
                            url={activeMap.tileUrl}
                            tms={activeMap.tms}
                            opacity={tileLayersOpacity[activeMap.id] ?? 1.0}
                            bounds={activeMap.boundingBox}
                            zIndex={1000}
                            maxZoom={MAX_ZOOM_LEVEL}
                            eventHandlers={eventHandlers}
                        />
                    </React.Fragment>
                );

            case ListingType.IMAGE: {
                const distoredGeometryWKT = activeMap.distoredGeometry;
                const points = GeoUtil.polygonFromPolygonWKT(distoredGeometryWKT);
                const corners = [points[2], points[3], points[1], points[0]];
                return (
                    <DistortableOverlay
                        corners={corners}
                        dataURL={ListingHelper.getPreviewUrlForListing(activeMap.id, 'full')}
                        opacity={tileLayersOpacity[activeMap.id] ?? 1.0}
                    />
                );
            }

            case ListingType.WMS: {
                if (!activeMap.serverUrl) {
                    toast.dark('This WMS layer is missing a server URL');
                    return <React.Fragment />;
                }
                const crs = activeMap.layerSrs ? CRSDefs[activeMap.layerSrs] : null;

                return (
                    <React.Fragment>
                        <TileLayerErrorNotice
                            setTileError={setTileError}
                            tileError={tileError}
                            listingTitle={activeMap?.title || ''}
                        />
                        <WMSTileLayer
                            key={activeMap.id}
                            url={activeMap.serverUrl}
                            layers={activeMap.layerName}
                            opacity={tileLayersOpacity[activeMap.id] ?? 1.0}
                            transparent={true}
                            format={activeMap.layerImageFormat || 'image/png'}
                            zIndex={1000}
                            maxZoom={MAX_ZOOM_LEVEL}
                            eventHandlers={eventWithErrorHandlers}
                            version={activeMap.layerVersion ?? '1.1.1'}
                            crs={crs}
                        />
                    </React.Fragment>
                );
            }

            case ListingType.WMTS: {
                if (!activeMap.serverUrl) {
                    toast.dark('This WMTS layer is missing a server URL');
                    return <React.Fragment />;
                }

                let maxZoom = MAX_ZOOM_LEVEL;
                if (activeMap.tileMatrixSet?.matrices) {
                    maxZoom = 0;
                    activeMap.tileMatrixSet?.matrices.forEach((m, i) => {
                        if (i > maxZoom) {
                            maxZoom = i;
                        }
                    });
                }

                const crs = activeMap.layerSrs ? CRSDefs[activeMap.layerSrs] : null;

                return (
                    <React.Fragment>
                        <TileLayerErrorNotice
                            setTileError={setTileError}
                            tileError={tileError}
                            listingTitle={activeMap?.title || ''}
                        />
                        <WMTSTileLayers
                            key={activeMap.id}
                            url={activeMap.serverUrl}
                            layer={activeMap.layerName}
                            tileMatrixSet={activeMap.tileMatrixSet?.identifier}
                            style={activeMap.style}
                            matrixIds={activeMap.tileMatrixSet?.matrices}
                            opacity={tileLayersOpacity[activeMap.id] ?? 1.0}
                            zIndex={1000}
                            eventHandlers={eventWithErrorHandlers}
                            maxZoom={maxZoom}
                            maxNativeZoom={maxZoom}
                            crs={crs}
                        />
                    </React.Fragment>
                );
            }
            case ListingType.NOT_SUPPORTED:
                // TODO
                toast.dark('Unable to open this listing. Listing is not supported');
                break;

            default:
                return <React.Fragment />;
        }
    }

    if (typeof activeOrder === 'object') {
        return (
            <LeafletTileLayer
                key={activeOrder.id}
                url={activeOrder.url}
                tms={true}
                opacity={tileLayersOpacity[activeOrder.id] || 1.0}
                bounds={activeOrder.boundingBox}
                zIndex={1000}
            />
        );
    }

    if (props.editMode || isMobileVersion) {
        return <React.Fragment />;
    }

    if (
        sideDrawerMode === SideDrawerMode.MAPS ||
        sideDrawerMode === SideDrawerMode.YOUR_MAPS ||
        sideDrawerMode === SideDrawerMode.SUBDOMAIN_MAPS ||
        sideDrawerMode === SideDrawerMode.MY_BOOKMARKS ||
        sideDrawerMode === SideDrawerMode.PROFILE ||
        sideDrawerMode === SideDrawerMode.PROFILE_ALL_MAPS
    ) {
        return <MapSearchDrawProvider />;
    }

    return <React.Fragment />;
};

export default TileLayers;
