import React from 'react';
import { useSelector } from 'react-redux';
import { selectMilitaryMarkers } from '../../../store/Annotations/MilitaryMarker/selectors';
import MilitaryMarkerAnnotation from '../../MapView/Annotations/MilitaryMarker/military-marker-annotation';

const MobileMilitaryMarker = () => {
    const militaryMarkers = useSelector(selectMilitaryMarkers);
    return (
        <React.Fragment>
            {militaryMarkers.map((marker) => {
                return (
                    <MilitaryMarkerAnnotation
                        key={`
                        ${marker.id}-
                        ${marker.zoomBase}-
                        ${marker.position.lat}-
                        ${marker.position.lng}-
                        ${marker.zIndex}-
                        ${marker.sidc}
                    `}
                        marker={marker}
                        isSelected={false}
                        onUpdate={() => ''}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileMilitaryMarker;
