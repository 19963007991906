import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { LatLng } from 'leaflet';
import { useSelector, useDispatch } from 'react-redux';
import Analytics from '../../../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../../../store/App/actions';
import { actionSetDroneImageManualPosition } from '../../../../../store/Map/Uploads/actions';
import { selectDroneImageManualPosition } from '../../../../../store/Map/Uploads/selectors';
import SoarAutocomplete from '../../../../SearchLocation/soar-autocomplete';
import { StyledButton, StyledTransButton } from '../../../../Shared/styled-button';

interface UploadMapImageNoLocationProps {
    onClickBack: () => void;
    onClickConfirmPosition: (position: LatLng) => void;
}

const UploadMapImageNoLocation = (props: UploadMapImageNoLocationProps) => {
    const droneImageManualPosition = useSelector(selectDroneImageManualPosition);

    const dispatch = useDispatch();
    const setDroneImagePosition = useCallback(
        (position: LatLng | undefined) => {
            dispatch(actionSetDroneImageManualPosition(position));
        },
        [dispatch]
    );

    const flyTo = useCallback((latlng: LatLng) => dispatch(actionFlyToOnMap(latlng)), [dispatch]);

    const [searchedLocation, setSearchedLocation] = useState<LatLng | undefined>(undefined);

    useEffect(() => {
        if (!searchedLocation) {
            setDroneImagePosition(undefined);
        } else {
            flyTo(searchedLocation);
        }
    }, [searchedLocation, flyTo, setDroneImagePosition]);

    return (
        <Container>
            <Title>Choose Location</Title>
            <DrawerHint error>
                This file does not have exif and we cannot automatically place it on the map. If you use the original
                unedited file you won't need to perform this step
            </DrawerHint>
            <DrawerHint>
                Enter the closest street address below and then move the marker to be as close as possible to where the
                image was taken
            </DrawerHint>

            <SoarAutocomplete
                autocompleteType={['ADDRESS']}
                onLatlngSelected={(latlng: LatLng) => {
                    setSearchedLocation(latlng);
                    setDroneImagePosition(latlng);
                }}
            />

            <div className="d-flex mt-4 justify-content-between">
                <StyledTransButton
                    onClick={() => {
                        setDroneImagePosition(undefined);
                        props.onClickBack();
                        Analytics.Event('Upload', 'Clicked back arrow at choose location');
                    }}
                >
                    <i className="fa fa-chevron-left mr-2"></i>BACK
                </StyledTransButton>

                <StyledButton
                    className="mr-0 pl-4 pr-4"
                    disabled={!droneImageManualPosition}
                    onClick={() => {
                        if (droneImageManualPosition) {
                            props.onClickConfirmPosition(droneImageManualPosition);
                            setDroneImagePosition(undefined);
                            Analytics.Event('Upload', 'Clicked confirm position at choose location');
                        }
                    }}
                >
                    CONFIRM POSITION
                    <i className="fa fa-chevron-right ml-2"></i>
                </StyledButton>
            </div>
        </Container>
    );
};

export default UploadMapImageNoLocation;

const Container = styled.div`
    max-height: calc(-75px + 100vh);
    padding: 20px;
`;

const Title = styled.h3`
    flex: 0 1 auto;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
`;

const DrawerHint = styled.div<{ error?: boolean }>`
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${(props) => (props.error ? '#E74C3C' : 'white')};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    max-width: 100%;
    border: 1px solid ${(props) => (props.error ? '#E74C3C' : 'white')};
    padding: 5px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.5);
`;
