import { History } from 'history';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import UriHelper from '../../lib/uri-helper';
import { SideDrawerMode } from '../../store/SideDrawer/model';

/**
 * `useHistoryNavigation` checks for the initial load or user incoming from an external url and
 *  allows a more control over routing to a specific sideDrawerMode with the 'POP' action.
 *
 * Usage: const { navigateToPath, setNavigateToPath } = useHistoryNavigation();
 * setNavigateToPath(SideDrawerMode.Foo)
 */

export const useHistoryNavigation = () => {
    const [navigateToPath, setNavigateToPath] = useState<SideDrawerMode | undefined>(undefined);
    const history: History = useHistory();

    useEffect(() => {
        const handleNavigateToPath = (sideDrawMode: SideDrawerMode) => {
            //"POP" identifies an initial load of the page and when the user has come from an external url
            if (history.action === 'POP') {
                UriHelper.navigateToDrawer(sideDrawMode);
            } else {
                history.goBack();
            }
            setNavigateToPath(undefined);
        };

        if (navigateToPath) {
            handleNavigateToPath(navigateToPath);
        }
    }, [history, navigateToPath]);

    return { navigateToPath, setNavigateToPath };
};
