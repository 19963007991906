import { actionTypes as at } from './constants';
import Coordinate from '../../../components/MapView/Annotations/Coordinate/coordinate';
import { CoordinateSystem } from '../../../components/MapView/Annotations/Coordinate/coordinate-util';

export const setCoordinatesAction = (coordinates: Coordinate[]) => {
    return {
        type: at.SET_COORDINATES,
        payload: { coordinates: coordinates },
    };
};

export const setCoordinateAddAction = (coordinate: Coordinate) => {
    return {
        type: at.SET_COORDINATE_ADD,
        payload: coordinate,
    };
};

export const setCoordinateUpdateAction = (coordinate: Coordinate) => {
    return {
        type: at.SET_COORDINATE_UPDATE,
        payload: coordinate,
    };
};

export const setCoordinateDeleteAction = (coordinate: Coordinate) => {
    return {
        type: at.SET_COORDINATE_DELETE,
        payload: coordinate,
    };
};

export const setCoordinateSystemAction = (coordinateSystem: CoordinateSystem) => {
    return {
        type: at.SET_COORDINATE_SYSTEM,
        payload: coordinateSystem,
    };
};
