import { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextBox, { TextBoxFontType } from './textbox';

interface TextFontProps {
    textBox: TextBox;
    onChangeFont: (font: string) => void;
}

const TextFont = (props: TextFontProps) => {
    const [selectedFont, setSelectedFont] = useState<TextBoxFontType>('Manrope');

    useEffect(() => {
        if (props.textBox.fontFamily) {
            setSelectedFont(props.textBox.fontFamily);
        }
    }, [props.textBox]);

    return (
        <Select
            value={selectedFont}
            onChange={(e) => {
                setSelectedFont(e.target.value as TextBoxFontType);
                props.onChangeFont(e.target.value);
            }}
        >
            <Option value="Manrope" font="Manrope">
                Manrope
            </Option>
            <Option value="Arial" font="Arial">
                Arial
            </Option>
            <Option value="Times New Roman" font="Times New Roman">
                Times New Roman
            </Option>
        </Select>
    );
};

export default TextFont;

const Select = styled.select`
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.8) !important;

    background-color: transparent !important;
    font-size: 1rem;
    outline: none;
    color: rgba(255, 255, 255, 0.6);
    width: 190px;
    height: 32px;

    padding: 5px;
    margin: 8px 0 0 12px;
    cursor: pointer;
`;

const Option = styled.option<{ font: string }>`
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(0, 0, 0, 0.8);
    font-family: ${(props) => props.font};
`;
