import React, { useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import styled from 'styled-components';
import { MarkdownViewer } from './markdown-viewer';

interface MarkdownTextAreaProps {
    value: string;
    setInputValue: (value: string) => void;
    initialEditorHeight?: number;
    placeholder?: string;
}

export const MarkdownTextArea = ({
    value,
    setInputValue,
    initialEditorHeight,
    placeholder,
}: MarkdownTextAreaProps): JSX.Element => {
    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>('write');
    const [markdownError, setMarkdownError] = useState<string | undefined>(undefined);

    const validateMarkdownOnChange = (description: string): void => {
        // Find bold markdown in the textarea and check if there is leading or trailing whitespace
        // Will display an error message if there is an issue, will not prevent the user from saving the markdown
        const regex = /\*\*(.*?)\*\*/g;
        let match: RegExpExecArray | null;
        while ((match = regex.exec(description)) !== null) {
            const boldContent = match[1];
            if (boldContent.startsWith(' ') || boldContent.endsWith(' ')) {
                const errorMessage = `**${boldContent}** has ${
                    boldContent.startsWith(' ') ? 'leading' : 'trailing'
                } whitespace, which is not valid markdown`;
                setMarkdownError(errorMessage);
                return;
            }
        }
        setMarkdownError(undefined);
    };

    useEffect(() => {
        if (value) {
            validateMarkdownOnChange(value);
        }
    }, [value]);

    const handleInputChange = (value: string) => {
        setInputValue(value);
        validateMarkdownOnChange(value);
    };

    // This limits the markdown toolbar options to those that we want to see
    // More information available here https://www.npmjs.com/package/react-mde#react-mde-props
    // Note some styles are overridden to coincide with the platform style using styled components as a wrapper.
    const toolBarOptions = [['header', 'bold', 'italic'], ['link'], ['unordered-list', 'ordered-list']];

    return (
        <MarkdownTextareaContainer>
            <ReactMde
                initialEditorHeight={initialEditorHeight}
                toolbarCommands={toolBarOptions}
                value={value}
                onChange={handleInputChange}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                childProps={{
                    textArea: {
                        placeholder: placeholder || '',
                    },
                }}
                generateMarkdownPreview={(markdown) => Promise.resolve(<MarkdownViewer text={markdown} />)}
            />
            {markdownError && (
                <MarkdownErrorNotice>
                    <Error>{markdownError}</Error>
                </MarkdownErrorNotice>
            )}
        </MarkdownTextareaContainer>
    );
};

const MarkdownTextareaContainer = styled.div`
    background-color: transparent;

    .react-mde {
        color: rgba(255, 255, 255, 0.67) !important;
        border: 1px solid rgb(255 255 255 / 0.3) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.67) !important;
        border-radius: 6px !important;

        :hover,
        :active,
        :focus,
        :focus-visible {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
        }
    }

    .mde-header {
        background-color: inherit;
        border-bottom: 1px solid rgb(255 255 255 / 0.3) !important;
    }

    .mde-header ul.mde-header-group {
        padding: 5px 0px;
    }

    .mde-header ul.mde-header-group li.mde-header-item button {
        color: rgba(255, 255, 255, 0.8) !important;
    }

    .mde-tabs button {
        border-radius: 6px !important;
        color: rgba(255, 255, 255, 0.8) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;

        :active,
        :focus,
        :focus-visible {
            border: 1px solid #eed926 !important;
            border-radius: 6px !important;
            outline: none;
        }
    }

    .mde-tabs button.selected {
        border: 1px solid #eed926 !important;
        border-radius: 6px !important;
    }

    .mde-preview-content,
    .mde-text {
        background-color: inherit;
        color: white;
        -webkit-text-fill-color: white;
        // Although we are using the MarkdownViewer rendering component the styles are not displaying
        // in the textarea preview so we are forcing them to display correctly here.

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: inherit;
            text-align: justify;
            font-size: 20px;
            border-bottom: none;
        }

        p,
        strong,
        li {
            color: inherit;
            font-size: 16px;
            word-break: break-word;

            ::marker {
                color: white !important;
            }
        }

        a {
            color: #eed926 !important;
            -webkit-text-fill-color: #eed926 !important;
            text-decoration: underline !important;

            &:hover {
                opacity: 0.8;
            }
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #343a40;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #343a40;
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #eed926;
            border-radius: 4px;
        }

        :focus-visible {
            outline: none !important;
        }
    }
`;

const MarkdownErrorNotice = styled.div`
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 5px;
    margin-top: 5px;
`;

const Error = styled.p`
    margin: 0px;
    color: #d32f2f !important;
    -webkit-text-fill-color: #d32f2f !important;
`;
