import {
    LeafletContextInterface,
    PathProps,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
} from '@react-leaflet/core';
import L from 'leaflet';
import LayersUtil from '../layers-util';
import TextBox from '../Textbox/textbox';

interface TextAnnotationSVGLabelProps extends PathProps {
    children?: React.ReactNode;
    isDisabled?: boolean;
    textBox: TextBox;
}

//
// An SVG overlay for displaying a text annotation when it is not selected
//
export const createTextAnnotationSVGLabel = (props: TextAnnotationSVGLabelProps, context: LeafletContextInterface) => {
    const createTextLabel = (width: number, height: number, textBox: TextBox): SVGSVGElement => {
        const formatBackgroundColor = (hex, alpha) =>
            `${hex}${Math.floor(alpha * 255)
                .toString(16)
                .padStart(2, '0')}`;

        const fontSizeBase = props.textBox.fontSizeBase || 32;
        const fontSize = fontSizeBase * Math.pow(2, context.map.getZoom() - props.textBox.zoomBase);

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
        svg.style.backgroundColor = formatBackgroundColor(textBox.fillColor, textBox.fillOpacity);

        const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        textElement.setAttribute('x', '50%');
        textElement.setAttribute('y', '0');
        textElement.setAttribute('width', '100%');
        textElement.setAttribute('height', '100%');
        textElement.setAttribute('font-size', `${fontSize}px`);
        textElement.setAttribute('font-family', textBox.fontFamily);
        textElement.setAttribute('text-anchor', 'middle');
        textElement.setAttribute('dominant-baseline', 'middle');

        textElement.style.userSelect = 'none';

        const linesOfText = textBox.text.split('\n');
        linesOfText.forEach((line, index) => {
            const dyOffsetForLine = (index: number): number => {
                // Ideally, we would use `dominant-baseline: auto` and allow the font-size to dictate the dY of the first line.
                // This doesn't work, probably because leaflet adds a `transform` to the SVG, effecting it's children
                // The workaround is to use `dominant-baseline: middle` and manually set the first lines dY offset
                // The exact value of this magic number was determined by trial and error.
                switch (props.textBox.fontFamily) {
                    case 'Manrope':
                        return index === 0 ? fontSize * 0.86 : fontSize * 1.5;
                    case 'Arial':
                        return index === 0 ? fontSize * 0.84 : fontSize * 1.5;
                    case 'Times New Roman':
                        return index === 0 ? fontSize * 0.85 : fontSize * 1.5;
                    default:
                        throw new Error('Unsupported font family');
                }
            };

            const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
            tspan.setAttribute('x', '50%');
            tspan.setAttribute('dy', `${dyOffsetForLine(index)}px`);
            tspan.setAttribute('fill', textBox.textColor);
            tspan.setAttribute('fill-color', 'transparent');
            tspan.innerHTML = line ? line : '&ZeroWidthSpace;';
            textElement.appendChild(tspan);
        });

        svg.appendChild(textElement);

        return svg;
    };

    const screenSizeOfLatLngBounds = (textBoxBounds: L.LatLngBounds): L.Point => {
        const northEastPoint = context.map.latLngToContainerPoint(textBoxBounds.getNorthEast());
        const southWestPoint = context.map.latLngToContainerPoint(textBoxBounds.getSouthWest());
        const width = Math.abs(northEastPoint.x - southWestPoint.x);
        const height = Math.abs(southWestPoint.y - northEastPoint.y);
        return new L.Point(width, height);
    };

    const pane = LayersUtil.getPaneId(context.map, props.textBox);
    const screenSize = screenSizeOfLatLngBounds(props.textBox.bounds);
    const svg = createTextLabel(screenSize.x, screenSize.y, props.textBox);

    const svgElement = createElementObject<L.SVGOverlay, L.SVGOverlayStyleOptions>(
        new L.SVGOverlay(svg, props.textBox.bounds, { interactive: true, pane: pane }),
        context
    );

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        svgElement.instance.options.interactive = false;
    } else {
        svgElement.instance.options.interactive = true;
    }

    return svgElement;
};

const useTextBoxAnnotation = createElementHook<L.SVGOverlay, TextAnnotationSVGLabelProps, LeafletContextInterface>(
    createTextAnnotationSVGLabel
);
const useTextBox = createLayerHook(useTextBoxAnnotation);
const TextAnnotationSVGLabel = createContainerComponent(useTextBox);

export default TextAnnotationSVGLabel;
