import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { CharacterCount } from './character-count';

interface InputValidationProps {
    maxLength?: number;
    required?: boolean;
}

interface TextInputProps {
    name: string;
    placeholder?: string;
    value?: string;
    onChange: (e) => void;
    validation: InputValidationProps;
}

const TextInput = (props: TextInputProps) => {
    const { name, placeholder, value, onChange, validation } = props;
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!value && validation.required) {
            setError('This field is required.');
        } else {
            setError(undefined);
        }
    }, [value, validation]);

    return (
        <Container>
            <StyledInput
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.currentTarget.value)}
                maxLength={validation.maxLength}
                required={validation.required}
            />
            {error ? (
                <ValidationError>{error}</ValidationError>
            ) : (
                validation.maxLength && <CharacterCount text={value} limit={validation.maxLength} />
            )}
        </Container>
    );
};

export default TextInput;

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const StyledInput = styled(Input)`
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid rgb(255 255 255 / 0.3) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :active,
    :focus {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;

const ValidationError = styled.div`
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 10px;
    display: inline-block;
    color: red;
    -webkit-text-fill-color: red;
`;
