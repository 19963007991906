import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SideDrawerIcons from './side-drawer-icons';
import SideDrawerSearchCloseLabel from './side-drawer-search-close-label';
import { getSideDrawerControlValues } from './side-drawer-util';

const SideDrawer = () => {
    const [expanded, setExpanded] = useState(true);
    const [showInput, setShowInput] = useState(false);

    const url = useLocation();

    useEffect(() => {
        if (!expanded || url.pathname === '/maps' || url.pathname.includes('/maps?')) {
            setShowInput(true);
        } else {
            requestAnimationFrame(() => setShowInput(false));
        }

        return () => {
            setShowInput(false);
        };
    }, [expanded, showInput, url.pathname]);

    // TODO: Refactor
    // This is an anti-pattern and can't be tested or storybooked.
    // It needs to be replaced with a proper component structure.
    const drawControl = getSideDrawerControlValues(url, expanded);
    if (!drawControl) return null;

    return (
        <React.Fragment>
            <SideDrawerSearchCloseLabel
                expanded={expanded}
                setExpanded={setExpanded}
                showInput={showInput}
                setShowInput={setShowInput}
                drawerControl={drawControl}
            />

            <SideDrawerContainer
                id="side-drawer-container"
                expanded={expanded}
                width={drawControl.width}
                minWidth={!expanded ? '76px' : drawControl.minWidth}
            >
                <SideDrawerIcons expanded={expanded} setExpanded={setExpanded} />
                {expanded ? <SideDrawerDivider /> : null}
                <SideDrawerContent
                    isShowingInput={showInput}
                    isSatellitePath={drawControl.isSatellitePath}
                    isProfilePath={drawControl.isProfilePath}
                    expanded={expanded}
                >
                    <SideDrawerFadeInContent
                        expanded={expanded}
                        overflow={
                            drawControl.isFeaturedPath ? 'hidden' : drawControl.isProfilePath ? 'initial' : 'none'
                        }
                    >
                        {drawControl.component}
                    </SideDrawerFadeInContent>
                </SideDrawerContent>
            </SideDrawerContainer>
            {drawControl.extraComponent}
        </React.Fragment>
    );
};

export default SideDrawer;

const SideDrawerContainer = styled.div<{ expanded: boolean; width: string; minWidth: string }>`
    position: absolute;
    top: 70px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(3px);
    z-index: 9998;
    min-height: calc(100vh - 70px);
    width: ${(props) => (props.expanded ? props.width : '76px')};
    min-width: ${(props) => (props.minWidth ? props.minWidth : '76px')};
    transition: width 0.3s ease;
`;

const SideDrawerContent = styled.div<{
    isShowingInput: boolean;
    isSatellitePath: boolean;
    isProfilePath: boolean;
    expanded: boolean;
}>`
    width: ${({ expanded }) => (expanded ? '100%' : '0px')};
    width: ${({ expanded }) => (expanded ? ' -webkit-fill-available' : '0px')};
    width: ${({ expanded }) => (expanded ? ' -moz-available' : '0px')};
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: ${({ isProfilePath }) => (isProfilePath ? 'auto' : 'hidden')};
    color: white;
    transition: width 0.3s ease, opacity 0.3s ease 0.1s;
    position: absolute;
    top: 10px;
    left: 77px;
    margin-right: 1px;
    max-height: calc(100vh - 80px);

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const SideDrawerDivider = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 76px;
`;

const SideDrawerFadeInContent = styled.div<{ expanded: boolean; overflow: string }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: opacity 0.4s ease;
    animation: ${({ expanded }) => (expanded ? 'fadeIn' : 'fadeOut')} 0.4s ease;
    height: 100%;
    overflow: ${({ overflow }) => overflow};

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        75% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;
