import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';

import { defaultPolylineOptions } from '../Polyline/polyline';
import PolygonPath, { defaultPolygonOptions } from './polygon';
import { v4 as uuidv4 } from 'uuid';
import './node-marker-handles.css';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';

import { handlePattternIdFromPatternFillUrl, handleDuplicatePattern } from '../FillPattern/pattern-util';
import { createSnapPointElement } from '../SnapPoints/annotation-snap-points';
import { defaultLabelColor } from '../Measurement/measurement-toolbar-label-color';

interface PolygonBuilderProps {
    onCreatePolygon: (polygon: PolygonPath) => void;
    onCancelBuild: () => void;
    snapPoints: L.LatLng[];
    zIndex?: number;
}

const createPolygonBuilder = (props: PolygonBuilderProps, context: LeafletContextInterface) => {
    const lastUsedPolygonOptions = store.getState().annotationDomain.present.polygonReducer.polygonOptions;

    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);
    const path = new L.Polyline([], {
        ...defaultPolylineOptions,
        ...(lastUsedPolygonOptions ?? {}),
        pane: builderPaneId,
    });
    const pathElement = createElementObject<L.Polyline, PolygonBuilderProps>(path, context);

    const hologramPath = new L.Polyline([], {
        dashArray: '5, 5',
        interactive: false,
        color: pathElement.instance.options.color ?? '#eed926',
        pane: builderPaneId,
    });
    const hologramPathElement = createElementObject<L.Polyline, PolygonBuilderProps>(hologramPath, context);
    context.map.addLayer(hologramPathElement.instance);

    const hologramClosePath = new L.Polyline([], {
        dashArray: '2, 12',
        interactive: false,
        color: pathElement.instance.options.color ?? '#eed926',
        opacity: 0.25,
        pane: builderPaneId,
    });
    const hologramClosePathElement = createElementObject<L.Polyline, PolygonBuilderProps>(hologramClosePath, context);
    context.map.addLayer(hologramClosePathElement.instance);

    const hologramPolygon = new L.Polygon([], {
        stroke: false,
        interactive: false,
        fill: true,
        fillColor: pathElement.instance.options.color ?? '#eed926',
        opacity: 0.125,
        pane: builderPaneId,
    });
    const hologramPolygonElement = createElementObject<L.Polygon, PolygonBuilderProps>(hologramPolygon, context);
    context.map.addLayer(hologramPolygonElement.instance);

    const snapPolygonElement = createSnapPointElement({ instance: pathElement.instance, pane: builderPaneId }, context);
    const nodeMarkers: L.Marker[] = [];
    const tooltip = new L.Tooltip(new L.LatLng(0, 0), { direction: 'top' });

    pathElement.instance.on('add', () => {
        context.map.on('mousemove', onMouseMove);
        context.map.on('click', onMouseClick);
        context.map.addLayer(snapPolygonElement.instance);

        document.addEventListener('keydown', onKeyDown);
    });

    pathElement.instance.on('remove', () => {
        nodeMarkers.forEach((marker) => {
            context.map.removeLayer(marker);
        });
        snapPolygonElement.instance.fireEvent('remove');
        hologramPathElement.instance.remove();
        hologramClosePathElement.instance.remove();
        hologramPolygonElement.instance.remove();
        context.map.off('mousemove', onMouseMove);
        context.map.off('click', onMouseClick);
        context.map.dragging.enable();
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        document.removeEventListener('keydown', onKeyDown);

        if (tooltip.isOpen()) {
            tooltip.removeFrom(context.map);
        }
    });

    const updateHologramPath = (position: L.LatLng) => {
        const pathPositions = pathElement.instance.getLatLngs() as L.LatLng[];

        const firstPosition = pathPositions[0];
        const lastPosition = pathPositions[pathPositions.length - 1];

        if (lastPosition) {
            const positions = [lastPosition, position];
            hologramPathElement.instance.setLatLngs(positions);
        }

        if (firstPosition) {
            hologramClosePathElement.instance.setLatLngs([position, firstPosition]);

            hologramPolygonElement.instance.setLatLngs([...pathPositions, position, firstPosition]);
        }
    };

    const buildPolygon = (polygonPositions: L.LatLng[]): PolygonPath => {
        const polygonId = uuidv4();
        const polygonOptions = {
            ...defaultPolygonOptions,
            color: lastUsedPolygonOptions?.color ?? '#eed926',
            fillColor: lastUsedPolygonOptions?.fillColor ?? '#eed926',
            weight: lastUsedPolygonOptions?.weight ?? 3,
            ...lastUsedPolygonOptions,
        };

        let duplicatedPattern = '';
        if (polygonOptions.fillColor) {
            const pattern = handlePattternIdFromPatternFillUrl(polygonOptions.fillColor);

            if (pattern !== 'none') {
                duplicatedPattern = handleDuplicatePattern(pattern, polygonId, polygonOptions.color || '#eed926');
                polygonOptions.fillColor = duplicatedPattern;
            }
        }

        const polygon: PolygonPath = {
            annotationType: 'Polygon',
            id: polygonId,
            positions: polygonPositions,
            zIndex: props.zIndex || defaultZIndex,
            options: polygonOptions,
            fillPattern: duplicatedPattern || undefined,
            showArea: false,
            showLength: false,
            labelColor: defaultLabelColor,
            units: 'metric',
        };

        return polygon;
    };

    const updateNodeMarkers = () => {
        const positions = pathElement.instance.getLatLngs() as L.LatLng[];
        const position = positions.length === 1 ? positions[0] : positions[positions.length - 1];

        const dotMarkerIcon = new L.DivIcon({
            iconSize: new L.Point(12, 12),
            iconAnchor: new L.Point(6, 6),
            className: 'visible-snap-point-node-marker',
        });

        const marker = new L.Marker(position, { interactive: true, icon: dotMarkerIcon, pane: builderPaneId });

        marker.on('mouseover', (e: L.LeafletMouseEvent) => {
            if (nodeMarkers.length < 3) {
                if (!tooltip.isOpen()) {
                    tooltip.setLatLng(e.latlng);
                    tooltip.setContent('Add more points to create a Polygon');
                    tooltip.openOn(context.map);
                }
            } else {
                const index = nodeMarkers.findIndex((m) => m.getLatLng() === e.latlng);
                if (index === 0) {
                    if (!tooltip.isOpen()) {
                        tooltip.setLatLng(e.latlng);
                        tooltip.setContent('Click to close the Polygon');
                        tooltip.openOn(context.map);
                    }

                    L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
                    L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
                } else if (index === nodeMarkers.length - 1) {
                    if (!tooltip.isOpen()) {
                        tooltip.setLatLng(e.latlng);
                        tooltip.setContent('Press ESC or Double-click to close the Polygon');
                        tooltip.openOn(context.map);
                    }

                    L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
                    L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
                }
            }
        });

        marker.on('mouseout', (_: L.LeafletMouseEvent) => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
            if (tooltip.isOpen()) {
                tooltip.removeFrom(context.map);
            }
        });

        marker.on('click', (e: L.LeafletMouseEvent) => {
            const index = nodeMarkers.findIndex((marker) => marker.getLatLng() === e.latlng);
            if (index === 0) {
                const polygonPositions = [...(pathElement.instance.getLatLngs() as L.LatLng[]), positions[0]];
                const polygon = buildPolygon(polygonPositions);
                // Only create the polygon if there are more than 2 points
                if (polygonPositions.length > 2) {
                    props.onCreatePolygon(polygon);
                } else {
                    props.onCancelBuild();
                }
            }
        });

        marker.on('dblclick', () => {
            const polygonPositions = [...(pathElement.instance.getLatLngs() as L.LatLng[])];
            if (polygonPositions.length > 2) {
                polygonPositions.push(positions[0]);
                const polygon = buildPolygon(polygonPositions);
                props.onCreatePolygon(polygon);
            } else {
                props.onCancelBuild();
            }
        });

        nodeMarkers.push(marker);
        context.map.addLayer(marker);
    };

    context.map.dragging.disable();
    L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

    const onMouseMove = (e: L.LeafletMouseEvent) => {
        updateHologramPath(e.latlng);
    };

    const onMouseClick = (e: L.LeafletMouseEvent) => {
        snapPolygonElement.instance.fireEvent('update', e);
        updateNodeMarkers();
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            if (tooltip.isOpen()) {
                tooltip.closeTooltip();
            }

            const nodeCount = nodeMarkers.length;
            if (nodeCount < 3) {
                props.onCancelBuild();
            } else {
                const positions = pathElement.instance.getLatLngs() as L.LatLng[];
                const polygonPositions = [...positions, positions[0]];
                const polygon = buildPolygon(polygonPositions);
                props.onCreatePolygon(polygon);
                tooltip.closeTooltip();
            }
            snapPolygonElement.instance.fireEvent('remove');
        }
    };

    return pathElement;
};

const usePolygonBuilder = createElementHook<L.Polyline, PolygonBuilderProps, LeafletContextInterface>(
    createPolygonBuilder
);

const PolygonBuilder = (props: PolygonBuilderProps) => {
    const context = useLeafletContext();
    const pathBuilder = usePolygonBuilder(props, context);
    useLayerLifecycle(pathBuilder.current, context);
    return null;
};

export default PolygonBuilder;
