import { useEffect, useState } from 'react';
import LoginRegisterDialog from './login-register-dialog';
import { useSelector } from 'react-redux';
import { selectMyProfile } from '../../store/Account/selectors';
import styled from 'styled-components';
import { LoginModalMode } from './login-enum';
import { Permission, UserDTO } from '../../api/model';
import UserHelper from '../../lib/user-helper';
import Constants from '../../constants';
import { selectConfig } from '../../store/App/selectors';
import UriHelper from '../../lib/uri-helper';

enum DisplayState {
    PRIVATE_DOMAIN,
    NO_PERMISSION,
    LOGIN,
}

interface LoginRegisterProps {
    subDomain?: string;
    modalMode?: LoginModalMode;
}

const LoginRegister = (props: LoginRegisterProps) => {
    const config = useSelector(selectConfig);
    const myProfile: UserDTO | undefined = useSelector(selectMyProfile);

    const [display, setDisplay] = useState(DisplayState.LOGIN);

    useEffect(() => {
        if (myProfile) {
            if (config.BEHAVIOURS.FORCED_LOGIN && UserHelper.getSubdomainPermission(myProfile) !== Permission.None) {
                UriHelper.navigateToPath('/');
            } else {
                setDisplay(DisplayState.NO_PERMISSION);
            }
        } else {
            setDisplay(DisplayState.LOGIN);
        }
    }, [myProfile, config.BEHAVIOURS.FORCED_LOGIN]);

    return (
        <>
            <LoginRegisterContainer>
                {display === DisplayState.PRIVATE_DOMAIN && (
                    <LoginRegisterNotice>
                        {props.subDomain ? (
                            <LoginRegisterLogo src="/assets/subdomain/soar-plus-logo.png" />
                        ) : (
                            <LoginRegisterLogo src="/assets/logos/soar_logo.png" />
                        )}
                        <LoginRegisterContent>
                            <LoginRegisterHeader>
                                <p>Just one quick step left...</p>
                            </LoginRegisterHeader>
                            <LoginRegisterBody>
                                {props.subDomain && (
                                    <>
                                        <p>
                                            <span>{props.subDomain}</span>
                                            <br />
                                            is a private domain
                                        </p>
                                        <p className="login">Please login to start exploring this subdomain</p>
                                    </>
                                )}
                            </LoginRegisterBody>

                            <LoginRegisterButtonContainer>
                                <LoginRegisterButton onClick={() => setDisplay(DisplayState.LOGIN)}>
                                    CLICK HERE TO SIGN IN
                                </LoginRegisterButton>
                            </LoginRegisterButtonContainer>
                        </LoginRegisterContent>
                    </LoginRegisterNotice>
                )}
                {display === DisplayState.NO_PERMISSION && (
                    <LoginRegisterNotice>
                        {props.subDomain ? (
                            <LoginRegisterLogo src="/assets/subdomain/soar-plus-logo.png" />
                        ) : (
                            <LoginRegisterLogo src="/assets/logos/soar_logo.png" />
                        )}
                        <LoginRegisterContent>
                            <LoginRegisterHeader>
                                <p>You need access</p>
                            </LoginRegisterHeader>
                            <LoginRegisterBody>
                                {props.subDomain && (
                                    <>
                                        <p>
                                            <span>{props.subDomain}</span>
                                            <br />
                                            is a private domain and your account has not been granted access
                                        </p>
                                        <p>If you know the owner you can ask for access.</p>
                                        <p className="login">
                                            Click the button to be redirected back to the public Soar.Earth
                                        </p>
                                    </>
                                )}
                            </LoginRegisterBody>

                            <LoginRegisterButtonContainer>
                                <a href={`https://${Constants.DOMAIN}`}>
                                    <LoginRegisterButton>GO ON SOAR.EARTH</LoginRegisterButton>
                                </a>
                            </LoginRegisterButtonContainer>
                        </LoginRegisterContent>
                    </LoginRegisterNotice>
                )}
            </LoginRegisterContainer>
            {display === DisplayState.LOGIN && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={props.modalMode ? props.modalMode : LoginModalMode.LOGIN}
                    onClose={() => setDisplay(DisplayState.PRIVATE_DOMAIN)}
                />
            )}
        </>
    );
};

export default LoginRegister;

const LoginRegisterContainer = styled.div`
    background-image: url(/assets/backgrounds-large/login-register-container-bg.jpeg);
    background-size: cover;
    background-color: #222327;
    width: 100vw;
    height: 100vh;
    display: flex;
`;

const LoginRegisterLogo = styled.img`
    width: 550px;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const LoginRegisterContent = styled.div`
    background: rgba(0, 0, 0, 0.8);
    opacity: 0.9;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;

    @media only screen and (max-width: 600px) {
        margin: auto 5px;
    }
`;

const LoginRegisterNotice = styled.div`
    width: 550px;
    margin: auto;
`;

const LoginRegisterHeader = styled.div`
    p {
        padding: 12px;
        color: white;
        font-size: 24px;
        padding-bottom: 0px;
        padding-left: 20px;
    }

    border-bottom: 1px solid gray;
`;

const LoginRegisterBody = styled.div`
    text-align: center;
    margin-top: 10px;

    p {
        font-size: 22px;
        color: white;
        padding: 12px;
        padding-bottom: 0px;
        padding-left: 20px;
    }

    span {
        color: #eed926;
        font-size: 26px;
    }

    .login {
        margin-top: 30px;
    }

    @media only screen and (max-width: 600px) {
        margin-top: 0px;

        p {
            font-size: 18px;
        }

        span {
            font-size: 22px;
        }

        .login {
            margin-top: 0px;
            font-size: 14px;
        }
    }
`;

const LoginRegisterButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const LoginRegisterButton = styled.button`
    border-radius: 6px;
    font-size: 18px;
    padding: 5px 57px;
    cursor: pointer;
    background-color: #eed926;
    color: black;
    border: none;
    margin-bottom: 20px;

    :hover {
        opacity: 0.9;
    }

    @media only screen and (max-width: 600px) {
        font-size: 14px;
    }
`;
