import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';

const CommunityModerator = { title: 'Community Moderator', icon: '😎' };
const HeroUser = { title: 'Hero User', icon: '🏆' };

interface ProfileNameProps {
    isCommunityModerator?: boolean;
    isReputable?: boolean;
    children: string;
    userId?: string;
    onClick?: () => void;
    className?: string;
}

const ProfileName = (props: ProfileNameProps) => {
    const isCommunityModerator = () => {
        return props.isCommunityModerator ? (
            <ProfileNameReputation
                data-tooltip-id="profile-name-tooltip"
                data-tooltip-content={CommunityModerator.title}
                data-tooltip-place="left"
            >
                {CommunityModerator.icon}
            </ProfileNameReputation>
        ) : (
            <React.Fragment />
        );
    };

    const isHeroUser = () => {
        return props.isReputable ? (
            <ProfileNameReputation
                data-tooltip-id="profile-name-tooltip"
                data-tooltip-content={HeroUser.title}
                data-tooltip-place="left"
            >
                {HeroUser.icon}
            </ProfileNameReputation>
        ) : (
            <React.Fragment />
        );
    };

    const handleNameClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        if (props.onClick) {
            props.onClick();
        }
    };

    const renderName = () => {
        if (props.userId && props.onClick) {
            return (
                <ProfileNameUsernameLink href={`/profile/${props.userId}`} onClick={(e) => handleNameClick(e)}>
                    {props.children}
                </ProfileNameUsernameLink>
            );
        } else {
            return <ProfileNameUsername>{props.children}</ProfileNameUsername>;
        }
    };

    return (
        <ProfileNameContainer className={`${props.className}`}>
            {renderName()}
            {isCommunityModerator()}
            {isHeroUser()}

            <Tooltip
                id="profile-name-tooltip"
                style={{
                    backgroundColor: 'rgba(0,0,0,0.95)',
                    color: '#ffffff',
                    fontFamily: "'Manrope', sans-serif !important",
                    fontSize: '12px',
                    fontWeight: 'normal',
                }}
            />
        </ProfileNameContainer>
    );
};

export default ProfileName;

const ProfileNameContainer = styled.div`
    margin: 5px 28px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;

    @media only screen and (max-width: 600px) {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: inherit;
        pointer-events: auto;
    }
`;
const ProfileNameUsernameLink = styled.a`
    color: #ffffff;
    word-break: break-word;
    text-decoration: underline !important;
    cursor: pointer !important;

    @media only screen and (max-width: 600px) {
        text-decoration: underline !important;
        cursor: auto !important;
    }
`;
const ProfileNameUsername = styled.span`
    color: #ffffff;
    word-break: break-word;

    @media only screen and (max-width: 600px) {
        text-decoration: underline !important;
        cursor: auto !important;
    }
`;
const ProfileNameReputation = styled.sup`
    top: 0;
    font-size: auto;
    margin: 0 2px;
    cursor: default;
    user-select: none;
    font-size: inherit;
    line-height: normal;
`;
