import React, { useEffect } from 'react';
import styled from 'styled-components';
import OrderList from './order-list';
import NavigationPanel from '../Shared/navigation-panel';
import { PulseLoader } from '../../Shared/pulse-loader';
import NoImageryModal from '../Imagery/Shared/no-imagery-modal';
import UriHelper from '../../../lib/uri-helper';
import profileIconUrl from '../profile-icon-url';
import Analytics from '../../../lib/user-analytics';
import { selectSatelliteOrders } from '../../../store/Map/Tasks/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { actionFetchSatelliteOrders } from '../../../store/Map/Tasks/actions';

const SatelliteOrders = () => {
    const dispatch = useDispatch();
    const orders = useSelector(selectSatelliteOrders);

    useEffect(() => {
        if (!orders) {
            dispatch(actionFetchSatelliteOrders());
        }
    }, [orders, dispatch]);

    if (!orders) return <PulseLoader />;

    return (
        <ProfileTasksContainer>
            <NavigationPanel label="My Satellite Orders" />
            {orders.length === 0 ? (
                <React.Fragment>
                    <NoImageryModal
                        icon={profileIconUrl.satelliteQueueListUrl}
                        title={'It looks like your Satellite Orders are empty'}
                        text={'Check the tutorials to set up your first task!'}
                        mobileText={''}
                        button={() => {
                            UriHelper.navigateToPath('/satellites');
                            Analytics.Event('My Dashboard', 'Clicked tasks search for satellite imagery');
                        }}
                        buttonText={'Search for satellite imagery'}
                    />
                </React.Fragment>
            ) : (
                <OrderList dataTestId="dashboard-satelliteorders" orders={orders} />
            )}
        </ProfileTasksContainer>
    );
};

export default SatelliteOrders;

const ProfileTasksContainer = styled.div`
    @media (max-width: 900px) {
        padding-right: 0px;
    }
`;
