import L from 'leaflet';
import store from '../../../../store/store';
import Arrow from '../Arrow/arrow';
import Rectangle from '../Rectangle/rectangle';
import Polyline from '../Polyline/polyline';
import PolygonPath from '../Polygon/polygon';
import Image from '../ImageTool/image';

export interface SnapPointElementProps {
    instance: Polyline | PolygonPath | Rectangle | Arrow;
}

export const calculatePossibleSnapPoints = () => {
    const polylines = store.getState().annotationDomain.present.pathReducer.polylines;
    const polygons = store.getState().annotationDomain.present.polygonReducer.polygons;
    const rectangles = store.getState().annotationDomain.present.rectangleReducer.rectangles;
    const arrows = store.getState().annotationDomain.present.arrowReducer.arrows;
    const images = store.getState().annotationDomain.present.imageReducer.images;

    let points: L.LatLng[] = [];

    // Add points from polylines
    points = points.concat(polylines.flatMap((polyline) => polyline.positions));

    // Add points from polygons
    points = points.concat(polygons.flatMap((polygon) => polygon.positions));

    // Add arrow start and end points
    arrows.forEach((arrow: Arrow) => {
        if (arrow.startLatLng && arrow.endLatLng) {
            points.push(arrow.startLatLng);
            points.push(arrow.endLatLng);
        }
    });

    // Add rectangle corner points from bounds
    rectangles.forEach((rectangle: Rectangle) => {
        if (rectangle.bounds) {
            const bounds = rectangle.bounds;
            points.push(bounds.getNorthWest(), bounds.getNorthEast(), bounds.getSouthWest(), bounds.getSouthEast());
        }
    });

    // Add image corner points from bounds
    images.forEach((image: Image) => {
        // Bit ugly but clear on what the intent is
        if (image.bounds) {
            // Get the 4 corners of the image
            const bounds = image.bounds;
            const northWest = bounds.getNorthWest();
            const southEast = bounds.getSouthEast();
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            points.push(northWest, northEast, southWest, southEast); // Order does not matter in this context as we are only interested in the 4 corners

            // Add half way points
            const halfWayNorth = new L.LatLng(northWest.lat, (northWest.lng + northEast.lng) / 2);
            const halfWaySouth = new L.LatLng(southWest.lat, (southWest.lng + southEast.lng) / 2);
            const halfWayEast = new L.LatLng((northEast.lat + southEast.lat) / 2, southEast.lng);
            const halfWayWest = new L.LatLng((northWest.lat + southWest.lat) / 2, southWest.lng);
            points.push(halfWayNorth, halfWaySouth, halfWayEast, halfWayWest);

            // Add quarter way points
            const quarterWayNorthWest = new L.LatLng(northWest.lat, (northWest.lng + halfWayNorth.lng) / 2);
            const quarterWayNorthEast = new L.LatLng(northEast.lat, (northEast.lng + halfWayNorth.lng) / 2);
            const quarterWaySouthWest = new L.LatLng(southWest.lat, (southWest.lng + halfWaySouth.lng) / 2);
            const quarterWaySouthEast = new L.LatLng(southEast.lat, (southEast.lng + halfWaySouth.lng) / 2);
            points.push(quarterWayNorthWest, quarterWayNorthEast, quarterWaySouthWest, quarterWaySouthEast);
        }
    });

    return points;
};

export const hiddenSnapPointIcon = new L.DivIcon({
    iconSize: new L.Point(12, 12),
    iconAnchor: new L.Point(6, 6),
    className: 'hidden-snap-point-node-marker',
});

export const visibleSnapPointIcon = new L.DivIcon({
    iconSize: new L.Point(12, 12),
    iconAnchor: new L.Point(6, 6),
    className: 'visible-snap-point-node-marker',
});
