import Constants from './constants';
import * as Sentry from '@sentry/react';

// Ignore list based off: https://gist.github.com/1878283 + https://gist.github.com/impressiver/5092952
export const SENTRY_OPTIONS = {
    release: `soar@${process.env.REACT_APP_VERSION}`,
    environment: Constants.ENV,
    tracesSampleRate: 0.25,
    // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
    // See: https://github.com/getsentry/raven-js/issues/73
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
        'leaflet',
    ],
    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
    ],
    dsn: Constants.SENTRY_URL,

    // We want to collect only sessions with errors
    replaysSessionSampleRate: 0,

    // Sample for sessions when an error occurs.
    replaysOnErrorSampleRate: 0.25,

    integrations: [
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
        new Sentry.BrowserTracing({
        }),
    ],
};

