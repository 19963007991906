import styled from 'styled-components';
import { SubDomainType } from '../../api/model';

export enum PricingStep {
    Plan,
    Domain,
    Address,
    Confirm,
}

interface PricingHeaderProps {
    step: PricingStep;
    planType: SubDomainType;
}

const PricingHeader = (props: PricingHeaderProps) => {
    return (
        <PricingHeaderContainer>
            <PricingHeaderStep>
                <PricingStepLabel step={props.step === PricingStep.Plan}>Plan</PricingStepLabel>
                <PricingArrow />
            </PricingHeaderStep>
            <PricingHeaderStep>
                <PricingStepLabel step={props.step === PricingStep.Domain}>Domain</PricingStepLabel>
                <PricingArrow />
            </PricingHeaderStep>
            <PricingHeaderStep>
                <PricingStepLabel step={props.step === PricingStep.Address}>Address</PricingStepLabel>
                <PricingArrow />
            </PricingHeaderStep>
            <PricingHeaderStep>
                <PricingStepLabel step={props.step === PricingStep.Confirm}>Confirm</PricingStepLabel>
            </PricingHeaderStep>
        </PricingHeaderContainer>
    );
};

export default PricingHeader;

const PricingHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    color: white;
    height: 50px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    justify-content: space-evenly;

    @media only screen and (max-width: 600px) {
        height: 100%;
        padding-top: 0px;
    }
`;

const PricingHeaderStep = styled.div`
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 18px;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

const PricingArrow = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M6 5.5L1 1L1 10L6 5.5Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 7px;
    margin-left: 25px;
    margin-top: 10px;

    @media only screen and (max-width: 600px) {
        margin-left: 10px;
        margin-top: 8px;
    }
`;

const PricingStepLabel = styled.p<{ step: boolean }>`
    color: ${(props) => (props.step ? 'yellow' : 'white')};
    display: flex;
    flex-direction: row;
`;
