import { ListingDTO } from '../../../../api/model';

export interface ListingDTOWithChip extends ListingDTO {
    chip?: ListingChipType;
}

export interface ListingCardChip {
    label: string;
    color: string;
    fontColor: string;
    title: string;
}

export type ListingChipType =
    | 'near-real-time'
    | 'daily'
    | 'weekly'
    | 'monthly'
    | 'within-monthly'
    | 'seasonal'
    | 'yearly'
    | 'featured'
    | 'new';

export const listingChipForType = (type: ListingChipType | undefined): ListingCardChip | undefined => {
    switch (type) {
        case 'near-real-time':
            return RealTimeListingCardChip;
        case 'daily':
            return DailyListingCardChip;
        case 'weekly':
            return WeeklyListingCardChip;
        case 'within-monthly':
            return MonthlyListingCardChip;
        case 'monthly':
            return MonthlyListingCardChip;
        case 'seasonal':
            return SeasonalListingCardChip;
        case 'yearly':
            return YearlyListingCardChip;
        case 'featured':
            return FeaturedListingCardChip;
        case 'new':
            return NewFeaturedListingCardChip;
        default:
            return undefined;
    }
};

export const attachChipToListingDTO = (listing: ListingDTO): ListingDTOWithChip => {
    if (listing.categories) {
        if (listing.categories.includes('featured')) {
            return { ...listing, chip: 'featured' };
        }

        if (listing.categories.includes('new')) {
            return { ...listing, chip: 'new' };
        }

        if (listing.categories.includes('near-real-time')) {
            return { ...listing, chip: 'near-real-time' };
        }

        if (listing.categories.includes('daily')) {
            return { ...listing, chip: 'daily' };
        }

        if (listing.categories.includes('weekly')) {
            return { ...listing, chip: 'weekly' };
        }

        if (listing.categories.includes('monthly')) {
            return { ...listing, chip: 'monthly' };
        }

        if (listing.categories.includes('within-monthly')) {
            return { ...listing, chip: 'monthly' };
        }

        if (listing.categories.includes('seasonal')) {
            return { ...listing, chip: 'seasonal' };
        }

        if (listing.categories.includes('yearly')) {
            return { ...listing, chip: 'yearly' };
        }

        return { ...listing, chip: undefined };
    }
    return { ...listing, chip: undefined };
};

export const RealTimeListingCardChip: ListingCardChip = {
    label: 'Real Time',
    color: '#8CE4A3',
    fontColor: '#000000',
    title: 'Maps that are updated less than 24 hours apart. Usually less than 6-12 hours.',
};

export const DailyListingCardChip: ListingCardChip = {
    label: 'Daily',
    color: '#cf8ce4',
    fontColor: '#000000',
    title: 'Maps that are updated once every 24 hours.',
};

export const WeeklyListingCardChip: ListingCardChip = {
    label: 'Weekly',
    color: '#8FAFFF',
    fontColor: '#000000',
    title: 'Maps that are updated once every few days or once a week.',
};

export const MonthlyListingCardChip: ListingCardChip = {
    label: 'Monthly',
    color: '#FF9F9F',
    fontColor: '#000000',
    title: 'Maps that are updated once every month.',
};

export const SeasonalListingCardChip: ListingCardChip = {
    label: 'Seasonal',
    color: '#FFAD8F',
    fontColor: '#000000',
    title: 'Maps that are updated seasonally. Usually between 3 to 6 months.',
};

export const YearlyListingCardChip: ListingCardChip = {
    label: 'Yearly',
    color: '#FFFFFF',
    fontColor: '#000000',
    title: 'Maps that are updated once a year.',
};

export const FeaturedListingCardChip: ListingCardChip = {
    label: 'Featured',
    color: '#FFD700',
    fontColor: '#000000',
    title: 'Featured map.',
};

export const NewFeaturedListingCardChip: ListingCardChip = {
    label: 'New',
    color: '#0099ff',
    fontColor: '#ffffff',
    title: 'A map that is new to Soar.',
};
