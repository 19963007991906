import React, { useState } from 'react';
import styled from 'styled-components';

import SoarModal, { SoarModalProps, StyledModalBody } from '../../Shared/soar-modal';
import { inputMaxValues } from '../../../lib/limitation-constants';
import { StyledButton } from '../../Shared/styled-button';

import { PulseLoader } from '../../Shared/pulse-loader';
import { StoaryResponse, UpdateStoaryMetaRequest } from '../../../api/stoaryModel';

interface DrawProjectUpdateDialogProps extends SoarModalProps {
    project: StoaryResponse | undefined;
    onSubmit: (project: UpdateStoaryMetaRequest) => void;
    isUpdating?: boolean;
}

const DrawProjectUpdateDialog = (props: DrawProjectUpdateDialogProps) => {
    const [title, setTitle] = useState<string>(props.project?.title ?? '');
    const [titleLength, setTitleLength] = useState<number>(props.project?.title?.length ?? 0);
    const [formValid, setFormValid] = useState<boolean>(true);

    const checkForm = () => {
        if (!title) {
            return false;
        }

        const updateRequest: UpdateStoaryMetaRequest = {
            title: title,
            public: props.project ? props.project.public : true,
            sharePermissions: props.project ? props.project.sharePermissions : [],
        };

        props.onSubmit(updateRequest);
        return true;
    };

    const setProjectTitle = (title: string) => {
        if (title.length < inputMaxValues.MAX_TITLE_CHARACTERS) {
            setTitle(title);
            setTitleLength(title.length);
        }
    };

    const resetForm = () => {
        setProjectTitle(props.project?.title ?? '');
    };

    return (
        <ProjectMetadataModal title="My Stoary" isOpen={props.isOpen} toggle={props.toggle} onOpen={resetForm}>
            <ProjectMetadataModalBody>
                <Content>
                    <Label>Stoary Title</Label>
                    <div className="d-flex" style={{ margin: '0px 1px' }}>
                        <TextField
                            type="text"
                            value={title}
                            onChange={(e) => setProjectTitle(e.target.value)}
                            placeholder="Give your Stoary a catchy title"
                            disabled={titleLength === inputMaxValues.MAX_TITLE_CHARACTERS ? true : false}
                        />
                    </div>

                    <CharacterCount characters={titleLength} maxCharacters={inputMaxValues.MAX_TITLE_CHARACTERS - 1}>
                        Characters: {titleLength}/{inputMaxValues.MAX_TITLE_CHARACTERS - 1}
                    </CharacterCount>
                    {!formValid && !title && <Error>Please enter a title for your Stoary</Error>}

                    <div className="d-flex mt-4 justify-content-between">
                        {!props.isUpdating ? (
                            <ProjectMetadataButtonControl>
                                <StyledButton
                                    className="mr-0 pl-4 pr-4"
                                    onClick={() => {
                                        setFormValid(checkForm());
                                    }}
                                >
                                    SAVE
                                    <i className="fa fa-save ml-2"></i>
                                </StyledButton>
                            </ProjectMetadataButtonControl>
                        ) : (
                            <PulseLoader />
                        )}
                    </div>
                </Content>
            </ProjectMetadataModalBody>
        </ProjectMetadataModal>
    );
};

export default DrawProjectUpdateDialog;

const ProjectMetadataModal = styled(SoarModal)`
    .modal-header {
        .modal-title {
            text-align: center;
        }

        & + div {
            margin-bottom: 5px;
            max-height: calc(100vh - 200px);
            overflow: hidden auto;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #343a40;
            }

            &::-webkit-scrollbar {
                width: 8px;
                background-color: #343a40;
            }

            &::-webkit-scrollbar-thumb {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #eed926;
                border-radius: 4px;
            }
        }
    }
`;

const ProjectMetadataModalBody = styled(StyledModalBody)`
    padding: 1rem;
`;

const Content = styled.div`
    flex: 1 1 auto;
    text-align: left;
    font-weight: 400;
    color: rgb(179, 179, 179);
`;

const TextField = styled.input`
    display: block;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    padding: 4px;
    color: rgba(255, 255, 255, 0.6);
    border-radius: 0.25rem;
    margin: 0px 1px;
`;

const Label = styled.p`
    display: block;
    color: white;
    font-size: 16px;
    margin: 4px 3px;
`;

const Error = styled.p`
    margin: 0px;
    color: red !important;

    &.markdown-error {
        margin-top: 5px;
        color: white !important;
    }
`;

const ProjectMetadataButtonControl = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

interface CharacterCountProps {
    characters: number;
    maxCharacters: number;
}

const CharacterCount = styled.span<CharacterCountProps>`
    color: ${(props) =>
        props.characters >= props.maxCharacters ? 'rgba(255, 0, 0, 0.7);' : 'rgba(255, 255, 255, 0.5);'};
    font-size: 10px;
    text-align: right;
    display: block;
    margin-right: 3px;
`;
