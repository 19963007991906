import L from 'leaflet';

export const nodeMarkerIcon = new L.Icon({
    iconSize: new L.Point(12, 12),
    iconAnchor: new L.Point(6, 6),
    iconUrl: '/assets/annotations/yellow-dot-marker.png',
    className: 'node-dot-marker',
});

export const nodeMarkerOptions: L.MarkerOptions = {
    draggable: true,
    bubblingMouseEvents: false,
    icon: nodeMarkerIcon,
};
