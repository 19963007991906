import L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import Circle from '../Circle/circle';
import Marker from '../Marker/marker';
import Rectangle from '../Rectangle/rectangle';
import FreehandPolyline from '../FreehandPolyline/freehand-polyline';
import Arrow from '../Arrow/arrow';
import Image from '../ImageTool/image';
import TextBox from '../Text/textbox';
import Polyline from '../Polyline/polyline';
import PolygonPath from '../Polygon/polygon';
import Coordinate from '../Coordinate/coordinate';
import store from '../../../../store/store';
import { actionSetBasemap } from '../../../../store/Map/Basemap/actions';
import MilitaryMarker from '../MilitaryMarker/military-marker';
import { StoaryProject, ProjectMapLayer } from '../../../../api/stoaryModel';

const DEBUG = true;

const log = (message: string) => {
    if (DEBUG) {
        console.log(message);
    }
};

export default class GeoJSONProject {
    static import(rawGeoJSON: string): StoaryProject {
        const project: StoaryProject = {
            markers: [],
            militaryMarkers: [],
            polylines: [],
            polygons: [],
            circles: [],
            rectangles: [],
            freehandDraws: [],
            arrows: [],
            images: [],
            textBoxes: [],
            coordinates: [],
        };

        let geoJSON;
        try {
            geoJSON = JSON.parse(rawGeoJSON);
        } catch (err) {
            geoJSON = rawGeoJSON;
        }
        let features = geoJSON.features;

        // The GeoJSON is a raw Point
        // if (!features && geoJSON.type === 'Point') {
        //     features = [geoJSON];
        // }

        // The GeoJSON is a feature object
        if (!features && geoJSON.type === 'Feature') {
            features = [geoJSON];
        }

        // The GeoJSON is a raw type
        if (!features && (geoJSON.type === 'Point' || geoJSON.type === 'LineString' || geoJSON.type === 'Polygon')) {
            features = [
                {
                    type: 'Feature',
                    geometry: geoJSON,
                    properties: {},
                },
            ];
        }

        if (!features) {
            log('GeoJSON file has no features');
            return project;
        }

        // Deprecated - legacy support for old projects pre 19/7/2024 - DP
        if (geoJSON.project?.baseMap) {
            store.dispatch(actionSetBasemap(geoJSON.project.baseMap));
        }

        if (geoJSON.project?.viewport) {
            const boundsJSON = geoJSON.project.viewport;
            const northEast = new L.LatLng(boundsJSON[0][1], boundsJSON[0][0]);
            const southWest = new L.LatLng(boundsJSON[1][1], boundsJSON[1][0]);
            const bounds = new L.LatLngBounds(southWest, northEast);
            project.viewportBounds = bounds;
        }

        if (geoJSON.project && geoJSON.project.mapLayers && geoJSON.project.mapLayers[0]) {
            project.activeMap = geoJSON.project.mapLayers[0].mapId;
        }

        let featureCount = features.length;

        // The GeoJSON was created by Soar and has the property "annotationType"
        features.forEach((feature, index) => {
            switch (feature.properties?.annotationType) {
                case 'Marker':
                    log(`[${index}] Importing Soar marker`);
                    project.markers.push(Marker.deserialize(feature));
                    break;

                case 'MilitaryMarker':
                    log(`[${index}] Importing Soar military marker`);
                    project.militaryMarkers.push(MilitaryMarker.deserialize(feature));
                    break;

                case 'Polyline':
                    log(`[${index}] Importing Soar polyline`);
                    project.polylines.push(Polyline.deserialize(feature));
                    break;

                case 'Polygon':
                    log(`[${index}] Importing Soar polygon`);
                    project.polygons.push(PolygonPath.deserialize(feature));
                    break;

                case 'Circle':
                    log(`[${index}] Importing Soar circle`);
                    project.circles.push(Circle.deserialize(feature));
                    break;

                case 'Rectangle':
                    log(`[${index}] Importing Soar rectangle`);
                    project.rectangles.push(Rectangle.deserialize(feature));
                    break;

                case 'FreehandDraw':
                    log(`[${index}] Importing Soar freehand draw`);
                    project.freehandDraws.push(FreehandPolyline.deserialize(feature));
                    break;

                case 'FreehandPolyline':
                    log(`[${index}] Importing Soar freehand draw`);
                    project.freehandDraws.push(FreehandPolyline.deserialize(feature));
                    break;

                case 'Arrow':
                    log(`[${index}] Importing Soar arrow`);
                    project.arrows.push(Arrow.deserialize(feature));
                    break;

                case 'Image':
                    log(`[${index}] Importing Soar image`);
                    project.images.push(Image.deserialize(feature));
                    break;

                case 'TextBox':
                    log(`[${index}] Importing raw text box`);
                    project.textBoxes.push(TextBox.deserialize(feature));
                    break;

                case 'Coordinate':
                    log(`[${index}] Importing raw coordinate`);
                    project.coordinates.push(Coordinate.deserialize(feature));
                    break;

                default:
                    // For geometry that was not generated by Soar.
                    switch (feature.geometry.type) {
                        case 'Point': {
                            log(`[${index}] Importing raw marker`);
                            const marker = Marker.deserialize(feature);
                            marker.id = uuidv4();
                            project.markers.push(marker);
                            break;
                        }

                        case 'LineString': {
                            log(`[${index}] Importing raw polyline`);
                            const polyline = Polyline.deserialize(feature);
                            polyline.id = uuidv4();
                            project.polylines.push(polyline);
                            break;
                        }

                        case 'Polygon': {
                            log(`[${index}] Importing raw polygon`);
                            const polygon = PolygonPath.deserialize(feature);
                            polygon.id = uuidv4();
                            project.polygons.push(polygon);
                            break;
                        }

                        case 'MultiPolygon': {
                            const polygons = PolygonPath.deserializeMultiPolygon(feature);
                            featureCount += polygons.length;
                            polygons.forEach((polygon) => {
                                log(`[${index}] Flattened multi-polygon to polygon`);
                                polygon.id = uuidv4();
                                project.polygons.push(polygon);
                            });
                            break;
                        }

                        default: {
                            log(`[${index}] Unable to import unknown feature type`);
                            log(feature);
                            break;
                        }
                    }
            }
        });

        const importedFeatureCount =
            project.markers.length +
            project.militaryMarkers.length +
            project.polylines.length +
            project.polygons.length +
            project.circles.length +
            project.rectangles.length +
            project.freehandDraws.length +
            project.arrows.length +
            project.textBoxes.length +
            project.images.length +
            project.coordinates.length;

        log(`Imported ${importedFeatureCount} of ${featureCount} features`);

        return project;
    }

    static export(project: StoaryProject): string {
        const markerData = project.markers.map((marker) => {
            return Marker.serialize(marker);
        });

        const militaryMarkerData = project.militaryMarkers.map((militaryMarker) => {
            return MilitaryMarker.serialize(militaryMarker);
        });

        const polylineData = project.polylines.map((polyline) => {
            return Polyline.serialize(polyline);
        });

        const polygonData = project.polygons.map((polygon) => {
            return PolygonPath.serialize(polygon);
        });

        const circleData = project.circles.map((circle) => {
            return Circle.serialize(circle);
        });

        const rectangleData = project.rectangles.map((rectangle) => {
            return Rectangle.serialize(rectangle);
        });

        const freehandDrawData = project.freehandDraws.map((freehandDraw) => {
            return FreehandPolyline.serialize(freehandDraw);
        });

        const arrowData = project.arrows.map((arrow) => {
            return Arrow.serialize(arrow);
        });

        const imageData = project.images.map((image) => {
            return Image.serialize(image);
        });

        // This filters out any text boxes that do not have text.
        // If a user adds a text box but does not enter any text, the text box should not be serialized, if it is serialized
        // the project can break when imported.
        const textBoxData = project.textBoxes
            .map((textBox) => {
                if (!textBox.text) return undefined;
                return TextBox.serialize(textBox);
            })
            .filter((item) => item !== undefined);

        const coordinateData = project.coordinates.map((coordinate) => {
            return Coordinate.serialize(coordinate);
        });

        const mapBoundsJSON =
            project.viewportBounds && (project.viewportBounds as L.LatLngBounds)
                ? [
                      [
                          (project.viewportBounds as L.LatLngBounds).getNorthEast().lng,
                          (project.viewportBounds as L.LatLngBounds).getNorthEast().lat,
                      ],
                      [
                          (project.viewportBounds as L.LatLngBounds).getSouthWest().lng,
                          (project.viewportBounds as L.LatLngBounds).getSouthWest().lat,
                      ],
                  ]
                : undefined;

        const mapLayers: ProjectMapLayer[] = [];
        if (project.pinnedMaps) {
            project.pinnedMaps.forEach((pinnedMap) => {
                mapLayers.push({
                    pinned: true,
                    mapId: pinnedMap,
                    transparency:
                        findOpacityValueByMapId(pinnedMap, store.getState().mapTileLayerDomain.tileLayerOpacity) || 1,
                });
            });
        }

        if (project.activeMap && !project.pinnedMaps?.includes(project.activeMap)) {
            mapLayers.push({
                mapId: project.activeMap,
                transparency:
                    findOpacityValueByMapId(project.activeMap, store.getState().mapTileLayerDomain.tileLayerOpacity) ||
                    1,
            });
        }

        if (project.satelliteFeature) {
            mapLayers.push({
                satelliteFeature: project.satelliteFeature,
                transparency: store.getState().sentinelDomain.sentinelSelectedFeatureOpacity || 1,
            });
        }

        const geoJSON = {
            type: 'FeatureCollection',
            project: {
                id: uuidv4(),
                viewport: mapBoundsJSON,
                mapLayers: mapLayers,
            },
            features: [
                ...markerData,
                ...militaryMarkerData,
                ...polylineData,
                ...polygonData,
                ...circleData,
                ...rectangleData,
                ...freehandDrawData,
                ...arrowData,
                ...imageData,
                ...textBoxData,
                ...coordinateData,
            ],
        };

        return JSON.stringify(geoJSON);
    }
}

function findOpacityValueByMapId(id?: number, data?: Record<number, number>): number | undefined {
    if (!data || !id) return undefined;

    if (Object.prototype.hasOwnProperty.call(data, id)) {
        return data[id];
    } else {
        return 1;
    }
}
