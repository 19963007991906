import proj4 from 'proj4';

const EPSG_4326 = '+proj=longlat +datum=WGS84 +no_defs +type=crs';
const EPSG_7857 = '+proj=utm +zone=57 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'; // GDA2020

const wrapLongitudeCoordinates = (longitude: number) => {
    if (longitude < -180) {
        return wrapLongitudeCoordinates(longitude + 360);
    } else if (longitude > 180) {
        return wrapLongitudeCoordinates(longitude - 360);
    } else {
        return longitude;
    }
};

const convertDDToDMS = (D: number, lng: boolean) => {
    return {
        dir: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
        deg: 0 | (D < 0 ? (D = -D) : D),
        min: 0 | ((D % 1) * 60),
        sec: (0 | (((D * 60) % 1) * 6000)) / 100,
    };
};

const convertDDtoMGRS = (lng: number, lat: number) => {
    const mgrs = require('mgrs');
    let mgrsString = mgrs.forward([lng, lat]);
    mgrsString =
        mgrsString.substring(0, 2) +
        ' ' +
        mgrsString.substring(2, 5) +
        ' ' +
        mgrsString.substring(5, 10) +
        ' ' +
        mgrsString.substring(10);
    return mgrsString;
};

const convertDDtoUTM = (lat: number, lng: number) => {
    const utmObj = require('utm-latlng');
    const utm = new utmObj();

    return utm.convertLatLngToUtm(lat, lng, 0);
};

const convertDDtoGDA2020 = (lat: number, lng: number) => {
    const [x, y] = proj4(EPSG_4326, EPSG_7857, [lng, lat]);
    return { x, y };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const displayDMS = (dms: any) => {
    const dmsDeg = dms.deg.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const dmsMin = dms.min.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const dmsSec = dms.sec.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: false,
    });
    const dmsString = dmsDeg + 'º ' + dmsMin + '′ ' + dmsSec + '′′ ' + dms.dir;
    return dmsString;
};

export { wrapLongitudeCoordinates, convertDDToDMS, convertDDtoMGRS, convertDDtoUTM, convertDDtoGDA2020, displayDMS };
