import React from 'react';
import styled from 'styled-components';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { StyledButton } from '../../../Shared/styled-button';

interface NoImageryModalProps {
    icon: string;
    title: string;
    text?: string;
    mobileText?: string;
    button?: () => void;
    buttonText?: React.ReactNode;
}

const NoImageryModal = (props: NoImageryModalProps) => {
    const { icon, title, text, mobileText } = props;
    return (
        <React.Fragment>
            <NoImageryContainer>
                <NoImageryImage src={icon} data-testid="dashboard-no-imagery-image" />
                <StyledTitle>
                    <h3>{title}</h3>
                </StyledTitle>
                {text ? <StyledText>{text}</StyledText> : null}
                {mobileText ? <StyledMobileText>{mobileText}</StyledMobileText> : null}
                {!isMobileVersion && (
                    <Button data-testid="dashboard-no-imagery-button" onClick={props.button}>
                        {props.buttonText}
                    </Button>
                )}
            </NoImageryContainer>
        </React.Fragment>
    );
};

export default NoImageryModal;
const NoImageryContainer = styled.div`
    text-align: center;
    height: 60vh;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: -10px 0px -90px 0px;
    padding-bottom: 6vh;
    overflow-y: hidden;
`;

const NoImageryImage = styled.img`
    width: 130px;
    height: 130px;
`;

const StyledTitle = styled.div`
    max-width: 400px;
    color: #fff;

    h3 {
        color: #fff;
        margin-top: 10px;
    }
`;

const StyledText = styled.p`
    color: #fff;
    margin-top: 10px;
    max-width: 400px;
    font-size: 12px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const StyledMobileText = styled.div`
    color: #fff;
    margin-top: 10px;
    display: none;

    @media only screen and (max-width: 600px) {
        display: inline-block;
        font-size: 12px;
    }
`;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    z-index: 1000;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;
