import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import { actionSetTileLayerOpacity } from '../../../store/Map/TileLayer/actions';
import { selectTileLayersOpacity } from '../../../store/Map/TileLayer/selectors';

interface ActiveMapOpacitySliderProps {
    tileLayer: ListingDTO;
    opacity?: number;
}

const ActiveMapOpacitySlider = (props: ActiveMapOpacitySliderProps) => {
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);

    const dispatch = useDispatch();
    const setTileLayerOpacity = (tileLayerId: number, opacity: number): void => {
        dispatch(actionSetTileLayerOpacity(tileLayerId, opacity));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sliderRef = useRef<any>(null);
    const [opacity, setOpacity] = useState(
        props.opacity ? props.opacity : tileLayersOpacity[props.tileLayer.id] || 1.0
    );

    useEffect(() => {
        if (sliderRef && sliderRef.current) {
            sliderRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const handleClearOpacity = () => {
            setOpacity(1);
            dispatch(actionSetTileLayerOpacity(props.tileLayer.id, 1));
        };
        return () => {
            handleClearOpacity();
        };
    }, [dispatch, props.tileLayer.id]);

    return (
        <SliderContainer className="opacity-slider">
            <SliderText>Transparency:</SliderText>
            <SliderValue>{(opacity * 100).toFixed()}%</SliderValue>

            <SliderInputContainer>
                <Slider
                    ref={sliderRef}
                    type="range"
                    value={opacity * 100}
                    step={0.01}
                    onChange={(e) => {
                        if (document.activeElement === document.getElementById('listing-comment-input')) {
                            document.getElementById('listing-comment-input')?.blur();
                        }

                        const opacity = parseInt(e.target.value) / 100;
                        setOpacity(opacity);
                        setTileLayerOpacity(props.tileLayer.id, opacity || 0.0001);
                    }}
                />
            </SliderInputContainer>
        </SliderContainer>
    );
};

export default ActiveMapOpacitySlider;

const SliderContainer = styled.div`
    width: 100%;
    padding: 14px 10px;
    display: flex;
`;

const SliderText = styled.p`
    vertical-align: center;
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
`;

const SliderValue = styled.p`
    vertical-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    margin: 0px 5px;
    padding: 0;
`;

const SliderInputContainer = styled.div`
    width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 12px;
    pointer-events: all;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: all;
    }

    &:focus {
        pointer-events: all;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
