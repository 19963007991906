import { defaultCircleOptions } from '../../components/MapView/Annotations/Circle/circle';
import { defaultPolygonOptions } from '../../components/MapView/Annotations/Polygon/polygon';
import { defaultRectangleOptions } from '../../components/MapView/Annotations/Rectangle/rectangle';
import { Pattern } from '../../components/MapView/Annotations/FillPattern/availablePatterns';
import store from '../store';
import { actionTypes as at } from './constants';
import { Annotation } from './reducer';

export const deleteAllAnnotationsAction = () => {
    return (dispatch) => {
        // Dispatch the action to clear fill patterns
        dispatch(setDeleteAllFillPatternsAction());

        // Dispatch the action to clear all annotations
        dispatch({
            type: at.CLEAR_ALL,
            payload: {},
        });
        // TODO: This could be done better but calling an action never fires the clear in the present...
        store.getState().annotationDomain.present.freehandReducer.freehandDraws = [];
        store.getState().annotationDomain.present.markerReducer.markers = [];
        store.getState().annotationDomain.present.militaryMarkerReducer.militaryMarkers = [];
        store.getState().annotationDomain.present.pathReducer.polylines = [];
        store.getState().annotationDomain.present.polygonReducer.polygons = [];
        store.getState().annotationDomain.present.arrowReducer.arrows = [];
        store.getState().annotationDomain.present.circleReducer.circles = [];
        store.getState().annotationDomain.present.rectangleReducer.rectangles = [];
        store.getState().annotationDomain.present.textboxReducer.textBoxes = [];
        store.getState().annotationDomain.present.imageReducer.images = [];
        store.getState().annotationDomain.present.coordinateReducer.coordinates = [];

        store.getState().annotationDomain.present.polygonReducer.polygonOptions = defaultPolygonOptions;
        store.getState().annotationDomain.present.circleReducer.circleOptions = defaultCircleOptions;
        store.getState().annotationDomain.present.rectangleReducer.rectangleOptions = defaultRectangleOptions;

        return {
            type: at.CLEAR_ALL,
            payload: {},
        };
    };
};

export const setSelectedAnnotationAction = (annotation: Annotation) => {
    return {
        type: at.SET_SELECTED_ANNOTATION,
        payload: { selectedAnnotation: annotation },
    };
};

export const setZIndexAction = (zIndex: number) => {
    return {
        type: at.SET_CURRENT_Z_INDEX,
        payload: { zIndex: zIndex },
    };
};

export const incrementZIndexAction = () => {
    return {
        type: at.INCREMENT_CURRENT_Z_INDEX,
        payload: {},
    };
};

export const setSelectedFillPatternAction = (patterns: Pattern[]) => {
    return {
        type: at.SET_ANNOTATION_FILL_PATTERNS,
        payload: { fillPatterns: patterns },
    };
};

export const setDeleteAllFillPatternsAction = () => {
    return {
        type: at.SET_ANNOTATION_FILL_PATTERNS,
        payload: { fillPatterns: [] },
    };
};

export const setDeleteFillPatternAction = (shapeId: string) => {
    const patterns = store.getState().annotationDomain.present.annotationReducer.fillPatterns;
    if (!patterns)
        return {
            type: at.SET_ANNOTATION_FILL_PATTERNS,
            payload: { fillPatterns: [] },
        };
    const newPatterns = patterns.filter((pattern) => pattern.shapeId !== shapeId);
    return {
        type: at.SET_ANNOTATION_FILL_PATTERNS,
        payload: { fillPatterns: newPatterns },
    };
};
