import { useState } from 'react';
import styled from 'styled-components';

interface ExploreSearchProps {
    handleKeywordSearch: (keyword: string) => void;
    resetCategory?: () => void;
    searchTerm?: string;
}

const ExploreSearch = (props: ExploreSearchProps) => {
    const [searchTerm, setSearchTerm] = useState(props.searchTerm);

    return (
        <ExploreSearchContainer>
            <ExploreSearchIcon className="fa fa-search" />
            <ExploreSearchInput
                type="text"
                placeholder="Coordinates, Countries, Places, Users, etc."
                value={searchTerm}
                onFocus={props.resetCategory}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    props.handleKeywordSearch(e.target.value);
                }}
            />
        </ExploreSearchContainer>
    );
};

export default ExploreSearch;

const ExploreSearchContainer = styled.div`
    width: 100%;
    flex-direction: row;
    padding: 1rem 0rem 1rem 1rem;

    @media only screen and (max-width: 600px) {
        padding: 0rem 0.5rem 0rem 0.75rem;
    }
`;

const ExploreSearchIcon = styled.i`
    size: 20px;
    padding: 10px;
    position: absolute;
    color: #eed926;
`;

const ExploreSearchInput = styled.input`
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid rgb(255 255 255 / 0.3) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    padding-left: 30px;
    min-height: 35px;
    width: 100%;

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :focus {
        background: transparent;
        outline: #eed926;
        border: 1px solid #eed926;
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;
