import { StoaryProject } from '../../../api/stoaryModel';
import { Annotation } from '../../../store/Annotations/reducer';
import store from '../../../store/store';
import L from 'leaflet';

export const defaultZIndex = 401;
export const selectedOutlinePathZIndex = 600;
export const controlPaneZIndex = 700;
export const builderPaneZIndex = 800;

export default class LayersUtil {
    static getControlPaneId(map: L.Map): string {
        const controlPaneId = 'annotation-control-pane';
        let pane = map.getPane(controlPaneId);
        if (!pane) {
            pane = map.createPane(controlPaneId);
            pane.style.zIndex = `${controlPaneZIndex}`;
        }

        return controlPaneId;
    }

    static getPaneId(map: L.Map, annotation: Annotation): string {
        if (!annotation) throw Error('Annotation is undefined');
        const currentZIndex = store.getState().annotationDomain.present.currentZIndex;

        const paneId = `annotation-${annotation.id}`;
        let pane = map.getPane(paneId);
        if (!pane) {
            pane = map.createPane(paneId);
        }
        pane.style.zIndex = `${annotation.zIndex || currentZIndex}`;

        return paneId;
    }

    static getSelectedOutlinePaneId(map: L.Map): string {
        const selectedOutlinePaneId = 'annotation-selected-outline-pane';
        let pane = map.getPane(selectedOutlinePaneId);
        if (!pane) {
            pane = map.createPane(selectedOutlinePaneId);
            pane.style.zIndex = `${selectedOutlinePathZIndex}`;
        }
        return selectedOutlinePaneId;
    }

    static getBuilderPaneId(map: L.Map): string {
        const builderPaneId = 'annotation-builder-pane';
        let pane = map.getPane(builderPaneId);
        if (!pane) {
            pane = map.createPane(builderPaneId);
            pane.style.zIndex = `${builderPaneZIndex}`;

            const tooltipPane = document.querySelector('.leaflet-tooltip-pane');
            if (tooltipPane) {
                (tooltipPane as HTMLElement).style.zIndex = `${builderPaneZIndex + 10}`;
            }
        }

        return builderPaneId;
    }

    static removePane(map: L.Map, annotation: Annotation) {
        if (!annotation) throw Error('Annotation is undefined');
        const pane = map.getPane(`annotation-${annotation.id}`);
        if (pane) pane.remove();
    }

    static setAnnotationZIndex(annotation: Annotation, zIndex: number) {
        if (!annotation) throw Error('Annotation is undefined');
        const paneId = `leaflet-annotation-${annotation.id}-pane`;
        const pane = document.getElementsByClassName(paneId);
        if (pane && pane[0]) (pane[0] as HTMLElement).style.zIndex = `${zIndex}`;
    }

    static getLayerGroupBounds = (project: StoaryProject): L.LatLngBounds | undefined => {
        const featureGroup = L.featureGroup();

        project.markers.forEach((marker) => {
            const { position } = marker;
            L.marker([position.lat, position.lng]).addTo(featureGroup);
        });

        project.militaryMarkers?.forEach((marker) => {
            const { position } = marker;
            L.marker([position.lat, position.lng]).addTo(featureGroup);
        });

        project.coordinates?.forEach((coordinate) => {
            const { position } = coordinate;
            L.marker([position.lat, position.lng]).addTo(featureGroup);
        });

        project.polylines?.forEach((polyline) => {
            const { positions } = polyline;
            L.polyline(positions.map((pos) => [pos.lat, pos.lng])).addTo(featureGroup);
        });

        project.polygons?.forEach((polygon) => {
            const { positions } = polygon;
            L.polygon(positions.map((pos) => [pos.lat, pos.lng])).addTo(featureGroup);
        });

        project.circles?.forEach((circle) => {
            const { center, radius } = circle;
            const circleBounds = L.latLng(center.lat, center.lng).toBounds(radius);
            L.rectangle(circleBounds).addTo(featureGroup);
        });

        project.rectangles?.forEach((rectangle) => {
            const { bounds } = rectangle;
            L.rectangle(bounds).addTo(featureGroup);
        });

        project.freehandDraws?.forEach((freehand) => {
            const { positions } = freehand;
            L.polyline(positions.map((pos) => [pos.lat, pos.lng])).addTo(featureGroup);
        });

        project.arrows?.forEach((arrow) => {
            const { startLatLng, endLatLng } = arrow;
            L.polyline([startLatLng, endLatLng]).addTo(featureGroup);
        });

        project.images?.forEach((image) => {
            const { bounds } = image;
            L.rectangle(bounds).addTo(featureGroup);
        });

        project.textBoxes?.forEach((textBox) => {
            const { bounds } = textBox;
            L.rectangle(bounds).addTo(featureGroup);
        });

        return featureGroup.getBounds();
    };

    static getProjectInsideBounds = (
        project: StoaryProject,
        bounds: L.LatLngBounds,
        padding: number
    ): { isInsideBounds: boolean; projectBounds: L.LatLngBounds } => {
        const featureGroupBounds = LayersUtil.getLayerGroupBounds(project);
        if (!featureGroupBounds || !featureGroupBounds?.getSouthWest() || !featureGroupBounds?.getNorthEast()) {
            return { isInsideBounds: false, projectBounds: bounds };
        }

        // Check if the featureGroupBounds is inside the bounds or intersects with the bounds to determine if the project is inside the bounds or close to it
        const boundsContains = bounds.contains(featureGroupBounds) || bounds.intersects(featureGroupBounds);
        return {
            isInsideBounds: boundsContains,
            projectBounds: featureGroupBounds ? featureGroupBounds.pad(padding) : bounds,
        };
    };
}
