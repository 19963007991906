import L from 'leaflet';

import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
} from '@react-leaflet/core';

import MathUtil from '../../../../lib/math-util';
import { selectedPolygonOutlineOptions } from '../Polygon/polygon';
import LayersUtil from '../layers-util';
import Arrow from './arrow';
import { lineLengthAsPixels } from './arrow-annotation-utils';
import { createArrowDragElement } from './arrow-drag-element';
import { createArrowEditElement } from './arrow-edit-element';
import { createArrowHeadElement } from './arrow-head';

interface ArrowAnnotationProps extends L.PolylineOptions {
    isSelected: boolean;
    isDisabled?: boolean;
    arrow: Arrow;
    onUpdateArrow?: (arrow: Arrow) => void;
    onDeselect: () => void;
    children?: React.ReactNode;
}

const createArrowAnnotation = (props: ArrowAnnotationProps, context: LeafletContextInterface) => {
    const pane = LayersUtil.getPaneId(context.map, props.arrow);
    // The arrow shaft is the visible polyline, we remove the burr from the end of this polyline
    const arrow = new L.Polyline([props.arrow.startLatLng, props.arrow.endLatLng], {
        ...props.arrow.options,
        pane: pane,
    });
    const arrowElement = createElementObject<L.Polyline, ArrowAnnotationProps>(arrow, context);

    // The arrow shaft is the invisible polyline we use to detect mouse events
    const arrowShaft = new L.Polyline([props.arrow.startLatLng, props.arrow.endLatLng], {
        ...props.arrow.options,
        color: 'transparent',
        pane: pane,
    });
    const arrowShaftElement = createElementObject<L.Polyline, ArrowAnnotationProps>(arrowShaft, context);

    // The arrow head is a marker with a DivIcon and a rotation transform
    const arrowHeadElement = createArrowHeadElement(
        {
            id: props.arrow.id,
            arrowShaftElement: arrowShaftElement,
            color: props.arrow.options.color,
            isDisabled: props.isDisabled,
        },
        context,
        pane
    );

    // The arrow drag element to allow selection and drag movement of the arrow by the shaft
    const arrowDragElement = createArrowDragElement(
        { arrowShaftElement: arrowShaftElement, isSelected: props.isSelected },
        context
    );

    // The arrow edit is placed once the arrow is selected. It allows moving the arrow start and end points
    const arrowEditElement = createArrowEditElement(
        { arrowShaftElement: arrowShaftElement, arrowHeadElement: arrowHeadElement },
        context
    );

    const positionsForBoundsOfPolygon = (positions: L.LatLng[]): L.LatLng[] => {
        const positionsTuple = positions.map((t) => [t.lat, t.lng] as L.LatLngTuple);
        const bounds = new L.LatLngBounds(positionsTuple);
        const boundsPositions = [
            bounds.getNorthEast(),
            bounds.getNorthWest(),
            bounds.getSouthWest(),
            bounds.getSouthEast(),
        ];
        return boundsPositions;
    };

    const selectedOutlinePaneId = LayersUtil.getSelectedOutlinePaneId(context.map);
    const arrowBounds = new L.Polygon(positionsForBoundsOfPolygon([props.arrow.startLatLng, props.arrow.endLatLng]), {
        ...selectedPolygonOutlineOptions,
        pane: selectedOutlinePaneId,
    });
    const arrowBoundsElement = createElementObject<L.Polygon, ArrowAnnotationProps>(arrowBounds, context);

    // The burr is the extra bit of the arrow that extends past the arrow head icon
    const removeBurr = () => {
        const startLatLng = arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
        const startPoint = context.map.latLngToLayerPoint(startLatLng);
        const endPoint = context.map.latLngToLayerPoint(endLatLng);
        const pixelLengthOfArrow = startPoint.distanceTo(endPoint);
        const fractionToRemove = 10;
        const lerpAsFractionAmount = Math.max(0, Math.min(1, 1 - fractionToRemove / pixelLengthOfArrow));
        const lerpAmount = isNaN(lerpAsFractionAmount) || !isFinite(lerpAsFractionAmount) ? 1 : lerpAsFractionAmount;

        const newEndLat = MathUtil.lerp(startLatLng.lat, endLatLng.lat, lerpAmount);
        const newEndLng = MathUtil.lerp(startLatLng.lng, endLatLng.lng, lerpAmount);
        const newEndLatLng = new L.LatLng(newEndLat, newEndLng);

        arrowElement.instance.setLatLngs([startLatLng, newEndLatLng]);
    };

    const checkShaftLength = () => {
        const startLatLng = arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;

        const shaftLengthInPx = lineLengthAsPixels(context.map, startLatLng, endLatLng);

        if (shaftLengthInPx > 50) {
            arrowElement.instance.setStyle({
                ...props.arrow.options,
                opacity: 1,
            });
            arrowHeadElement.instance.setOpacity(1);

            arrowDragElement.instance.setStyle({ ...arrowDragElement.instance.options, interactive: false });
        } else {
            arrowElement.instance.setStyle({
                ...props.arrow.options,
                opacity: 0,
            });
            arrowHeadElement.instance.setOpacity(0);

            arrowDragElement.instance.setStyle({ ...arrowDragElement.instance.options, interactive: false });

            context.map.removeLayer(arrowEditElement.instance);
            context.map.removeLayer(arrowDragElement.instance);

            if (props.isSelected) {
                props.onDeselect();
            }
        }
    };

    const onMapZoom = () => {
        checkShaftLength();
        removeBurr();
    };

    arrowShaftElement.instance.on('add', () => {
        context.map.addLayer(arrowElement.instance);
        context.map.addLayer(arrowShaftElement.instance);
        context.map.addLayer(arrowHeadElement.instance);
        context.map.on('zoomend', onMapZoom);
        removeBurr();

        if (props.isSelected) {
            context.map.addLayer(arrowDragElement.instance);
            context.map.addLayer(arrowEditElement.instance);
            context.map.addLayer(arrowBoundsElement.instance);
        } else {
            context.map.removeLayer(arrowDragElement.instance);
            context.map.removeLayer(arrowEditElement.instance);
            context.map.removeLayer(arrowBoundsElement.instance);
        }
        checkShaftLength();
    });

    arrowShaftElement.instance.on('remove', () => {
        context.map.removeLayer(arrowShaftElement.instance);
        context.map.removeLayer(arrowHeadElement.instance);
        context.map.removeLayer(arrowDragElement.instance);
        context.map.removeLayer(arrowEditElement.instance);
        context.map.removeLayer(arrowBoundsElement.instance);
        context.map.removeLayer(arrowElement.instance);

        context.map.off('zoomend', onMapZoom);
        context.map.dragging.enable();
    });

    arrowShaftElement.instance.on('update', () => {
        const startLatLng = arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
        arrowBoundsElement.instance.setLatLngs(positionsForBoundsOfPolygon([startLatLng, endLatLng]));
        arrowElement.instance.setLatLngs([startLatLng, endLatLng]);
        arrowHeadElement.instance.setLatLng(endLatLng);
        props.onUpdateArrow &&
            props.onUpdateArrow({
                ...props.arrow,
                startLatLng: startLatLng,
                endLatLng: endLatLng,
            });
        removeBurr();
    });

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        arrowShaftElement.instance.options.interactive = false;
    } else {
        arrowShaftElement.instance.options.interactive = true;
    }

    return arrowShaftElement;
};

const useArrowAnnotation = createElementHook<L.Polyline, ArrowAnnotationProps, LeafletContextInterface>(
    createArrowAnnotation
);

const useArrowAnnotationArrow = createPathHook<L.Polyline, ArrowAnnotationProps>(useArrowAnnotation);
const ArrowAnnotation = createContainerComponent(useArrowAnnotationArrow);

export default ArrowAnnotation;
