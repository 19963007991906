import styled from 'styled-components';

interface ImagePreviewProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    className?: string;
    type?: string;
}

const ImagePreview = (props: ImagePreviewProps) => {
    const orientation =
        props.width && props.height
            ? props.width === props.height
                ? 'square'
                : Number(props.height) / Number(props.width) < 0.75
                ? 'landscape'
                : 'portrait'
            : 'portrait';

    return (
        <MainImage
            data={props.src}
            width={props.width}
            height={props.height}
            className={`${orientation} ${props.className}`}
            type={props.type ?? 'image/png'}
            title={props.alt}
            aria-label={props.alt}
        >
            <FallbackContainer orientation={orientation}>
                <FallbackImage src="/assets/image-unavailable/preview_fallback.svg" loading="lazy" />
                <FallbackMessage>Preview not available</FallbackMessage>
            </FallbackContainer>
        </MainImage>
    );
};

export default ImagePreview;

const MainImage = styled.object`
    margin: 0 auto;
    object-fit: cover;
    position: relative;
`;

const FallbackImage = styled.img`
    width: auto;
    height: auto;
`;
const FallbackMessage = styled.span`
    font-size: 1rem;
`;

const FallbackContainer = styled.div<{ orientation: string }>`
    display: flex;
    flex-direction: ${(props) => (props.orientation === 'landscape' ? 'row' : 'column')};
    justify-content: center;
    align-items: center;
    width: ${(props) => (['landscape', 'square'].includes(props.orientation) ? '100%' : '60%')};
    height: calc(100% -4px);
    margin: 2px auto;

    pointer-events: none;
    user-select: none;

    border: 0.5px solid ${(props) => props.theme.color.opaqueYellow};
    border-radius: 6px;

    ${FallbackImage} {
        -moz-transform: ${(props) => props.orientation === 'landscape' && 'scale(0.6)'};
        -webkit-transform: ${(props) => props.orientation === 'landscape' && 'scale(0.6)'};
        transform: ${(props) => props.orientation === 'landscape' && 'scale(0.6)'};
    }

    ${FallbackMessage} {
        width: ${(props) => (props.orientation === 'landscape' ? '100%' : '60%')};
        text-align: center;
        margin-top: ${(props) => props.orientation === 'portrait' && '10px'};
    }
`;
