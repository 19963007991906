import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import Arrow, { defaultArrowOptions } from './arrow';
import { v4 as uuidv4 } from 'uuid';
import { createArrowHeadElement } from './arrow-head';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';
import { createSnapPointElement } from '../SnapPoints/annotation-snap-points';

interface ArrowBuilderProps {
    onCreateArrow: (arrow: Arrow) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const createArrowBuilder = (props: ArrowBuilderProps, context: LeafletContextInterface) => {
    const lastUsedArrowOptions = store.getState().annotationDomain.present.arrowReducer.arrowOptions;

    let startLatLng: L.LatLng = new L.LatLng(0, 0);
    let endLatLng: L.LatLng = new L.LatLng(0, 0);

    const MINIMUM_LENGTH_FOR_SINGLE_CLICK_ARROW_CREATION = 30; //px
    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);

    const arrowPath = new L.Polyline([], {
        ...defaultArrowOptions,
        ...(lastUsedArrowOptions ?? {}),
        pane: builderPaneId,
    });

    const arrowPathElement = createElementObject<L.Polyline, ArrowBuilderProps>(arrowPath, context);

    const id = uuidv4();

    const arrowHead = createArrowHeadElement(
        {
            id: id,
            arrowShaftElement: arrowPathElement,
            color: lastUsedArrowOptions?.color ? lastUsedArrowOptions.color : '#EED926',
        },
        context,
        builderPaneId
    );

    const snapPointElement = createSnapPointElement(
        { instance: arrowPathElement.instance, pane: builderPaneId },
        context
    );

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancelBuild();
        }
    };

    arrowPathElement.instance.on('add', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        document.addEventListener('keydown', handleKeyDown);

        // For when the user clicks on the map, instead of dragging
        const handleSingleClickToBuildArrow = (position: L.LatLng) => {
            const screenPosition = context.map.latLngToContainerPoint(position);
            const arrowSize = 120;
            const endScreenPosition = L.point(screenPosition.x + arrowSize, screenPosition.y);
            const endPosition = context.map.containerPointToLatLng(endScreenPosition);
            const newArrow: Arrow = {
                annotationType: 'Arrow',
                id: id,
                zIndex: props.zIndex || defaultZIndex,
                startLatLng: position,
                endLatLng: endPosition,
                options: arrowPathElement.instance.options,
            };

            props.onCreateArrow(newArrow);
        };

        const isArrowBelowMinimumSize = (start: L.LatLng, end: L.LatLng): boolean => {
            const startScreenPosition = context.map.latLngToContainerPoint(start);
            const endScreenPosition = context.map.latLngToContainerPoint(end);
            const length = startScreenPosition.distanceTo(endScreenPosition);
            return length < MINIMUM_LENGTH_FOR_SINGLE_CLICK_ARROW_CREATION;
        };

        const onMouseMove = (e: L.LeafletMouseEvent) => {
            endLatLng = e.latlng;
            arrowPathElement.instance.setLatLngs([startLatLng, endLatLng]);
            arrowHead.instance.setLatLng(endLatLng);
            arrowHead.instance.fire('update', { ...e, startLatLng: startLatLng, endLatLng: endLatLng });
        };

        const onMouseUp = (e: L.LeafletMouseEvent) => {
            context.map.removeLayer(snapPointElement.instance);
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            context.map.off('mouseup', onMouseUp);
            context.map.dragging.enable();

            const origin = new L.LatLng(0, 0);
            if (startLatLng.equals(origin) || endLatLng.equals(origin)) {
                handleSingleClickToBuildArrow(e.latlng);
                return;
            }

            if (isArrowBelowMinimumSize(startLatLng, endLatLng)) {
                handleSingleClickToBuildArrow(e.latlng);
                return;
            }

            snapPointElement.instance.fireEvent('update-arrow', e);

            const newArrow: Arrow = {
                annotationType: 'Arrow',
                id: id,
                startLatLng: startLatLng,
                endLatLng: (arrowPathElement.instance.getLatLngs()[1] as L.LatLng) || endLatLng,
                options: arrowPathElement.instance.options,
                zIndex: props.zIndex || defaultZIndex,
            };

            props.onCreateArrow(newArrow);
        };

        const onMouseDown = (e: L.LeafletMouseEvent) => {
            context.map.addLayer(snapPointElement.instance);
            arrowPath.setLatLngs([e.latlng, e.latlng]);
            startLatLng = e.latlng;
            context.map.dragging.disable();

            arrowHead.instance.setLatLng(startLatLng);
            context.map.addLayer(arrowHead.instance);

            context.map.on('mousemove', onMouseMove);
            context.map.on('mouseup', onMouseUp);
        };

        context.map.on('mousedown', onMouseDown);

        arrowPathElement.instance.on('remove', () => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

            context.map.removeLayer(snapPointElement.instance);
            context.map.removeLayer(arrowHead.instance);
            context.map.removeLayer(arrowPathElement.instance);
            context.map.off('mousedown', onMouseDown);
            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            document.removeEventListener('keydown', handleKeyDown);
        });
    });

    return arrowPathElement;
};

const useArrowBuilder = createElementHook<L.Polyline, ArrowBuilderProps, LeafletContextInterface>(createArrowBuilder);

const ArrowBuilder = (props: ArrowBuilderProps) => {
    const context = useLeafletContext();
    const arrowBuilder = useArrowBuilder(props, context);
    useLayerLifecycle(arrowBuilder.current, context);
    return null;
};

export default ArrowBuilder;
