import { actionTypes as at } from './constants';
import { LatLngBounds } from 'leaflet';
import { SatelliteArchiveImageryDTO } from '../../../api/api-supplier';

// TODO The file name and the use of Archival in some of these function names is misleading, also used for new collections
export const actionSatelliteBeginBoxSelect = () => {
    return {
        type: at.SATELLITE_BEGIN_BOX_SELECT,
    };
};

export const actionSatelliteEndBoxSelect = () => {
    return {
        type: at.SATELLITE_END_BOX_SELECT,
    };
};

export const actionSatelliteSelectAOI = (aoi: LatLngBounds) => {
    return {
        type: at.SATELLITE_SELECT_AOI,
        payload: aoi,
    };
};

export const actionSatelliteResetAOI = () => {
    return {
        type: at.SATELLITE_RESET_AOI,
        payload: undefined,
    };
};

export const actionSatelliteSetFeatureActive = (feature: SatelliteArchiveImageryDTO) => {
    return {
        type: at.SATELLITE_SET_FEATURE_ACTIVE,
        payload: feature,
    };
};

export const actionSatelliteSetFeatureInactive = (feature: SatelliteArchiveImageryDTO) => {
    return {
        type: at.SATELLITE_SET_FEATURE_INACTIVE,
        payload: feature,
    };
};

export const actionSatelliteResetActiveFeatures = () => {
    return {
        type: at.SATELLITE_RESET_ACTIVE_FEATURES,
    };
};

export const actionSatelliteMouseOver = (feature: SatelliteArchiveImageryDTO) => {
    return {
        type: at.SATELLITE_FEATURE_MOUSE_OVER,
        payload: feature,
    };
};

export const actionSatelliteMouseOut = () => {
    return {
        type: at.SATELLITE_FEATURE_MOUSE_OUT,
    };
};

export const actionSatelliteReorderZIndex = (reorder: { [sceneId: number]: number }) => {
    return {
        type: at.SATELLITE_REORDER_Z_INDEX,
        payload: reorder,
    };
};
