import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SoarModal from '../../../../Shared/soar-modal';
import StoryBuilderPageItemMapLayer from './story-builder-page-item-map-layer';
import StoryBuilderPageItemMapsAddButton from './story-builder-page-item-maps-add-button';
import { Divider } from '../../ToolBar/toolbar-modal';
import { StyledButton } from '../../../../Shared/styled-button';
import SearchModal from './SearchModal/search-modal';
import { MarkdownTextArea } from '../../../../Shared/markdown-textarea';
import Analytics from '../../../../../lib/user-analytics';
import StoryBuilderBaseMapControl from './story-builder-basemap-control';
import { selectLeafletMap } from '../../../../../store/App/selectors';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import { StoaryPage, StoaryPageMapLayer } from '../../../../../api/stoaryModel';
import { selectDeletedMaps } from '../../../../../store/Map/SuperMap/selectors';

interface StoryBuilderPageDetailsProps {
    isOpen: boolean;
    page: StoaryPage;
    onToggle: () => void;
    onUpdatePageTitle: (title: string) => void;
    onUpdatePageDescription: (description: string) => void;
    onSelectMapLayer: (mapLayer: StoaryPageMapLayer) => void;
    onUpdateMapLayerFromPage: (mapLayer: StoaryPageMapLayer) => void;
    onDeleteMapLayerFromPage: (mapLayer: StoaryPageMapLayer) => void;
    onMovePageUp: () => void;
    onMovePageDown: () => void;
    onSetPageViewport: () => void;
    onSetAllPagesViewport: () => void;
}

const PageTitleMaxLength = 100;
const UPDATE_VIEWPORT_TEXT_TIMEOUT = 300;
const PIXEL_DISTANCE_THRESHOLD = 50;

const StoryBuilderPageDetailsModal = (props: StoryBuilderPageDetailsProps) => {
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [isUpdatedViewPort, setIsUpdatedViewPort] = useState(false);
    const [pageTitle, setPageTitle] = useState<string | undefined>(props.page.pageTitle);

    const map = useSelector(selectLeafletMap);
    const deletedMaps = useSelector(selectDeletedMaps);

    const mapLayers = props.page.mapLayers
        ? props.page.mapLayers.filter((mapLayer) =>
              mapLayer.listingId ? !deletedMaps.includes(mapLayer.listingId) : true
          )
        : ([] as StoaryPageMapLayer[]);

    const hasViewportPixelChanged = (
        map: L.Map,
        mapBounds: L.LatLngBounds,
        viewportBounds: L.LatLngBounds | L.LatLng
    ) => {
        const mapSouthWestPoint = map.latLngToLayerPoint(mapBounds.getSouthWest());
        const mapNorthEastPoint = map.latLngToLayerPoint(mapBounds.getNorthEast());
        // @ts-ignore - Leaflet private properties
        const viewportSouthWestPoint = map.latLngToLayerPoint(viewportBounds._southWest);
        // @ts-ignore - Leaflet private properties
        const viewportNorthEastPoint = map.latLngToLayerPoint(viewportBounds._northEast);

        const southWestPixelDistance = mapSouthWestPoint.distanceTo(viewportSouthWestPoint);
        const northEastPixelDistance = mapNorthEastPoint.distanceTo(viewportNorthEastPoint);

        return southWestPixelDistance > PIXEL_DISTANCE_THRESHOLD || northEastPixelDistance > PIXEL_DISTANCE_THRESHOLD;
    };

    // It is a little confusing if the map view is set and they open this modal and the 'Map view set' is displayed
    // but they viewport has been updated since the map view was set.
    // To reduce the misunderstanding display a message to the user to update the map view if they want.
    const handleHasLockedMapViewportUpdated = () => {
        if (props.page.allViewportsLocked || !map) {
            return '';
        }

        const viewportBounds = props.page.project?.viewportBounds;
        const mapBounds = map?.getBounds();

        if (!viewportBounds || !mapBounds) {
            return '';
        }

        const boundsMatch = !hasViewportPixelChanged(map, mapBounds, viewportBounds);

        if (!boundsMatch) {
            return 'Update map view set';
        } else {
            return 'Map view set';
        }
    };

    const handleHasAllLockedMapViewportUpdated = () => {
        if (props.page.viewportLocked || !map) {
            return '';
        }

        const viewportBounds = props.page.project?.viewportBounds;
        const mapBounds = map?.getBounds();

        if (!viewportBounds || !mapBounds) {
            return '';
        }

        const boundsMatch = !hasViewportPixelChanged(map, mapBounds, viewportBounds);

        if (!boundsMatch) {
            return 'Update map views set';
        } else {
            return 'Map views set';
        }
    };

    useEffect(() => {
        // Visual effect that something has changed when the user clicks the set view button
        setTimeout(() => {
            setIsUpdatedViewPort(false);
        }, UPDATE_VIEWPORT_TEXT_TIMEOUT);
    }, [isUpdatedViewPort]);

    return (
        <SoarModal isOpen={props.isOpen} toggle={() => props.onToggle()} title={`Edit page details`}>
            <Container>
                <TextFieldContainer>
                    <Label>Page Title:</Label>
                    <TextField
                        value={pageTitle ?? ''}
                        onChange={(e) => {
                            setPageTitle(e.target.value);
                            props.onUpdatePageTitle(e.target.value);
                        }}
                        maxLength={PageTitleMaxLength}
                    />
                    <TextFieldNotice>
                        <TextFieldLimit>
                            Characters: {pageTitle?.length ?? 0}/{PageTitleMaxLength}
                        </TextFieldLimit>
                    </TextFieldNotice>
                </TextFieldContainer>
                <TextFieldContainer>
                    <Label>Page Description:</Label>
                    <TextArea
                        value={props.page.description}
                        setInputValue={props.onUpdatePageDescription}
                        initialEditorHeight={120}
                        placeholder="Add a description for this page..."
                    />
                </TextFieldContainer>

                <Label>Map View Settings:</Label>
                <Row>
                    <ControlButton
                        className="set-view-button"
                        onClick={() => {
                            setIsUpdatedViewPort(true);
                            props.onSetPageViewport();
                        }}
                    >
                        <ControlButtonIcon className="fa fa-expand" />
                        Set this page to the current map view
                    </ControlButton>
                    {props.page.viewportLocked && !isUpdatedViewPort ? (
                        <ViewportNotificationText>
                            <span>{handleHasLockedMapViewportUpdated()}</span>
                        </ViewportNotificationText>
                    ) : (
                        <ViewportNotificationText />
                    )}
                </Row>
                <Row>
                    <ControlButton
                        className="set-view-button"
                        onClick={() => {
                            setIsUpdatedViewPort(true);
                            props.onSetAllPagesViewport();
                        }}
                    >
                        <ControlButtonIcon className="fa fa-expand-arrows-alt" />
                        Set every page to the current map view
                    </ControlButton>
                    {props.page.allViewportsLocked && !isUpdatedViewPort ? (
                        <ViewportNotificationText>
                            <span>{handleHasAllLockedMapViewportUpdated()}</span>
                        </ViewportNotificationText>
                    ) : (
                        <ViewportNotificationText />
                    )}
                </Row>

                <Label>Map Settings:</Label>
                {mapLayers.length > 0 ? (
                    mapLayers.map((mapLayer) => (
                        <StoryBuilderPageItemMapLayer
                            mapLayer={mapLayer}
                            setShowSearchModal={() => {
                                Analytics.Event('Stoary - Builder', `Clicked to replace map on stoary page`);
                                setShowSearchModal(true);
                            }}
                            onUpdateMapLayerFromPage={props.onUpdateMapLayerFromPage}
                            onDeleteMapLayerFromPage={props.onDeleteMapLayerFromPage}
                        />
                    ))
                ) : (
                    <StoryBuilderPageItemMapsAddButton
                        setShowSearchModal={() => {
                            Analytics.Event('Stoary - Builder', `Clicked to add new map to stoary page`);
                            setShowSearchModal(true);
                        }}
                    />
                )}

                <SearchModal
                    isOpen={showSearchModal}
                    toggle={() => setShowSearchModal(!showSearchModal)}
                    onSelectMapLayer={(mapLayer: StoaryPageMapLayer) => {
                        Analytics.Event('Stoary - Builder', `Added map layer to stoary from search`);
                        setShowSearchModal(false);

                        props.onSelectMapLayer(mapLayer);
                    }}
                />

                {props.page.activeBaseMap ? (
                    <React.Fragment>
                        <Label>Basemap Setting:</Label>
                        <Row>
                            <StoryBuilderBaseMapControl basemap={props.page.activeBaseMap} />
                        </Row>
                        <br />
                    </React.Fragment>
                ) : null}

                <Label>Page Ordering:</Label>
                <Row>
                    <ControlButton onClick={props.onMovePageUp}>
                        <ControlButtonIcon className="fa fa-chevron-up" />
                        Move Page Up
                    </ControlButton>

                    <ControlButton onClick={props.onMovePageDown}>
                        <ControlButtonIcon className="fa fa-chevron-down" />
                        Move Page Down
                    </ControlButton>
                </Row>

                <Divider />
                <SaveButton onClick={() => props.onToggle()}>Save and close</SaveButton>
            </Container>
        </SoarModal>
    );
};

export default StoryBuilderPageDetailsModal;

const Container = styled.div`
    margin: 16px;
`;

const TextFieldContainer = styled.div`
    margin-bottom: 8px;
`;

const Label = styled.label`
    color: ${(props) => props.theme.color.lightGray};
    font-size: 0.9rem;
    font-weight: bold;
    margin: 5px 3px 2px 0;
`;

const TextFieldNotice = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TextFieldLimit = styled.span`
    font-size: 0.8rem;
    margin-left: auto;
`;

const TextField = styled.input`
    width: 100%;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 1.2rem;
    outline: none;
    color: white;

    :hover {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :-webkit-autofill {
        :hover {
            border: 1px solid ${(props) => props.theme.color.lightGray};
        }
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :focus {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }
`;

const TextArea = styled(MarkdownTextArea)`
    width: 100%;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 1.2rem;
    outline: none;
    color: white;

    :hover {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :-webkit-autofill {
        :hover {
            border: 1px solid ${(props) => props.theme.color.lightGray};
        }
        border: 1px solid ${(props) => props.theme.color.gray};
    }

    :focus {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1px;
`;

const ViewportNotificationText = styled.span`
    margin: 6px 0 0 6px;
    white-space: nowrap;
    display: flex;
    animation: ease-in-out 0.3s;

    span {
        margin-left: 8px;
        color: ${(props) => props.theme.color.red} !important;
    }
`;

const ControlButton = styled.button`
    display: flex;
    flex-direction: row;
    padding: 6px !important;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: ${(props) => props.theme.borderRadius};
    margin-bottom: 12px;
    color: white;
    background: none !important;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.6;
        border: 1px solid ${(props) => props.theme.color.lightGray} !important;
    }

    &.set-view-button {
        width: 300px;
    }
`;

const ControlButtonIcon = styled.i`
    margin: 0;
    padding: 0;
    margin-right: 12px;
    display: block;
    font-size: 22px;
    width: 22px;
    color: ${(props) => props.theme.color.yellow};
`;

const SaveButton = styled(StyledButton)`
    margin: 0 auto;
    display: block;
`;
