import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { ListingDTO } from '../../../../../api/model';
import Analytics from '../../../../../lib/user-analytics';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import { actionPinTileLayer, actionUnpinTileLayer } from '../../../../../store/Map/TileLayer/actions';
import { selectPinnedTileLayers } from '../../../../../store/Map/TileLayer/selectors';

const PinMap = () => {
    const [isPinned, setPinned] = useState<boolean>(false);
    const pinnedTileLayers = useSelector(selectPinnedTileLayers);
    const selectedMap = useSelector(selectActiveAllMap);

    const dispatch = useDispatch();

    const setPinnedTileLayer = (listing: ListingDTO) => {
        dispatch(actionPinTileLayer(listing));
        Analytics.Event('Active Map', 'Clicked to pin', listing.id);
    };
    const setUnPinnedTileLayer = (listing: ListingDTO) => {
        dispatch(actionUnpinTileLayer(listing));
        Analytics.Event('Active Map', 'Clicked to unpin', listing.id);
    };

    const pinMap = (selectedMap: ListingDTO) => {
        Analytics.Event('Side Drawer', `${isPinned ? 'Added' : 'Removed'} pin`, selectedMap.id);
        if (isPinned) {
            setUnPinnedTileLayer(selectedMap);
        } else {
            setPinnedTileLayer(selectedMap);
        }

        setPinned(!isPinned);
    };

    useEffect(() => {
        const checkPinned = (selectedMap) => {
            return pinnedTileLayers.filter((t) => t.id == selectedMap.id).length == 1;
        };

        if (selectedMap) {
            setPinned(checkPinned(selectedMap));
        }
    }, [pinnedTileLayers, selectedMap, selectedMap?.id]);

    if (!selectedMap) {
        return <React.Fragment />;
    }

    return (
        <PinIcon
            title="Pin map"
            className={`fa fa-thumbtack ${isPinned ? ' pinned' : ''}`}
            onClick={() => pinMap(selectedMap)}
            isPinned={isPinned}
            data-testid="map-pin"
        />
    );
};

export default PinMap;

interface PinIconProps {
    isPinned: boolean;
}

const PinPulseAnimation = keyframes`
    0% {
        transform: scale( 1 );
    } 20% {
        transform: scale( 1.25 );
    } 40% {
        transform: scale( 1 );
    } 60% {
        transform: scale( 1.25 );
    } 80% {
        transform: scale( 1 );
    } 100% {
        transform: scale( 1.25 );
    }
`;

// TODO we should standardise the icons and how they behave
const PinIcon = styled.i<PinIconProps>`
    cursor: pointer;
    color: ${(props) => (props.isPinned ? '#FF5A5A' : 'white')};
    font-size: 18px;
    pointer-events: all;
    margin: 0px 0px 10px 0px;

    &:hover {
        color: #eed926;
    }

    &.pinned {
        animation: ${PinPulseAnimation} 1s;
        animation-iteration-count: 1;
    }

    @media screen and (max-width: 600px) {
        margin: 1px 16px 0 0;
    }
`;
