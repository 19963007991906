import { actionTypes as at } from './constants';
import { SideDrawerMode } from './model';

export const actionSetSideDrawerModeAction = (sideDrawerMode: SideDrawerMode) => {
    return {
        type: at.SIDEDRAWER_MODE,
        payload: sideDrawerMode,
    };
};

export const actionSetSideDrawerCollapseAction = (sideDrawerOpen: boolean) => {
    return {
        type: at.SIDEDRAWER_COLLAPSE,
        payload: sideDrawerOpen,
    };
};
