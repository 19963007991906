import React from 'react';
import styled from 'styled-components';

export enum PreviewSize {
    SMALL,
    NORMAL,
    LARGE,
}

interface ImagePreviewFailedProps {
    previewSize?: PreviewSize;
    width?: string;
    className?: string;
}

const ImagePreviewFailed = ({ previewSize, width, className }: ImagePreviewFailedProps) => {
    return (
        <PreviewFailed className={className} previewSize={previewSize} width={width}>
            <PreviewFailedImage src="/assets/image-unavailable/preview_fallback.svg" />
            <PreviewFailedText>
                {previewSize === PreviewSize.SMALL ? (
                    'No preview'
                ) : (
                    <React.Fragment>
                        Preview not <br /> available
                    </React.Fragment>
                )}
            </PreviewFailedText>
        </PreviewFailed>
    );
};

export default ImagePreviewFailed;

const PreviewFailedImage = styled.img`
    width: 60%;
    height: auto;
    max-width: 95px;
    object-fit: cover;
`;

const PreviewFailedText = styled.div`
    font-size: ${(props) => props.theme.fontSize.large};
    color: white;
    text-align: center;
`;

const PreviewFailed = styled.div<ImagePreviewFailedProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: ${(props) => (props.width ? props.width : '80%')};
    height: 80%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin: 10px auto;

    ${PreviewFailedImage} {
        width: ${(props) => (props.previewSize === PreviewSize.SMALL ? '50%' : '60%')};
    }

    ${PreviewFailedText} {
        white-space: ${(props) => (props.previewSize === PreviewSize.SMALL ? 'nowrap' : 'normal')};
        font-size: ${(props) =>
            props.previewSize === PreviewSize.SMALL ? props.theme.fontSize.small : props.theme.fontSize.large};
        margin-top: ${(props) => (props.previewSize === PreviewSize.SMALL ? '2px' : '0px')};
    }
`;
