import { UploadCredentialsDTO } from './model';
import OSS from 'ali-oss';

export class OssUploader {
    private readonly client;

    constructor(credentials: UploadCredentialsDTO, refreshCredentials?: () => Promise<UploadCredentialsDTO>) {
        const options: OSS.Options = {
            accessKeyId: credentials.stsCredentials.accessKeyId,
            accessKeySecret: credentials.stsCredentials.accessSecretKey,
            stsToken: credentials.stsCredentials.securityToken,
            bucket: credentials.bucketName,
            region: credentials.ossRegion,
            timeout: 900000,
        };
        if (refreshCredentials) {
            options.refreshSTSTokenInterval = 1800000;
            options.refreshSTSToken = async () => {
                const cred = await refreshCredentials();
                return {
                    accessKeyId: cred.stsCredentials.accessKeyId,
                    accessKeySecret: cred.stsCredentials.accessSecretKey,
                    stsToken: cred.stsCredentials.securityToken,
                };
            };
        }
        this.client = new OSS(options);
    }

    public uploadFileToStorage(file: File, filename: string): Promise<void> {
        return this.client
            .put(filename, file)
            .then((_) => {
                return true;
            })
            .catch((err) => {
                throw err;
            });
    }

    public async multipartUpload(file: File, ossKey: string, progress: (p: number) => void): Promise<void> {
        try {
            const res = await this.client.multipartUpload(ossKey, file, {
                progress: function (p, _, __) {
                    progress(Math.floor(p * 100));
                },
            });
            return res;
        } catch (err) {
            console.log(err);
        }
    }
}
