import styled from 'styled-components';

const StoariesCompetitionInstructions = () => {
    return (
        <TermsAndConditionsContainer>
            <TermsAndConditionsHero src="/assets/Stoaries_Comp_thumb_01.jpg" />
            <TermsAndConditionsHeader>
                <h3>Soar Stoaries Competition Instructions</h3>
                <p>Effective Date: 16 August 2024</p>
            </TermsAndConditionsHeader>
            <TermsAndConditionsBody>
                <h4>How it works</h4>
                <p>
                    Create a map on Soar using the new Soar Stoaries feature to have a chance at winning some great
                    prizes!
                </p>
                <p>To enter the competition you must:</p>
                <ul>
                    <li>Have an account registered with Soar and follow the Soar account on X.</li>
                    <li>Create a Stoary project of at least 6 pages on any topic, using the Soar Stoaries features</li>
                    <li>
                        Have the Stoary access set to public at the time of submission and until the completion of
                        judging and public for the competition
                    </li>
                    <li>
                        Post a publicly accessible link to your Stoary on X (Twitter) and tag the official Soar X
                        account (@Soar_Earth) and use the hashtag #StoaryComp in your post prior to the close of the
                        Competition.
                    </li>
                </ul>

                <p>To be eligible for a Prize in the competition you must:</p>
                <ul>
                    <li>
                        Be over the age of 18 and not be ineligible to enter this competition where is would contravene
                        the law in your place of residence
                    </li>
                    <li>a free account registered on Soar; and</li>
                    <li>
                        an active PayPal account for the remittance of any Prize money should you win. If you do not
                        have an active PayPal account, you agree that the payment of any Prize money is void.
                    </li>
                </ul>

                <p>
                    You may enter as many times as you wish but each person will only be eligible for a single
                    shortlisted entry or prize.
                </p>

                <p>
                    The Soar Competition starts at 9:00 pm AWST on 15 August 2024 and runs for 4 weeks, until 9:00 pm
                    AWST on 12 September 2024.
                </p>

                <h4>Prizes available</h4>
                <p>The prizes shall be awarded as follows:</p>

                <p>
                    <b>First Prize </b>AU $500 plus a retro branded Soar shirt and Soar socks
                </p>
                <p>
                    <b>Second Prize </b>AU $300 plus a retro branded Soar backpack and Soar Socks
                </p>
                <p>
                    <b>Third Prize</b> AU $100 plus a retro branded Soar shirt and Soar socks
                </p>
                <p>
                    <b>Fourth Prize</b> Retro branded Soar Cap and Soar Socks
                </p>

                <h4>Selection of winners</h4>
                <ul>
                    <li>
                        At the end of the Competition, five (5) entries will be shortlisted by Soar staff and selected
                        independent judges.
                    </li>
                    <li>
                        Once the shortlist has been decided the shortlisted entries will be posted to the official Soar
                        X (Twitter) account @Soar_Earth to be voted upon by the public.
                    </li>
                    <li>
                        Prizes will be awarded on the most votes to least votes during the public voting period. Should
                        there be a tie, the prizes shall be ordered based on the earliest creation of the project on the
                        Soar platform.
                    </li>
                </ul>

                <h4>Important things to note</h4>
                <ul>
                    <li>
                        The Competition is governed by the{' '}
                        <a href="/stoaries-competition-terms-and-conditions" target="_blank">
                            Competition Terms & Conditions
                        </a>
                    </li>
                    <li>All decisions are final and no correspondence will be entered into.</li>
                </ul>
            </TermsAndConditionsBody>
        </TermsAndConditionsContainer>
    );
};

export default StoariesCompetitionInstructions;

const TermsAndConditionsHero = styled.img`
    margin-top: 30px;
    width: 100vw;

    @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
    }
`;

const TermsAndConditionsContainer = styled.div`
    padding-right: 50px;
    padding-bottom: 20px;
    padding-top: 60px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        margin-left: 4vw;
    }
    @media all and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
        margin: 20px;
    }

    @media only screen and (max-width: 767px) {
        margin: 20px;
        padding-top: 30px;
        padding-right: 0px;
        padding-bottom: 20px;
    }
`;

const TermsAndConditionsHeader = styled.div`
    -webkit-text-fill-color: white;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    height: 60px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    color: white;
    margin: auto;
    text-align: center;
    flex-direction: none;
    border-top: 0px;
    padding-top: 0px;

    h3 {
        color: inherit;
        justify-content: center;
        align-self: center;
        display: flex;
        padding-left: 20px;
    }

    p {
        color: inherit;
        opacity: 0.8;
        margin: 18px;
    }

    @media only screen and (max-width: 767px) {
        height: auto;
        margin-bottom: 40px;
        flex-direction: column;

        h3 {
            padding-left: 0px;
        }
    }
`;

const TermsAndConditionsBody = styled.div`
    color: white;
    margin: 20px 0px 0px 20px;

    h4 {
        font-weight: bold;
        color: #eed926;
        -webkit-text-fill-color: #eed926;
        text-align: left;
        font-size: 1.5rem;
        font-weight: 200;
    }

    li {
        color: white;
        list-style-type: number;
    }

    a {
        color: #eed926;
    }

    p {
        color: white;
    }

    @media only screen and (max-width: 767px) {
        margin-right: 20px;
    }
`;
