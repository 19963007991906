import React from 'react';
import styled from 'styled-components';

interface ShareLinkProps {
    title: string;
    href?: string;
    handleOnClick: () => void;
    icon?: string;
    children?: React.ReactElement;
}

const ShareLink = ({ title, href, handleOnClick, icon, children }: ShareLinkProps) => {
    return (
        <a title={title} href={href} target="_blank" onClick={handleOnClick}>
            {icon ? <ShareLinkIcon className={icon} /> : children ? children : <React.Fragment />}
        </a>
    );
};

export default ShareLink;

interface IconProps {
    isSoar?: boolean;
}

export const ShareLinkIcon = styled.i<IconProps>`
    color: white;
    font-size: 18px;
    pointer-events: all;
    cursor: pointer;
    margin: 0px 0px 10px 0px;

    &:hover {
        color: #eed926;
    }

    @media screen and (max-width: 600px) {
        margin: 0px 0px 0px 16px;
    }
`;
