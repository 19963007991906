import React from 'react';
import styled from 'styled-components';
import { ListingDTO, ListingType, Review } from '../../../../../api/model';
import GeoUtil from '../../../../../lib/geo-util';
import SoarHelper from '../../../../../lib/soar-helper';

interface ProfileUserMapStatsProps {
    listings: ListingDTO[];
}

const ProfileUserMapStats = (props: ProfileUserMapStatsProps) => {
    const mapCount = props.listings?.filter(
        (l) => l.listingType !== ListingType.NOT_SUPPORTED && l.reviewSoar === Review.APPROVED
    ).length;

    let likeCount: number | undefined = undefined;

    let mappedArea: string | undefined;
    if (props.listings) {
        likeCount = props.listings
            .map((t) => t.totalLikes)
            .reduce((acc, val) => {
                return acc + val;
            }, 0);

        const mappedAreaMetersSquared = props.listings
            .map((t) => GeoUtil.area(t.boundingBox))
            .reduce((acc, val) => {
                return acc + val;
            }, 0);
        if (mappedAreaMetersSquared / 1000 / 1000 > 1000000) {
            const number = mappedAreaMetersSquared / 1000 / 1000 / 1000000;
            mappedArea = `${Math.round(number).toLocaleString()}M km²`;
        } else {
            const number = mappedAreaMetersSquared / 1000 / 1000;
            mappedArea = `${Math.round(number).toLocaleString()} km²`;
        }
        if (mappedAreaMetersSquared === 0) {
            mappedArea = undefined;
        }
    }

    return (
        <ProfileStatsContainer>
            {mapCount ? (
                <React.Fragment>
                    <ProfileStats>
                        <Stats>
                            <StatsImage src="/assets/user-profile-logos/maps.png" />
                            <StatsCount>{mapCount.toLocaleString()}</StatsCount>
                            <StatsLabel>Maps</StatsLabel>
                        </Stats>
                    </ProfileStats>
                    {likeCount || mappedArea ? <Divider /> : null}
                </React.Fragment>
            ) : null}

            {likeCount && SoarHelper.isSoar() ? (
                <React.Fragment>
                    <ProfileStats>
                        <Stats>
                            <StatsImage src="/assets/user-profile-logos/likes.png" />
                            <StatsCount>{likeCount.toLocaleString()}</StatsCount>
                            <StatsLabel>Likes</StatsLabel>
                        </Stats>
                    </ProfileStats>
                    {mappedArea ? <Divider /> : null}
                </React.Fragment>
            ) : null}

            {mappedArea !== undefined ? (
                <ProfileStats>
                    <Stats>
                        <StatsImage src="/assets/user-profile-logos/covered.png" />
                        <StatsCount>{mappedArea}</StatsCount>
                        <StatsLabel>Mapped Area</StatsLabel>
                    </Stats>
                </ProfileStats>
            ) : null}
        </ProfileStatsContainer>
    );
};

export default ProfileUserMapStats;

const ProfileStatsContainer = styled.div`
    flex-direction: row;
    display: flex;
    width: fit-content;
    margin: auto;
    height: 90px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        justify-content: space-evenly;
        height: 80px;
    }
`;
const ProfileStats = styled.div`
    flex-direction: column;
    min-width: 100px;
    width: auto;

    @media only screen and (max-width: 600px) {
        min-width: 80px;
    }
`;

const Stats = styled.div`
    padding: 10px;
`;

const StatsImage = styled.img``;

const StatsCount = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
    white-space: nowrap;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

const StatsLabel = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: white;
    opacity: 0.5;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-bottom: 0px;
`;

const Divider = styled.div`
    border-left: 1px solid white;
    height: 50px;
    opacity: 0.15;
    margin-top: 15px;
`;
