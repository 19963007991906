import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { ListingDTO } from '../../../api/model';

interface StoreBookmarks {
    bookmarkedMaps: ListingDTO[] | undefined;
    bookmarkedMapsIsLoading: boolean;
}

const initialState: StoreBookmarks = {
    bookmarkedMaps: undefined,
    bookmarkedMapsIsLoading: false,
};

export default (state: StoreBookmarks = initialState, action: Action): StoreBookmarks => {
    switch (action.type) {
        case at.FETCH_BOOKMARKED_MAPS_SUCCESS:
            return {
                ...state,
                bookmarkedMaps: action.payload,
            };
        case at.FETCH_BOOKMARKED_MAPS_ISLOADING:
            return {
                ...state,
                bookmarkedMapsIsLoading: action.payload,
            };
        default:
            return state;
    }
};
