import React from 'react';
import styled from 'styled-components';
import ListingHelper from '../../../../../../lib/listing-helper';
import MapViewHistoryHelper from '../../../../../../lib/map-view-history-helper';
import Analytics from '../../../../../../lib/user-analytics';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-modal';

interface SearchContentHistoryProps {
    onSelectItem: (item: CarouselItem) => void;
}

const SearchContentHistory = (props: SearchContentHistoryProps) => {
    const recentlyViewedMaps: CarouselItem[] = MapViewHistoryHelper.getViewedListingHistory().map((t) => {
        return {
            listingId: t.id,
            title: t.title,
            author: t.author,
            previewUrl: ListingHelper.getPreviewUrlForListing(t.id),
            opacity: 1,
        };
    });

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-recent-map.svg" />
                Maps you have <span>recently viewed</span>
            </SegmentTitle>

            {!recentlyViewedMaps.length ? (
                <EmptyRecentlyViewedMapsNotice>
                    You haven't explored the digital atlas yet, so no maps are displayed here.
                </EmptyRecentlyViewedMapsNotice>
            ) : (
                <SearchContentCarousel
                    items={recentlyViewedMaps}
                    height="180px"
                    handleSelectItem={(item: CarouselItem) => {
                        props.onSelectItem(item);
                        Analytics.Event('Search Bar', 'Clicked Map From History', item.listingId);
                    }}
                />
            )}
        </SegmentContainer>
    );
};

export default SearchContentHistory;

const EmptyRecentlyViewedMapsNotice = styled.p`
    font-size: 18px;
    color: #eed926;
    padding: 10px 0px 0px 10px;
`;
