import L from 'leaflet';
import styled from 'styled-components';
import { lineStyles } from './line-style-popup';
import LineStylePreview from './line-style-preview';

import Select, { components } from 'react-select';

interface LineStyleProps {
    selectedLineStyle: L.PolylineOptions;
    onSelectLineStyle: (lineStyle: L.PolylineOptions) => void;
}

const LineStyle = (props: LineStyleProps) => {
    const { weight: selectedWeight, dashArray: selectedDashArray } = props.selectedLineStyle;

    const selectedOption = lineStyles.find(
        (lineStyle) =>
            lineStyle.weight === selectedWeight && selectedDashArray?.toString() === lineStyle?.dashArray?.toString()
    );

    const Input = (inputProps) => {
        return <components.Input {...inputProps} data-testid="annotation-line-style-input" />;
    };

    const Option = (optionProps) => {
        return (
            <components.Option
                {...optionProps}
                innerProps={Object.assign({}, optionProps.innerProps, {
                    'data-testid': 'annotation-line-style-option',
                })}
            />
        );
    };

    const DropdownIndicator = (dropdownProps) => {
        return (
            <components.DropdownIndicator
                {...dropdownProps}
                innerProps={Object.assign({}, dropdownProps.innerProps, {
                    'data-testid': 'annotation-line-style-indicator',
                })}
            />
        );
    };

    return (
        <Container>
            <LineStylePreviewContainer>
                <Select
                    components={{ Input, Option, DropdownIndicator }}
                    value={selectedOption}
                    placeholder={'Select Line Style'}
                    options={lineStyles}
                    formatOptionLabel={(lineStyle) => (
                        <LineStylePreview lineStyle={lineStyle as L.PolylineOptions} width={180} height={20} />
                    )}
                    onChange={(lineStyle) => {
                        if (lineStyle) {
                            const polylineLineStyle: L.PolylineOptions = {
                                dashArray: lineStyle.dashArray,
                                weight: lineStyle.weight,
                            };
                            props.onSelectLineStyle(polylineLineStyle);
                        }
                    }}
                    isSearchable={false}
                    theme={(theme) => ({
                        ...theme,
                        borderColor: 'rgba(255,255,255, 0.95)',
                        colors: {
                            ...theme.colors,
                            primary: 'rgba(0,0,0,0.8)', // focus color
                            primary25: 'rgba(255,255,255,0.5)', // highlight color
                            neutral0: 'transparent', // background color
                            neutral5: 'rgba(0,0,0,0.80)', // background color
                            neutral20: 'rgba(255,255,255,0.75)', // control border
                            neutral50: 'rgba(255,255,255,0.75)', // placeholder color
                        },
                    })}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            cursor: 'pointer',
                            background: 'transparent',
                            borderColor: 'rgba(255,255,255,0.95)',
                        }),
                        menuList: (baseStyles) => ({
                            ...baseStyles,
                            '::-webkit-scrollbar': {
                                width: '6px',
                                backgroundColor: '#343a40',
                            },
                            '::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                                backgroundColor: '#343a40',
                            },
                            '::-webkit-scrollbar-thumb': {
                                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                                backgroundColor: '#eed926',
                                borderRadius: '4px',
                            },
                        }),
                    }}
                />
            </LineStylePreviewContainer>
        </Container>
    );
};

export default LineStyle;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 8px;
    align-items: center;
    cursor: pointer !important;
`;

const LineStylePreviewContainer = styled.div`
    width: 100%;
    cursor: pointer;
    vertical-align: unset;
`;
