import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import ExploreCard from './explore-card';
import { Masonry, useInfiniteLoader } from 'masonic';
import { ListingDTO } from '../../api/model';
import { isMobileVersion } from '../../lib/soar-helper';
import { ExploreResultsEmpty } from './explore-results-empty';
import { ExploreSubdomainEmpty } from './explore-subdomain-empty';
import { PulseLoader } from '../Shared/pulse-loader';
import SoarHelper from '../../lib/soar-helper';
import ApiListings from '../../api/api-listings';

interface ExploreMasonaryProps {
    searchTerm: string;
    category?: string;
    aoi?: string;
    orderBy?: string;
}

const LIMIT = 60;
const THRESHOLD = 20;

const ExploreMasonary = (props: ExploreMasonaryProps) => {
    const { searchTerm, category, aoi, orderBy } = props;
    const [listings, setListings] = useState<ListingDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const [allResults, setAllResults] = useState(false);
    const isSoar = SoarHelper.isSoar();

    useEffect(() => {
        setListings([]);
        setAllResults(false);
        setLoading(true);
    }, [searchTerm, category, aoi, orderBy]);

    const fetchListings = useCallback(() => {
        const fetching = async () => {
            try {
                const res = await ApiListings.searchListings(
                    LIMIT,
                    listings.length,
                    searchTerm,
                    category,
                    aoi,
                    orderBy
                );
                if (res.listings.length !== 0) {
                    setListings((current) => [...current, ...res.listings]);
                }
                if (res.listings.length < LIMIT) {
                    setAllResults(true);
                }
            } catch (err) {
                // Do not display the error as we are intercepting it on change
            } finally {
                setLoading(false);
            }
        };

        return fetching();
    }, [aoi, category, listings.length, orderBy, searchTerm]);

    useEffect(() => {
        if (loading && !allResults) {
            fetchListings();
        }
    }, [loading, allResults, fetchListings]);

    const fetchMoreItems = async () => {
        setLoading(true);
    };

    const maybeLoadMore = useInfiniteLoader(fetchMoreItems, {
        isItemLoaded: (index, items) => !!items[index],
        threshold: THRESHOLD,
    });

    return (
        <ExploreMasonaryContainer>
            {listings?.length > 0 && (
                <Masonry
                    className="masonary-container"
                    items={listings.map((value, index) => {
                        return { id: index, listing: value };
                    })}
                    onRender={maybeLoadMore}
                    render={MasonaryCard}
                    columnGutter={16}
                    columnWidth={isMobileVersion ? 140 : 280}
                />
            )}
            {listings?.length === 0 && !loading && (isSoar ? <ExploreResultsEmpty /> : <ExploreSubdomainEmpty />)}
            {!listings?.length && loading ? <PulseLoader iconSize="2rem" iconMargin="1rem 1rem" /> : null}
        </ExploreMasonaryContainer>
    );
};

export default ExploreMasonary;

interface MasonaryCardProps {
    index: number;
    data: {
        id: number;
        listing: ListingDTO;
    };
    width: number;
}

const MasonaryCard = (props: MasonaryCardProps) => {
    return (
        <ExploreCard
            listingType={props.data.listing.listingType}
            userName={props.data.listing.userName || 'A Soar User'}
            metadata={props.data.listing.metadata}
            id={props.data.listing.id}
            dateUploaded={props.data.listing.createdAt || new Date().getMilliseconds()}
            owner={props.data.listing.userId}
        />
    );
};

const ExploreMasonaryContainer = styled.div`
    margin: 10px 25px 0 10px;
    background-color: #191a1a;

    @media only screen and (max-width: 600px) {
        margin: 10px 10px 0 10px;
        padding-bottom: 54px; // height of mobile navbar
    }

    .masonary-container {
        outline: none !important;
    }
`;
