import { SortOptions } from '../Shared/sort';

export enum ImagerySort {
    Title = 'Title',
    LastPosted = 'Oldest',
    Newest = 'Newest',
    Modified = 'Modified',
}

export const ImagerySortOptions: SortOptions = {
    title: 'Sort By',
    options: [
        { value: ImagerySort.Title, label: 'Title' },
        { value: ImagerySort.LastPosted, label: 'Oldest' },
        { value: ImagerySort.Newest, label: 'Newest' },
        { value: ImagerySort.Modified, label: 'Last Modified' },
    ],
    icons: {
        light: '/assets/explore-dropdown/sort-by-filter_dark.svg',
        dark: '/assets/explore-dropdown/sort-by-filter_light.svg',
    },
};
