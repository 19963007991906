import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MapViewHistoryHelper, { ViewedMapSessionObject } from '../../../../lib/map-view-history-helper';
import SideDrawerCarousel from '../Shared/side-drawer-carousel';
import DiscoverRecentlyViewedMapsCard from './discover-recently-viewed-maps-card';

const DiscoverRecentlyViewedMaps = () => {
    const [nearbyListings, setNearbyListings] = useState<ViewedMapSessionObject[] | undefined>(undefined);

    useEffect(() => {
        setNearbyListings(MapViewHistoryHelper.getViewedListingHistory());
    }, []);

    if (!nearbyListings || nearbyListings.length === 0) return <React.Fragment />;

    return (
        <DiscoverRecentlyViewedMapsContainer className="DiscoverRecentlyViewedMapsContainer">
            <DiscoverRecentlyViewedMapsTitle>
                <DiscoverRecentlyViewedMapsTitleIcon src="/assets/search-content/emoji-recent-map.svg" />
                Maps you have <span>recently viewed</span>
            </DiscoverRecentlyViewedMapsTitle>
            <SideDrawerCarousel height="240px">
                {nearbyListings.map((nearbyListing) => (
                    <DiscoverRecentlyViewedMapsCard listing={nearbyListing} />
                ))}
            </SideDrawerCarousel>
        </DiscoverRecentlyViewedMapsContainer>
    );
};

export default DiscoverRecentlyViewedMaps;

export const DiscoverRecentlyViewedMapsContainer = styled.div`
    margin: 0px 6px 10px 6px;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);

    &.unstyled {
        margin: 0px;
        padding: 0px;
        border: none;
        background: transparent;
    }
`;

export const DiscoverRecentlyViewedMapsTitle = styled.h4`
    color: white;
    text-align: left;
    font-size: 1.6rem;
    margin: 10px 0px;

    span {
        color: #eed923;
    }
`;

export const DiscoverRecentlyViewedMapsTitleIcon = styled.img`
    width: 32px;
    height: 32px;
    margin: -4px 8px 0 8px;
`;
