import L from 'leaflet';
import { defaultZIndex } from '../layers-util';
import { INITIAL_SIDC } from './MilSymbol/values';

export default class MilitaryMarker {
    annotationType: string;
    id: string;
    position: L.LatLng;
    sidc: string;
    options: L.MarkerOptions;
    zoomBase?: number;
    zIndex: number;

    constructor(id: string, position: L.LatLng, sidc: string, options: L.MarkerOptions, zoomBase?: number) {
        this.id = id;
        this.position = position;
        this.sidc = sidc;
        this.options = options;
        this.zoomBase = zoomBase;
    }

    static serialize(marker: MilitaryMarker): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [marker.position.lng, marker.position.lat],
            },
            properties: {
                annotationType: 'MilitaryMarker',
                id: marker.id,
                options: marker.options,
                sidc: marker.sidc,
                zoomBase: marker.zoomBase,
                zIndex: marker.zIndex || defaultZIndex,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): MilitaryMarker {
        const position = new L.LatLng(json.geometry.coordinates[1] as number, json.geometry.coordinates[0] as number);
        const sidcNumber = json.properties?.sidc ? json.properties.sidc : INITIAL_SIDC;
        const marker: MilitaryMarker = {
            annotationType: 'MilitaryMarker',
            id: json.properties.id,
            position,
            sidc: sidcNumber,
            options: {
                ...defaultMilitaryMarkerOptions,
            },
            zoomBase: json.properties?.zoomBase,
            zIndex: json.properties?.zIndex || defaultZIndex,
        };
        return marker;
    }
}

export const defaultMilitaryMarkerOptions: L.MarkerOptions = {
    interactive: true,
};
