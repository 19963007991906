import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../../store/Map/SuperMap/selectors';
import ActiveMapBottomSheet from '../MapSelection/active-map-bottom-sheet';
import BottomSheet, { BottomSheetState } from '../MapSelection/bottom-sheet';
import MobileSatelliteSearchResult from '../Satellite/mobile-satellite-search-result';

import ActiveMapLoading from '../MapSelection/active-map-loading';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import { deleteAllAnnotationsAction } from '../../../store/Annotations/actions';
import { selectSentinelSelectedFeature } from '../../../store/Map/Sentinel/selectors';
import MobileReturnToSearch from './mobile-return-to-search';
import UriHelper from '../../../lib/uri-helper';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import MobileSatelliteBottomSheetContainer from '../Satellite/mobile-satellite-bottom-sheet-container';
import {
    actionSentinelFetchFeaturesSuccess,
    actionSentinelResetSelectedFeature,
} from '../../../store/Map/Sentinel/actions';

const MobileBottomSheet = () => {
    const activeMap = useSelector(selectActiveAllMap);
    const activeMapFetching = useSelector(selectActiveAllMapIsFetching);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sideDrawerMode = useSelector(selectSideDrawerMode);

    const [desiredBottomSheetState, setDesiredBottomSheetState] = useState<BottomSheetState | undefined>(undefined);
    const [currentBottomSheetState, setCurrentBottomSheetState] = useState<BottomSheetState | undefined>(undefined);
    const [isBottomSheetClosing, setIsBottomSheetClosing] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        requestAnimationFrame(() => {
            setDesiredBottomSheetState(currentBottomSheetState);
        });
    }, [currentBottomSheetState]);

    const handleCloseBottomSheet = () => {
        setDesiredBottomSheetState(BottomSheetState.close);
        dispatch(actionActiveMapCleared());
        dispatch(deleteAllAnnotationsAction());
        UriHelper.navigateToPath('/');
    };

    useEffect(() => {
        // Add an event listener to listen for the "popstate" event (back button press on mobile)
        window.addEventListener('popstate', handleCloseBottomSheet);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleCloseBottomSheet);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isNativeMobile = sideDrawerMode === SideDrawerMode.MOBILE_NATIVE;

    if (activeMapFetching) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={(openState: BottomSheetState) => setCurrentBottomSheetState(openState)}
                handleCloseButton={() => handleCloseBottomSheet()}
            >
                <ActiveMapLoading />
            </BottomSheet>
        );
    }

    if (activeMap) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={setCurrentBottomSheetState}
                handleCloseButton={() => handleCloseBottomSheet()}
            >
                <ActiveMapBottomSheet
                    bottomSheetState={currentBottomSheetState || BottomSheetState.close}
                    listing={activeMap}
                    onCloseMap={() => {
                        setDesiredBottomSheetState(BottomSheetState.close);
                        setIsBottomSheetClosing(false);
                    }}
                    isClosing={isBottomSheetClosing}
                />
            </BottomSheet>
        );
    }

    // Only used by the mobile native view
    if (!selectedSentinelFeature && isNativeMobile) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={setCurrentBottomSheetState}
                handleCloseButton={() => setDesiredBottomSheetState(BottomSheetState.close)}
                showCloseButton={currentBottomSheetState !== BottomSheetState.open}
            >
                <MobileSatelliteBottomSheetContainer
                    bottomSheetState={currentBottomSheetState || BottomSheetState.close}
                    onCloseMap={(bottomSheetState) => {
                        setDesiredBottomSheetState(bottomSheetState);
                        setIsBottomSheetClosing(false);
                    }}
                    isClosing={isBottomSheetClosing}
                />
            </BottomSheet>
        );
    }

    // Used by both mobile web and mobile native
    // Note: The close viewing behaviour implemented is user clicks close icon when viewing a satellite image and it resets the search results and closes the bottom sheet
    if (selectedSentinelFeature) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={setCurrentBottomSheetState}
                handleCloseButton={() => {
                    UriHelper.removeAllSentinelParametersFromURI();
                    setDesiredBottomSheetState(BottomSheetState.close);
                    dispatch(actionSentinelResetSelectedFeature());
                    dispatch(actionSentinelFetchFeaturesSuccess(undefined));
                    //Close the bottom sheet if we are on mobile web to maintain current behavior
                    if (!isNativeMobile) {
                        handleCloseBottomSheet();
                    }
                }}
            >
                <MobileSatelliteSearchResult
                    bottomSheetState={currentBottomSheetState || BottomSheetState.close}
                    onCloseMap={() => {
                        setDesiredBottomSheetState(BottomSheetState.open);
                        setIsBottomSheetClosing(false);
                    }}
                    isClosing={isBottomSheetClosing}
                />
            </BottomSheet>
        );
    }

    return (
        <BottomSheet
            setOpenState={desiredBottomSheetState}
            onOpenStateChanged={setCurrentBottomSheetState}
            handleCloseButton={() => handleCloseBottomSheet()}
        >
            <MobileReturnToSearch handleCloseButton={() => handleCloseBottomSheet()} />
        </BottomSheet>
    );
};

export default MobileBottomSheet;
