import Api from './api';
import { EmbedDTO } from './model';

export default class ApiEmbedMap extends Api {
    public static createEmbedKey(embedDetails: EmbedDTO): Promise<number> {
        return this.axios.post('/v1/embd_maps', embedDetails).then((res) => {
            return res.data.id;
        });
    }
}
