export const actionTypes = {
    SENTINEL_BEGIN_BOX_SELECT: 'SENTINEL/BEGIN_BOX_SELECT',
    SENTINEL_END_BOX_SELECT: 'SENTINEL/END_BOX_SELECT',
    SENTINEL_SELECTED_AOI: 'SENTINEL/AOI',
    SENTINEL_RESET_SELECTED_AOI: 'SENTINEL/RESET_SELECTED_AOI',
    SENTINEL_SELECTED_FEATURE: 'SENTINEL/SELECTED_FEATURE',
    SENTINEL_SELECTED_FEATURE_OPACITY: 'SENTINEL/SELECTED_FEATURE_OPACITY',
    SENTINEL_RESET_SELECTED_FEATURE: 'SENTINEL/RESET_SELECTED_FEATURE',
    SENTINEL_OPEN_DOWNLOAD: 'SENTINEL/OPEN_DOWNLOAD',
    SENTINEL_OPEN_SHARE: 'SENTINEL/OPEN_SHARE',
    SENTINEL_SET_OPACITY: 'SENTINEL/SET_OPACITY',
    SENTINEL_CLOSE_DOWNLOAD: 'SENTINEL/CLOSE_DOWNLOAD',
    SENTINEL_CLOSE_SHARE: 'SENTINEL/CLOSE_SHARE',
    SENTINEL_MAP_ZOOM: 'SENTINEL/MAP_ZOOM',

    SENTINEL_FETCH_FEATURES: 'SENTINEL/FETCH_FEATURES',
    SENTINEL_FETCH_FEATURES_SUCCESS: 'SENTINEL/FETCH_FEATURES_SUCCESS',
    SENTINEL_FETCH_FEATURES_ERROR: 'SENTINEL/FETCH_FEATURES_ERROR',
    SENTINEL_CLEAR_FEATURES: 'SENTINEL/CLEAR_FEATURES',
    SENTINEL_FEATURE_LOADING: 'SENTINEL/FEATURE_LOADING',
};
