import styled from 'styled-components';
import { SatelliteArchiveImageryDTO } from '../../../../api/api-supplier';
import Analytics from '../../../../lib/user-analytics';

const moment = require('moment');

interface SatelliteArchivalItemProps {
    result: SatelliteArchiveImageryDTO;
    isSelected: boolean;
    isDragging: boolean;
    setResultActive: (result: SatelliteArchiveImageryDTO) => void;
    setResultInActive: (result: SatelliteArchiveImageryDTO) => void;
    setResultMouseOver: (result: SatelliteArchiveImageryDTO) => void;
    setResultMouseOut: (result: SatelliteArchiveImageryDTO) => void;
}

const SatelliteArchiveItem = (props: SatelliteArchivalItemProps) => {
    return (
        <Item isDragging={props.isDragging}>
            <ItemContainer
                onClick={() => {
                    if (props.isSelected) {
                        props.setResultInActive(props.result);
                        Analytics.Event(
                            `Satellite - ${props.result.supplier}`,
                            `Clicked to unselect archive ${props.result.supplier} ${props.result.product}`,
                            props.result.id
                        );
                    } else {
                        props.setResultActive(props.result);
                        Analytics.Event(
                            `Satellite - ${props.result.supplier}`,
                            `Clicked to select archive ${props.result.supplier} ${props.result.product}`,
                            props.result.id
                        );
                    }
                }}
                onMouseOver={() => {
                    props.setResultMouseOver(props.result);
                }}
                onMouseOut={() => {
                    props.setResultMouseOut(props.result);
                }}
            >
                <ButtonContainer>
                    <DragDropCheckbox
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={(e) => {
                            if (e.target.checked) {
                                props.setResultMouseOut(props.result);
                                props.setResultActive(props.result);
                            } else {
                                props.setResultInActive(props.result);
                            }
                        }}
                        id={`archive-feature-checkbox-trigger-${props.result.id}`}
                    />
                </ButtonContainer>
                <InfoContainer>
                    <InfoDate>{moment(props.result.date).format('MMM Do YYYY')}</InfoDate>
                    <InfoData>{props.result.cloud}% Covered</InfoData>
                    <Nadir>{Number(props.result.rollAngle).toFixed(1)}&#176;</Nadir>
                    <DragAndDropIcon src="/assets/sidebar-icons/dnd-icon-pex.png" />
                </InfoContainer>
            </ItemContainer>
            <DragDropDivider />
        </Item>
    );
};

export default SatelliteArchiveItem;

interface ItemProps {
    isDragging: boolean;
}

const Item = styled.div<ItemProps>`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => (props.isDragging ? 'rgb(0,0,0,0.6)' : 'transparent')};
    border-top: ${(props) => (props.isDragging ? ' 1px solid rgba(255, 255, 255, 0.3);' : '0')};
    backdrop-filter: ${(props) => (props.isDragging ? 'blur(10px)' : 'none')};
    box-shadow: ${(props) => (props.isDragging ? '0 2px 3px rgba(255, 255, 255, 0.05)' : 'none')};
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease, box-shadow 0.3s ease;
`;

const DragDropDivider = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    align-self: center;

    :-moz-last-node {
        border-top: none !important;
        width: 0%;
    }
`;

const ItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 6px 0px;
    cursor: pointer;
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0px 8px;
    align-items: center;
`;

const InfoData = styled.span`
    color: white;
    text-align: left;
    width: 100%;
    max-width: 95px;
    user-select: none;
`;

const Nadir = styled.span`
    color: white;
    text-align: center;
    width: 100%;
    max-width: 50px;
    user-select: none;
`;

const InfoDate = styled.div`
    color: white;
    width: 100%;
    user-select: none;
`;

const ButtonContainer = styled.div`
    margin-top: 6px;
    margin-left: 6px;
    width: 22px;
`;

const DragAndDropIcon = styled.img`
    width: 20px;
    height: 20px;
    cursor: grab;
    user-select: none;
    margin-left: 8px;
`;

const DragDropCheckbox = styled.input`
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='18' height='18' rx='6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
    background-repeat: none !important;
    outline: none !important;

    &:checked {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='6' fill='%23EED926'/%3E%3Cpath d='M5 9L8.10017 12L14 6' stroke='%23191A1A' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
`;
