export default Object.freeze([
  "#01888c", // teal
  "#fc7500", // bright orange
  "#034f5d", // dark teal
  "#f73f01", // orangered
  "#fc1960", // magenta
  "#c7144c", // raspberry
  "#f3c100", // goldenrod
  "#1598f2", // lightning blue
  "#2465e1", // sail blue
  "#f19e02", // gold
]);
