import { actionTypes as at } from './constants';
import { ListingDTO } from '../../../api/model';

export const actionSetTileLayerOpacity = (tileLayerId: number, opacity: number) => {
    return {
        type: at.TILE_LAYER_SET_OPACITY,
        payload: {
            tileLayerId: tileLayerId,
            opacity: opacity,
        },
    };
};

export const actionPinTileLayer = (tileLayer: ListingDTO) => {
    return {
        type: at.PIN_TILE_LAYER,
        payload: tileLayer,
    };
};

export const actionUnpinTileLayer = (tileLayer: ListingDTO) => {
    return {
        type: at.UNPIN_TILE_LAYER,
        payload: tileLayer,
    };
};
