import { AnimatedNumberChart, AnimatedNumberType } from './animated-number-chart';
import styled from 'styled-components';

interface DashboardTickerProps {
    totalFiles: number;
    totalListingFileSize: number;
    totalListingAreaMetersSquared: number;
    totalUserCount?: number;
}

export const DashboardTicker = (props: DashboardTickerProps): JSX.Element => {
    return (
        <AdminDashboardTickerContainer>
            <AdminDashboardCard>
                <h4>Total Maps</h4>
                <AnimatedNumberChart duration={1} number={props.totalFiles} type={AnimatedNumberType.Number} />
            </AdminDashboardCard>
            <AdminDashboardCard>
                <h4>Data Stored</h4>
                <AnimatedNumberChart
                    duration={2500}
                    number={props.totalListingFileSize}
                    type={AnimatedNumberType.FileSize}
                />
            </AdminDashboardCard>
            <AdminDashboardCard>
                <h4>Area Mapped</h4>
                {props.totalListingAreaMetersSquared / 1000 / 1000 > 1000000 ? (
                    <AnimatedNumberChart
                        duration={4500}
                        number={props.totalListingAreaMetersSquared / 1000 / 1000 / 1000000}
                        type={AnimatedNumberType.MillionSquareKilometers}
                    />
                ) : (
                    <AnimatedNumberChart
                        duration={4500}
                        number={props.totalListingAreaMetersSquared / 1000 / 1000}
                        type={AnimatedNumberType.SquareKilometers}
                    />
                )}
            </AdminDashboardCard>
            <AdminDashboardCard>
                <h4>Users</h4>
                <AnimatedNumberChart
                    number={props.totalUserCount ? props.totalUserCount : 0}
                    duration={1}
                    type={AnimatedNumberType.Number}
                />
            </AdminDashboardCard>
        </AdminDashboardTickerContainer>
    );
};

const AdminDashboardCard = styled.div`
    background-color: rgb(0, 0, 0, 0.6);
    padding: 20px;
    margin: 10px 0px;
    text-align: center;
    min-width: 200px;
    border-radius: 6px;

    h4 {
        color: white;
    }
`;

const AdminDashboardTickerContainer = styled.div`
    display: flex;
    flex-direction: '';
    justify-content: space-around;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;
