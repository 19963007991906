import styled from 'styled-components';

export const SegmentContainer = styled.div`
    margin: 0px 6px 10px 6px;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);

    &.unstyled {
        margin: 0px;
        padding: 0px;
        border: none;
        background: transparent;
    }
`;

export const SegmentTitle = styled.h4`
    color: white;
    text-align: left;
    font-size: 1.6rem;
    margin: 10px 0px;

    span {
        color: #eed923;
    }
`;

export const SegmentTitleIcon = styled.img`
    width: 32px;
    height: 32px;
    margin: -4px 8px 0 8px;
`;
