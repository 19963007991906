import { useDispatch, useSelector } from 'react-redux';
import LoginRegisterDialog from './login-register-dialog';
import { LoginModalMode } from './login-enum';
import { selectShowLoginDialog, selectOnLoginDialogCloseRedirect } from '../../store/App/selectors';
import { actionShowLoginDialog, actionResetLoginDialogCloseRedirect } from '../../store/App/actions';

import UriHelper from '../../lib/uri-helper';

const LoginDialog = () => {
    const showLoginDialog = useSelector(selectShowLoginDialog);
    const onLoginDialogCloseRedirect = useSelector(selectOnLoginDialogCloseRedirect);
    const dispatch = useDispatch();

    if (showLoginDialog) {
        return (
            <LoginRegisterDialog
                isOpen={true}
                initialMode={LoginModalMode.LOGIN}
                onClose={(isLoggedIn: boolean) => {
                    dispatch(actionShowLoginDialog(false));

                    if (!isLoggedIn && onLoginDialogCloseRedirect) {
                        UriHelper.navigateToPath(onLoginDialogCloseRedirect);
                        dispatch(actionResetLoginDialogCloseRedirect());
                    }
                }}
            />
        );
    }

    return null;
};

export default LoginDialog;
