import L, { LeafletMouseEvent } from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import Marker, { defaultMarkerOptions } from './marker';
import { v4 as uuidv4 } from 'uuid';
import { getMarkerIcon, scaleMarkerIconByZoom } from './marker-util';
import { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';

interface MarkerAnnotationBuilderProps {
    onCreateMarker: (marker: Marker) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const createMarkerBuilderElement = (props: MarkerAnnotationBuilderProps, context: LeafletContextInterface) => {
    const lastUsedColor = store.getState().annotationDomain.present.markerReducer.markerColor;
    const lastUsedIcon = store.getState().annotationDomain.present.markerReducer.markerIcon;

    const map = context.map;
    const icon = getMarkerIcon(lastUsedIcon, lastUsedColor);
    const initialMarkerIcon = scaleMarkerIconByZoom(icon, map.getZoom(), map.getZoom());
    const marker = new L.Marker(new L.LatLng(0, 0), { icon: initialMarkerIcon });
    const markerElement = createElementObject<L.Marker, MarkerAnnotationBuilderProps>(marker, context);

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancelBuild();
            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            context.map.dragging.enable();
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
            document.removeEventListener('keydown', onKeyDown);
        }
    };

    const onMouseDown = (e: LeafletMouseEvent) => {
        markerElement.instance.setLatLng(e.latlng);
        context.map.dragging.enable();
        document.removeEventListener('keydown', onKeyDown);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.off('mousemove', onMouseMove);
        context.map.off('mousedown', onMouseDown);

        const marker: Marker = {
            annotationType: 'Marker',
            id: uuidv4(),
            position: markerElement.instance.getLatLng(),
            iconName: lastUsedIcon,
            color: lastUsedColor,
            zoomBase: context.map.getZoom(),
            options: {
                ...defaultMarkerOptions,
                ...icon.options,
            },
            zIndex: props.zIndex || defaultZIndex,
        };

        props.onCreateMarker(marker);
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        markerElement.instance.setLatLng(e.latlng);
    };

    markerElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.on('mousemove', onMouseMove);
        context.map.on('mousedown', onMouseDown);
    });

    document.addEventListener('keydown', onKeyDown);

    return markerElement;
};

const useMarkerBuilder = createElementHook<L.Marker, MarkerAnnotationBuilderProps, LeafletContextInterface>(
    createMarkerBuilderElement
);

const MarkerBuilder = (props: MarkerAnnotationBuilderProps) => {
    const context = useLeafletContext();
    const markerBuilder = useMarkerBuilder(props, context);
    useLayerLifecycle(markerBuilder.current, context);
    return null;
};

export default MarkerBuilder;
