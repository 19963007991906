import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreators } from 'redux-undo';

export const useUndoRedo = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'z') {
                dispatch(ActionCreators.undo());
            }

            if (event.ctrlKey && event.key === 'y') {
                dispatch(ActionCreators.redo());
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [dispatch]);
};
