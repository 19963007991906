import { actionTypes as at } from './constants';
import Circle from '../../../components/MapView/Annotations/Circle/circle';

export const setCirclesAction = (circles: Circle[]) => {
    return {
        type: at.SET_CIRCLES,
        payload: { circles: circles },
    };
};

export const setCircleOptionsAction = (options: L.CircleMarkerOptions) => {
    return {
        type: at.SET_CIRCLE_OPTIONS,
        payload: options,
    };
};

export const setCircleAddAction = (circle: Circle) => {
    return {
        type: at.SET_CIRCLE_ADD,
        payload: circle,
    };
};

export const setCircleUpdateAction = (circle: Circle) => {
    return {
        type: at.SET_CIRCLE_UPDATE,
        payload: circle,
    };
};

export const setCircleDeleteAction = (circle: Circle) => {
    return {
        type: at.SET_CIRCLE_DELETE,
        payload: circle,
    };
};
