import MilitaryMarker from '../../../components/MapView/Annotations/MilitaryMarker/military-marker';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface MilitaryMarkerState {
    militaryMarkers: MilitaryMarker[];
    sidc?: string;
}

const initialState: MilitaryMarkerState = {
    militaryMarkers: [],
    sidc: '',
};

export default (state: MilitaryMarkerState = initialState, action: Action): MilitaryMarkerState => {
    switch (action.type) {
        case at.SET_MILITARY_MARKERS:
            return { ...state, militaryMarkers: action.payload.markers };

        case at.SET_MILITARY_MARKER_SIDC:
            return { ...state, sidc: action.payload };

        case at.SET_MILITARY_MARKER_ADD:
            return {
                ...state,
                militaryMarkers: [...state.militaryMarkers, action.payload],
            };

        case at.SET_MILITARY_MARKER_UPDATE: {
            const newMarkers = state.militaryMarkers.map((marker) => {
                if (marker.id === action.payload.id) {
                    return { ...marker, ...action.payload };
                }
                return marker;
            });
            return { ...state, militaryMarkers: newMarkers };
        }

        case at.SET_MILITARY_MARKER_DELETE: {
            const newMarkers = state.militaryMarkers.filter((marker) => marker.id !== action.payload.id);
            return { ...state, militaryMarkers: newMarkers };
        }

        default:
            return state;
    }
};
