import { StyledButton } from '../../Shared/styled-button';
import styled from 'styled-components';

interface MobileReturnToSearchProps {
    handleCloseButton: () => void;
}

const MobileReturnToSearch = (props: MobileReturnToSearchProps) => {
    return <ReturnToSearchButton onClick={() => props.handleCloseButton()}>Open Search</ReturnToSearchButton>;
};

export default MobileReturnToSearch;

const ReturnToSearchButton = styled(StyledButton)`
    pointer-events: all;
    margin: 0 auto;
    margin-top: 25px;
`;
