import L from 'leaflet';
import { defaultZIndex } from '../layers-util';

export default class Image {
    annotationType: string;
    id: string;
    imageSrc: string;
    bounds: L.LatLngBounds;
    opacity: number;
    zIndex: number;

    constructor(id: string, imageSrc: string, bounds: L.LatLngBounds, opacity: number) {
        this.imageSrc = imageSrc;
        this.bounds = bounds;
        this.opacity = opacity;
        this.annotationType = 'Image';
    }

    static serialize(image: Image): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [image.bounds.getNorthWest().lng, image.bounds.getNorthWest().lat],
                        [image.bounds.getNorthEast().lng, image.bounds.getNorthEast().lat],
                        [image.bounds.getSouthEast().lng, image.bounds.getSouthEast().lat],
                        [image.bounds.getSouthWest().lng, image.bounds.getSouthWest().lat],
                        [image.bounds.getNorthWest().lng, image.bounds.getNorthWest().lat],
                    ],
                ],
            },
            properties: {
                annotationType: 'Image',
                id: image.id,
                imageSrc: image.imageSrc,
                opacity: image.opacity,
                zIndex: image.zIndex || defaultZIndex,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): Image {
        const boundingBox = new L.LatLngBounds(
            new L.LatLng(json.geometry.coordinates[0][0][1], json.geometry.coordinates[0][0][0]),
            new L.LatLng(json.geometry.coordinates[0][2][1], json.geometry.coordinates[0][2][0])
        );

        const image: Image = {
            annotationType: 'Image',
            id: json.properties.id,
            imageSrc: json.properties.imageSrc,
            bounds: boundingBox,
            opacity: json.properties.opacity,
            zIndex: json.properties.zIndex || defaultZIndex,
        };
        return image;
    }
}
