import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { ListingDTO } from '../../../api/model';

interface StoreFavourites {
    likedMaps: ListingDTO[];
}

const initialState: StoreFavourites = {
    likedMaps: [],
};

export default (state: StoreFavourites = initialState, action: Action): StoreFavourites => {
    switch (action.type) {
        case at.FETCH_LIKED_MAPS_SUCCESS:
            return {
                ...state,
                likedMaps: action.payload,
            };

        default:
            return state;
    }
};
