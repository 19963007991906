import { AppState } from '../root-reducer';
import { Annotation } from './reducer';

export const selectAnnotationCount = (state: AppState) => {
    return (
        state.annotationDomain.present.markerReducer.markers.length +
        state.annotationDomain.present.militaryMarkerReducer.militaryMarkers.length +
        state.annotationDomain.present.pathReducer.polylines.length +
        state.annotationDomain.present.polygonReducer.polygons.length +
        state.annotationDomain.present.arrowReducer.arrows.length +
        state.annotationDomain.present.circleReducer.circles.length +
        state.annotationDomain.present.rectangleReducer.rectangles.length +
        state.annotationDomain.present.textboxReducer.textBoxes.length +
        state.annotationDomain.present.freehandReducer.freehandDraws.length +
        state.annotationDomain.present.imageReducer.images.length +
        state.annotationDomain.present.coordinateReducer.coordinates.length
    );
};

export const selectAllAnnotations = (state: AppState) => {
    return [
        ...state.annotationDomain.present.markerReducer.markers,
        ...state.annotationDomain.present.militaryMarkerReducer.militaryMarkers,
        ...state.annotationDomain.present.pathReducer.polylines,
        ...state.annotationDomain.present.polygonReducer.polygons,
        ...state.annotationDomain.present.arrowReducer.arrows,
        ...state.annotationDomain.present.circleReducer.circles,
        ...state.annotationDomain.present.rectangleReducer.rectangles,
        ...state.annotationDomain.present.textboxReducer.textBoxes,
        ...state.annotationDomain.present.freehandReducer.freehandDraws,
        ...state.annotationDomain.present.imageReducer.images,
        ...state.annotationDomain.present.coordinateReducer.coordinates,
    ] as Annotation[];
};

export const selectSelectedAnnotation = (state: AppState) =>
    state.annotationDomain.present.annotationReducer.selectedAnnotation;

export const selectFillPatterns = (state: AppState) => state.annotationDomain.present.annotationReducer.fillPatterns;
export const selectCurrentZIndex = (state: AppState) => state.annotationDomain.present.annotationReducer.currentZIndex;
