import { useRef, useState } from 'react';
import styled from 'styled-components';
import { Annotation } from '../../../store/Annotations/reducer';
import ToolbarModal, { ToolbarButton } from './ToolBar/toolbar-modal';
import useLayers from './use-layers';

interface LayerOrderControlProps {
    annotation: Annotation;
}

const LayerOrderControl = (props: LayerOrderControlProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toolbarButtonRef = useRef<HTMLButtonElement>(null);
    const { sendToBack, bringToFront } = useLayers();

    return (
        <Container>
            <ButtonToggle ref={toolbarButtonRef} isSelected={isOpen} onClick={() => setIsOpen(!isOpen)} title="Arrange">
                <OrderIcon src="/assets/annotations/layer-order.svg" alt="Arrange" />
            </ButtonToggle>

            {isOpen && (
                <ToolbarModal
                    toolbarButtonRef={toolbarButtonRef}
                    width={200}
                    title="Arrange"
                    onClose={() => setIsOpen(false)}
                >
                    <ToolbarButton
                        key={'bringToFront'}
                        title="Bring to Front"
                        onClick={() => {
                            bringToFront(props.annotation);
                        }}
                    >
                        Bring to Front
                        <Icon className="fa fa-chevron-up" />
                    </ToolbarButton>
                    <ToolbarButton
                        key={'sendToBack'}
                        title="Send to Back"
                        onClick={() => {
                            sendToBack(props.annotation);
                        }}
                    >
                        Send to Back
                        <Icon className="fa fa-chevron-down" />
                    </ToolbarButton>
                </ToolbarModal>
            )}
        </Container>
    );
};

export default LayerOrderControl;

const ButtonToggle = styled.button<{ isSelected: boolean }>`
    background: ${(props) => (props.isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
    color: inherit;
    border: none;
    border-radius: 4px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 2px 0px 0px 0px;
    padding: 2px 2px;
    display: flex;
    flex-direction: row;
    height: 38px;
`;

const OrderIcon = styled.img`
    width: 28px;
    height: 28px;
    margin-top: 2px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 2px 0 0 2px;
`;

const Icon = styled.i`
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
`;
