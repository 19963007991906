import ApiSocial from '../../../api/api-social';
import { actionTypes as at } from './constants';

export const actionFetchLikedMaps = () => {
    return async (dispatch, getState) => {
        try {
            const likedListings = await ApiSocial.userLikes();
            dispatch({
                type: at.FETCH_LIKED_MAPS_SUCCESS,
                payload: likedListings,
            });
        } catch (err) {
            //todo do something with error
        }
    };
};
