import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import MilitaryMarker from './military-marker';
import { MilSymbolValues, SidcMapValue } from './MilSymbol/values';
import { getSIDCName, updateSIDC } from './military-marker-util';

interface MilitaryMarkerSelectProps {
    selectOptions: MilSymbolValues[];
    name: SidcMapValue;
    selectControlWidth: string;
    selectedMarker: MilitaryMarker;
    updateMarker: (marker: MilitaryMarker) => void;
}

const MilitaryMarkerSelect = ({
    selectOptions,
    name,
    selectControlWidth,
    selectedMarker,
    updateMarker,
}: MilitaryMarkerSelectProps) => {
    const [selectedOption, setSelectedOption] = useState<MilSymbolValues>();

    useEffect(() => {
        const getSelectedOption = getSIDCName(selectOptions, selectedMarker.sidc, name);
        setSelectedOption(getSelectedOption);
    }, [name, selectOptions, selectedMarker.sidc]);

    const handleChangeMarker = (option: MilSymbolValues) => {
        const updatedMarker = {
            ...selectedMarker,
            sidc: updateSIDC(selectedMarker.sidc, name, option.value, option.symbolSet),
        };
        updateMarker(updatedMarker);
        setSelectedOption(option);
    };

    const Input = (inputProps) => {
        return (
            <components.Input {...inputProps} data-testid={`${name}-annotation-nato-dropdown-select-option-input`} />
        );
    };
    const Option = (optionProps) => {
        return (
            <components.Option
                {...optionProps}
                innerProps={Object.assign({}, optionProps.innerProps, {
                    'data-testid': `${name}-annotation-nato-dropdown-select-option`,
                })}
            />
        );
    };

    const DropdownIndicator = (dropdownProps) => {
        return (
            <components.DropdownIndicator
                {...dropdownProps}
                innerProps={Object.assign({}, dropdownProps.innerProps, {
                    'data-testid': `${name}-annotation-nato-dropdown-select-option-indicator`,
                })}
            />
        );
    };

    return (
        <Container>
            <Select
                components={{ Input, Option, DropdownIndicator }}
                value={selectedOption}
                placeholder={`Select `}
                options={selectOptions}
                formatOptionLabel={(option) => (
                    <SelectOptionDropdownItem key={`marker-select-dropdown-${option.text}-${option.value}`}>
                        {option.text}
                    </SelectOptionDropdownItem>
                )}
                onChange={(option) => {
                    if (option) {
                        handleChangeMarker(option);
                    }
                }}
                isSearchable={false}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        cursor: 'pointer',
                        background: 'transparent',
                        borderColor: 'rgba(255,255,255,0.95)',
                        width: selectControlWidth || '100%',
                        ':hover': {
                            boxShadow: 'none !important',
                            borderColor: 'rgba(255,255,255,0.95)',
                        },
                        ':active': {
                            boxShadow: 'none !important',
                            borderColor: 'rgba(255,255,255,0.95)',
                        },
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: 'rgba(255,255,255,0.95)',
                    }),
                    menu: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: 'rgba(0,0,0,0.8)',
                    }),
                    menuList: (baseStyles) => ({
                        ...baseStyles,
                        '::-webkit-scrollbar': {
                            width: '6px',
                            backgroundColor: '#343a40',
                        },
                        '::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                            backgroundColor: '#343a40',
                        },
                        '::-webkit-scrollbar-thumb': {
                            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                            backgroundColor: '#eed926',
                            borderRadius: '4px',
                        },
                        overflowX: 'hidden',
                    }),
                    option: (baseStyles, { isFocused }) => ({
                        ...baseStyles,
                        backgroundColor: isFocused ? 'rgba(255,255,255,0.3)' : 'transparent',
                    }),
                }}
            />
        </Container>
    );
};

export default MilitaryMarkerSelect;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 8px;
    align-items: center;
    cursor: pointer !important;
`;

const SelectOptionDropdownItem = styled.div`
    color: white;
`;
