import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { ListingDTO } from '../../../api/model';
import ImageryMasonaryCard from './imagery-masonary-card';

import { useWindowSize } from '@react-hook/window-size';
import ContactUs from '../../Contact/contact-us';
import ImageryEditModal from './imagery-edit-modal';
import ImageryDeleteModal from './imagery-delete-modal';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import NoImageryModal from './Shared/no-imagery-modal';
import profileIconUrl from '../profile-icon-url';
import Masonry from 'react-masonry-css';

enum Mode {
    EDIT,
    DELETE,
    REPORT,
    RESUBMIT,
    REUPLOAD,
}

interface ImageryMasonaryProps {
    listings: ListingDTO[];
    onRefresh: () => void;
    sortBy?: string;
    hasSearched?: boolean;
    dataTestId?: string;
}

const MAX_CARD_WIDTH = 300;
const SIDEBAR_WIDTH = 350;

const ImageryMasonary = (props: ImageryMasonaryProps) => {
    const { listings, onRefresh } = props;
    const [selectedListing, setSelectedListing] = useState<ListingDTO | undefined>(undefined);
    const [mode, setMode] = useState<Mode | undefined>(undefined);

    const [windowWidth] = useWindowSize();

    const masonryBreakPoints = (windowWidth: number): number => {
        const availableWidth = windowWidth - SIDEBAR_WIDTH;
        const numCards = Math.floor(availableWidth / MAX_CARD_WIDTH);
        return numCards;
    };

    const onAdd = () => {
        UriHelper.navigateToPath('/upload');
    };

    const onEdit = (id: number) => {
        setSelectedListing(listings.find((l) => l.id === id));
        setMode(Mode.EDIT);
    };

    const onDelete = (id: number) => {
        setSelectedListing(listings.find((l) => l.id === id));
        setMode(Mode.DELETE);
    };

    const onReport = (id: number) => {
        setSelectedListing(listings.find((l) => l.id === id));
        setMode(Mode.REPORT);
    };

    const selectableListings: ListingDTO[] = listings.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    if (selectableListings.length === 0) {
        return (
            <NoImageryModal
                icon={profileIconUrl.noImagesUrl}
                title={"It looks like you haven't uploaded any maps yet"}
                text={
                    '  What are you waiting for!? Check the tutorials on how to create compelling content and keep those images Soaring!'
                }
                mobileText={''}
                button={() => {
                    onAdd();
                }}
                buttonText={
                    <span>
                        <i className="fa fa-plus"></i> &nbsp; ADD YOUR MAPS
                    </span>
                }
            />
        );
    }

    return (
        <ProfileImageryMasonaryContainer data-testd={props.dataTestId}>
            {selectableListings ? (
                <React.Fragment>
                    <MasonryComponent
                        breakpointCols={masonryBreakPoints(windowWidth)}
                        className="masonry-grid"
                        columnClassName="masonry-grid_column"
                    >
                        {selectableListings.map((listing, index) => (
                            <ImageryMasonaryCard
                                dataTestId={props.dataTestId && `${props.dataTestId}-card`}
                                key={index}
                                listing={listing}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onReport={onReport}
                                handleAction={(action) => {
                                    if (action === 'RESUBMIT') {
                                        setSelectedListing(listing);
                                        setMode(Mode.RESUBMIT);
                                    } else if (action === 'REUPLOAD') {
                                        setMode(Mode.REUPLOAD);
                                        UriHelper.navigateToPath(`/reupload/${listing.id}`);
                                    } else if (action === 'PUBLIC' || action === 'PRIVATE') {
                                        onRefresh();
                                    }
                                }}
                            />
                        ))}
                    </MasonryComponent>
                    <RejectionTooltip id="reject-reason"></RejectionTooltip>
                </React.Fragment>
            ) : null}
            {mode === Mode.EDIT && selectedListing && (
                <ImageryEditModal
                    listingId={selectedListing.id}
                    listing={selectedListing}
                    handleSave={() => {
                        onRefresh();
                        setMode(undefined);
                    }}
                    editDetailsOpen={true}
                    setEditDetailsOpen={() => setMode(undefined)}
                />
            )}
            {mode === Mode.DELETE && selectedListing && (
                <ImageryDeleteModal
                    refreshMasonary={() => {
                        onRefresh();
                        setMode(undefined);
                    }}
                    showDelete={true}
                    toggleShowDelete={() => setMode(undefined)}
                    listingId={selectedListing.id}
                />
            )}

            {mode === Mode.REPORT && selectedListing && (
                <ContactUs
                    listingId={selectedListing.id}
                    isOpen={true}
                    toggle={() => setMode(undefined)}
                    customMessage={`What is the problem with ${ListingHelper.getTitleFromListing(selectedListing)}?`}
                />
            )}

            {mode === Mode.RESUBMIT && selectedListing && (
                <ImageryEditModal
                    listingId={selectedListing.id}
                    listing={selectedListing}
                    handleSave={() => {
                        onRefresh();
                        setMode(undefined);
                    }}
                    editDetailsOpen={true}
                    setEditDetailsOpen={() => setMode(undefined)}
                    reSubmit={true}
                />
            )}
        </ProfileImageryMasonaryContainer>
    );
};

export default ImageryMasonary;

const ProfileImageryMasonaryContainer = styled.div`
    background-color: #191a1a;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
`;

const MasonryComponent = styled(Masonry)`
    &.masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        width: auto;
    }

    &.masonry-grid_column {
        background-clip: padding-box;
        will-change: transform; /* Enable hardware acceleration for smoother animations */
    }
`;

const RejectionTooltip = styled(Tooltip)`
    max-width: 350px;
`;
