import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import Analytics from '../../../../lib/user-analytics';
import UserHelper from '../../../../lib/user-helper';
import { actionSetResetCommentSection } from '../../../../store/SocialMapping/actions';
import { selectAnnotationCommentId } from '../../../../store/SocialMapping/selectors';

interface ActiveCommentAnnotationTagsProps {
    annotationString: string;
}

interface TagProps {
    name: string;
    icon: string;
    count: number;
}

const ActiveCommentAnnotationTags = ({ annotationString }: ActiveCommentAnnotationTagsProps) => {
    const selectedAnnotationCommentId = useSelector(selectAnnotationCommentId);

    const dispatch = useDispatch();
    const setResetAnnotations = (resetAnnotations: boolean) => dispatch(actionSetResetCommentSection(resetAnnotations));

    const [annotationTags, setAnnotationTags] = useState<any | undefined>(undefined); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [annotationTagCount, setAnnotationTagCount] = useState<number>(0);
    const [tagAnimate, setTagAnimate] = useState<boolean>(false);
    const ANNOTATION_TAGS_DISPLAYED = 4; // Number of tags that display without grouping

    useEffect(() => {
        if (annotationString) {
            setTagAnimate(false);
            const tags = UserHelper.getTagsFromAnnotationString(annotationString);
            setAnnotationTags(tags);
            if (tags.length > 0) {
                const annotationCount = UserHelper.countAnnotations(tags);
                setTagAnimate(true);
                setAnnotationTagCount(annotationCount);
            }
        }
    }, [annotationString]);

    const handleRemoveCommentAnnotations = () => {
        if (window.confirm('Are you sure you want to delete all measurements?')) {
            setResetAnnotations(true);
            Analytics.Event('Comments', 'Clicked to remove comment annotations');
        }
    };

    if (!annotationTags || annotationTags.length === 0) {
        return <React.Fragment />;
    }

    return (
        <CommentItemAnnotations>
            {annotationTags?.length > 0 &&
            annotationTags?.length >= ANNOTATION_TAGS_DISPLAYED &&
            annotationTagCount !== 0 ? (
                <CommentTagItem
                    key={annotationTagCount}
                    animate={tagAnimate || selectedAnnotationCommentId ? true : false}
                >
                    {`${annotationTagCount} x Annotations on Map`}
                    <DeleteCommentIcon
                        className="fa fa-times"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRemoveCommentAnnotations();
                        }}
                    />
                </CommentTagItem>
            ) : (
                <React.Fragment>
                    {annotationTags?.length > 0 &&
                        annotationTags.map((tag: TagProps, _: number) => (
                            <CommentTagItem
                                key={tag.name + tag.count}
                                animate={tagAnimate || selectedAnnotationCommentId ? true : false}
                            >
                                {`${tag.count} `} x {`${tag.name}`}
                                <DeleteCommentIcon
                                    className="fa fa-times"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleRemoveCommentAnnotations();
                                    }}
                                />
                            </CommentTagItem>
                        ))}
                </React.Fragment>
            )}
        </CommentItemAnnotations>
    );
};

export default ActiveCommentAnnotationTags;

const CommentItemAnnotations = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
`;

interface CommentTagItemProps {
    animate: boolean;
}

const CommentTagItem = styled.li<CommentTagItemProps>`
    color: rgba(255, 255, 255, 0.8);
    list-style-type: none;
    border-radius: 6px;
    margin: 5px;
    padding: 2px 4px 2px 4px;
    font-size: 13px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    animation: ${(props) => (props.animate ? Shockwave : 'none')} 400ms ease-in-out 0s 1 forwards;
`;

const DeleteCommentIcon = styled.i`
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
    padding: 0 0 0 4px;
`;

const Shockwave = keyframes`
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }
    25% {
        transform: scale(1.1);
        animation-timing-function: ease-in;
        -webkit-box-shadow: 0px 0px 0px 1px #EED926;
        -moz-box-shadow: 0px 0px 0px 1px #EED926;
        box-shadow: 0px 0px 0px 1px #EED926;
        transform-origin: center center;
        border: 1px solid #EED926;
        background-color: #EED926
    }
    50% {
        transform: scale(1);
        animation-timing-function: ease-out;
        -webkit-box-shadow: 0px 0px 0px 4px #EED926;
        -moz-box-shadow: 0px 0px 0px 4px #EED926;
        box-shadow: 0px 0px 0px 4px #EED926;
        transform-origin: center center;
        border: 1px solid #EED926;
        color: rgba(255,255,255,0.8);
    }
    75% {
        transform: scale(1.05);
        animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-box-shadow: 0px 0px 0px 1px #EED926;
        -moz-box-shadow: 0px 0px 0px 1px #EED926;
        box-shadow: 0px 0px 0px 1px #EED926;
        transform-origin: center center;
        border: 1px solid #EED926;
        color: #EED926
    }
    to {
        transform: scale(1);
        animation-timing-function: ease-out;
        color: rgba(255,255,255,0.8);

    }
`;
