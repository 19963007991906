/**
 *  This file is used to calculate the day and night terminator line on the map
 *  Based on https://github.com/tammaroivan/react-leaflet-night-region
 */
import { useState } from 'react';
import { Polygon } from 'react-leaflet';
import useInterval from './use-interval';
import {
    getJulianDate,
    getGMST,
    getHourAngle,
    computeLatitude,
    getSunEclipticPosition,
    getEclipticObliquity,
    getSunEquatorialPosition,
} from './day-night-utils';

const DEFAULT_REFRESH_INTERVAL = 2000;

/**
 * DayNightTerminator component
 * @param refreshInterval - interval to refresh the day/night terminator line
 * @param rest - rest of the props
 * @example
 * <DayNightTerminator fillColor="black" color="black" fillOpacity="0.8" />
 * <DayNightTerminator refreshInterval={5000} fillColor="black" color="black" fillOpacity="0.8" />
 */
export const DayNightTerminator = ({ refreshInterval = DEFAULT_REFRESH_INTERVAL, ...rest }) => {
    const [positions, setPositions] = useState<[number, number][]>([]);

    const getNightRegionPositions = (specificDate?: Date): [number, number][] => {
        const date = specificDate || new Date();
        const julianDate = getJulianDate(date);
        const gst = getGMST(julianDate);
        const latLng: [number, number][] = [];
        const sunEclPos = getSunEclipticPosition(julianDate);
        const eclObliq = getEclipticObliquity(julianDate);
        const sunEqPos = getSunEquatorialPosition(sunEclPos.lambda, eclObliq);
        const sunEqPosValue = sunEqPos.delta < 0 ? 90 : -90;
        const minsInDay = 1440;

        for (let i = 0; i <= minsInDay; i++) {
            const lng = -360 + i / 2;
            const ha = getHourAngle(lng, sunEqPos, gst);
            latLng.push([computeLatitude(ha, sunEqPos), lng]);
        }

        latLng.unshift([sunEqPosValue, -360]);
        latLng.push([sunEqPosValue, 360]);

        return latLng;
    };

    useInterval(() => {
        const nightRegionPositions = getNightRegionPositions();
        setPositions(nightRegionPositions);
    }, refreshInterval || DEFAULT_REFRESH_INTERVAL);

    return <Polygon positions={positions} {...rest} />;
};
