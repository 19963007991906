import React, { useEffect, useState } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';
import { OrderForPayment } from '../../../api/model';
import Constants from '../../../constants';
import SideDrawerPurchaseCreditCardDetails from '../../Drawer/Satellites/OrderWorkflow/satellite-order-credit-card-details';
import PriceWithCurrency from '../../Shared/price-with-currency';
import UriHelper, { useQuery } from '../../../lib/uri-helper';
import { DashboardMode } from '../../../store/Dashboard/model';
import { PulseLoader } from '../../Shared/pulse-loader';
import ApiOrder from '../../../api/api-order';

const OrderPayment = () => {
    const id = useQuery().get('id');

    const [tasks, setTasks] = useState<OrderForPayment[] | undefined>(undefined);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalTax, setTotalTax] = useState<number>(0);
    const [currency, setCurrency] = useState<string>('');
    const [selected, setSelected] = useState<number[]>(id && !isNaN(Number(id)) ? [Number(id)] : []);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        ApiOrder.getOrdersForPayment()
            .then((res) => {
                setTasks(res);
            })
            .catch((_) => {
                setError('Error retrieving payments due, Please try again');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setError(undefined);

        let total_price = 0;
        let total_tax = 0;
        let task_currency = '';
        if (tasks && selected.length) {
            tasks
                .filter((t) => selected.includes(t.order.id))
                .forEach((t) => {
                    total_price += t.preview.totalPrice;
                    total_tax += t.preview.totalTax ? t.preview.totalTax : 0;
                    if (task_currency === '') {
                        task_currency = t.preview.currency;
                    } else if (task_currency !== t.preview.currency) {
                        setError('Tasks with different currency can not be paid at the same time');
                    }
                });
        }
        setTotalPrice(total_price);
        setTotalTax(total_tax);
        setCurrency(task_currency);
    }, [selected, tasks]);

    const typeStringForProvider = (type: string): string => {
        if (type === 'CSW' || type === 'CSW_CUSTOM') return 'New Collection';
        if (type === 'CSW_ARCHIVE' || type === 'CSW_CUSTOM_ARCHIVE') return 'Archival Imagery';
        if (type === 'CSW_CUSTOM_URGENT') return 'Urgent Collection';
        if (type === 'CSW_CUSTOM_STEREO') return 'Stereo Collection';
        if (type === 'CSW_CUSTOM_MONITORING') return 'Monitoring Collection';
        return 'Collection';
    };

    const onTaskSelected = (taskId: number) => {
        if (selected.includes(taskId)) {
            setSelected(selected.filter((id) => id !== taskId));
        } else {
            setSelected([...selected, taskId]);
        }
    };

    const onStripeTokenReceived = (stripeToken: string) => {
        ApiOrder.payOrders(selected, totalPrice, currency, stripeToken)
            .then(() => {
                UriHelper.navigateToDashboard(DashboardMode.SATELLITE_QUEUE);
            })
            .catch((err) => {
                setError(err.message);
            });
    };
    return (
        <React.Fragment>
            <Background>
                <TaskPaymentContainer>
                    <TaskPaymentTitle>Payments Due</TaskPaymentTitle>
                    {isLoading ? <PulseLoader iconSize={'18px'} iconMargin={'5px 10px 20px 10px'} /> : null}
                    {!isLoading &&
                        tasks?.map((tp) => (
                            <React.Fragment>
                                <Divider />
                                <FormGroup check>
                                    <Label check>
                                        <TaskItem>
                                            <TaskCheckBox
                                                checked={selected.includes(tp.order.id)}
                                                onClick={() => onTaskSelected(tp.order.id)}
                                                type="checkbox"
                                            />
                                            <TaskText>
                                                <TaskTextDetails>
                                                    {`${tp.order.orderNumber} (${typeStringForProvider(
                                                        tp.order.provider
                                                    )})`}
                                                </TaskTextDetails>
                                                <TaskLabelPrice>
                                                    <span>Order price: </span>
                                                    <PriceWithCurrency
                                                        value={tp.preview.totalPrice}
                                                        currency={tp.preview.currency}
                                                    />
                                                    {tp.preview.totalTax && tp.preview.totalTax > 0 ? ' inc. GST' : ''}
                                                </TaskLabelPrice>
                                            </TaskText>
                                        </TaskItem>
                                    </Label>
                                </FormGroup>
                            </React.Fragment>
                        ))}
                    <TotalPrice>
                        <PriceLabel>
                            <PriceWithCurrency value={totalPrice} currency={currency} />
                        </PriceLabel>
                        <GST>{totalTax && totalTax > 0 ? <>inc. GST</> : null}</GST>
                    </TotalPrice>
                    {error && <ErrorText>{error}</ErrorText>}
                    {totalPrice > 0 ? (
                        <StripeProvider apiKey={Constants.STRIPE_API_KEY}>
                            <Elements>
                                <SideDrawerPurchaseCreditCardDetails
                                    totalPrice={totalPrice}
                                    totalTax={totalTax}
                                    currency={currency}
                                    termsAndConditionsHref="/skymap50-terms"
                                    handleStripeTokenSubmitted={onStripeTokenReceived}
                                />
                            </Elements>
                        </StripeProvider>
                    ) : null}
                </TaskPaymentContainer>
            </Background>
        </React.Fragment>
    );
};

export default OrderPayment;

const TaskPaymentContainer = styled.div`
    color: white;
    background-color: rgba(0, 0, 0, 0.85) !important;
    border-radius: 6px;
    max-width: 600px;
    overflow: auto;
    margin: 10% auto 10px auto;
    padding: 20px;
    max-height: 75%;

    @media only screen and (max-width: 600px) {
        margin: 46px auto 0px auto;
        max-height: calc(100% - 5%);
        border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const TaskPaymentTitle = styled.h3`
    color: inherit;
    margin-bottom: 20px;
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-image: url(/assets/backgrounds-large/error-background.jpg);
    background-size: cover;
    background-repeat: repeat-y;
    height: 100vh;
    width: 100vw;
`;

const ErrorText = styled.p`
    margin: 15px auto 0px auto;
    color: red;
    text-align: center;
    font-size: 15px;
`;

const TaskItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
`;

const TaskText = styled.div`
    width: 100%;
    padding-left: 10px;
    color: inherit;
    display: flex;
    flex-direction: column;
    line-height: 15px;
`;

const TaskTextDetails = styled.p`
    color: white;
`;

const TaskLabelPrice = styled.p`
    color: #eed926 !important;

    span {
        color: white;
    }
`;

const TotalPrice = styled.div`
    width: 300px;
    text-align: center;
    margin: 0 auto;
    margin-top: 18px;
    margin-bottom: 4px;
    display: block;
    padding: 8px 24px 8px 24px;
    border: 1px solid #eed926;
    border-radius: 6px;

    div {
        color: #eed926;
        font-weight: bold;
    }
`;

const GST = styled.div`
    font-size: 1.2rem;
`;

const PriceLabel = styled.div`
    font-size: 1.6rem;
`;

const Divider = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.3);
`;

const TaskCheckBox = styled.input`
    margin-top: -43px;

    @media only screen and (max-width: 600px) {
        margin-top: -57px;
    }
`;
