import React, { useEffect, useRef, useState } from 'react';
import { LayersControl, FeatureGroup, useMap } from 'react-leaflet';
import { Satellite } from '../../../lib/satellite-util';
import SatelliteTracker from './satellite-tracker';
import Analytics from '../../../lib/user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import { selectSatelliteOverlays } from '../../../store/Map/SatelliteOverlay/selectors';
import { actionAddSatelliteOverlay, actionRemoveSatelliteOverlay } from '../../../store/Map/SatelliteOverlay/actions';
import { Control } from 'leaflet';
import { DayNightTerminator } from '../DatNightTerminator/day-night-terminator';

const { Overlay } = LayersControl;

const satelliteLayersIconUrl = '../../assets/map-categories/button_satellite.png';

const SatelliteLayers = () => {
    const [activeSatelliteOverlays, setActiveSatelliteOverlays] = useState<string[]>([]);
    const map = useMap();
    const dispatch = useDispatch();
    const satelliteOverlaysLayer = useSelector(selectSatelliteOverlays);

    const satelliteLayersRef = useRef<Control.Layers>(null);

    useEffect(() => {
        if (satelliteLayersRef?.current) {
            // @ts-ignore
            const layersLink = satelliteLayersRef.current._layersLink;
            layersLink.style.backgroundImage = `url(${satelliteLayersIconUrl})`;
            layersLink.title = '';
            layersLink.setAttribute('data-testid', 'satellite-layer-control-icon');
        }
    }, []);

    useEffect(() => {
        if (satelliteLayersRef && satelliteLayersRef.current) {
            const container = satelliteLayersRef.current.getContainer();
            if (container) {
                const controlContainer = container.querySelector('.leaflet-control-layers-list');
                if (controlContainer) {
                    const title = document.createElement('h5');
                    title.style.color = 'white';
                    title.style.fontWeight = 'bold';
                    title.innerHTML = 'Satellite Paths';

                    const divider = document.createElement('div');
                    divider.style.borderBottom = '1px solid rgba(255, 255, 255, 0.2)';
                    divider.style.width = '100%';
                    divider.style.marginBottom = '12px';

                    controlContainer.prepend(divider);
                    controlContainer.prepend(title);
                }
            }
        }
    }, [satelliteLayersRef]);

    useEffect(() => {
        const addSatelliteOverlay = (name: string) => {
            Analytics.Event('Main View', 'Satellite Overlay', 'Add ' + name);
            dispatch(actionAddSatelliteOverlay(name));
            map.off('overlayadd', (e: { name: string }) => addSatelliteOverlay(e.name));
        };

        const removeSatelliteOverlay = (name: string) => {
            Analytics.Event('Main View', 'Satellite Overlay', 'Remove ' + name);
            dispatch(actionRemoveSatelliteOverlay(name));
            map.off('overlayremove', (e: { name: string }) => removeSatelliteOverlay(e.name));
        };

        map.on('overlayadd', (e: { name: string }) => addSatelliteOverlay(e.name));
        map.on('overlayremove', (e: { name: string }) => removeSatelliteOverlay(e.name));
    }, [dispatch, map]);

    useEffect(() => {
        if (satelliteOverlaysLayer) {
            setActiveSatelliteOverlays(satelliteOverlaysLayer);
        }
    }, [satelliteOverlaysLayer]);

    return (
        <LayersControl position="bottomright" ref={satelliteLayersRef}>
            <Overlay
                name="Day / Night"
                checked={activeSatelliteOverlays.indexOf('Day / Night') !== -1}
                key="Day / Night"
            >
                <FeatureGroup>
                    {activeSatelliteOverlays.indexOf('Day / Night') !== -1 && (
                        <DayNightTerminator fillColor="black" color="rgba(0,0,0,0.1)" fillOpacity="0.67" />
                    )}
                </FeatureGroup>
            </Overlay>

            <Overlay name="SkyMap50 1" checked={activeSatelliteOverlays.indexOf('SkyMap50 1') !== -1} key="SkyMap50 1">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap501}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 1') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay name="SkyMap50 2" checked={activeSatelliteOverlays.indexOf('SkyMap50 2') !== -1}>
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap502}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 2') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('SkyMap50 3') !== -1} name="SkyMap50 3">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap503}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 3') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('SkyMap50 4') !== -1} name="SkyMap50 4">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap504}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 4') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 1A') !== -1} name="Sentinel 1A">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel1A}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 1A') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 2A') !== -1} name="Sentinel 2A">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel2A}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 2A') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 1B') !== -1} name="Sentinel 1B">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel1B}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 1B') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 2B') !== -1} name="Sentinel 2B">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel2B}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 2B') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Landsat 8') !== -1} name="Landsat 8">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Landsat8}
                        isActive={activeSatelliteOverlays.indexOf('Landsat 8') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Int. Space Station') !== -1} name="Int. Space Station">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.ISS}
                        isActive={activeSatelliteOverlays.indexOf('Int. Space Station') !== -1}
                    />
                </FeatureGroup>
            </Overlay>
        </LayersControl>
    );
};

export default SatelliteLayers;
