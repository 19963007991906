import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L, { LeafletMouseEvent } from 'leaflet';

interface CirclePathElementProps {
    circleElement: Readonly<{ instance: L.Circle; context: Readonly<{ map: L.Map }> }>;
    color?: string;
}

export const createCirclePathElement = (props: CirclePathElementProps, context: LeafletContextInterface) => {
    // Create a hologram path and track the users movement on the circle ghost
    const hologramCirclePath = new L.Polyline([], { dashArray: '5, 5', interactive: false });
    const hologramCirclePathElement = createElementObject<L.Polyline>(hologramCirclePath, context);

    hologramCirclePathElement.instance.on('add', () => {
        hologramCirclePathElement.instance.setStyle({ color: props.color });

        context.map.on('mouseout', () => {
            context.map.removeLayer(hologramCirclePathElement.instance);
        });

        context.map.on('mousemove', (e: LeafletMouseEvent) => {
            hologramCirclePathElement.instance.setLatLngs([props.circleElement.instance.getLatLng(), e.latlng]);
        });
    });

    return hologramCirclePathElement;
};
