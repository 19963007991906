import { PurchasedInfo } from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

interface StorePurchase {
    purchasedListings: PurchasedInfo[];
}

const initialState: StorePurchase = {
    purchasedListings: [],
};

export default (state: StorePurchase = initialState, action: Action): StorePurchase => {
    switch (action.type) {
        case at.UPDATE_PURCHASED_LISTINGS:
            return { ...state, purchasedListings: [] };

        case at.UPDATE_PURCHASED_LISTINGS_SUCCESS:
            return { ...state, purchasedListings: action.payload };
        default:
            return state;
    }
};
