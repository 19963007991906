import React, { useState } from 'react';
import ImagePreviewFailed, { PreviewSize } from '../../Shared/image-preview-failed';

interface ImageWithFallbackProps {
    src: string | undefined;
    alt: string;
    onClick?: () => void;
    onLoad?: () => void;
    onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    loading?: 'eager' | 'lazy';
    smallPreview?: boolean;
    height?: string;
    className?: string;
}

/*
 * ImageWithFallback component is used to display an image with a fallback image if the original image fails to load.
 * The component takes the following props:
 * @param src - the source URL of the image
 * @param alt - the alt text of the image
 * @param onClick - the function to be called when the image is clicked
 * @param onLoad - the function to be called when the image is loaded
 * @param onError - the function to be called when the image fails to load
 * @param loading - the loading attribute of the image
 */

const ImageWithFallback = ({
    src,
    alt,
    onClick,
    onLoad,
    onError,
    loading,
    smallPreview,
    height,
    className,
}: ImageWithFallbackProps) => {
    const [hasError, setHasError] = useState(false);

    const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setHasError(true);
        onError && onError(e);
    };

    return (
        <React.Fragment>
            {hasError || !src ? (
                <ImagePreviewFailed className={className} previewSize={smallPreview ? PreviewSize.SMALL : undefined} />
            ) : (
                <img
                    className={className}
                    src={src}
                    alt={alt}
                    onClick={onClick}
                    onLoad={onLoad}
                    onError={handleError}
                    loading={loading}
                    style={{ width: '100%', height: height || 'auto', objectFit: 'cover' }}
                />
            )}
        </React.Fragment>
    );
};

export default ImageWithFallback;
