import React from 'react';
import { LatLng } from 'leaflet';
import { Polygon } from 'react-leaflet';
import { ListingDTO } from '../../../../api/model';
import UriHelper from '../../../../lib/uri-helper';
import { wktPolygonToLatLngArray } from '../../../../lib/geo-util';

interface MapSearchPreviewProps {
    listings: ListingDTO[];
    wkt?: string;
}

const MapSearchPreview = (props: MapSearchPreviewProps) => {
    if (!props.listings?.length && props.wkt) {
        return (
            <Polygon
                positions={wktPolygonToLatLngArray(props.wkt) as LatLng[]}
                weight={2}
                color={'#EED926'}
                fillColor={'rgba(238, 218, 38, 0.5)'}
            />
        );
    }

    return (
        <React.Fragment>
            {props.listings?.map((listing: ListingDTO, index: number) => {
                if (!listing.bboxWKT) {
                    return;
                }

                return (
                    <Polygon
                        key={`${index}-${listing.id}`}
                        positions={wktPolygonToLatLngArray(listing.bboxWKT) as LatLng[]}
                        weight={2}
                        fillOpacity={0.9}
                        color={'#3388ff'}
                        fillColor={'transparent'}
                        eventHandlers={{
                            click: () => UriHelper.navigateToMap(listing.id),
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MapSearchPreview;
