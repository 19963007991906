import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SatelliteArchiveItem from './satellite-archive-item';
import { SatelliteArchiveImageryDTO } from '../../../../api/api-supplier';
import { SatelliteArchiveResultsHeader } from './satellite-archive-results-header';

interface SatelliteArchiveSelectAndReorderProps {
    results: SatelliteArchiveImageryDTO[];
    selectedResults: SatelliteArchiveImageryDTO[];

    reorderScene: (reorder: { [sceneId: number]: number }) => void;
    setResultActive: (result: SatelliteArchiveImageryDTO) => void;
    setResultInactive: (result: SatelliteArchiveImageryDTO) => void;
    setResultMouseOver: (result: SatelliteArchiveImageryDTO) => void;
    setResultMouseOut: (result: SatelliteArchiveImageryDTO) => void;

    // The 'purchase' button is passed in as a child so that all the
    // logic and state for cost lookups, errors, etc can be handled by
    // the parent component but positioned relative to the drag and drop container
    children: React.ReactNode;
}

const SatelliteArchiveSelectAndReorder = (props: SatelliteArchiveSelectAndReorderProps) => {
    const onDragEnd = (e) => {
        if (e.reason !== 'DROP') return;
        if (!e.destination) return;
        const sceneId = e.draggableId.replace('_key', '');
        const resultIndex = props.results.findIndex((t) => String(t.id) === sceneId);
        if (resultIndex === -1) return;
        const cswResult = props.results[resultIndex];
        const destinationIndex = e.destination.index;

        const reordered = props.results;
        reordered.splice(resultIndex, 1);
        reordered.splice(destinationIndex, 0, cswResult);
        const order: { [sceneId: number]: number } = {};
        reordered.forEach((feature, index) => {
            order[feature.id] = reordered.length + 100 - index;
        });

        props.reorderScene(order);
    };

    const bottomContainerHeight = props.selectedResults.length > 0 ? 110 : 45;

    return (
        <React.Fragment>
            <ResultsContainer bottomContainerHeight={bottomContainerHeight}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <SatelliteArchiveResultsHeader />
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <DroppableContainer ref={provided.innerRef}>
                                {props.results.map((item, index) => (
                                    <SatelliteDraggable
                                        key={item.id + '_key'}
                                        draggableId={item.id + '_key'}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <SatelliteArchiveItem
                                                    result={item}
                                                    isDragging={snapshot.isDragging}
                                                    isSelected={
                                                        props.selectedResults &&
                                                        props.selectedResults.findIndex((t) => t.id === item.id) !== -1
                                                    }
                                                    setResultActive={(result) => props.setResultActive(result)}
                                                    setResultInActive={(result) => props.setResultInactive(result)}
                                                    setResultMouseOut={(result) => props.setResultMouseOut(result)}
                                                    setResultMouseOver={(result) => props.setResultMouseOver(result)}
                                                />
                                            </div>
                                        )}
                                    </SatelliteDraggable>
                                ))}
                                {provided.placeholder}
                            </DroppableContainer>
                        )}
                    </Droppable>
                </DragDropContext>
                <FixedBottomContainer bottomContainerHeight={bottomContainerHeight}>
                    <FixedBottomResults>{props.children}</FixedBottomResults>
                </FixedBottomContainer>
            </ResultsContainer>
        </React.Fragment>
    );
};

export default SatelliteArchiveSelectAndReorder;

interface BottomContainerSizeProps {
    bottomContainerHeight: number;
}

const ResultsContainer = styled.div<BottomContainerSizeProps>`
    overflow-y: auto;
    flex-basis: calc(100vh - 215px);
    display: flex;
    flex-direction: column;
    position: relative;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const DroppableContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-basis: calc(100vh - 110px);

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const FixedBottomContainer = styled.div<BottomContainerSizeProps>`
    display: flex;
    flex-direction: column;
    height: 100px;
`;

const FixedBottomResults = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 10px;
`;

const SatelliteDraggable = styled(Draggable)`
    min-width: 500px;
`;
