// TODO: Due for refactor, since code is still class based

import * as React from 'react';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import styled from 'styled-components';

import store from '../../../store/store';

import ApiSubdomain from '../../../api/api-subdomain';
import { DomainDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';

interface ChangeCreditCardProps {
    stripe?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    handleUpdatedCreditCard: (domain: DomainDTO) => void;
    handleCloseChangeCreditCard?: () => void;
}

interface ChangeCreditCardState {
    isCreatingStripeToken: boolean;
    stripeError?: string;
}

class ChangeCreditCard extends React.Component<ChangeCreditCardProps, ChangeCreditCardState> {
    constructor(props) {
        super(props);

        this.state = {
            isCreatingStripeToken: false,
            stripeError: undefined,
        };

        this.handleChangeCreditCardSubmit = this.handleChangeCreditCardSubmit.bind(this);
    }

    async handleChangeCreditCardSubmit(e) {
        e.preventDefault();

        // Precondition: Stripe has initalized
        if (this.props.stripe === undefined) {
            this.setState({
                stripeError: "Stripe.js hasn't loaded",
            });
            return;
        }

        // Precondition: User has login credentials
        const userLoggedIn = store.getState().accountDomain.loggedIn;
        if (!userLoggedIn) {
            this.setState({
                stripeError: 'User is not logged in',
            });
            return;
        }

        this.setState({
            isCreatingStripeToken: true,
            stripeError: undefined,
        });
        try {
            const stripeResponse = await this.props.stripe.createToken();
            const stripeToken = stripeResponse?.token?.id;
            if (stripeToken) {
                await ApiSubdomain.updateCreditCardDetails(stripeToken).then((res) => {
                    toast.dark('Credit card details updated');
                    this.props.handleUpdatedCreditCard(res);
                });
                Analytics.Event('My Dashboard', 'Updated CC details');
            } else {
                const errorMessage = stripeResponse.error
                    ? stripeResponse.error.message
                    : 'Update Failed, please check your details';
                this.setState({
                    stripeError: errorMessage,
                });
            }
        } catch (err) {
            this.setState({
                stripeError: err?.message,
            });
            toast.error('Update Failed, please check your details');
        } finally {
            this.setState({
                isCreatingStripeToken: false,
            });
        }
    }

    // TODO the name input has never done anything....

    render(): React.ReactElement {
        return (
            <ChangeCreditCardContainer data-sentry-block>
                <CreditCardContainer>
                    <h4>Update Billing Information</h4>

                    <form onSubmit={this.handleChangeCreditCardSubmit}>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <label>Card holder name</label>
                                <NameOnCard placeholder="Name on card" />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <label>Card Number</label>
                                <div>
                                    <SoarCardNumberElement
                                        style={{
                                            base: {
                                                color: 'rgba(255, 255, 255, 0.6)',
                                                '::placeholder': {
                                                    color: 'rgba(255, 255, 255, 0.6)',
                                                },
                                            },
                                        }}
                                    />
                                    <StripeCardsImage src="/assets/payments/mastercard-visa-americanexpress.png" />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <CardDetailsInputs>
                                    <StripeElementsDetails>
                                        <label>Expiry</label>
                                        <SoarCardExpiryElement
                                            style={{
                                                base: {
                                                    color: 'rgba(255, 255, 255, 0.6)',
                                                    '::placeholder': {
                                                        color: 'rgba(255, 255, 255, 0.6)',
                                                    },
                                                },
                                            }}
                                        />
                                    </StripeElementsDetails>

                                    <StripeElementsDetails>
                                        <label>CVC</label>
                                        <SoarCardCVCElement
                                            style={{
                                                base: {
                                                    color: 'rgba(255, 255, 255, 0.6)',
                                                    '::placeholder': {
                                                        color: 'rgba(255, 255, 255, 0.6)',
                                                    },
                                                },
                                            }}
                                        />
                                    </StripeElementsDetails>
                                </CardDetailsInputs>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.stripeError && (
                                <Col md={{ size: 12 }}>
                                    <StripeError>{this.state.stripeError}</StripeError>
                                </Col>
                            )}

                            <Col md={{ size: 12 }}>
                                <CardDetailsButtons>
                                    {this.props.handleCloseChangeCreditCard && (
                                        <CardDetailsButtonCancel onClick={this.props.handleCloseChangeCreditCard}>
                                            CANCEL
                                        </CardDetailsButtonCancel>
                                    )}
                                    <CardDetailsButtonUpdate
                                        onClick={this.handleChangeCreditCardSubmit}
                                        disabled={this.state.isCreatingStripeToken}
                                    >
                                        UPDATE CREDIT CARD
                                    </CardDetailsButtonUpdate>
                                </CardDetailsButtons>
                            </Col>
                            <StripeLogo src="/assets/payments/powered_by_stripe.png" />
                        </Row>
                    </form>
                </CreditCardContainer>
            </ChangeCreditCardContainer>
        );
    }
}

export default injectStripe(ChangeCreditCard);

const ChangeCreditCardContainer = styled.div`
    margin: 20px 0px;
    border-radius: 6px;
    background-color: rgb(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    h4 {
        color: white;
    }
    label {
        color: white;
    }

    @media only screen and (max-width: 600px) {
        display: block;
    }
`;

const CreditCardContainer = styled.div`
    margin: 20px 5px;

    @media (min-width: 768px) {
        width: 500px;
        padding: 3px auto;
    }
`;

const StripeCardsImage = styled.img`
    height: 50px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const CardDetailsInputs = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
`;

const StripeElementsDetails = styled.div`
    label {
        background-color: rgb(10, 10, 10);
        width: 100%;
        padding-bottom: 2px;
        text-align: left;
    }
`;

const StripeError = styled.div`
    color: red;
    text-align: center;
    width: 100%;
`;

const CardDetailsButtons = styled.div`
    display: block;
    margin: 0 auto;
    text-align: center;
`;

const CardDetailsButtonUpdate = styled(Button)`
    background-color: #eed926 !important;
    color: black !important;
    font-size: 14px !important;
    margin: 14px 22px 0 0 !important;
`;

const CardDetailsButtonCancel = styled(Button)`
    background-color: transparent !important;
    color: white !important;
    font-size: 14px !important;
    margin: 14px 22px 0 0 !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
`;

const StripeLogo = styled.img`
    height: 25px;
    background-color: white;
    border-radius: 12px;
    margin: 10px auto;

    @media only screen and (max-width: 600px) {
        display: block;
        margin: 0 auto;
        margin-top: 10px;
    }
`;

const ChangeCreditCardFieldCSS = `
    text-align: left;
    display: block;
    height: 40px;
    padding: 12px 12px;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.6) !important;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: 'Manrope', sans-serif !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    :hover,
    :active,
    :focus,
    :focus-visible {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    }
`;

const NameOnCard = styled.input`
    background-color: transparent;
    width: 100%;
    height: 40px !important;
    padding: 0px 12px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 16px;
    outline: none;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.6);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    :hover,
    :active,
    :focus {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    }
`;

const SoarCardNumberElement = styled(CardNumberElement)`
    ${ChangeCreditCardFieldCSS}
`;

const SoarCardExpiryElement = styled(CardExpiryElement)`
    ${ChangeCreditCardFieldCSS}
    width: 90px;
`;

const SoarCardCVCElement = styled(CardCVCElement)`
    ${ChangeCreditCardFieldCSS}
    width: 90px;
`;
