import { CommentDTO } from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

interface StoreAnnotation {
    annotationString: string;
    replyingToComment: boolean;
    updateAnnotations: boolean;
    resetAnnotations: boolean;
    commentId: string;
    editingComment: boolean;
    comment: CommentDTO;
}

const initialState = {
    annotationString: '',
    replyingToComment: false,
    updateAnnotations: false,
    resetAnnotations: false,
    editingComment: false,
    commentId: '',
    comment: {},
};

export default (state: StoreAnnotation = initialState, action: Action): StoreAnnotation => {
    switch (action.type) {
        case at.COMMENT_SET:
            return { ...state, comment: action.payload };
        case at.REPLYING_TO_COMMENT:
            return { ...state, replyingToComment: action.payload };
        case at.ANNOTATION_GET_ACTIVE_STRING:
            return { ...state, annotationString: action.payload };
        case at.ANNOTATION_UPDATE_ALL:
            return { ...state, updateAnnotations: action.payload };
        case at.ANNOTATION_COMMENT_ID:
            return { ...state, commentId: action.payload };
        case at.ANNOTATION_RESET_ALL:
            return { ...state, resetAnnotations: action.payload };
        case at.SOCIAL_EDITING_COMMENT:
            return { ...state, editingComment: action.payload };
        default:
            return state;
    }
};
