import PolygonPath from '../Polygon/polygon';
import Rectangle from '../Rectangle/rectangle';
import {
    MeasurementToolbarCheckbox,
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
} from './measurement-toolbar';

interface MeasurementToolbarPolygonAreaProps {
    poly: PolygonPath | Rectangle;
    onChangeShowArea: (showArea: boolean) => void;
}

const MeasurementToolbarPolygonArea = (props: MeasurementToolbarPolygonAreaProps) => {
    return (
        <MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionLabel htmlFor="poly-area-tick">Show total Area:</MeasurementToolbarOptionLabel>
            <MeasurementToolbarCheckbox
                id="poly-area-tick"
                type="checkbox"
                checked={props.poly.showArea}
                onClick={() => props.onChangeShowArea(!props.poly.showArea)}
            />
        </MeasurementToolbarOptionContainer>
    );
};

export default MeasurementToolbarPolygonArea;
