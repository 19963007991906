import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const BACKGROUND_GRADIENT_DELAY = 200; //ms
const BACKGROUND_GRADIENT_DURATION = 800; //ms

/**
 * Adds a subtle background 'glow' that starts dim and lights up from the top.
 * Gives the impression of a down light warming up.
 */
const BackgroundGradientAnimation = () => {
    const [backgroundAnimation, setBackgroundAnimation] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setBackgroundAnimation(true);
        }, BACKGROUND_GRADIENT_DELAY);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <React.Fragment>
            <BackgroundFill />
            <BackgroundGradient className={backgroundAnimation ? 'active' : ''} />
        </React.Fragment>
    );
};

export default BackgroundGradientAnimation;

const BackgroundFill = styled.div`
    background-color: #181a1a;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
`;

const BackgroundGradient = styled.div`
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: radial-gradient(circle at top right, rgba(42, 42, 42, 1) 0%, rgba(29, 29, 29, 1) 35%);
    transition: opacity ${BACKGROUND_GRADIENT_DURATION}ms;
    opacity: 0;
    z-index: -1;

    &.active {
        opacity: 1;
    }
`;
