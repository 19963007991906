import { LatLng } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-autosuggest';
import styled from 'styled-components';
import { AutocompleteDTO } from '../../api/model';
import Analytics from '../../lib/user-analytics';
import './autocomplete-search-box.css';
import {
    actionClearActiveSearch,
    actionUpdateHighlightedSearchPosition,
    actionClearHighlightedSearch,
} from '../../store/Search/actions';
import ApiAutocomplete from '../../api/api-autocomplete';
import GeoUtil from '../../lib/geo-util';
import { useWindowHeight } from '@react-hook/window-size';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';

/**
 * Don't use this component directly.  It is responsible for rendering the autocomplete results
 * Use `soar-autocomplete.tsx` instead
 */
interface AutocompleteSearchBoxProps {
    placeholder?: string;
    searchResults: AutocompleteDTO[];
    onSearchResultSelected: (result: AutocompleteDTO) => void;
    onCoordinateSelected: (coordinate: LatLng) => void;
    onFetchRequest: (search: string) => void;
    placeholderAddress?: string;
    isLoading: boolean;
}

const AutocompleteSearchBox = (props: AutocompleteSearchBoxProps) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [portalElement, setPortalElement] = useState<HTMLDivElement | null>(null);
    const searchBoxRef = useRef<HTMLDivElement | null>(null);

    const [deviceHeight, setDeviceHeight] = useState<number | undefined>(undefined);
    const height = useWindowHeight();

    useEffect(() => {
        if (!deviceHeight) {
            setDeviceHeight(window.innerHeight);
        }
    }, [deviceHeight]);

    useEffect(() => {
        if (props.placeholderAddress) {
            setValue(props.placeholderAddress);
        }
    }, [props.placeholderAddress]);

    useEffect(() => {
        if (!value) {
            dispatch(actionClearActiveSearch());
            dispatch(actionClearHighlightedSearch());
        }
    }, [value, dispatch]);

    useEffect(() => {
        if (searchBoxRef.current && portalElement) {
            const rect = searchBoxRef.current.getBoundingClientRect();
            const portalStyles = {
                position: 'absolute',
                top: `${rect.bottom}px`, // Position the portal just below the search box
                left: `${rect.left}px`,
                width: `${rect.width}px`, // Match the width of the search box
            };
            Object.assign(portalElement.style, portalStyles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBoxRef.current, portalElement, height]);

    useEffect(() => {
        // Create a div for the portal and append it to the body
        const div = document.createElement('div');
        document.body.appendChild(div);
        setPortalElement(div);

        return () => {
            // Clean up the div when the component unmounts
            document.body.removeChild(div);
        };
    }, []);

    const getCoordinateFromSearchTerm = (search: string): LatLng | null => {
        search = search.trim();
        let split;

        if (search.includes(',')) {
            split = search
                .replace(' ', '')
                .split(',')
                .filter((word) => word !== '');
        } else {
            split = search.split(' ').filter((word) => word !== '');
        }

        if (split.length === 2) {
            const lat = parseFloat(split[0]);
            const lng = parseFloat(split[1]);

            if (!(lat > -90 && lat < 90 && lng > -180 && lng < 180)) return null;

            if (lat && lat !== 0 && !isNaN(lat) && lng && lng !== 0 && !isNaN(lng)) {
                setValue(`${lat}, ${lng}`);
                return new LatLng(lat, lng);
            }
        }

        return null;
    };

    const clearHighlightedSearch = () => {
        dispatch(actionClearHighlightedSearch());
    };

    return (
        <SearchBox ref={searchBoxRef} className="autocomplete-search-box">
            <Autocomplete
                suggestions={props.searchResults}
                onSuggestionHighlighted={(suggestion) => {
                    if (suggestion && suggestion.suggestion) {
                        if (suggestion.suggestion.type === 'ADDRESS' && suggestion.suggestion.magicKey) {
                            ApiAutocomplete.autocompleteFromMagic(suggestion.suggestion.magicKey).then((res) => {
                                if (res.length > 0) {
                                    dispatch(
                                        actionUpdateHighlightedSearchPosition(GeoUtil.latLngFromWKT(res[0].geometryWKT))
                                    );
                                }
                            });
                        } else if (suggestion.suggestion.type === 'LISTING' && suggestion.suggestion.listingId) {
                            dispatch(actionClearHighlightedSearch());
                        } else {
                            clearHighlightedSearch();
                        }
                    } else {
                        clearHighlightedSearch();
                    }
                }}
                onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
                    clearHighlightedSearch();
                    props.onSearchResultSelected(suggestion);
                    Analytics.Event('Search Bar', `${suggestion.title}`);
                    if (suggestion && !suggestion.title && suggestion.type === 'LISTING' && suggestion.listingId) {
                        setValue(suggestion.listingId.toString());
                        Analytics.Event('Annotation Tool Event', 'Searched for ', suggestion.listingId);
                    } else {
                        setValue(suggestionValue);
                        Analytics.Event('Annotation Tool Event', 'Searched for ', suggestion);
                    }
                }}
                onSuggestionsFetchRequested={({ value }) => {
                    setValue(value);
                    props.onFetchRequest(value);
                }}
                onSuggestionsClearRequested={() => {
                    props.onFetchRequest('');
                    clearHighlightedSearch();
                }}
                getSuggestionValue={(suggestion) => suggestion.title || suggestion.listingId?.toString() || ''}
                renderSuggestion={(suggestion) => (
                    <SuggestionItem>
                        {suggestion.type === 'ADDRESS' ? (
                            <img
                                className="react-autosuggest-result-icon"
                                src="/assets/marker-icon-white-outline.svg"
                            />
                        ) : null}

                        {suggestion.type === 'LISTING' ? (
                            <img
                                className="react-autosuggest-result-icon"
                                src="/assets/floating-drawer-icons/maps-icon-black.svg"
                            />
                        ) : null}

                        {suggestion.type === 'USER' ? (
                            <img
                                className="react-autosuggest-result-icon"
                                src="/assets/avatars/default_avatar_black.png"
                            />
                        ) : null}

                        <span> {suggestion.title ? suggestion.title : suggestion.listingId}</span>
                    </SuggestionItem>
                )}
                renderSuggestionsContainer={({ containerProps, children }) => {
                    if (!portalElement) {
                        return null;
                    }

                    if (searchBoxRef.current) {
                        const rect = searchBoxRef.current.getBoundingClientRect();
                        portalElement.style.position = 'absolute';
                        portalElement.style.top = `${rect.bottom}px`;
                        portalElement.style.left = `${rect.left}px`;
                        portalElement.style.width = `${rect.width}px`;
                    }
                    return ReactDOM.createPortal(
                        <SuggestionContainer {...containerProps}>{children}</SuggestionContainer>,
                        portalElement
                    );
                }}
                renderInputComponent={(inputProps) => (
                    <div className="inputContainer">
                        <i className="fa fa-search react-autosuggest-search-icon" />
                        {/* @ts-ignore */}
                        <input value={value} {...inputProps} />
                        {props.isLoading ? <i className="fa fa-spinner fa-spin react-autosuggest-spinner" /> : null}

                        {!props.isLoading && value ? (
                            <i
                                className="fa fa-times react-autosuggest-close-search"
                                onClick={() => {
                                    setValue('');
                                    dispatch(actionClearActiveSearch());
                                    clearHighlightedSearch();
                                }}
                            />
                        ) : null}
                    </div>
                )}
                inputProps={{
                    placeholder: props.placeholder || 'Search for a location or place...',
                    value,
                    onMouseDown: () => {
                        Analytics.Event('Annotation Tool Event', 'Opened Search Tool');
                    },
                    onChange: (event, { newValue }) => setValue(newValue),
                    onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                            const coordinate = getCoordinateFromSearchTerm(value);
                            if (coordinate) {
                                Analytics.Event('Annotation Tool Event', 'Searched for ', coordinate);
                                Analytics.Event('Search Bar', `${coordinate}`);
                                props.onCoordinateSelected(coordinate);
                            }
                        }
                    },
                }}
            />
        </SearchBox>
    );
};

export default AutocompleteSearchBox;

const SearchBox = styled.div``;

const SuggestionContainer = styled.div`
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #eed926;
        border-radius: 4px;
    }

    .react-autosuggest__suggestion {
        background-color: transparent;
        font-size: 14px;
        padding: 5px;
        cursor: pointer;

        &:hover {
            background-color: #eed926;
        }
    }
`;

const SuggestionItem = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;

    span {
        color: white;

        &:hover {
            color: black;
        }
    }

    .react-autosuggest-result-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
`;
