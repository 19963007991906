import L from 'leaflet';

import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
} from '@react-leaflet/core';
import { ImageOverlayProps } from 'react-leaflet';
import LayersUtil from '../layers-util';
import Image from './image';
import { createEditElement } from './image-annotation-edit-element';

interface ImageAnnotationProps extends ImageOverlayProps {
    imageAnnotation: Image;
    isSelected: boolean;
    onUpdateImage: (image: Image) => void;
    isDisabled?: boolean;
}

const imageAnnotationOptions: L.ImageOverlayOptions = {
    interactive: true,
    className: 'image-annotation-overlay',
};

const createImageAnnotation = (props: ImageAnnotationProps, context: LeafletContextInterface) => {
    const pane = LayersUtil.getPaneId(context.map, props.imageAnnotation);
    const imageOverlay = new L.ImageOverlay(props.url, props.bounds, {
        ...imageAnnotationOptions,
        ...props.imageAnnotation,
        pane: pane,
    });

    const imageOverlayElement = createElementObject<L.ImageOverlay, Image>(imageOverlay, context);

    let imageOverlayOpacity;
    imageOverlayElement.instance.on('peekOn', () => {
        imageOverlayOpacity = imageOverlay.options.opacity;

        imageOverlayElement.instance.setOpacity(0.66);
    });

    imageOverlayElement.instance.on('peekOff', () => {
        imageOverlayElement.instance.setOpacity(imageOverlayOpacity);
    });

    imageOverlayElement.instance.on('add', () => {
        if (props.isSelected) {
            context.map.addLayer(editElement.instance);
        } else {
            context.map.removeLayer(editElement.instance);
        }
    });

    imageOverlayElement.instance.on('remove', () => {
        context.map.removeLayer(editElement.instance);
    });

    imageOverlayElement.instance.on('update', () => {
        const updatedImage = {
            ...props.imageAnnotation,
            bounds: imageOverlayElement.instance.getBounds(),
        };

        props.onUpdateImage(updatedImage);
    });

    const editElement = createEditElement(
        {
            bounds: imageOverlayElement.instance.getBounds(),
            context: context,
            imageOverlayElement: imageOverlayElement,
        },
        context
    );

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        imageOverlayElement.instance.options.interactive = false;
    } else {
        imageOverlayElement.instance.options.interactive = true;
    }

    return imageOverlayElement;
};

const updateImageAnnotationElement = (
    instance: L.ImageOverlay,
    props: ImageAnnotationProps,
    prevProps: ImageAnnotationProps
) => {
    if (props.opacity && props.opacity !== prevProps.opacity) {
        props.imageAnnotation.opacity = props.opacity;
        instance.setOpacity(props.opacity);
    }
};

const useImageAnnotationElement = createElementHook<L.ImageOverlay, ImageAnnotationProps, LeafletContextInterface>(
    createImageAnnotation,
    updateImageAnnotationElement
);

const useImageAnnotation = createLayerHook(useImageAnnotationElement);

const ImageAnnotation = createContainerComponent(useImageAnnotation);

export default ImageAnnotation;
