export const actionTypes = {
    SET_SEARCH_FOCUSED: 'MAP/SELECTION/SET_SEARCH_FOCUSED',
    SET_SELECTED_CONTINENT: 'MAP/SELECTION/SET_SELECTED_CONTINENT',
    SET_SELECTED_CATEGORY: 'MAP/SELECTION/SET_SELECTED_CATEGORY',
    SET_CLEAR_SELECTED_LISTING: 'MAP/SELECTION/SET_CLEAR_SELECTED_LISTING',

    FETCH_SEARCH_DRAW_LISTINGS: 'MAP/SELECTION/FETCH_SEARCH_DRAW_LISTINGS',
    SET_SELECTED_SEARCH_TERM: 'MAP/SELECTION/SET_SELECTED_SEARCH_TERM',
    SET_SELECTED_IS_SEARCH_ACTIVE: 'MAP/SELECTION/SET_SELECTED_IS_SEARCH_ACTIVE',
};
