import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fade } from 'reactstrap';
import styled from 'styled-components';
import Constants from '../../../constants';
import { getDateFromShareLink, getLayerFromShareLink } from '../../../lib/sentinel-service';
import UriHelper from '../../../lib/uri-helper';
import { actionBeginMobileSentinelSearch } from '../../../store/Map/Mobile/actions';
import {
    actionSentinelResetSelectedFeature,
    actionSentinelClearFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelFetchFeatures,
} from '../../../store/Map/Sentinel/actions';
import { SentinelFeature, SentinelMetadata } from '../../../store/Map/Sentinel/model';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../store/Map/Sentinel/selectors';
import SentinelFilter, { DateRange, last30Days } from '../../Drawer/Satellites/Sentinel/sentinel-filter';
import { StyledButton } from '../../Shared/styled-button';
import SatelliteListStyleCard from './satellite-list-style-card';
import { BottomSheetState } from '../MapSelection/bottom-sheet';
import { MobileSearchResultTitle } from '../Search/Results/mobile-search-results';
import MobileSatelliteLoading from './mobile-satellite-loading';
import MobileBottomSheetNoDataView from '../BottomSheet/mobile-bottom-sheet-no-content';
import { useMobileCanSearchForSatelliteImagery } from './use-mobile-can-search-for-sattelite-imagery';

const moment = require('moment');

interface MobileSatelliteBottomSheetContainerProps {
    bottomSheetState?: BottomSheetState;
    onCloseMap: (state: BottomSheetState) => void;
    isClosing: boolean;
}

const MobileSatelliteBottomSheetContainer = (props: MobileSatelliteBottomSheetContainerProps) => {
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const satelliteAOI = useSelector(selectSentinelSelectedAOI);
    const sentinelError = useSelector(selectSentinelError);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);

    const [visibleSentinelFeatures, setVisibleSentinelFeatures] = useState<SentinelFeature[]>([]);
    const [layer, setLayer] = useState<SentinelMetadata>(Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR);
    const [dateRange, setDateRange] = useState<DateRange>(last30Days);
    const [loadingMoreResults, setLoadingMoreResults] = useState<boolean>(false);

    const searchForSatelliteImagery = useMobileCanSearchForSatelliteImagery();

    const dispatch = useDispatch();

    const handleShowMore = () => {
        if (satelliteAOI) {
            setLoadingMoreResults(true);
            const newStartDate = moment(dateRange.startDate).subtract(1, 'months').toDate();
            dispatch(actionSentinelFetchFeatures(satelliteAOI, newStartDate, dateRange.endDate, layer));
            setDateRange({ ...dateRange, startDate: newStartDate });
        }
    };

    const handleLayerChange = (layer: SentinelMetadata) => {
        setVisibleSentinelFeatures([]);
        if (satelliteAOI) {
            dispatch(actionSentinelFetchFeatures(satelliteAOI, dateRange.startDate, dateRange.endDate, layer));
            setLayer(layer);
        }
    };

    const handleDateRangeChange = (dates: DateRange) => {
        setVisibleSentinelFeatures([]);
        if (satelliteAOI) {
            dispatch(actionSentinelFetchFeatures(satelliteAOI, dates.startDate, dates.endDate, layer));
            setDateRange({ ...dates });
        }
    };

    const invalidateSentinelResults = useCallback(() => {
        const handleCloseSentinelResults = () => {
            dispatch(actionSentinelResetSelectedFeature());
            dispatch(actionSentinelClearFeatures());
            dispatch(actionSentinelResetSelectedAOI());
            setVisibleSentinelFeatures([]);
        };

        if (props.isClosing) {
            handleCloseSentinelResults();
        }
    }, [props.isClosing, dispatch]);

    useEffect(() => {
        if (props.bottomSheetState !== BottomSheetState.open) {
            dispatch(actionSentinelClearFeatures());
            dispatch(actionSentinelResetSelectedAOI());
            setVisibleSentinelFeatures([]);
        }
    }, [dispatch, props.bottomSheetState]);

    useEffect(() => {
        if (sentinelError) {
            setLoadingMoreResults(false);
        }
    }, [sentinelError]);

    useEffect(() => {
        if (searchForSatelliteImagery && props.bottomSheetState === BottomSheetState.open) {
            dispatch(actionBeginMobileSentinelSearch());
        }
    }, [dispatch, props.bottomSheetState, searchForSatelliteImagery]);

    useEffect(() => {
        if (satelliteAOI) {
            dispatch(actionSentinelFetchFeatures(satelliteAOI, dateRange.startDate, dateRange.endDate, layer));
        }
        // Silences data and layer changes triggering request so we can append to the list of results and not replace
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satelliteAOI]);

    useEffect(() => {
        return () => {
            invalidateSentinelResults();
        };
    }, [invalidateSentinelResults]);

    useEffect(() => {
        if (selectedSentinelFeature) {
            if (UriHelper.tryGetParam('time')) {
                setDateRange(getDateFromShareLink(selectedSentinelFeature.date));
            }
            if (UriHelper.tryGetParam('layerKey')) {
                setLayer(getLayerFromShareLink(selectedSentinelFeature.layer));
            }
        }
    }, [selectedSentinelFeature]);

    useEffect(() => {
        if (sentinelFeatures?.length) {
            const temporarySentinelFeatureArray = [...visibleSentinelFeatures, ...sentinelFeatures];
            const newSentinelVisibleFeatures = temporarySentinelFeatureArray.filter(
                (feature, index, array) =>
                    array.findIndex((f) => f.date === feature.date && f.previewUrl === feature.previewUrl) === index
            );
            setVisibleSentinelFeatures(newSentinelVisibleFeatures);
            setLoadingMoreResults(false);
        }
        // Disables visibleSentinelFeatures or we have an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentinelFeatures]);

    if (!searchForSatelliteImagery) {
        return (
            <ButtonContainer>
                <Button disabled={!searchForSatelliteImagery} onClick={() => props.onCloseMap(BottomSheetState.open)}>
                    Zoom in to search
                </Button>
            </ButtonContainer>
        );
    }

    if (props.bottomSheetState === BottomSheetState.mid || props.bottomSheetState === BottomSheetState.close) {
        return (
            <ButtonContainer>
                <Button disabled={!searchForSatelliteImagery} onClick={() => props.onCloseMap(BottomSheetState.open)}>
                    Search satellite imagery
                </Button>
            </ButtonContainer>
        );
    }

    if (sentinelFeaturesLoading && !visibleSentinelFeatures.length) {
        return <MobileSatelliteLoading />;
    }

    if (
        !sentinelFeaturesLoading &&
        !visibleSentinelFeatures.length &&
        props.bottomSheetState !== BottomSheetState.transitioning
    ) {
        return (
            <Fade in={!visibleSentinelFeatures.length ? true : false}>
                <MobileBottomSheetNoDataView currentBottomSheetState={props.bottomSheetState} />;
            </Fade>
        );
    }

    return (
        <Fade in={visibleSentinelFeatures.length ? true : false}>
            <MobileSearchFade isVisible={visibleSentinelFeatures.length ? true : false}>
                <MobileSearchResultTitle>
                    <MobileSearchTitleIcon src="/assets/search-content/emoji-current-events.svg" />
                    Explore satellite images <span>in this area</span>
                </MobileSearchResultTitle>
                <SentinelFilter
                    onSelectLayer={(layer) => handleLayerChange(layer)}
                    onSelectDateRange={(dateRange) => handleDateRangeChange(dateRange)}
                    layer={layer}
                    dateRange={dateRange}
                />
                <React.Fragment>
                    {visibleSentinelFeatures?.map((t, idx) => {
                        return (
                            <SatelliteListStyleCard
                                key={t.id}
                                feature={t}
                                index={idx}
                                onCloseMap={() => props.onCloseMap(BottomSheetState.close)}
                            />
                        );
                    })}
                </React.Fragment>
                {loadingMoreResults ? (
                    <MobileSatelliteLoading />
                ) : (
                    <LoadMoreButton
                        onClick={() => {
                            handleShowMore();
                        }}
                    >
                        Load More Satellite Imagery
                    </LoadMoreButton>
                )}
            </MobileSearchFade>
        </Fade>
    );
};

export default MobileSatelliteBottomSheetContainer;

interface MobileFadeContainerProps {
    isVisible: boolean;
}

const MobileSearchFade = styled.div<MobileFadeContainerProps>`
    display: ${(props) => (props.isVisible ? 'initial' : 'none')};
`;

const LoadMoreButton = styled.button`
    margin: 0px auto;
    width: 100%;
    align-items: normal;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    box-sizing: content-box;
    color: #eed309;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 10px 0px;
    text-decoration: underline;
    pointer-events: all;

    &:focus {
        outline: none;
        border: none;
    }
`;

const ButtonContainer = styled.div`
    display: 'block';
    height: 100px;
    margin-top: 24px;
    span {
        font-weight: bolder;
    }
`;

const Button = styled(StyledButton)`
    margin: 0 auto;
    display: block;
    pointer-events: auto;
`;

const MobileSearchTitleIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: -4px 8px 0 8px;
`;
