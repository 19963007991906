import { AppState } from '../../root-reducer';

export const selectDrawProjects = (state: AppState) => state.drawProjectDomain.drawProjects;
export const selectDrawProjectsIsFetched = (state: AppState) => state.drawProjectDomain.isFetchedDrawProjects;

export const selectSharedDrawProjects = (state: AppState) => state.drawProjectDomain.sharedDrawProjects;
export const selectSharedDrawProjectsIsFetched = (state: AppState) =>
    state.drawProjectDomain.isFetchedSharedDrawProjects;

export const selectIsUpdatingDrawProject = (state: AppState) => state.drawProjectDomain.isUpdatingDrawProject;
export const selectIsDeletingDrawProject = (state: AppState) => state.drawProjectDomain.isDeletingDrawProject;
