import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface MenuLinkProps {
    handleMenuItemClick: () => void;
    selected: boolean;
    iconLight: string;
    iconDark: string;
    label: string;
}

const MenuLink = (props: MenuLinkProps) => {
    const { iconLight, iconDark, label, selected, handleMenuItemClick } = props;
    const [changeIcon, setChangeIcon] = useState<boolean>(false);

    useEffect(() => {
        setChangeIcon(selected);
    }, [selected]);

    return (
        <ProfileSideBarLinkItemContainer
            onClick={() => handleMenuItemClick()}
            onMouseEnter={() => setChangeIcon(true)}
            onMouseLeave={() => !selected && setChangeIcon(false)}
            selected={selected}
        >
            <ProfileSidebarLinkItemIcon src={changeIcon ? iconDark : iconLight} />
            <ProfileSideBarLinkItemLabel>{label}</ProfileSideBarLinkItemLabel>
        </ProfileSideBarLinkItemContainer>
    );
};

export default MenuLink;

interface Selected {
    selected: boolean;
}

const ProfileSidebarLinkItemIcon = styled.img`
    margin-left: 20px;
    margin-right: 20px;
    width: 25px;
    height: 25px;
`;

const ProfileSideBarLinkItemLabel = styled.div`
    color: inherit;
    font-size: 16px;
`;

const ProfileSideBarLinkItemContainer = styled.div<Selected>`
    color: white;
    flex-basis: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 15px 0px;

    ${(props) => props.selected && `background-color: #eed926; color: black;`}

    @media only screen and (max-width: 600px) {
        background-color: transparent;
        color: white;
        box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
            0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
    }

    &:hover {
        background-color: #eed926;

        @media only screen and (max-width: 600px) {
            background-color: transparent;
        }
    }

    &:hover ${ProfileSideBarLinkItemLabel} {
        color: black;

        @media only screen and (max-width: 600px) {
            color: white;
        }
    }

    &:last-child {
        border-radius: 0px 0px 6px 6px;
    }
`;
