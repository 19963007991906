import styled from 'styled-components';
import { Basemap } from '../../../../../store/Map/Basemap/model';
import { useBasemap } from '../../../../../store/Map/Basemap/use-basemap';
import Analytics from '../../../../../lib/user-analytics';

import ImageSelect, {
    ImageSelectOptionType,
    ImageSelectOptionLabel,
    ImageSelectPreviewSize,
} from '../../../../Shared/image-select';

interface StoryBuilderBaseMapControlProps {
    basemap: string;
}

const BaseMapPreviewImages = {
    [Basemap.OPEN_ELEVATION]: '/assets/basemap/preview/open-elevation.png',
    [Basemap.GOOGLE_SATELLITE]: '/assets/basemap/preview/google-satellite.png',
    [Basemap.GOOGLE_HYBRID]: '/assets/basemap/preview/google-hybrid.png',
    [Basemap.GOOGLE_STREET]: '/assets/basemap/preview/google-street.png',
    [Basemap.OPEN_STREETMAPS]: '/assets/basemap/preview/open-streetmaps.png',
    [Basemap.DARK_BLANK]: '/assets/basemap/preview/dark-blank.png',
    [Basemap.WHITE_BLANK]: '/assets/basemap/preview/white-blank.png',
    [Basemap.OSM_DEFAULT_LIGHT]: '/assets/basemap/preview/osm-default-light.png',
    [Basemap.OSM_DEFAULT_DARK]: '/assets/basemap/preview/osm-default-dark.png',
};

const StoryBuilderBaseMapControl = ({ basemap }: StoryBuilderBaseMapControlProps) => {
    const { setBasemap } = useBasemap();

    return (
        <BaseMapImageSelect
            onChange={(basemap) => {
                Analytics.Event('Stoary - Builder', `Clicked to change basemap when editing page ${basemap.value}`);
                setBasemap(basemap.value as Basemap);
            }}
            value={basemap}
            options={Object.values(Basemap).map((basemap) => {
                return { value: basemap as string, image: BaseMapPreviewImages[basemap] } as ImageSelectOptionType;
            })}
            previewSize={ImageSelectPreviewSize.SMALL}
        />
    );
};

export default StoryBuilderBaseMapControl;

const BaseMapImageSelect = styled(ImageSelect)`
    background: transparent !important;
    flex: 1;

    .image-select__control {
        background: transparent !important;
        border-color: rgba(255, 255, 255, 0.3) !important;

        &.image-select__control--is-focused {
            border-color: white !important;
            box-shadow: none !important;
        }

        ${ImageSelectOptionLabel} {
            color: #fff !important;
        }

        .image-select__indicators {
            cursor: pointer;
        }
    }

    .image-select__menu {
        background: rgba(33, 33, 33, 0.98) !important;
        border: 1px solid rgba(0, 0, 0, 0.3);

        .image-select__menu-list {
            &::-webkit-scrollbar {
                width: 12px;
                background-color: #343a40;
            }

            &::-webkit-scrollbar-track {
                background-color: #343a40;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #eed926;
                border-radius: 20px;
                border: 1px solid #eed926;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            .image-select__option {
                ${ImageSelectOptionLabel} {
                    color: #fff !important;
                }

                &.image-select__option--is-focused {
                    background-color: rgba(255, 255, 255, 0.3) !important;
                }

                &.image-select__option--is-selected {
                    background-color: rgba(255, 255, 255, 0.6) !important;
                }
            }
        }
    }
`;
