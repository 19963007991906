import Constants from '../constants';
import { LatLng, Polyline, LatLngBounds } from 'leaflet';
import { SentinelImageDimension } from '../api/model';
import GeoUtil from './geo-util';
import axios from 'axios';

const satellite = require('satellite.js');
const moment = require('moment');

export enum Satellite {
    Skymap501,
    Skymap502,
    Skymap503,
    Skymap504,
    Landsat8,
    Sentinel1A,
    Sentinel2A,
    Sentinel3A,
    Sentinel1B,
    Sentinel2B,
    Sentinel5P,
    Sentinel3B,
    Himawari7,
    Himawari8,
    Himawari9,
    NOAA19,
    NOAA20,
    ISS,
    Pathfinder1,
    Global1,
    Global2,
    Global3,
    Global4,
    Global7,
    Global8,
}

interface PositionAndHeight {
    position: LatLng;
    height: string;
    velocity: number;
}

export default class SatelliteUtil {
    public static tleForSatellite(satelliteTracker: Satellite): string[] {
        switch (satelliteTracker) {
            case Satellite.Skymap501:
                return [Constants.SATELLITE_TLE.SKYMAP50_1_01_TLE1, Constants.SATELLITE_TLE.SKYMAP50_1_01_TLE2];
            case Satellite.Skymap502:
                return [Constants.SATELLITE_TLE.SKYMAP50_1_02_TLE1, Constants.SATELLITE_TLE.SKYMAP50_1_02_TLE2];
            case Satellite.Skymap503:
                return [Constants.SATELLITE_TLE.SKYMAP50_1_03_TLE1, Constants.SATELLITE_TLE.SKYMAP50_1_03_TLE2];
            case Satellite.Skymap504:
                return [Constants.SATELLITE_TLE.SKYMAP50_1_04_TLE1, Constants.SATELLITE_TLE.SKYMAP50_1_04_TLE2];
            case Satellite.Landsat8:
                return [Constants.SATELLITE_TLE.LANDSAT8_TLE1, Constants.SATELLITE_TLE.LANDSAT8_TLE2];
            case Satellite.Sentinel1A:
                return [Constants.SATELLITE_TLE.SENTINEL_1A_TLE1, Constants.SATELLITE_TLE.SENTINEL_1A_TLE2];
            case Satellite.Sentinel1B:
                return [Constants.SATELLITE_TLE.SENTINEL_1B_TLE1, Constants.SATELLITE_TLE.SETNINEL_1B_TLE2];
            case Satellite.Sentinel2A:
                return [Constants.SATELLITE_TLE.SENTINEL_2A_TLE1, Constants.SATELLITE_TLE.SENTINEL_2A_TLE2];
            case Satellite.Sentinel2B:
                return [Constants.SATELLITE_TLE.SENTINEL_2B_TLE1, Constants.SATELLITE_TLE.SENTINEL_2B_TLE2];
            case Satellite.Sentinel3A:
                return [Constants.SATELLITE_TLE.SENTINEL_3A_TLE2, Constants.SATELLITE_TLE.SENTINEL_3A_TLE2];
            case Satellite.Sentinel5P:
                return [Constants.SATELLITE_TLE.SENTINEL_5P_TLE1, Constants.SATELLITE_TLE.SENTINEL_5P_TLE2];
            case Satellite.Himawari7:
                return [Constants.SATELLITE_TLE.HIMAWARI_7_TLE1, Constants.SATELLITE_TLE.HIMAWARI_7_TLE2];
            case Satellite.Himawari8:
                return [Constants.SATELLITE_TLE.HIMAWARI_8_TLE1, Constants.SATELLITE_TLE.HIMAWARI_8_TLE2];
            case Satellite.Himawari9:
                return [Constants.SATELLITE_TLE.HIMAWARI_9_TLE1, Constants.SATELLITE_TLE.HIMAWARI_9_TLE2];
            case Satellite.NOAA19:
                return [Constants.SATELLITE_TLE.NOAA_19_TLE1, Constants.SATELLITE_TLE.NOAA_19_TLE2];
            case Satellite.NOAA20:
                return [Constants.SATELLITE_TLE.NOAA_20_TLE1, Constants.SATELLITE_TLE.NOAA_20_TLE2];
            case Satellite.ISS:
                return [Constants.SATELLITE_TLE.ISS_TLE1, Constants.SATELLITE_TLE.ISS_TLE2];
            case Satellite.Global1:
                return [Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_1_TLE1, Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_1_TLE2];
            case Satellite.Global2:
                return [Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_2_TLE1, Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_2_TLE2];
            case Satellite.Global3:
                return [Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_3_TLE1, Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_3_TLE2];
            case Satellite.Global4:
                return [Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_4_TLE1, Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_4_TLE2];
            case Satellite.Global7:
                return [Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_7_TLE1, Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_7_TLE2];
            case Satellite.Global8:
                return [Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_8_TLE1, Constants.SATELLITE_TLE.BLACKSKY_GLOBAL_8_TLE2];
            case Satellite.Pathfinder1:
                return [
                    Constants.SATELLITE_TLE.BLACKSKY_PATHFINDER_1_TLE1,
                    Constants.SATELLITE_TLE.BLACKSKY_PATHFINDER_1_TLE2,
                ];

            default:
                throw new Error('Satellite not specified');
        }
    }

    public static tooltipHTMLForSatellite(satelliteTracker: Satellite, altitude: string, velocity: number): string {
        const altitudeFormatted = Number(altitude).toFixed(2);
        const velocityFormatted = velocity.toFixed(2);

        switch (satelliteTracker) {
            case Satellite.Skymap501:
                return this.tooltipBuilder('SkyMap50 1', 'China Swei', altitudeFormatted, velocityFormatted);
            case Satellite.Skymap502:
                return this.tooltipBuilder('SkyMap50 2', 'China Swei', altitudeFormatted, velocityFormatted);
            case Satellite.Skymap503:
                return this.tooltipBuilder('SkyMap50 3', 'China Swei', altitudeFormatted, velocityFormatted);
            case Satellite.Skymap504:
                return this.tooltipBuilder('SkyMap50 4', 'China Swei', altitudeFormatted, velocityFormatted);
            case Satellite.Sentinel1A:
                return this.tooltipBuilder('Sentinel 1A', 'Synergise', altitudeFormatted, velocityFormatted);
            case Satellite.Sentinel1B:
                return this.tooltipBuilder('Sentinel 1B', 'China Swei', altitudeFormatted, velocityFormatted);
            case Satellite.Sentinel2A:
                return this.tooltipBuilder('Sentinel 2A', 'Synergise', altitudeFormatted, velocityFormatted);
            case Satellite.Sentinel2B:
                return this.tooltipBuilder('Sentinel 2B', 'Synergise', altitudeFormatted, velocityFormatted);
            case Satellite.Sentinel3A:
                return this.tooltipBuilder('Sentinel 3A', 'Synergise', altitudeFormatted, velocityFormatted);
            case Satellite.Sentinel3B:
                return this.tooltipBuilder('Sentinel 3B', 'Synergise', altitudeFormatted, velocityFormatted);
            case Satellite.Sentinel5P:
                return this.tooltipBuilder('Sentinel 5P', 'Synergise', altitudeFormatted, velocityFormatted);
            case Satellite.Landsat8:
                return this.tooltipBuilder('Landsat8', 'NASA', altitudeFormatted, velocityFormatted);
            case Satellite.ISS:
                return this.tooltipBuilder('International Space Station', 'NASA', altitudeFormatted, velocityFormatted);
            case Satellite.Pathfinder1:
                return this.tooltipBuilder('Pathfinder 1', 'Blacksky', altitudeFormatted, velocityFormatted);
            case Satellite.Global1:
                return this.tooltipBuilder('Global 1', 'Blacksky', altitudeFormatted, velocityFormatted);
            case Satellite.Global2:
                return this.tooltipBuilder('Global 2', 'Blacksky', altitudeFormatted, velocityFormatted);
            case Satellite.Global3:
                return this.tooltipBuilder('Global 3', 'Blacksky', altitudeFormatted, velocityFormatted);
            case Satellite.Global4:
                return this.tooltipBuilder('Global 4', 'Blacksky', altitudeFormatted, velocityFormatted);
            case Satellite.Global7:
                return this.tooltipBuilder('Global 7', 'Blacksky', altitudeFormatted, velocityFormatted);
            case Satellite.Global8:
                return this.tooltipBuilder('Global 8', 'Blacksky', altitudeFormatted, velocityFormatted);

            default:
                return '';
        }
    }

    // TODO organisation is not used and can be removed from the builder in 'tooltipHTMLForSatellite'
    private static tooltipBuilder(
        name: string,
        organisation: string,
        altitudeFormatted: string,
        velocityFormatted: string
    ): string {
        return `
            <p>${name}</p>
            <p>Height: ${altitudeFormatted}km</p>
            <p>Velocity: ${velocityFormatted}km/sec</p>
        `;
    }

    public static positionAndHeightAtDate(date: Date, satelliteTracker: Satellite): PositionAndHeight {
        const tle = SatelliteUtil.tleForSatellite(satelliteTracker);
        const satrec = satellite.twoline2satrec(tle[0], tle[1]);
        const positionAndVelocity = satellite.propagate(satrec, date);
        const vec = positionAndVelocity.velocity;
        const velocity = Math.sqrt(vec.x ** 2 + vec.y ** 2 + vec.z ** 2);
        const gmst = satellite.gstime(date);
        const position = satellite.eciToGeodetic(positionAndVelocity.position, gmst);
        const height = position.height;
        const lat = (position.latitude * 180) / Math.PI;
        const lng = (position.longitude * 180) / Math.PI;
        if (isNaN(lat) || isNaN(lng)) return { position: new LatLng(0, 0), height: '0', velocity: 0 };
        return { position: new LatLng(lat, lng), height: height, velocity: velocity };
    }

    public static positionsForLastNHours(
        hours: number,
        satelliteTracker: Satellite,
        minutesBetweenPositions: number
    ): LatLng[] {
        const latlngs: LatLng[] = [];

        const now = moment(new Date());
        const endDate = moment(new Date()).subtract(hours, 'hours');
        let date = now;
        while (date.isAfter(endDate)) {
            const latLng = SatelliteUtil.positionAndHeightAtDate(date.toDate(), satelliteTracker).position;
            latlngs.push(latLng);

            if (latLng.lng > 175.0 || latLng.lng < -175.0) {
                // Increase resolution close to anti-meridian so it doesn't chop off too early
                date = date.subtract(2, 'seconds');
            } else if (latLng.lng > 160.0 || latLng.lng < -160.0) {
                date = date.subtract(10, 'seconds');
            } else if (latLng.lat > 80 || latLng.lat < -80) {
                // Increase resolution around poles to create a more pleasing curve for polar orbits
                date = date.subtract(30, 'seconds');
            } else {
                date = date.subtract(minutesBetweenPositions, 'minutes');
            }
        }
        return latlngs;
    }

    public static doLatLngsCrossAntiMeridian(first: LatLng, second: LatLng): boolean {
        if (Math.sign(first.lng) === -1 && Math.sign(second.lng) === 1 && first.lng < -140.0) {
            return true;
        }

        if (Math.sign(first.lng) === 1 && Math.sign(second.lng) === -1 && first.lng > 140.0) {
            return true;
        }

        return false;
    }

    public static splitLatLngsAcrossAntiMeridian(latlngs: LatLng[]): [LatLng[]] {
        const polylines: [LatLng[]] = [[]];
        let accumulatorIndex = 0;
        let accumulator: LatLng[] = [];

        latlngs.forEach((latlng, index) => {
            if (index === 0) {
                accumulator.push(latlng);
            } else if (index === latlngs.length - 1) {
                polylines[accumulatorIndex] = accumulator;
            } else {
                if (SatelliteUtil.doLatLngsCrossAntiMeridian(latlng, latlngs[index - 1])) {
                    polylines[accumulatorIndex] = accumulator;
                    accumulatorIndex = accumulatorIndex + 1;
                    accumulator = [];
                } else {
                    accumulator.push(latlng);
                }
            }
        });

        return polylines;
    }

    public static splitLatLngsIntoGroupsTimeSeriesPolylines(latlngs: [LatLng[]], opacityDecay = 0.02): Polyline[] {
        const numberOfSlices = 10;
        const sliceSize = latlngs.flatMap((x) => x).length / numberOfSlices;
        opacityDecay = 0.1;

        let opacity = 1.0;
        const polylines: Polyline[] = [];

        latlngs.forEach((latlngSet) => {
            let lastUsedLatLng: LatLng | undefined = undefined;
            while (latlngSet.length > 0) {
                let currentSlice;
                if (
                    lastUsedLatLng &&
                    (lastUsedLatLng.lng > 170 ||
                        lastUsedLatLng.lng < 170 ||
                        lastUsedLatLng.lat > 80 ||
                        lastUsedLatLng.lat < -80)
                ) {
                    currentSlice = latlngSet.splice(0, sliceSize * 4);
                } else {
                    currentSlice = latlngSet.splice(0, sliceSize);
                }
                if (lastUsedLatLng) currentSlice.unshift(lastUsedLatLng);

                const polyline = new Polyline(currentSlice, { opacity: opacity });

                opacity = opacity - opacityDecay;
                polylines.push(polyline);
                lastUsedLatLng = currentSlice[currentSlice.length - 1];
            }
        });
        return polylines;
    }

    public static landsatQualityEvaluation(bbox: LatLngBounds): SentinelImageDimension[] {
        const MAX_SENTINEL_PIXELS = 2500;

        const widthMeters = GeoUtil.widthKilometers(bbox) * 1000;
        const heightMeters = GeoUtil.heightKilometers(bbox) * 1000;

        const imageWidthForLandsat = Math.round(widthMeters / Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8.resolution);
        const imageHeightForLandsat = Math.round(heightMeters / Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8.resolution);

        const isValidWidthForLandsat = imageWidthForLandsat < MAX_SENTINEL_PIXELS;
        const isValidHeightForLandsat = imageHeightForLandsat < MAX_SENTINEL_PIXELS;

        const imageForLandsatIsAvailable = isValidWidthForLandsat && isValidHeightForLandsat;

        const landsatImage: SentinelImageDimension = {
            widthPixels: imageWidthForLandsat,
            heightPixels: imageHeightForLandsat,
            resolution: Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8.resolution,
            isAvailable: imageForLandsatIsAvailable,
            maxPixels: MAX_SENTINEL_PIXELS,
            quality: 'Best',
        };
        return [landsatImage];
    }

    public static sentinelQualityEvaluation(bbox: LatLngBounds): SentinelImageDimension[] {
        const MAX_SENTINEL_PIXELS = 2500;

        const widthMeters = GeoUtil.widthKilometers(bbox) * 1000;
        const heightMeters = GeoUtil.heightKilometers(bbox) * 1000;

        const imageWidthFor10m = Math.round(widthMeters / 10);
        const imageHeightFor10m = Math.round(heightMeters / 10);

        const isValidWidth10m = imageWidthFor10m < MAX_SENTINEL_PIXELS;
        const isValidHeight10m = imageHeightFor10m < MAX_SENTINEL_PIXELS;

        const image10mIsAvailable = isValidWidth10m && isValidHeight10m;

        const sentinel10m: SentinelImageDimension = {
            widthPixels: imageWidthFor10m,
            heightPixels: imageHeightFor10m,
            resolution: 10,
            isAvailable: image10mIsAvailable,
            maxPixels: MAX_SENTINEL_PIXELS,
            quality: 'Best',
        };

        const imageWidthFor20m = Math.round(widthMeters / 20);
        const imageHeightFor20m = Math.round(heightMeters / 20);

        const isValidWidth20m = imageWidthFor20m < MAX_SENTINEL_PIXELS;
        const isValidHeight20m = imageHeightFor20m < MAX_SENTINEL_PIXELS;

        const image20mIsAvailable = isValidWidth20m && isValidHeight20m;

        const sentinel20m: SentinelImageDimension = {
            widthPixels: imageWidthFor20m,
            heightPixels: imageHeightFor20m,
            resolution: 20,
            isAvailable: image20mIsAvailable,
            maxPixels: MAX_SENTINEL_PIXELS,
            quality: 'Good',
        };

        const imageWidthFor50m = Math.round(widthMeters / 50);
        const imageHeightFor50m = Math.round(heightMeters / 50);

        const isValidWidth50m = imageWidthFor50m < MAX_SENTINEL_PIXELS;
        const isValidHeight50m = imageHeightFor50m < MAX_SENTINEL_PIXELS;

        const image50mIsAvailable = isValidWidth50m && isValidHeight50m;

        const sentinel50m: SentinelImageDimension = {
            widthPixels: imageWidthFor50m,
            heightPixels: imageHeightFor50m,
            resolution: 50,
            isAvailable: image50mIsAvailable,
            maxPixels: MAX_SENTINEL_PIXELS,
            quality: 'Low',
        };

        const imageWidthFor100m = Math.round(widthMeters / 100);
        const imageHeightFor100m = Math.round(heightMeters / 100);

        const isValidWidth100m = imageWidthFor100m < MAX_SENTINEL_PIXELS;
        const isValidHeight100m = imageHeightFor100m < MAX_SENTINEL_PIXELS;

        const image100mIsAvailable = isValidWidth100m && isValidHeight100m;

        const sentinel100m: SentinelImageDimension = {
            widthPixels: imageWidthFor100m,
            heightPixels: imageHeightFor100m,
            resolution: 100,
            isAvailable: image100mIsAvailable,
            maxPixels: MAX_SENTINEL_PIXELS,
            quality: 'Poor',
        };

        return [sentinel10m, sentinel20m, sentinel50m, sentinel100m];
    }

    public static sendSentinelSlackMessage = async (errorTitle: string, errorMessage: string, errorURL: string) => {
        const SLACK_CHANNEL_TEST = 'T02576M14DV/B02720YKANR/GJxonRiumgT3nPETehiD4j9S';
        const SLACK_CHANNEL_PROD = 'T02576M14DV/B027MRT9PND/uW9XAGeRa4HiERkUCEaRDLZd';

        const isProduction =
            process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'prod';
        const SLACK_CHANNEL = isProduction ? SLACK_CHANNEL_PROD : SLACK_CHANNEL_TEST;

        const url = `https://hooks.slack.com/services/${SLACK_CHANNEL}`;
        const date = new Date();

        const slackError = {
            blocks: [
                {
                    type: 'section',
                    text: {
                        type: 'plain_text',
                        text: errorTitle,
                    },
                },
                {
                    type: 'section',
                    text: {
                        type: 'mrkdwn',
                        text: errorMessage,
                    },
                },
                {
                    type: 'section',
                    text: {
                        type: 'plain_text',
                        text: `DATE: ${date.toString()}`,
                    },
                },
                {
                    type: 'section',
                    text: {
                        type: 'plain_text',
                        text: errorURL,
                    },
                },
            ],
        };

        await axios.post(url, JSON.stringify(slackError), {
            withCredentials: false,
            transformRequest: [
                (data, headers) => {
                    delete headers.post['Content-Type'];
                    return data;
                },
            ],
        });
    };

    public static sentinelErrorMessageResponse = (sentinelResult: SentinelImageDimension) => {
        const imageTooWide = sentinelResult.heightPixels < sentinelResult.maxPixels;
        const imageTooTall = sentinelResult.widthPixels < sentinelResult.maxPixels;

        if (imageTooTall) {
            return 'This AOI is invalid, please reduce the height';
        } else if (imageTooWide) {
            return 'This AOI is invalid, please reduce the width';
        } else {
            return 'This AOI is too large, please reduce the height and width';
        }
    };
}
