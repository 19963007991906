import styled from 'styled-components';
import SoarModal, { StyledModalBody } from '../../../../Shared/soar-modal';
import { StyledButton } from '../../../../Shared/styled-button';
import { shareProjectToClipboard } from '../../Share/share-util';
import Analytics from '../../../../../lib/user-analytics';

interface StoryBuilderShareLinkModalProps {
    isOpen: boolean;
    toggle: () => void;
    savedProjectId: number;
}

const StoryBuilderShareLinkModal = (props: StoryBuilderShareLinkModalProps) => {
    const handleShareProject = () => {
        Analytics.Event('Stoary - Builder', `Clicked to share stoary after save - ${props.savedProjectId}`);
        shareProjectToClipboard(props.savedProjectId);
        props.toggle();
    };

    const handleViewProject = () => {
        Analytics.Event('Stoary - Builder', `Clicked to view stoary after save - ${props.savedProjectId}`);
        window.open(`/draw/${props.savedProjectId}`, '_blank');
        props.toggle();
    };

    return (
        <SoarModal title="Stoary saved!" isOpen={props.isOpen} toggle={props.toggle} width="500px">
            <ProjectUpdateModalBody>
                <ProjectModalButtons>
                    <ShareButton onClick={handleViewProject} title="View Stoary in a new tab">
                        <ViewProjectImage className="fa fa-eye" />
                        View this Stoary
                    </ShareButton>
                    <ShareButton onClick={handleShareProject} title="Copy Stoary link">
                        <ShareImage src="/assets/annotations/copy-share.svg" />
                        Copy Stoary link
                    </ShareButton>
                </ProjectModalButtons>
            </ProjectUpdateModalBody>
        </SoarModal>
    );
};

export default StoryBuilderShareLinkModal;

const ProjectUpdateModalBody = styled(StyledModalBody)`
    display: flex;
    flex-direction: column;
    padding-right: 0px !important;
`;

const ProjectModalButtons = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const ShareButton = styled(StyledButton)`
    align-self: center;
    margin: 6px 0;

    :first-child {
        margin-right: 10px;
    }
`;

const ViewProjectImage = styled.i`
    margin: -2px 8px 0px 0;
    cursor: pointer;
`;

const ShareImage = styled.img`
    width: 20px;
    height: 20px;
    margin: -2px 8px 0px 0;
    cursor: pointer;
`;
