import {
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
    MeasurementToolbarSelect,
    MeasurementToolbarSelectOption,
} from './measurement-toolbar';

export type MeasurementLabelColor = 'white' | 'black' | 'yellow' | 'red' | 'rgb(34,177,76)' | 'rgb(12,136,248)';
export const defaultLabelColor: MeasurementLabelColor = 'red';
interface MeasurementToolbarLabelColorProps {
    labelColor: MeasurementLabelColor;
    onChangeLabelColor: (color: MeasurementLabelColor) => void;
}

const MeasurementToolbarLabelColor = (props: MeasurementToolbarLabelColorProps) => {
    return (
        <MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionLabel>Label Color:</MeasurementToolbarOptionLabel>
            <MeasurementToolbarSelect
                onChange={(e) => {
                    props.onChangeLabelColor(e.target.value as MeasurementLabelColor);
                }}
                value={props.labelColor}
            >
                <MeasurementToolbarSelectOption value="white">White</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="black">Black</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="yellow">Yellow</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="red">Red</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgb(34,177,76)">Green</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgb(12,136,248)">Blue</MeasurementToolbarSelectOption>
            </MeasurementToolbarSelect>
        </MeasurementToolbarOptionContainer>
    );
};

export default MeasurementToolbarLabelColor;
