import { useSelector, useDispatch } from 'react-redux';
import {
    selectActiveAllMap,
    selectActiveAllMapIsFetching,
    selectActiveAllMapIsLoading,
} from '../../../../store/Map/SuperMap/selectors';
import { actionSetReplyingToComment, actionSetEditingComment } from '../../../../store/SocialMapping/actions';
import ActiveMap from '../../Maps/ActiveMap/active-map';
import SideDrawerLoader from '../Shared/side-drawer-loader';
import MapSearchDraw from './map-search-draw';

const SideDrawerListings = () => {
    const activeMap = useSelector(selectActiveAllMap);

    const isFetching = useSelector(selectActiveAllMapIsFetching);
    const isLoading = useSelector(selectActiveAllMapIsLoading);

    const dispatch = useDispatch();

    const reset = () => {
        dispatch(actionSetReplyingToComment(false));
        dispatch(actionSetEditingComment(false));
    };

    if (!activeMap && (isFetching || isLoading)) {
        return <SideDrawerLoader />;
    }

    if (activeMap) {
        reset();
        return <ActiveMap listing={activeMap} />;
    }

    return <MapSearchDraw />;
};

export default SideDrawerListings;
