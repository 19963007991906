import { LatLng } from 'leaflet';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GeoUtil from '../../../lib/geo-util';
import {
    selectDroneImageDistortionCorners,
    selectDroneImageDistortionTool,
    selectDroneImageOpacity,
    selectDroneImagePositions,
    selectDroneImagePreviewUrl,
} from '../../../store/Map/Uploads/selectors';
import DistortableOverlay from '../Annotations/DistortableOverlay/DistortableOverlay';
import { actionSetDroneImageDistortionCorners } from '../../../store/Map/Uploads/actions';

const DroneUploadPreview = () => {
    const mapCorners = (corners: LatLng[]): number[][] => {
        return GeoUtil.toDistortablePositionsClock(corners);
    };

    const previewUrl = useSelector(selectDroneImagePreviewUrl);
    const positions = useSelector(selectDroneImagePositions);
    const distortionToolMode = useSelector(selectDroneImageDistortionTool);
    const opacity = useSelector(selectDroneImageOpacity);
    const distortedCorners = useSelector(selectDroneImageDistortionCorners);

    const dispatch = useDispatch();
    const setDistortionCorners = (corners: number[][]) => dispatch(actionSetDroneImageDistortionCorners(corners));

    if (previewUrl && positions) {
        // TODO a little messy but works fine
        const corners: LatLng[] = distortedCorners?.length
            ? GeoUtil.toLeafletPositionsClock(distortedCorners)
            : GeoUtil.toLeafletPositionsClock(positions);
        return (
            <DistortableOverlay
                dataURL={previewUrl}
                mode={distortionToolMode}
                setCorners={(corners) => setDistortionCorners(mapCorners(corners))}
                corners={corners}
                opacity={opacity}
            />
        );
    } else {
        return <React.Fragment />;
    }
};

export default DroneUploadPreview;
