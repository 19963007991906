import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectEditComment, selectReplyingToComment } from '../../../../store/SocialMapping/selectors';
import ActiveListingCommentInput from '../Comments/comment-input';
import { CommentList } from '../Comments/comment-list';
import { ListingDTO } from '../../../../api/model';

interface ActiveMapCommentsProps {
    listing: ListingDTO;
}

const ActiveMapComments = ({ listing }: ActiveMapCommentsProps) => {
    const selectedEditingComment = useSelector(selectEditComment);
    const selectedReplyingToComment = useSelector(selectReplyingToComment);

    const [commentUpdate, setCommentUpdate] = useState<boolean>(false);
    const [commentCount, setCommentCount] = useState<number | undefined>(undefined);

    return (
        <React.Fragment>
            <CommentListTitle>Comments</CommentListTitle>
            <CommentListContent>
                <CommentList
                    listingId={listing.id}
                    commentUpdate={commentUpdate}
                    setCommentCount={setCommentCount}
                    allowComment
                />
            </CommentListContent>
            <CommentInputContainer
                className="CommentInputContainer"
                isReplyOpen={!selectedEditingComment && !selectedReplyingToComment ? true : false}
            >
                {!selectedEditingComment && !selectedReplyingToComment && (
                    <ActiveListingCommentInput
                        listingId={listing.id}
                        handleCommentUpdate={setCommentUpdate}
                        commentCount={commentCount}
                    />
                )}
            </CommentInputContainer>
        </React.Fragment>
    );
};

export default ActiveMapComments;

interface ActiveMapContainerProps {
    closeDraw?: boolean;
    isReplyOpen?: boolean;
}

const CommentInputContainer = styled.div<ActiveMapContainerProps>`
    flex: 0 1 0px;
    padding: ${(props) => (props.isReplyOpen ? '15px 0px 16px 0px' : '0px 0px 0px 0px')};
    height: ${(props) => (props.isReplyOpen ? '100%' : '0px')};
    opacity: ${(props) => (props.isReplyOpen ? '1' : '0')};
    transition: all 0.55s;
    -webkit-transition: all 0.55ms;

    margin-top: 5px;
    padding: 10px 5px 5px;
    display: flex;
    flex-direction: column;
`;

const CommentListContent = styled.div`
    margin: 0;
    padding: 0;
    margin-right: 0;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
    overflow: hidden auto;
    flex: 1 0 calc(100vh - 275px);
    height: calc(100vh - 275px);

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const CommentListTitle = styled.h1`
    text-align: center;
    font-size: 22px;
    color: white;
    margin-bottom: 5px;
    padding-bottom: 10px;
`;
