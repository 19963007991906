import React from 'react';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { StyledButton } from '../../../../Shared/styled-button';

interface ProjectPermissionsUserTableProps {
    invitedUserEmails: string[];
    onRemoveUser: (email: string) => void;
}

const ProjectPermissionsUserTable = (props: ProjectPermissionsUserTableProps) => {
    if (props.invitedUserEmails.length === 0) {
        return <React.Fragment />;
    }

    return (
        <TasksTable>
            <thead>
                <tr>
                    <th>User</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {props.invitedUserEmails.map((email, index) => (
                    <tr key={index}>
                        <td>{email}</td>
                        <td>
                            <Button onClick={() => props.onRemoveUser(email)}>Remove user</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </TasksTable>
    );
};

export default ProjectPermissionsUserTable;

const TasksTable = styled(Table)`
    background-color: transparent;
    color: white;
    margin: 12px 12px 0 12px;
    width: calc(100% - 24px);

    th {
        color: white !important;
        border-top: none;
        border-bottom: none !important;
    }

    tr {
        color: white !important;
    }

    td {
        color: white !important;
        vertical-align: inherit !important;
        padding: 0;
    }

    td > span {
        color: white !important;
    }

    @media only screen and (max-width: 1600px) {
        th {
            font-size: 12px;
        }
        tbody {
            font-size: 12px;
        }
    }
`;

const Button = styled(StyledButton)`
    margin: 5px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    display: inline-block;
`;
