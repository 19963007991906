import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import RouteMap from '../../routes';
import { selectConfig } from '../../store/App/selectors';
import { actionGetSubdomain } from '../../store/Dashboard/actions';
import NavigationBar from '../NavigationBar/navbar';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import SuspendedSubdomain from './suspended-subdomain';

const SuspendedRoutesMap = () => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);

    useEffect(() => {
        dispatch(actionGetSubdomain());
    }, [dispatch]);

    return (
        <Switch>
            {config.STATUS === 'SUSPENDED' ? (
                <Route path="/" component={() => <SuspendedSubdomain />} />
            ) : (
                <>
                    <NavigationBar />
                    <ToastContainer className="toast-container" />
                    <RouteMap />
                </>
            )}
            // TODO check requirement of these routes now we are handling login and reg by modal.
            <Route
                exact
                path="/login"
                render={() => <LoginRegisterDialog isOpen={true} initialMode={LoginModalMode.LOGIN} />}
            />
            <Route
                exact
                path="/register"
                render={() => <LoginRegisterDialog isOpen={true} initialMode={LoginModalMode.REGISTER} />}
            />
            <Route
                exact
                path="/password_reset"
                render={() => <LoginRegisterDialog isOpen={true} initialMode={LoginModalMode.PASSWORD_RESET} />}
            />
            <Route
                exact
                path="/forgot_password"
                render={() => <LoginRegisterDialog isOpen={true} initialMode={LoginModalMode.FORGOT_PASSWORD} />}
            />
        </Switch>
    );
};

export default SuspendedRoutesMap;
