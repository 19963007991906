import React from 'react';
import styled from 'styled-components';

interface AnnotationDeleteProps {
    onClick: () => void;
}

const AnnotationDelete = (props: AnnotationDeleteProps) => {
    return (
        <React.Fragment>
            <DeleteButton title="Delete" onClick={props.onClick}>
                <DeleteIcon src="/assets/annotations/icon-trash-red.svg" />
            </DeleteButton>
        </React.Fragment>
    );
};

export default AnnotationDelete;

const DeleteButton = styled.button`
    top: 2px;
    right: 0px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
`;

const DeleteIcon = styled.img`
    width: 28px;
`;
