import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { StoaryResponse } from '../../../../api/stoaryModel';

interface StoariesCardItemProps {
    project: StoaryResponse;
}

const SideDrawerStoariesCarditem = ({ project }: StoariesCardItemProps) => {
    return (
        <StoariesItemCard>
            <StoaryTitle>{project.title || <StoaryNoTitle />}</StoaryTitle>
            <StoaryAuthor>{project.ownerName || 'Soar User'}</StoaryAuthor>
            <StoaryFooter>
                <StoaryDate>{moment(project.createdAt * 1000).fromNow()}</StoaryDate>
                <StoryStats>
                    {project?.featureCount && project.featureCount > 0 && (
                        <StoaryStatsIcon
                            title={`${project.featureCount} annotation${project.featureCount > 1 ? 's' : ''}`}
                        >
                            <StoryAnnotationIcon src="assets/dashboard/draw_yellow.svg" />
                            <StoryText>{project.featureCount}</StoryText>
                        </StoaryStatsIcon>
                    )}
                    {project?.viewPortsWKT && (
                        <StoaryStatsIcon>
                            <StoryAnnotationIcon
                                title={`${Object.entries(project.viewPortsWKT).length} page${
                                    Object.entries(project.viewPortsWKT).length > 1 ? 's' : ''
                                }`}
                                src="assets/side-drawer/page_yellow.svg"
                            />
                            <StoryText>{Object.entries(project.viewPortsWKT).length}</StoryText>
                        </StoaryStatsIcon>
                    )}
                </StoryStats>
            </StoaryFooter>
        </StoariesItemCard>
    );
};

export default SideDrawerStoariesCarditem;

const StoariesItemCard = styled.div`
    color: ${(props) => props.theme.color.white};
    display: flex;
    flex-direction: column;
    padding: 4px 8px;
`;

const StoaryTitle = styled.h3`
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.color.yellow};
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
`;

const StoaryNoTitle = styled.em`
    &:before {
        content: 'Untitled Stoary';
        color: ${(props) => props.theme.color.opaqueWhite};
    }
`;

const StoaryAuthor = styled.p`
    color: ${(props) => props.theme.color.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;

    &:before {
        content: 'Stoary by: ';
        color: ${(props) => props.theme.color.opaqueWhite};
    }
`;

const StoaryFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StoaryDate = styled.p`
    color: ${(props) => props.theme.color.opaqueWhite};
    margin-bottom: 0;
`;

const StoryAnnotationIcon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 4px;
`;

const StoaryStatsIcon = styled.div`
    margin-left: 2px;
`;

const StoryText = styled.sup`
    color: ${(props) => props.theme.color.opaqueWhite};
`;

const StoryStats = styled.div`
    display: flex;
    margin-top: -3px;
    margin-right: -5px;
`;
