import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import profileIconUrl from '../Dashboard/profile-icon-url';
import { StyledButton } from '../Shared/styled-button';

interface ExploreSubdomainEmptyProps {
    canUploadMap?: boolean;
}

export const ExploreSubdomainEmpty = (props: ExploreSubdomainEmptyProps, { canUploadMap = true }) => {
    return (
        <NoResultsContainer>
            <NoResultsNotice>
                <NoResultsImage src={profileIconUrl.noImagesUrl} />
                <h3>No images to display</h3>
                <NoMobileUpload>
                    <p> You can upload maps using a desktop computer.</p>
                </NoMobileUpload>
                <Button
                    onClick={() => {
                        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
                        Analytics.Event('My Maps', 'Clicked Search for Satellites', 'Existing User');
                    }}
                >
                    Search for satellite imagery
                </Button>
                {canUploadMap && (
                    <Button
                        onClick={() => {
                            UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
                            Analytics.Event('My Maps', 'Clicked upload a map', 'Existing User');
                        }}
                    >
                        <MyMapsUploadIcon src="/assets/floating-drawer-icons/maps-icon-black.svg" />
                        Add your maps
                    </Button>
                )}
            </NoResultsNotice>
        </NoResultsContainer>
    );
};

const NoResultsContainer = styled.div`
    text-align: center;
    width: 100vw;
    height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;

    @media only screen and (max-width: 600px) {
        height: inherit;
        padding-bottom: 20px;
    }
`;

const NoResultsImage = styled.img`
    width: 130px;
    height: 130px;
    margin-bottom: 30px;
`;

const NoResultsNotice = styled.div`
    color: inherit;
    max-width: 400px;
    height: inherit;
    margin-top: 300px;

    @media only screen and (max-width: 600px) {
        height: 100vh;
        margin-top: 200px;
    }

    h3 {
        color: inherit;
    }

    p {
        color: inherit;
        font-size: 1rem;
    }
`;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: inline-block;
    width: 93%;
    text-transform: uppercase;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const MyMapsUploadIcon = styled.img`
    width: 25px;
    margin: -4px 7px 0px 5px;
`;

const NoMobileUpload = styled.div`
    color: inherit;
    font-size: 12px;
    display: none;

    @media only screen and (max-width: 600px) {
        display: inline-block;
    }
`;
