import styled from 'styled-components';
import profileIconUrl from '../profile-icon-url';

const NoActivity = () => {
    return (
        <NoActivityContainer>
            <NoActivityNotice>
                <NoActivityImage src={profileIconUrl.noImagesUrl} />
                <h3>You do not have any maps yet</h3>
                <p>Check the tutorials on how to create compelling content and keep those images Soaring!</p>
            </NoActivityNotice>
        </NoActivityContainer>
    );
};

export default NoActivity;

const NoActivityContainer = styled.div`
    text-align: center;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        padding-bottom: 20px;
        align-items: center;
    }
`;

const NoActivityImage = styled.img`
    width: 130px;
    height: 130px;
    margin-bottom: 30px;
`;

const NoActivityNotice = styled.div`
    color: inherit;
    max-width: 400px;

    h3 {
        color: inherit;
    }

    p {
        color: inherit;
        font-size: 12px;
    }
`;
