import { useDispatch } from 'react-redux';
import ApiUser from '../../../api/api-user';
import Analytics from '../../../lib/user-analytics';
import { StyledDeleteButton, StyledDarkButton } from '../../Shared/styled-button';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../Shared/soar-modal';
import UriHelper from '../../../lib/uri-helper';

import { actionLogoutUser } from '../../../store/Account/actions';

interface ProfileDeleteModalProps {
    userId?: string;
    toggle: () => void;
}

const ProfileDeleteModal = (props: ProfileDeleteModalProps) => {
    const { userId, toggle } = props;

    const dispatch = useDispatch();

    const handleDeleteAccount = () => {
        if (userId) {
            ApiUser.deleteUser(userId)
                .then(() => {
                    toggle();
                    dispatch(actionLogoutUser());
                    UriHelper.navigateToPath('/');
                    Analytics.Event('My Dashboard', 'User deleted profile', userId);
                    window.location.reload();
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <SoarModal
            title={
                <span>
                    <i className="fa fa-exclamation-circle icon mr-2 text-danger" />
                    Delete Confirmation
                </span>
            }
            isOpen={true}
            toggle={toggle}
        >
            <StyledModalBody>
                Are you sure you want to delete your Soar account? This action can not be undone!
            </StyledModalBody>
            <StyledModalFooter>
                <StyledDarkButton onClick={toggle}>CANCEL</StyledDarkButton>
                <StyledDeleteButton onClick={handleDeleteAccount}>DELETE</StyledDeleteButton>
            </StyledModalFooter>
        </SoarModal>
    );
};

export default ProfileDeleteModal;
