import Constants from '../../../../constants';
import OAuthButton, { OAuthButtonThemes } from '../Shared/oauth-button';
import { OAuthProviderConfig } from '../Shared/oauth-callback';
import useOAuth from '../Shared/use-oauth';

interface GoogleLoginButtonProps {
    theme?: OAuthButtonThemes;
}

const FacebookLoginButton = ({ theme }: GoogleLoginButtonProps) => {
    const facebookOAuthConstants: OAuthProviderConfig = Constants.OAUTH_SOCIAL_LOGIN.FACEBOOK;
    const { openPopup } = useOAuth({
        subdomain: facebookOAuthConstants.SUBDOMAIN,
        scope: facebookOAuthConstants.SCOPE,
        identityProvider: facebookOAuthConstants.IDENTITY_PROVIDER,
    });

    return (
        <OAuthButton
            iconUrl="/assets/auth-icons/fb-logo.png"
            text="Sign in with Facebook"
            onClick={openPopup}
            theme={theme}
        />
    );
};

export default FacebookLoginButton;
