class StorageHelper {
    static get(key: string) {
        return localStorage.getItem(`soar-${key}`);
    }

    static set(key: string, value: string) {
        localStorage.setItem(`soar-${key}`, value);
    }

    static remove(key: string) {
        localStorage.removeItem(`soar-${key}`);
    }
}

export default StorageHelper;
