import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface CarouselProps {
    dragHandler: (isDragging: boolean) => void;
    children: React.ReactNode;
    containerHeight?: string;
}

//TODO: make left and right arrow customizable
const SoarCarousel = (props: CarouselProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const [isLeftArrowVisible, setLeftArrowVisible] = useState(true);
    const [isRightArrowVisible, setRightArrowVisible] = useState(true);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [scrollLeftStart, setScrollLeftStart] = useState(0);
    const [startX, setStartX] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = scrollLeft;

            setLeftArrowVisible(containerRef.current.scrollLeft > 0);
            setRightArrowVisible(
                containerRef.current.clientWidth + containerRef.current.scrollLeft + 20 <
                    containerRef.current.scrollWidth
            );
        }
    }, [scrollLeft]);

    useEffect(() => {
        props.dragHandler(isDragging);
    }, [isDragging, props]);

    const handleScrollButton = (x: number) => {
        if (containerRef.current) {
            containerRef.current.classList.add('scroll');
            setScrollLeft(containerRef.current.scrollLeft + x);
        }
    };

    return (
        <Container>
            <CarouselContainer
                ref={containerRef}
                isDragging={isDragging && isMouseDown}
                height={props.containerHeight}
                onMouseDown={(e) => {
                    setIsMouseDown(true);
                    setIsDragging(false);
                    if (containerRef.current) {
                        containerRef.current.classList.remove('scroll');

                        setStartX(e.clientX - containerRef.current.offsetLeft);
                        setScrollLeftStart(containerRef.current.scrollLeft);
                    }
                }}
                onMouseUp={() => {
                    setIsMouseDown(false);
                }}
                onMouseMove={(e) => {
                    if (isMouseDown) {
                        e.preventDefault();
                        if (containerRef.current) {
                            const x = e.pageX - containerRef.current.offsetLeft;
                            const walk = (x - startX) * 2;
                            const dragX = scrollLeftStart - walk;
                            setScrollLeft(dragX);
                            if (dragX !== 0) {
                                setIsDragging(true);
                            }
                        }
                    }
                }}
            >
                {props.children}
            </CarouselContainer>
            <LeftArrowContainer
                isVisible={isLeftArrowVisible}
                title="view previous"
                onClick={() => handleScrollButton(-300)}
            >
                <LeftArrowIcon className="fa fa-chevron-left" />
            </LeftArrowContainer>
            <RightArrowContainer
                isVisible={isRightArrowVisible}
                title="view more"
                onClick={() => handleScrollButton(300)}
            >
                <RightArrowIcon className="fa fa-chevron-right" />
            </RightArrowContainer>
        </Container>
    );
};

export default SoarCarousel;

interface ArrowProps {
    isVisible: boolean;
}

const Container = styled.div`
    width: 100%;
    margin-left: 0px;
    position: relative;
`;

interface CarouselContainerProps {
    isDragging: boolean;
    height?: string;
}

const CarouselContainer = styled.div<CarouselContainerProps>`
    background: none;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    cursor: ${(props) => (props.isDragging ? 'grabbing' : 'pointer')};
    height: ${(props) => props.height || '100%'};

    &.scroll {
        scroll-behavior: smooth;
    }
`;

const ArrowContainer = styled.button<ArrowProps>`
    width: 42px;
    height: calc(100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
    margin-top: 2px;
    display: flex;
    align-items: center;
    outline: none !important;
    border: none;
    display: ${(props) => (props.isVisible ? '' : 'none')};
`;

const LeftArrowContainer = styled(ArrowContainer)`
    z-index: 99999;
    padding-left: 0px;
    mask-image: -webkit-gradient(linear, left center, right center, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
`;

const RightArrowContainer = styled(ArrowContainer)`
    left: calc(100% - 42px);
    padding-right: 0px;
    mask-image: -webkit-gradient(linear, left center, right center, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
`;

const ArrowIcon = styled.i`
    font-size: 2rem;
    color: white;
    width: 100%;
`;

const LeftArrowIcon = styled(ArrowIcon)`
    text-align: left;
`;

const RightArrowIcon = styled(ArrowIcon)`
    text-align: right;
`;
