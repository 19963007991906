import styled from 'styled-components';
import { SentinelFeature } from '../../../../../store/Map/Sentinel/model';
import SentinelMapOpacitySlider from './sentinel-map-opacity-slider';

interface SentinelMapAttributionProps {
    feature: SentinelFeature;
}

const SentinelMapAttribution = ({ feature }: SentinelMapAttributionProps) => {
    const formatDate = (date) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString();
    };

    return (
        <Container>
            <Reference>{feature.satellite}</Reference>
            <CardContainer>
                <ContentContainer>
                    <Title>{`${feature.layer.replaceAll('_', ' ')} taken ${formatDate(feature.date)}`}</Title>
                    <SubText>Resolution: {feature.resolution}</SubText>
                    <SentinelMapOpacitySlider />
                </ContentContainer>
            </CardContainer>
        </Container>
    );
};

export default SentinelMapAttribution;

const Container = styled.div`
    margin: 12px;
`;

const Reference = styled.span`
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.normal};
    color: white;
    margin: 0 0 2px 2px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: ${(props) => props.theme.borderRadius};
    padding: 3px;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 2px;
    flex: 1;
`;

const Title = styled.p`
    color: #eed926 !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    margin: 0;
`;
