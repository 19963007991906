import FreehandPolyline from '../../../components/MapView/Annotations/FreehandPolyline/freehand-polyline';
import { actionTypes as at } from './constants';

export const setFreehandDrawsAction = (freehandDraws: FreehandPolyline[]) => {
    return {
        type: at.SET_FREEHAND_DRAWS,
        payload: freehandDraws,
    };
};

export const setFreehandDrawOptionsAction = (options: L.PolylineOptions) => {
    return {
        type: at.SET_FREEHAND_DRAW_OPTIONS,
        payload: options,
    };
};

export const setFreehandDrawAddAction = (freehandDraw: FreehandPolyline) => {
    return {
        type: at.SET_FREEHAND_DRAW_ADD,
        payload: freehandDraw,
    };
};

export const setFreehandDrawUpdateAction = (freehandDraw: FreehandPolyline) => {
    return {
        type: at.SET_FREEHAND_DRAW_UPDATE,
        payload: freehandDraw,
    };
};

export const setFreehandDrawDeleteAction = (freehandDraw: FreehandPolyline) => {
    return {
        type: at.SET_FREEHAND_DRAW_DELETE,
        payload: freehandDraw,
    };
};
