import { useState } from 'react';
import { ListingDTO, ListingType } from '../../../api/model';
import RecentActivityItem from './recent-activity-item';
import Analytics from '../../../lib/user-analytics';
import styled from 'styled-components';
import NoActivity from './no-activity';

interface RecentActivityProps {
    listingsData: ListingDTO[];
}

const RecentActivity = (props: RecentActivityProps): JSX.Element => {
    const [numberOfRecentItems, setNumberOfRecentItems] = useState(4);
    const mostRecentListings = props.listingsData
        .filter((l) => l.listingType !== ListingType.NOT_SUPPORTED && !l.error)
        .sort((a, b) => b.createdAt - a.createdAt);

    return (
        <AdminDashboardRecentActivity>
            <h4>Recent Activity</h4>
            {mostRecentListings.length ? (
                <ul>
                    {mostRecentListings.map((listing, index) => {
                        if (index > numberOfRecentItems - 1) return;
                        return <RecentActivityItem key={listing.id} listing={listing} />;
                    })}
                </ul>
            ) : (
                <NoActivity />
            )}

            {numberOfRecentItems < mostRecentListings.length ? (
                <ShowMore
                    onClick={() => {
                        setNumberOfRecentItems(numberOfRecentItems + 5);
                        Analytics.Event('My Dashboard', 'Clicked to show more recent activity');
                    }}
                >
                    Show More
                </ShowMore>
            ) : null}
        </AdminDashboardRecentActivity>
    );
};

export default RecentActivity;

const AdminDashboardRecentActivity = styled.div`
    background-color: rgb(0, 0, 0, 0.8);
    margin: 2px;
    padding: 20px;
    border-radius: 6px;
    padding-top: 0;

    h4 {
        padding-top: 10px;
        padding-bottom: 0px;
        color: white;
    }

    @media only screen and (max-width: 600px) {
        background-color: transparent;
        margin: 0px;
        padding: 0px;
        border-radius: 0px;
        padding-top: 10px;

        h4 {
            padding-bottom: 10px;
        }

        ul {
            padding-inline-start: 10px;
            padding-inline-end: 10px;
        }
    }
`;

const ShowMore = styled.div`
    color: white;
    padding-left: 40px;
    cursor: pointer;
`;
