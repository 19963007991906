import { actionTypes as at } from './constants';
import { Action } from '../root-reducer';

import Circle from '../../components/MapView/Annotations/Circle/circle';
import FreehandPolyline from '../../components/MapView/Annotations/FreehandPolyline/freehand-polyline';
import Image from '../../components/MapView/Annotations/ImageTool/image';
import Polyline from '../../components/MapView/Annotations/Polyline/polyline';
import Rectangle from '../../components/MapView/Annotations/Rectangle/rectangle';
import TextBox from '../../components/MapView/Annotations/Text/textbox';
import Coordinate from '../../components/MapView/Annotations/Coordinate/coordinate';
import PolygonPath from '../../components/MapView/Annotations/Polygon/polygon';
import Marker from '../../components/MapView/Annotations/Marker/marker';
import MilitaryMarker from '../../components/MapView/Annotations/MilitaryMarker/military-marker';
import Arrow from '../../components/MapView/Annotations/Arrow/arrow';

import { defaultZIndex } from '../../components/MapView/Annotations/layers-util';

export type Annotation =
    | Marker
    | MilitaryMarker
    | Polyline
    | PolygonPath
    | Arrow
    | Circle
    | Rectangle
    | TextBox
    | FreehandPolyline
    | Image
    | Coordinate
    | undefined;

interface ProjectState {
    selectedAnnotation: Annotation;
    currentZIndex: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fillPatterns: any[];
}

const initialState: ProjectState = {
    selectedAnnotation: undefined,
    currentZIndex: defaultZIndex,
    fillPatterns: [],
};

export default (state: ProjectState = initialState, action: Action): ProjectState => {
    switch (action.type) {
        case at.CLEAR_ALL:
            return { ...initialState };

        case at.SET_SELECTED_ANNOTATION:
            return { ...state, selectedAnnotation: action.payload.selectedAnnotation };

        case at.SET_CURRENT_Z_INDEX:
            return { ...state, currentZIndex: action.payload.zIndex };

        case at.INCREMENT_CURRENT_Z_INDEX:
            return { ...state, currentZIndex: state.currentZIndex + 1 };

        case at.SET_ANNOTATION_FILL_PATTERNS: {
            return { ...state, fillPatterns: action.payload.fillPatterns };
        }

        default:
            return state;
    }
};
