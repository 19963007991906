/**
 * Calculates the length of the line in pixels.
 * @param map The map to calculate the line length on.
 * @param fromLatLng The starting point of the line.
 * @param toLatLng The ending point of the line.
 * @returns The length of the line in pixels.
 */
export const lineLengthAsPixels = (map: L.Map, fromLatLng: L.LatLng, toLatLng: L.LatLng): number => {
    const from = map.latLngToLayerPoint(fromLatLng);
    const to = map.latLngToLayerPoint(toLatLng);
    const lineLengthAsPixels = from.distanceTo(to);
    return lineLengthAsPixels;
};