import styled from 'styled-components';

const ButtonBase = styled.button`
    outline: none !important;
    display: block;
    border-radius: ${(props) => props.theme.borderRadius};
    text-transform: uppercase;
    user-select: none;
    padding: 8px 25px;

    :active,
    :focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.6;
    }
`;

export const Button = styled(ButtonBase)`
    background-color: ${(props) => props.theme.color.yellow};
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    font-size: ${(props) => props.theme.fontSize.normal};

    &:disabled {
        background-color: ${(props) => props.theme.color.transparentDark};
    }
`;

export const SmallButton = styled(Button)`
    padding: 5px 10px;
    font-size: ${(props) => props.theme.fontSize.small};
`;

export const LargeButton = styled(Button)`
    font-size: ${(props) => props.theme.fontSize.large};
`;

export const TransparentButton = styled(ButtonBase)`
    background-color: transparent !important;
    border: 1px solid ${(props) => props.theme.color.yellow} !important;
    color: ${(props) => props.theme.color.yellow};
`;

export const LinkButton = styled.button`
    background: none !important;
    border: none;
    padding: 0 !important;
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.color.yellow};
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    :focus {
        outline: none;
    }
`;
