import styled from 'styled-components';
import Analytics from '../../../../lib/user-analytics';
import Coordinate from './coordinate';
import { getFormattedCoordinates } from './coordinate-util';
import { toast } from 'react-toastify';

interface CoordinateCopyControlProps {
    coordinate: Coordinate;
}

const CoordinateCopyControl = (props: CoordinateCopyControlProps) => {
    const { coordinateSystem, position } = props.coordinate;

    const handleCopyLatLng = () => {
        const coordinates = getFormattedCoordinates(coordinateSystem || 'WGS84 DD', position);
        navigator.clipboard.writeText(coordinates.join('\n'));
        Analytics.Event(`Annotations`, `Copied latlng as ${coordinateSystem}`);
        toast.dark('Copied to clipboard');
    };
    return (
        <ShareCoordinate onClick={handleCopyLatLng}>
            <CopyIcon className={'fa fa-clipboard'} />
        </ShareCoordinate>
    );
};

export default CoordinateCopyControl;

const CopyIcon = styled.i`
    color: rgba(255, 255, 255, 0.87);
    font-size: 1.5rem;
    pointer-events: all;
`;

const ShareCoordinate = styled.button`
    background-color: transparent;
    cursor: pointer;
    width: 40px;
    margin: 4px 0px 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.87);

    &:hover {
        color: #eed926;
        background-color: rgba(255, 255, 255, 0.1);
    }
`;
