import Select, { components, InputProps, OptionProps, DropdownIndicatorProps } from 'react-select';
import { PrimaryCategory } from '../../api/model';
interface MultiselectProps {
    options: PrimaryCategory[];
    onChange: (values: PrimaryCategory[]) => void;
    defaultValue?: PrimaryCategory[];
    dataTestId?: string;
}

export const Multiselect = (props: MultiselectProps) => {
    const Input = (inputProps: InputProps) => {
        return <components.Input {...inputProps} data-testid={props.dataTestId && `${props.dataTestId}-input`} />;
    };
    const Option = (optionProps: OptionProps) => {
        return (
            <components.Option
                {...optionProps}
                innerProps={Object.assign({}, optionProps.innerProps, {
                    'data-testid': props.dataTestId && `${props.dataTestId}-option`,
                })}
            />
        );
    };

    const DropdownIndicator = (dropdownProps: DropdownIndicatorProps) => {
        return (
            <components.DropdownIndicator
                {...dropdownProps}
                innerProps={Object.assign({}, dropdownProps.innerProps, {
                    'data-testid': props.dataTestId && `${props.dataTestId}-indicator`,
                })}
            />
        );
    };

    return (
        <Select
            components={{ Input, Option, DropdownIndicator }}
            options={props.options}
            isMulti
            menuPortalTarget={document.body}
            styles={{
                container: (provided) => ({
                    ...provided,
                    '& :hover': {
                        borderColor: '#eed926',
                    },
                }),
                control: (provided) => ({
                    ...provided,
                    backgroundColor: 'none',
                    borderColor: 'rgba(255, 255, 255, 0.6)',
                    boxShadow: 'none',
                    color: 'rgba(255, 255, 255, 0.6)',
                    '& :hover': {
                        borderColor: '#eed926',
                    },
                }),
                menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 999999,
                }),
                menuList: (provided) => ({
                    ...provided,
                    height: '160px',
                }),
                input: (provided) => ({
                    ...provided,
                    color: 'rgba(255, 255, 255, 0.6)',
                }),
                singleValue: (provided, state) => {
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = 'opacity 300ms';

                    return { ...provided, opacity, transition };
                },
            }}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
        />
    );
};
