import React from 'react';
import styled from 'styled-components';

interface StyledCommentProps {
    text: string;
    isSelected: boolean;
}

const StyledComment = (props: StyledCommentProps) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = props.text.split(urlRegex);

    const messageText = parts.map((part, index) => {
        if (urlRegex.test(part)) {
            return <TruncatedHyperlink key={index} urlText={part} isSelected={props.isSelected} />;
        } else {
            return part;
        }
    });

    return <Message>{messageText}</Message>;
};

export default StyledComment;

interface TruncatedHyperlinkProps {
    urlText: string;
    isSelected: boolean;
}

const TruncatedHyperlink = (props: TruncatedHyperlinkProps) => {
    const url = new URL(props.urlText);

    const truncatedUrl = `${url.origin}/${
        url.pathname.substr(1).length > 0
            ? `${url.pathname
                  .substr(1)
                  .split(/[^a-z0-9]/i)
                  .at(0)}…`
            : ''
    }`;

    return (
        <Hyperlink
            title={url.toString()}
            href={url.toString()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
                // open the link in a new tab if the comment is selected and stop propagation, otherwise allow the click to propagate
                if (!props.isSelected) {
                    e.preventDefault();
                } else {
                    e.stopPropagation();
                }
            }}
        >
            {truncatedUrl}
        </Hyperlink>
    );
};

const Message = styled.p`
    color: rgba(255, 255, 255, 0.87);
    font-size: 13px;
    margin: 1px 15px 5px 10px;
    word-break: break-word;
    border-radius: 4px;
`;

const Hyperlink = styled.a`
    color: rgba(255, 255, 255, 0.87);
    font-size: 13px;
    word-break: break-word;
    border-radius: 4px;

    &:hover {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
    }
`;
