import SoarHelper from '../../lib/soar-helper';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';
import { SideDrawerMode } from './model';

interface StoreSideDrawer {
    sideDrawerMode: SideDrawerMode;
    sideDrawerOpen: boolean;
    sideDrawerCollapse: boolean;
}

const initialState: StoreSideDrawer = {
    sideDrawerMode: SoarHelper.isSoar() ? SideDrawerMode.MAPS : SideDrawerMode.SUBDOMAIN_MAPS,
    sideDrawerOpen: true,
    sideDrawerCollapse: false,
};

export default (state: StoreSideDrawer = initialState, action: Action): StoreSideDrawer => {
    switch (action.type) {
        case at.SIDEDRAWER_MODE:
            return {
                ...state,
                sideDrawerMode: action.payload,
                sideDrawerCollapse: false,
            };
        case at.SIDEDRAWER_OPEN:
            return {
                ...state,
                sideDrawerOpen: action.payload,
            };
        case at.SIDEDRAWER_COLLAPSE: {
            return {
                ...state,
                sideDrawerCollapse: action.payload,
            };
        }
        default:
            return state;
    }
};
