import { useEffect, useRef } from 'react';
// https://github.com/Hermanya/use-interval/blob/master/src/index.tsx
// https://www.npmjs.com/package/use-interval

/* istanbul ignore next */
/** keep typescript happy */
const noop = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

/**
 *
 * @param callback  The function to call
 * @param delay  The delay in milliseconds
 * @param immediate Optional: If true, run the callback immediately
 *
 * useInterval(
 *  callback: () => void,
 *  delay: number,
 *  immediate?: boolean
 * )
 */
export function useInterval(callback: () => void, delay: number | null | false, immediate?: boolean) {
    const savedCallback = useRef(noop);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    });

    // Execute callback if immediate is set.
    useEffect(() => {
        if (!immediate || delay === null || delay === false) return;
        savedCallback.current();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [immediate]);

    // Set up the interval.
    useEffect(() => {
        if (delay === null || delay === false) return;
        const tick = () => savedCallback.current();
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export default useInterval;
