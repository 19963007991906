import React from 'react';
import { useSelector } from 'react-redux';
import { selectCoordinates } from '../../../store/Annotations/Coordinate/selectors';
import CoordinateAnnotation from '../../MapView/Annotations/Coordinate/coordinate-annotation';

const MobileCoordinate = () => {
    const coordinates = useSelector(selectCoordinates);

    return (
        <React.Fragment>
            {coordinates.map((coordinate) => {
                return (
                    <CoordinateAnnotation
                        key={`
                            ${coordinate.id}-
                            ${coordinate.zIndex}-
                            ${coordinate.position?.lat}-
                            ${coordinate.position?.lng}-
                            ${coordinate.coordinateSystem}    
                        `}
                        coordinate={coordinate}
                        isSelected={false}
                        onUpdate={() => ''}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileCoordinate;
