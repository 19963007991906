import Image from '../../../components/MapView/Annotations/ImageTool/image';
import { actionTypes as at } from './constants';

export const setImagesAction = (images: Image[]) => {
    return {
        type: at.SET_IMAGES,
        payload: { images: images },
    };
};

export const setImageAddAction = (image: Image) => {
    return {
        type: at.SET_IMAGE_ADD,
        payload: image,
    };
};

export const setImageUpdateAction = (image: Image) => {
    return {
        type: at.SET_IMAGE_UPDATE,
        payload: image,
    };
};

export const setImageDeleteAction = (image: Image) => {
    return {
        type: at.SET_IMAGE_DELETE,
        payload: image,
    };
};
