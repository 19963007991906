import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L, { LatLngBounds } from 'leaflet';
import { MarkerProps } from 'react-leaflet';
import LayersUtil from '../layers-util';
import { nodeMarkerOptions } from '../Polygon/node-marker-handles';
interface RectangleResizeElementProps {
    rectangleElement: Readonly<{ instance: L.Rectangle; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const createCornerDragMarker = (position: L.LatLng, cursorClass: string, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const markerElement = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, { ...nodeMarkerOptions, pane: controlPaneId }),
        context
    );

    markerElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), cursorClass);
    });

    markerElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), cursorClass);
    });

    markerElement.instance.on('remove', () => {
        L.DomUtil.removeClass(context.map.getContainer(), cursorClass);
    });

    return markerElement;
};

export const createRectangleResizeElement = (props: RectangleResizeElementProps, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const rectangleResizeLayer = new L.LayerGroup([], { pane: controlPaneId });
    const rectangleResizeLayerElement = createElementObject<L.LayerGroup>(rectangleResizeLayer, context);

    const northWestCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getNorthWest(),
        'leaflet-nw-resize',
        context
    );

    const northEastCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getNorthEast(),
        'leaflet-ne-resize',
        context
    );

    const southEastCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getSouthEast(),
        'leaflet-se-resize',
        context
    );
    const southWestCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getSouthWest(),
        'leaflet-sw-resize',
        context
    );

    const updateBounds = (newBounds: L.LatLngBounds, event: L.LeafletMouseEvent) => {
        northEastCornerMarker.instance.setLatLng(newBounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(newBounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(newBounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(newBounds.getSouthEast());
        props.rectangleElement.instance.setBounds(newBounds);
        props.rectangleElement.instance.fire('update', event);
    };

    const onDragNorthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getSouthWest());
        updateBounds(newBounds, e);
    };

    const onDragNorthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getSouthEast());
        updateBounds(newBounds, e);
    };

    const onDragSouthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getNorthWest());
        updateBounds(newBounds, e);
    };

    const onDragSouthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getNorthEast());
        updateBounds(newBounds, e);
    };

    rectangleResizeLayerElement.instance.on('add', () => {
        rectangleResizeLayerElement.instance.addLayer(northEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(northWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(southEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(southWestCornerMarker.instance);

        northEastCornerMarker.instance.on('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.on('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.on('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.on('drag', onDragSouthWestCornerMarker);
    });

    rectangleResizeLayerElement.instance.on('remove', () => {
        rectangleResizeLayerElement.instance.removeLayer(northEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(northWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(southEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(southWestCornerMarker.instance);

        northEastCornerMarker.instance.off('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.off('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.off('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.off('drag', onDragSouthWestCornerMarker);
    });

    rectangleResizeLayerElement.instance.on('movestart', () => {
        northEastCornerMarker.instance.setOpacity(0);
        northWestCornerMarker.instance.setOpacity(0);
        southEastCornerMarker.instance.setOpacity(0);
        southWestCornerMarker.instance.setOpacity(0);
    });

    rectangleResizeLayerElement.instance.on('moveend', () => {
        const bounds = props.rectangleElement.instance.getBounds();
        northEastCornerMarker.instance.setLatLng(bounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(bounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(bounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(bounds.getSouthEast());

        northEastCornerMarker.instance.setOpacity(1);
        northWestCornerMarker.instance.setOpacity(1);
        southEastCornerMarker.instance.setOpacity(1);
        southWestCornerMarker.instance.setOpacity(1);
    });

    rectangleResizeLayerElement.instance.on('update', () => {
        const bounds = props.rectangleElement.instance.getBounds();
        northEastCornerMarker.instance.setLatLng(bounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(bounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(bounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(bounds.getSouthEast());
    });

    return rectangleResizeLayerElement;
};
