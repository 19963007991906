import React from 'react';
import { LatLngBounds } from 'leaflet';
import GeoUtil from '../../../../lib/geo-util';
import Analytics from '../../../../lib/user-analytics';
import { SatelliteSubmitButton } from '../satellite-drawer-styles';
import DrawerHint from '../../drawer-hint';
import { ValidArchiveAOIParameters } from './satellite-archive';

interface SatelliteArchiveSelectAOIErrorProps {
    clearBoxSelect: () => void;
    selectedAOI: LatLngBounds | undefined;
    analyticsCategory: string;
    validAOIParameters: ValidArchiveAOIParameters;
}

const ErrorButton = (props: { onClick: () => void; error: string }) => {
    return (
        <React.Fragment>
            <SatelliteSubmitButton onClick={props.onClick}>RESET AREA OF INTEREST</SatelliteSubmitButton>
            <DrawerHint error>{props.error}</DrawerHint>
        </React.Fragment>
    );
};

const SatelliteArchiveSelectAOIError = (props: SatelliteArchiveSelectAOIErrorProps) => {
    const clearBoxSelect = props.clearBoxSelect;
    const params = props.validAOIParameters;

    if (props.selectedAOI) {
        const selectedArea = GeoUtil.area(props.selectedAOI) / 1000000;
        const height = GeoUtil.heightKilometers(props.selectedAOI);
        const width = GeoUtil.widthKilometers(props.selectedAOI);

        if (selectedArea < params.minArea) {
            return (
                <ErrorButton
                    onClick={() => {
                        clearBoxSelect();
                        Analytics.Event(props.analyticsCategory, 'Clicked reset aoi too small');
                    }}
                    error={`This AOI is too small. It must be greater than ${params.minArea}km²`}
                />
            );
        } else if (selectedArea > params.maxArea) {
            return (
                <ErrorButton
                    onClick={() => {
                        clearBoxSelect();
                        Analytics.Event(props.analyticsCategory, 'Clicked reset aoi too large');
                    }}
                    error={`This AOI is too large. It must be less than ${params.maxArea}km²`}
                />
            );
        } else if (width < params.minWidth) {
            return (
                <ErrorButton
                    onClick={() => {
                        clearBoxSelect();
                        Analytics.Event(props.analyticsCategory, 'Clicked reset aoi too narrow');
                    }}
                    error={`This AOI is too thin. Its width must be greater than ${params.minWidth}km`}
                />
            );
        } else if (width > params.maxWidth) {
            return (
                <ErrorButton
                    onClick={() => {
                        clearBoxSelect();
                        Analytics.Event(props.analyticsCategory, 'Clicked reset aoi too wide');
                    }}
                    error={`This AOI is too wide. Its width must be less than ${params.maxWidth}km`}
                />
            );
        } else if (height > params.maxHeight) {
            return (
                <ErrorButton
                    onClick={() => {
                        clearBoxSelect();
                        Analytics.Event(props.analyticsCategory, 'Clicked reset aoi too high');
                    }}
                    error={`This AOI is too high. Its height must be less than ${params.maxHeight}km`}
                />
            );
        } else if (height < params.minHeight) {
            return (
                <ErrorButton
                    onClick={() => {
                        clearBoxSelect();
                        Analytics.Event(props.analyticsCategory, 'Clicked reset aoi too short');
                    }}
                    error={`This AOI is too short. Its height must be greater than ${params.minHeight}km`}
                />
            );
        } else {
            return <React.Fragment />;
        }
    } else {
        return <React.Fragment />;
    }
};

export default SatelliteArchiveSelectAOIError;
