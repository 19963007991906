import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

import { selectLoggedIn } from '../../store/Account/selectors';
import ApiSubdomain from '../../api/api-subdomain';
import { BillingInfoDTO, SubDomainType } from '../../api/model';
import Constants from '../../constants';
import Analytics from '../../lib/user-analytics';
import ProgressButton from '../Shared/progress-button';
import PricingHeader, { PricingStep } from './pricing-header-indicator';

interface ConfirmSubdomain {
    subdomain: string;
    planType: SubDomainType;
    countryCode?: string;
    voucher?: string;
    billingInfo?: BillingInfoDTO;
}

const ConfirmSubdomain: React.FC<ConfirmSubdomain> = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const userLoggedIn = useSelector(selectLoggedIn);

    const handleSubmit = () => {
        if (userLoggedIn) {
            setIsSubmitting(true);
            ApiSubdomain.createSubdomain(
                props.subdomain,
                props.planType,
                props.countryCode,
                props.billingInfo,
                props.voucher
            )
                .then(() => {
                    const http = window.location.host.includes('https') ? 'https://' : 'http://';
                    if (window.location.host.split(':')[1]) {
                        const splitDomain = window.location.host.split('.');
                        if (splitDomain[1]) {
                            splitDomain.shift();
                        }
                        const domain = splitDomain.join('.');
                        window.location.href = `${http}${props.subdomain}.${domain}/dashboard/welcome`;
                    } else {
                        window.location.href = `${http}${props.subdomain}.${Constants.DOMAIN}/dashboard/welcome`;
                    }
                    Analytics.Event('Plus', 'Clicked to create subdomain', props.subdomain);
                    setIsSubmitting(false);
                })
                .catch((error) => {
                    toast.error('An error occurred registering the subdomain: ' + error);
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col md={6} sm={0} className="request-subdomain-name-left-container">
                        <LocationGradient />
                    </Col>
                    <LoginRegisterRightContainer
                        md={6}
                        sm={12}
                        className="my-profile-dashboard-container request-subdomain-name"
                    >
                        <PricingHeader step={PricingStep.Confirm} planType={props.planType} />

                        <Row className="justify-content-md-center">
                            <h4 className="pricing-confirm-title">You are about to start something incredible!</h4>
                        </Row>

                        <Row className="justify-content-md-center">
                            <ul className="pricing-confirm-dotpoints">
                                <li>Your free Soar+ Subdomain is ready!</li>
                                <li>Upload your ECW's, GeoTIFF's and JP2's with a simple drag and drop</li>
                                <li>Instantly view your imagery</li>
                                <li>No credit card is required</li>
                                <li>Upgrading to a paid account gives you access control and extra data</li>
                            </ul>
                        </Row>

                        <Row>
                            <ProgressButton
                                isDisabled={false}
                                isSubmitting={isSubmitting}
                                text="START NOW!"
                                onSubmit={() => handleSubmit()}
                            />
                        </Row>
                    </LoginRegisterRightContainer>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default ConfirmSubdomain;

const LoginRegisterRightContainer = styled(Col)`
    margin-left: 50%;
`;
const LocationGradient = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 150px;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    margin: 0;
    padding: 0;
    overflow-y: none;
`;
