import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L, { LeafletMouseEvent } from 'leaflet';
import { createCircleDragElement } from './circle-drag-element';
import { createCirclePathElement } from './circle-annotation-edit-hologram-path';
import LayersUtil from '../layers-util';

interface CircleEditElementProps {
    circleElement: Readonly<{ instance: L.Circle; context: Readonly<{ map: L.Map }> }>;
    color?: string;
}

const circleDragOptions: L.CircleOptions = {
    radius: 3,
    color: 'transparent',
    fill: false,
    fillColor: 'transparent',
    interactive: true,
    bubblingMouseEvents: false,
};

export const createCircleEditElement = (props: CircleEditElementProps, context: LeafletContextInterface) => {
    const controlPaneId = LayersUtil.getControlPaneId(context.map);
    const circleEditLayer = new L.LayerGroup([], { pane: controlPaneId });
    const circleEditLayerElement = createElementObject<L.LayerGroup>(circleEditLayer, context);
    const circleEdit = new L.Circle(props.circleElement.instance.getLatLng(), {
        ...props.circleElement.instance.options,
        ...circleDragOptions,
        weight: 30,

        radius: props.circleElement.instance.getRadius(),
        pane: controlPaneId,
    });
    const dragElement = createCircleDragElement({ circleElement: props.circleElement, color: props.color }, context);
    const pathElement = createCirclePathElement({ circleElement: props.circleElement, color: props.color }, context);

    const circleEditElement = createElementObject<L.Circle>(circleEdit, context);

    circleEditLayerElement.instance.on('add', () => {
        circleEditLayerElement.instance.addLayer(circleEditElement.instance);
        circleEditLayerElement.instance.addLayer(dragElement.instance);

        const mousemoveListener = (e: LeafletMouseEvent) => {
            props.circleElement.instance.fireEvent('resize', e);
            circleEditElement.instance.setRadius(circleEditElement.instance.getLatLng().distanceTo(e.latlng));
        };

        const mouseupListener = () => {
            context.map.dragging.enable();
            context.map.removeLayer(pathElement.instance);
            context.map.off('mousemove', mousemoveListener);
            context.map.off('mouseup', mouseupListener);
        };

        circleEditElement.instance.on('mousedown', () => {
            context.map.dragging.disable();
            context.map.addLayer(pathElement.instance);
            context.map.on('mousemove', mousemoveListener);
            context.map.on('mouseup', mouseupListener);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.circleElement.instance.on('drag', (e: any) => {
            circleEditElement.instance.setLatLng(e);
        });

        circleEditElement.instance.on('mouseover', () => {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-ew-resize');
        });

        circleEditElement.instance.on('mouseout', () => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-ew-resize');
        });

        circleEditElement.instance.on('remove', () => {
            context.map.removeLayer(pathElement.instance);
            context.map.removeLayer(dragElement.instance);
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-ew-resize');
        });
    });

    return circleEditLayerElement;
};
