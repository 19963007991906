import React from 'react';
import styled, { keyframes } from 'styled-components';

const MobileSatelliteLoading = () => {
    return (
        <React.Fragment>
            <LoadingText>Waiting for satellites ...</LoadingText>
            <LoadingContainer>
                <LoadingAnimatedSatellite>
                    <LoadingSatelliteIcon src="/assets/floating-drawer-icons/icon-UI-satellite-white.png" />
                    <LoadingWave src="/assets/floating-drawer-loading-icons/loading-wave.svg" />
                </LoadingAnimatedSatellite>
            </LoadingContainer>
        </React.Fragment>
    );
};

export default MobileSatelliteLoading;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const LoadingText = styled.p`
    margin-top: 20px;
    color: white;
    padding: 0;
    text-align: center;
`;

const LoadingAnimatedSatellite = styled.div`
    display: flex;
    flex-direction: column;
    width: 100px;
`;

const LoadingSatelliteIcon = styled.img`
    width: 44px;
    height: 45px;
    margin-left: 38px;
`;

const WaveAnimation = keyframes`
    0% {
        bottom: 50px;
        right: calc(50vw - 18px);
        transform: rotate(135deg) scale(0.3);
    } 90% {
        bottom: 20px;
        right: calc(50vw + 24px);
        transform: rotate(135deg) scale(0.7);
    }
    100% {
        bottom: 20px;
        right: calc(50vw + 24px);
        transform: rotate(135deg) scale(0.3);
    }
`;
const LoadingWave = styled.img`
    width: 69px;
    height: 64px;
    margin-top: -30px;
    transform: rotate(135deg) scale(0.7);
    opacity: 0.6;
    animation: ${WaveAnimation} 0.85s infinite linear;
`;
