import { useEffect } from 'react';
import styled from 'styled-components';
import Analytics from '../lib/user-analytics';

const Maintenance = () => {
    useEffect(() => {
        Analytics.Event('Maintenance', 'Displayed maintenance page');
    }, []);

    return (
        <DivContainer>
            <DivContent>
                <Title>We'll be back soon!</Title>
                <p>Sorry for the inconvenience but we're performing site maintenance at the moment.</p>
                <p>We'll be back online shortly!</p>
                <p>The Soar Team</p>
                <Logo src="/assets/logos/soar_logo_large.png" />
            </DivContent>
        </DivContainer>
    );
};

export default Maintenance;

const DivContainer = styled.div`
    background-image: url(/assets/backgrounds-large/soar-platform-hazy-as-backdrop.png);
    background-size: cover;
    min-height: 100vh;
    padding: 50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const DivContent = styled.div`
    display: block;
    text-align: center;
    & p {
        color: white;
        font-size: 20px;
    }
`;

const Title = styled.div`
    color: white;
    font-size: 40px;
    margin-bottom: 25px;
`;

const Logo = styled.img`
    width: 200px;
`;
