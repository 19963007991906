import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import ApiSocial from '../../../../../api/api-social';
import Analytics from '../../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../../store/Account/selectors';
import { selectFavouriteMaps } from '../../../../../store/Map/Favourites/selectors';
import { actionShowLoginDialog } from '../../../../../store/App/actions';
import { ListingDTO } from '../../../../../api/model';
import { actionFetchLikedMaps } from '../../../../../store/Map/Favourites/actions';
import MathUtil from '../../../../../lib/math-util';

interface ShareLikeProps {
    selectedMap: ListingDTO;
}

const ShareLike = ({ selectedMap }: ShareLikeProps) => {
    const likedMaps = useSelector(selectFavouriteMaps);
    const isLoggedIn = useSelector(selectLoggedIn);
    const dispatch = useDispatch();

    const [likes, setLikes] = useState(selectedMap.totalLikes ?? 0);
    const [isLikedByUser, setIsLikedByUser] = useState(false);

    useEffect(() => {
        setIsLikedByUser(likedMaps.find((t) => t.id === selectedMap.id) ? true : false);
    }, [likedMaps, selectedMap.id]);

    const handleClickedLike = async () => {
        if (!isLoggedIn) {
            dispatch(actionShowLoginDialog(true));
            return;
        }
        Analytics.Event('Side Drawer', `Clicked Like ${selectedMap.id}`);

        try {
            let newLikes: number;
            if (isLikedByUser) {
                newLikes = (await ApiSocial.unlikeListing(selectedMap.id)).totalLikes;
            } else {
                newLikes = (await ApiSocial.likeListing(selectedMap.id)).totalLikes;
            }
            setLikes(newLikes);

            setIsLikedByUser(!isLikedByUser);
            dispatch(actionFetchLikedMaps());
        } catch (err) {
            toast.error(err.message);
        }
    };

    const formattedLikes = MathUtil.formatNumber(likes);

    return (
        <HeartContainer textLength={formattedLikes.length}>
            <HeartIcon
                onClick={handleClickedLike}
                isLiked={isLikedByUser}
                className={isLikedByUser ? 'fas fa-heart liked' : 'far fa-heart'}
                title="Like this map"
            />
            <HeartValue
                data-testid="map-likes"
                title={`${likes.toLocaleString()} likes`}
                onClick={handleClickedLike}
                textLength={formattedLikes.length}
            >
                {formattedLikes}
            </HeartValue>
        </HeartContainer>
    );
};

export default ShareLike;

interface HeartIconProps {
    isLiked: boolean;
}

const HeartPulseAnimation = keyframes`
    0% {
        transform: scale( 1 );
    } 25% {
        transform: scale( 1.25 );
    } 50% {
        transform: scale( 1 );
    } 75% {
        transform: scale( 1.25 );
    } 100% {
        transform: scale( 1 );
    }
`;

const HeartContainer = styled.div<{ textLength: number }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: ${(props) => {
        switch (props.textLength) {
            case 6:
                return `28px`;
            case 5:
                return `23px`;
            case 4:
                return `18px`;
            case 3:
                return `16px`;
            case 2:
                return `11px`;
            default:
                return `7px`;
        }
    }};
    position: relative;

    @media screen and (max-width: 600px) {
        margin-top: auto;
    }
`;

const HeartIcon = styled.i<HeartIconProps>`
    cursor: pointer;
    color: ${(props) => (props.isLiked ? '#FF5A5A' : 'white')};
    font-size: 18px;
    pointer-events: all;

    &.liked {
        animation: ${HeartPulseAnimation} 1s ease;
        animation-iteration-count: 1;
    }

    &:hover {
        color: #eed926;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
        height: 20px;
        margin: 0px;
    }
`;

const HeartValue = styled.sup<{ textLength: number }>`
    cursor: pointer;
    color: white;
    position: absolute;
    top: 0;
    right: ${(props) => (props.textLength > 3 ? `-3px` : `-1px`)};
    text-shadow: 1px 1px 1px black;

    @media screen and (max-width: 600px) {
        position: initial;
        margin: -5px 0 0 6px;
        font-size: 18px;
    }
`;
