
import { History } from 'history';
import { useHistory } from 'react-router';
import UriHelper from '../../lib/uri-helper';
import { SideDrawerMode } from '../../store/SideDrawer/model';


/**
 * The `useBack` behaves like `history.goBack()` except you can provide a fallback for when
 * the page has no history (such as when opened from a link, bookmark or new tab)
 *
 * usage:
 *      const goBack = useBack(SideDrawerMode.MAPS);
 *      goBack();
 *
 * @param fallbackRoute
 * @returns
 */
export const useBack = (fallbackRoute: SideDrawerMode) => {
    const history: History = useHistory();

    const handleBack = () => {
        if (history.action === 'POP') {
            UriHelper.navigateToDrawer(fallbackRoute);
        } else {
            history.goBack();
        }
    }

    return [handleBack];
}
