import React, { useEffect, useRef, useState } from 'react';
import L, { Control } from 'leaflet';
import { useSelector } from 'react-redux';
import { LayersControl, TileLayer as LeafletTileLayer, WMSTileLayer, useMap, GeoJSON, LayerGroup } from 'react-leaflet';
import { selectPinnedTileLayers, selectTileLayersOpacity } from '../../store/Map/TileLayer/selectors';
import { ListingDTO, ListingType } from '../../api/model';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import './pinned-tilelayers.css';
import ListingHelper from '../../lib/listing-helper';
import { isMobileVersion } from '../../lib/soar-helper';
import GeoUtil from '../../lib/geo-util';
import PinnedImageOverlay from './Annotations/DistortedImageOverlay/PinnedImageOverlay';
import WMTSTileLayers from './TileLayers/wmts-tilelayers';

const pinnedTileLayersIconUrl = '/assets/layers.png';

interface PinnedTileLayersProps {
    editMode: boolean;
}

const PinnedTileLayers = ({ editMode }: PinnedTileLayersProps) => {
    const map = useMap();
    const url = useLocation();
    const pinnedTileLayers: ListingDTO[] = useSelector(selectPinnedTileLayers);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);
    const [isNotificationVisible, setNotificationVisible] = useState(false);

    const pinnedTileLayersRef = useRef<Control.Layers>(null);

    useEffect(() => {
        if (pinnedTileLayersRef?.current) {
            // @ts-ignore
            const layersLink = pinnedTileLayersRef.current._layersLink;
            layersLink.style.backgroundImage = `url(${pinnedTileLayersIconUrl})`;
            layersLink.title = '';

            // @ts-ignore
            const pinnedTileLayersContainer = pinnedTileLayersRef.current._container;
            pinnedTileLayersContainer.classList.add('leaflet-control-pinned-maps');
        }
    }, []);

    // NOTE: Do not remove L.DomUtil.addClass or removeClass unless the pinned maps are been removed completely from the map
    // they are important to the pinned maps behaviour of showing and hiding...
    useEffect(() => {
        // @ts-ignore
        const pinnedTileLayersContainer = pinnedTileLayersRef.current._container;

        if (pinnedTileLayers.length > 0) {
            pinnedTileLayersContainer.classList.add('show-pinned-maps');
            setNotificationVisible(true);
            L.DomUtil.addClass(map.getContainer(), 'pinnedMaps');
            // After a 4 second delay, allow the notification to be presented again if needed
            setTimeout(() => {
                setNotificationVisible(false);
            }, 4000);
        } else {
            pinnedTileLayersContainer.classList.remove('show-pinned-maps');
            setNotificationVisible(false);
            L.DomUtil.removeClass(map.getContainer(), 'pinnedMaps');
        }
    }, [pinnedTileLayers, url.pathname, map]);

    // LeafletTileLayer is ignoring the zIndex={1001} but give a classname and assign a z-index it works as expected
    return (
        <React.Fragment>
            {isNotificationVisible && pinnedTileLayers.length > 0 && !isMobileVersion && !editMode && (
                <Tooltip>Check out your pinned map here!</Tooltip>
            )}
            <LayersControl position="bottomright" ref={pinnedTileLayersRef}>
                {pinnedTileLayers.map((pinned, id) => {
                    if (pinned.listingType === ListingType.EXTERNAL_TILE_LAYER && pinned.tileUrl) {
                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <LeafletTileLayer
                                    key={pinned.id}
                                    className="pinned-tile-layer"
                                    url={pinned.tileUrl}
                                    tms={pinned.tms ? pinned.tms : false}
                                    bounds={pinned.boundingBox}
                                    opacity={tileLayersOpacity[pinned.id] ?? 1.0}
                                    maxZoom={28}
                                    maxNativeZoom={28}
                                />
                            </LayersControl.Overlay>
                        );
                    }

                    if (pinned.listingType === ListingType.TILE_LAYER && pinned.tileUrl) {
                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <LeafletTileLayer
                                    className="pinned-tile-layer"
                                    url={pinned.tileUrl}
                                    tms={true}
                                    bounds={pinned.boundingBox}
                                    opacity={tileLayersOpacity[pinned.id] ?? 1.0}
                                    maxZoom={28}
                                    maxNativeZoom={28}
                                />
                            </LayersControl.Overlay>
                        );
                    }

                    if (pinned.listingType === ListingType.WMS && pinned.serverUrl) {
                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <WMSTileLayer
                                    className="pinned-tile-layer"
                                    url={pinned.serverUrl}
                                    layers={pinned.layerName}
                                    opacity={tileLayersOpacity[pinned.id] ?? 1.0}
                                    transparent={true}
                                    format={pinned.layerImageFormat || 'image/png'}
                                    zIndex={1000}
                                    maxZoom={28}
                                    maxNativeZoom={28}
                                />
                            </LayersControl.Overlay>
                        );
                    }

                    if (pinned.listingType === ListingType.WMTS && pinned.serverUrl) {
                        let maxZoom = 28;
                        if (pinned.tileMatrixSet?.matrices) {
                            maxZoom = 0;
                            pinned.tileMatrixSet?.matrices.forEach((m, i) => {
                                if (i > maxZoom) {
                                    maxZoom = i;
                                }
                            });
                        }

                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <WMTSTileLayers
                                    className="pinned-tile-layer"
                                    key={pinned.id}
                                    url={pinned.serverUrl}
                                    layer={pinned.layerName}
                                    tileMatrixSet={pinned.tileMatrixSet?.identifier}
                                    style={pinned.style}
                                    matrixIds={pinned.tileMatrixSet?.matrices}
                                    opacity={tileLayersOpacity[pinned.id] ?? 1.0}
                                    zIndex={1000}
                                    maxZoom={maxZoom}
                                    maxNativeZoom={maxZoom}
                                />
                            </LayersControl.Overlay>
                        );
                    }

                    if (pinned.listingType === ListingType.IMAGE) {
                        const distoredGeometryWKT = pinned.distoredGeometry;
                        const points = GeoUtil.polygonFromPolygonWKT(distoredGeometryWKT);
                        const corners = [points[2], points[3], points[0], points[1]];

                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <PinnedImageOverlay
                                    corners={corners}
                                    imageURL={ListingHelper.getPreviewUrlForListing(pinned.id)}
                                    opacity={tileLayersOpacity[pinned.id] ?? 1.0}
                                />
                            </LayersControl.Overlay>
                        );
                    }

                    if (
                        (pinned.listingType === ListingType.COG ||
                            pinned.listingType === ListingType.EXTERNAL_TILE_LAYER) &&
                        pinned.tileUrl
                    ) {
                        // Create a new pane for the external layer if not it will not be visible
                        const pane = `external-layer-${pinned.id.toString()}`;
                        map.createPane(pane);
                        return (
                            <LayersControl.Overlay name={pinned.title || 'External Layer'} checked key={id}>
                                <LayerGroup>
                                    <LeafletTileLayer
                                        key={pinned.id}
                                        url={pinned.tileUrl}
                                        tms={pinned.tms ? pinned.tms : false}
                                        opacity={tileLayersOpacity[pinned.id] ?? 1.0}
                                        bounds={pinned.boundingBox}
                                        zIndex={1000}
                                        pane={pane}
                                        maxZoom={28}
                                        maxNativeZoom={28}
                                    />
                                    {pinned.geometryGeoJson && (
                                        <GeoJSON
                                            data={JSON.parse(pinned.geometryGeoJson as string)}
                                            style={{ fillOpacity: 0, color: 'red', weight: 1 }}
                                        />
                                    )}
                                </LayerGroup>
                            </LayersControl.Overlay>
                        );
                    }

                    return <React.Fragment />;
                })}
            </LayersControl>
        </React.Fragment>
    );
};

export default PinnedTileLayers;

const TooltipAnimation = keyframes`
    0% {
        opacity: 1;
    } 80% {
        opacity: 1;
    } 100% {
        opacity: 0;
    }
`;

const Tooltip = styled.span`
    position: fixed;
    bottom: 80px;
    right: 60px;
    z-index: 1000;
    pointer-events: none;

    width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 30px;
    opacity: 0;
    font-size: 16px;

    animation: ${TooltipAnimation} 2s;
    animation-iteration-count: 1;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    }
`;
