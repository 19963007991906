import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import ApiUser from '../../../api/api-user';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { actionGetMyProfile } from '../../../store/Account/actions';
import { StyledButton } from '../../Shared/styled-button';
import NavigationPanel from '../Shared/navigation-panel';
import { ProfileEditAvatar } from './profile-edit-avatar';
import { CharacterCount } from '../../Shared/character-count';
import { MarkdownTextArea } from '../../Shared/markdown-textarea';
import { UserDTO } from '../../../api/model';
import TextInput from '../../Shared/text-input';

interface ProfileEditProps {
    myProfile: UserDTO;
}

const MAX_BIO_CHARACTERS = 2500;

const ProfileEdit = ({ myProfile }: ProfileEditProps) => {
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState(myProfile.firstName);
    const [lastName, setLastName] = useState(myProfile.lastName);
    const [tagLine, setTagLine] = useState(myProfile.bio);
    const [bio, setBio] = useState(myProfile.longDescription || '');
    const [avatarFile, setAvatarFile] = useState<File | undefined>(undefined);
    const [twitterUrl, setTwitterUrl] = useState(myProfile.twitterUrl);
    const [facebookUrl, setFaceBookUrl] = useState(myProfile.facebookUrl);
    const [instagramUrl, setInstagramUrl] = useState(myProfile.instagramUrl);
    const [linkedinUrl, setLinkedinUrl] = useState(myProfile.linkedinUrl);
    const [websiteUrl, setWebsiteUrl] = useState(myProfile.websiteUrl);

    const handleApplyChanges = () => {
        if (!myProfile) return;
        if (!isValidForm()) return;

        const name = myProfile.name;
        const avatarUrl = myProfile.avatarUrl && myProfile.avatarUrl.replace('/preview', '');

        const sanitizedTwitterUrl = twitterUrl ? UserHelper.setTwitterUrl(twitterUrl) : undefined;
        const sanitizedInstagramUrl = instagramUrl ? UserHelper.setInstagramUrl(instagramUrl) : undefined;
        const sanitizedFacebookUrl = facebookUrl ? UserHelper.setFacebookUrl(facebookUrl) : undefined;
        const sanitizedLinkedinUrl = linkedinUrl ? UserHelper.setLinkedInUrl(linkedinUrl) : undefined;

        ApiUser.updateMyProfile(
            name,
            tagLine,
            sanitizedTwitterUrl,
            sanitizedFacebookUrl,
            sanitizedInstagramUrl,
            websiteUrl,
            sanitizedLinkedinUrl,
            firstName,
            lastName,
            bio,
            avatarFile,
            avatarUrl
        ).then(() => {
            toast.dark('Profile Details Updated', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
            });
            // TODO this forces rerender but need better way
            dispatch(actionGetMyProfile());
            Analytics.Event('My Dashboard', 'Updated profile', myProfile.userId);
        });
    };

    const handleAvatarChanged = () => {
        if (avatarFile) {
            handleApplyChanges();
        }
    };

    const isValidForm = () => {
        if (!firstName || !lastName) return false;

        if (bio.length > MAX_BIO_CHARACTERS) return false;

        return true;
    };

    const userSoarProfileUrl = `${window.location.origin}/profile/${myProfile.userId}`;

    return (
        <ProfileEditContainer>
            <NavigationPanel label="Edit My Profile" />
            <Row className="d-flex justify-content-center">
                <ProfileEditAvatar
                    myProfile={myProfile}
                    avatarFile={avatarFile}
                    setAvatarFile={setAvatarFile}
                    setHasChangedAvatar={(changed) => {
                        if (changed) handleAvatarChanged();
                    }}
                />
            </Row>

            <RowCard>
                <ColumnCard>
                    <h3>My Profile</h3>
                    <br />

                    <ProfileFormGroup>
                        <Label for="firstname">First Name</Label>
                        <TextInput
                            name="firstname"
                            placeholder="Enter your first name."
                            value={firstName}
                            onChange={setFirstName}
                            validation={{ required: true, maxLength: 100 }}
                        />
                    </ProfileFormGroup>
                    <ProfileFormGroup>
                        <Label for="lastname">Last Name</Label>
                        <TextInput
                            name="lastname"
                            placeholder="Enter your last name."
                            value={lastName}
                            onChange={setLastName}
                            validation={{ required: true, maxLength: 100 }}
                        />
                    </ProfileFormGroup>
                    <ProfileFormGroup>
                        <Label for="tagline">Tagline</Label>
                        <TextInput
                            name="tagline"
                            placeholder="Write something..."
                            value={tagLine}
                            onChange={setTagLine}
                            validation={{ maxLength: 100 }}
                        />
                    </ProfileFormGroup>

                    <ProfileFormGroup className="flex-column">
                        <Label for="bio">Bio</Label>
                        <InputGroup>
                            <MarkdownTextArea value={bio} setInputValue={setBio} />
                            {bio.length > MAX_BIO_CHARACTERS ? (
                                <ValidationError>{`Maximum allowed characters: ${MAX_BIO_CHARACTERS}`}</ValidationError>
                            ) : (
                                <CharacterCount text={bio} limit={MAX_BIO_CHARACTERS} />
                            )}
                        </InputGroup>
                    </ProfileFormGroup>
                </ColumnCard>

                <ColumnCard>
                    <h3>Social Media</h3>
                    <br />
                    <ProfileFormGroup>
                        <img src="/assets/social-share-icons/twitter.png" alt="X" />
                        <TextInput
                            name="twitter"
                            placeholder="Enter X Url"
                            value={twitterUrl}
                            onChange={setTwitterUrl}
                            validation={{ maxLength: 100 }}
                        />
                    </ProfileFormGroup>
                    <ProfileFormGroup>
                        <img src="/assets/social-share-icons/instagram.png" alt="instagram" />
                        <TextInput
                            name="instagram"
                            placeholder="Enter Instagram Url"
                            value={instagramUrl}
                            onChange={setInstagramUrl}
                            validation={{ maxLength: 100 }}
                        />
                    </ProfileFormGroup>
                    <ProfileFormGroup>
                        <img src="/assets/social-share-icons/facebook.png" alt="facebook" />
                        <TextInput
                            name="facebook"
                            placeholder="Enter Facebook Url"
                            value={facebookUrl}
                            onChange={setFaceBookUrl}
                            validation={{ maxLength: 100 }}
                        />
                    </ProfileFormGroup>
                    <ProfileFormGroup>
                        <img src="/assets/social-share-icons/linkedin.png" alt="linkedin" />
                        <TextInput
                            name="linkedin"
                            placeholder="Enter LinkedIn Url"
                            value={linkedinUrl}
                            onChange={setLinkedinUrl}
                            validation={{ maxLength: 100 }}
                        />
                    </ProfileFormGroup>
                    <ProfileFormGroup>
                        <img src="/assets/social-share-icons/website.png" alt="website" />
                        <TextInput
                            name="website"
                            placeholder="Enter Website Url"
                            value={websiteUrl}
                            onChange={setWebsiteUrl}
                            validation={{ maxLength: 100 }}
                        />
                    </ProfileFormGroup>

                    <ProfileFormGroup>
                        <img src="/assets/social-share-icons/icon-share.png" alt="soar profile" />
                        <ShareProfileContainer>
                            <UserSoarProfile
                                href={userSoarProfileUrl}
                                title=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    const soarURL = userSoarProfileUrl;
                                    const textField = document.createElement('textarea');
                                    textField.innerText = soarURL;
                                    document.body.appendChild(textField);
                                    textField.select();
                                    document.execCommand('copy');
                                    textField.remove();
                                    toast.dark('Copied to clipboard');
                                    Analytics.Event('Profile', 'Soar Profile', soarURL);
                                }}
                            >
                                <p>Share your user Soar Profile</p>
                            </UserSoarProfile>
                        </ShareProfileContainer>
                    </ProfileFormGroup>
                </ColumnCard>
            </RowCard>

            <RowCard>
                <ColumnCard className="d-flex justify-content-center">
                    <StyledButton disabled={!isValidForm()} onClick={handleApplyChanges}>
                        APPLY CHANGES
                    </StyledButton>
                </ColumnCard>
            </RowCard>
        </ProfileEditContainer>
    );
};

export default ProfileEdit;

const RowCard = styled(Row)`
    @media only screen and (max-width: 600px) {
        margin-right: 0px !important;
    }
`;

const ColumnCard = styled(Col)`
    margin: 15px;
    background-color: black;
    padding: 20px;
    border-radius: 6px;

    color: white;
    -webkit-text-fill-color: white;

    h3 {
        text-align: left;
    }

    @media only screen and (max-width: 600px) {
        margin: 10px 10px 10px 20px;
        padding: 10px;
    }
`;

const ProfileEditContainer = styled.div`
    width: 100%;
    padding-right: 10px;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;
`;

const ProfileFormGroup = styled(FormGroup)`
    display: flex;
    min-height: 55px;
    margin-left: 10px;
    margin-right: 10px;

    label {
        width: 120px;
    }
    img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
`;

const InputGroup = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const UserSoarProfile = styled.a`
    cursor: pointer;
    p {
        color: rgba(255, 255, 255, 0.6);
    }
    &:hover {
        color: #eed926;
        -webkit-text-fill-color: #eed926;
    }
`;

const ShareProfileContainer = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    width: 100%;
    height: 30px;
    justify-content: center;
    color: rgba(255, 255, 255, 0.6);
    padding: 3px 0px 0px 10px;

    &:hover {
        border-color: #eed926;
        -webkit-text-fill-color: #eed926;
    }
`;

const ValidationError = styled.div`
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 10px;
    display: inline-block;
    color: red;
    -webkit-text-fill-color: red;
`;
