import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import MilitaryMarker from './military-marker';
import MilitaryMarkerSelect from './military-marker-select';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import {
    setMilitaryMarkerDeleteAction,
    setMilitaryMarkerSidcAction,
    setMilitaryMarkerUpdateAction,
} from '../../../../store/Annotations/MilitaryMarker/actions';
import { selectMilitaryMarkers } from '../../../../store/Annotations/MilitaryMarker/selectors';
import MilitaryMarkerModal from './military-marker-modal';
import MarkerResize from '../Marker/marker-resize';
import { MilSymbolValues, sidValues, echelonValues, unitValues } from './MilSymbol/values';
import Analytics from '../../../../lib/user-analytics';

const MilitaryMarkerToolbar = () => {
    const [affiliationData, setAffiliationData] = useState<MilSymbolValues[]>([]);
    const [unitData, setUnitData] = useState<MilSymbolValues[]>([]);
    const [echelonData, setEchelonData] = useState<MilSymbolValues[]>([]);

    useEffect(() => {
        const fetchData = () => {
            setAffiliationData(sidValues);
            setEchelonData(echelonValues);
            setUnitData(unitValues);
        };

        fetchData();
    }, []);

    const dispatch = useDispatch();
    const militaryMarkers = useSelector(selectMilitaryMarkers);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedMarker = militaryMarkers.find((c) => c.id === selectedAnnotation?.id);

    const updateMarker = (marker: MilitaryMarker) => {
        Analytics.Event('Draw Tools - Project', `Updated military marker ${marker.sidc ? marker.sidc : ''}`);
        dispatch(setMilitaryMarkerUpdateAction(marker));
        dispatch(setMilitaryMarkerSidcAction(marker.sidc));
    };

    if (!selectedMarker) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Affiliation:</ToolbarText>
            <MilitaryMarkerSelect
                selectOptions={affiliationData}
                name="affiliation"
                selectControlWidth="180px"
                selectedMarker={selectedMarker}
                updateMarker={updateMarker}
            />
            <ToolbarDivider />

            <ToolbarText>Unit:</ToolbarText>
            <MilitaryMarkerSelect
                selectOptions={unitData}
                name="unit"
                selectControlWidth="180px"
                selectedMarker={selectedMarker}
                updateMarker={updateMarker}
            />
            <ToolbarDivider />

            <ToolbarText>Echelon:</ToolbarText>
            <MilitaryMarkerSelect
                selectOptions={echelonData}
                name="echelon"
                selectControlWidth="180px"
                selectedMarker={selectedMarker}
                updateMarker={updateMarker}
            />
            <ToolbarDivider />

            <ToolbarText>Custom:</ToolbarText>
            <MilitaryMarkerModal selectedMarker={selectedMarker} updateMarker={updateMarker} />
            <ToolbarDivider />

            <ToolbarText>Resize</ToolbarText>
            <MarkerResize
                marker={selectedMarker}
                onUpdate={(marker: MilitaryMarker) => {
                    const newMarker = {
                        ...selectedMarker,
                        zoomBase: marker.zoomBase,
                    };
                    updateMarker(newMarker);
                }}
            />
            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    Analytics.Event('Draw Tools - Project', `Deleted military marker ${selectedMarker.sidc}`);
                    DrawToolAnalytics.delete('military-marker');
                    dispatch(setMilitaryMarkerDeleteAction(selectedMarker));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default MilitaryMarkerToolbar;
