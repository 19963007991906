import { useState } from 'react';
import countryList from 'react-select-country-list';
import { CountryInfo } from '../../../../api/model';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import {
    SatelliteSubmitButton,
    Content,
    Title,
    Divider,
    SatelliteSelectField,
    ErrorText,
    SatelliteInputField,
    SatelliteLabel,
    SatelliteForm,
} from '../satellite-drawer-styles';

interface SatelliteOrderUserDetailsProps {
    handleSubmitUserDetails: (companyName: string, industry: Industry, country: CountryInfo) => void;
}

export enum Industry {
    Undefined = 'Select your industry',
    Mining = 'Mining / Oil / Gas',
    Agriculture = 'Agriculture',
    RealEstate = 'Real Estate',
    Security = 'Security / Defense',
    News = 'Media / News',
    Government = 'Government',
    Other = 'Other',
}

const SatelliteOrderUserDetails = (props: SatelliteOrderUserDetailsProps) => {
    const [companyName, setCompanyName] = useState<string>('');
    const [industry, setIndustry] = useState<Industry>(Industry.Undefined);
    const [industryValid, setIndustryValid] = useState(true);
    const [companyNameValid, setCompanyNameValid] = useState(true);
    const [countryName, setCountryName] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('');
    const [countryNameValid, setCountryNameValid] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (companyName.length < 2) {
            setCompanyNameValid(false);
            return;
        }

        if (countryCode.length < 2) {
            setCountryNameValid(false);
            return;
        }

        if (industry == Industry.Undefined) {
            setIndustryValid(false);
            return;
        }
        setSubmitting(true);
        const countryInfo: CountryInfo = {
            country: countryName,
            countryCode: countryCode,
        };
        props.handleSubmitUserDetails(companyName, industry, countryInfo);
        Analytics.Event('Satellite', 'Completed user details');
    };

    const handleCountryChange = (countryName: string, countryCode: string) => {
        setCountryName(countryName);
        setCountryCode(countryCode);
        setCountryNameValid(true);
        Analytics.Event('Satellite', 'Selected country', countryName);
    };

    return (
        <Content>
            <Title>Your Details</Title>

            <SatelliteForm onSubmit={(e) => handleSubmit(e)}>
                <SatelliteLabel>Your name</SatelliteLabel>
                <SatelliteInputField
                    placeholder="Enter your name"
                    type="text"
                    name="Organisation"
                    value={companyName}
                    onChange={(e) => {
                        setCompanyName(e.target.value);
                        setCompanyNameValid(true);
                    }}
                />

                {!companyNameValid ? <ErrorText>Please enter your organisation or name</ErrorText> : null}

                <SatelliteLabel>Your Industry</SatelliteLabel>
                <SatelliteSelectField
                    name="Industry"
                    onChange={(value) => {
                        setIndustry(Industry[value.target.value]);
                        setIndustryValid(true);
                    }}
                >
                    {Object.keys(Industry).map((key) => {
                        return (
                            <option
                                onSelect={() => setIndustry(Industry[key])}
                                selected={industry.toString() === key}
                                key={key}
                                value={key}
                            >
                                {Industry[key]}
                            </option>
                        );
                    })}
                </SatelliteSelectField>

                {!industryValid ? <ErrorText>Please enter your industry</ErrorText> : null}

                <SatelliteLabel>Your country</SatelliteLabel>
                <SatelliteSelectField
                    name="Country"
                    onChange={(result) => {
                        const selected = JSON.parse(result.target.value);
                        handleCountryChange(selected.label, selected.value);
                        setCountryNameValid(true);
                    }}
                >
                    <option key="Your country" value={`{"value":"","label":""}`}>
                        Select your country
                    </option>
                    {countryList()
                        .getData()
                        .map((key) => {
                            if (key.label === 'Antarctica') return null;
                            return (
                                <option key={key.label} value={JSON.stringify(key)}>
                                    {key.label}
                                </option>
                            );
                        })}
                </SatelliteSelectField>

                {!countryNameValid ? <ErrorText>Please enter your country</ErrorText> : null}
            </SatelliteForm>

            <Divider />

            <SatelliteSubmitButton
                disabled={submitting}
                onClick={(e) => {
                    handleSubmit(e);
                    Analytics.Event('Satellite Ordering', 'Clicked confirmed user details');
                    Analytics.Conversion(ConversionEvent.ADD_USER_DETAILS);
                }}
            >
                Confirm your details
            </SatelliteSubmitButton>
        </Content>
    );
};

export default SatelliteOrderUserDetails;
